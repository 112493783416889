import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { useState } from 'react';
import { GenericDrawer, getVisibleTags, useDepartmentInfoContext } from '@stationwise/component-module';
import { PayCode } from '@stationwise/share-types';
import { AdditionalPaidTimeContent } from './AdditionalPaidTimeContent';
import { EventTypeSelect } from './AdditionalPaidTimeEventSelector';
import { PayCodeSelect } from './AdditionalPaidTimePayCodeSelector';

interface AdditionalPaidTimeProps {
  handleOnClose: () => void;
  drawerOpen: boolean;
  shift: EventInput | EventImpl;
  handleAdditionalPaidTimeRequest: (responseMessage: string, error: boolean) => void;
  forceManual?: boolean;
}

export const AdditionalPaidTime = ({
  shift,
  handleOnClose,
  drawerOpen,
  handleAdditionalPaidTimeRequest,
  forceManual = false,
}: AdditionalPaidTimeProps) => {
  const { state: department } = useDepartmentInfoContext();
  const [selectEventTypeOpen, setSelectEventTypeOpen] = useState(false);
  const [selectPayCodeOpen, setSelectPayCodeOpen] = useState(false);
  const [eventType, setEventType] = useState<string | null>(null);
  const [selectedPayCode, setSelectedPayCode] = useState<PayCode | null>(null);
  const departmentPayCodes = department?.departmentInfo?.payCodes || [];
  const visibleTags = getVisibleTags(department?.departmentInfo, 'APT_REQUEST', 'paycode');

  // Filter pay codes based on visible tags
  const filteredPayCodes =
    visibleTags.length > 0
      ? departmentPayCodes.filter((payCode) => payCode.tags.some((tag) => visibleTags.includes(tag)))
      : departmentPayCodes;

  // Group filtered pay codes by their types
  const filteredTypeToPayCodeMapping = filteredPayCodes.reduce(
    (acc, payCode) => {
      if (payCode.payCodeType !== 'Time off') {
        if (!acc[payCode.payCodeType]) {
          acc[payCode.payCodeType] = [];
        }
        acc[payCode.payCodeType].push(payCode);
      }
      return acc;
    },
    {} as Record<string, PayCode[]>,
  );

  // Get event types from the filtered mappings
  const eventTypes = Object.keys(filteredTypeToPayCodeMapping);

  const handleRequest = (responseMessage: string, error: boolean) => {
    handleAdditionalPaidTimeRequest(responseMessage, error);
    setEventType(null);
    setSelectedPayCode(null);
  };

  const handleSelectEventTypeOpen = () => {
    setSelectEventTypeOpen(true);
  };
  const handleSelectEventTypeClose = () => {
    setSelectEventTypeOpen(false);
  };
  const handleSelectPayCodeOpen = () => {
    if (eventType) {
      setSelectPayCodeOpen(true);
    }
  };

  const handleSelectPayCodeClose = () => {
    setSelectPayCodeOpen(false);
  };
  const handleClose = () => {
    setEventType(null);
    setSelectedPayCode(null);
    handleOnClose();
  };
  return (
    <>
      <GenericDrawer
        anchor="bottom"
        drawerOpen={drawerOpen}
        handleOnClose={handleClose}
        loading={false}
        showHeader={true}
        disableFooter={true}
        headerTitle={'Additional paid time '}
      >
        <div>
          <Box sx={{ height: '100vh' }}>
            <AdditionalPaidTimeContent
              shift={shift}
              handleAdditionalPaidTimeRequest={handleRequest}
              forceManual={forceManual}
              handleEventTypeOpen={handleSelectEventTypeOpen}
              eventType={eventType}
              handleSelectPayCodeOpen={handleSelectPayCodeOpen}
              payCode={selectedPayCode}
            />
          </Box>
        </div>
      </GenericDrawer>
      <EventTypeSelect
        handleOnClose={handleSelectEventTypeClose}
        drawerOpen={selectEventTypeOpen}
        options={eventTypes}
        setEventType={setEventType}
        setPayCode={setSelectedPayCode}
      />
      <PayCodeSelect
        handleOnClose={handleSelectPayCodeClose}
        drawerOpen={selectPayCodeOpen}
        options={eventType ? filteredTypeToPayCodeMapping[eventType] || [] : []}
        setPayCode={setSelectedPayCode}
      />
    </>
  );
};
