import { Box, Skeleton } from '@mui/material';
import { LetterAvatar } from '@stationwise/component-module';

export const SendingMessageSkeleton = () => {
  return (
    <Box display="flex" sx={{ mt: '25px', ml: '24px' }}>
      <Box>
        <LetterAvatar firstName="E" color="red" />
      </Box>
      <Box
        display="flex"
        gap="5px"
        sx={(theme) => ({
          background: theme.palette.stationGray[50],
          height: '80px',
          textAlign: 'center',
          borderRadius: '16px',
          width: '85%',
          ml: '16px',
          pt: '34px',
          justifyContent: 'center',
        })}
      >
        {Array.from({ length: 3 }, (_, index) => (
          <Skeleton
            sx={(theme) => ({
              bgcolor: theme.palette.stationGray[100],
            })}
            variant="circular"
            width={12}
            height={12}
            key={index}
          />
        ))}
      </Box>
    </Box>
  );
};
