import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import {
  ChevronDownIcon16,
  ChevronLeftIcon20,
  ChevronRightIcon20,
  useDateQueryParam,
  useFetchShiftsData,
} from '@stationwise/component-module';
import { Day } from './Day';

export const MiniCalendar = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const { addDays, selectedDateAsDate } = useDateQueryParam();

  const formattedStartDate = useMemo(
    () => format(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1), 'MM/dd/yyyy'),
    [currentMonth],
  );
  const formattedEndDate = useMemo(
    () => format(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0), 'MM/dd/yyyy'),
    [currentMonth],
  );

  const { isLoading, data, refetchData } = useFetchShiftsData(formattedStartDate, formattedEndDate);

  const getTeamColorForDate = (day: Date) => {
    const shift = data.find((shift) => shift.startDate === format(day, 'yyyy-MM-dd'));
    return shift?.teamColor;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateCalendar
        value={new Date(formattedStartDate)}
        onMonthChange={(newMonth) => {
          setCurrentMonth(newMonth);
          refetchData();
        }}
        sx={{ width: 324, height: 376 }}
        loading={isLoading}
        renderLoading={() => <DayCalendarSkeleton />}
        views={['day', 'month']}
        slots={{
          day: (props) => (
            <Day
              {...props}
              getTeamColorForDate={getTeamColorForDate}
              selectedDateAsDate={selectedDateAsDate || new Date()}
              addDays={addDays}
            />
          ),
          leftArrowIcon: ChevronLeftIcon20,
          rightArrowIcon: ChevronRightIcon20,
          switchViewIcon: ChevronDownIcon16,
        }}
      />
    </LocalizationProvider>
  );
};
