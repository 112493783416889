import { Box, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { useState } from 'react';
import {
  formatPhoneNumber,
  GenericDrawer,
  PhoneInput,
  SnackbarService,
  unformatPhoneNumber,
  useAuthUserContext,
} from '@stationwise/component-module';
import { client, refreshUserData } from '@stationwise/share-api';

interface EditPhoneNumberDrawerProps {
  drawerOpen: boolean;
  onClose: () => void;
  phoneNumber: string | null;
  setPhoneNumber: (value: string | null) => void;
}

export const EditPhoneNumberDrawer = ({ drawerOpen, phoneNumber, onClose, setPhoneNumber }: EditPhoneNumberDrawerProps) => {
  const theme = useTheme();
  const { dispatch } = useAuthUserContext();
  const [phoneValue, setPhoneValue] = useState(phoneNumber ? phoneNumber : '');
  const disabled = !(phoneValue?.length === 10 || phoneValue?.length === 0) || phoneValue === unformatPhoneNumber(phoneNumber);

  const handleSave = async () => {
    try {
      const params = { phone: formatPhoneNumber(phoneValue) };
      await client.patch('/employee/update/', params);
      await refreshUserData(dispatch);
      SnackbarService.notify({
        content: 'Your phone number has been updated successfully.',
        severity: 'success',
        duration: 3000,
      });
      setPhoneNumber(phoneValue); // update the outer phoneNumber
    } catch (error) {
      SnackbarService.notify({
        content: 'An error occurred while trying to change your phone number. Please try again later.',
        severity: 'error',
        duration: 5000,
      });
      captureException(error);
    } finally {
      onClose();
    }
  };

  return (
    <GenericDrawer
      anchor={'bottom'}
      drawerOpen={drawerOpen}
      handleOnClose={onClose}
      loading={false}
      headerTitle={'Edit Phone Number'}
      showHeader={true}
      disableFooter={false}
      footerButtons={[
        {
          label: 'Save Phone Number',
          onClick: handleSave,
          backgroundColor: theme.palette.stationPurple[500],
          disabled,
        },
      ]}
      sxProps={{
        '.MuiDrawer-paper': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          px: 0.5,
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <PhoneInput handleChange={(value) => setPhoneValue(value)} phone={phoneValue} />
      </Box>
    </GenericDrawer>
  );
};
