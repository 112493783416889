import { Button, Box, useTheme, Typography, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { ChevronDownIcon20 } from '../../../assets';
import { useAuthUserCapabilities } from '../../../utils/capabilities';
import { useLoadedDepartmentInfoContext } from '../../Department';

interface ActionButtonProps {
  requestOvertimeDisabled?: boolean;
  handleRequestOvertimeClick?: () => void;
  handleCreateIncidentClick?: () => void;
  createIncident: boolean;
  setCreateIncident: (value: boolean) => void;
  setCreateIncidentOpen?: (value: boolean) => void;
  dropdownDisabled?: boolean;
}

export const ActionButton = ({
  dropdownDisabled = false,
  requestOvertimeDisabled,
  handleRequestOvertimeClick,
  handleCreateIncidentClick,
  createIncident,
  setCreateIncident,
  setCreateIncidentOpen,
}: ActionButtonProps) => {
  const theme = useTheme();
  const capabilities = useAuthUserCapabilities();
  const incidentReportAdminEnabled = capabilities.INCIDENT_REPORT_ADMIN;
  const dropdownEnabled = !dropdownDisabled && incidentReportAdminEnabled;

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isOptOut = departmentInfoState.departmentInfo.settings.voluntaryOvertimeOptOut;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        data-cy="request-overtime-button"
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '40px',
          padding: dropdownEnabled ? '9px 0px 9px 17px' : '9px 17px',
          backgroundColor: theme.palette.stationPrimary[400],
          color: theme.palette.common.black,
          borderRadius: 2,
          '&: hover': {
            backgroundColor: theme.palette.stationPrimary[400],
          },
        }}
        disabled={createIncident ? false : requestOvertimeDisabled}
        onClick={createIncident ? handleCreateIncidentClick : handleRequestOvertimeClick}
      >
        <Typography variant="buttonM">
          {createIncident ? 'Create incident' : isOptOut ? 'Mark unavailable' : 'Request overtime'}
        </Typography>
        {dropdownEnabled && (
          <Box
            sx={(theme) => ({
              ml: theme.spacing(2),
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              borderLeft: `1px solid ${theme.palette.common.white}`,
              padding: '9px 10px',
              '& svg path': {
                stroke: requestOvertimeDisabled ? theme.palette.stationGray[300] : theme.palette.common.black,
              },
            })}
            onClick={handleClick}
          >
            <ChevronDownIcon20 />
          </Box>
        )}

        <Menu
          id="avatar-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            key={`Request`}
            onClick={(event) => {
              setCreateIncident(false);
              handleClose(event);
            }}
            sx={{
              typography: 'bodySMRegular',
            }}
          >
            {isOptOut ? 'Mark unavailable' : 'Request overtime'}
          </MenuItem>
          <MenuItem
            key={`Incident`}
            onClick={(event) => {
              setCreateIncident(true);
              handleClose(event);
              setCreateIncidentOpen && setCreateIncidentOpen(true);
            }}
            sx={{
              typography: 'bodySMRegular',
            }}
          >
            Create incident
          </MenuItem>
        </Menu>
      </Button>
    </Box>
  );
};
