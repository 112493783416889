import { Navigate } from 'react-router-dom';
import { getDepartmentFeatureFlagValue, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { MandatoryOvertimeListMain } from './components/MandatoryOvertimeListMain';

export const MandatoryOvertimeList = () => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isStaffingListsEnabled = getDepartmentFeatureFlagValue(departmentInfoState, 'STAFFING_LISTS', false);
  if (isStaffingListsEnabled) {
    return <Navigate to="/app/my-staffing-lists" replace={true} />;
  }

  return <MandatoryOvertimeListMain />;
};
