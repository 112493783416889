import { Box } from '@mui/material';
import { StationwiseTextLogo } from '../../../assets';
import { Logo } from '../../Logo';

interface LogoWrapperProps {
  mb?: number;
  top?: number;
}
export const LogoWrapper = ({ mb, top }: LogoWrapperProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        position: top ? 'absolute' : 'relative',
        top: top ? top : 0,
        mb: mb ? mb : 0,
      }}
    >
      <Logo />
      <StationwiseTextLogo />
    </Box>
  );
};
