import { Box, Button, Divider, Drawer, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { ChevronRightIcon24, NoShiftTrades, theme } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { Employee, ShiftTradesView } from '@stationwise/share-types';
import { DetailsModal } from './DetailsModal';
interface TradeLedgerDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  employees: Employee[];
  employeeId: string;
}
export const TradeLedgerDrawer = ({ isOpen, onClose, employees, employeeId }: TradeLedgerDrawerProps) => {
  const employee = employees.find((emp) => emp.id === employeeId);
  const [tradeEmployee, setTradeEmployee] = useState<string | undefined>();
  const [tradeEmployeeId, setTradeEmployeeId] = useState<number | undefined>();

  const [dataShiftTrades, setDataShiftTrades] = useState<ShiftTradesView | null>();
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const fetchShiftTrades = async () => {
    try {
      const response = await client.get(`/employee/shift-trades/?employee_id=${employeeId}`);
      if (response.data) {
        setDataShiftTrades(response.data);
      }
    } catch (error) {
      captureException(error);
    }
  };

  useEffect(() => {
    fetchShiftTrades();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

  return (
    <Box>
      <Drawer anchor={'right'} open={isOpen} onClose={onClose} PaperProps={{ sx: { borderRadius: '0px', width: '24rem' } }}>
        <Box
          sx={(theme) => ({ padding: theme.spacing(2), display: 'flex', justifyContent: 'space-between', alignItems: 'center' })}
        >
          <Typography variant="bodyMSemibold">Trade Ledger</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Divider orientation="vertical" flexItem sx={(theme) => ({ marginRight: theme.spacing(2) })} />
            <Box onClick={onClose} sx={{ cursor: 'pointer' }}>
              <ChevronRightIcon24 />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={(theme) => ({ pl: theme.spacing(2), pt: theme.spacing(4) })}>
          <Typography variant="h4" color={theme.palette.stationGray[600]}>
            {employee?.name}
          </Typography>
        </Box>
        <Box sx={(theme) => ({ px: theme.spacing(2), pt: theme.spacing(2) })}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '40%', padding: '30px 16px' }}>
              <Typography variant="tagline1" sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
                BALANCE
              </Typography>
            </Box>
            <Typography variant="tagline1" sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
              NAME
            </Typography>
          </Box>
          <Divider />
        </Box>
        <Box>
          <Box sx={(theme) => ({ display: 'flex', flexDirection: 'column', px: theme.spacing(2) })}>
            {dataShiftTrades?.balance.length === 0 && (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: '44px',
                })}
              >
                <NoShiftTrades />
                <Typography
                  variant="bodyXXLSemibold"
                  sx={(theme) => ({ color: theme.palette.stationGray[900], mt: theme.spacing(4) })}
                >
                  No shift trades
                </Typography>
                <Typography
                  variant="bodyMRegular"
                  sx={(theme) => ({ color: theme.palette.stationGray[500], mt: theme.spacing(2), textAlign: 'center' })}
                >
                  There is no shift traded or manually <br /> added for this person yet.
                </Typography>
              </Box>
            )}
            {dataShiftTrades?.balance.map((shiftTrade, index) =>
              shiftTrade.owedHours && shiftTrade.owedHours > 0 ? (
                <React.Fragment key={index}>
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '24px',
                      m: theme.spacing(2),
                    })}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        variant="bodyXSRegular"
                        sx={{ color: shiftTrade.owesMe ? theme.palette.success.main : theme.palette.error.main }}
                      >
                        {shiftTrade.owesMe ? 'is owed' : 'owes'}
                      </Typography>
                      <Typography variant="bodyMSemibold">{shiftTrade.owedHours.toFixed(2)}h</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '60%' }}>
                      <Typography variant="bodySRegular" sx={(theme) => ({ color: theme.palette.stationGray[900] })}>
                        {shiftTrade.owesMe ? 'by' : 'to'} {shiftTrade.employeeName}
                      </Typography>
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => {
                          setTradeEmployee(shiftTrade.employeeName);
                          setTradeEmployeeId(shiftTrade.employeeId);
                          setDetailsModalOpen(true);
                        }}
                      >
                        Details
                      </Button>
                    </Box>
                  </Box>
                  <Divider />
                </React.Fragment>
              ) : null,
            )}
          </Box>
        </Box>
      </Drawer>
      <DetailsModal
        detailsOpen={detailsModalOpen}
        setDetailsOpen={setDetailsModalOpen}
        selectedEmployee={employee}
        tradeEmployee={tradeEmployee}
        tradeEmployeeId={tradeEmployeeId}
        onRefetch={fetchShiftTrades}
      />
    </Box>
  );
};
