import { Box, Typography } from '@mui/material';
import { ChevronUpIcon16, theme, XCloseIcon16 } from '@stationwise/component-module';

interface ThumbBarWrapperProps {
  onClick: (open: boolean) => void;
  isOpen?: boolean;
}
export const ThumbBarWrapper = ({ onClick, isOpen }: ThumbBarWrapperProps) => {
  return (
    <Box
      onClick={() => {
        onClick(isOpen ?? false);
      }}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={(theme) => ({
          position: 'absolute',
          top: '-16px',
          left: '50%',
          transform: 'translateX(-50%)',
          borderRadius: '24px',
          border: `1px solid ${theme.palette.stationGray[300]}`,
          backgroundColor: theme.palette.common.white,
          gap: theme.spacing(1),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '32px',
          padding: '9px 11px 9px 13px',
        })}
      >
        <Typography color={theme.palette.stationGray[700]} variant="buttonM">
          {isOpen ? 'Close' : 'See all events'}
        </Typography>
        {isOpen ? <XCloseIcon16 /> : <ChevronUpIcon16 color={theme.palette.stationGray[500]} />}
      </Box>
    </Box>
  );
};
