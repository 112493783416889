import { DetailCode, PayCode, LoggedInEmployeeDataView, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import { IShiftSummaryHelper } from '../types';
import { copyBoardWithNewApparatus } from './apparatus';

export const checkIsPlannedEmployee = (shiftSummaryHelper: IShiftSummaryHelper, employee: Pick<RosterEmployee, 'id'>) => {
  const plannedAssignments = shiftSummaryHelper.plannedAssignments.get(employee.id);
  if (!plannedAssignments) {
    return false;
  }

  return plannedAssignments.some((assignment) =>
    shiftSummaryHelper.currentTeams.some((team) => team.id === assignment.shiftTeamId),
  );
};

export const getBoardEmployees = ({ allStationCards, unassignedCards }: IShiftSummaryHelper) => {
  const employees: RosterEmployee[] = [];
  for (const station of allStationCards.values()) {
    for (const apparatus of station.apparatuses) {
      for (const position of apparatus.positions) {
        for (const employee of position.employees) {
          employees.push(employee);
        }
      }
    }
  }
  for (const employee of unassignedCards) {
    employees.push(employee);
  }
  return employees;
};

export const getBoardEmployee = ({ allStationCards, unassignedCards }: IShiftSummaryHelper, employeeActiveId: string) => {
  for (const station of allStationCards.values()) {
    for (const apparatus of station.apparatuses) {
      let positionIndex = 0;
      for (const position of apparatus.positions) {
        const employeeIndex = position.employees.findIndex((employee) => employee.activeId === employeeActiveId);
        const employee = position.employees[employeeIndex];
        if (employee) {
          return { station, apparatus, position, positionIndex, employee, employeeIndex };
        }
        positionIndex += 1;
      }
    }
  }

  const employeeIndex = unassignedCards.findIndex((e) => e.activeId === employeeActiveId);
  if (employeeIndex >= 0) {
    const employee = unassignedCards[employeeIndex];
    return { station: null, apparatus: null, position: null, positionIndex: -1, employee, employeeIndex };
  }

  return { station: null, apparatus: null, position: null, positionIndex: -1, employee: null, employeeIndex: -1 };
};

export const getBoardEmployeeNoteOverride = (shiftSummaryHelper: IShiftSummaryHelper, employeeId: string) => {
  const employees = getBoardEmployees(shiftSummaryHelper).filter((e) => e.id === employeeId);
  return employees[0]?.noteOverride ?? null;
};

export const getOverrideEmployeePositionFields = () => ({ noteOverride: null, trade: null });

export const makePlaceholderEmployee = (position: RosterPosition, employee: RosterEmployee) => ({
  ...employee,
  id: '',
  activeId: '',
  name: '',
  rank: position.rank,
  certifications: [],
  team: null,
  payCodes: [],
  detailCodes: [],
  ...getOverrideEmployeePositionFields(),
});

/**
 * Return a new `shiftSummaryHelper` such that `employee`
 * can be shallow-mutated without affecting the original `shiftSummaryHelper`.
 */
export const copyBoardWithNewEmployee = (shiftSummaryHelper: IShiftSummaryHelper, employeeActiveId: string) => {
  const { apparatus, positionIndex, employeeIndex, employee } = getBoardEmployee(shiftSummaryHelper, employeeActiveId);
  if (!employee) {
    throw new Error('Cannot copy board with new employee');
  }

  if (!apparatus) {
    const newUnassignedCards = [...shiftSummaryHelper.unassignedCards];
    const newEmployee = { ...employee };
    newUnassignedCards.splice(employeeIndex, 1, newEmployee);
    const newShiftSummaryHelper = { ...shiftSummaryHelper, unassignedCards: newUnassignedCards };
    return { newShiftSummaryHelper, newEmployee };
  }

  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(shiftSummaryHelper, apparatus.id);
  const newPosition = newApparatus.positions[positionIndex];
  const newEmployee = { ...newPosition.employees[employeeIndex] };
  newPosition.employees[employeeIndex] = newEmployee;
  const newShiftSummaryHelper = { ...shiftSummaryHelper, allStationCards: newAllStationCards };
  return { newShiftSummaryHelper, newEmployee };
};

export const updateBoardEmployeeNote = (
  shiftSummaryHelper: IShiftSummaryHelper,
  note: string,
  employeeId: string,
  overriddenBy: LoggedInEmployeeDataView,
) => {
  const now = new Date();
  const employees = getBoardEmployees(shiftSummaryHelper).filter((e) => e.id === employeeId);

  let newShiftSummaryHelper = { ...shiftSummaryHelper };
  employees.forEach((employee) => {
    const newBoard = copyBoardWithNewEmployee(newShiftSummaryHelper, employee.activeId);
    if (!note) {
      newBoard.newEmployee.noteOverride = null;
    } else {
      newBoard.newEmployee.noteOverride = {
        note,
        noteUpdatedAt: now.toISOString(),
        overrideBy: {
          id: overriddenBy.id,
          name: `${overriddenBy.firstName} ${overriddenBy.lastName}`,
          rank: overriddenBy.rank,
        },
      };
    }

    newShiftSummaryHelper = newBoard.newShiftSummaryHelper;
  });

  return newShiftSummaryHelper;
};

export const overrideBoardEmployeePayCodes = (
  shiftSummaryHelper: IShiftSummaryHelper,
  payCodes: PayCode[],
  detailCodes: DetailCode[],
  note: string,
  employeeActiveId: string,
  overriddenBy: LoggedInEmployeeDataView,
) => {
  const newBoard = copyBoardWithNewEmployee(shiftSummaryHelper, employeeActiveId);
  newBoard.newEmployee.payCodes = payCodes;
  const newShiftSummaryHelper = modifyBoardEmployeeDetailCodes(newBoard.newShiftSummaryHelper, detailCodes, employeeActiveId);
  return updateBoardEmployeeNote(newShiftSummaryHelper, note, newBoard.newEmployee.id, overriddenBy);
};

export const modifyBoardEmployeeDetailCodes = (
  shiftSummaryHelper: IShiftSummaryHelper,
  detailCodes: DetailCode[],
  employeeActiveId: string,
) => {
  const { newShiftSummaryHelper, newEmployee } = copyBoardWithNewEmployee(shiftSummaryHelper, employeeActiveId);
  newEmployee.detailCodes = Array.from(new Map(detailCodes.map((detailCode) => [detailCode.id, detailCode])).values());
  return newShiftSummaryHelper;
};
