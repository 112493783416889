import { useState } from 'react';
import { GenericDrawer } from '@stationwise/component-module';
import { PayrollData } from '@stationwise/share-types';
import { formatTimePeriod } from '@stationwise/share-utils';
import { PayrollValidationModalContent } from './PayrollValidationModalContent';
import { PayrollValidationModalFooter } from './PayrollValidationModalFooter';
import { PayrollValidationWarningModal } from './PayrollValidationWarningModal';

interface PayrollValidationModalModalProps {
  setShowPayrollValidationModal: React.Dispatch<React.SetStateAction<boolean>>;
  showPayrollValidationModal: boolean;
  departmentName: string;
  userName: string;
  startDate: string | undefined;
  endDate: string | undefined;
  payPeriodId: number | undefined;
  setRefetchCounter: React.Dispatch<React.SetStateAction<number>>;
  refetchCounter: number;
  days: Date[];
  payroll: PayrollData;
}

export const PayrollValidationModal = ({
  setShowPayrollValidationModal,
  showPayrollValidationModal,
  departmentName,
  userName,
  startDate,
  endDate,
  payPeriodId,
  setRefetchCounter,
  refetchCounter,
  days,
  payroll,
}: PayrollValidationModalModalProps) => {
  const closeDrawer = () => {
    setShowPayrollValidationModal(false);
  };
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [openPayrollValidationWarningModal, setOpenPayrollValidationWarningModal] = useState(false);
  const formattedDate = startDate && endDate ? formatTimePeriod(startDate, endDate) : '';
  return (
    <GenericDrawer
      anchor="bottom"
      drawerOpen={showPayrollValidationModal}
      handleOnClose={closeDrawer}
      loading={false}
      showHeader={true}
      disableFooter={true}
      headerTitle="Review and sign your time card"
      noBorderOnHeader={true}
    >
      <>
        <PayrollValidationModalContent
          userName={userName}
          setIsButtonActive={setIsButtonActive}
          formattedDate={formattedDate}
          days={days}
          payroll={payroll}
        />
        <PayrollValidationModalFooter
          departmentName={departmentName}
          userName={userName}
          isButtonActive={isButtonActive}
          setOpenPayrollValidationWarningModal={setOpenPayrollValidationWarningModal}
          payPeriodId={payPeriodId}
          setOpenPayrollValidationModal={setShowPayrollValidationModal}
          setRefetchCounter={setRefetchCounter}
          refetchCounter={refetchCounter}
        />
        <PayrollValidationWarningModal
          setOpenPayrollValidationWarningModal={setOpenPayrollValidationWarningModal}
          openPayrollValidationWarningModal={openPayrollValidationWarningModal}
          setOpenPayrollValidationModal={setShowPayrollValidationModal}
          payPeriodId={payPeriodId}
        />
      </>
    </GenericDrawer>
  );
};
