import { IShiftSummaryHelper, RemoveEmployeeAvailabilityProps } from '../types';
import { sortUnassignedCards } from '../unassigned';
import { resolveAllEmployeeOverlaps, resolveOneEmployeeOverlap } from './overlap';

type PropNames = 'shiftSummaryHelper' | 'employeeId' | 'startTime' | 'endTime' | 'resolveOverlap';

export const removeUnassignedEmployeeAvailability = <T extends IShiftSummaryHelper>({
  shiftSummaryHelper,
  employeeId,
  startTime,
  endTime,
  resolveOverlap = resolveOneEmployeeOverlap,
}: Pick<RemoveEmployeeAvailabilityProps<T>, PropNames>): T => {
  const newShiftSummaryHelper = { ...shiftSummaryHelper };
  newShiftSummaryHelper.unassignedCards = resolveAllEmployeeOverlaps(
    newShiftSummaryHelper.unassignedCards,
    employeeId,
    startTime,
    endTime,
    resolveOverlap,
  );
  newShiftSummaryHelper.unassignedCards = sortUnassignedCards(newShiftSummaryHelper.unassignedCards);
  return newShiftSummaryHelper;
};
