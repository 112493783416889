import { EventInput, EventSourceFuncArg } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Modal, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { client } from '@stationwise/share-api';
import { AllowedColors, GetMyScheduledCalendarDataView, RosterEmployee } from '@stationwise/share-types';
import { Star05Icon16, XCloseIcon24 } from '../../assets';
import { Badge, getColor } from '../Badge';
import { CalendarShiftCard } from '../CalendarShiftCard';
import { Calendar, SHIFT_TITLES, STATUS_TITLES, getDisplayOptionByName } from '../EmployeeCalendar';
import { formatDate, isAdditionalPaidTimeEvent, /*isFutureDate,*/ parseDateParam } from '../EmployeeCalendar/calendarHelper';
import { FlagIcon } from '../FlagIcon';
import { theme } from '../ThemeProvider';
interface Props {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  modalOpen: boolean;
  employee: RosterEmployee;
}

export const fetchEvents = async (dateInterval: {
  startDate: string;
  endDate: string;
  employeeId: string;
}): Promise<GetMyScheduledCalendarDataView[]> => {
  try {
    const params = dateInterval;
    const response = await client.get('/employee/scheduled-calendar-data/', {
      params,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const CalendarModal = ({ setModalOpen, modalOpen, employee }: Props) => {
  const [todayEvents, setTodayEvents] = useState<EventInput[]>([]);
  const [searchParams] = useSearchParams();
  const [selectedDate, setSelectedDate] = useState(() => parseDateParam(searchParams.get('date') || ''));
  const [selectedView, setSelectedView] = useState(() => getDisplayOptionByName(searchParams.get('display') || '').value);
  const [refetchEvents, setRefetchEvents] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedEvent, setSelectedEvent] = useState<EventInput | EventImpl>({});
  const [calendarHeight, setCalendarHeight] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const calculateCalendarHeight = () => {
      const windowHeight = window.innerHeight;
      const fixedBoxHeight = 140;
      const heightLeft = windowHeight - fixedBoxHeight - 179;
      setCalendarHeight(heightLeft);
    };

    calculateCalendarHeight();

    window.addEventListener('resize', calculateCalendarHeight);

    return () => window.removeEventListener('resize', calculateCalendarHeight);
  }, []);

  const displayDayEvents = (dayEvents: EventInput[]) => {
    setTodayEvents(dayEvents);
  };

  const getDateIntervalEvents = (fetchInfo: EventSourceFuncArg) => {
    const formattedStartDate = formatDate(fetchInfo.start);
    const formattedEndDate = formatDate(fetchInfo.end);
    return fetchEvents({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      employeeId: employee.id,
    });
  };

  const getStatus = (status: string) => {
    switch (status) {
      case STATUS_TITLES.ADMIN_APPROVED:
        return 'Approved';
      case STATUS_TITLES.ADMIN_DENIED:
        return 'Denied';
      case STATUS_TITLES.USER_CANCELLED:
        return 'Cancelled';
      default:
        return 'Pending';
    }
  };

  const StatusBadge = ({ status }: { status: string }) => {
    return (
      <Badge
        label={status}
        isRound
        type="sm"
        color={status === 'Approved' ? 'green' : status === 'Denied' ? 'red' : 'white'}
        overrideFontSize="14px"
        isCustom={status === 'Pending'}
        customColor={status === 'Pending' ? theme.palette.stationGray[700] : 'unset'}
        customBorder={status === 'Pending' ? `1px solid ${theme.palette.stationGray[200]}` : 'none'}
      />
    );
  };

  const getStatusBadge = (event: EventInput) => {
    const status = getStatus(event['status']);
    return <StatusBadge status={status} />;
  };

  return (
    <Modal open={modalOpen}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '16px',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            width: '428px',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '16px',
          })}
        >
          <Box
            sx={(theme) => ({
              py: '10px',
              color: theme.palette.stationPurple[600],
              backgroundColor: theme.palette.stationPurple[100],
              height: '40px',
              width: '100%',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            })}
          >
            <Box sx={(theme) => ({ display: 'flex', pl: theme.spacing(3), alignItems: 'center', gap: '8px' })}>
              <Star05Icon16 />
              <Typography variant="bodySMedium">Viewing as admin</Typography>
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: theme.palette.stationGray[400],
                pr: '10px',
                '&:hover': { color: theme.palette.stationGray[500] },
              })}
              onClick={(event) => {
                event.stopPropagation();
                setModalOpen(!modalOpen);
              }}
            >
              <XCloseIcon24 />
            </Box>
          </Box>

          <Box
            sx={(theme) => ({
              pt: theme.spacing(3),
              px: theme.spacing(3),
              mb: theme.spacing(2),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            })}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Badge
                label={employee.rank.code}
                isRound
                type="sm"
                isCustom
                customColor={getColor(theme, (employee.rank.color as AllowedColors) || '').color}
                customBackgroundColor={getColor(theme, (employee.rank.color as AllowedColors) || '').background}
                variant="bodyXSMedium"
              />
              <Typography sx={(theme) => ({ ml: theme.spacing(1) })} variant="bodyXLSemibold">
                {employee.name}
              </Typography>
            </Box>
            <Box display="flex" sx={{ display: 'flex', alignItems: 'center' }}>
              <FlagIcon height={theme.spacing(3)} width={theme.spacing(3)} color={employee.team?.color} showBorder />
              <Typography
                variant="bodySMedium"
                sx={(theme) => ({
                  color: theme.palette.stationGray[500],
                  ml: theme.spacing(1),
                })}
              >
                {employee.team?.name}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              touchAction: 'unset',
              height: '100%',
            }}
          >
            <Calendar
              displayDayEvents={displayDayEvents}
              getDateIntervalEvents={getDateIntervalEvents}
              selectedDate={selectedDate}
              selectedView={selectedView}
              refetchEvents={refetchEvents}
              setRefetchEvents={setRefetchEvents}
              setSelectedDate={setSelectedDate}
              setSelectedEvent={setSelectedEvent}
              setSelectedView={setSelectedView}
              heightOfCalendar={calendarHeight}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              viewingPersonalCalendar
            />
            <Box
              sx={(theme) => {
                const defaultStyles = {
                  backgroundColor: theme.palette.stationGray[100],
                  pt: todayEvents.length === 0 ? 0 : theme.spacing(1),
                  borderBottomLeftRadius: '16px',
                  borderBottomRightRadius: '16px',
                  height: todayEvents.length > 1 ? '120px' : '112px',
                };
                return defaultStyles;
              }}
            >
              {(() => {
                if (todayEvents.length === 1 && todayEvents[0].title === SHIFT_TITLES.OFF_TRADE) {
                  return <CalendarShiftCard key={todayEvents[0].id} shift={todayEvents[0]} />;
                }

                if (todayEvents.every(isAdditionalPaidTimeEvent) && !isLoading) {
                  return (
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        padding: '16px 12px',
                        flexDirection: 'column',
                        height: '112px',
                        backgroundColor: theme.palette.common.white,
                        width: '100%',
                        gap: theme.spacing(3),
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderBottomLeftRadius: '16px',
                        borderBottomRightRadius: '16px',
                      })}
                    >
                      <Typography variant="bodyLSemibold" sx={(theme) => ({ color: theme.palette.stationGray[900] })}>
                        No shift on this day
                      </Typography>
                    </Box>
                  );
                }

                return (
                  <Box
                    sx={{
                      height: todayEvents.length > 1 ? '106px' : '96px',
                      width: '100%',
                      overflowY: todayEvents.length > 1 ? 'scroll' : 'visible',
                    }}
                  >
                    {todayEvents.map((shift) => {
                      let statusBadge = null;
                      if (
                        shift.title === SHIFT_TITLES.ADDITIONAL_PAID_TIME ||
                        shift.title === SHIFT_TITLES.ADDITIONAL_PAID_TIME_REQUEST
                      ) {
                        statusBadge = getStatusBadge(shift);
                      }
                      return (
                        <Box key={shift.id} sx={{ height: '96px', width: '100%' }}>
                          <CalendarShiftCard shift={shift} statusBadge={statusBadge} />
                        </Box>
                      );
                    })}
                  </Box>
                );

                return null;
              })()}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
