import { Box } from '@mui/material';
import { EmployeeOffView } from '@stationwise/share-types';
import { Header } from './Header';
import { SwappedEmployees } from './SwappedEmployees';

export const AbsenceCard = ({ employeeOffData }: { employeeOffData: EmployeeOffView }) => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.stationGray[50],
        borderRadius: theme.spacing(2.5),
        border: `1px solid ${theme.palette.stationGray[200]}`,
        mb: theme.spacing(2),
      })}
    >
      <Header employeeOffData={employeeOffData} />
      <SwappedEmployees employeeOffData={employeeOffData} />
    </Box>
  );
};
