import { Box, CircularProgress } from '@mui/material';
import { Button } from '../../Button';

interface ConversationButtonsProps {
  handleSubmitClick: () => void;
  loading: boolean;
  handleOnCloseDrawer: () => void;
  disableSubmit: boolean;
  isFollowUp: boolean;
  rosterMessage?: boolean;
}
export const ConversationButtons = ({
  handleSubmitClick,
  handleOnCloseDrawer,
  loading,
  disableSubmit,
  isFollowUp,
  rosterMessage,
}: ConversationButtonsProps) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          mr: 1,
          position: 'absolute',
          bottom: '14px',
          right: rosterMessage ? '154px' : isFollowUp ? '8px' : '82px',
          zIndex: theme.zIndex.mobileStepper,
          borderRadius: '6px',
        })}
      >
        <Button
          data-cy="send-button"
          buttonSize="xs"
          buttonType="primary"
          disabled={disableSubmit}
          sx={() => ({ height: '28px' })}
          onClick={handleSubmitClick}
        >
          <Box component="span" sx={{ display: 'flex', alignItems: 'center', typography: 'buttonM' }}>
            {loading ? <CircularProgress color="inherit" size="16px" /> : 'Send'}
          </Box>
        </Button>
      </Box>
      {!isFollowUp && (
        <Box
          sx={(theme) => ({
            mr: 1,
            position: 'absolute',
            bottom: '14px',
            right: rosterMessage ? '80px' : '8px',
            zIndex: theme.zIndex.mobileStepper,
            borderRadius: '6px',
          })}
        >
          <Button
            data-cy="cancel-button"
            buttonSize="xs"
            buttonType="tertiary"
            sx={() => ({ height: '28px' })}
            onClick={handleOnCloseDrawer}
          >
            <Box component="span" sx={{ display: 'flex', alignItems: 'center', typography: 'buttonM' }}>
              Cancel
            </Box>
          </Button>
        </Box>
      )}
    </>
  );
};
