import { Box } from '@mui/material';
import { Modal } from '@stationwise/component-module';
import { WarningModal } from '../../../../components/Common';

export const ShiftTemplateWarningModal = ({
  onConfirm,
  primaryMessage,
  warningModalOpen,
  setWarningModalOpen,
  secondaryMessage,
  hideButtons,
}: {
  onConfirm: () => void;
  primaryMessage: string;
  warningModalOpen: boolean;
  setWarningModalOpen: (open: boolean) => void;
  secondaryMessage?: string;
  hideButtons?: boolean;
}) => {
  return (
    <Modal open={warningModalOpen} setOpen={setWarningModalOpen}>
      <WarningModal setModalOpen={setWarningModalOpen} onConfirm={onConfirm} hideButtons={hideButtons}>
        <Box sx={(theme) => ({ width: '416px', mb: theme.spacing(3) })}>
          <Box sx={{ typography: 'bodyXXLSemibold' }}>{primaryMessage}</Box>
          <Box sx={(theme) => ({ typography: 'bodyMRegular', mt: theme.spacing(2) })}>{secondaryMessage}</Box>
        </Box>
      </WarningModal>
    </Modal>
  );
};
