import { StartTime } from '@stationwise/share-types';

export const parseHourAndMinute = (time: string): [number, number] => {
  const [hourString, minuteString] = time.split(':');
  const hour = parseInt(hourString, 10);
  const minute = parseInt(minuteString, 10);
  return [hour, minute];
};

export const generateStartTimes = (initialHour: string, duration?: number): StartTime[] => {
  const [initialHourNumber, initialMinuteNumber] = parseHourAndMinute(initialHour);

  const intervals = [0, 4, 8, 12, 16, 20, 24].filter((hours) => (duration ? hours < duration : hours));
  const startTimes: StartTime[] = [];

  intervals.forEach((interval) => {
    const hour = (initialHourNumber + interval) % 24;
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
    const formattedMinute = initialMinuteNumber < 10 ? `0${initialMinuteNumber}` : `${initialMinuteNumber}`;

    startTimes.push({
      label: `${formattedHour}:${formattedMinute}`,
      value: `${formattedHour}:${formattedMinute}`,
    });
  });

  return startTimes;
};
