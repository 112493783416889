import { Box, useTheme } from '@mui/material';
import React from 'react';
import { AvatarEmail, Checkbox, getShiftColor } from '../../../../components';
import { RecipientChip } from '../RecipientChip';

export interface SelectContactOptionProps {
  id?: number[];
  category?: string;
  chipBackgroundColor?: string;
  chipLabel: string;
  name: string;
  email: string;
  userImg?: string;
  onClick?: () => void;
  checked?: boolean;
  memberIds?: string[];
  filter?: string;
  count?: number;
}

export const SelectContactOption = ({
  chipBackgroundColor,
  chipLabel,
  name,
  email,
  userImg,
  onClick,
  checked,
}: SelectContactOptionProps) => {
  const theme = useTheme();
  const chipBg = chipBackgroundColor ? chipBackgroundColor : 'gray';
  return (
    <Box
      data-cy={`recipient-option-${name.toLowerCase().replace(' ', '-')}`}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: theme.spacing(2),
        })}
      >
        <Checkbox checked={checked} onClick={onClick} />
        <AvatarEmail name={name} email={email} userImg={userImg} />
      </Box>
      <RecipientChip backgroundColor={getShiftColor(chipBg, theme)} label={chipLabel} />
    </Box>
  );
};
