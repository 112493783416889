import { useTheme } from '@mui/material';
import { Badge } from '@stationwise/component-module';

export const StatusBadge = ({ status }: { status: string }) => {
  const theme = useTheme();

  return (
    <Badge
      label={status}
      isRound
      type="sm"
      color={status === 'Approved' ? 'green' : status === 'Denied' ? 'red' : 'white'}
      overrideFontSize="14px"
      isCustom={status === 'Pending' || status === 'On waitlist'}
      customColor={status === 'Pending' || status === 'On waitlist' ? theme.palette.stationGray[700] : 'unset'}
      customBorder={status === 'Pending' || status === 'On waitlist' ? `1px solid ${theme.palette.stationGray[200]}` : 'none'}
    />
  );
};
