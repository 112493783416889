import { Box } from '@mui/material';
import { HireBackCard } from '@stationwise/component-module';
import { OvertimeInfo } from '../../../MandatoryOvertimeList/overtimeListHelper';

export const OvertimeList = ({ overtimeInfo }: { overtimeInfo: OvertimeInfo }) => {
  return (
    <>
      {overtimeInfo.hireBackList.map((hireback) => (
        <Box
          key={hireback.employee.id}
          sx={(theme) => ({
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
            border: `solid 1px ${theme.palette.stationGray[50]}`,
          })}
        >
          <HireBackCard
            badgeTitle={hireback.employee.rank.name}
            nameLabel={`${hireback.employee.firstName} ${hireback.employee.lastName}`}
            certs={hireback.employee.certifications}
            number={hireback.order}
            partialHoursOt={hireback.overtime.partialHours ? `${hireback.overtime.partialHours}` : ''}
            lastShiftOt={hireback.overtime.lastShift || ''}
            timeAsString={hireback.overtime.lastShift ? hireback.overtime.lastOtStaffTime : ''}
            type="border"
            color={hireback.employee.rank.color}
            borderColor={hireback.employee.employeeTeam?.color || 'white'}
          />
        </Box>
      ))}
    </>
  );
};
