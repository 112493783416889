import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { makeTestIdentifier } from '@stationwise/share-utils';

export const CalendarDisplayOptions = ({ calendarOptions }: { calendarOptions: string[] }) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const display = searchParams.get('display');

    if (!display) {
      setSelectedOptionIndex(0);
    } else {
      const selectedIndex = calendarOptions.findIndex((option) => option.toLowerCase() === display);
      setSelectedOptionIndex(selectedIndex);
    }
  }, [searchParams, calendarOptions]);

  const handleSelectOption = (index: number) => {
    setSelectedOptionIndex(index);
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.set('display', calendarOptions[index].toLowerCase());
      return newSearchParams;
    });
  };

  return (
    <>
      {calendarOptions.map((displayOption, index) => (
        <Box
          data-cy={`calendar-display-option-${makeTestIdentifier(displayOption)}`}
          onClick={() => handleSelectOption(index)}
          key={displayOption}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={(theme) => ({
            width: '40px',
            height: '40px',
            borderRadius: '8px',
            pt: '4px',
            color: index === selectedOptionIndex ? theme.palette.stationGray[900] : theme.palette.stationGray[400],
            typography: 'bodySMedium',
            fontWeight: index === selectedOptionIndex ? '600' : '500',
          })}
        >
          {displayOption[0]}
        </Box>
      ))}
    </>
  );
};
