import { Channel } from 'pusher-js';

export const PUSHER_UPDATE_MESSAGE = 'update';

export const PUSHER_EVENT_TYPES = {
  RELOAD: 'reload',
  STAFFING: 'Staffing',
  REVIEW: 'Review',
  REQUEST: 'Request',
  CANCELLATION: 'Cancellation',
  MESSAGE: 'Message',
};

const intervalRefreshPeriodMS = 60000;

interface RefreshEventInternalData {
  shiftDate: string;
}

interface refreshEventData {
  message: string;
  employeeIdList: number[];
  triggerAll: boolean;
  createdAt: string;
  data: RefreshEventInternalData | null;
}

function pushToCallbackMap<K, V>(map: Map<K, V[]>, k: K, v: V) {
  const array = map.get(k) || [];
  array.push(v);
  map.set(k, array);
}

export type RefreshEventCallback = (data: refreshEventData) => void;

export interface AbstractChannel {
  bind_many: (eventTypes: string[], callback: (data: refreshEventData) => void) => void;
  unbind_many: (eventTypes: string[]) => void;
  drop: () => void;
}

export class PusherChannelAdapter implements AbstractChannel {
  private channel: Channel;

  constructor(channel: Channel) {
    this.channel = channel;
  }

  bind_many(eventTypes: string[], callback: (data: refreshEventData) => void) {
    eventTypes.forEach((eventType) => {
      this.channel.bind(eventType, callback);
    });
  }

  unbind_many(eventTypes: string[]) {
    eventTypes.forEach((eventType) => {
      this.channel.unbind(eventType);
    });
  }

  drop() {
    this.channel.pusher.disconnect();
  }
}

export class IntervalChannel implements AbstractChannel {
  private callbacks: Map<string, ((data: refreshEventData) => void)[]> = new Map();
  private intervalId: number | null = null;

  constructor() {
    this.intervalId = window.setInterval(() => this.triggerEvents(), intervalRefreshPeriodMS);
  }

  bind_many(eventTypes: string[], callback: (data: refreshEventData) => void): void {
    eventTypes.forEach((eventType) => {
      pushToCallbackMap(this.callbacks, eventType, callback);
    });
  }

  unbind_many(eventTypes: string[]): void {
    eventTypes.forEach((eventType) => {
      this.callbacks.delete(eventType);
    });
  }

  drop(): void {
    this.callbacks = new Map();
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
    }
  }

  private triggerEvents(): void {
    const simulatedData = {
      message: PUSHER_UPDATE_MESSAGE,
      employeeIdList: [],
      triggerAll: true,
      createdAt: new Date().toISOString(),
      data: null,
    };
    this.callbacks.forEach((callbackArray) => {
      callbackArray.forEach((callback) => callback(simulatedData));
    });
  }
}
