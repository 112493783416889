import { EventInput } from '@fullcalendar/core';
import { Box, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { MenuAltIcon, HourglassIcon, Loader, formatDate } from '@stationwise/component-module';
import { OvertimeInfo } from '../../../MandatoryOvertimeList/overtimeListHelper';
import { OvertimeList } from './OvertimeList';

export const OvertimeCard = ({
  shift,
  displayOvertimeInfo,
}: {
  displayOvertimeInfo: (dateStr: string) => Promise<OvertimeInfo>;
  shift: EventInput;
}) => {
  const [overtimeInfoError, setOvertimeInfoError] = useState('');
  const [isError, setIsError] = useState(false);
  const [overtimeInfoLoading, setOvertimeInfoLoading] = useState(false);
  const [overtimeInfo, setOvertimeInfo] = useState<OvertimeInfo | null>(null);

  useEffect(() => {
    fetchOvertime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shift]);

  const fetchOvertime = () => {
    setOvertimeInfoLoading(true);
    if (shift.start) {
      const formattedDate = formatDate(new Date(shift.start.toString()));
      displayOvertimeInfo(formattedDate)
        .then((response) => {
          setOvertimeInfo(response);
          setOvertimeInfoLoading(false);
          setIsError(false);
          setOvertimeInfoError('');
        })
        .catch((err) => {
          setIsError(true);
          setOvertimeInfoError(err);
          setOvertimeInfoLoading(false);
        });
    }
  };
  return (
    <>
      {overtimeInfoLoading && !overtimeInfo && <Loader sx={{ mt: 2, mb: 1 }} />}
      {isError && <div>{`Something went wrong please try again later ${overtimeInfoError}`}</div>}
      {!overtimeInfoLoading && !isError && overtimeInfo && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={(theme) => ({
              borderTop: `solid 1px ${theme.palette.stationGray[100]}`,
              pt: '16px',
            })}
          >
            <Typography
              variant="bodyLMedium"
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
              })}
            >
              Voluntary Overtime
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex">
              <Box sx={{ marginTop: '2px' }}>
                <HourglassIcon />
              </Box>
              <Typography
                variant="bodyMMedium"
                sx={(theme) => ({
                  color: theme.palette.stationGray[900],
                  ml: '16px',
                })}
              >
                {`${overtimeInfo.shiftName} position`}
              </Typography>
            </Box>
            <Box
              display="flex"
              sx={{
                textAlign: 'right',
              }}
            >
              <Typography
                variant="bodyMSemibold"
                sx={(theme) => ({
                  color: theme.palette.stationGray[900],
                })}
              >
                {overtimeInfo && overtimeInfo.shiftPosition}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={(theme) => ({
              borderBottom: `solid 1px ${theme.palette.stationGray[100]}`,
              pb: '16px',
            })}
          >
            <Box display="flex">
              <Box sx={{ marginTop: '2px' }}>
                <MenuAltIcon />
              </Box>
              <Typography
                variant="bodyMMedium"
                sx={(theme) => ({
                  color: theme.palette.stationGray[900],
                  ml: '16px',
                })}
              >
                Overall position
              </Typography>
            </Box>
            <Box
              display="flex"
              sx={{
                textAlign: 'right',
              }}
            >
              <Typography
                variant="bodyMSemibold"
                sx={(theme) => ({
                  color: theme.palette.stationGray[900],
                })}
              >
                {overtimeInfo && overtimeInfo.overallPosition}
              </Typography>
            </Box>
          </Box>
          {overtimeInfo?.hireBackList?.length > 0 && <OvertimeList overtimeInfo={overtimeInfo} />}
        </>
      )}
    </>
  );
};
