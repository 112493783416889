import { Box } from '@mui/material';
import { SelectCategory, SelectCategoryItem } from '@stationwise/component-module';

interface MessageComposerTopBarProps {
  categories: SelectCategoryItem[];
  category: string;
  setCategory: (category: string) => void;
}

export const MessageComposerTopBar = ({ categories, category, setCategory }: MessageComposerTopBarProps) => {
  return (
    <Box
      display="flex"
      sx={(theme) => ({
        pl: theme.spacing(2),
        pr: theme.spacing(2),
        pt: '20px',
        pb: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      })}
    >
      <Box sx={(theme) => ({ typography: theme.typography.bodyLSemibold })}>New Message</Box>

      <SelectCategory
        items={categories}
        onChange={(selectedOption: string) => setCategory(selectedOption)}
        selected={category}
        isEditable={true}
      />
    </Box>
  );
};
