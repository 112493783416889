import { Box } from '@mui/material';
import { AdditionalPaidTimeView } from '@stationwise/share-types';
import { Header } from './Header';
import { Overview } from './Overview';

export interface OverviewCardProps {
  additionalPaidTime: AdditionalPaidTimeView;
  eventColor?: string;
}

export const OverviewCard = ({ additionalPaidTime, eventColor }: OverviewCardProps) => {
  return (
    <Box
      sx={(theme) => ({
        pt: theme.spacing(2),
        borderRadius: '12px',
        border: `1px solid ${theme.palette.stationGray[200]}`,
      })}
    >
      <Box sx={(theme) => ({ pb: !additionalPaidTime.detailCode ? theme.spacing(1) : 'unset' })}>
        <Header additionalPaidTime={additionalPaidTime} eventColor={eventColor} />
        {additionalPaidTime.payCode && <Overview additionalPaidTime={additionalPaidTime} />}
      </Box>
    </Box>
  );
};
