import React, { createContext, useContext, useReducer } from 'react';
import { DETAIL_CODE_MANDATORY_OT, DepartmentSettingsView, DetailCode, FeatureFlag } from '@stationwise/share-types';
import { AbstractChannel } from '@stationwise/share-utils';

export interface DepartmentInfo extends DepartmentSettingsView {
  featureFlags: FeatureFlag[];
}

export interface DepartmentInfoState {
  departmentInfo: DepartmentInfo | null;
  refreshTriggerChannel: AbstractChannel | null;
  refetchData: boolean;
}

export interface LoadedDepartmentInfoState {
  departmentInfo: DepartmentInfo;
  refreshTriggerChannel: AbstractChannel;
  refetchData: boolean;
}

const DepartmentInfoStateContext = createContext<DepartmentInfoState | null>(null);
DepartmentInfoStateContext.displayName = 'DepartmentInfoStateContext';

const DepartmentInfoUpdateContext = createContext<React.Dispatch<Action>>(() => null);
DepartmentInfoUpdateContext.displayName = 'DepartmentInfoUpdateContext';

type ActionType = 'REFETCH_DEPARTMENT_INFO' | 'SET_DEPARTMENT_INFO.SUCCESS' | 'SET_PUSHER_CHANNEL' | 'LOGOUT_USER.SUCCESS';

type BaseAction<Type extends ActionType, Payload = undefined> = Payload extends undefined
  ? { type: Type }
  : { type: Type; payload: Payload };

type Action =
  | BaseAction<'REFETCH_DEPARTMENT_INFO'>
  | BaseAction<'SET_DEPARTMENT_INFO.SUCCESS', DepartmentInfo>
  | BaseAction<'SET_PUSHER_CHANNEL', AbstractChannel>
  | BaseAction<'LOGOUT_USER.SUCCESS'>;

const reducer = (state: DepartmentInfoState, action: Action): DepartmentInfoState => {
  switch (action.type) {
    case 'REFETCH_DEPARTMENT_INFO': {
      return {
        ...state,
        refetchData: true,
      };
    }
    case 'SET_DEPARTMENT_INFO.SUCCESS': {
      return {
        ...state,
        departmentInfo: action.payload,
        refetchData: false,
      };
    }
    case 'SET_PUSHER_CHANNEL': {
      return {
        ...state,
        refreshTriggerChannel: action.payload,
        refetchData: false,
      };
    }
    case 'LOGOUT_USER.SUCCESS': {
      return {
        departmentInfo: null,
        refreshTriggerChannel: null,
        refetchData: false,
      };
    }
    default: {
      throw new Error(`Unexpected action: ${action}`);
    }
  }
};

interface Props {
  children: React.ReactNode;
  initialState?: DepartmentInfoState;
}

export function DepartmentInfoProvider({
  children,
  initialState = {
    departmentInfo: null,
    refreshTriggerChannel: null,
    refetchData: false,
  },
}: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DepartmentInfoStateContext.Provider value={state}>
      <DepartmentInfoUpdateContext.Provider value={dispatch}>{children}</DepartmentInfoUpdateContext.Provider>
    </DepartmentInfoStateContext.Provider>
  );
}

function useDepartmentInfoState() {
  const context = useContext(DepartmentInfoStateContext);
  if (!context) {
    throw new Error('useDepartmentInfoState hook must be within a DepartmentInfoProvider');
  }
  return context;
}

function useDepartmentInfoUpdater() {
  const context = useContext(DepartmentInfoUpdateContext);
  if (!context) {
    throw Error('useDepartmentInfoUpdater hook must be within a DepartmentInfoProvider');
  }
  return context;
}

export function useDepartmentInfoContext() {
  return { state: useDepartmentInfoState(), dispatch: useDepartmentInfoUpdater() };
}

export function useLoadedDepartmentInfoContext() {
  const { state, ...context } = useDepartmentInfoContext();
  if (!state.departmentInfo) {
    throw new Error('Department info is not loaded');
  }
  return { ...context, state: state as LoadedDepartmentInfoState };
}

export function getDepartmentFeatureFlagValue(state: LoadedDepartmentInfoState, name: string, defaultValue = false) {
  const flag = state.departmentInfo.featureFlags.find((flag) => flag.name === name);
  return flag ? flag.value : defaultValue;
}

export function renameMandatoryOvertimeTitle(state: LoadedDepartmentInfoState, title: string) {
  const naming = state.departmentInfo.mandatoryOvertimeNaming;
  let renamedTitle = title.replace(/Mandatory/g, naming.title);
  renamedTitle = renamedTitle.replace(/mandatory/g, naming.title.toLowerCase());
  renamedTitle = renamedTitle.replace(/MANDATORY/g, naming.title.toUpperCase());
  return renamedTitle;
}

export function renameMandatoryDetailCode<DC extends Pick<DetailCode, 'name' | 'code'>>(
  state: LoadedDepartmentInfoState,
  detailCode: DC,
): DC {
  if (detailCode.code !== DETAIL_CODE_MANDATORY_OT) {
    return detailCode;
  }

  return {
    ...detailCode,
    name: renameMandatoryOvertimeTitle(state, detailCode.name),
    code: state.departmentInfo.mandatoryOvertimeNaming.code,
  };
}
