import { Box, Grid } from '@mui/material';
import { addDays, format } from 'date-fns';
import { Dispatch, SetStateAction, useState } from 'react';
import { ChevronDownIcon20, ClockIcon20, GenericDrawer } from '@stationwise/component-module';
import { StartTime } from '@stationwise/share-types';
import { makeTestIdentifier, parseHourAndMinute } from '@stationwise/share-utils';

export const RequestStartTimes = ({
  originalStartDateTime,
  startDateTime,
  setStartDateTime,
  startOptions,
  setDuration,
  title,
}: {
  originalStartDateTime: string;
  startDateTime: string;
  setStartDateTime: Dispatch<SetStateAction<string>>;
  startOptions: StartTime[];
  setDuration: Dispatch<SetStateAction<number>>;
  title: string;
}) => {
  const [selectionDrawerOpen, setSelectionDrawerOpen] = useState<boolean>(false);
  const shiftStartTime = format(startDateTime, 'HH:mm');
  const originalStartTime = format(originalStartDateTime, 'HH');

  const handleClick = (time: StartTime) => {
    const [hour, minute] = parseHourAndMinute(time.value);

    const dateObj = new Date(originalStartDateTime);
    dateObj.setHours(hour);
    dateObj.setMinutes(minute);
    const newDateObj = hour < parseInt(originalStartTime) ? addDays(dateObj, 1) : dateObj;
    setStartDateTime(format(newDateObj, "yyyy-MM-dd'T'HH:mm:ss"));
    setDuration(0);
    setSelectionDrawerOpen(false);
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        mt: theme.spacing(1),
      })}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                border: `solid 1px ${theme.palette.stationGray[200]}`,
                p: theme.spacing(1, 1.5, 1, 1.5),
                borderRadius: '8px',
                color: theme.palette.stationGray[500],
                typography: 'bodyMRegular',
              })}
            >
              {format(startDateTime, 'MMM dd')}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Box
              data-cy="shift-trade-start-select"
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
                border: `solid 1px ${theme.palette.stationGray[200]}`,
                p: theme.spacing(1, 1.5, 1, 1.5),
                borderRadius: '8px',
                typography: 'bodyMRegular',
                '& svg': {
                  color: theme.palette.stationGray[400],
                },
              })}
              onClick={() => setSelectionDrawerOpen(true)}
            >
              {shiftStartTime}
              <ChevronDownIcon20 />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <GenericDrawer
        anchor="bottom"
        drawerOpen={selectionDrawerOpen}
        handleOnClose={() => setSelectionDrawerOpen(false)}
        loading={false}
        showHeader
        headerTitle={title}
        disableFooter
        noBorderOnHeader={true}
      >
        <Box sx={(theme) => ({ pb: theme.spacing(1) })}>
          {startOptions.map((time) => (
            <Box
              data-cy={`shift-trade-start-${makeTestIdentifier(time.label)}`}
              key={time.value}
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                p: theme.spacing(2),
              })}
              onClick={() => handleClick(time)}
            >
              <Box
                display={'flex'}
                sx={(theme) => ({
                  '& svg': {
                    '& path': {
                      fill: theme.palette.stationGray[400],
                    },
                  },
                })}
              >
                <ClockIcon20 />
              </Box>
              <Box sx={(theme) => ({ ml: theme.spacing(1), typography: 'bodyLRegular' })}>
                {time.label === '24:00' ? '00:00' : time.label}
              </Box>
            </Box>
          ))}
        </Box>
      </GenericDrawer>
    </Box>
  );
};
