import { Box, ListItemIcon, ListItemText, MenuItem, Select as MuiSelect, SelectChangeEvent } from '@mui/material';
import { ChevronDownIcon16 as ChevronDown } from '../../../assets';
import { selectStyles } from './selectCategoryStyles';

export interface SelectCategoryItem {
  label: string;
  value: string;
  icon?: React.ReactNode;
  color?: string;
}
interface SelectProps {
  items: SelectCategoryItem[];
  selected: string;
  onChange: (selectedOption: string) => void;
  isEditable: boolean;
}

export const SelectCategory = ({ items, selected, onChange, isEditable }: SelectProps) => {
  return (
    <Box sx={(theme) => ({ margin: { sx: theme.spacing(0, 2, 0, 0), sm: theme.spacing(0, 0, 0, 1) } })}>
      <MuiSelect
        value={selected}
        onChange={(event: SelectChangeEvent) => onChange(event.target.value as string)}
        MenuProps={{
          MenuListProps: {
            disablePadding: true,
          },
          disableScrollLock: true,
        }}
        variant="outlined"
        IconComponent={isEditable ? ChevronDown : () => null}
        disabled={!isEditable}
        sx={selectStyles}
      >
        {items.map((option) => {
          return (
            <MenuItem value={option.value} key={option.value}>
              {option.icon && (
                <ListItemIcon
                  style={{
                    minWidth: '18px',
                    textAlign: 'center',
                    paddingTop: '1px',
                  }}
                >
                  {option.icon}
                </ListItemIcon>
              )}

              <ListItemText
                disableTypography={true}
                sx={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                  color: option.color,
                }}
              >
                {option.label}
              </ListItemText>
            </MenuItem>
          );
        })}
      </MuiSelect>
    </Box>
  );
};
