import { Dialog } from '@mui/material';
import { useRosterContext } from '../RosterContext';
import { StrikeTeamAssignmentForm } from '../StrikeTeamAssignmentModal/StrikeTeamAssignmentForm';

export const StrikeTeamExcessCapacityModal = () => {
  const { selectedStrikeTeamApparatusState } = useRosterContext();
  const { selectedApparatus, selectedAction } = selectedStrikeTeamApparatusState;
  const onClose = selectedStrikeTeamApparatusState.resetState;

  return (
    <Dialog
      open={!!selectedApparatus?.strikeTeam.id && selectedAction === 'ADD_EMPLOYEE_TO_EVENT'}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { borderRadius: '16px', position: 'relative' } }}
    >
      {selectedApparatus && <StrikeTeamAssignmentForm apparatus={selectedApparatus} position={null} onClose={onClose} />}
    </Dialog>
  );
};
