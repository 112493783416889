import { RosterDataSource } from '@stationwise/share-types';
import { COLLISION_REGION } from '../constants';
import { setEmployeeActiveId } from '../id';
import { removeUnassignedEmployeeAvailability } from '../removeEmployeeAvailability/unassigned';
import { IShiftSummaryHelperCollisionParams } from '../types';
import { sortUnassignedCards } from '../unassigned';

export const addActiveToUnassigned = (params: IShiftSummaryHelperCollisionParams) => {
  const { over, active } = params;
  if (over.region !== COLLISION_REGION.FLOATERS_BAR) {
    throw new Error('Cannot add active to unassigned');
  }

  const newEmployee = setEmployeeActiveId({ ...active.employee, dataSource: RosterDataSource.FLOATER });

  const newUnassignedCards = sortUnassignedCards([...params.unassignedCards, newEmployee]);

  const newActive = { employee: newEmployee };
  return { ...params, newActive, unassignedCards: newUnassignedCards };
};

export const removeActiveFromUnassigned = (params: IShiftSummaryHelperCollisionParams) => {
  const { over, active } = params;
  if (active.region !== COLLISION_REGION.FLOATERS_BAR) {
    throw new Error('Cannot remove active from unassigned');
  }

  let startDateTime = active.employee.startDateTime;
  let endDateTime = active.employee.endDateTime;
  if (over.region === COLLISION_REGION.BOARD_POSITION) {
    const overStartDateTime = over.employee?.startDateTime ?? params.shiftDuration.startTime;
    const overEndDateTime = over.employee?.endDateTime ?? params.shiftDuration.endTime;
    startDateTime = overStartDateTime > active.employee.startDateTime ? overStartDateTime : active.employee.startDateTime;
    endDateTime = overEndDateTime < active.employee.endDateTime ? overEndDateTime : active.employee.endDateTime;
  }

  return removeUnassignedEmployeeAvailability({
    shiftSummaryHelper: params,
    employeeId: active.employee.id,
    startTime: startDateTime,
    endTime: endDateTime,
  });
};
