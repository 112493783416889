import { captureException } from '@sentry/react';
import { Dispatch, useState } from 'react';
import { SnackbarService, SwDialog } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { AutoHireInfoView } from '@stationwise/share-types';
import { AutoHireVacancy } from '../Vacancies/vacanciesHelper';
import { AutoHireHistory } from './AutoHireHistory';
import { AutoHireVacancies } from './AutoHireVacancies';

interface AutoHireMainProps {
  autoHireModalOpen: boolean;
  setAutoHireModalOpen: Dispatch<React.SetStateAction<boolean>>;
  isDateInPast: boolean;
  autoHireInfo: AutoHireInfoView | null;
  groupedVacancies: Record<number, AutoHireVacancy[]>;
  forceRefetch: () => void;
  selectedBattalionId: number | null | undefined;
  selectedDate: string | undefined | null;
}

export const AutoHireMain = ({
  autoHireModalOpen,
  setAutoHireModalOpen,
  isDateInPast,
  autoHireInfo,
  groupedVacancies,
  forceRefetch,
  selectedBattalionId,
  selectedDate,
}: AutoHireMainProps) => {
  const [autoHireRequestLoading, setAutoHireRequestLoading] = useState(false);
  const [rankOrders, setRankOrders] = useState<Record<number, { rankId: number; isSelected: boolean }[]>>({});
  const [vacancyFillRankOrder, setVacancyFillRankOrder] = useState<number[]>(autoHireInfo?.autoHireVacancyRankOrder || []);
  const [disabled, setDisabled] = useState(false);
  const [autoHireHistoryOpen, setAutoHireHistoryOpen] = useState<boolean>(false);
  const [autoHireHistoryRankId, setAutoHireHistoryRankId] = useState<number | null>(null);

  const handleAutoHireHistoryOpen = (rankId: number) => {
    setAutoHireHistoryRankId(rankId);
    setAutoHireHistoryOpen(true);
  };

  const handleAutoHireHistoryClose = () => {
    setAutoHireHistoryOpen(false);
  };

  const onCancel = () => setAutoHireModalOpen(false);

  const onSubmitAutoHire = async () => {
    try {
      setAutoHireRequestLoading(true);
      if (selectedDate) {
        const params = {
          shift_date: selectedDate,
          battalion_id: selectedBattalionId || 0,
          ranks_and_orders: Object.entries(rankOrders).map(([rankId, ranks]) => ({
            rank_id: Number(rankId),
            ranks_id_list: ranks.filter((rankObj) => rankObj.isSelected).map((rankObj) => rankObj.rankId),
          })),
          vacancy_fill_rank_order: vacancyFillRankOrder,
        };

        await client.post('auto-hire/', params);
        SnackbarService.notify({
          content: 'Auto Hire started successfully',
          severity: 'success',
          duration: 10000,
        });
        setAutoHireRequestLoading(false);
        forceRefetch();
      }
    } catch (error) {
      SnackbarService.notify({
        content: 'Auto Hire failed to start',
        severity: 'error',
        duration: 10000,
      });
      captureException(error);
      setAutoHireRequestLoading(false);
      console.error('An error occurred while trying to start auto hire', error);
    } finally {
      setAutoHireModalOpen(false);
    }
  };

  return (
    <SwDialog
      isOpen={autoHireModalOpen}
      onSubmit={onSubmitAutoHire}
      onCancel={onCancel}
      title="Auto Hire"
      label="Start Auto Hire"
      loading={autoHireRequestLoading}
      disabled={disabled}
      width="350px"
      displayButton={!isDateInPast}
    >
      {autoHireInfo && (
        <AutoHireVacancies
          groupedVacancies={groupedVacancies}
          rankOrders={rankOrders}
          setRankOrders={setRankOrders}
          setDisabled={setDisabled}
          autoHireInfo={autoHireInfo}
          handleAutoHireHistoryOpen={handleAutoHireHistoryOpen}
          vacancyFillRankOrder={vacancyFillRankOrder}
          setVacancyFillRankOrder={setVacancyFillRankOrder}
          isDateInPast={isDateInPast}
        />
      )}
      {autoHireInfo && autoHireHistoryRankId && (
        <AutoHireHistory
          open={autoHireHistoryOpen}
          onClose={handleAutoHireHistoryClose}
          autoHireInfo={autoHireInfo}
          rankId={autoHireHistoryRankId}
          forceRefetch={forceRefetch}
        />
      )}
    </SwDialog>
  );
};
