import { Box, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Logo,
  useLoadedAuthUserContext,
  useLogout,
  useRequests,
  Menu06Icon24 as FullMenuIcon,
  REQUEST_CARD_STATUS,
} from '@stationwise/component-module';
import { INavMenu } from '../../../app/AppPageLayout';
import { ROUTES } from '../../../core/Routes';
import { useSelectMenu } from '../../hooks/useSelectMenu';
import { DrawerMenu } from './DrawerMenu';
import { MessageNotification } from './MessageNotification';

export const calendarOptions = ['Month', 'List'];
export const calendarPaths = calendarOptions.map((option) => `${ROUTES.CALENDAR.fullRoute}?display=${option.toLowerCase()}`);

interface TopBarProps {
  fixedPosition?: boolean;
  navItems: INavMenu[];
}

export const TopBar = ({ fixedPosition = false, navItems }: TopBarProps) => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  const { state: authUserState } = useLoadedAuthUserContext();
  const logout = useLogout();

  const [refetchCounter] = useState(0);
  const { selectedMenu } = useSelectMenu();
  const requests = useRequests(refetchCounter);

  useEffect(() => {
    const pendingRequest = requests[REQUEST_CARD_STATUS.PENDING];
    pendingRequest.initialize();
  }, [requests]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleSelectItem = (item: INavMenu) => {
    if (item.type === 'logout') {
      logout();
      return;
    }

    if (item.type === 'support') {
      window.Intercom?.('show');
      return;
    }

    navigate(item.path);
  };

  return (
    <Box
      data-cy="app-top-bar"
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '56px',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.common.white,
        p: '12px 12px 12px 10px',
        position: fixedPosition ? 'fixed' : 'absolute',
        zIndex: fixedPosition ? 999 : 'none',
        top: '0px',
        borderStyle: 'dashed',
        borderColor: theme.palette.stationGray[100],
        borderWidth: '1px 0px',
      })}
    >
      <Box
        onClick={() => navigate('/')}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          my: 0.5,
          gap: 1,
        }}
      >
        <Logo size="sm" />
      </Box>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box data-testid="messages" onClick={() => navigate(ROUTES.INBOX.fullRoute)}>
          <MessageNotification
            count={authUserState.employee.unreadMessages.length + requests[REQUEST_CARD_STATUS.PENDING].data.length}
          />
        </Box>
        <Box
          sx={(theme) => ({
            ml: theme.spacing(2),
            mr: '-8px',
          })}
        >
          <IconButton
            data-cy="sidenav"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 1 }}
          >
            <FullMenuIcon />
          </IconButton>
        </Box>
        <DrawerMenu
          navItems={navItems}
          selectedMenu={selectedMenu}
          handleSelectItem={handleSelectItem}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Box>
    </Box>
  );
};
