import { Box, Tab, TabProps, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { Certification, DetailCode, PayCodeComplete, RankComplete } from '@stationwise/share-types';
import { DepartmentSettingsTab } from './DepartmentSettingsTab';
import { ElementTypes } from './DepartmentSettingsUtil';

interface CustomTabProps extends TabProps {
  label: string;
  value: number;
}

const CustomTab = React.forwardRef<HTMLDivElement, CustomTabProps>(function CustomTab(props, ref) {
  const { label, value, ...otherProps } = props;
  return (
    <Tab
      ref={ref}
      {...otherProps}
      sx={(theme) => ({
        textTransform: 'none',
        color: theme.palette.stationGray[400],
        typography: 'bodyXLMedium',
        '&.Mui-selected': {
          color: theme.palette.stationGray[900],
        },
        '&:hover': {
          color: theme.palette.stationGray[900],
        },
      })}
      label={label}
      value={value}
    />
  );
});

export const DepartmentSettingsPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [certifications, setCertifications] = useState<Certification[]>([]);
  const [selectedCertification, setSelectedCertification] = useState<Certification>();
  const [ranks, setRanks] = useState<RankComplete[]>([]);
  const [selectedRank, setSelectedRank] = useState<RankComplete>();
  const [detailCodes, setDetailCodes] = useState<DetailCode[]>([]);
  const [selectedDetailCode, setSelectedDetailCode] = useState<DetailCode>();
  const [payCodes, setPayCodes] = useState<PayCodeComplete[]>([]);
  const [selectedPayCode, setSelectedPayCode] = useState<PayCodeComplete>();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedRank(undefined);
    setSelectedCertification(undefined);
    setSelectedDetailCode(undefined);
    setSelectedPayCode(undefined);
    setSelectedTab(newValue);
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        height: '100%',
        overflowY: 'scroll',
      })}
    >
      <Box sx={{ px: 5, pt: 4, pb: 8, typography: 'heading4', mb: 0 }}>Department Settings</Box>
      <Box
        sx={(theme) => ({
          width: '75%',
          mx: 'auto',
          boxShadow: 'none',
          gap: 3,
        })}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={(theme) => ({
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.stationPurple[500],
            },
            textTransform: 'none',
          })}
        >
          <CustomTab label="Ranks" value={0} />
          <CustomTab label="Certifications" value={1} />
          <CustomTab label="Detail Codes" value={2} />
          <CustomTab label="Pay Codes" value={3} />
        </Tabs>
        <Box mt={2}>
          {selectedTab === 0 && (
            <DepartmentSettingsTab
              elements={ranks}
              setElements={setRanks}
              selectedElement={selectedRank}
              setSelectedElement={setSelectedRank}
              createUpdateUrl={'organization/ranks/'}
              elementType={ElementTypes.RANK}
              exportUrl={'organization/ranks/export/'}
            />
          )}
        </Box>
        <Box mt={2}>
          {selectedTab === 1 && (
            <DepartmentSettingsTab
              elements={certifications}
              setElements={setCertifications}
              selectedElement={selectedCertification}
              setSelectedElement={setSelectedCertification}
              createUpdateUrl={'certification/certifications/'}
              elementType={ElementTypes.CERTIFICATION}
              isFilterLowerCase={true}
              exportUrl={'certification/certifications/export/'}
            />
          )}
        </Box>
        <Box mt={2}>
          {selectedTab === 2 && (
            <DepartmentSettingsTab
              elements={detailCodes}
              setElements={setDetailCodes}
              selectedElement={selectedDetailCode}
              setSelectedElement={setSelectedDetailCode}
              createUpdateUrl={'payroll/detail-code/'}
              elementType={ElementTypes.DETAIL_CODE}
              exportUrl={'payroll/detail-code/export/'}
            />
          )}
        </Box>
        <Box mt={2}>
          {selectedTab === 3 && (
            <DepartmentSettingsTab
              elements={payCodes}
              setElements={setPayCodes}
              selectedElement={selectedPayCode}
              setSelectedElement={setSelectedPayCode}
              createUpdateUrl={'payroll/pay-code/'}
              elementType={ElementTypes.PAY_CODE}
              exportUrl={'payroll/pay-code/export/'}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
