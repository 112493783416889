import { useDroppable } from '@dnd-kit/core';
import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

interface DroppableItemProps {
  droppable: ReturnType<typeof useDroppable>;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

export const DroppableItem = ({ children, sx, droppable }: DroppableItemProps) => {
  return (
    <Box
      ref={droppable.setNodeRef}
      sx={[
        (theme) => ({
          boxShadow: droppable.isOver
            ? `${theme.palette.stationBlue[100]} 0px 13px 27px -5px, ${theme.palette.stationBlue[100]} 0px 8px 16px -8px`
            : 'none',
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </Box>
  );
};
