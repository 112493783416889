import { Button as MuiButton, ButtonProps, Typography, Theme } from '@mui/material';
import React from 'react';

interface OurProps {
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'dark' | 'danger';
  buttonSize?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  fontWeight?: number | string;
}

type Props<RootComponentType extends React.ElementType = 'button'> = ButtonProps<RootComponentType> & OurProps;

const setProperStyles = (theme: Theme, buttonType: string, size: string): React.CSSProperties => {
  const properties = {
    padding: 0,
    background: (() => {
      if (buttonType === 'primary') {
        return theme.palette.stationBlue[600];
      }
      if (buttonType === 'secondary') {
        return theme.palette.stationGray[100];
      }
      if (buttonType === 'tertiary') {
        return theme.palette.common.white;
      }
      if (buttonType === 'dark') {
        return theme.palette.stationGray[900];
      }
      if (buttonType === 'danger') {
        return theme.palette.stationRose[600];
      }
      return theme.palette.stationBlue[600];
    })(),
    color: (() => {
      if (buttonType === 'primary') {
        return theme.palette.common.white;
      }
      if (buttonType === 'secondary') {
        return theme.palette.stationGray[700];
      }
      if (buttonType === 'tertiary') {
        return theme.palette.stationGray[700];
      }
      if (buttonType === 'dark') {
        return theme.palette.common.white;
      }
      if (buttonType === 'danger') {
        return theme.palette.common.white;
      }
      return theme.palette.common.white;
    })(),
    boxShadow: 'none',
    border: (() => {
      if (buttonType === 'tertiary') {
        return `1px solid ${theme.palette.stationGray[300]}`;
      }
      return '1px solid transparent';
    })(),
    textTransform: 'none',
    height: 'fit-content',
    width: 'fit-content',
    '&:hover': {
      boxShadow: 'none',
      background: (() => {
        if (buttonType === 'primary') {
          return theme.palette.stationBlue[700];
        }
        if (buttonType === 'secondary') {
          return theme.palette.stationGray[200];
        }
        if (buttonType === 'tertiary') {
          return theme.palette.stationGray[300];
        }
        if (buttonType === 'dark') {
          return theme.palette.stationGray[900];
        }
        if (buttonType === 'danger') {
          return theme.palette.stationRose[700];
        }
        return theme.palette.stationBlue[700];
      })(),
    },
    '&.Mui-focusVisible': {
      background: (() => {
        if (buttonType === 'primary') {
          return theme.palette.stationBlue[600];
        }
        if (buttonType === 'secondary') {
          return theme.palette.stationGray[100];
        }
        if (buttonType === 'tertiary') {
          return theme.palette.common.white;
        }
        if (buttonType === 'dark') {
          return theme.palette.stationGray[900];
        }
        if (buttonType === 'danger') {
          return theme.palette.stationRose[600];
        }
        return theme.palette.stationBlue[600];
      })(),
      boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px ${theme.palette.common.white}, 0px 0px 0px 4px ${theme.palette.stationBlue[500]}`,
    },
  } as React.CSSProperties;
  return properties;
};

const renderText = (label: string, size: string, fontWeight: number | string) => {
  if (size === 'lg' || size === 'xl') {
    return (
      <Typography
        component="p"
        variant="bodyMRegular"
        style={{ fontWeight }}
        sx={{
          padding: (() => {
            if (size === 'xl') {
              return '13px 25px';
            }
            return '9px 17px';
          })(),
        }}
      >
        {label}
      </Typography>
    );
  }

  if (size === 'xs') {
    return (
      <Typography
        component="p"
        variant="buttonS"
        style={{ fontWeight }}
        sx={{
          padding: '7px 11px',
        }}
      >
        {label}
      </Typography>
    );
  }

  return (
    <Typography
      component="p"
      variant="bodySRegular"
      style={{ fontWeight }}
      sx={{
        padding: (() => {
          if (size === 'sm') {
            return '9px 13px';
          }
          return '9px 17px';
        })(),
      }}
    >
      {label}
    </Typography>
  );
};

export const Button = <RootComponentType extends React.ElementType>({
  buttonType = 'primary',
  sx,
  buttonSize = 'md',
  fontWeight = '500',
  children,
  ...props
}: Props<RootComponentType>) => {
  return (
    <MuiButton
      disableFocusRipple
      variant="contained"
      sx={(theme) => ({
        ...setProperStyles(theme, buttonType, buttonSize),
        ...(typeof sx === 'function' ? sx(theme) : {}),
        '&.Mui-disabled': {
          background: theme.palette.stationGray[300],
          color: theme.palette.common.white,
        },
      })}
      {...props}
    >
      {typeof children === 'string' ? renderText(String(children), buttonSize, fontWeight) : children}
    </MuiButton>
  );
};
