import { object, TypeOf, z } from 'zod';

// Post shift trade request DTO
export const postShiftTradeRequestSchema = object({
  shift_trade_date: z.string({
    required_error: 'Date is required.',
  }),
  start_time: z.number(),
  end_time: z.number(),
  receiver_employee_ids: z.array(z.string(), {
    required_error: 'Receiver employee ids are required.',
  }),
});
export type PostShiftTradeRequestDto = TypeOf<typeof postShiftTradeRequestSchema>;
