import { Box, Modal, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Button, SnackbarService, theme } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { EmployeePayroll } from '@stationwise/share-types';
import { isChangedNameContainsInvalidSymbols, isChangedNameInvalid } from './utils';
interface AddColumnProps {
  addColumnModalOpen: boolean;
  setAddColumnModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  employeePayroll: EmployeePayroll;
  forceRefetch: () => void;
}

export const AddColumnModal = ({ addColumnModalOpen, setAddColumnModalOpen, employeePayroll, forceRefetch }: AddColumnProps) => {
  const [columnName, setColumnName] = useState<string>('');
  const [defaultValue, setDefaultValue] = useState<string>('');

  const handleModalClose = () => {
    setAddColumnModalOpen(false);
    setColumnName('');
    setDefaultValue('');
  };
  const handleSubmit = async () => {
    const data = {
      additional_columns_with_defaults: {
        [columnName.toLowerCase()]: defaultValue,
      },
      payPeriodId: employeePayroll.payPeriod.id,
    };
    const response = await client.put('payroll/customize-payroll-export/', data);
    if (response.status === 200) {
      SnackbarService.notify({
        content: 'Column added successfully',
        severity: 'success',
        duration: 5000,
      });
      forceRefetch();
    } else {
      SnackbarService.notify({
        content: 'Something went wrong, please try again',
        severity: 'error',
        duration: 10000,
      });
    }
    handleModalClose();
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={addColumnModalOpen}
      onClose={() => handleModalClose()}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: '16px',
        })}
      >
        <Box
          sx={(theme) => ({
            padding: '24px 24px 0px 24px',
            borderRadius: '16px 16px 0px 0px',
            width: '428px',
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            maxHeight: '800px',
            gap: theme.spacing(1),
            typography: 'bodyXLSemibold',
          })}
        >
          <Typography sx={{ typography: 'bodyXLSemibold' }}>Add new column</Typography>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
            })}
          >
            Column Name
          </Box>
          <TextField
            value={columnName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setColumnName(event.target.value);
            }}
            sx={(theme) => ({
              mb: theme.spacing(1),
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(145, 173, 1, 1)',
                  boxShadow: `0 0 0 4px ${theme.palette.stationPrimary[400]}`,
                },
              },
            })}
          />
          {isChangedNameInvalid(employeePayroll.payrollColumns, employeePayroll.firstColumnsNames, columnName) && (
            <Typography sx={{ color: theme.palette.error.main, typography: 'bodyLSmall' }}>
              {'This name is already used, please choose another name.'}
            </Typography>
          )}
          {isChangedNameContainsInvalidSymbols(columnName) && (
            <Typography sx={{ color: theme.palette.error.main, typography: 'bodyLSmall' }}>
              {'The name contains invalid characters.'}
            </Typography>
          )}
          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
            })}
          >
            Default Value
          </Box>
          <TextField
            value={defaultValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDefaultValue(event.target.value);
            }}
            inputProps={{ maxLength: 80 }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(145, 173, 1, 1)',
                  boxShadow: `0 0 0 4px ${theme.palette.stationPrimary[400]}`,
                },
              },
            }}
          />

          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              mt: theme.spacing(1.5),
              gap: theme.spacing(2),
              width: '100%',
              paddingY: theme.spacing(3),
            })}
          >
            <Button onClick={() => handleModalClose()} buttonType="tertiary" sx={() => ({ width: '100%' })}>
              {'Cancel'}
            </Button>
            <Button
              buttonType="dark"
              onClick={handleSubmit}
              disabled={
                columnName === '' ||
                isChangedNameInvalid(employeePayroll.payrollColumns, employeePayroll.firstColumnsNames, columnName) ||
                isChangedNameContainsInvalidSymbols(columnName)
              }
              sx={() => ({ width: '100%', ':hover': { backgroundColor: theme.palette.stationGray[700] } })}
            >
              {'Submit'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
