import { isEqual, setMinutes } from 'date-fns';
import {
  RosterDataSource,
  RosterEmployee,
  RosterEmployeeDuration,
  RosterEmployeeOffPayloads,
  ListFieldsStaffingList,
  StaffingListItem,
} from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { getBoardEmployees, getOverrideEmployeePositionFields } from './board';
import { formatShiftDuration } from './datetime';
import { makeOvertimeEmployeeActiveId } from './id';
import { IShiftSummaryHelper } from './types';

export const getOvertimeEmployee = (
  shiftSummaryHelper: IShiftSummaryHelper,
  selectedStaffingList: ListFieldsStaffingList | undefined,
  employeeActiveId: string,
) => {
  const staffingListItem = selectedStaffingList?.items?.find((item) => {
    return employeeActiveId === makeOvertimeEmployeeActiveId(item.employee.id);
  });
  if (!staffingListItem) {
    return null;
  }
  return {
    ...staffingListItem.employee,
    activeId: makeOvertimeEmployeeActiveId(staffingListItem.employee.id),
    dataSource: RosterDataSource.OVERTIME,
    startDateTime: shiftSummaryHelper.shiftDuration.startTime,
    endDateTime: shiftSummaryHelper.shiftDuration.endTime,
    payCodes: [staffingListItem.employee.defaults.overtimeAssignmentPayCode],
    ...getOverrideEmployeePositionFields(),
  };
};

interface GetStaffingListEmployeeDurationsInput extends RosterEmployeeOffPayloads {
  shiftSummaryHelper: IShiftSummaryHelper;
  staffingListItems: StaffingListItem[];
}

export const getStaffingListItemEmployeeDurations = (input: GetStaffingListEmployeeDurationsInput) => {
  const employeeDurations = new Map<string, RosterEmployeeDuration[]>();
  if (!input.staffingListItems.length) {
    return employeeDurations;
  }

  const apparatusIds = new Set<string>();
  const positionIds = new Set<string>();
  const employeeIds = new Set(input.staffingListItems.map((item) => item.employee.id));
  const employees = getBoardEmployees(input.shiftSummaryHelper).filter((e) => employeeIds.has(e.id));
  const shiftStartDateTime = input.shiftSummaryHelper.shiftDuration.startTime;

  input.shiftSummaryHelper.allStationCards.forEach((station) => {
    station.apparatuses.forEach((apparatus) => {
      apparatusIds.add(apparatus.id);
      apparatus.positions.forEach((p) => positionIds.add(p.id));
    });
  });

  const cancelledOffKeys = new Set([
    ...input.cancelTemporaryNonShiftAssignmentPayloads.map((id) => `TEMPORARY_NON_SHIFT:${id}`),
    ...input.cancelShiftTradePayloads.map(({ shiftTradeId: id }) => `SHIFT_TRADE_REQUEST:${id}`),
    ...input.cancelTimeOffPayloads.map((id) => `TIME_OFF_REQUEST:${id}`),
  ]);

  const pushTimeDuration = (employeeId: string, source: string, startTime: number, endTime: number) => {
    const startDateTime = setMinutes(shiftStartDateTime, shiftStartDateTime.getMinutes() + startTime);
    const endDateTime = setMinutes(shiftStartDateTime, shiftStartDateTime.getMinutes() + endTime);
    const durations = employeeDurations.get(employeeId) || [];
    durations.push({ source, startDateTime, endDateTime });
    employeeDurations.set(employeeId, durations);
  };

  employees.forEach(({ id, startDateTime, endDateTime }) => {
    const startTime = differenceInUTCMinutes(startDateTime, shiftStartDateTime);
    const endTime = differenceInUTCMinutes(endDateTime, shiftStartDateTime);
    pushTimeDuration(id, 'ASSIGNMENT', startTime, endTime);
  });

  input.staffingListItems.forEach((item) => {
    item.employee.assignments.forEach((assignment) => {
      // Include the assignments on other battalions.
      const isForOtherBattalionApparatus = !!assignment.apparatusId && !apparatusIds.has(`${assignment.apparatusId}`);
      const isForOtherBattalionPosition = !!assignment.positionId && !positionIds.has(`${assignment.positionId}`);
      if (isForOtherBattalionApparatus || isForOtherBattalionPosition) {
        pushTimeDuration(item.employee.id, 'ASSIGNMENT', assignment.startTime, assignment.endTime);
      }
    });
    item.employee.offs.forEach((off) => {
      if (!cancelledOffKeys.has(off.key)) {
        pushTimeDuration(item.employee.id, 'OFF', off.startTime, off.endTime);
      }
    });
  });

  input.createTemporaryNonShiftAssignmentPayloads.forEach((p) => pushTimeDuration(p.candidateId, 'OFF', p.startTime, p.endTime));
  input.createShiftTradePayloads.forEach((p) => pushTimeDuration(p.senderId, 'OFF', p.startTime, p.endTime));
  input.createTimeOffPayloads.forEach((p) => pushTimeDuration(p.employeeId, 'OFF', p.startTime, p.endTime));

  employeeDurations.forEach((durations) => durations.sort((a, b) => differenceInUTCMinutes(a.startDateTime, b.startDateTime)));
  return employeeDurations;
};

export const getStaffingListItemBlockerErrorMessages = (
  shiftSummaryHelper: IShiftSummaryHelper,
  employeeOffPayloads: RosterEmployeeOffPayloads,
  staffingListItem: StaffingListItem | undefined,
  employee: RosterEmployee,
) => {
  if (!staffingListItem) {
    return [];
  }

  const employeeDurations = getStaffingListItemEmployeeDurations({
    ...employeeOffPayloads,
    shiftSummaryHelper,
    staffingListItems: [staffingListItem],
  });
  const durations = employeeDurations.get(staffingListItem.employee.id) || [];
  const thisDuration = durations.find((d) => {
    return isEqual(d.startDateTime, employee.startDateTime) && isEqual(d.endDateTime, employee.endDateTime);
  });
  const conflictDuration = durations.find((d) => {
    return d !== thisDuration && d.startDateTime < employee.endDateTime && d.endDateTime > employee.startDateTime;
  });
  if (thisDuration && conflictDuration) {
    const formattedDuration = formatShiftDuration({
      startTime: conflictDuration.startDateTime,
      endTime: conflictDuration.endDateTime,
    });
    const formattedSource = conflictDuration.source === 'ASSIGNMENT' ? 'staffed' : 'off';
    return [`This person is already ${formattedSource} ${formattedDuration}`];
  }

  const totalDuration = durations.reduce((sum, e) => sum + differenceInUTCMinutes(e.endDateTime, e.startDateTime), 0);
  if (totalDuration > 24 * 60) {
    return ['This person is scheduled over 24 hours'];
  }

  return [];
};
