import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

export enum DRAWER_SIDE {
  RIGHT = 'right',
  LEFT = 'left',
}

export interface DrawerProps {
  isOpen: boolean;
  children: React.ReactNode;
  side?: DRAWER_SIDE;
  onClickOutside?: () => void;
  drawerSize?: string;
  backgroundColor?: string;
  additionalAddedPx?: string;
}

export const Drawer = ({
  isOpen,
  children,
  side = DRAWER_SIDE.LEFT,
  onClickOutside,
  drawerSize,
  backgroundColor,
  additionalAddedPx,
}: DrawerProps) => {
  const [isFirstTimeOpen, setIsFirstTimeOpen] = useState(false);

  useEffect(() => {
    if (!isFirstTimeOpen && isOpen) {
      setIsFirstTimeOpen(true);
    }
  }, [isFirstTimeOpen, isOpen]);

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (
        onClickOutside &&
        event.target instanceof HTMLElement &&
        !event.target.closest('a[href],button,[role="button"],input,[data-clickable="true"],.MuiModal-root')
      ) {
        onClickOutside();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside]);

  return (
    <Box
      /* Do not trigger onClickOutside */
      onMouseDown={(event) => event.stopPropagation()}
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
        }}
        width="100%"
        height="100%"
      >
        <Box
          sx={(theme) => ({
            backgroundColor: backgroundColor ?? theme.palette.common.white,
            top: 0,
            left: side === DRAWER_SIDE.LEFT ? 0 : undefined,
            right: side === DRAWER_SIDE.RIGHT ? 0 : undefined,
            position: 'absolute',
            width: drawerSize ?? '380px',
            height: '100%',
            minHeight: '100%',
            boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25)',
            zIndex: theme.zIndex.drawer,
            transition: 'transform 0.2s ease-out',
            transform: (() => {
              if (isFirstTimeOpen && isOpen) {
                return `translateX(${additionalAddedPx ?? 0})`;
              }
              return side === DRAWER_SIDE.LEFT ? 'translateX(-100%)' : 'translateX(100%)';
            })(),
          })}
        >
          {isFirstTimeOpen && children}
        </Box>
      </Box>
    </Box>
  );
};
