import { format } from 'date-fns';
import { RosterShiftDuration } from '@stationwise/share-types';

export const formatShiftTime = (time: Date) => format(time, 'HH:mm');

export const formatShiftDuration = ({ startTime, endTime }: Pick<RosterShiftDuration, 'startTime' | 'endTime'>) => {
  return `${formatShiftTime(startTime)} - ${formatShiftTime(endTime)}`;
};

const getHourMinutes = (time: Date) => time.getHours() * 60 + time.getMinutes();

export const diffCycleMinutes = (endTime: Date | null, startTime: Date | null) => {
  if (!endTime || !startTime) {
    return 0;
  }
  // E.g. diffCycleMinutes(23:00, 07:00) = 16 * 60.
  // E.g. diffCycleMinutes(05:00, 07:00) = 22 * 60. 05:00 means 05:00 the next day.
  const minutes = getHourMinutes(endTime) - getHourMinutes(startTime);
  return (minutes + 24 * 60) % (24 * 60);
};

export const offsetMinutes = (relatedTime: Date | null, anchorTime: Date | null) => {
  if (!relatedTime || !anchorTime) {
    return 0;
  }
  // E.g. offsetMinutes(23:00, 07:00) = 16 * 60.
  // E.g. offsetMinutes(05:00, 07:00) = -2 * 60.
  const minutes = getHourMinutes(relatedTime) - getHourMinutes(anchorTime);
  return minutes % (24 * 60);
};
