import { Box } from '@mui/material';
import { RosterPosition, RosterEmployee, DEFAULT_RANK } from '@stationwise/share-types';
import { DriverIcon20 } from '../../assets';
import { Badge, CertBadge } from '../Badge';
import { Chip } from '../Card';

interface Props {
  position: RosterPosition | null;
  employee: RosterEmployee;
  addNoteButton?: React.ReactNode;
  defaultStaffingSection?: React.ReactNode;
  actions?: React.ReactNode;
}

export const PreviewHeader = ({ position, employee, addNoteButton, defaultStaffingSection, actions }: Props) => {
  return (
    <Box sx={(theme) => ({ backgroundColor: theme.palette.stationGray[800], p: 2 })}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {position && position.driver && (
          <Box component={DriverIcon20} sx={(theme) => ({ color: theme.palette.stationGray[400], mr: 0.5 })} />
        )}
        {position && position.rank.code !== employee.rank.code && position.rank.id !== DEFAULT_RANK.id && (
          <Chip label="Acting as" />
        )}
        {position && position.rank.id !== DEFAULT_RANK.id && (
          <Badge
            label={position ? position.rank.name : employee.rank.name}
            isRound
            type="sm"
            color={position ? position.rank.color : employee.rank.color}
            isBold
            noPadding
            overrideMaxLength={32}
          />
        )}
        <Box sx={{ flex: 1 }} />
        {addNoteButton}
      </Box>
      <Box
        sx={(theme) => ({
          mt: 2,
          color: theme.palette.common.white,
          typography: 'bodyLMedium',
        })}
      >
        {employee.name}
      </Box>
      {!!employee.certifications.length && (
        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap', maxWidth: '290px', mt: 2 }}>
          {employee.certifications.map((certification, index) => (
            <CertBadge key={index} cert={certification} dark={true} />
          ))}
        </Box>
      )}
      {defaultStaffingSection}
      {actions}
    </Box>
  );
};
