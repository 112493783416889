import { useEffect, useRef, useState } from 'react';
import { client, isAxiosError } from '@stationwise/share-api';
import { RosterPosition, StrikeTeam, StrikeTeamCandidate } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { useLoadedDepartmentInfoContext } from '../../../Department';
import { useRosterContext } from '../RosterContext';

interface Props {
  strikeTeam: StrikeTeam;
  position: RosterPosition | null;
  fromDate: string;
  toDate: string;
  startDateTime: Date | null;
  endDateTime: Date | null;
  searchValue: string;
  certCodes: Set<string>;
}

export const useStrikeTeamCandidates = ({
  strikeTeam,
  position,
  fromDate,
  toDate,
  startDateTime,
  endDateTime,
  searchValue,
  certCodes,
}: Props) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { shiftSummaryHelper } = useRosterContext();
  const shiftStartTime = departmentInfoState.departmentInfo.shiftStart;
  const abortControllerRef = useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [data, setData] = useState<StrikeTeamCandidate[]>([]);

  useEffect(() => {
    setPage(1);
    setHasNextPage(true);
    setData([]);
  }, [fromDate, toDate, startDateTime, endDateTime, searchValue, certCodes]);

  useEffect(() => {
    const fetchData = async () => {
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      if (!startDateTime || !endDateTime) {
        setIsLoading(false);
        setData([]);
        setHasNextPage(false);
        return;
      }

      setIsLoading(true);
      try {
        const url = 'event/strike-team-candidates/';
        const shiftStartDateTime = shiftSummaryHelper.shiftDuration.startTime;
        const params = {
          page,
          fromDate,
          toDate,
          strikeTeamId: strikeTeam.id,
          positionId: !position || Number(position.id) < 0 ? undefined : position.id,
          activeWeekdays: strikeTeam.activeWeekdays,
          positionStartTime: differenceInUTCMinutes(position?.startDateTime || startDateTime, shiftStartDateTime),
          positionEndTime: differenceInUTCMinutes(position?.endDateTime || endDateTime, shiftStartDateTime),
          assignmentStartTime: differenceInUTCMinutes(startDateTime, shiftStartDateTime),
          assignmentEndTime: differenceInUTCMinutes(endDateTime, shiftStartDateTime),
          search: searchValue || undefined,
          certificationCodes: [...certCodes],
        };
        const response = await client.get(url, {
          params,
          signal: abortControllerRef.current.signal,
        });
        setData((oldData) => {
          const keys = new Map<number, number>();
          const newData: StrikeTeamCandidate[] = [];
          const pushData = (result: StrikeTeamCandidate) => {
            const index = keys.get(result.id);
            if (typeof index === 'number') {
              newData[index] = result;
            } else {
              newData.push(result);
              keys.set(result.id, newData.length - 1);
            }
          };
          oldData.forEach(pushData);
          (response.data.results || []).forEach(pushData);
          return newData;
        });
        setHasNextPage(!!response.data.next);
        setIsLoading(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && setData([]);
        !isCanceled && setHasNextPage(false);
        !isCanceled && setIsLoading(false);
      }
    };

    fetchData();
  }, [
    shiftStartTime,
    strikeTeam,
    position,
    page,
    fromDate,
    toDate,
    startDateTime,
    endDateTime,
    searchValue,
    certCodes,
    shiftSummaryHelper.shiftDuration.startTime,
  ]);

  const loadMore = () => {
    if (hasNextPage && !isLoading) {
      setPage(page + 1);
    }
  };

  return {
    isLoading,
    data,
    hasNextPage,
    loadMore,
  };
};
