import { Box } from '@mui/material';
import { Button } from '@stationwise/component-module';

interface LeftSideFiltersProps {
  todayHandle: () => void;
}

export const LeftSideFilters = ({ todayHandle }: LeftSideFiltersProps) => {
  return (
    <Box
      sx={{
        ml: '24px',
        minWidth: 0,
      }}
    >
      <Button buttonType="tertiary" buttonSize="sm" sx={{ minWidth: 0 }} onClick={() => todayHandle()}>
        Today
      </Button>
    </Box>
  );
};
