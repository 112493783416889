import { useCallback, useEffect, useRef, useState } from 'react';
import { client } from '@stationwise/share-api';
import { ConversationListView } from '@stationwise/share-types';
import { useLoadedAuthUserContext } from '../components/Auth';

export const useGetConversations = () => {
  // this value is only to force refetch
  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<ConversationListView[] | []>([]);

  const { state: authUserState, dispatch: dispatchAuthUserState } = useLoadedAuthUserContext();
  const meRef = useRef(authUserState.employee);
  meRef.current = authUserState.employee;

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setError(null);
    setData([]);
    client
      .get<ConversationListView[]>('/conversation/', {
        params: {
          search: 'Sent',
        },
      })
      .then((response) => {
        setData(response.data);

        const newUnreadMessageIds = new Set(meRef.current.unreadMessages);
        response.data.forEach((conversation) => {
          const recipient = conversation.recipients.find((r) => `${r.employee.id}` === meRef.current.id);
          if (recipient) {
            if (recipient.isRead || Number(meRef.current.id) === Number(conversation.createdBy)) {
              newUnreadMessageIds.delete(conversation.id);
            } else {
              newUnreadMessageIds.add(conversation.id);
            }
          }
        });
        dispatchAuthUserState({ type: 'SET_USER_UNREAD_MESSAGES.SUCCESS', payload: [...newUnreadMessageIds] });
      })
      .catch((error) => {
        setIsError(true);
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatchAuthUserState, fetchNoRequest]);

  const forceRefetch = useCallback(() => {
    setFetchNoRequest((prevValue) => prevValue + 1);
  }, []);

  return {
    isLoading,
    isError,
    error,
    data,
    forceRefetch,
  };
};
