import { Box, Typography } from '@mui/material';
import { BlankCard, NoUpcomingShift } from '@stationwise/component-module';

export const NoUpcomingShiftCard = () => {
  return (
    <BlankCard type="passive" sxProps={{ border: 'none', padding: 2, boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)' }}>
      <Box>
        <Typography
          variant="bodyLSemibold"
          sx={(theme) => ({
            color: theme.palette.stationGray[900],
          })}
        >
          Next shift
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center', mt: '8px' }}>
        <NoUpcomingShift />
      </Box>
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          mt: '8px',
          textAlign: 'center',
        })}
      >
        <Typography variant="bodyXXLSemibold">No upcoming shift</Typography>
      </Box>
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[500],
          mt: '8px',
          mb: '40px',
          textAlign: 'center',
        })}
      >
        <Typography component="div" variant="bodyMRegular">
          You have no upcoming shift right now.
        </Typography>
        <Typography component="div" variant="bodyMRegular">
          Enjoy your free time!
        </Typography>
      </Box>
    </BlankCard>
  );
};
