import { Box, Grid } from '@mui/material';
import React from 'react';
import { WorkGroupPreview } from '@stationwise/share-types';
import { NewWorkGroupCard } from './NewWorkGroupCard';
import { WorkGroupCard } from './WorkGroupCard';

interface WorkGroupGridProps {
  workGroupsView: WorkGroupPreview[];
  clicked: number;
  setClicked: React.Dispatch<React.SetStateAction<number>>;
}

export const WorkGroupGrid = ({ workGroupsView, clicked, setClicked }: WorkGroupGridProps) => {
  const gridItemSize = 4;
  const workGroupNames = workGroupsView.map((workgroup) => workgroup.name);
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'columns',
        padding: '0px 24px',
        gap: theme.spacing(3),
        width: '1158px',
        overflowY: 'auto',
        height: '498px',
      })}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={gridItemSize}>
          <NewWorkGroupCard clicked={clicked} setClicked={setClicked} workGroupNames={workGroupNames} />
        </Grid>
        {workGroupsView.map((workgroup) => (
          <Grid item xs={12} sm={6} md={gridItemSize} key={workgroup.id}>
            <WorkGroupCard workgroup={workgroup} clicked={clicked} setClicked={setClicked} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
