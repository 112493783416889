import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Typography } from '@mui/material';
import React, { useState, useRef } from 'react';
import { Button, Modal, theme } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { Rank } from '@stationwise/share-types';

interface VacancyFillRankOrderModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  rankOrder: number[];
  uniqueRanks: Rank[];
  onSubmit: (newOrders: number[]) => void;
}

export const VacancyFillRankOrderModal: React.FC<VacancyFillRankOrderModalProps> = ({
  open,
  setOpen,
  rankOrder,
  uniqueRanks,
  onSubmit,
}) => {
  const [orders, setOrders] = useState<number[]>(rankOrder);
  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);

  const handleSort = () => {
    if (dragItem.current !== null && dragOverItem.current !== null) {
      const updatedOrders = [...orders];
      const draggedItemContent = updatedOrders.splice(dragItem.current, 1)[0];
      updatedOrders.splice(dragOverItem.current, 0, draggedItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setOrders(updatedOrders);
    }
  };

  const handleSaveDefault = async () => {
    try {
      await client.post('auto-hire/save_auto_hire_vacancy_rank_order/', {
        rank_order: orders,
      });
      handleSubmit();
    } catch (error) {
      console.error('Failed to save default vacancy fill rank order', error);
    }
  };

  const handleSubmit = () => {
    onSubmit(orders);
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen} hideCloseIcon>
      <Box
        sx={{
          p: theme.spacing(2),
          borderRadius: '8px',
          mx: 'auto',
          position: 'relative',
          maxWidth: '320px',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '85vh',
          overflow: 'auto',
        }}
      >
        <Typography variant="h5">Auto Hire Rank Order</Typography>
        <Typography variant="body1">Auto hire will notify vacant rank groups in the order they are listed below.</Typography>

        <Box sx={{ flex: 1, overflow: 'auto', my: 2 }}>
          {orders.map((order, index) => {
            const rank = uniqueRanks.find((rank) => rank.id === order);
            return (
              <Box
                key={index}
                draggable
                onDragStart={() => (dragItem.current = index)}
                onDragEnter={() => (dragOverItem.current = index)}
                onDragEnd={handleSort}
                onDragOver={(e) => e.preventDefault()}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1,
                  backgroundColor: theme.palette.stationGray[50],
                  p: theme.spacing(1),
                  width: '280px',
                  borderRadius: theme.spacing(1),
                  border: '1px solid',
                  borderColor: dragItem.current === index ? theme.palette.primary.light : 'transparent',
                  opacity: 1,
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: theme.palette.stationGray[300],
                    cursor: 'move',
                  },
                }}
              >
                <DragIndicatorIcon
                  sx={{
                    visibility: 'visible',
                    marginRight: theme.spacing(1),
                    color: theme.palette.stationGray[500],
                    '&:hover': {
                      color: theme.palette.primary.dark,
                    },
                  }}
                />
                <Typography sx={{ width: '20px', textAlign: 'center', color: theme.palette.stationGray[500] }}>
                  {index + 1}
                </Typography>
                <Typography sx={{ flex: 1, ml: theme.spacing(1.5) }}>{rank?.name}</Typography>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: theme.spacing(2),
            gap: theme.spacing(1),
            mb: theme.spacing(2),
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveDefault}>
            Save as Default
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
