import { Box, Theme, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { ChevronDownIcon20, getRequestTypeIcon } from '@stationwise/component-module';
import { TimeOffLimit } from '@stationwise/share-types';
import { TimeOffTypeSelector } from './TimeOffTypeSelector';

interface TimeOffLimitSelectProps {
  timeOffLimits: TimeOffLimit[];
  selectedTimeOffLimit: TimeOffLimit | undefined;
  setSelectedTimeOffLimit: Dispatch<SetStateAction<TimeOffLimit | undefined>>;
  isAccrualsEnabled: boolean;
}

export const getTimeOffIcon = (timeOffLimit: TimeOffLimit, theme: Theme) => {
  return getRequestTypeIcon({ requestType: 'TimeOffRequest', timeOffType: timeOffLimit.payCode.name }, theme).icon;
};

export const TimeOffLimitSelect = ({
  timeOffLimits,
  selectedTimeOffLimit,
  setSelectedTimeOffLimit,
  isAccrualsEnabled,
}: TimeOffLimitSelectProps) => {
  const theme = useTheme();
  const [selectionDrawerOpen, setSelectionDrawerOpen] = useState<boolean>(false);
  const handleSelectionDrawerClose = () => {
    setSelectionDrawerOpen(false);
  };

  return (
    <>
      <Box>
        <Box
          sx={(theme) => ({
            color: theme.palette.stationGray[900],
            typography: 'bodyLMedium',
          })}
        >
          Time off type
        </Box>
        <Box
          data-cy="time-off-type-select"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            border: `1px solid ${theme.palette.stationGray[200]}`,
            borderRadius: '8px',
            p: theme.spacing(1),
            mt: theme.spacing(1),
            justifyContent: 'space-between',
          })}
          onClick={() => setSelectionDrawerOpen(true)}
        >
          {!selectedTimeOffLimit && (
            <Box sx={(theme) => ({ typography: 'bodyMRegular', color: theme.palette.stationGray[500] })}>
              Please select a type of Time Off
            </Box>
          )}
          {selectedTimeOffLimit && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  '& svg': {
                    '& path': {
                      stroke: theme.palette.stationGray[100],
                    },
                  },
                  backgroundColor: theme.palette.stationGray[400],
                  borderRadius: '50%',
                  p: theme.spacing(0.5),
                })}
              >
                {getTimeOffIcon(selectedTimeOffLimit, theme)}
              </Box>
              <Box
                sx={(theme) => ({
                  ml: theme.spacing(1),
                  typography: 'bodyMRegular',
                })}
              >
                {selectedTimeOffLimit.payCode.name}
              </Box>
            </Box>
          )}
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              mr: theme.spacing(1),
              color: theme.palette.stationGray[400],
            })}
          >
            <ChevronDownIcon20 />
          </Box>
        </Box>
        {selectedTimeOffLimit && !selectedTimeOffLimit.isUnlimited && isAccrualsEnabled && (
          <Box
            sx={(theme) => ({
              color: theme.palette.stationGray[500],
              mt: theme.spacing(1),
              typography: 'bodyMRegular',
            })}
          >
            {(selectedTimeOffLimit.accruedTimeOff - selectedTimeOffLimit.pending).toFixed(2)}{' '}
            {selectedTimeOffLimit.payCode.name.toLowerCase()} hours remaining
          </Box>
        )}
      </Box>
      <TimeOffTypeSelector
        handleOnClose={handleSelectionDrawerClose}
        drawerOpen={selectionDrawerOpen}
        options={timeOffLimits}
        setSelectedTimeOffLimit={setSelectedTimeOffLimit}
        isAccrualsEnabled={isAccrualsEnabled}
      />
    </>
  );
};
