import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip, FormControl, MenuItem, Select, Button } from '@mui/material';
import React from 'react';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { ChevronDownIcon16 } from '../../assets';
import { SearchInput } from '../SearchInput';

interface FilterOption {
  name: string;
  options: { value: string | number; label: string }[];
  selected: string;
  onChange: (value: string) => void;
}

interface SearchAndFilterProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>> | ((value: string) => void);
  filters: FilterOption[];
  clearAllFilters: () => void;
  searchPlaceholder?: string;
}

export const SearchAndFilter: React.FC<SearchAndFilterProps> = ({
  searchText,
  setSearchText,
  filters,
  clearAllFilters,
  searchPlaceholder = 'Search',
}) => {
  const selectedFilters = filters.filter((filter) => filter.selected);
  return (
    <Box sx={{ width: 'fit-content' }}>
      <SearchInput value={searchText} setValue={setSearchText} color="white" placeHolder={searchPlaceholder} />
      <Box sx={{ display: 'flex', gap: '15px', marginTop: '20px' }}>
        {filters.map((filter) => (
          <FormControl key={filter.name} variant="outlined" data-cy={`filter-${makeTestIdentifier(filter.name)}`}>
            <Select
              value=""
              onChange={(event) => filter.onChange(event.target.value)}
              displayEmpty
              renderValue={() => filter.name}
              sx={{
                borderRadius: '8px',
                typography: 'bodySRegular',
              }}
              MenuProps={{
                autoFocus: false,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
              }}
              IconComponent={ChevronDownIcon16}
            >
              {filter.options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={(theme) => ({
                    borderRadius: '8px',
                    color: theme.palette.stationGray[900],
                    typography: 'bodySRegular',
                  })}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginTop: 2.5, gap: 1.5 }}>
        {selectedFilters.map((filter) => (
          <Chip
            key={filter.name}
            sx={(theme) => ({
              padding: theme.spacing(0.5, 1),
              borderRadius: '100px',
              backgroundColor: theme.palette.stationGray[100],
              color: theme.palette.stationGray[600],
              typography: 'bodySRegular',
              '& .MuiChip-deleteIcon': {
                color: theme.palette.stationGray[400],
                fontSize: '13px',
              },
            })}
            deleteIcon={<CloseIcon />}
            label={filter.options.find((option) => option.value === filter.selected)?.label || ''}
            onDelete={() => filter.onChange('')}
          />
        ))}
        {selectedFilters.length > 0 && (
          <Button onClick={clearAllFilters} data-cy="clear-all-button">
            Clear All
          </Button>
        )}
      </Box>
    </Box>
  );
};
