import { Box } from '@mui/material';
import { NoEmployeesOff } from '@stationwise/component-module';

export const NoEmployeesOffCard = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        textAlign: 'center',
        '& svg': { width: '120px', height: '120px' },
      }}
    >
      <NoEmployeesOff />
      <Box sx={{ mt: 3, typography: 'bodyXXLSemibold' }}>No employees off roster today</Box>
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[500],
          maxWidth: '300px',
          m: theme.spacing(2, 'auto', 0, 'auto'),
          typography: 'bodyMRegular',
        })}
      >
        When time off requests and shift trades are approved, you will see them here.
      </Box>
    </Box>
  );
};
