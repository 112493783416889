import { Box, Pagination } from '@mui/material';
import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import React, { useDeferredValue, useEffect, useRef, useState } from 'react';
import {
  CustomTable,
  LetterAvatar,
  TableProps,
  SearchAndFilter,
  LottieLogo,
  theme,
  GenericDrawer,
  ChevronRightIcon24,
  NoDataToDisplay,
  StateCard,
} from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { capitalize, camelCaseToTitle, snakeCaseToTitle } from '@stationwise/share-utils';

export function getTypeColor(type: string) {
  switch (type) {
    case 'Add Column':
      return theme.palette.stationTeal[500];
    case 'Edit Column':
      return theme.palette.stationBlue[500];
    case 'Add Time Entry':
      return theme.palette.stationYellow[500];
    case 'Delete Column':
      return theme.palette.stationRed[500];
    case 'Edit Time Entry':
      return theme.palette.stationGray[500];
    case 'Delete Time Entry':
      return theme.palette.stationOrange[500];
    default:
      return theme.palette.stationGreen[500];
  }
}

interface FilterOptions {
  [key: string]: string[];
}
interface PayPeriodActivity {
  name: string;
  actionType: string;
  timestamp: Date;
  role: string;
  action: string;
  note: string;
}

interface EmployeePayPeriodHistoryProps {
  setOpenChangeLog: (value: boolean) => void;
  openChangeLog: boolean;
  employeePayPeriodId: number;
}

export const EmployeePayPeriodHistory = ({
  openChangeLog,
  setOpenChangeLog,
  employeePayPeriodId,
}: EmployeePayPeriodHistoryProps) => {
  const [activities, setActivities] = useState<PayPeriodActivity[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  const abortControllerRef = useRef<AbortController | null>(null);
  const deferredSearchInput = useDeferredValue(searchInput);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await client.get('payroll/pay-period-history/filter_options/');
        if (response.data) {
          setFilterOptions(response.data);
        }
      } catch (error) {
        captureException(error);
      }
    };
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      try {
        const filterParams = Object.fromEntries(Object.entries(selectedFilters).filter(([_, value]) => value));

        const params = {
          page: page,
          employeePayPeriodId: employeePayPeriodId,
          ...(deferredSearchInput && { search: deferredSearchInput }),
          ...filterParams,
        };

        const response = await client.get('payroll/pay-period-history/', {
          params,
          signal: abortControllerRef.current.signal,
        });
        if (response.data.results && Array.isArray(response.data.results)) {
          setActivities(response.data.results);
          setTotalPages(Math.ceil(response.data.count / 100));
        }
        setIsLoading(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && captureException(error);
        !isCanceled && setIsLoading(false);
      }
    };
    fetchData();
  }, [page, deferredSearchInput, selectedFilters, employeePayPeriodId]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleFilterChange = (filterName: string, value: string) => {
    setPage(1);
    setSelectedFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const handleSearchChange = (value: string) => {
    setPage(1);
    setSearchInput(value);
  };

  const filters = Object.entries(filterOptions).map(([filterName, options]) => ({
    name: camelCaseToTitle(capitalize(filterName)),
    options: options.map((option) => ({
      label: snakeCaseToTitle(option),
      value: capitalize(option),
    })),
    selected: selectedFilters[filterName],
    onChange: (value: string) => handleFilterChange(filterName, value),
  }));

  const columns: TableProps['columns'] = [
    { id: 'name', label: 'USER', minWidth: 200, align: 'left' },
    { id: 'role', label: 'ROLE', minWidth: 100, align: 'left' },
    { id: 'action', label: 'ACTION', minWidth: 300 },
    { id: 'timestamp', label: 'TIMESTAMP', minWidth: 150, align: 'center' },
    { id: 'note', label: 'NOTE', minWidth: 150, align: 'center' },
    { id: 'actionType', label: 'ACTION TYPE', minWidth: 100, align: 'center' },
  ];

  const data: TableProps['data'] = activities.map((activity) => ({
    name: (
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          alignItems: 'center',
        })}
      >
        <LetterAvatar firstName={activity.name} color="green" />
        {activity.name}
      </Box>
    ),

    action: <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>{activity.action}</Box>,
    timestamp: format(activity.timestamp, 'MMM dd, HH:mm aaa').replace(/am|pm/i, (match) => match.toUpperCase()),
    actionType: (
      <Box
        sx={(theme) => ({
          backgroundColor: getTypeColor(activity.actionType),
          color: theme.palette.common.white,
          borderRadius: '20px',
          padding: theme.spacing(0.25, 1),
          typography: 'bodySRegular',
        })}
      >
        {activity.actionType}
      </Box>
    ),
    role: capitalize(activity.role.toLowerCase()),
    note: activity.note,
  }));

  const clearAllFilters = () => {
    setSelectedFilters({});
  };

  const headerIcon = <ChevronRightIcon24 />;

  return (
    <GenericDrawer
      anchor="right"
      handleOnClose={() => setOpenChangeLog(false)}
      drawerOpen={openChangeLog}
      showHeader={true}
      disableFooter={true}
      loading={false}
      headerTitle={'Change Log'}
      headerIcon={headerIcon}
      sxProps={{
        '.MuiDrawer-paper': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          pb: theme.spacing(2),
          width: '1250px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflowY: 'scroll',
        }}
      >
        <Box
          sx={{
            width: '100%',
            mx: 'auto',
            boxShadow: 'none',
            px: theme.spacing(2),
            pt: theme.spacing(4),
          }}
        >
          {!isLoading ? (
            activities.length === 0 ? (
              <StateCard
                primaryText="No data to display yet"
                secondaryText="When you start making changes, they'll appear here.."
              >
                <NoDataToDisplay />
              </StateCard>
            ) : (
              <>
                <SearchAndFilter
                  searchText={searchInput}
                  setSearchText={handleSearchChange}
                  filters={filters}
                  clearAllFilters={clearAllFilters}
                  searchPlaceholder="Search for people or comments"
                />
                <CustomTable columns={columns} data={data} />
              </>
            )
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
              <LottieLogo height="200px" width="200px" />
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {totalPages > 1 && !isLoading && (
            <Pagination count={totalPages} page={page} onChange={handlePageChange} sx={{ my: 2 }} />
          )}
        </Box>
      </Box>
    </GenericDrawer>
  );
};
