import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { useLoadedAuthUserContext } from '../../Auth';
import { useLoadedDepartmentInfoContext } from '../../Department';
import { RequestCardRequest, REQUEST_CARD_STATUS } from '../RequestCard/types';

interface RequestListView {
  next: string | null;
  results: RequestCardRequest[];
}

interface UseGetRequestsInput {
  status: REQUEST_CARD_STATUS;
  refetchCounter: number;
}

export const useGetRequests = (input: UseGetRequestsInput) => {
  const { refetchCounter, status } = input;
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<RequestCardRequest[]>([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();

  const requestsPanelEnabled =
    authUserState.employee.hasAdminPermission ||
    (authUserState.employee.role === 'OFFICER' && departmentInfoState.departmentInfo.settings.captainApprovesShiftTrades);

  const reset = useCallback(() => {
    setData([]);
    setPage(1);
    setHasNextPage(true);
  }, []);

  useEffect(() => {
    reset();
  }, [refetchCounter, reset]);

  useEffect(() => {
    const load = async () => {
      if (!isInitialized) {
        return;
      }

      try {
        setIsLoading(true);
        setIsError(false);

        const response = await client.get<RequestListView>('/request/inbox/', {
          params: { page, status },
        });

        setData((oldData) => {
          const keys = new Map<string, number>();
          const newData: RequestCardRequest[] = [];
          const pushData = (result: RequestCardRequest) => {
            const key = `${result.requestType}:${result.id}`;
            const index = keys.get(key);
            if (typeof index === 'number') {
              newData[index] = result;
            } else {
              newData.push(result);
              keys.set(key, newData.length - 1);
            }
          };
          oldData.forEach(pushData);
          (response.data.results || []).forEach(pushData);
          return newData;
        });

        setHasNextPage(!!response.data.next);
        setIsLoading(false);
      } catch (error) {
        captureException(error);
        setIsError(true);
        setIsLoading(false);
      }
    };

    if (requestsPanelEnabled) {
      load();
    }
  }, [isInitialized, page, status, refetchCounter, requestsPanelEnabled]);

  const initialize = () => {
    if (!isInitialized) {
      reset();
      setIsInitialized(true);
    }
  };

  const loadMore = () => {
    if (hasNextPage && !isLoading) {
      setPage(page + 1);
    }
  };

  return {
    isLoading,
    isError,
    data,
    setData,
    initialize,
    hasNextPage,
    loadMore,
  };
};
