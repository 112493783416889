import { Box, BoxProps } from '@mui/material';
import { useMemo } from 'react';
import {
  Snackbar,
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
  getDepartmentFeatureFlagValue,
  useAuthUserCapabilities,
  Capability,
} from '@stationwise/component-module';
import { EmployeeTypes } from '@stationwise/share-types';
import { IntercomHandler } from '../components/IntercomHandler';
import { MenuIconTypes } from '../components/Navigation/SideBar/MenuIcon';
import { SideBar } from '../components/Navigation/SideBar/SideBar';
import { TopBar } from '../components/Navigation/TopBar/TopBar';
import { ROUTES } from '../core/Routes';
import { NoMatch } from './pages/NoMatch';

export const calendarOptions = ['Month', 'List'];
export const calendarPaths = calendarOptions.map((option) => `${ROUTES.CALENDAR.fullRoute}?display=${option.toLowerCase()}`);

export const navigationItems: INavMenu[] = [
  // USER ITEMS
  {
    type: 'my-dashboard',
    path: ROUTES.MY_DASHBOARD.fullRoute,
    match: [ROUTES.MY_DASHBOARD.fullRoute],
    adminOnly: false,
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    type: 'calendar',
    path: calendarPaths[0],
    match: [ROUTES.CALENDAR.fullRoute].concat(calendarPaths),
    adminOnly: false,
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    type: 'my-roster',
    path: ROUTES.PERSONAL_ROSTER.fullRoute,
    match: [ROUTES.PERSONAL_ROSTER.fullRoute],
    adminOnly: false,
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    type: 'my-payroll',
    path: ROUTES.MY_PAYROLL.fullRoute,
    match: [ROUTES.MY_PAYROLL.fullRoute],
    adminOnly: false,
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    type: 'my-overtime-list',
    path: ROUTES.MY_OVERTIME_LIST.fullRoute,
    match: [ROUTES.MY_OVERTIME_LIST.fullRoute],
    adminOnly: false,
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    type: 'my-staffing-lists',
    path: ROUTES.MY_STAFFING_LISTS.fullRoute,
    match: [ROUTES.MY_STAFFING_LISTS.fullRoute],
    adminOnly: false,
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    type: 'preferences',
    path: ROUTES.PREFERENCES.fullRoute,
    match: [ROUTES.PREFERENCES.fullRoute],
    adminOnly: false,
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    type: 'support',
    path: '',
    match: [],
    adminOnly: false,
    mobileOnly: true,
    desktopOnly: false,
  },
  {
    type: 'change-department',
    path: '',
    match: [],
    adminOnly: false,
    mobileOnly: true,
    desktopOnly: false,
  },
  {
    type: 'logout',
    path: '',
    match: [],
    adminOnly: false,
    mobileOnly: true,
    desktopOnly: false,
  },
  // ADMIN ITEMS
  {
    type: 'dashboard',
    path: '/app/dashboard',
    match: ['/app/dashboard'],
    adminOnly: true,
    mobileOnly: false,
    desktopOnly: true,
  },
  {
    type: 'roster',
    path: '/app/roster-view',
    match: ['/app/roster-view', '/app/shift-template'],
    adminOnly: true,
    mobileOnly: false,
    desktopOnly: true,
  },
  {
    type: 'employees',
    path: '/app/employees',
    match: ['/app/employees', '/app/employees/:employeeId'],
    adminOnly: true,
    mobileOnly: false,
    desktopOnly: true,
  },
  {
    type: 'overtime-list',
    path: '/app/overtime-list/',
    match: ['/app/overtime-list/'],
    adminOnly: true,
    mobileOnly: false,
    desktopOnly: true,
  },
  {
    type: 'staffing-lists',
    path: '/app/staffing-lists/',
    match: ['/app/staffing-lists/'],
    adminOnly: true,
    mobileOnly: false,
    desktopOnly: true,
  },
  {
    type: 'payroll',
    path: '/app/payroll',
    match: ['/app/payroll', '/app/payroll/employee/:employeeId'],
    adminOnly: true,
    mobileOnly: false,
    desktopOnly: true,
  },
  {
    type: 'department-settings',
    path: '/app/department-settings',
    match: ['/app/department-settings'],
    adminOnly: true,
    mobileOnly: false,
    desktopOnly: true,
  },
];

export interface INavMenu {
  type: MenuIconTypes;
  path: string;
  match: string[];
  onClick?: () => void;
  adminOnly: boolean;
  desktopOnly: boolean;
  mobileOnly: boolean;
}

interface AppPageLayoutProps extends BoxProps<'div'> {
  fixedPosition?: boolean;
  requiredCapability?: Capability;
}

export const AppPageLayout = ({ children, fixedPosition = false, requiredCapability, sx, ...props }: AppPageLayoutProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { state: authUserState } = useLoadedAuthUserContext();
  const capabilities = useAuthUserCapabilities();
  const isDepartmentSettingsEnabled =
    getDepartmentFeatureFlagValue(departmentInfoState, 'ADMIN_DEPARTMENT_SETTINGS', false) &&
    capabilities.EDIT_DEPARTMENT_SETTINGS;
  const canViewTeamSettings = capabilities.VIEW_TEAM_SETTINGS;
  const canViewStaffingList = capabilities.VIEW_STAFFING_LIST;
  const isStaffingListsEnabled = getDepartmentFeatureFlagValue(departmentInfoState, 'STAFFING_LISTS', false);
  const isApparatusLogin = authUserState.employee.isApparatusLogin;
  const { sideNavItems, topNavItems } = useMemo(() => {
    const isAdmin = ['SUPER_ADMIN', 'ADMIN'].includes(authUserState.employee.role);
    const { isNonShift, mobileIntercomSupportEnabled } = authUserState.employee;
    const isVolunteer = authUserState.employee.employeeType === EmployeeTypes.VOLUNTEER;

    const enabledNavItems = navigationItems.filter((navItem) => {
      if (navItem.adminOnly && !isAdmin) {
        return false;
      }
      if (navItem.type === 'my-dashboard') {
        return !isVolunteer && !isNonShift;
      }
      if (navItem.type === 'employees') {
        return canViewTeamSettings;
      }
      if (navItem.type === 'my-overtime-list') {
        return !isStaffingListsEnabled && !isVolunteer && !isNonShift;
      }
      if (navItem.type === 'my-staffing-lists') {
        return isStaffingListsEnabled && !isVolunteer && !isNonShift;
      }
      if (navItem.type === 'support') {
        return mobileIntercomSupportEnabled;
      }
      if (navItem.type === 'change-department') {
        return authUserState.employee.departments.length > 1;
      }
      if (navItem.type === 'overtime-list') {
        return canViewStaffingList && !isStaffingListsEnabled;
      }
      if (navItem.type === 'staffing-lists') {
        return canViewStaffingList && isStaffingListsEnabled;
      }
      if (navItem.type === 'department-settings') {
        return isDepartmentSettingsEnabled;
      }
      return true;
    });

    const sideNavItems = enabledNavItems.filter((navItem) => {
      if (isAdmin) {
        return navItem.adminOnly;
      }

      return !navItem.adminOnly && !navItem.mobileOnly;
    });

    const topNavItems = enabledNavItems.filter((navItem) => !navItem.desktopOnly);

    return { sideNavItems, topNavItems };
  }, [isDepartmentSettingsEnabled, isStaffingListsEnabled, canViewTeamSettings, canViewStaffingList, authUserState.employee]);

  if (requiredCapability && !capabilities[requiredCapability]) {
    return <NoMatch />;
  }

  return (
    <Box
      data-cy={`app-${departmentInfoState.departmentInfo.domain}`}
      {...props}
      sx={[
        (theme) => ({
          backgroundColor: theme.palette.stationGray[100],
          height: '100vh',
          width: '100vw',
          position: 'relative',
          zIndex: 0,
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          height: { xs: 'default', lg: '100%' },
          width: '100%',
          position: 'relative',
        }}
      >
        {!isApparatusLogin && <SideBar moreNavItems={topNavItems} navItems={sideNavItems} />}
        <Box
          sx={{
            flex: 1,
            ml: { xs: 0, lg: '64px' },
            height: '100%',
            mt: { xs: isApparatusLogin ? '0px' : '56px', lg: '0px' },
            width: { xs: '100%', lg: 'calc(100% - 64px)' },
            overflow: 'auto',
          }}
        >
          {!isApparatusLogin && (
            <Box
              sx={{
                display: { xs: 'block', lg: 'none' },
              }}
            >
              <TopBar navItems={topNavItems} fixedPosition={fixedPosition} />
            </Box>
          )}
          {children}
        </Box>
      </Box>
      <IntercomHandler />
      <Snackbar />
    </Box>
  );
};
