import { Box } from '@mui/material';
import { format, differenceInMinutes } from 'date-fns';
import { Badge, Button, useAuthUserContext, useDepartmentInfoContext } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { CreateAdditionalPaidTimeDTO, PayCode } from '@stationwise/share-types';
import { parseHourAndMinute } from '@stationwise/share-utils';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';
import { ADDITIONAL_PAID_TIME_OPTIONS } from './AdditionalPaidTimeContent';

interface CardProps {
  handleAdditionalPaidTimeRequest: (responseMessage: string, error: boolean) => void;
  additionalPaidTime: {
    start: Date;
    end: Date;
  };
  note: string;
  eventType: string | null;
  payCode: PayCode | null;
  selectedButton: string;
}

export const AdditionalPaidTimeFooter = ({
  additionalPaidTime,
  note,
  handleAdditionalPaidTimeRequest,
  eventType,
  payCode,
  selectedButton,
}: CardProps) => {
  const iosWebViewPaddingBottom = useIOSPaddingBottom();
  const { state } = useAuthUserContext();
  const {
    state: { departmentInfo },
  } = useDepartmentInfoContext();

  const sendRequest = async (requestDto: CreateAdditionalPaidTimeDTO | null) => {
    let error = false;
    let responseMessage = '';
    try {
      await client.post('request/additional-paid-time-request/', requestDto);
      responseMessage = 'Your additional paid time request has been successfully submitted';
    } catch (err: unknown) {
      error = true;
      if (isAxiosError(err) && typeof err.response?.data.detail === 'string') {
        responseMessage = err.response.data.detail;
      } else if (isAxiosError(err) && typeof err.response?.data.nonFieldErrors?.[0] === 'string') {
        responseMessage = err.response.data.nonFieldErrors[0];
      } else {
        responseMessage = 'We were unable to process your additional paid time request. Please try again later.';
      }
    } finally {
      handleAdditionalPaidTimeRequest(responseMessage, error);
    }
  };

  const prepareRequest = (additionalPaidTime: { start: Date; end: Date }): [number, number, string] => {
    const [departmentStartHours, departmentStartMinutes] = departmentInfo
      ? parseHourAndMinute(departmentInfo.shiftStart)
      : [0, 0];
    const hour = additionalPaidTime.start.getHours();
    const minute = additionalPaidTime.start.getMinutes();
    const startTimeFromDepartmentShiftStart = (hour - departmentStartHours) * 60 + (minute - departmentStartMinutes);
    const minutesDiff = differenceInMinutes(additionalPaidTime.end, additionalPaidTime.start);
    const shiftDate = format(additionalPaidTime.start, 'yyyy-MM-dd');
    return [startTimeFromDepartmentShiftStart, minutesDiff, shiftDate];
  };

  const handleRequest = (additionalPaidTime: { start: Date; end: Date }, note: string) => {
    const [startTime, duration, shiftDate] = prepareRequest(additionalPaidTime);
    let selectedPayCodeCode = null;
    if (payCode) {
      selectedPayCodeCode = payCode?.code;
    } else if (selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.ARRIVED_EARLY) {
      selectedPayCodeCode = state.employee?.defaults.arrivedEarlyPayCode.code || null;
    } else if (selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.HELD_OVER) {
      selectedPayCodeCode = state.employee?.defaults.heldOverPayCode.code || null;
    }
    const requestDto: CreateAdditionalPaidTimeDTO = {
      note: note,
      start_time: startTime,
      duration: duration,
      additional_paid_time_date: shiftDate,
      pay_code: selectedPayCodeCode,
      additional_paid_time_type: selectedButton,
    };

    sendRequest(requestDto);
  };

  return (
    <Box
      sx={(theme) => ({
        position: 'fixed',
        bottom: iosWebViewPaddingBottom,
        width: '100%',
        border: '1px solid #DEE3ED',
        borderBottom: 0,
        boxShadow: '0px -10px 15px -3px rgba(10, 14, 22, 0.1)',
        backgroundColor: theme.palette.common.white,
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          m: theme.spacing(2),
          justifyContent: 'left',
        })}
      >
        <Badge label="ADDITIONAL PAID TIME" color="green" variant="bodySMedium" overrideFontSize="14px" overrideMaxLength={20} />
      </Box>

      <Box sx={(theme) => ({ display: 'flex', px: theme.spacing(2), typography: 'bodyMRegular' })}>
        {additionalPaidTime.start?.toString() && <Box>{format(additionalPaidTime.start?.toString(), 'M/d/yy')}</Box>}
        <Box
          display="flex"
          sx={(theme) => ({
            ml: '10px',
            color: theme.palette.stationGray[400],
          })}
        >
          {additionalPaidTime.start?.toString() && <Box>{format(additionalPaidTime.start?.toString(), 'HH:mm')}</Box>}
          <Box sx={{ ml: '3px', mr: '3px' }}>-</Box>
          {additionalPaidTime.end?.toString() && <Box>{format(additionalPaidTime.end?.toString(), 'HH:mm')}</Box>}
        </Box>
      </Box>

      <Box
        sx={(theme) => ({
          p: theme.spacing(2),
          width: '100%',
        })}
      >
        <Button
          data-cy="submit-additional-paid-time-button"
          buttonType="tertiary"
          buttonSize="sm"
          disabled={(() => {
            if (
              differenceInMinutes(additionalPaidTime.end, additionalPaidTime.start) <= 0 ||
              (selectedButton !== ADDITIONAL_PAID_TIME_OPTIONS.INCIDENT &&
                differenceInMinutes(additionalPaidTime.end, additionalPaidTime.start) > 24 * 60)
            ) {
              return true;
            }
            if (selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.INCIDENT) {
              return !(!!eventType && !!payCode);
            }
            return note && ((eventType && payCode) || selectedButton !== ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY)
              ? false
              : true;
          })()}
          variant="contained"
          onClick={() => handleRequest(additionalPaidTime, note)}
          sx={(theme) => ({
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            width: '100%',
            borderWidth: theme.spacing(0.1),
            textTransform: 'none',
            borderRadius: theme.spacing(0.75),
          })}
        >
          Submit additional paid time
        </Button>
      </Box>
    </Box>
  );
};
