import { format } from 'date-fns';
import { useState } from 'react';
import { client } from '@stationwise/share-api';
import { AvailableCompTime } from '../components/utils';

export const useCompTimeRequest = (setAvailableCompTimes: (compTimes: AvailableCompTime[]) => void) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleRequestCompTime = async (day: Date, overtimeCode: string) => {
    const params = {
      overtimeDate: format(day, 'yyyy-MM-dd'),
      overtimeCode: overtimeCode,
    };

    setLoading(true);
    setError(null);

    try {
      const response = await client.get('request/comp-time-request/available-comp-time/', { params });
      if (response.data && response.data.length > 0) {
        setAvailableCompTimes(response.data);
      } else {
        setAvailableCompTimes([]);
      }
    } catch {
      setError('Failed to fetch available comp time.');
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, handleRequestCompTime };
};
