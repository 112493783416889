import { MenuItem, Box, Chip, OutlinedInput, Select, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import { CheckIcon16, ChevronDownIcon16 } from '../../assets';
import { AdminSelectItem } from './AdminSelect';

export const MultipleSelect = ({
  items,
  selectedItems,
  setSelectedItems,
  labelStyles,
  secondLabelStyles,
  placeholder,
  hideValue,
  showLabel,
}: {
  items: AdminSelectItem[];
  selectedItems: string[];
  setSelectedItems: (selected: string[]) => void;
  labelStyles?: SxProps<Theme>;
  secondLabelStyles?: SxProps<Theme>;
  placeholder: string;
  hideValue?: boolean;
  showLabel?: boolean;
}) => {
  const onChange = (event: SelectChangeEvent<typeof selectedItems>) => {
    const {
      target: { value },
    } = event;
    if (value.includes('')) {
      setSelectedItems([]);
    } else {
      setSelectedItems(typeof value === 'string' ? value.split(',') : value);
    }
  };
  return (
    <Select
      multiple
      displayEmpty
      value={selectedItems}
      onChange={onChange}
      sx={{
        borderRadius: '8px',
        typography: 'bodySRegular',
        '& .MuiSelect-icon': { right: '16px' },
        width: '100%',
        scroll: 'auto',
        maxHeight: '220px',
        overflowX: 'hidden',
      }}
      MenuProps={{
        autoFocus: false,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: { vertical: 'top', horizontal: 'left' },
        sx: (theme) => ({
          '&& .Mui-selected': {
            backgroundColor: theme.palette.common.white,
            borderRadius: '8px',
            border: `1px solid ${theme.palette.stationGray[900]}`,
          },
        }),
        PaperProps: {
          sx: {
            maxHeight: 264,
          },
        },
      }}
      input={<OutlinedInput />}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return (
            <Box
              sx={(theme) => ({
                typography: 'bodyMRegular',
                color: theme.palette.stationGray[500],
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              })}
            >
              {placeholder}
            </Box>
          );
        }
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => {
              const item = items.find((item) => item.value === value);
              const color = item?.color;
              const displayText = showLabel ? item?.label : value;
              return (
                <Chip
                  variant="outlined"
                  label={displayText}
                  key={value}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.common.white,
                    border: 'none',
                    height: '24px',
                    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
                    color: !color || color === 'white' ? theme.palette.stationGray[500] : color,
                  })}
                />
              );
            })}
          </Box>
        );
      }}
      IconComponent={ChevronDownIcon16}
    >
      {items.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={(theme) => ({
            borderRadius: theme.spacing(1),
            color: theme.palette.stationGray[900],
            typography: 'bodySRegular',
            whiteSpace: 'normal',
            mx: theme.spacing(1.5),
          })}
        >
          <Box display="flex" justifyContent="space-between" sx={{ width: '100%' }}>
            <Box
              display="flex"
              sx={[
                ...(Array.isArray(labelStyles) ? labelStyles : labelStyles ? [labelStyles] : []),
                (theme) => ({
                  typography: 'bodyMRegular',
                  color: option.value === '' ? theme.palette.stationGray[500] : 'inherit',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }),
              ]}
            >
              {option.value !== '' && !hideValue && (
                <Box
                  key={option.value}
                  sx={(theme) => ({
                    typography: 'bodyMRegular',
                    height: '24px',
                    color: !option.color || option.color === 'white' ? theme.palette.stationGray[500] : option.color,
                    width: '50px',
                  })}
                >
                  {option.value}
                </Box>
              )}
              <Box sx={{ ml: '5px' }}>{option.label}</Box>
            </Box>
            <Box
              sx={[
                (theme) => ({
                  display: 'flex',
                  pt: '3px',
                  pr: '2px',
                  typography: 'bodySMedium',
                  color: theme.palette.stationGray[500],
                  '& svg path': {
                    stroke: theme.palette.stationGray[900],
                  },
                }),
                ...(Array.isArray(secondLabelStyles) ? secondLabelStyles : secondLabelStyles ? [secondLabelStyles] : []),
              ]}
            >
              {selectedItems.includes(option.value) && <CheckIcon16 />}
            </Box>
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};
