import { Box, TextField } from '@mui/material';
import { ArrowRightIcon16, Link03Icon16, XCloseIcon16 } from '../../../assets';

interface EmbedLinkProps {
  link: string;
  setLink: (link: string) => void;
  handleOnCloseEmbedLink: () => void;
  handleOnSaveLink: () => void;
}
export const EmbedLink = ({ link, setLink, handleOnCloseEmbedLink, handleOnSaveLink }: EmbedLinkProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={(theme) => ({
        width: '95%',
        height: '37px',
        border: `1px solid ${theme.palette.stationGray[200]}`,
        borderRadius: theme.spacing(1),
        position: 'absolute',
        bottom: '65px',
        left: theme.spacing(2),
        pt: theme.spacing(1),
        pl: theme.spacing(1),
        boxShadow: '0px 4px 6px -2px rgba(10, 14, 22, 0.05), 0px 10px 15px -3px rgba(10, 14, 22, 0.1)',
      })}
    >
      <Link03Icon16 />
      <Box sx={(theme) => ({ width: '100%', mt: '-7px', ml: theme.spacing(1) })}>
        <TextField
          value={link}
          autoFocus
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          fullWidth
          onChange={(event) => {
            setLink(event.currentTarget.value);
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              if (link) {
                handleOnSaveLink();
              }
            }
          }}
          placeholder="Type URL and then press enter"
          inputProps={{
            maxLength: 10000,
            'data-testid': 'embed-link-input',
          }}
        />
      </Box>
      <Box
        display="flex"
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Box
          onClick={handleOnCloseEmbedLink}
          sx={(theme) => ({
            width: '20px',
            height: '20px',
            borderRadius: '20px',
            border: `1px solid ${theme.palette.stationGray[200]}`,
            textAlign: 'center',
            mr: '5px',
          })}
        >
          <Box sx={(theme) => ({ color: theme.palette.stationGray[900], mt: theme.spacing(-0.1) })}>
            <XCloseIcon16 />
          </Box>
        </Box>
        <Box
          data-testid="save-link"
          onClick={handleOnSaveLink}
          sx={(theme) => ({
            width: '20px',
            height: '20px',
            borderRadius: '20px',
            background: theme.palette.stationBlue[700],
            textAlign: 'center',
            mr: theme.spacing(1),
          })}
        >
          <Box
            sx={(theme) => ({
              color: theme.palette.common.white,
            })}
          >
            <ArrowRightIcon16 />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
