import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import {
  FeedbackMessage,
  GenericDrawer,
  SnackbarService,
  getDepartmentFeatureFlagValue,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { CancelRequest } from '../CancelRequest';

interface Props {
  handleOnClose: Dispatch<SetStateAction<boolean>>;
  drawerOpen: boolean;
  shift: EventInput | EventImpl;
  setRefetchEvents: Dispatch<React.SetStateAction<boolean>>;
}

export const CancelOvertimeRequest = ({ shift, handleOnClose, drawerOpen, setRefetchEvents }: Props) => {
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const isOptOut = departmentContext.departmentInfo.settings.voluntaryOvertimeOptOut;
  const isStaffingListsEnabled = getDepartmentFeatureFlagValue(departmentContext, 'STAFFING_LISTS', false);

  const cancelOvertimeRequest = async () => {
    let success = false;
    try {
      if (isStaffingListsEnabled) {
        const response = await client.put('/staffing-list/staffing-lists/vot/my-marks/', {
          status: 'CANCELLED',
          dates: [format(new Date(shift.start as string), 'yyyy-MM-dd')],
        });
        success = response.status === 200;
      } else {
        const response = await client.patch(`/shift/overtimes/${shift.id}/`, {
          status: 'CANCELLED',
        });
        success = response.status === 200;
      }

      if (success) {
        setRefetchEvents(true);
      }
    } catch {
      success = false;
    }
    handleOnClose(false);
    SnackbarService.notify({
      content: success
        ? isOptOut
          ? 'Your voluntary OT opt out has been successfully canceled.'
          : 'Your voluntary OT request has been successfully canceled.'
        : 'There was an issue processing your request. ',
      severity: success ? 'success' : 'error',
      ...(success
        ? { showCloseButton: true }
        : {
            onCallToAction: () => handleRequest(),
            actionButtonText: 'Try again',
          }),
      duration: 5000,
    });
  };

  const handleRequest = () => {
    cancelOvertimeRequest();
  };

  return (
    <GenericDrawer
      anchor="bottom"
      drawerOpen={drawerOpen}
      handleOnClose={() => handleOnClose(false)}
      loading={false}
      showHeader={false}
      disableFooter={true}
      headerTitle={'Additional paid time '}
    >
      <div>
        <Box>
          <FeedbackMessage
            status="question"
            firstMessage={
              isOptOut ? 'Do you really wish to cancel the overtime opt out?' : 'Do you really wish to cancel this request?'
            }
            secondMessage={
              isOptOut
                ? ''
                : "After you cancel your request, your battalion chief will be informed and you won't be able to request voluntary overtime for this day again."
            }
          />
          <CancelRequest handleOnClose={handleOnClose} handleRequest={handleRequest} isOptOut={isOptOut} />
        </Box>
      </div>
    </GenericDrawer>
  );
};
