import { Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Button } from '../../../Button';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
}

export const ShiftTradeCancelButtons = ({ onClose, onSubmit, step, setStep }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        justifyContent: 'space-between',
        display: 'flex',
        mt: theme.spacing(2),
        width: '380px',
      })}
    >
      <Button
        sx={(theme) => ({
          height: '44px',
          width: '182px',
          padding: '9px 17px 9px 17px',
          borderRadius: theme.spacing(0.75),
        })}
        buttonType="tertiary"
        variant="contained"
        onClick={step === 1 ? () => onClose() : () => setStep(1)}
      >
        <Box component="span" sx={{ typography: 'buttonL' }}>
          Cancel
        </Box>
      </Button>
      <Button
        sx={(theme) => ({
          height: '44px',
          width: '182px',
          padding: '9px 17px 9px 17px',
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          borderRadius: theme.spacing(0.75),
          '&:hover': { backgroundColor: theme.palette.stationGray[700], boxShadow: 'none' },
        })}
        buttonType="tertiary"
        variant="contained"
        disableFocusRipple
        disableTouchRipple
        onClick={step === 1 ? () => setStep(2) : () => onSubmit()}
      >
        <Box component="span" sx={{ typography: 'buttonL' }}>
          {step === 1 ? 'Submit' : 'Confirm'}
        </Box>
      </Button>
    </Box>
  );
};
