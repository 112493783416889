import { Box } from '@mui/material';
import { MinReqRecord } from './MinReqRecord';

interface Props {
  name: string;
  total: number;
  amount: number;
  color: string;
}

export const CertificationRecord = ({ name, total, amount, color }: Props) => {
  return (
    <Box mt={1}>
      <MinReqRecord total={total} amount={amount}>
        <Box
          sx={(theme) => ({
            background: theme.palette.common.white,
            color,
            px: 1,
            borderRadius: '30px',
            typography: 'bodyXSMedium',
          })}
        >
          {name}
        </Box>
      </MinReqRecord>
    </Box>
  );
};
