import { useTheme } from '@mui/material';
import { RosterStrikeTeamApparatus } from '@stationwise/share-types';
import { Actions } from '../../../Actions';
import { useRosterContext } from '../RosterContext';

interface Props {
  apparatus: RosterStrikeTeamApparatus;
}

export const StrikeTeamActions = ({ apparatus }: Props) => {
  const { createStrikeTeamModalState, selectedStrikeTeamApparatusState } = useRosterContext();
  const theme = useTheme();

  const duplicateEvent = () => {
    createStrikeTeamModalState.setIsOpen(true);
    createStrikeTeamModalState.setDuplicatedFromApparatus(apparatus);
  };

  const undeployEvent = () => {
    selectedStrikeTeamApparatusState.setSelectedApparatus(apparatus);
    selectedStrikeTeamApparatusState.setSelectedAction('UNDEPLOY_EVENT');
  };

  const editEvent = () => {
    selectedStrikeTeamApparatusState.setSelectedApparatus(apparatus);
    selectedStrikeTeamApparatusState.setSelectedAction('EDIT_EVENT');
  };

  const addEmployeeToEvent = () => {
    selectedStrikeTeamApparatusState.setSelectedApparatus(apparatus);
    selectedStrikeTeamApparatusState.setSelectedAction('ADD_EMPLOYEE_TO_EVENT');
  };

  const actions = [
    { label: 'Edit event', onClick: editEvent },
    { label: 'Duplicate event', onClick: duplicateEvent },
    { label: 'Undeploy event', onClick: undeployEvent },
    { label: 'Add employee to event', onClick: addEmployeeToEvent },
  ];

  return <Actions hoverColor={theme.palette.stationTeal[200]} actions={actions} />;
};
