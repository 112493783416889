import { Box, BoxProps } from '@mui/material';
import React from 'react';
import { Logo } from '../Logo';

const getCommonCssAttributes = ({ delay }: { delay: string }) =>
  ({
    animation: `load 1.5s infinite`,
    animationDelay: `${delay}`,
    '@keyframes load': {
      '0%': {
        opacity: '0',
      },
      '50%': {
        opacity: '0',
      },
      '100%': {
        opacity: '1',
      },
    },
  }) as React.CSSProperties;

type Props = Omit<BoxProps<'span'>, 'children'>;

export const Loader = ({ sx, ...props }: Props) => {
  return (
    <Box
      data-testid="loader"
      component="span"
      {...props}
      sx={[{ display: 'flex', justifyContent: 'center', gap: 2 }, ...(Array.isArray(sx) ? sx : sx ? [sx] : [])]}
    >
      <Logo sx={getCommonCssAttributes({ delay: '0.1s' })} />
      <Logo sx={getCommonCssAttributes({ delay: '0.3s' })} />
      <Logo sx={getCommonCssAttributes({ delay: '0.5s' })} />
    </Box>
  );
};
