import { Box, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { Mail02Icon16, CheckCircleBrokenIcon16, MedicalCrossIcon16 as EMSIcon, StandIcon16 } from '../../assets';
import { SelectCategoryItem } from '../../components';

export const ConversationCategories = (): SelectCategoryItem[] => {
  const theme = useTheme();

  // @TODO https://app.clickup.com/t/9006090230/SW-3268
  // get categories from server
  const categories: SelectCategoryItem[] = useMemo(
    () => [
      {
        label: 'GENERAL',
        value: 'General',
        icon: (
          <Box
            sx={{
              '& path': {
                stroke: theme.palette.stationLightBlue[500],
              },
              '& svg': {
                width: '12px',
                height: '12px',
              },
            }}
          >
            <Mail02Icon16 />
          </Box>
        ),
        color: theme.palette.stationLightBlue[500],
      },
      {
        label: 'TO-DO',
        value: 'To-Do',
        icon: (
          <Box
            sx={{
              '& path': {
                stroke: theme.palette.stationPurple[500],
              },
              '& svg': {
                width: '12px',
                height: '12px',
              },
            }}
          >
            <CheckCircleBrokenIcon16 />
          </Box>
        ),
        color: theme.palette.stationPurple[500],
      },
      {
        label: 'EMS',
        value: 'EMS',
        icon: (
          <Box
            sx={{
              '& path': {
                stroke: theme.palette.stationRose[500],
              },
              '& svg': {
                width: '12px',
                height: '12px',
              },
            }}
          >
            <EMSIcon />
          </Box>
        ),
        color: theme.palette.stationRose[500],
      },
      // {
      //   label: 'STAFFING',
      //   value: 'STAFFING',
      //   icon: (
      //     <Box
      //       sx={({
      //         '& path': {
      //           stroke: theme.palette.stationBlue[500],
      //         },
      //         '& svg': {
      //           width: '12px',
      //           height: '12px',
      //         },
      //       })}
      //     >
      //       <StaffingIcon />
      //     </Box>
      //   ),
      //   color: theme.palette.stationBlue[500],
      // },
      {
        label: 'TRAINING',
        value: 'Training',
        icon: (
          <Box
            sx={{
              '& path': {
                stroke: theme.palette.stationGreen[500],
              },
              '& svg': {
                width: '12px',
                height: '12px',
              },
            }}
          >
            <StandIcon16 />
          </Box>
        ),
        color: theme.palette.stationGreen[500],
      },
    ],
    [theme],
  );
  return categories;
};
