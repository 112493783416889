import { Navigate } from 'react-router-dom';
import { getDepartmentFeatureFlagValue, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { StaffingListsMain } from './components/StaffingListsMain';

export const StaffingLists = () => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isStaffingListsEnabled = getDepartmentFeatureFlagValue(departmentInfoState, 'STAFFING_LISTS', false);
  if (!isStaffingListsEnabled) {
    return <Navigate to="/app/overtime-list" replace={true} />;
  }

  return <StaffingListsMain />;
};
