import { Box, useMediaQuery, Theme } from '@mui/material';
import { useState, MouseEvent } from 'react';
import { RosterStation as RosterStationType } from '@stationwise/share-types';
import { checkIsShift, checkIsStrikeTeam, getTotalStaffed, stationHasMinRequirements } from '@stationwise/shift-summary-helper';
import { Counter } from '../../../Counter';
import { Popover, POPOVER_POSITION } from '../../../Popover';
import { StationHeader } from '../../../Station';
import { StationMinReq } from '../StationMinReq';
import { RosterApparatus } from './RosterApparatus';

interface Props {
  station: RosterStationType;
}

export const RosterStation = ({ station }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const totalStaffed = getTotalStaffed(station);
  const hasMinRequirements = stationHasMinRequirements(station);

  const hasStrikeTeams = station.apparatuses.some((app) => checkIsStrikeTeam(app));
  const hasRequirements = checkIsShift(station) || hasStrikeTeams;

  const handleOpenClick = (event: MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
  const handleCloseClick = () => setAnchorEl(null);

  return (
    <Box>
      <StationHeader station={station} onClick={hasRequirements ? handleOpenClick : undefined} anchorEl={anchorEl}>
        {hasRequirements ? (
          <Counter
            total={totalStaffed.total}
            amount={totalStaffed.amount}
            overrideIcon
            overrideIconToShow={hasMinRequirements ? 'inGoal' : 'belowGoal'}
          />
        ) : undefined}
      </StationHeader>
      {hasRequirements && (
        <Popover
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleCloseClick}
          withArrow={!isMobile}
          {...(isMobile ? POPOVER_POSITION.TOP : POPOVER_POSITION.RIGHT)}
          sx={(theme) => ({
            '& .MuiPaper-root': { width: '360px' },
            '& .SWPopover-content': { backgroundColor: theme.palette.stationGray[800], borderRadius: '8px' },
            '& .SWPopover-arrow': { backgroundColor: theme.palette.stationGray[800] },
          })}
        >
          <StationMinReq onClose={handleCloseClick} station={station} />
        </Popover>
      )}
      {station.apparatuses.map((apparatus) => {
        return <RosterApparatus key={apparatus.id} apparatus={apparatus} />;
      })}
    </Box>
  );
};
