import { ReactComponent as ArrowDropDownSymbol } from '@material-symbols/svg-400/outlined/arrow_drop_down.svg';
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemText,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { SvgIcon } from '../../../../SvgIcon';

export const useStaffingListFilterGroup = (
  appliedOptions: Set<string>,
  setAppliedOptions: Dispatch<SetStateAction<Set<string>>>,
) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState(appliedOptions);

  const checkIsEqualOptions = (a: Set<string>, b: Set<string>) => a.size === b.size && Array.from(a).every((v) => b.has(v));

  const isDirty = !checkIsEqualOptions(selectedOptions, appliedOptions);

  useEffect(() => {
    setSelectedOptions(appliedOptions);
  }, [appliedOptions]);

  return { appliedOptions, isDirty, isOpen, selectedOptions, setAppliedOptions, setIsOpen, setSelectedOptions };
};

export type StaffingListFilterState = ReturnType<typeof useStaffingListFilterGroup>;

interface Props<T> extends StaffingListFilterState {
  title: ReactNode;
  options: T[];
  optionsSx?: SxProps<Theme>;
  getOptionKey: (option: T) => string;
  getOptionLabel: (option: T) => ReactNode;
}

export const StaffingListFilterGroup = <T,>(props: Props<T>) => {
  const theme = useTheme();

  return (
    <>
      <ListItem component="div" disableGutters={true} divider={true} sx={{ color: theme.palette.text.secondary }}>
        <ListItemText primary={props.title} />
        <IconButton size="small" aria-label="Toggle" onClick={() => props.setIsOpen(!props.isOpen)}>
          <SvgIcon
            component={ArrowDropDownSymbol}
            sx={{ color: theme.palette.action.active, fontSize: '22px', transform: !props.isOpen ? 'rotate(180deg)' : undefined }}
          />
        </IconButton>
      </ListItem>
      <Collapse in={props.isOpen} timeout={0}>
        <Box
          sx={[
            { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 1 },
            ...(Array.isArray(props.optionsSx) ? props.optionsSx : props.optionsSx ? [props.optionsSx] : []),
          ]}
        >
          {props.options.map((option) => {
            const optionKey = props.getOptionKey(option);

            return (
              <FormControlLabel
                key={optionKey}
                data-cy={`${makeTestIdentifier(optionKey)}-checkbox`}
                label={props.getOptionLabel(option)}
                sx={{ m: 0 }}
                control={
                  <Checkbox
                    size="small"
                    checked={props.selectedOptions.has(optionKey)}
                    onChange={(event) => {
                      const newSelectedOptions = new Set(props.selectedOptions);
                      event.target.checked ? newSelectedOptions.add(optionKey) : newSelectedOptions.delete(optionKey);
                      props.setSelectedOptions(newSelectedOptions);
                    }}
                  />
                }
              />
            );
          })}
        </Box>
      </Collapse>
    </>
  );
};
