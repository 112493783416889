import { Box } from '@mui/material';
import { theme } from '@stationwise/component-module';
import { PayrollCode } from '@stationwise/share-types';
import { AccrueCompTimeCardWrapper } from './AccrueCompTimeCardWrapper';
import { AvailableCompTime } from './utils';

export const AccrueCompTimeCards = ({
  children,
  availableCompTimes,
  selectedIndex,
  setSelectedIndex,
  payrollCode,
}: {
  children?: React.ReactNode;
  availableCompTimes: AvailableCompTime[];
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  payrollCode: PayrollCode | null;
}) => {
  return (
    <Box
      className="accrue-comp-time-cards"
      sx={{
        ml: theme.spacing(2),
        mr: theme.spacing(2),
      }}
    >
      {availableCompTimes.map((compTime, index) => (
        <AccrueCompTimeCardWrapper
          key={index}
          compTime={compTime}
          index={index}
          isSelected={index === selectedIndex}
          onSelect={setSelectedIndex}
          payrollCode={payrollCode}
        >
          {children}
        </AccrueCompTimeCardWrapper>
      ))}
    </Box>
  );
};
