import { Box, Divider, Modal, TextField, Typography, Tooltip } from '@mui/material';
import { Button, theme } from '@stationwise/component-module';
import { Change } from './utils';

interface OverrideModalProps {
  overrideModalOpen: boolean;
  setOverrideModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  change: Change | null;
  note: string;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  onConfirm: () => void;
  payCodes: { code: string; name: string }[];
}

export const OverrideModal = ({
  overrideModalOpen,
  setOverrideModalOpen,
  change,
  note,
  setNote,
  onConfirm,
  payCodes,
}: OverrideModalProps) => {
  const handleModalClose = () => {
    setOverrideModalOpen(false);
    setNote('');
  };
  const getCodeName = (code: string | undefined): string => {
    if (!code) return '';
    const payCodeItem = payCodes.find((item) => item.code.trim() === code.trim());
    if (payCodeItem) {
      return `${payCodeItem.code} - ${payCodeItem.name}`;
    }
    return code;
  };

  const confirm = () => {
    setOverrideModalOpen(false);
    setNote('');
    onConfirm();
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={overrideModalOpen}
      onClose={() => handleModalClose()}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: '16px',
        })}
      >
        <Box
          sx={(theme) => ({
            padding: '24px 24px 0px 24px',
            borderRadius: '16px 16px 0px 0px',
            width: '428px',
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            maxHeight: '800px',

            typography: 'bodyXLSemibold',
          })}
        >
          <Typography sx={{ typography: 'bodyXLSemibold', marginBottom: theme.spacing(2) }}>
            {change?.field.toUpperCase()} Override
          </Typography>
          <Box
            sx={{
              padding: theme.spacing(2),
              flexDirection: 'column',
              border: `1px solid ${theme.palette.stationGray[100]}`,
              borderRadius: theme.spacing(1.5),
              marginBottom: theme.spacing(3),
            }}
          >
            <Box
              sx={{
                paddingBottom: theme.spacing(2),
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Typography variant="bodyMRegular" color={theme.palette.stationGray[500]}>{`Previous Value`}</Typography>
              <Tooltip
                title={change?.field === 'pay code' ? getCodeName(change.previousValue) : change?.previousValue || ''}
                arrow
              >
                <Typography
                  variant="bodyMSemibold"
                  sx={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {change?.previousValue}
                </Typography>
              </Tooltip>
            </Box>
            <Divider />
            <Box
              sx={{
                paddingTop: theme.spacing(2),
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Typography variant="bodyMRegular" color={theme.palette.stationGray[500]}>{`New Value`}</Typography>
              <Tooltip title={change?.field === 'pay code' ? getCodeName(change.newValue) : change?.newValue || ''} arrow>
                <Typography
                  variant="bodyMSemibold"
                  sx={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {change?.newValue}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
          {change?.field === 'duration' && isNaN(change.newValue) ? (
            <Typography sx={{ alignSelf: 'center', marginBottom: theme.spacing(3) }} variant="bodyMSemibold">
              Please enter a valid number for duration
            </Typography>
          ) : (
            <>
              <Typography sx={{ typography: 'bodySMedium', marginBottom: theme.spacing(0.5) }}>Note</Typography>
              <TextField
                value={note}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNote(event.target.value);
                }}
                multiline
                rows={3}
                inputProps={{ maxLength: 80 }}
                sx={{
                  mb: theme.spacing(3),
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'rgba(145, 173, 1, 1)',
                      boxShadow: `0 0 0 4px ${theme.palette.stationPrimary[400]}`,
                    },
                  },
                }}
              />
            </>
          )}

          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              gap: theme.spacing(2),
              width: '100%',
              paddingBottom: theme.spacing(3),
            })}
          >
            <Button onClick={() => handleModalClose()} buttonType="tertiary" sx={() => ({ width: '100%' })}>
              {'Cancel'}
            </Button>
            <Button
              onClick={confirm}
              buttonType="dark"
              disabled={note === ''}
              sx={() => ({ width: '100%', ':hover': { backgroundColor: theme.palette.stationGray[700] } })}
            >
              {'Confirm'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
