import { RosterTemporaryNonShiftApparatus, RosterTemporaryNonShiftPosition } from '@stationwise/share-types';
import { copyBoardWithNewApparatus } from '../board';
import { keepPlannedEmployeeOnRoster } from '../keepPlannedEmployeeOnRoster';
import { IShiftSummaryHelper } from '../types';

export const cancelTemporaryNonShiftAssignment = (
  shiftSummaryHelper: IShiftSummaryHelper,
  position: RosterTemporaryNonShiftPosition,
  apparatus: RosterTemporaryNonShiftApparatus,
) => {
  const employee = {
    ...position.employees[0],
    startDateTime: shiftSummaryHelper.shiftDuration.startTime,
    endDateTime: shiftSummaryHelper.shiftDuration.endTime,
  };

  const { newAllStationCards, newStation, newApparatus } = copyBoardWithNewApparatus(shiftSummaryHelper, apparatus.id);
  newApparatus.positions = newApparatus.positions.filter((p) => p.id !== position.id);
  if (newApparatus.positions.length === 0) {
    newStation.apparatuses = newStation.apparatuses.filter((a) => a.id !== apparatus.id);
  }

  let newShiftSummaryHelper = { ...shiftSummaryHelper, allStationCards: newAllStationCards };
  newShiftSummaryHelper = keepPlannedEmployeeOnRoster(newShiftSummaryHelper, employee);
  return { newShiftSummaryHelper };
};
