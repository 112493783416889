import { Box, BoxProps } from '@mui/material';
import { StationwiseLogo } from '../../assets';

const mapSize = (type: LogoTypeSize) => {
  if (type === 'sm') {
    return { height: '28px', width: '28px' };
  }

  if (type === 'lg') {
    return { height: '60px', width: '66px' };
  }

  if (type === 'xlg') {
    return { height: '80px', width: '86px' };
  }

  if (type === 'xs') {
    return { height: '17px', width: '24px' };
  }

  return { height: '32px', width: '32px' };
};

export type LogoTypeSize = 'default' | 'sm' | 'lg' | 'xs' | 'xlg';

interface Props extends BoxProps<'img'> {
  size?: LogoTypeSize;
  component?: React.ElementType;
}

export const Logo = ({ sx, size = 'default', ...props }: Props) => {
  const mappedSize = mapSize(size);

  return (
    <Box
      component={StationwiseLogo}
      {...props}
      sx={[
        {
          height: mappedSize.height,
          width: mappedSize.width,
          verticalAlign: 'middle',
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    />
  );
};
