import { Box } from '@mui/material';
import { EmployeeOffView } from '@stationwise/share-types';
import { EmployeeOffCard } from './EmployeeOffCard';
import { FloaterCard } from './FloaterCard';
import { ReplacementEmployeeCard } from './ReplacementEmployeeCard';
import { SwapArrow } from './SwapArrow';

export const SwappedEmployees = ({ employeeOffData }: { employeeOffData: EmployeeOffView }) => {
  return employeeOffData.replacementEmployees.length > 0 ? (
    employeeOffData.replacementEmployees.map((replacementEmployee, i) => (
      <Box key={i} sx={(theme) => ({ display: 'flex', p: theme.spacing(2) })}>
        <EmployeeOffCard employeeOffData={employeeOffData} />
        <SwapArrow />
        <ReplacementEmployeeCard
          replacementEmployee={replacementEmployee}
          shiftDate={employeeOffData.shiftDate}
          startTime={employeeOffData.startTime}
          endTime={employeeOffData.endTime}
        />
      </Box>
    ))
  ) : (
    <Box
      sx={(theme) => ({
        display: 'flex',
        p: theme.spacing(2),
      })}
    >
      <EmployeeOffCard employeeOffData={employeeOffData} />
      <SwapArrow />
      <FloaterCard isReplacementNeeded={employeeOffData.isReplacementNeeded} />
    </Box>
  );
};
