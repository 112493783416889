import { Box } from '@mui/material';
import { Fragment } from 'react';
import { RosterApparatus as RosterApparatusType, RosterPosition as RosterPositionType } from '@stationwise/share-types';
import { checkIsShift, makePositionOverId } from '@stationwise/shift-summary-helper';
import { DraggableCard } from '../DraggableCard';
import { getLastChangesErrorPopoverAnchorProps } from '../LastChangesErrorPopover';
import { useRosterContext } from '../RosterContext';
import { RosterAssignedEmployeeCard } from './RosterAssignedEmployeeCard';
import { RosterEmptyPositionCard } from './RosterEmptyPositionCard';

interface Props {
  apparatus: RosterApparatusType;
  position: RosterPositionType;
  isCollapsed: boolean;
}

export const RosterPosition = ({ apparatus, position, isCollapsed }: Props) => {
  const { selectedFilledPositionState } = useRosterContext();

  const getBorderRadius = (isFirstCard: boolean, isLastCard: boolean) => ({
    borderTopLeftRadius: isFirstCard ? '12px' : '0',
    borderTopRightRadius: isFirstCard ? '12px' : '0',
    borderBottomLeftRadius: isLastCard ? '12px' : '0',
    borderBottomRightRadius: isLastCard ? '12px' : '0',
  });

  if (position.employees.length === 0) {
    return (
      <RosterEmptyPositionCard
        apparatus={apparatus}
        position={position}
        isCollapsed={isCollapsed}
        overId={makePositionOverId(position.id)}
      />
    );
  }

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          padding: '1px',
          borderRadius: theme.spacing(1.5),
        })}
      >
        {position.employees.map((employee, index) => {
          const isFirstCard = employee === position.employees[0];
          const isLastCard = employee === position.employees[position.employees.length - 1];

          return (
            <Fragment key={`${position.id}:${index}`}>
              <Box sx={{ position: 'relative' }}>
                {employee.id && (
                  <Box
                    data-roster-employee-id={employee.id}
                    {...getLastChangesErrorPopoverAnchorProps(employee.activeId)}
                    sx={{
                      position: 'absolute',
                      top: isFirstCard ? '-3px' : '0',
                      left: '-3px',
                      bottom: isLastCard ? '-3px' : '0',
                      right: '-3px',
                    }}
                  >
                    <DraggableCard
                      activeId={employee.activeId}
                      disabled={!checkIsShift(position)}
                      sx={getBorderRadius(isFirstCard, isLastCard)}
                    >
                      <Box
                        onClick={(event) => selectedFilledPositionState.setEmployee(employee, event.currentTarget)}
                        sx={{ height: '100%' }}
                      >
                        <RosterAssignedEmployeeCard
                          apparatus={apparatus}
                          position={position}
                          employee={employee}
                          isCollapsed={isCollapsed}
                        />
                      </Box>
                    </DraggableCard>
                  </Box>
                )}
                <RosterEmptyPositionCard
                  apparatus={apparatus}
                  position={{ ...position, startDateTime: employee.startDateTime, endDateTime: employee.endDateTime }}
                  isCollapsed={isCollapsed}
                  overId={makePositionOverId(position.id, index)}
                  cardSxProps={getBorderRadius(isFirstCard, isLastCard)}
                />
              </Box>
              {!isLastCard && (
                <Box sx={(theme) => ({ height: theme.spacing(1.5), position: 'relative' })}>
                  <Box
                    className="SWRosterPosition-split-divider"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'absolute',
                      top: 0,
                      height: '100%',
                      left: '-3px',
                      right: '-3px',
                    }}
                  >
                    <Box sx={{ borderBottom: '2px solid currentColor', width: '90%' }} />
                  </Box>
                </Box>
              )}
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};
