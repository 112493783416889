import { StaffingListItem } from '@stationwise/share-types';

export const OVERTIME_SORT_OPTIONS = ['Number', 'Rank'];

export const filterHireBackList = ({
  operators,
  filterConditions,
  searchInput,
}: {
  operators: StaffingListItem[];
  filterConditions: string[];
  searchInput: string;
}) => {
  let filtered = operators;
  for (const condition of filterConditions) {
    filtered = filtered.filter((operator) => {
      const { rank, certifications } = operator.employee;
      return rank.name === condition || certifications.some((cert) => cert.code === condition);
    });
  }
  filtered = filtered.filter((operator) => {
    const { name, rank, certifications } = operator.employee;
    return (
      !searchInput ||
      name.toLowerCase().includes(searchInput.toLowerCase()) ||
      rank.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      certifications.some((cert) => cert.code.toLowerCase().includes(searchInput.toLowerCase())) ||
      certifications.some((cert) => cert.name.toLowerCase().includes(searchInput.toLowerCase()))
    );
  });
  return filtered;
};

export const getNonRepeatedCertifications = ({ operators }: { operators: StaffingListItem[] }): string[] => {
  return Array.from(
    new Set(
      operators
        .map((operator) => operator.employee.certifications)
        .flatMap((certs) => certs)
        .map((cert) => cert.code),
    ),
  );
};

export const getNonRepeatedRanks = ({ operators }: { operators: StaffingListItem[] }): string[] => {
  return Array.from(new Set(operators.map((operator) => operator.employee.rank.name || '')));
};

export const sortHireBackList = (sortValue: string) => (a: StaffingListItem, b: StaffingListItem) => {
  if (sortValue === 'Number') {
    return a.order - b.order;
  } else if (sortValue === 'Rank') {
    return a.employee.rank.sortOrder - b.employee.rank.sortOrder;
  }
  return 0;
};
