import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { EmployeeOffView, RosterCancelShiftTradePayload } from '@stationwise/share-types';
import { IShiftSummaryHelper, cancelShiftTrade, getEmployeeOffDuration } from '@stationwise/shift-summary-helper';

interface UseCancelShiftTradeInput {
  shiftSummaryHelper: IShiftSummaryHelper;
  setShiftSummaryHelper: Dispatch<SetStateAction<IShiftSummaryHelper>>;
  setUserHasMadeChanges: Dispatch<SetStateAction<boolean>>;
}

export const useCancelShiftTrade = ({
  shiftSummaryHelper,
  setShiftSummaryHelper,
  setUserHasMadeChanges,
}: UseCancelShiftTradeInput) => {
  const [payloads, setPayloads] = useState<RosterCancelShiftTradePayload[]>([]);

  const resetState = useCallback(() => {
    setPayloads([]);
  }, []);

  const addPayload = (payload: RosterCancelShiftTradePayload) => setPayloads((prev) => [...prev, payload]);

  const handleCancelShiftTrade = (employeeOffData: EmployeeOffView, tradedHoursBalancePolicy: string) => {
    addPayload({ shiftTradeId: employeeOffData.id, tradedHoursBalancePolicy });
    const { startDateTime, endDateTime } = getEmployeeOffDuration(shiftSummaryHelper, employeeOffData);
    const newShiftSummaryHelper = cancelShiftTrade(
      shiftSummaryHelper,
      `${employeeOffData.replacementEmployees[0].id}`,
      {
        ...employeeOffData.employeeOff,
        id: `${employeeOffData.employeeOff.id}`,
        name: `${employeeOffData.employeeOff.firstName} ${employeeOffData.employeeOff.lastName}`,
      },
      startDateTime,
      endDateTime,
    );
    setShiftSummaryHelper(newShiftSummaryHelper);
    setUserHasMadeChanges(true);
  };

  return { payloads, addPayload, handleCancelShiftTrade, resetState };
};
