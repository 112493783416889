import { Box, Typography } from '@mui/material';
import { ChevronDownIcon20 } from '@stationwise/component-module';
import { PayCode } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';

interface EventTypeProps {
  header: string;
  openEventTypeDrawer: () => void;
  option: string | PayCode | null;
  placeholder: string;
  code?: string;
  disabled?: boolean;
}

export const AdditionalPaidTimeDropDown = ({
  header,
  openEventTypeDrawer,
  option,
  placeholder,
  code = '',
  disabled = false,
}: EventTypeProps) => {
  const optionName = option ? (typeof option === 'string' ? option : (option as PayCode).name) : placeholder;

  return (
    <>
      <Box
        sx={(theme) => ({
          p: theme.spacing(2),
          mt: theme.spacing(2),
          typography: 'bodyLMedium',
        })}
      >
        {header}
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          px: theme.spacing(2),
          width: '100%',
        })}
      >
        <Box
          data-cy={`${makeTestIdentifier(header)}-select`}
          display="flex"
          justifyContent="center"
          sx={(theme) => ({
            border: `1px solid ${theme.palette.stationGray[200]}`,
            borderRadius: theme.spacing(1),
            p: '10px 13px 10px',
            width: '100%',
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? 'default' : 'pointer',
            justifyContent: 'space-between',
          })}
          onClick={() => openEventTypeDrawer()}
        >
          <Typography
            variant="bodySRegular"
            sx={(theme) => ({
              color: option ? theme.palette.stationGray[900] : theme.palette.stationGray[500],
            })}
          >
            {optionName}
          </Typography>
          <Typography
            variant="bodySRegular"
            sx={(theme) => ({
              color: theme.palette.stationGray[500],
              '& svg': {
                color: theme.palette.stationGray[400],
              },
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(0.5),
            })}
          >
            {code !== optionName && code} <ChevronDownIcon20 />
          </Typography>
        </Box>
      </Box>
    </>
  );
};
