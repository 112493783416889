import { RosterApparatus, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { IShiftSummaryHelper } from '../types';

export const getBoardPosition = ({ allStationCards }: IShiftSummaryHelper, positionId: string) => {
  for (const station of allStationCards.values()) {
    for (const apparatus of station.apparatuses) {
      const positionIndex = apparatus.positions.findIndex((position) => position.id === positionId);
      const position = apparatus.positions[positionIndex];
      if (position) {
        return { station, apparatus, position, positionIndex };
      }
    }
  }
  return { station: null, apparatus: null, position: null, positionIndex: -1 };
};

export const makeTemporaryPosition = (apparatus: RosterApparatus, employee: RosterEmployee): RosterPosition => {
  return {
    id: `${apparatus.id}|${employee.activeId}`,
    dataSource: apparatus.dataSource,
    employees: [],
    startDateTime: employee.startDateTime,
    endDateTime: employee.endDateTime,
    rank: employee.rank,
    certifications: [],
    isTemporary: true,
    driver: false,
  };
};

export const sortTemporaryPositions = (positions: RosterPosition[]) => {
  const regularPositions = positions.filter((p) => !p.isTemporary);
  const temporaryPositions = positions.filter((p) => p.isTemporary);
  temporaryPositions.sort((a, b) => {
    const aId = Number(a.employees[0]?.id || 0);
    const bId = Number(b.employees[0]?.id || 0);
    return aId - bId || differenceInUTCMinutes(a.startDateTime, b.startDateTime);
  });
  return [...regularPositions, ...temporaryPositions];
};
