import { format } from 'date-fns';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { AutoHireVacancy } from './types';

export const getFormattedTimes = (vacancy: AutoHireVacancy, hours: number, minutes: number) => {
  const shiftStartDateTime = new Date();
  shiftStartDateTime.setHours(hours);
  shiftStartDateTime.setMinutes(minutes);

  const startDateTime = new Date(shiftStartDateTime.getTime() + vacancy.startTime * 60000);
  const endDateTime = new Date(shiftStartDateTime.getTime() + vacancy.endTime * 60000);
  endDateTime < startDateTime && endDateTime.setMinutes(endDateTime.getMinutes() + 24 * 60);

  const differenceInMinutesTotal = differenceInUTCMinutes(endDateTime, startDateTime);

  return {
    startTimeFormatted: format(startDateTime, 'HH:mm'),
    endTimeFormatted: format(endDateTime, 'HH:mm'),
    hoursDifference: Math.floor(differenceInMinutesTotal / 60),
    minutesDifference: differenceInMinutesTotal % 60,
  };
};
