import { Box, Button } from '@mui/material';

interface probs {
  handleClose: () => void;
}

export const PrintDayScheduleModalFooter = ({ handleClose }: probs) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, height: '80px' }}>
      <Button
        sx={{ height: '40px' }}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          handleClose();
          setTimeout(() => {
            window.print();
          }, 50);
        }}
      >
        Print
      </Button>
    </Box>
  );
};
