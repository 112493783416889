import { RosterStation, RosterApparatus, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import { checkIsShift } from '@stationwise/shift-summary-helper';

export type Vacancy = Pick<RosterPosition, 'rank' | 'certifications' | 'startDateTime' | 'endDateTime'>;

export interface AutoHireVacancy extends Vacancy {
  stationName: string;
  apparatusName: string;
}
export interface VacantApparatus extends Omit<RosterApparatus, 'positions'> {
  vacancies: Vacancy[];
}

export interface VacantStation extends Omit<RosterStation, 'apparatuses'> {
  apparatuses: VacantApparatus[];
}

export const getVacantStations = (stations: RosterStation[]) => {
  const vacantStations: VacantStation[] = [];
  stations.forEach((station) => {
    if (!checkIsShift(station)) {
      return;
    }

    const vacantApparatuses: VacantApparatus[] = [];
    station.apparatuses.forEach((apparatus) => {
      const vacancies: Vacancy[] = [];
      apparatus.positions.forEach((position) => {
        if (position.employees.length === 0) {
          vacancies.push(createVacancy(position));
        } else {
          position.employees.forEach((employee) => {
            !employee.id && vacancies.push(createVacancy(position, employee));
          });
        }
      });

      if (vacancies.length > 0) {
        vacancies.sort((a, b) => a.rank.sortOrder - b.rank.sortOrder);
        vacantApparatuses.push({ ...apparatus, vacancies });
      }
    });

    if (vacantApparatuses.length > 0) {
      vacantStations.push({ ...station, apparatuses: vacantApparatuses });
    }
  });

  return vacantStations;
};

const createVacancy = (position: RosterPosition, employee?: RosterEmployee) => {
  return {
    rank: position.rank,
    certifications: position.certifications,
    startDateTime: employee ? employee.startDateTime : position.startDateTime,
    endDateTime: employee ? employee.endDateTime : position.endDateTime,
  };
};
