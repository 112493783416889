export enum DRAWER_TYPE {
  HIREBACK = 'HIREBACK',
  EMPLOYEES_OFF = 'EMPLOYEES_OFF',
  MESSAGE = 'MESSAGE',
  STAFFING_STATS = 'STAFFING_STATS',
  CHANGELOG = 'CHANGELOG',
}

export enum DRAWER_WIDTH {
  HIREBACK = 380,
  EMPLOYEES_OFF = 650,
  MESSAGE = 470,
  STAFFING_STATS = 660,
  CHANGELOG = 800,
}
