import { Box, Skeleton } from '@mui/material';
import { parseISO } from 'date-fns';
import React from 'react';
import { useTimeCounterLabel } from '@stationwise/component-module';

export interface TimeLabelProps {
  time: string;
  isLoading?: boolean;
}

export const TimeLabel = ({ time, isLoading }: TimeLabelProps) => {
  const currentTime = parseISO(time);
  const timeLabel = useTimeCounterLabel(currentTime);
  if (isLoading) {
    return (
      <Box display="flex" gap={0.5}>
        <Skeleton variant="text" width={50} height={20} />
      </Box>
    );
  }

  return (
    <Box display="flex" gap={0.5}>
      <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'bodySRegular' })}>{timeLabel}</Box>
    </Box>
  );
};
