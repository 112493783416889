import { Theme, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LottieLogoPageLoader, useNextURLQueryParam } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { ROUTES } from '../../../core/Routes';

export const MagicLinkConfirmPage = () => {
  const navigate = useNavigate();
  const nextUrl = useNextURLQueryParam();
  const isMobileApp = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  function absoluteRedirection(redirectionURL: string) {
    window.location.href = redirectionURL;
  }

  useEffect(() => {
    const confirm = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const response = await client.post('/auth/login/magic-link/confirm/', {
          uid: urlParams.get('uid'),
          token: urlParams.get('token'),
          aid: urlParams.get('aid'),
        });
        if (nextUrl && nextUrl.includes('/n/')) {
          absoluteRedirection(nextUrl);
        } else if (!isMobileApp && response.data.hasAdminPermission) {
          navigate(nextUrl || ROUTES.DASHBOARD.fullRoute);
        } else {
          navigate(nextUrl || ROUTES.MY_DASHBOARD.fullRoute);
        }
      } catch {
        navigate('/');
      }
    };

    confirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return <LottieLogoPageLoader />;
};
