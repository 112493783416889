import { Box, BoxProps } from '@mui/material';
import lottie, { AnimationItem } from 'lottie-web';
import { useEffect, useRef } from 'react';
import { animationData } from './animationData';

export const LottieLogo = (props: BoxProps<'div'>) => {
  const lottieContainerRef = useRef<HTMLDivElement | null>(null);
  const lottieInstanceRef = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (lottieContainerRef.current && !lottieInstanceRef.current) {
      lottieInstanceRef.current = lottie.loadAnimation({
        container: lottieContainerRef.current,
        animationData,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
      });
    }

    return () => {
      lottieInstanceRef.current?.destroy();
      lottieInstanceRef.current = null;
    };
  }, []);

  return <Box {...props} ref={lottieContainerRef} />;
};
