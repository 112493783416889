import { Box, Modal } from '@mui/material';
import React from 'react';
import { IMaskInput } from 'react-imask';
import { Button, Input, theme } from '@stationwise/component-module';
import { isValidEmail, isValidPhoneNumber } from '@stationwise/share-utils';
import { AddEmployeeProps } from './MyTeamMain';

interface ModalProps {
  addEmployeeOpen: boolean;
  setaddEmployeeOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  employeeInfo: AddEmployeeProps;
  addEmployeeInfo: (employeeInfo: AddEmployeeProps) => void;
  initialState: AddEmployeeProps;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const AddEmployeeModal = ({
  addEmployeeOpen,
  setaddEmployeeOpen,
  setDetailsOpen,
  employeeInfo,
  addEmployeeInfo,
  initialState,
}: ModalProps) => {
  const isDisabled =
    !employeeInfo.firstName ||
    !employeeInfo.lastName ||
    !employeeInfo.email ||
    !isValidEmail(employeeInfo.email) ||
    (!isValidPhoneNumber(employeeInfo.phone) && employeeInfo.phone !== '');

  const handleFieldChange = (fieldName: string, value: string | number) => {
    addEmployeeInfo({ ...employeeInfo, [fieldName]: value });
  };

  const handleModalClose = () => {
    addEmployeeInfo(initialState);
    setaddEmployeeOpen(false);
  };

  const openDetailsModal = () => {
    setaddEmployeeOpen(false);
    setDetailsOpen(true);
  };

  return (
    <Modal open={addEmployeeOpen}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '90vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Add a new employee
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box width={'49%'}>
              <Box
                sx={(theme) => ({
                  typography: 'bodySMedium',
                  textAlign: 'left',
                  paddingTop: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                })}
              >
                First name <span style={{ color: theme.palette.stationRed[600] }}>*</span>
              </Box>

              <Box
                sx={(theme) => ({
                  py: theme.spacing(1),
                })}
              >
                <Input
                  type="name"
                  value={employeeInfo.firstName}
                  onChange={(event) => handleFieldChange('firstName', event.target.value)}
                />
              </Box>
            </Box>
            <Box width={'49%'}>
              <Box
                sx={(theme) => ({
                  typography: 'bodySMedium',
                  textAlign: 'left',
                  paddingTop: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                })}
              >
                Last name <span style={{ color: theme.palette.stationRed[600] }}>*</span>
              </Box>

              <Box
                sx={(theme) => ({
                  py: theme.spacing(1),
                })}
              >
                <Input
                  type="lastname"
                  value={employeeInfo.lastName}
                  onChange={(event) => handleFieldChange('lastName', event.target.value)}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Email <span style={{ color: theme.palette.stationRed[600] }}>*</span>
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
            })}
          >
            <Input type="email" value={employeeInfo.email} onChange={(event) => handleFieldChange('email', event.target.value)} />
          </Box>

          {!isValidEmail(employeeInfo.email) && employeeInfo.email && (
            <Box
              sx={(theme) => ({
                typography: 'bodyXSMedium',
                textAlign: 'left',
                paddingTop: theme.spacing(0.5),
                color: theme.palette.stationRed[500],
              })}
            >
              * Enter a valid email
            </Box>
          )}

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Phone
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
            })}
          >
            <Input
              value={employeeInfo.phone}
              onChange={(event) => handleFieldChange('phone', event.target.value)}
              name="textmask"
              placeholder="(100) 000-0000"
              id="formatted-text-mask-input"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              inputComponent={TextMaskCustom as any}
            />
          </Box>

          {!isValidPhoneNumber(employeeInfo.phone) && employeeInfo.phone && (
            <Box
              sx={(theme) => ({
                typography: 'bodyXSMedium',
                textAlign: 'left',
                paddingTop: theme.spacing(0.5),
                color: theme.palette.stationRed[500],
              })}
            >
              * Enter a valid phone number
            </Box>
          )}

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button
              buttonType="tertiary"
              variant="contained"
              style={{
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              }}
              onClick={() => handleModalClose()}
            >
              <Box component="span" sx={{ typography: 'buttonM' }}>
                Cancel
              </Box>
            </Button>
            <Button
              style={{
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              }}
              sx={(theme) => ({
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                borderRadius: theme.spacing(0.75),
                '&:hover': { backgroundColor: 'black', boxShadow: 'none' },
              })}
              buttonType="tertiary"
              variant="contained"
              disableFocusRipple={true}
              disableTouchRipple={true}
              disabled={isDisabled}
              onClick={() => {
                openDetailsModal();
              }}
            >
              <Box component="span" sx={{ typography: 'buttonM' }}>
                Continue
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
