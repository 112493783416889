import { ReactComponent as AccountBalanceWalletFillSymbol } from '@material-symbols/svg-400/outlined/account_balance_wallet-fill.svg';
import { ReactComponent as AccountBalanceWalletSymbol } from '@material-symbols/svg-400/outlined/account_balance_wallet.svg';
import { ReactComponent as CalendarTodayFillSymbol } from '@material-symbols/svg-400/outlined/calendar_today-fill.svg';
import { ReactComponent as CalendarTodaySymbol } from '@material-symbols/svg-400/outlined/calendar_today.svg';
import { ReactComponent as DashboardFillSymbol } from '@material-symbols/svg-400/outlined/dashboard-fill.svg';
import { ReactComponent as DashboardSymbol } from '@material-symbols/svg-400/outlined/dashboard.svg';
import { ReactComponent as GroupFillSymbol } from '@material-symbols/svg-400/outlined/group-fill.svg';
import { ReactComponent as GroupSymbol } from '@material-symbols/svg-400/outlined/group.svg';
import { ReactComponent as HistorySymbol } from '@material-symbols/svg-400/outlined/history.svg';
import { ReactComponent as LogoutSymbol } from '@material-symbols/svg-400/outlined/logout.svg';
import { ReactComponent as MailFillSymbol } from '@material-symbols/svg-400/outlined/mail-fill.svg';
import { ReactComponent as MailSymbol } from '@material-symbols/svg-400/outlined/mail.svg';
import { ReactComponent as ManageAccountsFillSymbol } from '@material-symbols/svg-400/outlined/manage_accounts-fill.svg';
import { ReactComponent as ManageAccountsSymbol } from '@material-symbols/svg-400/outlined/manage_accounts.svg';
import { ReactComponent as PatientListFillSymbol } from '@material-symbols/svg-400/outlined/patient_list-fill.svg';
import { ReactComponent as PatientListSymbol } from '@material-symbols/svg-400/outlined/patient_list.svg';
import { ReactComponent as PaymentsFillSymbol } from '@material-symbols/svg-400/outlined/payments-fill.svg';
import { ReactComponent as PaymentsSymbol } from '@material-symbols/svg-400/outlined/payments.svg';
import { ReactComponent as PendingFillSymbol } from '@material-symbols/svg-400/outlined/pending-fill.svg';
import { ReactComponent as PendingSymbol } from '@material-symbols/svg-400/outlined/pending.svg';
import { ReactComponent as QuestionMarkSymbol } from '@material-symbols/svg-400/outlined/question_mark.svg';
import { ReactComponent as SettingsFillSymbol } from '@material-symbols/svg-400/outlined/settings-fill.svg';
import { ReactComponent as SettingsSymbol } from '@material-symbols/svg-400/outlined/settings.svg';
import { ReactComponent as SwapHorizSymbol } from '@material-symbols/svg-400/outlined/swap_horiz.svg';
import { ReactComponent as TeamDashboardFillSymbol } from '@material-symbols/svg-400/outlined/team_dashboard-fill.svg';
import { ReactComponent as TeamDashboardSymbol } from '@material-symbols/svg-400/outlined/team_dashboard.svg';
import { ReactComponent as ViewDayFillSymbol } from '@material-symbols/svg-400/outlined/view_day-fill.svg';
import { ReactComponent as ViewDaySymbol } from '@material-symbols/svg-400/outlined/view_day.svg';
import { Box, useTheme, ButtonBase, Tooltip, TooltipProps } from '@mui/material';
import { SvgIcon } from '@stationwise/component-module';
import { makeTestIdentifier } from '@stationwise/share-utils';

export type MenuIconTypes =
  | 'dashboard'
  | 'roster'
  | 'employees'
  | 'overtime-list'
  | 'staffing-lists'
  | 'department-settings'
  | 'payroll'
  | 'support'
  | 'logout'
  | 'my-dashboard'
  | 'calendar'
  | 'my-roster'
  | 'my-payroll'
  | 'my-overtime-list'
  | 'my-staffing-lists'
  | 'preferences'
  | 'change-department'
  | 'inbox'
  | 'change-log'
  | 'more';

export const getIconData = (type: MenuIconTypes) => {
  switch (type) {
    case 'roster':
      return {
        Component: ViewDaySymbol,
        ActiveComponent: ViewDayFillSymbol,
        title: 'Roster',
      };
    case 'dashboard':
      return {
        Component: TeamDashboardSymbol,
        ActiveComponent: TeamDashboardFillSymbol,
        title: 'Dashboard',
      };
    case 'employees':
      return {
        Component: GroupSymbol,
        ActiveComponent: GroupFillSymbol,
        title: 'Employees',
      };
    case 'overtime-list':
      return {
        Component: PatientListSymbol,
        ActiveComponent: PatientListFillSymbol,
        title: 'Overtime Lists',
      };
    case 'staffing-lists':
      return {
        Component: PatientListSymbol,
        ActiveComponent: PatientListFillSymbol,
        title: 'Staffing Lists',
      };
    case 'payroll':
      return {
        Component: AccountBalanceWalletSymbol,
        ActiveComponent: AccountBalanceWalletFillSymbol,
        title: 'Payroll',
      };
    case 'department-settings':
      return {
        Component: SettingsSymbol,
        ActiveComponent: SettingsFillSymbol,
        title: 'Department Settings',
      };
    case 'support':
      return {
        Component: QuestionMarkSymbol,
        ActiveComponent: QuestionMarkSymbol,
        title: 'Support',
      };
    case 'change-department':
      return {
        Component: SwapHorizSymbol,
        ActiveComponent: SwapHorizSymbol,
        title: 'Change Department',
      };
    case 'logout':
      return {
        Component: LogoutSymbol,
        ActiveComponent: LogoutSymbol,
        title: 'Logout',
      };
    case 'calendar':
      return {
        Component: CalendarTodaySymbol,
        ActiveComponent: CalendarTodayFillSymbol,
        title: 'Calendar',
      };
    case 'my-roster':
      return {
        Component: ViewDaySymbol,
        ActiveComponent: ViewDayFillSymbol,
        title: 'Roster',
      };
    case 'my-payroll':
      return {
        Component: PaymentsSymbol,
        ActiveComponent: PaymentsFillSymbol,
        title: 'My Payroll',
      };
    case 'my-overtime-list':
      return {
        Component: PatientListSymbol,
        ActiveComponent: PatientListFillSymbol,
        title: 'My Overtime List',
      };
    case 'my-staffing-lists':
      return {
        Component: PatientListSymbol,
        ActiveComponent: PatientListFillSymbol,
        title: 'My Staffing Lists',
      };
    case 'preferences':
      return {
        Component: ManageAccountsSymbol,
        ActiveComponent: ManageAccountsFillSymbol,
        title: 'Preferences',
      };
    case 'my-dashboard':
      return {
        Component: DashboardSymbol,
        ActiveComponent: DashboardFillSymbol,
        title: 'My Dashboard',
      };
    case 'inbox':
      return {
        Component: MailSymbol,
        ActiveComponent: MailFillSymbol,
        title: 'Inbox',
      };
    case 'change-log':
      return {
        Component: HistorySymbol,
        ActiveComponent: HistorySymbol,
        title: 'Change Log',
      };
    case 'more':
      return {
        Component: PendingSymbol,
        ActiveComponent: PendingFillSymbol,
        title: 'More',
      };
  }
};

interface Props {
  isActive?: boolean;
  type: MenuIconTypes;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  placement?: TooltipProps['placement'];
  withTooltip?: boolean;
}

export const MenuIcon = ({ isActive = false, type, onClick, withTooltip = true }: Props) => {
  const theme = useTheme();
  const iconData = getIconData(type);
  const renderContent = () => {
    return (
      <ButtonBase
        data-cy={`menu-icon-${makeTestIdentifier(type)}`}
        onClick={onClick}
        sx={{
          background: isActive ? theme.palette.stationGray[100] : theme.palette.common.white,
          borderRadius: theme.spacing(1),
          color: isActive ? theme.palette.stationGray[900] : theme.palette.stationGray[400],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '40px',
          width: '40px',
          '& .SWMenuIcon-default': { display: isActive ? 'none' : 'flex' },
          '& .SWMenuIcon-active': { display: isActive ? 'flex' : 'none' },
          '&:hover': {
            background: theme.palette.stationGray[100],
            color: theme.palette.stationGray[900],
            '& .SWMenuIcon-default': { display: 'none' },
            '& .SWMenuIcon-active': { display: 'flex' },
            '& svg': {
              color: theme.palette.stationGray[900],
            },
          },
          '& svg': {
            color: isActive ? theme.palette.stationGray[900] : theme.palette.stationGray[400],
          },
        }}
      >
        <Box className="SWMenuIcon-default" component="span">
          <SvgIcon component={iconData.Component} />
        </Box>
        <Box className="SWMenuIcon-active" component="span">
          <SvgIcon component={iconData.ActiveComponent} />
        </Box>
      </ButtonBase>
    );
  };
  return withTooltip ? (
    <Tooltip
      arrow
      title={iconData.title}
      placement="right"
      slotProps={{
        tooltip: {
          sx: {
            background: theme.palette.stationGray[800],
            borderRadius: theme.spacing(1),
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
            padding: '10px 12px 10px 12px',
            textAlign: 'center',
            '&.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementRight': { ml: 3 },
            '& .MuiTooltip-arrow': { color: theme.palette.stationGray[800] },
          },
        },
      }}
    >
      {renderContent()}
    </Tooltip>
  ) : (
    renderContent()
  );
};
