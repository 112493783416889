import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { RosterEmployee, RosterPosition, TEMPORARY_NON_SHIFT_TITLES } from '@stationwise/share-types';
import { checkIsTemporaryNonShiftPosition } from '@stationwise/shift-summary-helper';
import { AssignmentDetails } from './AssignmentDetails';
import { CalendarModal } from './CalendarModal';
import { NonShiftDetails } from './NonShiftDetails';
import { PreviewHeader } from './PreviewHeader';

interface Props {
  employee: RosterEmployee;
  position: RosterPosition | null;
  hideDates?: boolean;
  children?: React.ReactNode;
  addNoteButton?: React.ReactNode;
  defaultStaffingSection?: React.ReactNode;
  payCodeAndDetailCodes?: React.ReactNode;
  actions?: React.ReactNode;
  hidePersonalCalendar?: boolean;
}
export const EmployeePreview = ({
  employee,
  position,
  children,
  hideDates,
  addNoteButton,
  defaultStaffingSection,
  payCodeAndDetailCodes,
  actions,
  hidePersonalCalendar,
}: Props) => {
  const TOOLTIP_WIDTH = '296px';
  const [modalOpen, setModalOpen] = useState(false);

  const teamName =
    (position && checkIsTemporaryNonShiftPosition(position)
      ? TEMPORARY_NON_SHIFT_TITLES.get(position.temporaryNonShiftAssignment?.temporaryNonShiftType)
      : employee.team?.name) || '';

  return (
    <Box sx={{ minWidth: TOOLTIP_WIDTH }} data-cy="employee-preview">
      <PreviewHeader
        position={position}
        employee={employee}
        addNoteButton={addNoteButton}
        defaultStaffingSection={defaultStaffingSection}
        actions={actions}
      />
      <Box sx={{ p: 2 }}>
        <AssignmentDetails
          employee={employee}
          hideDates={hideDates}
          payCodeAndDetailCodes={payCodeAndDetailCodes}
          teamName={teamName}
        />
        {position && checkIsTemporaryNonShiftPosition(position) && (
          <NonShiftDetails assignment={position.temporaryNonShiftAssignment} />
        )}
        {children}
        {!hidePersonalCalendar && (
          <Box
            sx={(theme) => ({
              mt: theme.spacing(1.5),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: '13px',
              py: '9px',
              borderRadius: '6px',
              border: '1px solid',
              color: theme.palette.common.white,
              borderColor: theme.palette.stationGray[500],
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.palette.stationGray[50],
                color: theme.palette.stationGray[900],
              },
            })}
            onClick={() => setModalOpen(true)}
          >
            <Typography variant="buttonM">View personal schedule</Typography>
            {modalOpen && <CalendarModal setModalOpen={setModalOpen} modalOpen={modalOpen} employee={employee} />}
          </Box>
        )}
      </Box>
    </Box>
  );
};
