import { format } from 'date-fns';
import {
  RosterDataSource,
  ROSTER_SHIFT_DATA_SOURCES,
  ROSTER_EVENT_DATA_SOURCES,
  RosterStation,
  RosterApparatus,
  RosterPosition,
  RosterEmployee,
  RosterAdministrationPosition,
  RosterStrikeTeamApparatus,
  RosterTemporaryNonShiftApparatus,
  RosterTemporaryNonShiftPosition,
} from '@stationwise/share-types';
import { COLLISION_REGION } from './constants';

type RosterItem = RosterStation | RosterApparatus | RosterPosition | RosterEmployee;

const SEPARATOR = '|';

export const makeApparatusOverId = (apparatusId: string) => {
  return [apparatusId, COLLISION_REGION.BOARD_APPARATUS].join(SEPARATOR);
};

export const makePositionOverId = (positionId: string, employeeIndex = 0) => {
  return [positionId, employeeIndex, COLLISION_REGION.BOARD_POSITION].join(SEPARATOR);
};

export const makeEmployeeActiveId = (employee: Omit<RosterEmployee, 'activeId'>) => {
  if (!employee.id) {
    return '';
  }

  const keys = [employee.id, employee.dataSource, format(employee.startDateTime, 'HHmm'), format(employee.endDateTime, 'HHmm')];
  return keys.join(SEPARATOR);
};

export const makeOvertimeEmployeeActiveId = (employeeId: string) => {
  return [employeeId, COLLISION_REGION.OVERTIME_LIST].join(SEPARATOR);
};

export const setEmployeeActiveId = (employee: Omit<RosterEmployee, 'activeId'>): RosterEmployee => {
  return Object.assign(employee, { activeId: makeEmployeeActiveId(employee) });
};

const checkDataSources = (dataSources: Set<RosterDataSource>) => {
  return <T extends RosterItem>(item: T) => dataSources.has(item.dataSource);
};

export const checkIsShift = checkDataSources(ROSTER_SHIFT_DATA_SOURCES);

export const checkIsFloater = checkDataSources(new Set([RosterDataSource.FLOATER]));

export const checkIsAdministration = checkDataSources(new Set([RosterDataSource.ADMINISTRATION]));
export const checkIsAdministrationPosition = (position: RosterPosition): position is RosterAdministrationPosition => {
  return checkIsAdministration(position);
};

export const checkIsEvent = checkDataSources(ROSTER_EVENT_DATA_SOURCES);

export const checkIsStrikeTeam = checkDataSources(new Set([RosterDataSource.STRIKE_TEAM]));
export const checkIsStrikeTeamApparatus = (apparatus: RosterApparatus): apparatus is RosterStrikeTeamApparatus => {
  return checkIsStrikeTeam(apparatus);
};

export const checkIsTemporaryNonShift = checkDataSources(new Set([RosterDataSource.TEMPORARY_NON_SHIFT]));
export const checkIsTemporaryNonShiftApparatus = (apparatus: RosterApparatus): apparatus is RosterTemporaryNonShiftApparatus => {
  return checkIsTemporaryNonShift(apparatus);
};
export const checkIsTemporaryNonShiftPosition = (position: RosterPosition): position is RosterTemporaryNonShiftPosition => {
  return checkIsTemporaryNonShift(position);
};
