import { SxProps, Theme } from '@mui/material';
import { PreviewButton } from './PreviewButton';

interface Props {
  employeeId: string;
  sx?: SxProps<Theme>;
}

export const ViewProfileButton = ({ employeeId, sx }: Props) => (
  <PreviewButton
    buttonType="secondary"
    component="a"
    href={`/app/employees/${employeeId}`}
    target="_blank"
    rel="noopener noreferrer"
    sx={sx}
  >
    Full profile
  </PreviewButton>
);
