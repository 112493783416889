import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { RosterStation } from '@stationwise/share-types';
import { makeEventStations } from '@stationwise/shift-summary-helper';

interface Props {
  selectedDate: string;
  skip?: boolean;
}

export const useFetchEventStation = ({ selectedDate, skip = false }: Props) => {
  const [refetchCounter, setRefetchCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<RosterStation[] | null>(null);
  useEffect(() => {
    if (skip) {
      setIsLoading(false);
      setIsError(false);
      setData(makeEventStations([]));
      return;
    }
    setIsLoading(true);
    setIsError(false);
    setData(null);
    client
      .get('event/strike-teams/', {
        params: {
          date: selectedDate,
        },
      })
      .then((response) => {
        setData(makeEventStations(response.data));
      })
      .catch((error) => {
        setIsError(true);
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedDate, skip, refetchCounter]);

  const forceRefetch = useCallback(() => setRefetchCounter((c) => c + 1), []);

  return {
    isLoading,
    isError,
    data,
    forceRefetch,
  };
};
