import { Box, Typography } from '@mui/material';
import { addHours, differenceInHours } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { makeTestIdentifier } from '@stationwise/share-utils';

export interface Option {
  label: string;
  value: number;
}

interface RequestDurationOptionsProps {
  durationOptions: Option[];
  startDateTime: string;
  duration: number;
  setDuration: Dispatch<SetStateAction<number>>;
  originalEndDateTime: string;
  originalStartDateTime?: string;
  durationError?: boolean;
}

export const RequestDurationOptions = ({
  durationOptions,
  startDateTime,
  duration,
  setDuration,
  originalEndDateTime,
  durationError,
}: RequestDurationOptionsProps) => {
  const remainingHours = Math.abs(differenceInHours(new Date(startDateTime), new Date(originalEndDateTime)));
  const updatedDurationOptions = durationOptions.map((option) =>
    option.label === 'Remaining Hours' ? { ...option, value: remainingHours } : option,
  );
  if (remainingHours % 4 !== 0 && !updatedDurationOptions.some((option) => option.label === 'Remaining Hours')) {
    updatedDurationOptions.push({ label: 'Remaining Hours', value: remainingHours });
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {updatedDurationOptions.map((option) => {
        let isDisabled = false;

        const newEnd = addHours(new Date(startDateTime), option.value);

        if (newEnd > new Date(originalEndDateTime)) {
          isDisabled = true;
        }

        return (
          <Box
            key={option.value}
            data-cy={`shift-trade-duration-${makeTestIdentifier(option.value.toString())}-hrs`}
            sx={(theme) => ({
              borderRadius: '8px',
              border: `solid 1px ${theme.palette.stationGray[200]}`,
              p: theme.spacing(1.5),
              mr: theme.spacing(1),
              mt: theme.spacing(1),
              backgroundColor: isDisabled
                ? 'inherit'
                : option.value === duration
                  ? durationError
                    ? theme.palette.stationRose[500]
                    : theme.palette.stationGray[900]
                  : 'transparent',
            })}
            onClick={!isDisabled ? () => setDuration(option.value) : undefined}
          >
            <Typography
              variant="bodyMRegular"
              sx={(theme) => ({
                color: isDisabled
                  ? theme.palette.stationGray[100]
                  : option.value === duration
                    ? theme.palette.common.white
                    : theme.palette.stationGray[900],
              })}
            >
              {option.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
