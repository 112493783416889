import { useState } from 'react';
import { GenericDrawer } from '@stationwise/component-module';
import { PayrollCode } from '@stationwise/share-types';
import { CompTimeAccrualResponse } from './CompTimeAccrualResponse';
import { PayrollCompTimeAccrualContent } from './PayrollCompTimeAccrualContent';
import { AvailableCompTime } from './utils';

interface PayrollCompTimeAccrualModalProps {
  setOpenCompTimeModal: React.Dispatch<React.SetStateAction<boolean>>;
  openCompTimeModal: boolean;
  setOpenResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
  openResponseModal: boolean;
  availableCompTimes: AvailableCompTime[];
  payrollCode: PayrollCode | null;
  loading: boolean;
  requestCompTimeError: string | null;
  setRefetchCounter: React.Dispatch<React.SetStateAction<number>>;
  refetchCounter: number;
}

export const PayrollCompTimeAccrualModal = ({
  setOpenCompTimeModal,
  openCompTimeModal,
  setOpenResponseModal,
  openResponseModal,
  availableCompTimes,
  payrollCode,
  loading,
  requestCompTimeError,
  setRefetchCounter,
  refetchCounter,
}: PayrollCompTimeAccrualModalProps) => {
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  return (
    <>
      <GenericDrawer
        anchor="bottom"
        drawerOpen={openCompTimeModal}
        handleOnClose={() => setOpenCompTimeModal(false)}
        loading={loading}
        showHeader={true}
        disableFooter={true}
        headerTitle="Accrue comp time"
        noBorderOnHeader={true}
      >
        <PayrollCompTimeAccrualContent
          availableCompTimes={availableCompTimes}
          payrollCode={payrollCode}
          setOpenResponseModal={setOpenResponseModal}
          setMessage={setMessage}
          setError={setError}
          requestCompTimeError={requestCompTimeError}
        />
      </GenericDrawer>

      <CompTimeAccrualResponse
        setOpenResponseModal={setOpenResponseModal}
        openResponseModal={openResponseModal}
        setOpenCompTimeModal={setOpenCompTimeModal}
        error={error}
        message={message}
        setRefetchCounter={setRefetchCounter}
        refetchCounter={refetchCounter}
      />
    </>
  );
};
