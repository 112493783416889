import { Certification, CertificationActAsRank, RosterStation, RosterApparatus, RosterEmployee } from '@stationwise/share-types';
import { CertificationRequirementCount } from '../types';

/**
 * This function will return a map of certifications and the amount of employees
 * that have that certification
 * @param apparatus - The apparatus to get the certifications from
 * @returns - { Map with certification code as key and values as
 *  total: total amount of certifications required by the apparatus
 *  amount: amount of employees that have that certification
 *  obj: the certification object
 * }
 */
export const getApparatusCertifications = (apparatus: RosterApparatus) => {
  const certMap = new Map<string, CertificationRequirementCount>();
  const certEmployeeIdsMap = new Map<string, Set<string>>();
  apparatus.certificationRequirements.forEach((certReq) => {
    if (!certMap.has(certReq.code)) {
      certMap.set(certReq.code, { total: certReq.amount, amount: 0, obj: certReq });
      certEmployeeIdsMap.set(certReq.code, new Set<string>());
    }
  });
  apparatus.positions.forEach((position) => {
    position.employees.forEach((employee) => {
      const certs = [...employee.certifications];
      while (certs.length > 0) {
        const cert = certs.shift() as Certification;
        certs.push(...cert.childCertifications);
        const employeeIds = certEmployeeIdsMap.get(cert.code);
        if (employeeIds && employee.id) {
          employeeIds.add(employee.id);
        }
      }
    });
  });
  for (const [code, count] of certMap.entries()) {
    const employeeIds = certEmployeeIdsMap.get(code);
    count.amount += employeeIds ? employeeIds.size : 0;
  }
  return certMap;
};

/**
 * This function will return a map of certifications and the amount of employees
 * that have that certification. The key will be the certification code
 * @param station - The station to get the certifications from
 * @returns - { Map with certification code as key and values as
 * total: total amount of certifications required by the station
 * amount: amount of employees that have that certification
 * obj: the certification object
 * }
 */
export const getStationCertifications = (station: RosterStation) => {
  const certMap = new Map<string, CertificationRequirementCount>();
  const certEmployeeIdsMap = new Map<string, Set<string>>();
  station.certificationRequirements.forEach((certReq) => {
    if (!certMap.has(certReq.code)) {
      certMap.set(certReq.code, { total: certReq.amount, amount: 0, obj: certReq });
      certEmployeeIdsMap.set(certReq.code, new Set<string>());
    }
  });
  station.apparatuses.forEach((apparatus) => {
    apparatus.positions.forEach((position) => {
      position.employees.forEach((employee) => {
        const certs = [...employee.certifications];
        while (certs.length > 0) {
          const cert = certs.shift() as Certification;
          certs.push(...cert.childCertifications);
          const employeeIds = certEmployeeIdsMap.get(cert.code);
          if (employeeIds && employee.id) {
            employeeIds.add(employee.id);
          }
        }
      });
    });
  });
  for (const [code, count] of certMap.entries()) {
    const employeeIds = certEmployeeIdsMap.get(code);
    count.amount += employeeIds ? employeeIds.size : 0;
  }
  return certMap;
};

/**
 * Returns all of the certification codes included in a certification list,
 * including the ones implicitly obtained through child certifications.
 */
export const getAllCertificationCodes = (certs: Certification[]) => {
  const codes = new Set();
  certs.forEach((cert) => {
    codes.add(cert.code);
    getAllCertificationCodes(cert.childCertifications).forEach((code) => codes.add(code));
  });
  return codes;
};

/**
 * This function will return true if the employee has the rank or has a certification
 * that acts as a rank
 * @param employee - The employee to check
 * @param rankName - The rank the employee should have
 * @param certificationActAsRanks - The certifications that act as ranks
 * @returns - true if the employee has the rank or has a certification that acts as a rank
 */
export const hasRankOrCertificationActAsRank = (
  employee: RosterEmployee,
  rankName: string,
  certificationActAsRanks: CertificationActAsRank[],
) => {
  if (employee.rank.name === rankName) {
    return true;
  }

  const allEmployeeCertCodes = getAllCertificationCodes(employee.certifications);
  return certificationActAsRanks.some((caar) => caar.rankName === rankName && allEmployeeCertCodes.has(caar.certificationCode));
};

/**
 * Given two certification lists "required" and "obtained", return the ones in "required" that are not in "obtained",
 * taking into account child certifications of "obtained".
 */
export const getMissingCertifications = (requiredCerts: Pick<Certification, 'code'>[], obtainedCerts: Certification[]) => {
  const allObtainedCodes = getAllCertificationCodes(obtainedCerts);
  return requiredCerts.filter((c) => !allObtainedCodes.has(c.code));
};
