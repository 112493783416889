/* eslint-disable @typescript-eslint/no-explicit-any */

import { loadLib } from './intercomExtLib';

declare global {
  interface Window {
    Intercom: (command: string, ...args: any[]) => void;
  }
}

export const loadIntercomForLoggedInUser = (name: string, userId: string, email: string, phone: string | null) => {
  if (!process.env['NX_PUBLIC_SW_ENV']) {
    return;
  }
  loadLib();
  window.Intercom('boot', {
    app_id: 'r25wavus',
    name: name,
    user_id: userId,
    email: email,
    phone: phone,
    hide_default_launcher: true,
  });
};
