import { Box, Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import { ChevronDownIcon16 as ChevronDown, ChevronUpIcon16 as ChevronUp } from '../../../../assets';
import { Button } from '../../../Button';
import { GenericDrawer } from '../../../GenericDrawer';
import { useRosterContext } from '../RosterContext';
import { StationsMenu } from './StationsMenu';

export const StationsSelect = () => {
  const { preferences } = useRosterContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isMobileApp = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const selectedBattalion = preferences.battalions.find((b) => b.id === preferences.selectedBattalionId);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        data-cy="station-select-button"
        buttonType="tertiary"
        buttonSize="sm"
        variant="contained"
        onClick={handleClick}
        sx={(theme) => ({
          borderRadius: theme.spacing(1),
          ...(isMobileApp
            ? {
                borderColor: 'transparent',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                  borderColor: theme.palette.stationGray[100],
                },
              }
            : {
                '&:hover': {
                  backgroundColor: theme.palette.common.white,
                  boxShadow: 'none',
                },
              }),
          ml: { xs: 0, lg: theme.spacing(2) },
        })}
      >
        <Box
          component="span"
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            typography: 'buttonM',
            height: theme.spacing(5),

            '& svg path': { stroke: theme.palette.stationGray[900] },
            ...(isMobileApp
              ? {
                  borderColor: 'transparent',
                  padding: '10px 12px',
                  marginLeft: '4px',
                }
              : {
                  padding: '9px 17px',
                }),
          })}
        >
          {selectedBattalion?.name}
          {open ? <ChevronUp /> : <ChevronDown />}
        </Box>
      </Button>
      {isMobileApp ? (
        <GenericDrawer
          anchor="bottom"
          drawerOpen={anchorEl !== null}
          handleOnClose={handleClose}
          loading={false}
          showHeader={true}
          disableFooter={true}
          noBorderOnHeader={true}
          headerTitle="Battalions"
          sxProps={() => ({ '& .MuiDrawer-paper': { height: '100%', borderRadius: 0 } })}
        >
          <StationsMenu anchorEl={anchorEl} handleClose={handleClose} />
        </GenericDrawer>
      ) : (
        <StationsMenu anchorEl={anchorEl} handleClose={handleClose} />
      )}{' '}
    </>
  );
};
