import { Box } from '@mui/material';
import PopupState, { bindPopover, bindHover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { MouseEvent, ReactNode, useId, useRef } from 'react';

interface ControlledPopupProps {
  anchorComponent: ReactNode;
  popupComponent: ReactNode;
  delay?: number;
}

export const ControlledPopup = ({ anchorComponent, popupComponent, delay = 0 }: ControlledPopupProps) => {
  const id = useId();
  const delayTimeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  return (
    <PopupState variant="popover" popupId={id}>
      {(popupState) => {
        const hoverProps = bindHover(popupState);
        const popoverProps = bindPopover(popupState);

        const onAnchorMouseLeave = (event: MouseEvent<HTMLDivElement>) => {
          if (delay > 0) {
            delayTimeoutIdRef.current = setTimeout(() => hoverProps.onMouseLeave(event), delay);
          } else {
            hoverProps.onMouseLeave(event);
          }
        };

        const onPopoverMouseEnter = () => {
          if (delayTimeoutIdRef.current !== null) {
            clearTimeout(delayTimeoutIdRef.current);
            delayTimeoutIdRef.current = null;
          }
        };

        return (
          <>
            <Box
              onTouchStart={hoverProps.onTouchStart}
              onMouseOver={hoverProps.onMouseOver}
              onMouseLeave={onAnchorMouseLeave}
              aria-controls={hoverProps['aria-controls']}
              aria-describedby={hoverProps['aria-describedby']}
              aria-haspopup={hoverProps['aria-haspopup']}
              sx={{ cursor: 'pointer', display: 'flex' }}
            >
              {anchorComponent}
            </Box>

            <HoverPopover
              id={popoverProps.id}
              anchorEl={popoverProps.anchorEl}
              anchorPosition={popoverProps.anchorPosition}
              anchorReference={popoverProps.anchorReference}
              disableAutoFocus={popoverProps.disableAutoFocus}
              disableEnforceFocus={popoverProps.disableEnforceFocus}
              disableRestoreFocus={popoverProps.disableRestoreFocus}
              onClose={popoverProps.onClose}
              onMouseEnter={onPopoverMouseEnter}
              onMouseLeave={popoverProps.onMouseLeave}
              open={popoverProps.open}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              {popupComponent}
            </HoverPopover>
          </>
        );
      }}
    </PopupState>
  );
};
