import { eachDayOfInterval, format, parseISO, setMinutes } from 'date-fns';
import { RosterShiftDuration, RosterStrikeTeamApparatus, RosterPosition, StrikeTeam } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';

export const getPythonWeekday = (date: Date) => {
  // The "+ 6 % 7" operation maps JavaScript `getDay` (0 = Sunday, 6 = Saturday) to Python `weekday()` (0 = Monday, 6 = Sunday).
  return (date.getDay() + 6) % 7;
};

export const checkIsStrikeTeamActive = (strikeTeam: StrikeTeam, date: Date) => {
  return strikeTeam.activeWeekdays.includes(getPythonWeekday(date));
};

export const getStrikeTeamFutureActiveDurations = (
  apparatus: RosterStrikeTeamApparatus,
  duration: Pick<RosterPosition, 'startDateTime' | 'endDateTime'>,
  toDate: string,
) => {
  const startHourMinute = format(duration.startDateTime, 'HH:mm');
  const durationMinutes = differenceInUTCMinutes(duration.endDateTime, duration.startDateTime);
  const durations: RosterShiftDuration[] = [];
  eachDayOfInterval({ start: duration.startDateTime, end: parseISO(toDate) }).forEach((startDay, i) => {
    if (i === 0 || !checkIsStrikeTeamActive(apparatus.strikeTeam, startDay)) {
      return;
    }

    const startYearMonthDay = format(startDay, 'yyyy-MM-dd');
    const startDateTime = new Date(`${startYearMonthDay}T${startHourMinute}`);
    const endDateTime = setMinutes(startDateTime, startDateTime.getMinutes() + durationMinutes);
    durations.push({ startTime: startDateTime, endTime: endDateTime, hours: durationMinutes / 60 });
  });

  return durations;
};
