import { useDroppable } from '@dnd-kit/core';
import { Box } from '@mui/material';
import { RosterApparatus as RosterApparatusType } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import {
  checkIsCollapsedApparatus,
  checkIsShift,
  checkIsStrikeTeamApparatus,
  makeApparatusOverId,
  makePositionOverId,
} from '@stationwise/shift-summary-helper';
import { Apparatus } from '../../../Apparatus';
import { DroppableItem } from '../DroppableItem';
import { useRosterContext } from '../RosterContext';
import { RosterPosition } from '../RosterPosition';
import { StrikeTeamActions } from '../StrikeTeamActions';

interface Props {
  apparatus: RosterApparatusType;
}

export const RosterApparatus = ({ apparatus }: Props) => {
  const { isReadonly, preferences } = useRosterContext();
  const isDroppableDisabled = !checkIsShift(apparatus);
  const droppable = useDroppable({ id: makeApparatusOverId(apparatus.id), disabled: isDroppableDisabled });

  const isCollapsed = checkIsCollapsedApparatus(apparatus, preferences.isCollapsed);

  const isOver = (() => {
    const overId = `${droppable.over?.id || ''}`;
    if (!overId || isDroppableDisabled) {
      return false;
    }

    if (overId === makeApparatusOverId(apparatus.id)) {
      return true;
    }

    return apparatus.positions.some((position) => {
      let employeeIndex = position.employees.findIndex((employee, employeeIndex) => {
        return overId === makePositionOverId(position.id, employeeIndex);
      });
      employeeIndex = Math.max(employeeIndex, overId === makePositionOverId(position.id) ? 0 : -1);
      return employeeIndex >= 0;
    });
  })();

  if (!apparatus.positions.length) {
    return null;
  }

  const actions = !isReadonly && checkIsStrikeTeamApparatus(apparatus) && <StrikeTeamActions apparatus={apparatus} />;

  return (
    <Apparatus apparatus={apparatus} isOver={isOver} actions={actions}>
      {apparatus.positions.map((position) => (
        <Box key={position.id} data-cy={`position-${makeTestIdentifier(position.rank.code)}`} sx={{ mt: 1.5 }}>
          <RosterPosition apparatus={apparatus} position={position} isCollapsed={isCollapsed} />
        </Box>
      ))}
      {!isDroppableDisabled && (
        <DroppableItem droppable={droppable}>
          <Box
            sx={(theme) => ({
              mt: isOver ? 1.5 : 0,
              opacity: isOver ? 1 : 0,
              height: isOver ? (isCollapsed ? '40px' : '104px') : '0px',
              transition: isOver ? 'opacity 0.4s ease-in-out, height 0.4s ease-in-out' : 'none',
              background: theme.palette.stationGray[100],
              borderRadius: '12px',
            })}
          />
        </DroppableItem>
      )}
    </Apparatus>
  );
};
