import { Box, CircularProgress } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { FormEvent } from 'react';
import { RefreshIcon20 } from '../../../assets';
import { Button } from '../../Button';

interface ResetPasswordResultPageBodyProps {
  onSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  isSubmitting: boolean;
  isSuccess: boolean;
}

export const ResetPasswordResultPageBody = ({ onSubmit, isSubmitting, isSuccess }: ResetPasswordResultPageBodyProps) => {
  return (
    <Box component="form" onSubmit={onSubmit}>
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[600],
          mb: 5,
          typography: 'bodyMRegular',
          textAlign: 'center',
          display: 'flex',
          height: '44px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '12px',
          flexShrink: 0,
        })}
      >
        <Button
          buttonType="primary"
          type="submit"
          sx={() => ({
            p: '9px 17px 9px 15px',
            width: '100%',
            typography: 'bodyMMedium',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
            flexShrink: 0,
          })}
        >
          {isSubmitting && (
            <CircularProgress
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              color="inherit"
              size="1.5rem"
            />
          )}
          <Box
            component="span"
            sx={
              isSubmitting
                ? visuallyHidden
                : {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '12px',
                    '& svg': {
                      '& path': {
                        stroke: '#fff',
                      },
                    },
                  }
            }
          >
            {!isSuccess && <RefreshIcon20 />}
            {isSuccess ? 'Continue' : 'Try again'}
          </Box>
        </Button>
      </Box>
    </Box>
  );
};
