import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import {
  BigArrowRightIcon,
  BlankCard,
  Chip,
  getEventDisplayedTitle,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';

export const OvertimeRequestCard = ({
  shift,
  children,
  handleClick,
  cancelled,
}: {
  shift: EventInput | EventImpl;
  children?: React.ReactNode;
  handleClick?: (arg0: EventInput | EventImpl) => void;
  cancelled: boolean;
}) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const backgroundColor = shift.backgroundColor;
  const theme = useTheme();
  return (
    <Box
      className="shift-card-box"
      sx={{
        ml: '16px',
        mr: '16px',
        pb: '16px',
      }}
      onClick={() => handleClick && handleClick(shift)}
    >
      <BlankCard>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Chip
            label={getEventDisplayedTitle(departmentInfoState, shift.title || '').toUpperCase()}
            chipSxProps={() => ({
              background: backgroundColor ?? theme.palette.stationPurple[200],
              height: '24px',
              width: '110%',
              p: '4px 2px',
              borderRadius: '7px',
              '&:after': {
                content: `url('data:image/svg+xml,<svg width="10" height="24" viewBox="0 0 10 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.30463 2.93237C4.15848 1.10758 2.15488 0 0 0V24C2.15488 24 4.15848 22.8924 5.30463 21.0676L8.66146 15.7232C10.0912 13.4469 10.0912 10.5531 8.66147 8.27681L5.30463 2.93237Z" fill="%23${backgroundColor}"/></svg>')`,
                position: 'absolute',
                height: '100%',
                width: '10px',
                right: '-4px',
              },
              '&:before': {
                content: `url('data:image/svg+xml,<svg width="10" height="24" viewBox="0 0 10 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.69537 2.93237C5.84152 1.10758 7.84512 0 10 0V0V24V24C7.84512 24 5.84152 22.8924 4.69537 21.0676L1.33854 15.7232C-0.0911757 13.4469 -0.091176 10.5531 1.33853 8.27681L4.69537 2.93237Z" fill="%23${backgroundColor}"/></svg>')`,
                position: 'absolute',
                height: '100%',
                width: '10px',
                left: '-4px',
              },
            })}
            variant="buttonS"
            typographyStyles={{
              letterSpacing: '0.03em',
              color: shift.textColor ? shift.textColor : 'inherit',
            }}
          />
          {cancelled && (
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.stationGray[100],
                borderRadius: 4,
                padding: '0px 12px 0px 12px',
              })}
            >
              <Typography variant="bodySRegular">Cancelled</Typography>
            </Box>
          )}
        </Box>
        <Box display="flex">
          {shift.start?.toString() && <Typography variant="bodyMRegular">{format(shift.start?.toString(), 'M/d/yy')}</Typography>}
          <Box sx={{ ml: '9px', mr: '9px', mt: '2px' }}>
            <BigArrowRightIcon />
          </Box>
          {shift.end?.toString() && <Typography variant="bodyMRegular">{format(shift.end?.toString(), 'M/d/yy')}</Typography>}
          <Box
            display="flex"
            sx={(theme) => ({
              ml: '10px',
              color: theme.palette.stationGray[400],
            })}
          >
            {shift.start?.toString() && (
              <Typography variant="bodyMRegular">{format(shift.start?.toString(), 'HH:mm')}</Typography>
            )}
            <Box sx={{ ml: '3px', mr: '3px' }}>-</Box>
            {shift.end?.toString() && <Typography variant="bodyMRegular">{format(shift.end?.toString(), 'HH:mm')}</Typography>}
          </Box>
        </Box>
        {children}
      </BlankCard>
    </Box>
  );
};
