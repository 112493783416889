import { useAmplitude, useSentry } from '@stationwise/component-module';
import { AppProviders } from '../core/AppProviders';
import { Router } from '../core/Router';

export function App() {
  // setup Sentry for error & performance reporting
  useSentry();
  useAmplitude();

  return (
    <AppProviders>
      <Router />
    </AppProviders>
  );
}
