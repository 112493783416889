import { Box } from '@mui/material';
import { ConversationRecipient, Team, WorkGroup } from '@stationwise/share-types';
import { ISelectedRecipients } from '../SelectRecipients';
import { Link } from './Link';
import { Subject } from './Subject';
import { To } from './To';

interface ConversationInformationProps {
  link?: string;
  setLink: (link: string) => void;
  subject: string;
  setSubject: (subject: string) => void;
  isEditable: boolean;
  setRecipients: (selection: ISelectedRecipients) => void;
  recipients: ISelectedRecipients;
  resetRecipients: boolean;
  conversationRecipients?: ConversationRecipient[];
  conversationWorkGroups?: WorkGroup[];
  conversationTeam?: Team[];
  rosterMessage?: boolean;
}
export const ConversationInformation = ({
  link,
  setLink,
  subject,
  setSubject,
  isEditable,
  setRecipients,
  recipients,
  resetRecipients,
  conversationRecipients,
  conversationWorkGroups,
  conversationTeam,
  rosterMessage,
}: ConversationInformationProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        borderBottom: `1px solid ${theme.palette.stationGray[100]}`,
        padding: '12px 16px',
      })}
    >
      <To
        isEditable={isEditable}
        setRecipients={setRecipients}
        recipients={recipients}
        resetRecipients={resetRecipients}
        conversationRecipients={conversationRecipients}
        conversationWorkGroups={conversationWorkGroups}
        conversationTeams={conversationTeam}
        rosterMessage={rosterMessage}
      />
      <Subject subject={subject} setSubject={setSubject} isEditable={isEditable} />
      {link && <Link link={link} setLink={setLink} removalAllowed={isEditable} />}
    </Box>
  );
};
