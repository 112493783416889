import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { CalendarShiftCard, GenericDrawer } from '@stationwise/component-module';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';
import { ShiftOverviewOptions } from './ShiftOverviewOptions';

interface ShiftOverviewProps {
  handleOnClose: () => void;
  drawerOpen: boolean;
  shift: EventInput | EventImpl;
  handleSelect: (option: string) => void;
  todayEvents: EventInput[];
}

export const ShiftOverview = ({ shift, handleOnClose, drawerOpen, handleSelect, todayEvents }: ShiftOverviewProps) => {
  const iosWebViewPaddingBottom = useIOSPaddingBottom();
  return (
    <GenericDrawer
      anchor="bottom"
      drawerOpen={drawerOpen}
      handleOnClose={handleOnClose}
      loading={false}
      showHeader={true}
      headerTitle="Shift overview"
      disableFooter={true}
      noBorderOnHeader={true}
      paddingBottom={iosWebViewPaddingBottom}
    >
      <Box>
        <CalendarShiftCard shift={shift} />
        <Box
          sx={(theme) => ({
            pr: theme.spacing(2),
            pl: theme.spacing(2),
          })}
        >
          <ShiftOverviewOptions shift={shift} handleSelect={handleSelect} todayEvents={todayEvents} />
        </Box>
      </Box>
    </GenericDrawer>
  );
};
