import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { ErrorPage, ThemeProvider } from '@stationwise/component-module';
import { App } from './app/app';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const fallback = (
  <ThemeProvider>
    <ErrorPage />
  </ThemeProvider>
);

root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={fallback}>
      <App />
    </Sentry.ErrorBoundary>
  </StrictMode>,
);
