import { StrikeTeam, RosterDataSource, RosterStrikeTeamApparatus, RosterStation, DEFAULT_RANK } from '@stationwise/share-types';
import { getOverrideEmployeePositionFields } from '../board';
import { setEmployeeActiveId } from '../id';

export const DEFAULT_EVENT_STATION = 'Events';

export const makeStrikeTeamApparatuses = (strikeTeams: StrikeTeam[]): RosterStrikeTeamApparatus[] => {
  return strikeTeams.map((strikeTeam) => {
    const positions = strikeTeam.positions.map((position) => {
      const employees = position.employees.map((employee) => {
        return setEmployeeActiveId({
          id: employee.id || '',
          dataSource: RosterDataSource.STRIKE_TEAM,
          name: employee.name,
          rank: employee.rank || position.rank || DEFAULT_RANK,
          certifications: employee.certifications,
          team: employee.team,
          defaults: employee.defaults,
          startDateTime: new Date(employee.startDateTime),
          endDateTime: new Date(employee.endDateTime),
          payCodes: employee.payCodes,
          detailCodes: employee.detailCodes,
          ...getOverrideEmployeePositionFields(),
          noteOverride: employee.noteOverride,
        });
      });
      return {
        id: `${position.id}`,
        dataSource: RosterDataSource.STRIKE_TEAM,
        employees,
        startDateTime: new Date(position.startDateTime),
        endDateTime: new Date(position.endDateTime),
        rank: position.rank || DEFAULT_RANK,
        certifications: position.certifications,
        isTemporary: false,
        driver: false,
      };
    });
    strikeTeam.temporaryPositions.forEach((excessCapacity) => {
      const employee = setEmployeeActiveId({
        id: `${excessCapacity.id}`,
        dataSource: RosterDataSource.STRIKE_TEAM,
        name: excessCapacity.name,
        rank: excessCapacity.rank,
        certifications: excessCapacity.certifications,
        team: excessCapacity.team,
        defaults: excessCapacity.defaults,
        startDateTime: new Date(excessCapacity.startDateTime),
        endDateTime: new Date(excessCapacity.endDateTime),
        payCodes: excessCapacity.payCodes,
        detailCodes: excessCapacity.detailCodes,
        ...getOverrideEmployeePositionFields(),
        noteOverride: excessCapacity.noteOverride,
      });
      positions.push({
        id: `strike-team-apparatus-${strikeTeam.id}|${employee.activeId}`,
        dataSource: RosterDataSource.STRIKE_TEAM,
        employees: [employee],
        startDateTime: new Date(excessCapacity.startDateTime),
        endDateTime: new Date(excessCapacity.endDateTime),
        rank: excessCapacity.rank,
        certifications: [],
        isTemporary: true,
        driver: false,
      });
    });
    return {
      id: `strike-team-apparatus-${strikeTeam.id}`,
      dataSource: RosterDataSource.STRIKE_TEAM,
      name: strikeTeam.name,
      isForShiftLeader: false,
      positions,
      certificationRequirements: [],
      deactivationDate: strikeTeam.deactivationDate,
      strikeTeam,
      strikeTeamAssignmentPayloadMap: new Map(),
      strikeTeamUnassignmentPayloadMap: new Map(),
    };
  });
};

export const makeEventStation = (station: string, strikeTeams: StrikeTeam[]) => {
  return {
    stationName: station,
    stationId: `event-station-${station}`,
    dataSource: RosterDataSource.EVENT,
    apparatuses: makeStrikeTeamApparatuses(strikeTeams),
    certificationRequirements: [],
  };
};

export const makeEventStations = (strikeTeams: StrikeTeam[]) => {
  // group strike teams by station name
  const eventGroups = new Map<string, StrikeTeam[]>();
  strikeTeams.forEach((strikeTeam) => {
    const events = eventGroups.get(strikeTeam.station) || [];
    events.push(strikeTeam);
    eventGroups.set(strikeTeam.station, events);
  });
  const eventStations: RosterStation[] = [];
  eventGroups.forEach((strikeTeams, station) => {
    eventStations.push(makeEventStation(station, strikeTeams));
  });
  return eventStations.sort((a, b) => a.stationName.localeCompare(b.stationName));
};
