import { Box, Theme, useTheme } from '@mui/material';
import { getCertColors, MinusIcon20, PlusIcon20 } from '@stationwise/component-module';
import { CertificationRequirement } from '@stationwise/share-types';

interface CertificationRequirementCounterProps {
  currentCertificationRequirement: CertificationRequirement;
  setCertRequirements: (certRequirements: CertificationRequirement[]) => void;
  certRequirements: CertificationRequirement[];
}

const amountControlsStyles = (theme: Theme) => {
  return {
    backgroundColor: theme.palette.stationGray[100],
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    cursor: 'pointer',
  };
};

export const CertificationRequirementCounter = ({
  currentCertificationRequirement,
  setCertRequirements,
  certRequirements,
}: CertificationRequirementCounterProps) => {
  const theme = useTheme();
  const color = getCertColors({ theme, color: currentCertificationRequirement.color }).color;

  const updateAmount = (newAmount: number) => {
    setCertRequirements(
      certRequirements.map((requirement) =>
        requirement.id !== currentCertificationRequirement.id
          ? requirement
          : { ...requirement, amount: newAmount < 0 ? 0 : newAmount },
      ),
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={(theme) => ({ p: theme.spacing(1), borderBottom: `1px solid ${theme.palette.stationGray[100]}` })}
    >
      <Box display="flex">
        <Box sx={{ typography: 'bodyMRegular', width: '36px', color }}>{currentCertificationRequirement.code}</Box>
        <Box sx={(theme) => ({ typography: 'bodyMRegular', color: theme.palette.stationGray[500], mx: theme.spacing(2.5) })}>
          {currentCertificationRequirement.name}
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          sx={(theme) => ({
            color: theme.palette.stationGray[400],
            '& svg path': { stroke: theme.palette.stationGray[400] },
          })}
        >
          <Box
            sx={(theme) => amountControlsStyles(theme)}
            onClick={() => updateAmount(currentCertificationRequirement.amount - 1)}
          >
            <MinusIcon20 />
          </Box>
          <Box
            sx={(theme) => ({
              mx: theme.spacing(1.5),
              color: currentCertificationRequirement.amount > 0 ? theme.palette.stationGray[900] : 'inherit',
            })}
          >
            {currentCertificationRequirement.amount}
          </Box>
          <Box
            sx={(theme) => amountControlsStyles(theme)}
            onClick={() => updateAmount(currentCertificationRequirement.amount + 1)}
          >
            <PlusIcon20 />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
