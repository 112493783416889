import { Box } from '@mui/material';
import { Badge, CustomTable, Edit02Icon16, CertBadge, TableProps } from '@stationwise/component-module';
import { RankComplete } from '@stationwise/share-types';

interface RanksTableProps {
  ranks: RankComplete[];
  handleEditAction: (rank: RankComplete) => void;
}

export const RanksTable = ({ ranks, handleEditAction }: RanksTableProps) => {
  const columns: TableProps['columns'] = [
    {
      id: 'name',
      label: 'NAME',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'code',
      label: 'CODE',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'canActCertification',
      label: '"ACTING" CERTIFICATION',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'sortOrder',
      label: 'SORT ORDER',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'isOfficer',
      label: 'IS OFFICER?',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'payPeriodType',
      label: 'PAY PERIOD TYPE',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'actions',
      label: 'ACTIONS',
      minWidth: 100,
      align: 'left',
    },
  ];

  const data: TableProps['data'] = ranks.map((rank) => ({
    name: <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>{rank.name}</Box>,
    code: (
      <Box display="inline-flex">
        <Badge label={rank.code} isRound type="sm" color={rank.color} />
      </Box>
    ),
    canActCertification: (
      <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>
        {rank.canActCertification?.name ? <CertBadge cert={rank.canActCertification} /> : '-'}
      </Box>
    ),
    sortOrder: (
      <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>{rank.sortOrder}</Box>
    ),
    isOfficer: rank.isOfficer ? 'Yes' : 'No',
    payPeriodType: (
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          textTransform: 'capitalize',
          typography: 'bodySRegular',
        })}
      >
        {rank.payPeriodType.toLowerCase()}
      </Box>
    ),
    actions: (
      <Box>
        <Box
          onClick={() => handleEditAction(rank)}
          sx={(theme) => ({
            color: theme.palette.stationGray[500],
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              color: theme.palette.stationGray[800],
            },
          })}
          title="Edit Rank"
        >
          <Edit02Icon16 />
        </Box>
      </Box>
    ),
  }));
  return <CustomTable columns={columns} data={data} />;
};
