import { Box } from '@mui/material';
import { parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import {
  ErrorPage,
  LottieLogoPageLoader,
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
  useFetchPersonalPayroll,
  useDocumentTitle,
} from '@stationwise/component-module';
import { PayPeriod } from '@stationwise/share-types';
import { PUSHER_EVENT_TYPES, PUSHER_UPDATE_MESSAGE, RefreshEventCallback } from '@stationwise/share-utils';
import { AppPageLayout } from '../../../AppPageLayout';
import { PayrollContent } from './PayrollContent';

export const PayrollMain = () => {
  useDocumentTitle('My Payroll');
  const { state: userState } = useLoadedAuthUserContext();
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const [selectedPayPeriod, setSelectedPayPeriod] = useState<PayPeriod | null>(null);
  const [refetchCounter, setRefetchCounter] = useState(0);

  const departmentInfo = departmentContext.departmentInfo;
  const currentPayPeriods = departmentInfo?.currentPayPeriods;

  const fetchParams = useMemo(() => {
    if (!departmentInfo || !currentPayPeriods || !currentPayPeriods.length) {
      return { skip: true, refetchCounter };
    }
    const payPeriodType = userState.employee.rank.payPeriodType;
    const currentPayPeriod = currentPayPeriods.find((period) => period.payPeriodType === payPeriodType) || currentPayPeriods[0];
    const currentPayPeriodStartDate = currentPayPeriod.startDate;
    const currentPayPeriodEndDate = currentPayPeriod.endDate;

    if (!selectedPayPeriod) {
      setSelectedPayPeriod({
        startDate: currentPayPeriodStartDate,
        endDate: currentPayPeriodEndDate,
        duration: currentPayPeriod.duration,
        payPeriodType: payPeriodType,
        id: currentPayPeriod.id,
      });
    }
    return {
      startDate: selectedPayPeriod?.startDate || currentPayPeriodStartDate,
      endDate: selectedPayPeriod?.endDate || currentPayPeriodEndDate,
      refetchCounter,
    };
  }, [departmentInfo, refetchCounter, selectedPayPeriod, currentPayPeriods, userState.employee.rank.payPeriodType]);

  const { data, isError, isLoading } = useFetchPersonalPayroll(fetchParams);
  const refreshTriggerChannel = departmentContext.refreshTriggerChannel;
  const employeeId = userState.employee?.id || '';

  useEffect(() => {
    if (!refreshTriggerChannel) return;

    const handlePusherUpdate: RefreshEventCallback = (data) => {
      if (data.triggerAll) {
        setRefetchCounter((c) => c + 1);
      } else if (data.message === PUSHER_UPDATE_MESSAGE) {
        data.employeeIdList.forEach((id) => {
          if (id.toString() === employeeId.toString()) {
            setRefetchCounter((c) => c + 1);
          }
        });
      }
    };

    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.REVIEW];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
    };
  }, [refreshTriggerChannel, employeeId, refetchCounter]);

  if (!currentPayPeriods || !currentPayPeriods.length) {
    return <ErrorPage title="No Payroll Details?" subtitle="We are currently calculating your payroll details." />;
  }

  return (
    <AppPageLayout>
      <Box
        data-testid="payroll"
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: '768px',
          margin: '0 auto',
        }}
      >
        {isLoading || !fetchParams.startDate ? (
          <LottieLogoPageLoader />
        ) : isError ? (
          <ErrorPage />
        ) : data === null ? (
          <div>No Data...</div>
        ) : (
          <PayrollContent
            endDate={parseISO(fetchParams.endDate)}
            startDate={parseISO(fetchParams.startDate)}
            payroll={data}
            selectedPayPeriod={selectedPayPeriod}
            setSelectedPayPeriod={setSelectedPayPeriod}
            setRefetchCounter={setRefetchCounter}
            refetchCounter={refetchCounter}
          />
        )}
      </Box>
    </AppPageLayout>
  );
};
