import { ReactComponent as AvgPaceSymbol } from '@material-symbols/svg-400/outlined/avg_pace.svg';
import { Box, Tooltip, useTheme } from '@mui/material';
import { AllowedColors, StaffingListItem } from '@stationwise/share-types';
import { differenceInUTCMinutes, makeTestIdentifier } from '@stationwise/share-utils';
import { getOvertimeCardErrorMessage, makeOvertimeEmployeeActiveId } from '@stationwise/shift-summary-helper';
import { HireBackCard } from '../../../HireBackCard';
import { SvgIcon } from '../../../SvgIcon';
import { getEmployeeCardStyles } from '../EmployeeCard';
import { useRosterContext } from '../RosterContext';

interface HireBackOperatorCardProps {
  operator: StaffingListItem;
  isDragOverlayCard?: boolean;
}

export const HireBackOperatorCard = ({ operator, isDragOverlayCard = false }: HireBackOperatorCardProps) => {
  const theme = useTheme();
  const { staffingListsState, selectedEmptyPositionState, shiftSummaryHelper } = useRosterContext();
  const durations = staffingListsState.employeeDurations.get(operator.employee.id) || [];
  const totalDuration = durations.reduce((sum, e) => sum + differenceInUTCMinutes(e.endDateTime, e.startDateTime), 0);
  const errorMessage = getOvertimeCardErrorMessage(shiftSummaryHelper, operator);
  const hasErrorMessageStyle = !!errorMessage && !isDragOverlayCard;

  const card = (
    <Box
      data-cy={`ot-card-${makeTestIdentifier(operator.employee.name)}`}
      sx={{
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        borderRadius: '12px',
        border: `1px solid ${theme.palette.stationGray[100]}`,
        opacity: hasErrorMessageStyle ? 0.5 : undefined,
        position: 'relative',
      }}
    >
      <HireBackCard
        type="border"
        badgeTitle={operator.employee.rank.name}
        color={operator.employee.rank.color}
        borderColor={(operator.employee.team?.color?.toLowerCase() || 'white') as AllowedColors}
        nameLabel={operator.employee.name}
        certs={operator.employee.certifications}
        number={operator.order}
        partialHoursOt={`${operator.attributes.overtimeAccHours ?? ''}`}
        lastShiftOt={operator.attributes.lastThreeOvertimes?.[0]?.date ?? ''}
        timeAsString={operator.attributes.lastThreeOvertimes?.[0]?.staffedAt ?? ''}
        cardSxProps={getEmployeeCardStyles({
          theme,
          employeeActiveId: makeOvertimeEmployeeActiveId(`${operator.employee.id}`),
          selectedEmptyPositionState,
        })}
      />
      {totalDuration > 0 && totalDuration < 24 * 60 && (
        <Box
          sx={{
            backgroundColor: theme.palette.stationGray[900],
            color: theme.palette.common.white,
            borderRadius: '50%',
            display: 'inline-flex',
            position: 'absolute',
            bottom: theme.spacing(1),
            right: theme.spacing(1.5),
          }}
        >
          <SvgIcon component={AvgPaceSymbol} sx={{ fontSize: '20px' }} />
        </Box>
      )}
    </Box>
  );

  if (!hasErrorMessageStyle) {
    return card;
  }

  return (
    <Tooltip
      arrow
      title={errorMessage}
      placement="bottom"
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.stationGray[800],
            borderRadius: 2,
            p: '10px 12px',
            typography: 'bodySMedium',
            '& .MuiTooltip-arrow': { color: theme.palette.stationGray[800] },
          },
        },
      }}
    >
      {card}
    </Tooltip>
  );
};
