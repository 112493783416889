import { captureException } from '@sentry/react';
import { GenericDrawer, FeedbackMessage, Button } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { useIOSPaddingBottom } from '../../../../context/DeviceInfoContext';
interface PayrollValidationWarningModalProps {
  setOpenPayrollValidationWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenPayrollValidationModal: React.Dispatch<React.SetStateAction<boolean>>;
  openPayrollValidationWarningModal: boolean;
  payPeriodId: number | undefined;
}
export const PayrollValidationWarningModal = ({
  setOpenPayrollValidationWarningModal,
  setOpenPayrollValidationModal,
  openPayrollValidationWarningModal,
  payPeriodId,
}: PayrollValidationWarningModalProps) => {
  const iosWebViewPaddingBottom = useIOSPaddingBottom();
  const closeWarningModal = () => {
    setOpenPayrollValidationWarningModal(false);
  };

  const handlePressSignLater = async (
    payPeriodId: number | undefined,
    setOpenPayrollValidationModal: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    try {
      await client.post('/payroll/personal/validate-payroll-later/', {
        payPeriodId: payPeriodId,
      });
      setOpenPayrollValidationModal(false);
    } catch (error) {
      captureException(error);
    }
  };

  return (
    <GenericDrawer
      anchor="bottom"
      loading={false}
      drawerOpen={openPayrollValidationWarningModal}
      handleOnClose={closeWarningModal}
      paddingBottom={iosWebViewPaddingBottom}
    >
      <>
        <FeedbackMessage
          status="warning"
          firstMessage="You must validate your time card before you get paid"
          secondMessage="Your approver will know you have not signed your time card yet."
        />
        <Button
          data-cy="sign-time-card"
          buttonType="tertiary"
          buttonSize="lg"
          variant="contained"
          sx={(theme) => ({
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            width: '90%',
            textTransform: 'none',
            borderRadius: '6px',
            marginTop: theme.spacing(1),
            alignSelf: 'center',
          })}
          onClick={() => handlePressSignLater(payPeriodId, setOpenPayrollValidationModal)}
        >
          I understand
        </Button>

        <Button
          data-cy="sign-time-card"
          buttonType="tertiary"
          buttonSize="lg"
          variant="contained"
          sx={(theme) => ({
            marginTop: theme.spacing(2),
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            width: '90%',
            alignSelf: 'center',
            textTransform: 'none',
            borderRadius: '6px',
          })}
          onClick={closeWarningModal}
        >
          I will sign now
        </Button>
      </>
    </GenericDrawer>
  );
};
