import { useState } from 'react';
import { useLoadedDepartmentInfoContext, useAuthUserCapabilities, useLoadedAuthUserContext } from '@stationwise/component-module';
import { MessagesPanel } from './MessagesPanel';
import { RequestsPanel } from './RequestsPanel';
import { TopBar } from './TopBar';

export const MessagesInboxContent = () => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const [isCreatingMessage, setIsCreatingMessage] = useState(false);

  const capabilities = useAuthUserCapabilities();
  const canSendMessages = capabilities.SEND_MESSAGES;

  const requestsPanelEnabled =
    authUserState.employee.hasAdminPermission ||
    (authUserState.employee.role === 'OFFICER' && departmentInfoState.departmentInfo.settings.captainApprovesShiftTrades);

  const [requestsPanelOpen, setRequestsPanelOpen] = useState(requestsPanelEnabled);

  const onComposeClick = () => {
    setIsCreatingMessage(true);
  };

  return (
    <div data-testid="messages-inbox-content">
      <TopBar
        requestsPanelEnabled={requestsPanelEnabled}
        requestsPanelOpen={requestsPanelOpen}
        setRequestsPanelOpen={setRequestsPanelOpen}
        canSendMessages={canSendMessages}
        onComposeClick={onComposeClick}
        setIsCreatingMessage={setIsCreatingMessage}
        isCreatingMessage={isCreatingMessage}
      />
      {requestsPanelOpen && <RequestsPanel />}
      {!requestsPanelOpen && (
        <MessagesPanel
          isCreatingMessage={isCreatingMessage}
          setIsCreatingMessage={setIsCreatingMessage}
          canSendMessages={canSendMessages}
        />
      )}
    </div>
  );
};
