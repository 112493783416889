import { RosterStrikeTeamApparatus } from '@stationwise/share-types';
import { copyBoardWithNewApparatus, sortStations } from '../board';
import { checkIsEvent, checkIsStrikeTeamApparatus } from '../id';
import { IShiftSummaryHelper } from '../types';
import { makeEventStation } from './station';

export const updateStrikeTeamName = (
  shiftSummaryHelper: IShiftSummaryHelper,
  apparatus: RosterStrikeTeamApparatus,
  name: string,
) => {
  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(shiftSummaryHelper, apparatus.id);
  if (!checkIsStrikeTeamApparatus(newApparatus)) {
    throw new Error('Cannot update event name');
  }

  newApparatus.name = name;
  newApparatus.strikeTeam = { ...newApparatus.strikeTeam, name };
  return { ...shiftSummaryHelper, allStationCards: newAllStationCards };
};

export const updateStrikeTeamStation = (
  shiftSummaryHelper: IShiftSummaryHelper,
  apparatus: RosterStrikeTeamApparatus,
  station: string,
) => {
  const {
    newAllStationCards,
    newStation: newSrcStation,
    newApparatus,
    apparatusIndex,
  } = copyBoardWithNewApparatus(shiftSummaryHelper, apparatus.id);
  if (!checkIsStrikeTeamApparatus(newApparatus)) {
    throw new Error('Cannot update event group');
  }

  newApparatus.strikeTeam = { ...newApparatus.strikeTeam, station };
  newSrcStation.apparatuses.splice(apparatusIndex, 1);
  if (!newSrcStation.apparatuses.length) {
    newAllStationCards.delete(newSrcStation.stationId);
  }

  let destStation = Array.from(newAllStationCards.values()).find((s) => checkIsEvent(s) && s.stationName === station);
  destStation = destStation && { ...destStation, apparatuses: [...destStation.apparatuses] };

  const newDestStation = destStation || makeEventStation(station, []);
  newAllStationCards.set(newDestStation.stationId, newDestStation);

  newDestStation.apparatuses.push(newApparatus);
  newDestStation.apparatuses.sort((a, b) => {
    if (!checkIsStrikeTeamApparatus(a) || !checkIsStrikeTeamApparatus(b)) {
      return 0;
    }

    return a.strikeTeam.activationDate.localeCompare(b.strikeTeam.activationDate) || a.strikeTeam.id - b.strikeTeam.id;
  });

  return { ...shiftSummaryHelper, allStationCards: sortStations(newAllStationCards) };
};
