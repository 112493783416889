import { Box, FormLabel, Modal, TextField } from '@mui/material';
import { OverrideIcon, XCloseIcon24 } from '../../assets';
import { Button } from '../Button';

interface OverrideModalProps {
  isOpen: boolean;
  onApply: () => void;
  onCancel: () => void;
  icon?: React.ReactNode;
  overrideNote: string;
  setOverrideNote: React.Dispatch<React.SetStateAction<string>>;
  subtitle?: string;
  title: string;
  label?: string;
}

export const OverrideModal = ({
  isOpen,
  onApply,
  onCancel,
  icon,
  overrideNote,
  setOverrideNote,
  subtitle,
  title,
  label = 'Submit note',
}: OverrideModalProps) => {
  return (
    <Modal open={isOpen}>
      <Box
        /* Prevent drags and clicks from propagating up to the anchor element. */
        onKeyDown={(e) => (e.code === 'Space' || e.code === 'Enter') && e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        sx={{
          display: 'flex',
          width: '100%',
          height: '90vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            borderRadius: 3,
            p: 3,
            width: '400px',
            maxWidth: '100%',
          })}
        >
          <Box pb="32px">
            <Box textAlign="right">
              <Box
                onClick={onCancel}
                sx={(theme) => ({
                  color: theme.palette.stationGray[400],
                  cursor: 'pointer',
                  display: 'inline',
                  '&:hover': {
                    color: theme.palette.stationGray[700],
                  },
                })}
              >
                <XCloseIcon24 />
              </Box>
            </Box>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.stationIndigo[50],
                borderRadius: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '56px',
                width: '56px',
                margin: '0 auto',
              })}
            >
              {icon !== undefined ? icon : <OverrideIcon />}
            </Box>
            <Box sx={{ typography: 'bodyXLSemibold', lineHeight: '28px', textAlign: 'center' }}>{title}</Box>
            {subtitle && <Box textAlign="center">{subtitle}</Box>}
          </Box>
          <Box
            sx={{
              display: 'grid',
            }}
          >
            <FormLabel sx={{ mb: '16px' }}>Message</FormLabel>
            <TextField
              multiline
              rows={4}
              sx={{ mb: '16px' }}
              value={overrideNote}
              onChange={(event) => setOverrideNote(event.target.value)}
            />
          </Box>
          <Box>
            <Button
              onClick={onApply}
              buttonSize="lg"
              sx={() => ({
                width: '100%',
              })}
              disabled={!overrideNote}
            >
              {label}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
