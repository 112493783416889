import { Box } from '@mui/material';
import { CheckIcon40 } from '../../assets/index';

export const SuccessMessage = ({ firstMessage, secondMessage }: { firstMessage: string; secondMessage?: string }) => {
  return (
    <Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.stationTeal[100],
          width: '80px',
          height: '80px',
          borderRadius: '80px',
          margin: 'auto',
          textAlign: 'center',
          mt: '44px',
          mb: '40px',
        })}
      >
        <Box sx={{ pt: '25%' }}>
          <CheckIcon40 />
        </Box>
      </Box>
      <Box sx={{ width: '310px', textAlign: 'center', margin: 'auto' }}>
        <Box sx={{ typography: 'bodyXXLSemibold' }}>{firstMessage}</Box>
        <Box
          sx={(theme) => ({
            color: theme.palette.stationGray[500],
            mt: '16px',
            mb: '32px',
          })}
        >
          {secondMessage && <Box sx={{ typography: 'bodyLRegular' }}>{secondMessage}</Box>}
        </Box>
      </Box>
    </Box>
  );
};
