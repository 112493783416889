import { Box, Menu } from '@mui/material';
import { useEffect, useState } from 'react';
import { theme } from '@stationwise/component-module';
import { AutoHireInfoView, BulkCallDetailsView } from '@stationwise/share-types';
import { AutoHireMain } from './AutoHire/AutoHireMain';
import { HiringEngineServices } from './HiringEngineServices';
import { EligibleEmployeesMain } from './RequestVolunteers/EligibleEmployeesMain';
import { RequestVolunteersHistoryModal } from './RequestVolunteers/RequestVolunteersHistoryModal';
import { RequestVolunteersMain } from './RequestVolunteers/RequestVolunteersMain';
import { AutoHireVacancy, VacantStation } from './Vacancies/vacanciesHelper';
interface HiringEngineModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  anchorEl: HTMLElement | null;
  forceRefetch: () => void;
  vacantStations: VacantStation[];
  bulkCalls: BulkCallDetailsView[];
  selectedDate: string | null | undefined;
  selectedBattalionId: number | undefined;
  autoHireInfo: AutoHireInfoView | null;
  isDateInPast: boolean;
  groupedVacancies: Record<number, AutoHireVacancy[]>;
}

export const HiringEngineModal = ({
  open,
  setOpen,
  anchorEl,
  forceRefetch,
  vacantStations,
  bulkCalls,
  selectedDate,
  selectedBattalionId,
  autoHireInfo,
  isDateInPast,
  groupedVacancies,
}: HiringEngineModalProps) => {
  const [bulkCallIsOpen, setBulkCallIsOpen] = useState(false);
  const [isRequestVolunteersHistoryModalOpen, setIsRequestVolunteersHistoryModalOpen] = useState(false);
  const [bulkCallIsLoading, setBulkCallIsLoading] = useState(false);
  const [showEligibleEmployees, setShowEligibleEmployees] = useState(false);
  const [autoHireModalOpen, setAutoHireModalOpen] = useState(false);
  const [autoHireIsActive, setAutoHireIsActive] = useState(false);
  const requestVolunteersIsActive = bulkCalls.length > 0;
  useEffect(() => {
    const autoHireIsActive = autoHireInfo && autoHireInfo.autoHires.length > 0;
    setAutoHireIsActive(autoHireIsActive ? autoHireIsActive : false);
  }, [autoHireInfo]);
  const onClose = () => {
    setOpen(false);
  };

  const withClose = (handler: () => void) => () => {
    handler();
    onClose();
  };

  const HIRING_ENGINE_OPTIONS = [
    {
      title: 'Auto Hire',
      description: 'Employees will receive a notification to choose their assignment. Similar to bidding process.',
      isActive: autoHireIsActive,
      onClick: withClose(() => {
        setAutoHireModalOpen(true);
      }),
    },
    {
      title: 'Request volunteers',
      description:
        "Employees will receive a notification to sign up for a shift. Once signed up, they'll appear on the volunteer list.",
      isActive: requestVolunteersIsActive,
      onClick: withClose(() => {
        if (requestVolunteersIsActive) {
          setIsRequestVolunteersHistoryModalOpen(true);
        } else {
          setBulkCallIsOpen(true);
        }
      }),
    },
  ];

  return (
    <Box>
      <Menu
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ marginTop: theme.spacing(2) }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            p: theme.spacing(2),
            gap: theme.spacing(2),
          })}
        >
          {HIRING_ENGINE_OPTIONS.map((option) => (
            <HiringEngineServices
              key={option.title}
              title={option.title}
              description={option.description}
              isActive={option.isActive}
              onClick={option.onClick}
            />
          ))}
        </Box>
      </Menu>
      <RequestVolunteersMain
        isOpen={bulkCallIsOpen}
        vacantStations={vacantStations}
        bulkCallIsLoading={bulkCallIsLoading}
        setBulkCallIsOpen={setBulkCallIsOpen}
        setShowEligibleEmployees={setShowEligibleEmployees}
      />
      <RequestVolunteersHistoryModal
        modalOpen={isRequestVolunteersHistoryModalOpen}
        setIsRequestVolunteersHistoryModalOpen={setIsRequestVolunteersHistoryModalOpen} // Pass the setter directly
        bulkCalls={bulkCalls}
        setBulkCallIsOpen={setBulkCallIsOpen}
      />
      <EligibleEmployeesMain
        selectedDate={selectedDate}
        showEligibleEmployees={showEligibleEmployees}
        setShowEligibleEmployees={setShowEligibleEmployees}
        bulkCallIsLoading={bulkCallIsLoading}
        setBulkCallIsLoading={setBulkCallIsLoading}
        forceRefetch={forceRefetch}
        setBulkCallIsOpen={setBulkCallIsOpen}
      />
      <AutoHireMain
        autoHireModalOpen={autoHireModalOpen}
        setAutoHireModalOpen={setAutoHireModalOpen}
        isDateInPast={isDateInPast}
        autoHireInfo={autoHireInfo}
        groupedVacancies={groupedVacancies}
        forceRefetch={forceRefetch}
        selectedBattalionId={selectedBattalionId}
        selectedDate={selectedDate}
      />
    </Box>
  );
};
