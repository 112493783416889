import { format } from 'date-fns';

export const getMessageAge = (sendingDateStr: string): string => {
  const sendingDate = new Date(sendingDateStr);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  if (isSameDate(today, sendingDate)) {
    const hours = today.getHours() - sendingDate.getHours();
    if (hours === 0) {
      const minutes = today.getMinutes() - sendingDate.getMinutes();
      if (minutes === 0) {
        const seconds = today.getSeconds() - sendingDate.getSeconds();
        return seconds === 1 ? `${seconds} SECOND AGO` : `${seconds} SECONDS AGO`;
      }
      return minutes === 1 ? `${minutes} MINUTE AGO` : `${minutes} MINUTES AGO`;
    }
    return hours === 1 ? `${hours} HOUR AGO` : `${hours} HOURS AGO`;
  } else if (isSameDate(yesterday, sendingDate)) {
    return 'YESTERDAY';
  } else {
    return format(sendingDate, 'MMM dd').toUpperCase();
  }
};

const isSameDate = (dateOne: Date, dateTwo: Date): boolean => {
  return (
    dateOne.getDate() === dateTwo.getDate() &&
    dateOne.getMonth() === dateTwo.getMonth() &&
    dateOne.getFullYear() === dateTwo.getFullYear()
  );
};
