import { RosterEmployee } from '@stationwise/share-types';
import { getPlannedAdministrationPosition, createAdministrationEmployee } from './administration';
import { checkIsPlannedEmployee } from './board';
import { IShiftSummaryHelper } from './types';
import { createUnassignedEmployee } from './unassigned';

export const addUnplannedAssignee = (shiftSummaryHelper: IShiftSummaryHelper, employee: RosterEmployee) => {
  const newShiftSummaryHelper = { ...shiftSummaryHelper };
  newShiftSummaryHelper.unplannedAssignees = [...newShiftSummaryHelper.unplannedAssignees, employee];
  return newShiftSummaryHelper;
};

export const keepPlannedEmployeeOnRoster = (shiftSummaryHelper: IShiftSummaryHelper, employee: RosterEmployee) => {
  if (shiftSummaryHelper.kellyDayEmployeeIds.includes(Number(employee.id))) {
    return addUnplannedAssignee(shiftSummaryHelper, employee);
  }
  if (getPlannedAdministrationPosition(shiftSummaryHelper, employee).position) {
    return createAdministrationEmployee(shiftSummaryHelper, employee);
  }
  if (checkIsPlannedEmployee(shiftSummaryHelper, employee)) {
    return createUnassignedEmployee(shiftSummaryHelper, employee);
  }
  return addUnplannedAssignee(shiftSummaryHelper, employee);
};
