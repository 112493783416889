import { Box } from '@mui/material';
import React from 'react';

interface RecipientChipProps {
  backgroundColor?: string;
  label: string;
}

export const RecipientChip = ({ label, backgroundColor }: RecipientChipProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'inline-flex',
        alignItems: 'flex-start',
        borderRadius: '20px',
        padding: '0px 8px',
        width: 'fit-content',
        color: theme.palette.common.white,
        backgroundColor: backgroundColor ? backgroundColor : theme.palette.stationGray[900],
        typography: 'bodyXSMedium',
      })}
    >
      {label}
    </Box>
  );
};
