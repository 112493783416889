import { Box, Typography } from '@mui/material';
import { LetterAvatar, theme } from '@stationwise/component-module';
import { ConversationRecipient } from '@stationwise/share-types';

export const Recipients = ({ recipients }: { recipients: ConversationRecipient[] }) => {
  return recipients.map((recipient) => (
    <Box key={recipient.employee.id} sx={{ ml: theme.spacing(2), display: 'flex', alignItems: 'center', mt: theme.spacing(1) }}>
      {recipient.employee.image ? (
        <img src={recipient.employee.image} alt="" />
      ) : (
        <LetterAvatar firstName={recipient.employee.firstName} color={recipient.employee.team?.color ?? 'red'} />
      )}

      <Box sx={{ ml: theme.spacing(1) }}>
        <Box sx={{ height: theme.spacing(3) }}>
          <Typography sx={{ marginTop: theme.spacing(0.5) }} variant="bodyMMedium">
            {`${recipient.employee.firstName} ${recipient.employee.lastName}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="bodySRegular" sx={{ color: theme.palette.stationGray[600] }}>
            {recipient.employee.email}
          </Typography>
        </Box>
      </Box>
    </Box>
  ));
};

// TODO: replace recipients
