import { TextField } from '@mui/material';
import { useEffect } from 'react';
import { RosterStation } from '@stationwise/share-types';
import { buildAddress } from '../../stations';

export const AddressForm = ({
  station,
  setStation,
}: {
  station: RosterStation;
  setStation: (station: RosterStation) => void;
}) => {
  useEffect(() => {
    if (!station.address) {
      setStation({ ...station, address: buildAddress() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TextField
        label="Street 1"
        value={station.address?.street1 || ''}
        onChange={(event) => {
          if (station.address) {
            setStation({
              ...station,
              address: { ...station.address, street1: event.currentTarget.value },
            });
          }
        }}
      />
      <TextField
        label="Street 2"
        value={station.address?.street2 || ''}
        onChange={(event) => {
          if (station.address) {
            setStation({
              ...station,
              address: { ...station.address, street2: event.currentTarget.value },
            });
          }
        }}
      />
      <TextField
        label="City"
        value={station.address?.city || ''}
        onChange={(event) => {
          if (station.address) {
            setStation({ ...station, address: { ...station.address, city: event.currentTarget.value } });
          }
        }}
      />
      <TextField
        label="State"
        value={station.address?.state || ''}
        onChange={(event) => {
          if (station.address) {
            setStation({ ...station, address: { ...station.address, state: event.currentTarget.value } });
          }
        }}
      />
      <TextField
        label="Postal code"
        value={station.address?.postalCode || ''}
        onChange={(event) => {
          if (station.address) {
            setStation({
              ...station,
              address: { ...station.address, postalCode: event.currentTarget.value },
            });
          }
        }}
      />
    </>
  );
};
