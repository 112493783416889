import { Box, Alert, Link, CircularProgress, Dialog } from '@mui/material';
import { captureException } from '@sentry/react';
import { useState } from 'react';
import { Button, SnackbarService, CreateNewPasswordForm } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { usePatchRequest } from './PatchRequestProvider';

interface EditAccountControlProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  isActive: boolean;
  canDeactivate: boolean;
  lastPasswordChange: string;
  employeeId: string;
  handleRefresh: () => void;
}

export const EditAccountControl = ({
  showModal,
  setShowModal,
  isActive,
  canDeactivate,
  lastPasswordChange,
  employeeId,
  handleRefresh,
}: EditAccountControlProps) => {
  const { handlePatchRequest } = usePatchRequest();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [uid, setUid] = useState('');
  const [resetPasswordToken, setResetPasswordToken] = useState('');
  const [showNewPasswordForm, setShowNewPasswordForm] = useState(false);

  const handleAccountStatus = (status: boolean) => {
    const data = {
      isActive: status,
    };
    handlePatchRequest(data);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleChangePassword = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await client.post('/auth/change-password/', {
        employeeId,
      });
      setUid(response.data.uid);
      setResetPasswordToken(response.data.token);
      setShowNewPasswordForm(true);
    } catch (err) {
      const message = isAxiosError(err) ? err.response?.data.error : '';
      if (!message) {
        captureException(err);
      }
      SnackbarService.notify({
        content: message || 'Something went wrong please try again later',
        severity: 'error',
        duration: 10000,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog open={showModal} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={(theme) => ({
              typography: 'bodyLSemibold',
              lineHeight: '32px',
              textAlign: 'left',
            })}
          >
            Account Control
          </Box>

          {showNewPasswordForm && (
            <CreateNewPasswordForm
              setShowNewPasswordForm={setShowNewPasswordForm}
              token={resetPasswordToken}
              uid={uid}
              handleRefresh={handleRefresh}
              showCancelButton
            />
          )}

          {!showNewPasswordForm && (
            <Box>
              <Box
                sx={(theme) => ({
                  typography: 'bodySSemibold',
                  textAlign: 'left',
                  paddingTop: theme.spacing(2),
                  color: theme.palette.stationGray[900],
                })}
              >
                Last Password Change
              </Box>

              <Box
                sx={(theme) => ({
                  typography: 'bodySMedium',
                  textAlign: 'left',
                  paddingTop: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                })}
              >
                {lastPasswordChange}
                <Button
                  sx={(theme) => ({
                    height: theme.spacing(5.5),
                    padding: '9px 17px 9px 17px',
                    width: theme.spacing(20),
                    backgroundColor: theme.palette.stationGray[900],
                    color: theme.palette.common.white,
                    borderRadius: theme.spacing(0.75),
                    '&:hover': { backgroundColor: theme.palette.stationGray[700], boxShadow: 'none' },
                  })}
                  buttonType="tertiary"
                  onClick={handleChangePassword}
                  disabled={isSubmitting}
                >
                  {isSubmitting && <CircularProgress color="inherit" size="1.5rem" />}
                  {!isSubmitting && 'Change Password'}
                </Button>
              </Box>

              <Box
                sx={(theme) => ({
                  typography: 'bodySSemibold',
                  textAlign: 'left',
                  paddingTop: theme.spacing(2),
                  color: theme.palette.stationGray[900],
                })}
              >
                Account Status
              </Box>

              {isActive && !canDeactivate && (
                <Box
                  sx={(theme) => ({
                    mt: theme.spacing(2),
                  })}
                >
                  <Alert severity="warning">
                    You cannot deactivate an employee who has active shift assignments. Please unassign the employee from any
                    shifts in the <Link href="/app/shift-template">Shift Template Editor</Link> before deactivating.
                  </Alert>
                </Box>
              )}

              <Box
                sx={(theme) => ({
                  justifyContent: 'space-between',
                  display: 'flex',
                  flexDirection: 'column',
                  mt: theme.spacing(2),
                  width: '100%',
                })}
              >
                {isActive && (
                  <Button
                    buttonType="tertiary"
                    variant="contained"
                    sx={(theme) => ({
                      width: '100%',
                      height: '44px',
                      padding: '9px 17px 9px 17px',
                      backgroundColor: theme.palette.stationRed[600],
                      color: theme.palette.common.white,
                      borderRadius: theme.spacing(0.75),
                      '&:hover': { backgroundColor: theme.palette.stationRed[700], boxShadow: 'none' },
                    })}
                    onClick={() => handleAccountStatus(false)}
                    disabled={!canDeactivate}
                  >
                    <Box component="span" sx={{ typography: 'buttonM' }}>
                      Deactivate
                    </Box>
                  </Button>
                )}

                {!isActive && (
                  <Button
                    sx={(theme) => ({
                      width: '100%',
                      height: '44px',
                      padding: '9px 17px 9px 17px',
                      backgroundColor: theme.palette.stationGreen[600],
                      color: theme.palette.common.white,
                      borderRadius: theme.spacing(0.75),
                      '&:hover': { backgroundColor: theme.palette.stationGreen[700], boxShadow: 'none' },
                    })}
                    buttonType="tertiary"
                    variant="contained"
                    disableFocusRipple={true}
                    disableTouchRipple={true}
                    onClick={() => handleAccountStatus(true)}
                  >
                    <Box component="span" sx={{ typography: 'buttonM' }}>
                      Activate
                    </Box>
                  </Button>
                )}

                <Button
                  buttonType="tertiary"
                  variant="contained"
                  sx={(theme) => ({
                    width: '100%',
                    height: '44px',
                    padding: '9px 17px 9px 17px',
                    mt: theme.spacing(2),
                  })}
                  onClick={() => handleClose()}
                >
                  <Box component="span" sx={{ typography: 'buttonM' }}>
                    Cancel
                  </Box>
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
