import { Box, Theme, useTheme } from '@mui/material';
import { useState } from 'react';
import { TimeOffLimit } from '@stationwise/share-types';
import { BlankCard } from '../BlankCard';
import { Props, TimeOff } from './TimeOff';

type Consumed = { [key: string]: number };
type Pending = { [key: string]: number };

interface AccrualsTimeOffData {
  consumed: Consumed;
  pending: Pending;
}

const mapAccruals = (theme: Theme, accruals: TimeOffLimit[], accrualsTimeOffData: AccrualsTimeOffData) => {
  const color = [
    theme.palette.stationPink[600],
    theme.palette.stationBlue[600],
    theme.palette.stationPurple[600],
    theme.palette.stationLightBlue[500],
  ];
  const pendingColor = [
    theme.palette.stationPink[300],
    theme.palette.stationBlue[300],
    theme.palette.stationPurple[300],
    theme.palette.stationLightBlue[300],
  ];

  const mapped: Props[] = [];
  if (Array.isArray(accruals)) {
    accruals.forEach((acc, index) => {
      if (!acc.isUnlimited) {
        mapped.push({
          label: acc.payCode.name,
          remaining: acc.accruedTimeOff,
          pending: acc.pending,
          maxAccrualLimit: acc.payCode.maxAccrualLimit || 365 * 24,
          barColor: color[index % 4],
          pendingBarColor: pendingColor[index % 4],
        });
      }
    });
  }
  return mapped;
};

export const AccrualsCard = ({ accruals, id }: { accruals: TimeOffLimit[]; id?: string }) => {
  const theme = useTheme();
  const [accrualsTimeOffData] = useState<AccrualsTimeOffData>({ consumed: {}, pending: {} });
  const mappedAccruals = mapAccruals(theme, accruals, accrualsTimeOffData);

  if (mappedAccruals.length === 0) {
    return null;
  }

  return (
    <BlankCard type="passive" sxProps={{ border: 'none', padding: 2, boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)' }}>
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          mt: 1,
          typography: 'bodyLSemibold',
        })}
      >
        Accruals
      </Box>
      {mappedAccruals.map((accrual) => (
        <Box key={accrual.label}>
          <TimeOff
            label={accrual.label}
            remaining={accrual.remaining}
            pending={accrual.pending}
            maxAccrualLimit={accrual.maxAccrualLimit}
            barColor={accrual.barColor}
            pendingBarColor={accrual.pendingBarColor}
          />
        </Box>
      ))}
    </BlankCard>
  );
};
