import { Box } from '@mui/material';
import { useState } from 'react';
import { PromotionHistory } from '@stationwise/share-types';
import { EditCareerInfo } from './Edit/EditCareerInfo';
import { InfoCard, InfoCardField } from './InfoCard';

type CareerSectionProps = {
  promotionHistory: PromotionHistory[];
  hireDate: string | null;
};

export const CareerSection = (props: CareerSectionProps) => {
  const [showModal, setShowModal] = useState(false);
  const fieldDefs: InfoCardField[][] = [
    [
      {
        label: 'Hire Date',
        field: props.hireDate
          ? {
              value: props.hireDate,
              type: 'date',
            }
          : {},
      },
    ],
    props.promotionHistory.length !== 0
      ? [
          {
            label: 'Last Promotion',
            field: {
              label: props.promotionHistory[0].rank.name,
              value: props.promotionHistory[0].promotionDate,
              type: 'date',
            },
          },
          {
            label: 'All Promotion Dates',
            field: props.promotionHistory.map((promotion) => {
              return {
                label: promotion.rank.name,
                value: promotion.promotionDate,
                type: 'date',
              };
            }),
          },
        ]
      : [],
  ];

  const handleEditModal = () => {
    setShowModal(true);
  };

  return (
    <Box
      width="100%"
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        borderRadius: '16px',
        border: `1px solid ${theme.palette.stationGray[200]}`,
        p: theme.spacing(4, 3, 3, 3),
        gap: theme.spacing(3),
      })}
    >
      <EditCareerInfo showModal={showModal} setShowModal={setShowModal} hireDate={props.hireDate} />
      <InfoCard fieldDefs={fieldDefs} title="Career" handleEditModal={handleEditModal} />
      {props.promotionHistory.length === 0 && <Box sx={(theme) => ({ mt: theme.spacing(2) })}>No promotion history</Box>}
    </Box>
  );
};
