import { Box, Link as MUILink } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { client, isAxiosError } from '@stationwise/share-api';
import { ArrowLeftIcon20, Mail01Icon24 } from '../../../assets';
import { useNextURLQueryParam } from '../../../hooks';
import { Button } from '../../Button';
import { ErrorBanner } from '../../ErrorBanner';
import { theme } from '../../ThemeProvider';

interface MailSentPageProps {
  email: string;
}

export const MailSentPage = ({ email }: MailSentPageProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [countdown, setCountdown] = useState(0);
  const nextUrl = useNextURLQueryParam();

  const setAnError = (message: string) => {
    setErrorMessage(message);
  };

  const handleSubmit = async () => {
    try {
      await client.post('/auth/login/reset-password/send-email/', { email: email, next: nextUrl });
      setCountdown(60);
    } catch (err) {
      const message = isAxiosError(err) ? err.response?.data.error : '';
      if (!message) {
        captureException(err);
      }
      setAnError(message || 'Something went wrong please try again later');
    }
  };
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [countdown]);

  const onMailClick = () => {
    window.location.href = 'mailto:';
  };

  const errorBanner = !!errorMessage && <ErrorBanner sx={{ mt: -1, mb: 2 }}>{errorMessage}</ErrorBanner>;
  return (
    <Box
      sx={(theme) => ({
        mt: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
          mt: theme.spacing(4),
        },
      })}
    >
      <Box
        sx={(theme) => ({
          mx: 'auto',
          [theme.breakpoints.up('sm')]: {
            width: 368,
          },
          maxWidth: '368px',
          px: theme.spacing(2),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mx: 'auto',
            width: 48,
            height: 48,
            borderRadius: '50%',
            border: theme.palette.stationGray[600],
            background: theme.palette.common.white,
            boxShadow: '0px 2px 4px -1px rgba(10, 14, 22, 0.06), 0px 4px 6px -1px rgba(10, 14, 22, 0.10)',
            color: theme.palette.stationBlue[600],
            [theme.breakpoints.up('sm')]: {
              mb: 6,
              width: 80,
              height: 80,
            },
            '& svg': {
              width: 24,
              height: 24,
              [theme.breakpoints.up('sm')]: {
                width: 40,
                height: 40,
              },
            },
          })}
        >
          <Mail01Icon24 />
        </Box>
        <Box
          component="h1"
          sx={(theme) => ({
            color: theme.palette.stationGray[900],
            typography: 'heading4',
            mt: 5,
            mb: 4,
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
              fontSize: '32px',
              mb: 6,
            },
          })}
        >
          {'Check your email'}
        </Box>
        <Box
          component="p"
          sx={(theme) => ({
            color: theme.palette.stationGray[600],
            mt: 2.5,
            mb: 5,
            typography: 'bodyMRegular',
            textAlign: 'center',
          })}
        >
          <span>
            {"We've sent a reset password link to"}
            <br />
            <Box component="span" sx={{ fontWeight: 600 }}>
              {email.toString()}
            </Box>
          </span>
        </Box>
        <Box>
          {errorBanner}
          <Box
            sx={(theme) => ({
              color: theme.palette.stationGray[600],
              mb: 5,
              typography: 'bodyMRegular',
              textAlign: 'center',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
            })}
          >
            <Box
              component="span"
              sx={{
                verticalAlign: 'middle',
                width: '328px',
                padding: '9px 17px',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                onClick={onMailClick}
                sx={() => ({
                  borderRadius: '6px',
                  backgroundColor: theme.palette.stationBlue[600],
                  color: theme.palette.common.white,
                  typography: 'bodyMMedium',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '44px',
                  width: '100%',
                  display: 'flex',
                  padding: '9px 17px',
                  flexShrink: 0,
                })}
              >
                {'Open email app'}
              </Button>
            </Box>

            <Box
              sx={{
                mt: 5,
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
              }}
            >
              <Box component="span" sx={{ verticalAlign: 'middle' }}>
                {countdown > 0 ? 'Please wait ' : "Didn't receive the email? "}
              </Box>
              <MUILink
                component="button"
                underline="none"
                onClick={handleSubmit}
                disabled={countdown > 0}
                sx={(theme) => ({
                  color: countdown === 0 ? theme.palette.stationBlue[600] : theme.palette.stationGray[600],
                  fontWeight: 600,
                  verticalAlign: 'middle',
                })}
              >
                {countdown > 0 ? `${countdown} seconds to click resend` : 'Click to resend'}
              </MUILink>
            </Box>
          </Box>
        </Box>
        <Button
          buttonType="tertiary"
          component={RouterLink}
          to="/login"
          startIcon={<ArrowLeftIcon20 />}
          sx={{
            border: '1px solid transparent',
            p: '9px 17px',
            width: '100%',
            typography: 'bodyMMedium',
          }}
        >
          <span>Back to log in</span>
        </Button>
      </Box>
    </Box>
  );
};
