import { useState, useEffect, useCallback } from 'react';
import { client } from '@stationwise/share-api';
import { RequestVolunteersSignUpView } from '../components/RequestVolunteersSignUpMain';

interface UseFetchRequestVolunteersSignUpDataProps {
  requestVolunteersId: string;
  skip?: boolean;
}

export const useFetchRequestVolunteersSignUpData = ({
  requestVolunteersId,
  skip = false,
}: UseFetchRequestVolunteersSignUpDataProps) => {
  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<RequestVolunteersSignUpView | null>(null);

  useEffect(() => {
    if (skip || !requestVolunteersId) {
      return;
    }

    setIsLoading(true);
    setIsError(false);
    setError(null);
    setData(null);

    client
      .get('shift/overtime-sign-up/', {
        params: {
          bulk_call_id: requestVolunteersId,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setIsError(true);
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [requestVolunteersId, fetchNoRequest, skip]);

  const forceRefetch = useCallback(() => {
    setFetchNoRequest((prevValue) => prevValue + 1);
  }, []);

  return {
    isLoading,
    isError,
    error,
    data,
    forceRefetch,
  };
};
