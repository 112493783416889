import { copyBoardWithNewApparatus, makeTemporaryPosition, makePlaceholderEmployee, sortTemporaryPositions } from '../board';
import { COLLISION_REGION } from '../constants';
import { getMandatoryDetailCodes } from '../detailCode';
import { setEmployeeActiveId } from '../id';
import { getMandatoryPayCodes } from '../payCode';
import { removeBoardEmployeeAvailability } from '../removeEmployeeAvailability/board';
import { IShiftSummaryHelperCollisionParams } from '../types';

export const addActiveToApparatus = (params: IShiftSummaryHelperCollisionParams) => {
  // Add employee to excess capacity by appending a temporary position to the apparatus.
  const { over, active } = params;
  if (over.region !== COLLISION_REGION.BOARD_APPARATUS) {
    throw new Error('Cannot add active to apparatus');
  }

  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(params, over.apparatus.id);
  const newPosition = makeTemporaryPosition(newApparatus, active.employee);
  const newEmployee = setEmployeeActiveId({
    ...active.employee,
    dataSource: newPosition.dataSource,
    payCodes: getMandatoryPayCodes(params, newPosition, active.employee),
    detailCodes: getMandatoryDetailCodes(params, newPosition, active.employee),
  });
  newPosition.employees.push(newEmployee);
  newApparatus.positions = sortTemporaryPositions([...newApparatus.positions, newPosition]);
  const newActive = { employee: newEmployee };
  return { ...params, newActive, allStationCards: newAllStationCards };
};

export const addActiveToPosition = (params: IShiftSummaryHelperCollisionParams) => {
  const { over, active } = params;
  if (over.region !== COLLISION_REGION.BOARD_POSITION) {
    throw new Error('Cannot add active to position');
  }

  const overStartDateTime = over.employee?.startDateTime ?? params.shiftDuration.startTime;
  const overEndDateTime = over.employee?.endDateTime ?? params.shiftDuration.endTime;

  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(params, over.apparatus.id);
  const newPosition = newApparatus.positions[over.positionIndex];
  const newEmployee = setEmployeeActiveId({
    ...active.employee,
    dataSource: newPosition.dataSource,
    payCodes: getMandatoryPayCodes(params, newPosition, active.employee),
    detailCodes: getMandatoryDetailCodes(params, newPosition, active.employee),
    startDateTime: overStartDateTime > active.employee.startDateTime ? overStartDateTime : active.employee.startDateTime,
    endDateTime: overEndDateTime < active.employee.endDateTime ? overEndDateTime : active.employee.endDateTime,
  });

  const newEmployees = [];
  if (over.employee) {
    newEmployees.push(...over.position.employees.slice(0, over.employeeIndex));
  }

  if (overStartDateTime < newEmployee.startDateTime) {
    const newVacancy = makePlaceholderEmployee(over.position, {
      ...newEmployee,
      startDateTime: overStartDateTime,
      endDateTime: newEmployee.startDateTime,
    });
    newEmployees.push(newVacancy);
  }

  newEmployees.push(newEmployee);

  if (overEndDateTime > newEmployee.endDateTime) {
    const newVacancy = makePlaceholderEmployee(over.position, {
      ...newEmployee,
      startDateTime: newEmployee.endDateTime,
      endDateTime: overEndDateTime,
    });
    newEmployees.push(newVacancy);
  }

  if (over.employee) {
    newEmployees.push(...over.position.employees.slice(over.employeeIndex + 1, over.position.employees.length));
  }

  newPosition.employees = newEmployees;
  const newActive = { employee: newEmployee };

  return { ...params, newActive, allStationCards: newAllStationCards };
};

export const removeActiveFromPosition = (params: IShiftSummaryHelperCollisionParams) => {
  const { over, active } = params;
  if (active.region !== COLLISION_REGION.BOARD_POSITION) {
    throw new Error('Cannot remove active from position');
  }

  let startDateTime = active.employee.startDateTime;
  let endDateTime = active.employee.endDateTime;
  if (over.region === COLLISION_REGION.BOARD_POSITION) {
    const overStartDateTime = over.employee?.startDateTime ?? params.shiftDuration.startTime;
    const overEndDateTime = over.employee?.endDateTime ?? params.shiftDuration.endTime;
    startDateTime = overStartDateTime > active.employee.startDateTime ? overStartDateTime : active.employee.startDateTime;
    endDateTime = overEndDateTime < active.employee.endDateTime ? overEndDateTime : active.employee.endDateTime;
  }

  return removeBoardEmployeeAvailability({
    shiftSummaryHelper: params,
    employeeId: active.employee.id,
    startTime: startDateTime,
    endTime: endDateTime,
    checkIsPositionExcluded: (p) => p.id !== active.position.id,
  });
};
