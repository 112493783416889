import { Box } from '@mui/material';

export const PrintDayScheduleModalHeader = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '32px' }}>
      <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodyXLSemibold' })}>Print Day Schedule</Box>
      <Box
        sx={(theme) => ({
          mt: theme.spacing(-1.5),
          mr: theme.spacing(-1.5),
          cursor: 'pointer',
          borderRadius: '50%',
        })}
      ></Box>
    </Box>
  );
};
