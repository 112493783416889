import { Box, ButtonBase, Pagination } from '@mui/material';
import { captureException } from '@sentry/react';
import React, { useDeferredValue, useEffect, useRef, useState } from 'react';
import { SearchAndFilter, LottieLogo, theme, useLoadedDepartmentInfoContext, ErrorPage } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { EmployeePayroll } from '@stationwise/share-types';
import { capitalize, camelCaseToTitle } from '@stationwise/share-utils';
import { PayrollExportModal } from './PayrollExportModal';
import { PayrollList } from './PayrollList';

interface FilterOptions {
  [key: string]: string[];
}

export const PayrollMain: React.FC = () => {
  const [employeePayrolls, setEmployeePayrolls] = useState<EmployeePayroll[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const deferredSearchInput = useDeferredValue(searchInput);
  const [isLoading, setIsLoading] = useState(true);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  const [selectedEmployeePayrolls, setselectedEmployeePayrolls] = useState<EmployeePayroll[]>([]);
  const [clickedEmployeePayroll, setClickedEmployeePayroll] = useState<EmployeePayroll | null>(null);
  const [exportModelOpen, setExportModelOpen] = useState(false);
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await client.get('payroll/filter_options/');
        if (response.data) {
          setFilterOptions(response.data);
        }
      } catch (error) {
        captureException(error);
      }
    };
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      try {
        const filterParams = Object.fromEntries(Object.entries(selectedFilters).filter(([_, value]) => value));
        const params = {
          page: page,
          latest: true,
          ...(deferredSearchInput && { search: deferredSearchInput }),
          ...filterParams,
        };

        const response = await client.get('payroll/', {
          params,
          signal: abortControllerRef.current.signal,
        });
        if (response.data.results && Array.isArray(response.data.results)) {
          setEmployeePayrolls(response.data.results);
          setTotalPages(Math.ceil(response.data.count / 25));
        }
        setIsLoading(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && captureException(error);
        !isCanceled && setIsLoading(false);
      }
    };
    fetchData();
  }, [page, deferredSearchInput, selectedFilters]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setselectedEmployeePayrolls([]);
  };

  const handleSearchChange = (value: string) => {
    setPage(1);
    setSearchInput(value);
  };

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const currentPayPeriods = departmentInfoState.departmentInfo.currentPayPeriods;
  if (!currentPayPeriods.length) {
    return <ErrorPage title="No Payroll Details?" subtitle="We are currently calculating your payroll details." />;
  }

  const handleFilterChange = (filterName: string, value: string) => {
    setPage(1);
    setSelectedFilters((prev) => ({ ...prev, [filterName]: value }));
    setselectedEmployeePayrolls([]);
  };
  const filters = Object.entries(filterOptions).map(([filterName, options]) => ({
    name: camelCaseToTitle(capitalize(filterName)),
    options: options.map((option) => ({
      label: capitalize(option),
      value: capitalize(option),
    })),
    selected: selectedFilters[filterName],
    onChange: (value: string) => handleFilterChange(filterName, value),
  }));

  const clearAllFilters = () => {
    setSelectedFilters({});
    setselectedEmployeePayrolls([]);
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        height: '100%',
        overflowY: 'scroll',
      })}
    >
      <PayrollExportModal
        exportModelOpen={exportModelOpen}
        setExportModelOpen={setExportModelOpen}
        selectedEmployeePayrolls={selectedEmployeePayrolls}
        employeePayrolls={employeePayrolls}
        selectedFilters={selectedFilters}
        currentPayPeriods={currentPayPeriods}
      />
      {selectedEmployeePayrolls.length > 0 && (
        <Box
          sx={(theme) => ({
            position: 'fixed',
            bottom: theme.spacing(3),
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: theme.palette.stationGray[800],
            borderRadius: theme.spacing(1.5),
            padding: '12px 16px',
            width: 'auto',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
          })}
        >
          <ButtonBase
            className="export-time-card-button-inner"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(1),
              borderRadius: theme.spacing(1),
              backgroundColor: theme.palette.stationGray[600],
              color: theme.palette.common.white,
              padding: theme.spacing(1),
              fontWeight: 500,
              typography: 'bodyS',
            }}
            onClick={() => setExportModelOpen(true)}
          >
            Export time card
          </ButtonBase>
        </Box>
      )}
      <Box sx={{ px: 5, pt: 4, pb: 8 }}>
        <Box
          component="h1"
          sx={(theme) => ({
            typography: 'heading4',
            m: 0,
          })}
        >
          Payroll
        </Box>
      </Box>

      <Box
        sx={{
          width: '75%',
          mx: 'auto',
          boxShadow: 'none',
          gap: 3,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchAndFilter
            searchText={searchInput}
            setSearchText={handleSearchChange}
            filters={filters}
            clearAllFilters={clearAllFilters}
            searchPlaceholder="Search for people in your team"
          />
        </Box>
        {!isLoading && (
          <PayrollList
            selectedEmployeePayrolls={selectedEmployeePayrolls}
            setselectedEmployeePayrolls={setselectedEmployeePayrolls}
            employeePayrolls={employeePayrolls}
            clickedEmployeePayroll={clickedEmployeePayroll}
            setClickedEmployeePayroll={setClickedEmployeePayroll}
          />
        )}
        {isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
            <LottieLogo height="200px" width="200px" />
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {totalPages > 1 && !isLoading && <Pagination count={totalPages} page={page} onChange={handlePageChange} sx={{ my: 2 }} />}
      </Box>
    </Box>
  );
};
