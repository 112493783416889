import { Box, Button, useMediaQuery, useTheme, Drawer } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useEffect, useState } from 'react';
import { ChevronDownIcon16 as ChevronDown, ChevronUpIcon16 as ChevronUp } from '../../../assets';
import { SelectContactOptionProps } from './Options';
import { Recipients } from './Recipients';
import { ISelectedRecipients } from './types';

interface SelectProps {
  data: {
    [key in string]: SelectContactOptionProps[];
  };
  isEditable?: boolean;
  editRecipients: (selectedRecipients: ISelectedRecipients) => void;
  reset: boolean;
}

export const SelectRecipients = ({ data, isEditable, editRecipients, reset }: SelectProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setAnchorEl(null);
    handleClose();
  }, [reset]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const openPopOver = Boolean(anchorEl);

  return (
    <>
      <Button
        data-cy="message-to"
        disabled={!isEditable}
        variant="contained"
        onClick={handleClick}
        sx={(theme) => ({
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderColor: theme.palette.stationGray[400],
          },
          backgroundColor: 'transparent',
          border: `1px solid ${theme.palette.stationGray[200]}`,
          boxShadow: 'none',
          height: '28px',
          borderRadius: theme.spacing(1),
        })}
      >
        <Box
          display="flex"
          sx={(theme) => ({
            width: '55px',
            cursor: 'pointer',
            color: theme.palette.stationGray[500],
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 500,
            p: '6px 8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '5px',
            '&:hover': {
              color: theme.palette.stationGray[900],
            },
          })}
        >
          <Box sx={{}}>To</Box>
          <Box sx={{ display: 'flex' }}>{open ? <ChevronUp /> : <ChevronDown />}</Box>
        </Box>
      </Button>

      {isSmallScreen && (
        <Drawer sx={{ display: !open ? 'none' : 'inherit' }} open={openPopOver} onClose={handleClose} anchor={'bottom'}>
          <Recipients
            data={data}
            onAddRecipients={(selectedRecipients, close = true) => {
              if (close) {
                handleClose();
              }
              editRecipients(selectedRecipients);
            }}
            onClose={handleClose}
          />
        </Drawer>
      )}

      {!isSmallScreen && (
        <Popover
          sx={{ display: !open ? 'none' : 'inherit' }}
          open={openPopOver}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Recipients
            data={data}
            onAddRecipients={(selectedRecipients, close = true) => {
              if (close) {
                handleClose();
              }
              editRecipients(selectedRecipients);
            }}
            onClose={handleClose}
          />
        </Popover>
      )}
    </>
  );
};
