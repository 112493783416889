import { Box, BoxProps } from '@mui/material';

export const Row = ({ children, sx, ...props }: BoxProps<'div'>) => {
  return (
    <Box
      {...props}
      sx={[
        (theme) => ({
          borderWidth: 0,
          borderStyle: 'solid',
          borderColor: theme.palette.stationGray[300],
          color: theme.palette.stationGray[700],
          display: 'flex',
          '&.SWPrintDaySchedule-dashed-row': { borderStyle: 'dashed' },
          '&.SWPrintDaySchedule-circled-row': { borderWidth: '2px', borderRadius: '9999px' },
          '&.SWPrintDaySchedule-lined-row + .SWPrintDaySchedule-lined-row': { borderTopWidth: '2px' },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </Box>
  );
};
