import { captureException } from '@sentry/react';
import { useDeferredValue, useEffect, useRef, useState } from 'react';
import { client, isAxiosError } from '@stationwise/share-api';
import { ListFieldsStaffingList } from '@stationwise/share-types';

interface UseSelectedStaffingListAPIParams {
  date: string;
}

interface UseSelectedStaffingListProps {
  selectedDate: string;
  staffingListsResponse?: { data: ListFieldsStaffingList[] | null; isError: boolean };
}

export const useSelectedStaffingList = ({ selectedDate, staffingListsResponse }: UseSelectedStaffingListProps) => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const deferredSelectedDate = useDeferredValue(selectedDate);
  const paramsRef = useRef<UseSelectedStaffingListAPIParams | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [staffingLists, setStaffingLists] = useState<ListFieldsStaffingList[]>([]);
  const [selectedStaffingList, setSelectedStaffingList] = useState<ListFieldsStaffingList | undefined>(undefined);

  useEffect(() => {
    setStaffingLists(staffingListsResponse?.data || []);
    setSelectedStaffingList(staffingListsResponse?.data?.[0]);
    setIsLoading(!staffingListsResponse?.data?.[0]?.items);
    setIsError(staffingListsResponse?.isError || false);
  }, [staffingListsResponse?.data, staffingListsResponse?.isError]);

  useEffect(() => {
    const setLoadedStaffingListData = (data: ListFieldsStaffingList) => {
      setStaffingLists((sls) => sls.map((sl) => (sl.id === data.id ? data : sl)));
      setSelectedStaffingList(data);
    };

    const loadSelectedStaffingListItems = async () => {
      const prevParams = paramsRef.current;
      const currParams = { date: deferredSelectedDate };
      paramsRef.current = currParams;
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      setIsLoading(!selectedStaffingList);
      setIsError(false);
      if (!selectedStaffingList || (currParams.date === prevParams?.date && selectedStaffingList.items)) {
        return;
      }

      if (!currParams.date) {
        setLoadedStaffingListData({ ...selectedStaffingList, items: [] });
        return;
      }

      setIsLoading(true);
      try {
        const response = await client.get(`/staffing-list/staffing-lists/${selectedStaffingList.id}/`, {
          params: currParams,
          signal: abortControllerRef.current.signal,
        });
        setLoadedStaffingListData(response.data);
        setIsLoading(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && captureException(error);
        !isCanceled && setIsLoading(false);
        !isCanceled && setIsError(true);
      }
    };

    loadSelectedStaffingListItems();
  }, [deferredSelectedDate, selectedStaffingList]);

  return {
    isLoading,
    isError,
    staffingLists,
    selectedStaffingList,
    setSelectedStaffingList,
  };
};
