import { Dialog } from '@mui/material';
import { useRosterContext } from '@stationwise/component-module';
import { EditStrikeTeamForm } from './EditStrikeTeamForm';

export const EditStrikeTeamModal = () => {
  const { selectedStrikeTeamApparatusState } = useRosterContext();
  const { selectedApparatus, selectedAction } = selectedStrikeTeamApparatusState;

  return (
    <Dialog
      open={!!selectedApparatus?.strikeTeam.id && selectedAction === 'EDIT_EVENT'}
      onClose={selectedStrikeTeamApparatusState.resetState}
      sx={{ '& .MuiDialog-paper': { borderRadius: '16px', p: 3, width: '444px' } }}
    >
      <EditStrikeTeamForm />
    </Dialog>
  );
};
