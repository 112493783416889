import { Box } from '@mui/material';
import { Badge } from '../Badge';
import { BlankCard } from '../BlankCard';
import { ActingAsIndicator } from './ActingAsIndicator';
import { CardProps } from './Card';
import { DriverIndicator } from './DriverIndicator';

type CardPropNames =
  | 'badgeTitle'
  | 'color'
  | 'nameLabel'
  | 'borderColor'
  | 'type'
  | 'isDriver'
  | 'actingAs'
  | 'indicators'
  | 'cardSxProps';

export const CollapsedCard = ({
  badgeTitle,
  color = 'orange',
  nameLabel,
  borderColor = 'gold',
  type = 'default',
  isDriver = false,
  actingAs,
  indicators,
  cardSxProps,
}: Pick<CardProps, CardPropNames>) => {
  return (
    <BlankCard
      type={type}
      borderColor={borderColor}
      sxProps={[
        { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, whiteSpace: 'nowrap' },
        ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : []),
      ]}
    >
      <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 0.5 }}>
        {isDriver && <DriverIndicator />}
        {actingAs && <ActingAsIndicator actingAs={actingAs} color={color} />}
        {badgeTitle && <Badge label={badgeTitle} isRound type="sm" color={color} isBold noPadding />}
      </Box>
      <Box sx={(theme) => ({ flex: 1, minWidth: '1px', color: theme.palette.stationGray[900] })}>
        <Box
          sx={(theme) => ({
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            typography: 'bodyLMedium',
            [theme.breakpoints.up('sm')]: { typography: 'bodySMedium' },
          })}
        >
          {nameLabel}
        </Box>
      </Box>
      {indicators}
    </BlankCard>
  );
};
