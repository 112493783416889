import { Autocomplete, Box, createFilterOptions, IconButton, TextField } from '@mui/material';
import { ChevronDownIcon20, Trash04Icon20 } from '@stationwise/component-module';
import { Manager } from '@stationwise/share-types';

type ManagerAutocompleteProps = {
  title: string;
  manager: Manager;
  setManager: React.Dispatch<React.SetStateAction<Manager>>;
  options: Manager[];
};

export const ManagerAutocomplete = (props: ManagerAutocompleteProps) => {
  const filter = createFilterOptions<Manager>();
  return (
    <Box>
      <Box
        sx={(theme) => ({
          typography: 'bodySMedium',
          textAlign: 'left',
          paddingTop: theme.spacing(2),
          color: theme.palette.stationGray[700],
        })}
      >
        {props.title}
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: theme.spacing(1),
        })}
      >
        <Autocomplete
          sx={{
            width: '100%',
          }}
          value={props.manager ?? ''}
          onChange={(event, newValue) => {
            if (typeof newValue !== 'string' && newValue.id !== null) {
              props.setManager(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            return filtered;
          }}
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={props.options}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return option?.name ?? '';
          }}
          renderOption={(props, option) => (
            <li {...props} key={props.key}>
              {option.name}
            </li>
          )}
          disableClearable
          forcePopupIcon={true}
          popupIcon={<ChevronDownIcon20 />}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                '& .MuiOutlinedInput-root': { padding: '2.5px 3px 2.5px 12px' },
              }}
            />
          )}
        />
        <IconButton
          aria-label="Delete manager"
          onClick={() =>
            props.setManager({
              name: '',
              id: null,
            })
          }
          sx={{ ml: 1 }}
        >
          <Trash04Icon20 />
        </IconButton>
      </Box>
    </Box>
  );
};
