import { useCallback, useEffect, useState } from 'react';
import { RosterPosition, ListFieldsStaffingList } from '@stationwise/share-types';
import {
  IShiftSummaryHelper,
  checkIsShift,
  checkIsStrikeTeam,
  getMissingCertifications,
  getOvertimeCardErrorMessage,
  makeOvertimeEmployeeActiveId,
} from '@stationwise/shift-summary-helper';

export const useSelectedEmptyPosition = (
  shiftSummaryHelper: IShiftSummaryHelper,
  staffingLists: ListFieldsStaffingList[],
  isReadonly: boolean,
) => {
  const [selectedPosition, setSelectedPosition] = useState<RosterPosition | null>(null);
  const [candidateAssigneeIds, setCandidateAssigneeIds] = useState(new Set<string>());

  const resetState = useCallback(() => {
    setSelectedPosition(null);
    setCandidateAssigneeIds(new Set<string>());
  }, []);

  useEffect(() => {
    if (isReadonly) {
      return;
    }

    if (!selectedPosition || checkIsStrikeTeam(selectedPosition)) {
      return;
    }

    const handlePointerDown = (event: PointerEvent) => {
      // Magic Number = 45 is an approximation of the width of the scroll on x
      // + the OT drawer close button width
      const onVerticalScroll = window.innerWidth - event.clientX < 45;
      // Magic Number = 15 is an approximation of the height of the scroll on y
      const onHorizontalScroll = window.innerHeight - event.clientY < 15;
      if (!onVerticalScroll && !onHorizontalScroll) {
        resetState();
      }
    };

    window.addEventListener('pointerdown', handlePointerDown);
    return () => window.removeEventListener('pointerdown', handlePointerDown);
  }, [isReadonly, selectedPosition, resetState]);

  const setPosition = (emptyPosition: RosterPosition) => {
    setSelectedPosition(emptyPosition);
    if (checkIsStrikeTeam(emptyPosition)) {
      return;
    }

    const employeeActiveIds = new Set<string>();
    shiftSummaryHelper.allStationCards.forEach((station) => {
      if (!checkIsShift(station)) {
        return;
      }

      station.apparatuses.forEach((apparatus) => {
        apparatus.positions.forEach((position) => {
          if (position.employees.length > 1) {
            // Employees within a split shift position cannot be moved.
            return;
          }
          position.employees.forEach((employee) => {
            if (employee.activeId && !getMissingCertifications(emptyPosition.certifications, employee.certifications).length) {
              employeeActiveIds.add(employee.activeId);
            }
          });
        });
      });
    });
    shiftSummaryHelper.unassignedCards.forEach((baseCard) => {
      if (
        baseCard.activeId &&
        !getMissingCertifications(emptyPosition.certifications, baseCard.certifications).length &&
        !(emptyPosition.employees.length > 1)
      ) {
        employeeActiveIds.add(baseCard.activeId);
      }
    });
    const voluntaryOvertimeStaffingList = staffingLists.find((sl) => sl.name === 'Voluntary Overtime');
    voluntaryOvertimeStaffingList?.items?.forEach((operator) => {
      if (
        operator.employee.id &&
        !getMissingCertifications(emptyPosition.certifications, operator.employee.certifications).length &&
        !getOvertimeCardErrorMessage(shiftSummaryHelper, operator)
      ) {
        employeeActiveIds.add(makeOvertimeEmployeeActiveId(operator.employee.id));
      }
    });
    setCandidateAssigneeIds(employeeActiveIds);
  };

  return {
    position: selectedPosition,
    candidateAssigneeIds,
    setPosition,
    resetState,
  };
};
