import { Box } from '@mui/material';
import { useState } from 'react';
import {
  Badge,
  SuperAdminBadge,
  AccrualsCard,
  Pencil01Icon24,
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { TimeOffLimit } from '@stationwise/share-types';
import { EditBasicInfo } from './Edit/EditBasicInfo';

type AccrualsSectionProps = {
  accruals: TimeOffLimit[];
  role: string;
  firstName: string;
  lastName: string;
  id: string;
  isActive?: boolean;
};

export const AccrualsSection = ({ accruals, role, firstName, lastName, id, isActive = true }: AccrualsSectionProps) => {
  const [showModal, setShowModal] = useState(false);
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const isAccrualsEnabled = departmentContext.departmentInfo.settings.accrualLogicEnabled;
  const showEditModal = () => {
    setShowModal(true);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        borderRadius: '16px',
        border: `1px solid ${theme.palette.stationGray[200]}`,
        p: theme.spacing(2, 3, 3, 3),
        gap: theme.spacing(3),
      })}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 1,
          }}
        >
          {isActive ? (
            <Badge label="Active" isRound type="lg" color={'green'} variant="bodyMRegular" />
          ) : (
            <Badge label="Inactive" isRound type="lg" variant="bodyMRegular" />
          )}
          {role === 'SUPER_ADMIN' && <SuperAdminBadge />}
        </Box>

        {authUserState.employee.role === 'SUPER_ADMIN' && (
          <Box sx={{ cursor: 'pointer', display: 'flex' }}>
            <Pencil01Icon24 onClick={showEditModal} />
          </Box>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          borderRadius: '50%',
          backgroundColor: ['ADMIN', 'SUPER_ADMIN'].includes(role)
            ? theme.palette.stationGreen[500]
            : theme.palette.stationLightBlue[500],
          color: theme.palette.common.white,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 112,
          height: 112,
          typography: 'heading1',
          lineHeight: '64px',
          textTransform: 'uppercase',
        })}
      >
        {firstName.charAt(0)}
      </Box>
      <Box
        sx={(theme) => ({
          typography: 'heading4',
        })}
      >
        {firstName} {lastName}
      </Box>
      {accruals && isAccrualsEnabled && <AccrualsCard accruals={accruals} id={id} />}
      <EditBasicInfo
        showModal={showModal}
        setShowModal={setShowModal}
        employeeFirstName={firstName}
        employeeLastName={lastName}
        employeeRole={role}
      />
    </Box>
  );
};
