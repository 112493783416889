import { Box, Typography, useTheme } from '@mui/material';
import { Badge, getColor as getRankColorsBadge } from '@stationwise/component-module';
import { AllowedColors } from '@stationwise/share-types';

interface Props {
  stationLeader?: string;
  stationLeaderBadgeTitle?: string;
  stationLeaderBadgeColor?: string;
}

export const LeaderInfo = ({ stationLeader, stationLeaderBadgeTitle, stationLeaderBadgeColor }: Props) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      sx={() => ({
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderBottomLeftRadius: theme.spacing(1.5),
        borderBottomRightRadius: theme.spacing(1.5),
        boxShadow: '0px 4px 6px -1px rgba(10, 14, 22, 0.102)',
      })}
    >
      <Box
        sx={{
          width: '100%',
          ml: '8px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={() => ({
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            p: '8px 12px',
            gap: theme.spacing(1),
          })}
        >
          <Box sx={{ width: '38px', height: '28px' }}>
            <Badge
              label={stationLeaderBadgeTitle || ''}
              isRound
              type="sm"
              isCustom
              customColor={getRankColorsBadge(theme, (stationLeaderBadgeColor as AllowedColors) || '').color}
              customBackgroundColor={getRankColorsBadge(theme, (stationLeaderBadgeColor as AllowedColors) || '').background}
              variant="bodyMRegular"
            />
          </Box>
          <Typography variant="bodyMMedium">{stationLeader}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
