import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { getMe } from '@stationwise/share-api';
import { LottieLogoPageLoader } from '../../LottieLogo';
import { useAuthUserContext } from '../context/AuthUser';

interface Props {
  children: JSX.Element;
  redirectTo: To;
}

export const RequireAnonProtector = ({ children, redirectTo }: Props) => {
  const { state, dispatch } = useAuthUserContext();
  const isAuthenticated = !!state.employee?.id;
  const [isLoading, setIsLoading] = useState(!isAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectTo);
      return;
    }

    const loadMe = async () => {
      const me = await getMe();
      if (me) {
        dispatch({ type: 'SET_USERNAME.SUCCESS', payload: me });
        navigate(redirectTo);
      } else {
        setIsLoading(false);
        Cookies.remove('departmentdomain');
      }
    };

    loadMe();
  }, [isAuthenticated, dispatch, navigate, redirectTo]);

  return isLoading ? <LottieLogoPageLoader /> : children;
};
