import { Box } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Button, Modal, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { Battalion, Team } from '@stationwise/share-types';
import { WarningModal, TeamColoredBar } from '../../../../components/Common';
import { useShiftTemplateContext } from '../contexts/ShiftTemplateContext';
import { BattalionSelect } from './BattalionSelect';
import { PublishingEffectsDetail } from './PublishingEffectsDetail';
import { TeamSelect } from './TeamSelect';

interface Props {
  saveChanges?: () => void;
  forceRefetch: (teamId: string, battalionId: string) => void;
  lastSaved: string;
  executeAction: (action: 'publish' | 'discard') => void;
  isCurrentlyActive?: boolean;
}

export const TopBar = ({ saveChanges, forceRefetch, lastSaved, executeAction, isCurrentlyActive }: Props) => {
  const { shiftTemplateHelper, resetValues, userHasMadeChanges } = useShiftTemplateContext();
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const teams = departmentContext.departmentInfo.teams.sort((a, b) => a.name.localeCompare(b.name));
  const battalions = departmentContext.departmentInfo.battalions.sort((a, b) => a.name.localeCompare(b.name));
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>(shiftTemplateHelper.currentTeams[0]);
  const [selectedBattalion, setSelectedBattalion] = useState<Battalion | undefined>(shiftTemplateHelper.currentBattalion);
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);
  const [warning, setWarning] = useState<{ message: string; secondaryMessage?: string; extraInfo?: ReactNode } | null>(null);
  const [selectedAction, setSelectedAction] = useState<'publish' | 'discard'>('publish');

  const onChangeTeam = (selectedOption: string) => {
    const selectedTeam = teams.find((team) => team.id.toString() === selectedOption);
    setSelectedTeam(selectedTeam);
    forceRefetch(selectedOption, shiftTemplateHelper.currentBattalion.id.toString());
  };

  const onChangeBattalion = (selectedOption: string) => {
    const selectedBattalion = battalions.find((b) => b.id.toString() === selectedOption);
    setSelectedBattalion(selectedBattalion);
    forceRefetch(shiftTemplateHelper.currentTeams[0].id.toString(), selectedOption);
  };

  const onPublish = () => {
    setWarning({
      message: 'You are about to publish a new shift template',
      secondaryMessage: 'Once published it will become active immediately',
      extraInfo: <PublishingEffectsDetail />,
    });
    setSelectedAction('publish');
    setWarningModalOpen(true);
  };

  const onDiscard = () => {
    setWarning({
      message: 'You are about to discard your shift template draft',
      secondaryMessage: 'Are you sure?',
    });
    setSelectedAction('discard');
    setWarningModalOpen(true);
  };

  const executeSelectedAction = () => {
    setWarningModalOpen(false);
    executeAction(selectedAction);
  };

  return (
    <Box>
      <Box
        display="flex"
        width="100%"
        sx={(theme) => ({
          background: theme.palette.common.white,
          p: '8px 24px',
        })}
      >
        <Box>
          <TeamSelect teams={teams} selectedTeam={selectedTeam?.id.toString() || ''} onChange={onChangeTeam} />
        </Box>
        {battalions.length > 1 && (
          <Box sx={{ ml: 2 }}>
            <BattalionSelect
              battalions={battalions}
              selectedBattalion={selectedBattalion?.id.toString() || ''}
              onChange={onChangeBattalion}
            />
          </Box>
        )}
        {!isCurrentlyActive && lastSaved && (
          <>
            <Box display="flex" gap={1} alignItems="center" sx={{ ml: 3 }}>
              <Button buttonType="tertiary" onClick={onDiscard} data-cy="remove">
                Discard draft
              </Button>
              <Button disabled={userHasMadeChanges} onClick={onPublish} data-cy="publish">
                Publish
              </Button>
            </Box>
            <Box
              typography="bodyMRegular"
              display="flex"
              alignItems="center"
              sx={(theme) => ({ ml: 3, color: theme.palette.stationGray[300] })}
            >
              {lastSaved}
            </Box>
          </>
        )}

        <Box display="flex" gap={1} alignItems="center" sx={{ marginLeft: 'auto', marginRight: 0 }}>
          <Button buttonType="tertiary" disabled={!userHasMadeChanges} onClick={resetValues} data-cy="cancel">
            Cancel
          </Button>
          <Button disabled={!userHasMadeChanges} onClick={saveChanges} data-cy="save">
            Save as draft
          </Button>
        </Box>
      </Box>
      {warning && (
        <Modal open={warningModalOpen} setOpen={setWarningModalOpen}>
          <WarningModal setModalOpen={setWarningModalOpen} onConfirm={executeSelectedAction}>
            <Box sx={(theme) => ({ width: '450px', mb: theme.spacing(3) })}>
              <Box sx={{ typography: 'bodyXXLSemibold' }}>{warning.message}</Box>
              <Box sx={(theme) => ({ typography: 'bodyMRegular', mt: theme.spacing(2) })}>{warning.secondaryMessage}</Box>
              {warning.extraInfo && warning.extraInfo}
            </Box>
          </WarningModal>
        </Modal>
      )}
      {selectedTeam && <TeamColoredBar shiftTeams={[selectedTeam]} shiftColor={selectedTeam.color} />}
    </Box>
  );
};
