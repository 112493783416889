import { Box } from '@mui/material';
import React from 'react';
import { AvatarEmail, AvatarEmailProps } from '@stationwise/component-module';
import { Chip, ChipProps } from './Chip';

type UserMessageStatusProps = ChipProps & AvatarEmailProps;

export const UserMessageStatus = ({ name, email, userImg, type, color }: UserMessageStatusProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '4px',
      }}
    >
      <AvatarEmail name={name} email={email} userImg={userImg} color={color} />
      <Chip type={type} />
    </Box>
  );
};
