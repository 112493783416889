import { SxProps, Theme } from '@mui/material';
import { CardTooltip } from './CardTooltip';

interface Props {
  duration: number;
  durationLabel: string;
  sx?: SxProps<Theme>;
}

export const ShiftDurationIndicator = ({ duration, durationLabel, sx }: Props) => {
  return (
    <CardTooltip
      anchorSx={[
        (theme) => ({
          color: theme.palette.stationGray[400],
          typography: 'bodyMRegular',
          [theme.breakpoints.up('sm')]: { typography: 'bodyXSRegular' },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
      anchorContent={durationLabel}
      popoverContent={`${duration}h shift`}
    />
  );
};
