import { Box, BoxProps } from '@mui/material';

export const COLUMNS = [
  { name: 'NAME', width: 'auto', whiteSpace: 'normal' },
  { name: 'RANK', width: '132px', whiteSpace: 'nowrap' },
  { name: 'SHIFT TIMES', width: '108px', whiteSpace: 'nowrap' },
  { name: 'OFF ROSTER', width: 'auto', whiteSpace: 'normal' },
  { name: 'CERTIFICATIONS', width: 'auto', whiteSpace: 'normal' },
  { name: 'SHIFT TYPE', width: 'auto', whiteSpace: 'normal' },
  { name: 'NOTES', width: 'auto', whiteSpace: 'normal' },
];

interface Props extends BoxProps<'div'> {
  colIndex: number;
  scope?: 'head' | 'body';
}

export const Cell = ({ children, colIndex, scope = 'body', sx, ...props }: Props) => {
  const column = COLUMNS[colIndex];
  return (
    <Box
      {...props}
      sx={[
        (theme) => ({
          alignSelf: scope === 'head' ? 'center' : 'flex-start',
          flex: column.width === 'auto' ? '1' : undefined,
          width: column.width,
          p: theme.spacing(0.25, 1),
          color: scope === 'head' ? theme.palette.stationGray[500] : theme.palette.stationGray[700],
          typography: scope === 'head' ? 'bodySMedium' : 'bodySRegular',
          lineHeight: 1,
          textTransform: scope === 'head' ? 'uppercase' : undefined,
          whiteSpace: column.whiteSpace,
          '&:first-of-type': { pl: 2 },
          '&:last-of-type': { pr: 2 },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </Box>
  );
};
