import { useDocumentTitle, CAPABILITIES } from '@stationwise/component-module';
import { AppPageLayout } from '../../AppPageLayout';
import { TeamSettings } from './components/TeamSettings';

export const TeamPage = () => {
  useDocumentTitle('Employees');

  return (
    <AppPageLayout requiredCapability={CAPABILITIES.VIEW_TEAM_SETTINGS}>
      <TeamSettings />
    </AppPageLayout>
  );
};
