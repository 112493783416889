import { RosterDataSource, RosterShiftDuration, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import { checkIsPlannedEmployee, getBoardEmployeeNoteOverride, getOverrideEmployeePositionFields } from './board';
import { setEmployeeActiveId } from './id';
import { getMandatoryPayCodes } from './payCode';
import { removeEmployeeAvailability } from './removeEmployeeAvailability';
import { resolveOneEmployeeOverlap } from './removeEmployeeAvailability/overlap';
import { IShiftSummaryHelper } from './types';
import { createUnassignedEmployee } from './unassigned';

type TraderPropNames = 'id' | 'name' | 'rank' | 'certifications' | 'team' | 'defaults';

export const forceShiftTrade = (
  shiftSummaryHelper: IShiftSummaryHelper,
  senderId: string,
  receiver: Pick<RosterEmployee, TraderPropNames>,
  startTime: Date,
  endTime: Date,
  position?: RosterPosition,
) => {
  const receiverNoteOverride = getBoardEmployeeNoteOverride(shiftSummaryHelper, receiver.id);

  return removeEmployeeAvailability({
    shiftSummaryHelper,
    employeeId: senderId,
    startTime,
    endTime,
    resolveOverlap: (employees: RosterEmployee[], sender: RosterEmployee) => {
      const mandatoryPayCodes = position
        ? getMandatoryPayCodes(shiftSummaryHelper, position, {
            ...receiver,
            ...sender,
            rank: receiver.rank,
            payCodes: sender.payCodes,
          })
        : sender.payCodes;
      const newEmployee = setEmployeeActiveId({
        id: receiver.id,
        dataSource: sender.dataSource,
        name: receiver.name,
        rank: receiver.rank,
        certifications: receiver.certifications,
        team: receiver.team,
        defaults: receiver.defaults,
        startDateTime: sender.startDateTime,
        endDateTime: sender.endDateTime,
        payCodes: mandatoryPayCodes,
        detailCodes: sender.detailCodes,
        noteOverride: receiverNoteOverride,
        trade: {
          id: null,
          requester: { id: sender.id, name: sender.name, rank: sender.rank },
        },
      });
      employees.push(newEmployee);
    },
  });
};

export const cancelShiftTrade = (
  shiftSummaryHelper: IShiftSummaryHelper,
  receiverId: string,
  sender: Pick<RosterEmployee, TraderPropNames>,
  startTime: Date,
  endTime: Date,
) => {
  const isPlannedSender = checkIsPlannedEmployee(shiftSummaryHelper, sender);
  const senderNoteOverride = getBoardEmployeeNoteOverride(shiftSummaryHelper, sender.id);
  const resolvedDurations: Omit<RosterShiftDuration, 'hours'>[] = [];

  let newShiftSummaryHelper = removeEmployeeAvailability({
    shiftSummaryHelper,
    employeeId: receiverId,
    startTime,
    endTime,
    resolveOverlap: (employees: RosterEmployee[], receiver: RosterEmployee, position?: RosterPosition) => {
      if (!isPlannedSender) {
        resolveOneEmployeeOverlap(employees, receiver, position);
        return;
      }

      const newEmployee = setEmployeeActiveId({
        id: sender.id,
        dataSource: receiver.dataSource,
        name: sender.name,
        rank: sender.rank,
        certifications: sender.certifications,
        team: sender.team,
        defaults: sender.defaults,
        startDateTime: receiver.startDateTime,
        endDateTime: receiver.endDateTime,
        payCodes: receiver.payCodes,
        detailCodes: receiver.detailCodes,
        noteOverride: senderNoteOverride,
        trade: null,
      });
      employees.push(newEmployee);
      resolvedDurations.push({ startTime: receiver.startDateTime, endTime: receiver.endDateTime });
    },
  });

  if (!isPlannedSender) {
    newShiftSummaryHelper.unplannedAssignees = [...newShiftSummaryHelper.unplannedAssignees, sender];
    return newShiftSummaryHelper;
  }

  resolvedDurations.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
  const firstResolvedDuration = resolvedDurations[0];
  const lastResolvedDuration = resolvedDurations[resolvedDurations.length - 1];

  const gaps: Omit<RosterShiftDuration, 'hours'>[] = [];
  if (resolvedDurations.length === 0) {
    gaps.push({ startTime, endTime });
  }
  if (firstResolvedDuration && startTime < firstResolvedDuration.startTime) {
    gaps.push({ startTime, endTime: firstResolvedDuration.startTime });
  }
  resolvedDurations.forEach((currDuration, i) => {
    const prevDuration = resolvedDurations[i - 1];
    if (prevDuration && prevDuration.endTime < currDuration.startTime) {
      gaps.push({ startTime: prevDuration.endTime, endTime: currDuration.startTime });
    }
  });
  if (lastResolvedDuration && lastResolvedDuration.endTime < endTime) {
    gaps.push({ startTime: lastResolvedDuration.endTime, endTime });
  }

  gaps.forEach((gap) => {
    const newEmployee = setEmployeeActiveId({
      id: sender.id,
      dataSource: RosterDataSource.FLOATER,
      name: sender.name,
      rank: sender.rank,
      certifications: sender.certifications,
      team: sender.team,
      defaults: sender.defaults,
      startDateTime: gap.startTime,
      endDateTime: gap.endTime,
      payCodes: [sender.defaults.regularAssignmentPayCode],
      detailCodes: [],
      ...getOverrideEmployeePositionFields(),
    });
    newShiftSummaryHelper = createUnassignedEmployee(newShiftSummaryHelper, newEmployee);
  });

  return newShiftSummaryHelper;
};
