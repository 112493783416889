import { Box } from '@mui/material';
import { BigArrowLeftIcon16 } from '@stationwise/component-module';

export const SwapArrow = () => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.spacing(3),
        border: `1px solid ${theme.palette.stationGray[100]}`,
        width: theme.spacing(6),
        height: theme.spacing(3),
        m: 'auto',
        ml: theme.spacing(1),
        mr: theme.spacing(1),
        textAlign: 'center',
        pt: '1px',
      })}
    >
      <BigArrowLeftIcon16 />
    </Box>
  );
};
