import { Box, Typography, useTheme } from '@mui/material';
import { Badge } from '@stationwise/component-module';
import { Rank, BasicEmployee } from '@stationwise/share-types';

export const ShiftPrincipals = ({
  shiftLeader,
  shiftLeaderRank,
  captain,
  captainRank,
  loggedInEmployeeId,
}: {
  shiftLeader: BasicEmployee;
  shiftLeaderRank: Rank;
  captain: BasicEmployee | string;
  captainRank: Rank;
  loggedInEmployeeId?: string;
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          borderTop: `solid 1px ${theme.palette.stationGray[100]}`,
          mb: theme.spacing(1),
        }}
      >
        <Box display="flex" alignItems="center" sx={{ mt: theme.spacing(2), justifyContent: { sm: 'space-between' } }}>
          <Box display="flex" sx={{ width: '40%' }}>
            <Badge
              label={shiftLeader.name ? shiftLeaderRank.name : 'Shift Leader'}
              isRound
              type="lg"
              color={shiftLeaderRank ? shiftLeaderRank.color : 'gray'}
              variant="bodyMMedium"
            />
          </Box>
          <Box display="flex">
            <Typography variant="bodyMMedium">{shiftLeader.name ? shiftLeader.name : 'No leader assigned'}</Typography>
            {loggedInEmployeeId === shiftLeader.id && (
              <Box
                sx={{
                  ml: theme.spacing(1),
                  color: theme.palette.stationGray[400],
                }}
              >
                <Typography variant="bodyMMedium">(You)</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {captain && typeof captain !== 'string' && captain.id !== loggedInEmployeeId && (
        <Box
          sx={{
            justifyContent: { sm: 'space-between' },
            mb: theme.spacing(1),
          }}
          display="flex"
          alignItems="center"
        >
          <Box display="flex" sx={{ width: '40%' }}>
            <Badge label="Fire Captain" isRound type="lg" color={captainRank.color} variant="bodyMMedium" />
          </Box>
          <Box display="flex">
            <Typography variant="bodyMMedium">{captain.name ? captain.name : 'To Be Staffed'}</Typography>
            {loggedInEmployeeId === captain.id && (
              <Box
                sx={{
                  ml: theme.spacing(1),
                  color: theme.palette.stationGray[400],
                }}
              >
                <Typography variant="bodyMMedium">(You)</Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
