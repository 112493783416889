import { Box, Fade, Popover as MUIPopover, PopoverProps as MUIPopoverProps } from '@mui/material';
import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import {
  PopoverPositionProps,
  POPOVER_POSITION,
  checkIsTop,
  checkIsBottom,
  checkIsLeft,
  checkIsRight,
  getArrowPosition,
  getBestPopoverPosition,
} from './position';

interface PopoverContentRef {
  el: HTMLElement | null;
  observer: MutationObserver | null;
}

interface PopoverContentProps extends Pick<MUIPopoverProps, 'children'> {
  onRender: () => void;
}

const PopoverContent = ({ children, onRender }: PopoverContentProps) => {
  useEffect(() => onRender(), [onRender]);
  return children;
};

interface Props extends MUIPopoverProps {
  withArrow?: boolean;
}

export const Popover = ({ children, sx, withArrow = true, ...props }: Props) => {
  const givenPosition = useMemo(() => {
    if (props.anchorOrigin && props.transformOrigin) {
      return {
        anchorOrigin: props.anchorOrigin,
        transformOrigin: props.transformOrigin,
      };
    }
    return POPOVER_POSITION.BOTTOM;
  }, [props.anchorOrigin, props.transformOrigin]);

  const [position, setPosition] = useState<PopoverPositionProps>(givenPosition);

  const anchorEl = typeof props.anchorEl === 'function' ? props.anchorEl() : props.anchorEl;
  const contentId = useId();
  const contentRef = useRef<PopoverContentRef>({ el: null, observer: null });
  const arrowRef = useRef<HTMLElement | null>(null);

  useEffect(() => setPosition(givenPosition), [givenPosition]);

  useEffect(() => {
    if (!props.open) {
      contentRef.current.observer?.disconnect();
      contentRef.current = { el: null, observer: null };
    }
  }, [props.open]);

  const marginThreshold = props.marginThreshold ?? 16; // Space between content and window.
  const anchorMarginThreshold = withArrow ? 16 : 0; // Space between anchor and content.
  const contentMargin = anchorMarginThreshold + marginThreshold;

  const onRenderContent = useCallback(() => {
    const contentEl = document.getElementById(contentId);
    if (!anchorEl || !contentEl) {
      return;
    }

    // The MUI Popover adjusts the content position by directly modifying the `style` attribute:
    // https://github.com/mui/material-ui/blob/v5.10.16/packages/mui-material/src/Popover/Popover.js#L283
    // Hence the only way to know when it re-positions is to observe the `style` attribute.
    const contentObserver = new MutationObserver(() => {
      if (anchorEl && contentEl) {
        const anchorRect = anchorEl.getBoundingClientRect();
        const contentRect = contentEl.getBoundingClientRect();
        const contentSize = {
          width: contentRect.right - contentRect.left + contentMargin,
          height: contentRect.bottom - contentRect.top + contentMargin,
        };
        setPosition(getBestPopoverPosition({ anchorRect, current: position, preferred: givenPosition, size: contentSize }));
        if (arrowRef.current) {
          Object.assign(arrowRef.current.style, getArrowPosition({ anchorRect, contentRect, position }));
        }
      }
    });
    contentObserver.observe(contentEl, { attributes: true, attributeFilter: ['style'] });

    contentRef.current.observer?.disconnect();
    contentRef.current = { el: contentEl, observer: contentObserver };
  }, [anchorEl, contentId, contentMargin, givenPosition, position]);

  // With the default Grow transition, the arrow renders with a delay,
  // since we don't know the size of the content until the transition is done.
  const TransitionComponent = Fade;

  return (
    <MUIPopover
      {...props}
      {...position}
      marginThreshold={marginThreshold}
      slotProps={{ paper: { id: contentId } }}
      TransitionComponent={TransitionComponent}
      sx={[
        (theme) => {
          const spacing = { mt: '0', ml: '0' };
          if (checkIsTop(position) || checkIsBottom(position)) {
            spacing.mt = `${(checkIsTop(position) ? -1 : 1) * anchorMarginThreshold}px`;
          } else if (checkIsLeft(position) || checkIsRight(position)) {
            spacing.ml = `${(checkIsLeft(position) ? -1 : 1) * anchorMarginThreshold}px`;
          }
          return {
            '& .MuiPaper-root': {
              backgroundColor: 'transparent',
              borderRadius: 0,
              boxShadow: 'none',
              overflowY: 'visible',
              overflowX: 'visible',
              ...spacing,
            },
          };
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      <PopoverContent onRender={onRenderContent}>
        <Box
          className="SWPopover-content"
          sx={(theme) => ({
            backgroundColor: theme.palette.stationGray[900],
            borderRadius: '16px',
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: 'calc(100vh - 32px)',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { width: 0 },
          })}
        >
          {children}
        </Box>
        {withArrow && (
          <Box
            className="SWPopover-arrow"
            ref={arrowRef}
            sx={(theme) => ({
              backgroundColor: theme.palette.stationGray[900],
              borderRadius: '4px',
              position: 'absolute',
              width: '24px',
              height: '24px',
              transform: 'translate(-50%, -50%) rotate(45deg)',
              zIndex: -1,
            })}
          />
        )}
      </PopoverContent>
    </MUIPopover>
  );
};
