import { Dialog } from '@mui/material';
import { useRosterContext } from '../RosterContext';
import { UndeployStrikeTeamForm } from './UndeployStrikeTeamForm';

export const UndeployStrikeTeamModal = () => {
  const { selectedStrikeTeamApparatusState } = useRosterContext();
  const { selectedApparatus, selectedAction } = selectedStrikeTeamApparatusState;

  return (
    <Dialog
      open={!!selectedApparatus?.strikeTeam.id && selectedAction === 'UNDEPLOY_EVENT'}
      onClose={selectedStrikeTeamApparatusState.resetState}
      sx={{ '& .MuiDialog-paper': { borderRadius: '16px', p: 3, width: '444px' } }}
    >
      <UndeployStrikeTeamForm />
    </Dialog>
  );
};
