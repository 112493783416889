import { alpha, Button as MuiButton, ButtonProps } from '@mui/material';
import { ElementType } from 'react';

interface OurProps {
  buttonType?: 'primary' | 'secondary' | 'link';
}

type Props<RootComponentType extends ElementType = 'button'> = ButtonProps<RootComponentType> & OurProps;

export const PreviewButton = <RootComponentType extends ElementType>({
  buttonType = 'primary',
  children,
  sx,
  ...props
}: Props<RootComponentType>) => (
  <MuiButton
    disableFocusRipple
    {...props}
    sx={[
      {
        border: '1px solid transparent',
        borderRadius: '6px',
        p: '9px 13px',
        typography: 'buttonM',
        textTransform: 'none',
        whiteSpace: 'nowrap',
      },
      (theme) => {
        if (buttonType === 'primary') {
          return {
            backgroundColor: theme.palette.stationGray[50],
            color: theme.palette.stationGray[900],
            '&:hover': { backgroundColor: theme.palette.stationGray[300] },
          };
        } else if (buttonType === 'secondary') {
          return {
            backgroundColor: 'transparent',
            borderColor: theme.palette.stationGray[500],
            color: theme.palette.common.white,
            '&:hover': { backgroundColor: theme.palette.stationGray[700] },
          };
        } else if (buttonType === 'link') {
          return {
            backgroundColor: 'transparent',
            color: theme.palette.stationBlue[400],
            p: 1,
            '&:hover': { backgroundColor: alpha(theme.palette.stationBlue[400], 0.2) },
            '&.Mui-disabled': { color: theme.palette.stationGray[500] },
          };
        }

        return {};
      },
      ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
    ]}
  >
    {children}
  </MuiButton>
);
