import { Box } from '@mui/material';
import { LetterAvatar, User03Icon16 } from '@stationwise/component-module';
import { WorkGroupMember } from '@stationwise/share-types';

interface Prop {
  members: WorkGroupMember[];
  membersCount: number;
}

export const MembersPreview = ({ members, membersCount }: Prop) => {
  if (!members || membersCount === 0) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={(theme) => ({
            height: '24px',
            width: '24px',
            border: `1px solid ${theme.palette.stationGray[200]}`,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <User03Icon16 />
        </Box>
        <Box
          sx={(theme) => ({
            typography: 'bodySRegular',
            marginLeft: theme.spacing(1),
            color: theme.palette.stationGray[500],
          })}
        >
          No members yet
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" gap={2} alignItems="center" ml={1}>
      <Box display="flex" alignContent={'center'}>
        {members.slice(0, 4).map((member, index) =>
          member.avatar ? (
            <Box
              key={index}
              sx={(theme) => ({
                marginLeft: theme.spacing(-1),
                borderRadius: '50%',
                height: '20px',
                width: '20px',
                border: `1px solid ${theme.palette.common.white}`,
                zIndex: `${index}`,
                position: 'relative',
              })}
              component="img"
              alt="image of person"
              src={member.avatar}
            />
          ) : (
            // letter box
            <Box
              key={index}
              sx={(theme) => ({
                marginLeft: theme.spacing(-1),
                borderRadius: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `1px solid ${theme.palette.common.white}`,
                height: '24px',
                width: '24px',
                '& svg': {
                  height: '12px',
                  width: '12px',
                },
                zIndex: `${index}`,
                position: 'relative',
              })}
            >
              <LetterAvatar size="xsmall" key={index} firstName={member.name} />
            </Box>
          ),
        )}
      </Box>
      <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodySRegular' })}>
        {`${membersCount} member${membersCount === 1 ? '' : 's'}`}
      </Box>
    </Box>
  );
};
