import { Card, Box } from '@mui/material';
import React from 'react';
import { WorkGroupPreview } from '@stationwise/share-types';
import { MembersPreview } from './MembersPreview';

interface WorkGroupCardProps {
  workgroup: WorkGroupPreview;
  clicked: number;
  setClicked: React.Dispatch<React.SetStateAction<number>>;
}

export const WorkGroupCard = ({ workgroup, clicked, setClicked }: WorkGroupCardProps) => {
  return (
    <Card
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '354px',
        height: '150px',
        borderRadius: '8px',
        border:
          workgroup.id === clicked
            ? `1px solid ${theme.palette.stationGray[900]}`
            : `1px solid ${theme.palette.stationGray[200]}`,
        cursor: 'pointer',
        '&:active': {
          border: `1px solid ${theme.palette.stationGray[900]}`,
          overflow: 'hidden',
        },
      })}
      onClick={() => setClicked(workgroup.id)}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: theme.spacing(2),
          height: '100%',
        })}
      >
        <Box
          sx={(theme) => ({
            typography: 'bodyLMedium',
            color: theme.palette.stationGray[900],
            alignSelf: 'stretch',
          })}
        >
          {workgroup.name}
        </Box>
        <Box
          sx={(theme) => ({
            typography: 'bodySRegular',
            color: theme.palette.stationGray[500],
            height: '60px',
            display: 'flex',
            alignItems: 'center',
          })}
        >
          {workgroup.description || 'No description'}
        </Box>
        <Box display="flex" alignItems="center" marginTop="10px">
          <MembersPreview members={workgroup.firstFourMembers} membersCount={workgroup.membersCount} />
        </Box>
      </Box>
    </Card>
  );
};
