import { Box } from '@mui/material';
import { renameMandatoryOvertimeTitle, useLoadedDepartmentInfoContext } from '../../../Department';
import { ToggleWithLabel } from '../../../Toggle';
import { useRosterContext } from '../RosterContext';

export const HireBackListHeader = () => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { staffingListsState } = useRosterContext();
  const { staffingLists, selectedStaffingList, setSelectedStaffingList } = staffingListsState;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ml: 3 }}>
      <Box sx={{ typography: 'bodyMSemibold' }}>Overtime List</Box>
      <ToggleWithLabel
        label={renameMandatoryOvertimeTitle(departmentInfoState, 'MANDATORY OT')}
        isActive={selectedStaffingList?.name === 'Mandatory Overtime'}
        onClick={() => setSelectedStaffingList(staffingLists.find((sl) => sl !== selectedStaffingList))}
      />
    </Box>
  );
};
