import { Box, ButtonProps } from '@mui/material';
import { ElementType } from 'react';
import { Pencil01Icon16 } from '../../../../../assets';
import { PreviewButton } from '../../../../EmployeePreview';

export const AddNoteButton = <RootComponentType extends ElementType>({ sx, ...props }: ButtonProps<RootComponentType>) => {
  return (
    <PreviewButton buttonType="link" {...props} sx={[{ gap: 0.5 }, ...(Array.isArray(sx) ? sx : sx ? [sx] : [])]}>
      <Box component={Pencil01Icon16} sx={(theme) => ({ '& path': { stroke: 'currentColor' } })} />
      Add note
    </PreviewButton>
  );
};
