import { Box, Typography } from '@mui/material';
import { MenuAltIcon } from '@stationwise/component-module';
import { OvertimeInfo } from '../../../MandatoryOvertimeList/overtimeListHelper';
import { OvertimeList } from './OvertimeList';

export const FetchedDataOvertimeCard = ({ displayOvertimeInfo }: { displayOvertimeInfo: OvertimeInfo | undefined }) => {
  return (
    <Box>
      {displayOvertimeInfo && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={(theme) => ({
              borderTop: `solid 1px ${theme.palette.stationGray[100]}`,
              pt: '16px',
            })}
          >
            <Typography
              variant="bodyLMedium"
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
              })}
            >
              Voluntary Overtime
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={(theme) => ({
              borderBottom: `solid 1px ${theme.palette.stationGray[100]}`,
              pb: '16px',
            })}
          >
            <Box display="flex">
              <Box sx={{ marginTop: '2px' }}>
                <MenuAltIcon />
              </Box>
              <Typography
                variant="bodyLMedium"
                sx={(theme) => ({
                  color: theme.palette.stationGray[900],
                  ml: '16px',
                  fontSize: '16px',
                })}
              >
                Overall position
              </Typography>
            </Box>
            <Box
              display="flex"
              sx={{
                textAlign: 'right',
              }}
            >
              <Typography
                variant="bodyLSemibold"
                sx={(theme) => ({
                  color: theme.palette.stationGray[900],
                  fontSize: '16px',
                })}
              >
                {displayOvertimeInfo && 'Not on the list'}
              </Typography>
            </Box>
          </Box>
          {displayOvertimeInfo?.hireBackList?.length > 0 && <OvertimeList overtimeInfo={displayOvertimeInfo} />}
        </>
      )}
    </Box>
  );
};
