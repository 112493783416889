import { Box, Button, Modal, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { theme, XIcon24 } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { TradeHistory } from './DetailsModal';

interface DeleteModalProps {
  open: boolean;
  onClose: () => void;
  tradeHistoryItem: TradeHistory | null;
  onRefetch: () => void;
}

export const DeleteModal = ({ open, onClose, tradeHistoryItem, onRefetch }: DeleteModalProps) => {
  const handleDeleteClick = async () => {
    try {
      await client.delete(`/request/shift-trade-history/${tradeHistoryItem?.id}/`);
      onClose();
      onRefetch();
    } catch (error) {
      captureException(error);
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '90vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            width: '350px',
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
          })}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '16px',
            }}
          >
            <Typography variant="bodyXLSemibold"> Delete Entry</Typography>
            <Box
              onClick={onClose}
              sx={(theme) => ({
                color: theme.palette.stationGray[400],
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.stationGray[600],
                },
              })}
            >
              <XIcon24 />
            </Box>
          </Box>

          <Typography color={theme.palette.stationGray[600]} variant="bodyMRegular">
            You are about to delete an entry, are you <br /> sure you want to continue?
          </Typography>

          <Box sx={(theme) => ({ display: 'flex', justifyContent: 'flex-end', pt: theme.spacing(2) })}>
            <Button size="large" color="error" variant="text" onClick={onClose}>
              Cancel
            </Button>
            <Button
              size="medium"
              color="error"
              variant="contained"
              onClick={handleDeleteClick}
              sx={(theme) => ({ px: '22px', ml: theme.spacing(1), textTransform: 'none' })}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
