import { Box, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { Button, exportData, theme } from '@stationwise/component-module';
import { EmployeePayroll } from '@stationwise/share-types';

interface ExportModalProps {
  exportModelOpen: boolean;
  setExportModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  employeePayroll: EmployeePayroll;
}

export const EmployeePayrollExportModal = ({ exportModelOpen, setExportModelOpen, employeePayroll }: ExportModalProps) => {
  const [selectedExportType, setSelectedExportType] = useState('csv');
  const handleExport = async () => {
    const params = {
      payPeriodId: employeePayroll?.payPeriod.id,
      employeeId: employeePayroll?.employee.id,
      exportType: selectedExportType,
    };
    await exportData('payroll/export/', params, 'payroll');
    setExportModelOpen(false);
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={exportModelOpen}
      onClose={() => setExportModelOpen(false)}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
        })}
      >
        <Box
          sx={(theme) => ({
            padding: '24px 24px 0px 24px',
            borderRadius: '16px 16px 0px 0px',
            width: theme.spacing(53.5),
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            maxHeight: theme.spacing(100),
            gap: theme.spacing(1),
            typography: 'bodyXLSemibold',
          })}
        >
          <Typography sx={{ typography: 'bodyXLSemibold' }}>Export time card</Typography>
        </Box>
        <Box
          sx={(theme) => ({
            marginTop: theme.spacing(1),
            marginX: theme.spacing(3),
            gap: theme.spacing(1),
          })}
        >
          <Typography sx={{ typography: 'bodySMedium' }}>Choose export type</Typography>
          <Box
            sx={(theme) => ({
              display: 'fixed',
              flexDirection: 'column',
              marginTop: theme.spacing(0.5),
              marginBottom: theme.spacing(2),
              gap: theme.spacing(1.5),
              width: theme.spacing(21),
              height: theme.spacing(5.5),
            })}
          >
            <Box
              sx={(theme) => ({
                borderRadius: theme.spacing(1),
                border: '1px solid',
                borderColor: theme.palette.stationGray[200],
                background: selectedExportType === 'csv' ? theme.palette.stationGray[900] : theme.palette.common.white,
                justifyContent: 'center',
                alignContent: 'center',
                cursor: 'pointer',
                alignItems: 'center',
                color: selectedExportType === 'csv' ? theme.palette.common.white : theme.palette.stationGray[900],
                typography: 'bodyMRegular',
                paddingX: theme.spacing(1.5),
              })}
              onClick={() => setSelectedExportType('csv')}
            >
              CSV
            </Box>
            <Box
              sx={(theme) => ({
                gap: theme.spacing(1),
                borderRadius: theme.spacing(1),
                border: '1px solid',
                borderColor: theme.palette.stationGray[200],
                background: selectedExportType === 'iif' ? theme.palette.stationGray[900] : theme.palette.common.white,
                justifyContent: 'center',
                alignContent: 'center',
                paddingX: theme.spacing(1.5),
                cursor: 'pointer',
                color: selectedExportType === 'iif' ? theme.palette.common.white : theme.palette.stationGray[900],
                typography: 'bodyMRegular',
              })}
              onClick={() => setSelectedExportType('iif')}
            >
              IIF
            </Box>
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            mt: theme.spacing(2),
            gap: theme.spacing(1.5),
            width: '100%',
            padding: theme.spacing(2),
          })}
        >
          <Button onClick={() => setExportModelOpen(false)} buttonType="tertiary" sx={() => ({ width: '100%' })}>
            {'Cancel'}
          </Button>
          <Button
            buttonType="dark"
            onClick={handleExport}
            sx={() => ({ width: '100%', ':hover': { backgroundColor: theme.palette.stationGray[700] } })}
          >
            {'Export'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
