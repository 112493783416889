import Cookies from 'js-cookie';
import { useLoadedAuthUserContext } from '../../Auth';

export const useSwitchDepartmentMenuItems = () => {
  const { state: authUserState } = useLoadedAuthUserContext();
  if (authUserState.employee.departments.length < 2) {
    return [];
  }

  return authUserState.employee.departments
    .filter((department) => department.domain !== authUserState.employee.departmentDomain)
    .map((department) => ({
      key: department.domain,
      name: department.name,
      onClick: () => {
        Cookies.set('departmentdomain', department.domain, { expires: 30 });
        window.location.reload();
      },
    }));
};
