import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface Input<T> {
  key: string;
  parse: (value: unknown) => T;
  stringify?: (value: T) => string;
}

const getLocalStorageValue = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) ?? 'null');
  } catch {
    return null;
  }
};

export const useLocalStorageState = <T>({ key, parse, stringify }: Input<T>): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    return parse(getLocalStorageValue(key));
  });

  useEffect(() => {
    const value = stringify ? stringify(state) : JSON.stringify(state);
    localStorage.setItem(key, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, state]);

  return [state, setState];
};
