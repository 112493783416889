import React from 'react';
import { DepartmentInfoLoader, RequireAuthProtector, RequireAdminProtector } from '@stationwise/component-module';
import { NoMatch } from '../app/pages/NoMatch';
import { ROUTES } from '../core/Routes';

interface Props {
  children: React.ReactElement;
  onlyAdmin?: boolean;
}

export const AuthPage = ({ children, onlyAdmin = false }: Props) => {
  const renderedChildren = onlyAdmin ? (
    <RequireAdminProtector restrictedComponent={<NoMatch />}>
      <DepartmentInfoLoader redirectTo={ROUTES.LOGIN.fullRoute}>{children}</DepartmentInfoLoader>
    </RequireAdminProtector>
  ) : (
    <DepartmentInfoLoader redirectTo={ROUTES.LOGIN.fullRoute}>{children}</DepartmentInfoLoader>
  );

  return (
    <div>
      <RequireAuthProtector redirectTo={ROUTES.LOGIN.fullRoute}>{renderedChildren}</RequireAuthProtector>
    </div>
  );
};
