import { IShiftSummaryHelper, RemoveEmployeeAvailabilityProps } from '../types';
import { removeBoardEmployeeAvailability } from './board';
import { resolveOneEmployeeOverlap } from './overlap';
import { removeUnassignedEmployeeAvailability } from './unassigned';

/**
 * Remove an employee's availability from `startTime` to `endTime`.
 * - Remove their floater and excess capacity assignments from `startTime` to `endTime`.
 * - Change their regular position assignments to vacancies from `startTime` to `endTime`.
 * - Keep their assignments the same before `startTime` or after `endTime`.
 */
export const removeEmployeeAvailability = <T extends IShiftSummaryHelper>({
  shiftSummaryHelper,
  employeeId,
  startTime,
  endTime,
  checkIsPositionExcluded = () => false,
  resolveOverlap = resolveOneEmployeeOverlap,
}: RemoveEmployeeAvailabilityProps<T>): T => {
  let newShiftSummaryHelper = shiftSummaryHelper;
  newShiftSummaryHelper = removeUnassignedEmployeeAvailability({
    shiftSummaryHelper: newShiftSummaryHelper,
    employeeId,
    startTime,
    endTime,
    resolveOverlap,
  });
  newShiftSummaryHelper = removeBoardEmployeeAvailability({
    shiftSummaryHelper: newShiftSummaryHelper,
    employeeId,
    startTime,
    endTime,
    checkIsPositionExcluded,
    resolveOverlap,
  });
  return newShiftSummaryHelper;
};
