import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';
import { AllowedColors } from '@stationwise/share-types';

function getBorderColor(theme: Theme, color: AllowedColors) {
  switch (color) {
    case 'black':
      return theme.palette.stationGray[500];
    case 'red':
      return theme.palette.stationRed[500];
    case 'gold':
      return theme.palette.stationYellow[500];
    case 'blue':
      return theme.palette.stationBlue[500];
    // we have no brown color on palette
    case 'brown':
      return theme.palette.stationOrange[900];
    case 'gray':
      return theme.palette.stationOrange[500];
    case 'green':
      return theme.palette.stationGreen[500];
    case 'indigo':
      return theme.palette.stationIndigo[500];
    case 'orange':
      return theme.palette.stationOrange[500];
    case 'pink':
      return theme.palette.stationPink[500];
    case 'purple':
      return theme.palette.stationPurple[500];
    case 'rose':
      return theme.palette.stationRose[500];
    case 'teal':
      return theme.palette.stationTeal[500];
    case 'violet':
      return theme.palette.stationViolet[500];
    case 'yellow':
      return theme.palette.stationYellow[500];
    case 'white':
      return theme.palette.stationGray[200];
    default:
      return theme.palette.stationGray[500];
  }
}

export interface BlankCardProps {
  children: React.ReactNode;
  type?: 'default' | 'disable' | 'border' | 'disabledWithBorder' | 'passive';
  borderColor?: AllowedColors;
  isSelected?: boolean;
  sxProps?: SxProps<Theme>;
  selectedColor?: string;
  selectedBorderWidth?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const BlankCard = ({
  children,
  type = 'default',
  borderColor = 'white',
  isSelected,
  sxProps,
  selectedColor,
  selectedBorderWidth,
  onClick,
}: BlankCardProps) => {
  return (
    <Box
      display="flex"
      p="8px 12px"
      flexDirection="column"
      onClick={onClick}
      sx={[
        (theme) => ({
          gap: theme.spacing(1.75),
          background: (() => {
            if (type === 'disable') {
              return `repeating-linear-gradient(-45deg, ${theme.palette.stationGray[200]}, ${theme.palette.stationGray[200]} 1.5px, ${theme.palette.stationGray[100]} 0, ${theme.palette.stationGray[100]} 10px)`;
            }
            if (type === 'border') {
              return `linear-gradient(90deg, ${getBorderColor(theme, borderColor)} 4px, ${theme.palette.common.white} 0)`;
            }
            if (type === 'disabledWithBorder') {
              return `
              linear-gradient(90deg, ${getBorderColor(theme, borderColor)} 4px, rgba(255, 255, 255, 0) 0),
              repeating-linear-gradient(-45deg,
                ${theme.palette.stationGray[300]},
                ${theme.palette.stationGray[300]} 1.5px,
                ${theme.palette.stationGray[200]} 0,
                ${theme.palette.stationGray[200]} 10px)
              `;
            }
            return theme.palette.common.white;
          })(),
          borderRadius: theme.spacing(1.5),
          width: '100%',
          height: '100%',
          border: (() => {
            if (type === 'passive') return `1px solid ${theme.palette.stationGray[300]}`;
            else if (isSelected) {
              if (selectedColor && selectedBorderWidth) return `${selectedBorderWidth} solid ${selectedColor}`;
              else if (selectedColor) return `2px solid ${selectedColor}`;
              else return `3px solid ${theme.palette.stationTeal[400]}`;
            }
            return `1px solid ${theme.palette.stationGray[300]}`;
          })(),
          transition: type === 'passive' ? 'none' : 'border linear 0.2s',
          '&:hover': (() => {
            if (type === 'passive') return {};
            else if (isSelected) {
              if (selectedColor && selectedBorderWidth) return `${selectedBorderWidth} solid ${selectedColor}`;
              else if (selectedColor) return { border: `2px solid ${selectedColor}` };
              else return { border: `3px solid ${theme.palette.stationTeal[600]}` };
            }
            return { border: `1px solid ${theme.palette.stationGray[600]}` };
          })(),
        }),
        ...(Array.isArray(sxProps) ? sxProps : sxProps ? [sxProps] : []),
      ]}
    >
      {children}
    </Box>
  );
};
