import Quill from 'quill';
import React, { useRef, useEffect, Dispatch, SetStateAction } from 'react';
import 'quill/dist/quill.snow.css'; // Import Quill styles
import './textEditor.css';

export const TextEditor = ({
  messageContent,
  setMessageContent,
  placeholder,
}: {
  messageContent: string;
  setMessageContent: Dispatch<SetStateAction<string>>;
  placeholder: string;
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<Quill | null>(null);

  const hasTextContentInsideHTML = (body: string) => {
    // The following regex detects any html tags
    const regex = /(<([^>]+)>)/gi;

    // Remove the html tags to detect if there is any text
    return !!body.replace(regex, '').length;
  };

  useEffect(() => {
    if (editorRef.current && !quillRef.current) {
      const quill = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'bullet' }, { list: 'ordered' }],
          ],
        },
        formats: ['bold', 'italic', 'underline', 'strike', 'list'],
        placeholder: placeholder,
      });

      quillRef.current = quill;
      quill.on('text-change', () => {
        if (hasTextContentInsideHTML(quill.root.innerHTML)) {
          setMessageContent(quill.root.innerHTML);
        } else {
          setMessageContent('');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMessageContent]);

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.root.dataset['placeholder'] = placeholder;
      if (messageContent === '') {
        quillRef.current.root.innerHTML = messageContent;
      }
    }
  }, [messageContent, placeholder]);

  return <div className="editor-container" ref={editorRef}></div>;
};
