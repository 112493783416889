import { RosterPosition } from '@stationwise/share-types';
import { makeTemporaryPosition, sortTemporaryPositions } from '../board';
import { IShiftSummaryHelper, RemoveEmployeeAvailabilityProps } from '../types';
import { mergeVacancies, resolveAllEmployeeOverlaps, resolveOneEmployeeOverlap } from './overlap';

export const removeBoardEmployeeAvailability = <T extends IShiftSummaryHelper>({
  shiftSummaryHelper,
  employeeId,
  startTime,
  endTime,
  checkIsPositionExcluded = () => false,
  resolveOverlap = resolveOneEmployeeOverlap,
}: RemoveEmployeeAvailabilityProps<T>): T => {
  const newShiftSummaryHelper = { ...shiftSummaryHelper };

  const newAllStationCards = new Map(newShiftSummaryHelper.allStationCards);
  for (const [stationId, station] of newAllStationCards.entries()) {
    const newStation = { ...station };

    newStation.apparatuses = newStation.apparatuses.map((apparatus) => {
      const newApparatus = { ...apparatus };

      const newPositions: RosterPosition[] = [];
      newApparatus.positions.forEach((position) => {
        const newPosition = { ...position };
        if (!checkIsPositionExcluded(position, newApparatus)) {
          newPosition.employees = resolveAllEmployeeOverlaps(
            newPosition.employees,
            employeeId,
            startTime,
            endTime,
            (employees, employee) => resolveOverlap(employees, employee, position),
          );
          newPosition.employees = mergeVacancies(newPosition.employees);
        }

        if (!newPosition.isTemporary || newPosition.employees.length === 1) {
          newPositions.push(newPosition);
        } else if (newPosition.employees.length > 1) {
          newPosition.employees.forEach((employee) => {
            newPositions.push({ ...makeTemporaryPosition(newApparatus, employee), employees: [employee] });
          });
        }
      });

      newApparatus.positions = sortTemporaryPositions(newPositions);

      return newApparatus;
    });

    newAllStationCards.set(stationId, newStation);
  }

  newShiftSummaryHelper.allStationCards = newAllStationCards;

  return newShiftSummaryHelper;
};
