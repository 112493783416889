import { Box, Divider, Skeleton } from '@mui/material';

export const RequestCardLoading = () => {
  return (
    <Box sx={{ mb: '16px', display: 'flex' }}>
      <Box sx={{ position: 'relative', width: 45, height: 45 }}>
        <Skeleton
          variant="circular"
          width={40}
          height={40}
          sx={{ background: 'linear-gradient(90deg, #CED5DF 0%, #EAEFF6 100%)' }}
        />
        <Skeleton
          variant="circular"
          width={24}
          height={24}
          sx={{ background: 'linear-gradient(90deg, #CED5DF 0%, #EAEFF6 100%)', position: 'absolute', right: 0, bottom: 0 }}
        />
      </Box>
      <Box sx={{ marginLeft: '8px' }}>
        <Skeleton
          variant="text"
          width="302px"
          height="20px"
          sx={{ borderRadius: '6px', background: 'linear-gradient(90deg, #CED5DF 0%, #EAEFF6 100%)' }}
        />
        <Skeleton
          variant="text"
          width="183px"
          height="20px"
          sx={{ borderRadius: '6px', background: 'linear-gradient(90deg, #CED5DF 0%, #EAEFF6 100%)' }}
        />
        <Skeleton
          variant="text"
          width="273px"
          height="20px"
          sx={{ borderRadius: '6px', background: 'linear-gradient(90deg, #CED5DF 0%, #EAEFF6 100%)' }}
        />
        <Skeleton
          variant="text"
          width="233px"
          height="20px"
          sx={{ borderRadius: '6px', background: 'linear-gradient(90deg, #CED5DF 0%, #EAEFF6 100%)' }}
        />
        <Skeleton
          variant="text"
          width="79px"
          height="20px"
          sx={{ borderRadius: '6px', background: 'linear-gradient(90deg, #CED5DF 0%, #EAEFF6 100%)' }}
        />
        <Box sx={{ display: 'flex', mt: '8px' }}>
          <Skeleton
            variant="text"
            width="83px"
            height="32px"
            sx={{ background: 'linear-gradient(90deg, #CED5DF 0%, #EAEFF6 100%)' }}
          />
          <Skeleton
            variant="text"
            width="61px"
            height="32px"
            sx={{ background: 'linear-gradient(90deg, #CED5DF 0%, #EAEFF6 100%)', ml: '8px', mr: '8px' }}
          />
          <Skeleton
            variant="text"
            width="112px"
            height="32px"
            sx={{ background: 'linear-gradient(90deg, #CED5DF 0%, #EAEFF6 100%)' }}
          />
        </Box>
      </Box>
      <Divider sx={(theme) => ({ mt: '16px', borderColor: theme.palette.stationGray[100] })} />
    </Box>
  );
};
