import { useMemo } from 'react';
import { useAuthUserContext } from '../components/Auth/context/AuthUser';

export const CAPABILITIES = {
  ALL: 'ALL',
  SEND_MESSAGES: 'SEND_MESSAGES',
  EDIT_DEPARTMENT_SETTINGS: 'EDIT_DEPARTMENT_SETTINGS',
  VIEW_CHANGE_LOGS: 'VIEW_CHANGE_LOGS',
  VIEW_TEAM_SETTINGS: 'VIEW_TEAM_SETTINGS',
  ADD_NEW_EMPLOYEE: 'ADD_NEW_EMPLOYEE',
  EDIT_EMPLOYEE_PROFILE: 'EDIT_EMPLOYEE_PROFILE',
  EMPLOYEE_ACCOUNT_CONTROL: 'EMPLOYEE_ACCOUNT_CONTROL',
  PROMOTION: 'PROMOTION',
  MANAGE_SHIFT_TRADE_LEDGER: 'MANAGE_SHIFT_TRADE_LEDGER',
  MANAGE_WORK_GROUPS: 'MANAGE_WORK_GROUPS',
  INCIDENT_REPORT_ADMIN: 'INCIDENT_REPORT_ADMIN',
  VIEW_PAYROLL: 'VIEW_PAYROLL',
  EDIT_PAYROLL: 'EDIT_PAYROLL',
  EDIT_ACCRUALS: 'EDIT_ACCRUALS',
  VIEW_STAFFING_LIST: 'VIEW_STAFFING_LIST',
  STAFFING_LIST_EDITOR: 'STAFFING_LIST_EDITOR',
  SHIFT_TEMPLATE_EDITOR: 'SHIFT_TEMPLATE_EDITOR',
  STAFFING_EDIT_ROSTER: 'STAFFING_EDIT_ROSTER',
  STAFFING_MANAGE_HIRING: 'STAFFING_MANAGE_HIRING',
  STAFFING_MANAGE_PAYCODE: 'STAFFING_MANAGE_PAYCODE',
  CREATE_UNDEPLOY_EVENTS: 'CREATE_UNDEPLOY_EVENTS',
} as const;

export type Capability = (typeof CAPABILITIES)[keyof typeof CAPABILITIES];

export type ComputedCapabilities = {
  [K in keyof typeof CAPABILITIES]: boolean;
};

export function computeCapabilities(capabilities: string[]): ComputedCapabilities {
  const computed = Object.fromEntries(
    Object.values(CAPABILITIES).map((capability) => [
      capability,
      capabilities.includes('ALL') || capabilities.includes(capability),
    ]),
  ) as ComputedCapabilities;

  return computed;
}

export function useAuthUserCapabilities() {
  const { state } = useAuthUserContext();
  return useMemo(() => {
    const userCapabilities = state.employee?.capabilities || [];
    return computeCapabilities(userCapabilities);
  }, [state.employee?.capabilities]);
}
