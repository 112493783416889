import { Box, Theme, useMediaQuery } from '@mui/material';
import { ChevronRightIcon16 } from '../../../assets';

interface StatusPanelProps {
  reviewedBy: string;
  formattedDate: string;
  icon: React.ReactNode;
  statusLabel: string;
  isHovered: boolean;
}

export const StatusPanel = ({ reviewedBy, formattedDate, icon, statusLabel, isHovered }: StatusPanelProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return isMobile ? (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 1.5),
        backgroundColor: theme.palette.stationGray[100],
        borderRadius: theme.spacing(1.5),
        typography: 'bodySMedium',
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          '& svg path': {
            stroke: theme.palette.stationGray[500],
          },
        })}
      >
        {icon}
      </Box>
      <Box sx={(theme) => ({ pl: theme.spacing(1) })}>
        <span>
          {statusLabel} by <strong>{reviewedBy}</strong>
        </span>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(0.5),
            color: theme.palette.stationGray[500],
            '& svg path': {
              stroke: theme.palette.stationGray[900],
            },
          })}
        >
          {formattedDate}
        </Box>
      </Box>
      <Box sx={{ position: 'absolute', right: '48px' }}>
        <ChevronRightIcon16 />
      </Box>
    </Box>
  ) : (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1, 1.5),
        backgroundColor: isHovered ? theme.palette.stationGray[200] : theme.palette.stationGray[100],
        borderRadius: theme.spacing(1.5),
        typography: 'bodySMedium',
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(0.5),
          '& svg path': {
            stroke: theme.palette.stationGray[500],
          },
        })}
      >
        {icon}
        <span>
          {statusLabel} by <strong>{reviewedBy}</strong>
        </span>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(0.5),
          color: theme.palette.stationGray[500],
          '& svg path': {
            stroke: theme.palette.stationGray[900],
          },
        })}
      >
        {formattedDate}
        <ChevronRightIcon16 />
      </Box>
    </Box>
  );
};
