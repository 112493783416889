import { Box, Button as MUIButton, DialogTitle, SelectChangeEvent } from '@mui/material';
import { setMinutes } from 'date-fns';
import { FormEvent, ReactNode, useState } from 'react';
import { RosterSplitShiftOrTimeOffChange } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { diffCycleMinutes, splitEmployee } from '@stationwise/shift-summary-helper';
import { CheckIcon16, PlusIcon16 } from '../../../../assets';
import { Button } from '../../../Button';
import { useRosterContext } from '../RosterContext';
import { Reason } from './Reason';
import { Split } from './Split';
import { INITIAL_SPLITS, MAX_SPLITS } from './constants';
import { getSplitErrors, makeSplits, mergeSplits, rebalanceSplitTimes } from './helpers';

export const SplitShiftOrTimeOffForm = () => {
  const {
    selectedFilledPositionState,
    splitShiftOrTimeOffState,
    setUserHasMadeChanges,
    shiftSummaryHelper,
    setShiftSummaryHelper,
  } = useRosterContext();
  const { shiftDuration } = shiftSummaryHelper;

  const { employee } = selectedFilledPositionState;

  const employeeShiftDuration = !employee
    ? shiftDuration
    : {
        startTime: employee.startDateTime,
        endTime: employee.endDateTime,
        hours: differenceInUTCMinutes(employee.endDateTime, employee.startDateTime) / 60,
      };

  const [reason, setReason] = useState('NONE');
  const [splits, setSplits] = useState(makeSplits(employeeShiftDuration, INITIAL_SPLITS));
  const [submitted, setSubmitted] = useState(false);

  const isReasonValid = reason === 'NONE' ? splits.length > 1 : splits.some((split) => split.isEmpty);
  const splitErrors = getSplitErrors(employeeShiftDuration, splits);
  const canSubmit = !!employee?.id && isReasonValid && splitErrors.every((err) => !err) && !submitted;

  const onChangeReason = (event: SelectChangeEvent) => {
    const newReason = event.target.value || 'NONE';
    setReason(newReason);
    if (newReason === 'NONE') {
      setSplits(splits.map((split) => ({ ...split, isEmpty: false })));
    } else if (splits.length === 1) {
      setSplits(splits.map((split) => ({ ...split, isEmpty: true })));
    }
  };

  const onChangeSplitTimeOff = (splitIndex: number, isEmpty: boolean) => {
    const split = splits[splitIndex];
    const newSplits = [...splits];
    newSplits[splitIndex] = { ...split, isEmpty };
    setSplits(newSplits);
  };

  const onChangeSplitEndTime = (splitIndex: number, endTime: Date | null) => {
    const split = splits[splitIndex];
    const nextSplit = splits[splitIndex + 1];
    if (endTime && nextSplit) {
      const durationMinutes = diffCycleMinutes(endTime, split.startTime) || shiftDuration.hours * 60;
      const newEndTime = setMinutes(split.startTime, split.startTime.getMinutes() + durationMinutes);
      const newSplits = [...splits];
      newSplits[splitIndex] = { ...split, endTime: newEndTime };
      newSplits[splitIndex + 1] = { ...nextSplit, startTime: newEndTime };
      setSplits(newSplits);
    }
  };

  const checkSplitDurationEquals = (splits: RosterSplitShiftOrTimeOffChange[]): boolean => {
    const splitDurations = splits.map((split) => diffCycleMinutes(split.endTime, split.startTime) || shiftDuration.hours * 60);
    return splitDurations.every((duration) => duration === splitDurations[0]);
  };

  const createSplitDuration = (startTime: Date, endTime: Date) => ({
    startTime,
    endTime,
    hours: differenceInUTCMinutes(endTime, startTime) / 60,
  });

  const addNewSplit = (newSplits: RosterSplitShiftOrTimeOffChange[], startTime: Date, endTime: Date) => {
    newSplits.push({ startTime, endTime, isEmpty: false });
    rebalanceSplitTimes(employeeShiftDuration, newSplits);
    setSplits(newSplits);
  };

  const onDeleteSplit = (splitIndex: number) => {
    const newSplits = [...splits];
    newSplits.splice(splitIndex, 1);
    rebalanceSplitTimes(employeeShiftDuration, newSplits);
    if (newSplits.length === 1 && reason !== 'NONE') {
      newSplits[0] = { ...newSplits[0], isEmpty: true };
    }
    setSplits(newSplits);
  };

  const onAddSplit = () => {
    const isModified = !checkSplitDurationEquals(splits);
    let newSplits = [];
    if (splits.length === 1 && reason !== 'NONE') {
      newSplits = [{ ...splits[0], isEmpty: false }];
    } else {
      newSplits = [...splits];
    }
    if (isModified) {
      const lastSplit = newSplits.pop() as RosterSplitShiftOrTimeOffChange;
      const lastSplits = [
        lastSplit,
        { startTime: employeeShiftDuration.endTime, endTime: employeeShiftDuration.endTime, isEmpty: false },
      ];
      rebalanceSplitTimes(createSplitDuration(lastSplit.startTime, lastSplit.endTime), lastSplits);
      newSplits.push(...lastSplits);
      setSplits(newSplits);
    } else {
      addNewSplit(newSplits, employeeShiftDuration.endTime, employeeShiftDuration.endTime);
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!canSubmit) {
      return;
    }

    setSubmitted(true);

    const mergedSplits = mergeSplits(splits);
    const timeOffSplits = mergedSplits.filter((split) => reason !== 'NONE' && split.isEmpty);

    const payloads = timeOffSplits.map((split) => ({
      employeeId: employee.id,
      positionId:
        selectedFilledPositionState.position && !selectedFilledPositionState.position.isTemporary
          ? selectedFilledPositionState.position.id
          : null,
      payCode: reason,
      startTime: diffCycleMinutes(split.startTime, shiftDuration.startTime),
      endTime: diffCycleMinutes(split.endTime, shiftDuration.startTime) || shiftDuration.hours * 60,
      employeeTradeId: employee.trade?.id,
    }));
    splitShiftOrTimeOffState.setPayloads((prev) => [...prev, ...payloads]);
    setShiftSummaryHelper(splitEmployee(shiftSummaryHelper, employee.activeId, mergedSplits));
    setUserHasMadeChanges(true);
    selectedFilledPositionState.closeDialog();
    splitShiftOrTimeOffState.setIsDialogOpen(false);
  };

  const renderChecklistItem = (item: ReactNode) => (
    <Box
      sx={(theme) => ({
        color: theme.palette.stationGray[700],
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        mt: 0.5,
        'h2 + &': {
          mt: 1,
        },
        '& svg path': {
          stroke: theme.palette.stationGray[400],
        },
      })}
    >
      <CheckIcon16 />
      {item}
    </Box>
  );

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Box
        sx={(theme) => ({
          backgroundColor: isReasonValid ? 'transparent' : theme.palette.stationGray[100],
          borderRadius: '8px',
          typography: 'bodySRegular',
          p: isReasonValid ? theme.spacing(1.5, 0) : 1.5,
        })}
      >
        <DialogTitle component="h2" sx={{ p: 0, m: 0, typography: 'bodyXLSemibold' }}>
          Manage shift
        </DialogTitle>
        {!isReasonValid && renderChecklistItem('Select "Employee is not off" and at least two splits, or')}
        {!isReasonValid && renderChecklistItem('Select a reason and at least one employee off')}
      </Box>

      {employee && <Reason value={reason} onChange={onChangeReason} employeeId={employee.id} />}

      {splits.map((split, index) => (
        <Split
          key={index}
          splits={splits}
          index={index}
          error={splitErrors[index]}
          isTimeOffDisabled={reason === 'NONE'}
          onChangeTimeOff={onChangeSplitTimeOff}
          onChangeEndTime={onChangeSplitEndTime}
          onDelete={onDeleteSplit}
        />
      ))}
      {splits.length < MAX_SPLITS && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <MUIButton
            data-cy="add-split-button"
            onClick={onAddSplit}
            sx={(theme) => ({
              color: theme.palette.stationPurple[500],
              gap: 1,
              p: '9px 17px',
              typography: 'buttonL',
              '& svg path': {
                stroke: 'currentColor',
              },
            })}
          >
            <PlusIcon16 aria-hidden="true" />
            Add split
          </MUIButton>
        </Box>
      )}
      <Box sx={{ display: 'flex', mt: 3, gap: 2 }}>
        <Button
          data-cy="manage-cancel-button"
          onClick={() => splitShiftOrTimeOffState.setIsDialogOpen(false)}
          buttonSize="lg"
          buttonType="tertiary"
          sx={() => ({ flex: 1 })}
        >
          Cancel
        </Button>
        <Button
          data-cy="manage-apply-button"
          type="submit"
          disabled={!canSubmit}
          buttonSize="lg"
          buttonType="dark"
          sx={() => ({ flex: 1 })}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};
