import { Box } from '@mui/material';
import React from 'react';
import { Checkbox } from '../../Checkbox';
import { SelectContactOption, SelectContactOptionProps, SelectShiftOption, SelectWorkspaceOption } from './Options';
import { ALL_CONTACTS, SHIFTS, WORK_GROUPS } from './constants';

interface SelectContactProps {
  options: SelectContactOptionProps[];
  isAllSelected?: boolean;
  onAllSelect?: () => void;
  selectedFilter?: string;
}

export const SelectContact = ({ options, isAllSelected, onAllSelect, selectedFilter }: SelectContactProps) => {
  const Component = (() => {
    switch (selectedFilter) {
      case ALL_CONTACTS:
        return SelectContactOption;
      case WORK_GROUPS:
        return SelectWorkspaceOption;
      case SHIFTS:
        return SelectShiftOption;
      default:
        return SelectContactOption;
    }
  })();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: theme.spacing(2),
          marginBottom: theme.spacing(2),
          typography: 'bodySMedium',
        })}
      >
        <Checkbox checked={isAllSelected} onClick={onAllSelect} />
        Select all
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
        })}
      >
        {options.map((option) => (
          <Component
            key={`${option.name}-${option.email}`}
            chipBackgroundColor={option.chipBackgroundColor}
            chipLabel={option.chipLabel}
            name={option.name}
            email={option.email}
            userImg={option.userImg}
            onClick={option.onClick}
            checked={isAllSelected || option.checked}
            id={option.id}
            count={option.count}
          />
        ))}
      </Box>
    </Box>
  );
};
