import { Box } from '@mui/material';
import { useState } from 'react';
import { ErrorPage } from '@stationwise/component-module';
import { PayrollCode } from '@stationwise/share-types';
import { AccrueCompTimeCards } from './AccrueCompTimeCards';
import { AccrueCompTimeFooter } from './AccrueCompTimeFooter';
import { CompTimeBanked } from './CompTimeBanked';
import { AvailableCompTime } from './utils';

interface PayrollCompTimeAccrualContentProps {
  availableCompTimes: AvailableCompTime[];
  payrollCode: PayrollCode | null;
  setOpenResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  requestCompTimeError: string | null;
}
export const PayrollCompTimeAccrualContent = ({
  availableCompTimes,
  payrollCode,
  setOpenResponseModal,
  setMessage,
  setError,
  requestCompTimeError,
}: PayrollCompTimeAccrualContentProps) => {
  const [duration, setDuration] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <Box
      sx={{
        height: '100vh',
      }}
    >
      {requestCompTimeError ? (
        <ErrorPage />
      ) : (
        <>
          <AccrueCompTimeCards
            availableCompTimes={availableCompTimes}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            payrollCode={payrollCode}
          />
          <CompTimeBanked
            availableCompTime={availableCompTimes}
            duration={duration}
            selectedIndex={selectedIndex}
            setDuration={setDuration}
          />
          <AccrueCompTimeFooter
            setOpenResponseModal={setOpenResponseModal}
            availableCompTime={availableCompTimes[selectedIndex]}
            duration={duration}
            setMessage={setMessage}
            setError={setError}
          />
        </>
      )}
    </Box>
  );
};
