import { Box, SxProps, Theme, useTheme, useMediaQuery } from '@mui/material';
import { RosterPosition, RosterEmployee, Certification, DEFAULT_RANK } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import {
  checkHasMandatoryOvertimeDetailCode,
  checkHasVoluntaryOvertimeDetailCode,
  checkIsAdministration,
  checkIsTemporaryNonShift,
  checkIsShift,
  formatShiftDuration,
  getAllCertificationCodes,
} from '@stationwise/shift-summary-helper';
import { CircleHigherIcon20, Pencil01Icon16 } from '../../assets';
import { CardProps, Card, CardTooltip, CollapsedCard, OvalChip, ShiftDurationIndicator } from '../Card';
import { useLoadedDepartmentInfoContext } from '../Department';

interface Props {
  position: RosterPosition;
  employee: RosterEmployee;
  actingAs?: boolean;
  isCollapsed: boolean;
  cardSxProps?: SxProps<Theme>;
  cardType?: 'default' | 'disable' | 'border';
  actions?: React.ReactNode;
}

export const FilledPosition = ({
  position,
  employee,
  actingAs,
  isCollapsed,
  cardSxProps,
  cardType,
  actions,
  ...props
}: Props) => {
  const theme = useTheme();
  const isMobileApp = useMediaQuery((muiTheme: Theme) => muiTheme.breakpoints.down('lg'));

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isFirstCard = employee === position.employees[0];
  const isLastCard = employee === position.employees[position.employees.length - 1];
  const durationMinutes = employee.breakDuration
    ? differenceInUTCMinutes(employee.endDateTime, employee.startDateTime) - employee.breakDuration
    : differenceInUTCMinutes(employee.endDateTime, employee.startDateTime);
  const duration = Math.floor(durationMinutes / 60);
  const certificationActAsRanks = departmentInfoState.departmentInfo.certificationActAsRanks;

  const sxProps = {
    borderTopLeftRadius: isFirstCard ? '12px' : '0',
    borderTopRightRadius: isFirstCard ? '12px' : '0',
    borderBottomLeftRadius: isLastCard ? '12px' : '0',
    borderBottomRightRadius: isLastCard ? '12px' : '0',
  };

  const coveredActingAs = (employee: RosterEmployee, position: RosterPosition, certification: Certification): boolean => {
    if (employee.rank.code !== position.rank.code) {
      return false;
    }

    const relatedCertificationCodes = getAllCertificationCodes([certification]);
    return certificationActAsRanks
      .filter((caar) => caar.rankCode === position.rank.code)
      .some((certificationActAsRank) => relatedCertificationCodes.has(certificationActAsRank.certificationCode));
  };

  const certs = employee.certifications.filter((eCert) => {
    const eCodes = getAllCertificationCodes([eCert]);
    return position.certifications.some((pCert) => eCodes.has(pCert.code)) && !coveredActingAs(employee, position, eCert);
  });

  const indicators = (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, '&:empty': { display: 'none' } }}>
      {position.isTemporary && <CircleHigherIcon20 />}
      {(() => {
        if (checkHasMandatoryOvertimeDetailCode(employee)) {
          const { mandatoryOvertimeNaming } = departmentInfoState.departmentInfo;
          return <OvalChip label={mandatoryOvertimeNaming.abbr} backgroundColor={theme.palette.stationPink[500]} />;
        } else if (checkHasVoluntaryOvertimeDetailCode(employee)) {
          return <OvalChip label="VOL" backgroundColor={theme.palette.stationPurple[500]} />;
        } else if (employee.trade) {
          return <OvalChip label="TRADE" backgroundColor={theme.palette.stationLightBlue[500]} />;
        }
        return null;
      })()}
      {!isMobileApp && employee.noteOverride?.note && (
        <CardTooltip anchorContent={<Pencil01Icon16 />} popoverContent="Click to see note details" />
      )}
    </Box>
  );

  const cardProps: CardProps = {
    badgeTitle: position.rank.id !== DEFAULT_RANK.id ? position.rank.code : '',
    durationLabel: formatShiftDuration({ startTime: employee.startDateTime, endTime: employee.endDateTime }),
    nameLabel: employee.name,
    certs: !checkIsShift(position) ? [] : certs,
    color: position.rank.color,
    actingAs: actingAs ?? position.rank.code !== employee.rank.code ? position.rank.name : undefined,
    isDriver: position.driver,
    duration: duration <= 0 ? duration + 24 : duration,
    borderColor: employee.team?.color,
    indicators,
    cardSxProps: [sxProps, ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : [])],
    type: cardType,
    actions: actions,
    ...props,
  };

  if (isCollapsed) {
    const [firstName, ...lastNames] = employee.name.split(' ');
    cardProps.nameLabel = `${firstName[0]}. ${lastNames.join(' ')}`;

    if (checkIsAdministration(position) || checkIsTemporaryNonShift(position)) {
      cardProps.indicators = <ShiftDurationIndicator duration={cardProps.duration} durationLabel={cardProps.durationLabel} />;
    }

    return (
      <CollapsedCard
        badgeTitle={cardProps.badgeTitle}
        color={cardProps.color}
        nameLabel={cardProps.nameLabel}
        borderColor={cardProps.borderColor}
        type={cardProps.type}
        isDriver={cardProps.isDriver}
        actingAs={cardProps.actingAs}
        indicators={cardProps.indicators}
        cardSxProps={cardProps.cardSxProps}
      />
    );
  }

  return <Card {...cardProps} />;
};
