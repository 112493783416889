import { Fragment } from 'react';
import { EmployeeOffView } from '@stationwise/share-types';
import { EmployeeOffCard } from './EmployeeOffCard';
import { FloaterCard } from './FloaterCard';
import { ReplacementEmployeeCard } from './ReplacementEmployeeCard';
import { SwapArrow } from './SwapArrow';

interface Props {
  employeeOffData: EmployeeOffView;
}

export const SwappedEmployees = ({ employeeOffData }: Props) => {
  const card = (
    <EmployeeOffCard
      employeeOff={employeeOffData.employeeOff}
      shiftDate={employeeOffData.shiftDate}
      startTime={employeeOffData.startTime}
      endTime={employeeOffData.endTime}
    />
  );
  if (employeeOffData.requestType !== 'ShiftTradeRequest') {
    return card;
  } else if (employeeOffData.replacementEmployees.length === 0) {
    return (
      <>
        <FloaterCard isReplacementNeeded={employeeOffData.isReplacementNeeded} />
        <SwapArrow />
        {card}
      </>
    );
  }
  return employeeOffData.replacementEmployees.map((replacementEmployee) => (
    <Fragment key={replacementEmployee.id}>
      <ReplacementEmployeeCard
        replacementEmployee={replacementEmployee}
        shiftDate={employeeOffData.shiftDate}
        startTime={employeeOffData.startTime}
        endTime={employeeOffData.endTime}
      />
      <SwapArrow />
      {card}
    </Fragment>
  ));
};
