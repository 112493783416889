import { Box, Skeleton } from '@mui/material';
import { LetterAvatar, Flag02Icon16, getPalette, getShiftColor, Users01Icon16 } from '@stationwise/component-module';
import { AllowedColors, ConversationRecipient, Team, WorkGroup } from '@stationwise/share-types';
import { AVATAR_SIZE } from '../constants';

export interface RecipientsProps {
  isLoading?: boolean;
  recipients: ConversationRecipient[];
  workGroups: WorkGroup[];
  team: Team[];
}

export const RecipientSkeletons = () => {
  return (
    <Box display="flex" gap={2} alignItems="center" ml={1}>
      <Box display="flex">
        <Skeleton
          variant="circular"
          width={AVATAR_SIZE}
          height={AVATAR_SIZE}
          sx={(theme) => ({ marginLeft: theme.spacing(-1) })}
        />
        <Skeleton
          variant="circular"
          width={AVATAR_SIZE}
          height={AVATAR_SIZE}
          sx={(theme) => ({ marginLeft: theme.spacing(-1) })}
        />
        <Skeleton
          variant="circular"
          width={AVATAR_SIZE}
          height={AVATAR_SIZE}
          sx={(theme) => ({ marginLeft: theme.spacing(-1) })}
        />
        <Skeleton
          variant="circular"
          width={AVATAR_SIZE}
          height={AVATAR_SIZE}
          sx={(theme) => ({ marginLeft: theme.spacing(-1) })}
        />
      </Box>
      <Box>
        <Skeleton variant="text" width={100} height={20} />
      </Box>
    </Box>
  );
};

export const Recipients = ({ recipients, isLoading, workGroups, team }: RecipientsProps) => {
  const groupSize = workGroups.length + team.length;
  const independentEmployeeSize = Math.max(0, 4 - groupSize);
  if (isLoading) {
    return <RecipientSkeletons />;
  }
  return (
    <Box display="flex" gap={2} alignItems="center" ml={1}>
      <Box display="flex" alignContent={'center'}>
        {recipients
          .filter((recipient) => recipient.inGroup === false)
          .slice(0, independentEmployeeSize)
          .map((recipient, index) =>
            recipient?.employee?.image ? (
              <Box
                key={index}
                sx={(theme) => ({
                  marginLeft: theme.spacing(-1),
                  borderRadius: '50%',
                  height: '20px',
                  width: '20px',
                  border: `1px solid ${theme.palette.common.white}`,
                  zIndex: `${index}`,
                  position: 'relative',
                })}
                component="img"
                alt="image of person"
                src={recipient.employee.image}
              />
            ) : (
              // letter box
              <Box
                key={index}
                sx={(theme) => ({
                  marginLeft: theme.spacing(-1),
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: `1px solid ${theme.palette.common.white}`,
                  height: '20px',
                  width: '20px',
                  '& svg': {
                    height: '12px',
                    width: '12px',
                  },
                  zIndex: `${index}`,
                  position: 'relative',
                })}
              >
                <LetterAvatar
                  size="xxsmall"
                  key={index}
                  firstName={recipient.employee.firstName}
                  lastName={recipient.employee.lastName}
                />
              </Box>
            ),
          )}
        {workGroups.map((workGroup, index) => (
          <Box
            key={index}
            sx={(theme) => ({
              marginLeft: theme.spacing(-1),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              border: `1px solid ${theme.palette.common.white}`,
              zIndex: `${500 + index}`,
              position: 'relative',
              height: '20px',
              width: '20px',
              '& svg': {
                height: '12px',
                width: '12px',
              },
              color: theme.palette.common.white,
              backgroundColor: getPalette({ theme, color: workGroup.color })[500],
            })}
          >
            <Users01Icon16 />
          </Box>
        ))}
        {team.map((team, index) => (
          <Box
            key={index}
            sx={(theme) => ({
              marginLeft: theme.spacing(-1),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              border: `1px solid ${theme.palette.common.white}`,
              zIndex: `${1000 + index}`,
              position: 'relative',
              color: getShiftColor(team.color as AllowedColors, theme),
              backgroundColor: theme.palette.common.white,

              height: '20px',
              width: '20px',
              '& svg': {
                height: '12px',
                width: '12px',
              },
            })}
          >
            <Flag02Icon16 />
          </Box>
        ))}
      </Box>
      <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'bodySRegular' })}>
        {`${recipients.length} Recipient${recipients.length === 1 ? '' : 's'}`}
      </Box>
    </Box>
  );
};
