import { Box, Typography } from '@mui/material';
import { VacancyInfoView } from './RequestVolunteersSignUpMain';
import { VacancyInfoCard } from './VacancyInfoCard';

interface Props {
  numberOfVacancies?: number;
  vacancies: VacancyInfoView[];
}

export const VacanciesInfo = ({ numberOfVacancies, vacancies }: Props) => {
  return (
    <Box
      display="flex"
      sx={() => ({
        width: '92%',
        ml: '4%',
        mr: '4%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'left',
      })}
    >
      <Box
        display="flex"
        sx={{
          width: '100%',
          height: '10%',
          mt: '4%',
          mb: '4%',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="bodyLSemibold"
          sx={(theme) => ({
            color: theme.palette.stationGray[900],
          })}
        >
          Eligible for {numberOfVacancies} vacancies
        </Typography>
      </Box>
      <Box
        display="flex"
        sx={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {vacancies.map((vacancy, index) => (
          <VacancyInfoCard
            key={index}
            badgeTitle={vacancy.actingRankName}
            badgeColor={vacancy.actingRankColor}
            stationName={vacancy.stationName}
            apparatusName={vacancy.apparatusName}
          />
        ))}
      </Box>
    </Box>
  );
};
