import { Box, Dialog } from '@mui/material';
import { useMemo, useState } from 'react';
import { Button, useDepartmentInfoContext } from '@stationwise/component-module';
import { Manager, MANAGER_NAMES } from '@stationwise/share-types';
import { ManagerAutocomplete } from './ManagerAutoComplete';
import { usePatchRequest } from './PatchRequestProvider';
interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  managerOne: Manager;
  managerTwo: Manager;
  managerThree: Manager;
}

export const EditManagerInfo = ({ showModal, setShowModal, managerOne, managerTwo, managerThree }: ModalProps) => {
  const [selectedManagerOne, setSelectedManagerOne] = useState(managerOne);
  const [selectedManagerTwo, setSelectedManagerTwo] = useState(managerTwo);
  const [selectedManagerThree, setSelectedManagerThree] = useState(managerThree);

  const { state: department } = useDepartmentInfoContext();

  const { handlePatchRequest } = usePatchRequest();

  const managerOptions = department.departmentInfo?.managers ?? [];

  const handleSave = () => {
    const data = {
      managerOne: selectedManagerOne?.id?.toString() ?? '',
      managerTwo: selectedManagerTwo?.id?.toString() ?? '',
      managerThree: selectedManagerThree?.id?.toString() ?? '',
    };
    handlePatchRequest(data);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedManagerOne(managerOne);
    setSelectedManagerTwo(managerTwo);
    setSelectedManagerThree(managerThree);
  };

  const isDisabled = useMemo(() => {
    const isChangedManagerOne = (selectedManagerOne?.name ?? '') === (managerOne?.name ?? '');
    const isChangedManagerTwo = (selectedManagerTwo?.name ?? '') === (managerTwo?.name ?? '');
    const isChangedManagerThree = (selectedManagerThree?.name ?? '') === (managerThree?.name ?? '');

    return isChangedManagerOne && isChangedManagerTwo && isChangedManagerThree;
  }, [selectedManagerOne, selectedManagerTwo, selectedManagerThree, managerOne, managerTwo, managerThree]);

  return (
    <Dialog open={showModal} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Managers
          </Box>

          <ManagerAutocomplete
            title={MANAGER_NAMES.MANAGER_ONE}
            manager={selectedManagerOne}
            setManager={setSelectedManagerOne}
            options={managerOptions}
          />
          <ManagerAutocomplete
            title={MANAGER_NAMES.MANAGER_TWO}
            manager={selectedManagerTwo}
            setManager={setSelectedManagerTwo}
            options={managerOptions}
          />
          <ManagerAutocomplete
            title={MANAGER_NAMES.MANAGER_THREE}
            manager={selectedManagerThree}
            setManager={setSelectedManagerThree}
            options={managerOptions}
          />

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button
              buttonType="tertiary"
              variant="contained"
              sx={() => ({
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              })}
              onClick={() => handleClose()}
            >
              <Box component="span" sx={{ typography: 'buttonM', letterSpacing: '0.03em' }}>
                Cancel
              </Box>
            </Button>
            <Button
              sx={(theme) => ({
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                borderRadius: theme.spacing(0.75),
                '&:hover': { backgroundColor: 'black', boxShadow: 'none' },
              })}
              buttonType="tertiary"
              variant="contained"
              disableFocusRipple={true}
              disableTouchRipple={true}
              onClick={() => handleSave()}
              disabled={isDisabled}
            >
              <Box component="span" sx={{ typography: 'buttonM', letterSpacing: '0.03em' }}>
                Save
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
