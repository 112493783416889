import { Box } from '@mui/material';

export interface ToggleProps {
  isActive: boolean;
  onClick: () => void;
  size?: 'sm' | 'md' | 'lg';
}

function getInnerDimensions(size: ToggleProps['size']) {
  if (size === 'sm') {
    return {
      edge: '15px',
      height: '14px',
      width: '14px',
    };
  }

  if (size === 'lg') {
    return {
      edge: '23px',
      height: '22px',
      width: '22px',
    };
  }

  return {
    edge: '19px',
    height: '18px',
    width: '18px',
  };
}

function getOuterDimensions(size: ToggleProps['size']) {
  if (size === 'sm') {
    return { height: '20px', width: '32px' };
  }

  if (size === 'lg') {
    return { height: '28px', width: '48px' };
  }

  return { height: '24px', width: '40px' };
}

export const Toggle = ({ isActive, onClick, size = 'md' }: ToggleProps) => {
  const innerDimensions = getInnerDimensions(size);
  const outerDimensions = getOuterDimensions(size);
  return (
    <Box
      onClick={onClick}
      sx={(theme) => ({
        borderRadius: theme.spacing(2.5), // 20px
        backgroundColor: isActive ? theme.palette.stationBlue[600] : theme.palette.stationGray[200],
        height: outerDimensions.height,
        width: outerDimensions.width,
        position: 'relative',
        '&:hover': {
          cursor: 'pointer',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: '#fff',
          borderRadius: theme.spacing(2.5), // 20px
          height: innerDimensions.height,
          width: innerDimensions.width,
          position: 'absolute',
          top: '3px',
          left: isActive ? innerDimensions.edge : '3px',
          [`@keyframes activate-${size}`]: {
            from: {
              left: '3px',
            },
            to: {
              left: innerDimensions.edge,
            },
          },
          [`@keyframes deactivate-${size}`]: {
            to: {
              left: '3px',
            },
            from: {
              left: innerDimensions.edge,
            },
          },
          animation: isActive ? `activate-${size} 0.2s ease-out both` : `deactivate-${size} 0.2s ease-out both`,
        })}
      ></Box>
    </Box>
  );
};
