import { Typography, Box } from '@mui/material';
import { StepIndicatorProps } from '../types';

export const StepIndicator = ({ number, text, width, height }: StepIndicatorProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box
        sx={(theme) => ({
          width: width ? width : 24,
          height: height ? height : 24,
          borderRadius: '50%',
          backgroundColor: theme.palette.common.black,
          color: 'common.white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        <Typography variant="caption" component="div">
          {number}{' '}
        </Typography>
      </Box>
      <Typography
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          typography: 'bodyLRegular',
          textAlign: 'center',
        })}
      >
        {text}
      </Typography>
    </Box>
  );
};
