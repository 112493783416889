import { Box } from '@mui/material';
import React from 'react';
import { Users01Icon16 } from '../../assets';

interface UsersIconProps {
  showBorder?: boolean;
  color?: string;
}

export const UsersIcon = ({ showBorder, color = 'red' }: UsersIconProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        border: `1px solid ${showBorder ? theme.palette.stationGray[200] : 'transparent'}`,
        backgroundColor: color,
        '& svg': {
          stroke: theme.palette.common.white,
          height: 12,
          width: 12,
        },
        '& svg path': {
          stroke: theme.palette.common.white,
        },
      })}
    >
      <Users01Icon16 />
    </Box>
  );
};
