import { useCallback, useState } from 'react';
import { RosterCreateShiftTradePayload } from '@stationwise/share-types';

export const useForceShiftTrade = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [payloads, setPayloads] = useState<RosterCreateShiftTradePayload[]>([]);

  const resetState = useCallback(() => {
    setIsDialogOpen(false);
    setPayloads([]);
  }, []);

  const addPayload = (payload: RosterCreateShiftTradePayload) => setPayloads((prev) => [...prev, payload]);

  return { isDialogOpen, setIsDialogOpen, payloads, addPayload, resetState };
};
