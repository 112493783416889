import { object, TypeOf, z } from 'zod';

// Post time off request DTO
export const postTimeOffRequestSchema = object({
  pay_code: z
    .string({
      required_error: 'Pay code is required.',
    })
    .refine(
      (str) => {
        return str === 'SICK' || str === 'VACATION' || str === 'KELLY' || str === 'EMERGENCY_LEAVE' || str === 'BEREAVEMENT';
      },
      {
        message: 'Invalid time off type. Must be SICK, VACATION, KELLY, EMERGENCY_LEAVE, or BEREAVEMENT.',
      },
    ),
  note: z.string().optional(),
  start_time: z.number(),
  end_time: z.number(),
  time_off_date: z.string({
    required_error: 'Date is required.',
  }),
  assigned_position_id: z.number({
    required_error: 'Position id is required.',
  }),
});
export type PostTimeOffRequestDTO = TypeOf<typeof postTimeOffRequestSchema>;
