import { Box, useTheme } from '@mui/material';
import { getRequestTypeIcon } from '@stationwise/component-module';
import { EmployeeOffView } from '@stationwise/share-types';

export const RequestType = ({ employeeOffData }: { employeeOffData: EmployeeOffView }) => {
  const theme = useTheme();
  return (
    <Box
      sx={(theme) => ({
        borderRadius: theme.spacing(4),
        backgroundColor: getRequestTypeIcon(employeeOffData, theme).backgroundColor || theme.palette.stationGray[400],
        width: theme.spacing(3),
        height: theme.spacing(3),
        mt: '-3px',
        lineHeight: '29px',
        textAlign: 'center',
        '& svg path': {
          stroke: theme.palette.common.white,
          fill: 'transparent',
        },
      })}
    >
      {getRequestTypeIcon(employeeOffData, theme).icon}
    </Box>
  );
};
