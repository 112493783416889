import { Box, Typography, Button } from '@mui/material';
import { FlagIconComponent, NumberListIcon16 } from '@stationwise/component-module';
import { OvertimeInfo } from '../overtimeListHelper';

export const OvertimeListHeader = ({
  overtimeInfo,
  scrollToEmployee,
}: {
  overtimeInfo: OvertimeInfo;
  scrollToEmployee: () => void;
}) => {
  return (
    <Box
      sx={(theme) => ({
        ml: theme.spacing(2),
        mr: theme.spacing(2),
        p: theme.spacing(1.5),
        border: `1px solid ${theme.palette.stationGray[200]}`,
        backgroundColor: theme.palette.common.white,
        borderRadius: '12px',
        mt: theme.spacing(2.5),
      })}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <FlagIconComponent showBorder color={overtimeInfo.shiftColor} />
          <Typography
            variant="bodyMMedium"
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
              ml: theme.spacing(2),
            })}
          >
            {`${overtimeInfo.shiftName.charAt(0).toUpperCase()}${overtimeInfo.shiftName.slice(1)} position`}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="bodyMSemibold"
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
            })}
          >
            {overtimeInfo.shiftPosition}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" sx={(theme) => ({ pt: theme.spacing(1) })}>
        <Box display="flex">
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '20px',
              width: '20px',
              borderRadius: '50%',
              border: `1px solid ${theme.palette.stationGray[200]}`,
              p: '3px',
              mt: '2px',
            })}
          >
            <NumberListIcon16 />
          </Box>
          <Typography
            variant="bodyMMedium"
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
              ml: '16px',
            })}
          >
            Overall position
          </Typography>
          <Button
            data-cy="overtime-show"
            size="small"
            sx={(theme) => ({
              ml: theme.spacing(1.5),
              mt: '-3px',
              borderRadius: '116px',
              height: '32px',
              width: '64px',
              p: '9px 13px',
              border: `1px solid ${theme.palette.stationGray[200]}`,
              backgroundColor: theme.palette.common.white,
              color: theme.palette.stationGray[700],
              '&:focus': {
                backgroundColor: theme.palette.stationGray[50],
              },
            })}
            onClick={scrollToEmployee}
          >
            Show
          </Button>
        </Box>
        <Box>
          <Typography
            variant="bodyMSemibold"
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
            })}
          >
            {overtimeInfo.overallPosition}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
