import { CircularProgress, Box, Button, SxProps } from '@mui/material';
import { makeTestIdentifier } from '@stationwise/share-utils';

export interface FooterButton {
  label: string;
  onClick: () => void;
  backgroundColor?: string;
  color?: string;
  disabled?: boolean;
}

export const DrawerFooter = ({
  loading = false,
  buttons,
  sxProps,
}: {
  loading: boolean;
  sxProps?: SxProps;
  buttons?: FooterButton[];
}) => {
  const handleClick = (buttonOnClick: () => void) => {
    if (!loading) {
      buttonOnClick();
    }
  };
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      sx={(theme) => ({
        height: '76px',
        padding: '16px',
        ...(typeof sxProps === 'function' ? sxProps(theme) : { sxProps }),
      })}
    >
      {buttons && (
        <Box sx={(theme) => ({ width: '100%', mb: theme.spacing(7) })}>
          {buttons.map((b, index) => (
            <Button
              data-cy={`footer-${makeTestIdentifier(b.label)}-button`}
              key={index}
              variant="contained"
              disabled={b.disabled}
              onClick={() => handleClick(b.onClick)}
              sx={(theme) => ({
                background: b.backgroundColor ? b.backgroundColor : theme.palette.stationBlue[700],
                color: b.color ? b.color : theme.palette.common.white,
                width: '100%',
                textTransform: 'none',
                borderRadius: '6px',
                typography: 'bodyMMedium',
              })}
            >
              {loading ? <CircularProgress color="inherit" size="1.5rem" /> : b.label}
            </Button>
          ))}
        </Box>
      )}
    </Box>
  );
};
