import { Box, Typography } from '@mui/material';
import { getShiftColor } from '@stationwise/component-module';
import { Team } from '@stationwise/share-types';

export const TeamColoredBar = ({ shiftTeams, shiftColor }: { shiftTeams: Team[]; shiftColor?: string }) => {
  return (
    <Box
      display="flex"
      width="100%"
      sx={(theme) => ({
        background: getShiftColor(shiftColor || '', theme),
        p: '5px 80px',
        color: theme.palette.common.white,
      })}
      justifyContent="start"
    >
      <Typography
        variant="eyebrow"
        fontWeight="600"
        sx={(theme) => ({
          color: theme.palette.stationGray[50],
        })}
      >
        {shiftTeams.map((team) => team.name.toUpperCase()).join(', ')}
      </Typography>
    </Box>
  );
};
