export const AVATAR_SIZE = 20;

export const HEADER_OPTIONS = [
  {
    label: 'Requests',
  },
  {
    label: 'Messages',
  },
];
