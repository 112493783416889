import { RosterEmployee } from '@stationwise/share-types';
import { copyBoardWithNewApparatus, getOverrideEmployeePositionFields, makePlaceholderEmployee } from './board';
import { checkIsAdministration, checkIsAdministrationPosition, setEmployeeActiveId } from './id';
import { mergeEmployees } from './mergeEmployees';
import { IShiftSummaryHelper } from './types';

export const getPlannedAdministrationPosition = (shiftSummaryHelper: IShiftSummaryHelper, employee: RosterEmployee) => {
  for (const station of shiftSummaryHelper.allStationCards.values()) {
    if (checkIsAdministration(station)) {
      for (const apparatus of station.apparatuses) {
        let positionIndex = 0;
        for (const position of apparatus.positions) {
          if (checkIsAdministrationPosition(position) && `${position.nonShiftEmployee.id}` === employee.id) {
            return { station, apparatus, position, positionIndex };
          }
          positionIndex += 1;
        }
      }
    }
  }

  return { station: null, apparatus: null, position: null, positionIndex: -1 };
};

export const createAdministrationEmployee = (shiftSummaryHelper: IShiftSummaryHelper, employee: RosterEmployee) => {
  const newShiftSummaryHelper = { ...shiftSummaryHelper };
  const { station, apparatus, position, positionIndex } = getPlannedAdministrationPosition(shiftSummaryHelper, employee);
  if (!station || !apparatus || !position) {
    return newShiftSummaryHelper;
  }

  const minStartDateTime = new Date(position.nonShiftEmployee.startDateTime);
  const maxEndDateTime = new Date(position.nonShiftEmployee.endDateTime);
  const oldEmployees = [...position.employees];
  if (!oldEmployees.length) {
    const oldVacancy = makePlaceholderEmployee(position, {
      ...employee,
      startDateTime: minStartDateTime,
      endDateTime: maxEndDateTime,
    });
    oldEmployees.push(oldVacancy);
  }

  const newEmployees: RosterEmployee[] = [];
  for (const e of oldEmployees) {
    if (e.id || e.startDateTime >= employee.endDateTime || e.endDateTime <= employee.startDateTime) {
      newEmployees.push(e);
      continue;
    }

    if (e.startDateTime < employee.startDateTime) {
      newEmployees.push({ ...e, endDateTime: employee.startDateTime });
    }

    const newEmployee = setEmployeeActiveId({
      ...employee,
      dataSource: position.dataSource,
      startDateTime: employee.startDateTime < minStartDateTime ? minStartDateTime : employee.startDateTime,
      endDateTime: employee.endDateTime > maxEndDateTime ? maxEndDateTime : employee.endDateTime,
      payCodes: [employee.defaults.regularAssignmentPayCode],
      detailCodes: [],
      ...getOverrideEmployeePositionFields(),
    });
    newEmployees.push(newEmployee);

    if (e.endDateTime > employee.endDateTime) {
      newEmployees.push({ ...e, startDateTime: employee.endDateTime });
    }
  }

  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(newShiftSummaryHelper, apparatus.id);
  newApparatus.positions[positionIndex].employees = mergeEmployees(newEmployees);
  return { ...newShiftSummaryHelper, allStationCards: newAllStationCards };
};
