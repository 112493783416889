import { format } from 'date-fns';
import {
  PayCode,
  DetailCode,
  RosterPosition,
  RosterEmployee,
  RosterStrikeTeamApparatus,
  StrikeTeamCandidate,
} from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import {
  checkIsPlannedEmployee,
  copyBoardWithNewApparatus,
  getBoardEmployees,
  getOverrideEmployeePositionFields,
  makePlaceholderEmployee,
} from '../board';
import { checkIsEvent, checkIsStrikeTeam, checkIsStrikeTeamApparatus, setEmployeeActiveId } from '../id';
import { getMandatoryPayCodes } from '../payCode';
import { removeEmployeeAvailability } from '../removeEmployeeAvailability';
import { mergeConsecutiveWorkDurations, getPositionRequirementErrorMessages } from '../requirement';
import { IShiftSummaryHelper } from '../types';
import { getStrikeTeamFutureActiveDurations } from './active';

export const checkStrikeTeamCandidateMaxConsecutiveHours = (
  shiftSummaryHelper: IShiftSummaryHelper,
  apparatus: RosterStrikeTeamApparatus,
  duration: Pick<RosterPosition, 'startDateTime' | 'endDateTime'>,
  candidate: Pick<RosterEmployee, 'id' | 'maybeConsecutiveWorkDurations'>,
  toDate: string,
) => {
  const { maxConsecutiveHoursRule, shiftDuration } = shiftSummaryHelper;
  if (typeof maxConsecutiveHoursRule.consecutiveHours === 'number') {
    const maxConsecutiveHours = maxConsecutiveHoursRule.consecutiveHours;
    const consecutiveWorkDurations = mergeConsecutiveWorkDurations(
      candidate.maybeConsecutiveWorkDurations || [],
      [duration, ...getBoardEmployees(shiftSummaryHelper).filter((e) => e.id === candidate.id)],
      getStrikeTeamFutureActiveDurations(apparatus, duration, toDate),
      shiftDuration,
      maxConsecutiveHoursRule.breakHours ?? 0,
    );
    return consecutiveWorkDurations.filter((d) => d.hours > maxConsecutiveHours);
  }

  return [];
};

const checkIsStrikeTeamAssignmentValid = (
  shiftSummaryHelper: IShiftSummaryHelper,
  assignedApparatus: RosterStrikeTeamApparatus,
  assignedPosition: RosterPosition,
  assignee: RosterEmployee,
) => {
  for (const station of shiftSummaryHelper.allStationCards.values()) {
    for (const apparatus of station.apparatuses) {
      for (const position of apparatus.positions) {
        for (const employee of position.employees) {
          if (employee.id && employee.id === assignee.id) {
            if (employee.startDateTime < assignee.endDateTime && employee.endDateTime > assignee.startDateTime) {
              if (checkIsStrikeTeam(position)) {
                return { messages: ['This person is already assigned to an event'] };
              }
            }
          }
        }
      }
    }
  }

  const messages: string[] = [];
  messages.push(...getPositionRequirementErrorMessages(shiftSummaryHelper, assignedPosition, assignee));

  const { maxConsecutiveHoursRule, shiftDuration } = shiftSummaryHelper;
  const maxConsecutiveHourErrors = checkStrikeTeamCandidateMaxConsecutiveHours(
    shiftSummaryHelper,
    assignedApparatus,
    { startDateTime: assignee.startDateTime, endDateTime: assignee.endDateTime },
    assignee,
    format(shiftDuration.startTime, 'yyyy-MM-dd'),
  );
  if (maxConsecutiveHourErrors.length && typeof maxConsecutiveHoursRule.consecutiveHours === 'number') {
    const consecutiveWorkHours = Math.max(...maxConsecutiveHourErrors.map((d) => d.hours));
    const message = `This person is working ${consecutiveWorkHours} consecutive hours, which exceeds the maximum ${maxConsecutiveHoursRule.consecutiveHours} hours`;
    messages.push(message);
  }

  return !messages.length ? undefined : { messages, canOverride: true };
};

export const assignStrikeTeamEmployee = (
  shiftSummaryHelper: IShiftSummaryHelper,
  apparatus: RosterStrikeTeamApparatus,
  position: RosterPosition | null,
  candidate: StrikeTeamCandidate,
  startDateTime: Date,
  endDateTime: Date,
  toDate: string,
  payCodes: PayCode[],
  dutyDayPayCodes: PayCode[] | null,
  detailCodes: DetailCode[],
) => {
  let newShiftSummaryHelper = removeEmployeeAvailability({
    shiftSummaryHelper,
    employeeId: `${candidate.id}`,
    startTime: startDateTime,
    endTime: endDateTime,
    // Do not remove employee availability from event positions.
    // An employee cannot be assigned to a strike team if they are already assigned to an event during the same time.
    // In this case the admin should see two event cards for the same employee, and the error popover.
    checkIsPositionExcluded: (p) => checkIsEvent(p),
  });

  const newEmployee = setEmployeeActiveId({
    id: `${candidate.id}`,
    dataSource: apparatus.dataSource,
    name: candidate.name,
    rank: candidate.rank,
    certifications: candidate.certifications,
    team: candidate.team,
    defaults: candidate.defaults,
    startDateTime,
    endDateTime,
    payCodes,
    detailCodes,
    ...getOverrideEmployeePositionFields(),
    maybeConsecutiveWorkDurations: candidate.maybeConsecutiveWorkDurations,
  });

  if (dutyDayPayCodes && checkIsPlannedEmployee(newShiftSummaryHelper, newEmployee)) {
    newEmployee.payCodes = dutyDayPayCodes;
  }
  newEmployee.payCodes = position ? getMandatoryPayCodes(newShiftSummaryHelper, position, newEmployee) : newEmployee.payCodes;

  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(newShiftSummaryHelper, apparatus.id);

  let newPosition = position;
  if (position) {
    newPosition = newApparatus.positions.find((p) => p.id === position.id) || null;
  } else {
    newPosition = {
      id: `${apparatus.id}|${newEmployee.activeId}`,
      dataSource: apparatus.dataSource,
      startDateTime,
      endDateTime,
      rank: candidate.rank,
      certifications: [],
      isTemporary: true,
      driver: false,
      employees: [],
    };
    newApparatus.positions.push(newPosition);
  }

  if (!checkIsStrikeTeamApparatus(newApparatus) || !newPosition) {
    throw new Error('Cannot assign strike team employee');
  }

  const newEmployees: RosterEmployee[] = [];
  if (newPosition.employees.length === 0) {
    newEmployees.push(
      makePlaceholderEmployee(newPosition, {
        ...newEmployee,
        startDateTime: newPosition.startDateTime,
        endDateTime: startDateTime,
      }),
      makePlaceholderEmployee(newPosition, {
        ...newEmployee,
        startDateTime: endDateTime,
        endDateTime: newPosition.endDateTime,
      }),
    );
  }

  newPosition.employees.forEach((employee) => {
    if (employee.startDateTime >= endDateTime || employee.endDateTime <= startDateTime) {
      newEmployees.push(employee);
      return;
    }

    if (employee.id) {
      throw new Error('Cannot assign strike team employee');
    }

    newEmployees.push(
      makePlaceholderEmployee(newPosition, { ...employee, endDateTime: startDateTime }),
      makePlaceholderEmployee(newPosition, { ...employee, startDateTime: endDateTime }),
    );
  });

  newEmployees.push(newEmployee);

  newPosition.employees = newEmployees
    .filter((e) => differenceInUTCMinutes(e.endDateTime, e.startDateTime) > 0)
    .sort((a, b) => differenceInUTCMinutes(a.startDateTime, b.startDateTime));

  newApparatus.strikeTeamAssignmentPayloadMap = new Map(newApparatus.strikeTeamAssignmentPayloadMap);
  newApparatus.strikeTeamAssignmentPayloadMap.set(newPosition.id, [
    ...(newApparatus.strikeTeamAssignmentPayloadMap.get(newPosition.id) || []),
    {
      positionId: newPosition.isTemporary ? null : newPosition.id,
      candidateId: candidate.id,
      strikeTeamId: apparatus.strikeTeam.id,
      startTime: differenceInUTCMinutes(newEmployee.startDateTime, newShiftSummaryHelper.shiftDuration.startTime),
      endTime: differenceInUTCMinutes(newEmployee.endDateTime, newShiftSummaryHelper.shiftDuration.startTime),
      toDate,
      payCodeIds: newEmployee.payCodes.map((pc) => pc.id),
      dutyDayPayCodeIds: dutyDayPayCodes?.map((pc) => pc.id) || null,
      detailCodeIds: detailCodes.map((dc) => dc.id),
    },
  ]);

  const error = checkIsStrikeTeamAssignmentValid(newShiftSummaryHelper, newApparatus, newPosition, newEmployee);
  newShiftSummaryHelper = { ...newShiftSummaryHelper, allStationCards: newAllStationCards };
  return { newShiftSummaryHelper, newEmployee, error };
};
