import { Box, Typography } from '@mui/material';
import { NoShiftTrades as NoShiftTradesImg, BlankCard } from '@stationwise/component-module';

export const NoShiftTrades = () => {
  return (
    <BlankCard type="passive" sxProps={{ border: 'none', padding: 2, boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)' }}>
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <NoShiftTradesImg />
        <Box sx={(theme) => ({ mt: theme.spacing(3) })}>
          <Typography variant="heading4">No shift trades</Typography>
        </Box>
        <Box
          sx={(theme) => ({
            width: '100%',
            textAlign: 'center',
            mb: theme.spacing(3),
          })}
        >
          <Box
            sx={(theme) => ({
              color: theme.palette.stationGray[500],
              width: '286px',
              mt: theme.spacing(2.5),
              display: 'inline-block',
            })}
          >
            <Typography variant="bodyMRegular">When someone trades a shift, you will see it here.</Typography>
          </Box>
        </Box>
      </Box>
    </BlankCard>
  );
};
