/* include "+1" (country code for USA) to the phone string */
export const formatPhoneNumber = (phone: string | null): string => {
  if (phone === null) {
    return '';
  }
  if (phone.length === 10 && !phone.startsWith('+1')) {
    return `+1${phone}`;
  }

  return phone;
};

/* Remove the leading "+1" (country code for USA) from phone string */
export const unformatPhoneNumber = (phone: string | null): string => {
  if (!phone) return '';
  if (phone.startsWith('+1') && phone.length === 12) {
    return phone.substring(2);
  }

  return phone;
};
