import { Theme } from '@mui/material';

export interface GetComponentColorsProps {
  theme: Theme;
  color?: string;
  dark?: boolean;
}

export const getPalette = ({ theme, color: colorName = 'gray' }: GetComponentColorsProps) => {
  switch (colorName.toLowerCase()) {
    case 'rose':
    case 'red':
      return theme.palette.stationRed;
    case 'pink':
      return theme.palette.stationPink;
    case 'orange':
      return theme.palette.stationOrange;
    case 'gold':
    case 'yellow':
      return theme.palette.stationElectric;
    case 'teal':
    case 'green':
      return theme.palette.stationGreen;
    case 'cyan':
      return theme.palette.stationCyan;
    case 'lightblue':
      return theme.palette.stationLightBlue;
    case 'blue':
      return theme.palette.stationBlue;
    case 'indigo':
      return theme.palette.stationIndigo;
    case 'violet':
    case 'purple':
      return theme.palette.stationPurple;
    case 'gray':
    default:
      return theme.palette.stationGray;
  }
};

export const getRankColors = ({ theme, color: colorName = 'gray', dark = false }: GetComponentColorsProps) => {
  switch (colorName.toLowerCase()) {
    case 'rose':
    case 'red': {
      const background = dark ? theme.palette.stationRed[900] : theme.palette.stationRed[100];
      const color = dark ? theme.palette.stationRed[300] : theme.palette.stationRed[800];
      return { background, color };
    }
    case 'pink': {
      const background = dark ? theme.palette.stationPink[900] : theme.palette.stationPink[100];
      const color = dark ? theme.palette.stationPink[300] : theme.palette.stationPink[800];
      return { background, color };
    }
    case 'orange': {
      const background = dark ? theme.palette.stationOrange[900] : theme.palette.stationOrange[100];
      const color = dark ? theme.palette.stationOrange[300] : theme.palette.stationOrange[800];
      return { background, color };
    }
    case 'gold':
    case 'yellow': {
      const background = dark ? theme.palette.stationElectric[800] : theme.palette.stationElectric[300];
      const color = dark ? theme.palette.stationElectric[200] : theme.palette.stationElectric[800];
      return { background, color };
    }
    case 'teal':
    case 'green': {
      const background = dark ? theme.palette.stationGreen[900] : theme.palette.stationGreen[100];
      const color = dark ? theme.palette.stationGreen[300] : theme.palette.stationGreen[800];
      return { background, color };
    }
    case 'cyan': {
      const background = dark ? theme.palette.stationCyan[900] : theme.palette.stationCyan[100];
      const color = dark ? theme.palette.stationCyan[300] : theme.palette.stationCyan[800];
      return { background, color };
    }
    case 'blue': {
      const background = dark ? theme.palette.stationBlue[900] : theme.palette.stationBlue[100];
      const color = dark ? theme.palette.stationBlue[300] : theme.palette.stationBlue[800];
      return { background, color };
    }
    case 'indigo': {
      const background = dark ? theme.palette.stationIndigo[900] : theme.palette.stationIndigo[100];
      const color = dark ? theme.palette.stationIndigo[200] : theme.palette.stationIndigo[800];
      return { background, color };
    }
    case 'violet':
    case 'purple': {
      const background = dark ? theme.palette.stationPurple[900] : theme.palette.stationPurple[100];
      const color = dark ? theme.palette.stationPurple[200] : theme.palette.stationPurple[800];
      return { background, color };
    }
    case 'print': {
      const background = 'transparent';
      const color = theme.palette.common.black;
      return { background, color };
    }
    case 'gray':
    default: {
      const background = dark ? theme.palette.stationGray[700] : theme.palette.stationGray[100];
      const color = dark ? theme.palette.stationGray[300] : theme.palette.stationGray[800];
      return { background, color };
    }
  }
};

export const getCertColors = ({ theme, color: colorName = 'gray', dark = false }: GetComponentColorsProps) => {
  const palette = getPalette({ theme, color: colorName });
  const values = {
    background: dark ? 'transparent' : theme.palette.common.white,
    color: dark ? palette[300] : palette[600],
  };
  switch (palette) {
    case theme.palette.stationRed:
    case theme.palette.stationPink:
    case theme.palette.stationOrange:
      values.color = dark ? palette[400] : palette[500];
      return values;
    case theme.palette.stationElectric:
      values.color = dark ? palette[300] : palette[700];
      return values;
    case theme.palette.stationGreen:
    case theme.palette.stationCyan:
      values.color = dark ? palette[400] : palette[600];
      return values;
    case theme.palette.stationLightBlue:
    case theme.palette.stationBlue:
    case theme.palette.stationIndigo:
    case theme.palette.stationPurple:
      values.color = dark ? palette[300] : palette[500];
      return values;
    default:
      return values;
  }
};

export const getAvatarColors = ({ theme, color: colorName = 'gray', dark = false }: GetComponentColorsProps) => {
  const palette = getPalette({ theme, color: colorName });
  const values = {
    background: dark ? palette[400] : palette[500],
    color: theme.palette.common.white,
  };
  switch (palette) {
    case theme.palette.stationCyan:
      values.background = dark ? palette[400] : palette[700];
      return values;
    default:
      return values;
  }
};
