import { Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { theme } from '@stationwise/component-module';
import { PayrollData } from '@stationwise/share-types';
import { PayrollTable } from './PayrollTable';

interface PayrollValidationModalContentProps {
  userName: string;
  setIsButtonActive: (isButtonActive: boolean) => void;
  formattedDate: string;
  days: Date[];
  payroll: PayrollData;
}

export const PayrollValidationModalContent = ({
  userName,
  setIsButtonActive,
  formattedDate,
  days,
  payroll,
}: PayrollValidationModalContentProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setIsButtonActive(value.toLowerCase() === userName.toLowerCase());
  };

  return (
    <Box
      sx={{
        height: '100vh',
        paddingX: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            color: theme.palette.stationGray[900],
            typography: 'bodyLMedium',
            marginTop: theme.spacing(2),
          }}
        >
          Time card for {formattedDate}
        </Box>
        <PayrollTable days={days} payroll={payroll} />
      </Box>
      <Typography
        sx={{
          marginTop: theme.spacing(3),
          color: theme.palette.stationGray[900],
          typography: 'bodyLMedium',
        }}
      >
        Type full name
      </Typography>
      <TextField
        fullWidth
        placeholder={userName}
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        sx={{
          marginTop: theme.spacing(1.5),
        }}
        InputProps={{
          sx: {
            typography: 'bodyMRegular',
          },
        }}
      />
    </Box>
  );
};
