import { Box, Modal } from '@mui/material';
import { useState } from 'react';
import { ExclamationMarkIcon24 } from '../../../../assets';
import { ShiftTradeCancelButtons } from './ShiftTradeCancelButtons';
import { RefundOptType, ShiftTradeRefundOptions } from './ShiftTradeRefundOptions';

interface OverrideModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  selectedButton: string;
  handleButtonClick: (arg0: RefundOptType) => void;
}

export const ShiftTradeModal = ({ isOpen, onClose, onSubmit, selectedButton, handleButtonClick }: OverrideModalProps) => {
  const [step, setStep] = useState(1);
  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={isOpen}
    >
      <Box
        /* Prevent drags and clicks from propagating up to the anchor element. */
        onKeyDown={(e) => (e.code === 'Space' || e.code === 'Enter') && e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          width: '428px',
          height: 'auto',
          justifyContent: 'center',
          backgroundColor: theme.palette.common.white,
          p: theme.spacing(3),
          borderRadius: 3,
        })}
      >
        {step === 1 && (
          <>
            <Box sx={{ typography: 'bodyXLSemibold', display: 'flex' }}>Cancel shift trade</Box>
            <ShiftTradeRefundOptions selectedButton={selectedButton} handleButtonClick={handleButtonClick} />
          </>
        )}
        {step === 2 && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={(theme) => ({
                '& svg path': { stroke: theme.palette.stationOrange[500] },
                borderRadius: '80px',
                borderColor: theme.palette.stationGray[50],
                boxShadow: '0px 2px 4px -1px rgba(10, 14, 22, 0.06), 0px 4px 6px -1px rgba(10, 14, 22, 0.10)',
                width: '56px',
                height: '56px',
                p: theme.spacing(2),
                mb: theme.spacing(3),
              })}
            >
              <ExclamationMarkIcon24 />
            </Box>
            <Box sx={{ typography: 'bodyXLSemibold' }}>Do you wish to cancel the approved shift trade? </Box>
            <Box sx={(theme) => ({ typography: 'bodyMRegular', my: theme.spacing(1) })}>
              User will be removed from shift for this day.
            </Box>
          </Box>
        )}
        <ShiftTradeCancelButtons onClose={onClose} onSubmit={onSubmit} step={step} setStep={setStep} />
      </Box>
    </Modal>
  );
};
