import { Box } from '@mui/material';
import React from 'react';
import { ChipFilter, ChipFilterProps, SearchInput } from '@stationwise/component-module';

export interface FiltersProps {
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue?: string;
  filters?: ChipFilterProps[];
}

export const Filters = ({ setSearchValue, searchValue, filters }: FiltersProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <SearchInput value={searchValue || ''} setValue={setSearchValue} color="white" />
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          padding: '24px 0px',
        })}
      >
        {filters?.map((filter, index) =>
          filter?.amount && filter.amount >= 0 ? (
            <ChipFilter
              key={`${filter.label}-${index}`}
              action={filter.action}
              amount={filter.amount}
              icon={filter.icon}
              isSelected={filter.isSelected}
              label={filter.label}
            />
          ) : undefined,
        )}
      </Box>
    </Box>
  );
};
