import { createContext, useContext, ReactNode, useState, useEffect } from 'react';

interface DeviceInfoValue {
  isIOS: boolean;
  isStandalone: boolean;
  paddingBottom: string;
}

const DeviceInfo = createContext<DeviceInfoValue | undefined>(undefined);

export function useDeviceInfo() {
  const context = useContext(DeviceInfo);
  if (context === undefined) {
    throw new Error('useDeviceInfo must be used within a DeviceInfoProvider');
  }
  return context;
}

interface DeviceInfoProviderProps {
  children: ReactNode;
}

export function DeviceInfoProvider({ children }: DeviceInfoProviderProps) {
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent?.toLowerCase() || '';
    if (/safari/.test(userAgent) && /ip(hone|ad|od)/.test(userAgent)) {
      setIsIOS(true);
    }
  }, []);

  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  const paddingBottom = isIOS && isStandalone ? '30px' : '0px';

  return <DeviceInfo.Provider value={{ isIOS, isStandalone, paddingBottom }}>{children}</DeviceInfo.Provider>;
}
export function useIsIOSWebView() {
  const context = useDeviceInfo();
  return (context?.isIOS || false) && (context?.isStandalone || false);
}

export function useIOSPaddingBottom() {
  const context = useDeviceInfo();
  return context?.paddingBottom || 'none';
}
