import { useDocumentTitle, CAPABILITIES } from '@stationwise/component-module';
import { AppPageLayout } from '../../AppPageLayout';
import { DepartmentSettingsPage } from './components/DepartmentSettingsPage';

export const DepartmentSettings = () => {
  useDocumentTitle('Department Settings');
  return (
    <AppPageLayout requiredCapability={CAPABILITIES.EDIT_DEPARTMENT_SETTINGS}>
      <DepartmentSettingsPage />
    </AppPageLayout>
  );
};
