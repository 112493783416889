import { Box } from '@mui/material';
import { BlankCard } from '@stationwise/component-module';

interface Props {
  isReplacementNeeded: boolean;
}

export const FloaterCard = ({ isReplacementNeeded }: Props) => {
  return (
    <BlankCard
      sxProps={() => ({
        border: 'none',
        '&:hover': {
          border: 'none',
        },
        height: 'inherit',
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'grid',
          color: theme.palette.stationGray[500],
          alignItems: 'center',
          textAlign: 'center',
          height: '100%',
          typography: 'bodySMedium',
        })}
      >
        {isReplacementNeeded ? 'Replacement pending...' : 'No replacement needed'}
      </Box>
    </BlankCard>
  );
};
