import { Input } from '../Input';
import { TextMaskCustom } from './TextMaskCustom';
import { unformatPhoneNumber } from './helpers';

interface PhoneInputProps {
  handleChange: (value: string) => void;
  phone: string | null;
}

export const PhoneInput = ({ handleChange, phone }: PhoneInputProps) => {
  return (
    <Input
      value={unformatPhoneNumber(phone)}
      onChange={(event) => handleChange(event.target.value)}
      name="textmask"
      placeholder="(100) 000-0000"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputComponent={TextMaskCustom as any}
    />
  );
};
