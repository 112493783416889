import { Box } from '@mui/material';
import { User02Icon16 } from '../../../../assets';
import { Counter, CounterProps } from '../../../Counter';

export const StaffingCounter = ({ total, amount }: CounterProps) => {
  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Box component={User02Icon16} sx={(theme) => ({ color: theme.palette.stationGray[400] })} />
      <Counter total={total} amount={amount} mode="dark" />
    </Box>
  );
};
