import { Box } from '@mui/material';
import { useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { DashboardContent } from './DashboardContent';

export const DashboardMain = () => {
  useDocumentTitle('Dashboard');
  return (
    <AppPageLayout>
      <Box
        display="flex"
        flexDirection="column"
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          height: '100%',
          width: '100%',
          p: 0,
          m: 0,
        })}
      >
        <DashboardContent />
      </Box>
    </AppPageLayout>
  );
};
