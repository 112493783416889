const allowedColors = [
  'black',
  'blue',
  'brown',
  'gold',
  'gray',
  'green',
  'indigo',
  'orange',
  'pink',
  'purple',
  'red',
  'rose',
  'teal',
  'violet',
  'white',
  'yellow',
] as const;

export type AllowedColors = (typeof allowedColors)[number];

export const allowedColorsList: AllowedColors[] = [...allowedColors];
