import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { ScheduledShiftsDataView } from '@stationwise/share-types';

export const fetchShiftsData = async (
  startDate: string,
  endDate: string,
  battalionId?: number,
): Promise<ScheduledShiftsDataView[] | []> => {
  try {
    const response = await client.get('shift/scheduled/', {
      params: { startDate, endDate, battalionId },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error fetching data');
    }
  } catch (err) {
    captureException(err);
    throw err;
  }
};

export const useFetchShiftsData = (startDate: string, endDate: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<ScheduledShiftsDataView[] | []>([]);
  const [fetchCounter, setFetchCounter] = useState(0);

  const fetchShiftsDataCallback = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const fetchedData = await fetchShiftsData(startDate, endDate);
      setData(fetchedData);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    fetchShiftsDataCallback();
  }, [fetchShiftsDataCallback, fetchCounter]);

  const refetchData = () => {
    setFetchCounter((prev) => prev + 1);
  };

  return {
    isLoading,
    isError,
    data,
    refetchData,
  };
};
