import { Box } from '@mui/material';
import { ErrorPage, LottieLogo, useDocumentTitle, useFetchRosterData } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { ScheduleContent } from './ScheduleContent';

export const ScheduleMain = () => {
  useDocumentTitle('Roster');
  const {
    isLoading,
    isError,
    selectedBattalionState,
    isErrorStaffingLists,
    shiftSummary,
    staffingLists,
    currentDate,
    forceRefetchAll,
    administrationStations,
    eventStations,
    temporaryNonShiftStation,
    dataEmployeesOff,
    dataAutoHire,
    bulkCalls,
  } = useFetchRosterData();

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
          <LottieLogo height="200px" width="200px" />
        </Box>
      );
    }

    if (isError || !shiftSummary || !administrationStations || !eventStations || !temporaryNonShiftStation || !dataEmployeesOff) {
      return <ErrorPage />;
    }

    return (
      <ScheduleContent
        selectedBattalionState={selectedBattalionState}
        shiftSummary={shiftSummary}
        administrationStations={administrationStations}
        eventStations={eventStations}
        temporaryNonShiftStation={temporaryNonShiftStation}
        currentDate={currentDate}
        staffingListsResponse={{ data: staffingLists, isError: isErrorStaffingLists }}
        forceRefetch={forceRefetchAll}
        bulkCalls={bulkCalls}
        employeesOff={dataEmployeesOff}
        autoHireInfo={dataAutoHire}
      />
    );
  };

  return (
    <AppPageLayout className="SWSchedule-main">
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          height: '100%',
          width: '100%',
          p: 0,
          m: 0,
        }}
      >
        {renderContent()}
      </Box>
    </AppPageLayout>
  );
};
