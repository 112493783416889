import { useLoadedDepartmentInfoContext } from '../components/Department';
import { useLocalStorageState } from './useLocalStorageState';

export const useSelectedBattalionPreference = () => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const battalions = departmentInfoState.departmentInfo.battalions;

  return useLocalStorageState<number | undefined>({
    key: 'roster.preference.selectedBattalionId',
    parse: (value) => {
      const battalion = battalions.find((b) => `${b.id}` === value) ?? battalions[0];
      return battalion?.id;
    },
    stringify: (value) => JSON.stringify(String(value || '')),
  });
};
