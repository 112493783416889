import { Box } from '@mui/material';
import { format } from 'date-fns';
import { Fragment, useState } from 'react';
import { ChevronRightIcon16, FlagIconComponent as FlagIcon, useRosterContext } from '@stationwise/component-module';
import { EmployeeOffView } from '@stationwise/share-types';
import { checkIsEvent } from '@stationwise/shift-summary-helper';
import { Cell, COLUMNS } from './Cell';
import { PositionRows } from './PositionRows';
import { Row } from './Row';

export const PrintDaySchedule = () => {
  const { preferences, employeesOffState, shiftSummaryHelper } = useRosterContext();
  const [stations] = useState(() => Array.from(shiftSummaryHelper.allStationCards.values()));

  const selectedStations = stations.filter(
    (station) =>
      (preferences.selectedStationsMap.get(preferences.selectedBattalionId || -1) || new Map()).get(station.stationName) ||
      (checkIsEvent(station) &&
        (preferences.selectedStationsMap.get(preferences.selectedBattalionId || -1) || new Map()).get('Event Groups')),
  );
  const [positionEmployeesOffMap] = useState(() => {
    const map = new Map<string, EmployeeOffView[]>();
    employeesOffState.employeesOff.forEach((employeeOffData) => {
      employeeOffData.positions.forEach((positionId) => {
        const key = positionId.toString();
        const value = map.get(key) || [];
        !map.has(key) && map.set(key, value);
        value.push(employeeOffData);
      });
    });
    return map;
  });

  return (
    <Box sx={{ backgroundColor: 'white' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <Box component="h1" sx={{ m: 0, typography: 'bodyXXLSemibold', lineHeight: '28px' }}>
          Schedule for {format(shiftSummaryHelper.shiftDuration.startTime, 'MMMM dd, yyyy')}
        </Box>
        <Box sx={{ flex: 1 }} />
        <Box sx={{ display: 'inline-flex', '& svg': { width: '16px', height: '16px' } }}>
          <FlagIcon color={shiftSummaryHelper.shiftColor} showBorder={true} width="28px" height="28px" />
        </Box>
        {shiftSummaryHelper.currentTeams.map((team) => team.name).join(', ')}
      </Box>
      <Row>
        {COLUMNS.map((column, colIndex) => (
          <Cell key={colIndex} colIndex={colIndex} scope="head" sx={{ py: 1 }}>
            {column.name}
          </Cell>
        ))}
      </Row>
      {selectedStations.map((station) => (
        <Fragment key={station.stationId}>
          {station.apparatuses.map((apparatus) => (
            <Fragment key={apparatus.id}>
              {!apparatus.isForShiftLeader && (
                <Row
                  className="SWPrintDaySchedule-circled-row"
                  sx={(theme) => ({ background: theme.palette.stationGray[100], borderColor: theme.palette.stationGray[100] })}
                >
                  <Cell colIndex={0} scope="head" sx={{ py: 0.5, width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, '& svg path': { stroke: 'currentColor' } }}>
                      {station.stationName}
                      <ChevronRightIcon16 />
                      {apparatus.name}
                    </Box>
                  </Cell>
                </Row>
              )}
              {apparatus.positions.map((position) => (
                <PositionRows
                  key={position.id}
                  apparatus={apparatus}
                  position={position}
                  employeesOff={positionEmployeesOffMap.get(position.id) || []}
                />
              ))}
              <Row
                className="SWPrintDaySchedule-lined-row"
                sx={(theme) => ({
                  height: theme.spacing(3),
                  '&:last-of-type': { maxHeight: 0, overflow: 'hidden' },
                })}
              >
                <Cell colIndex={0} sx={{ width: '100%' }} />
              </Row>
            </Fragment>
          ))}
        </Fragment>
      ))}
    </Box>
  );
};
