import { DayHeaderContentArg } from '@fullcalendar/core';
import { useTheme, Box } from '@mui/material';

export const DayHeaderContent = ({ dayHeaderContentArg }: { dayHeaderContentArg: DayHeaderContentArg }) => {
  const theme = useTheme();
  const weekDay = dayHeaderContentArg.text.split(' ')[0];
  const date = dayHeaderContentArg.text.split(' ')[1];
  const isListView = dayHeaderContentArg.view.type === 'listMonth';

  return (
    <Box
      sx={{
        height: isListView ? undefined : '60px',
        width: '50px',
        mt: isListView ? '8px' : undefined,
      }}
    >
      {isListView && (
        <Box
          className="day-header-weekday"
          sx={(theme) => ({
            color: theme.palette.stationGray[900],
            mb: 0.5,
            typography: 'bodyMMedium',
          })}
        >
          {weekDay}
        </Box>
      )}
      {!isListView && (
        <Box className="day-header-weekday" sx={{ position: 'relative', left: '-5px', height: '33px' }}>
          {weekDay}
        </Box>
      )}
      <Box
        className={isListView ? 'day-header-list' : 'day-header-date'}
        style={{
          padding: isListView ? undefined : '2px',
          color: dayHeaderContentArg.isToday ? theme.palette.common.white : '',
        }}
        sx={{
          width: isListView ? undefined : '28px',
          height: isListView ? undefined : '28px',
          borderRadius: '50%',
          mt: isListView ? undefined : '6px',
          ml: isListView ? undefined : '5px',
          backgroundColor: dayHeaderContentArg.isToday ? theme.palette.stationGray[900] : '',
        }}
      >
        {date}
      </Box>
    </Box>
  );
};
