import { Box, Container, Typography } from '@mui/material';
import { useDocumentTitle } from '../../hooks';
import { Button } from '../Button';

interface ErrorPageProps {
  title?: string;
  subtitle?: string;
  body?: string;
}

export const ErrorPage = ({ title, subtitle, body }: ErrorPageProps) => {
  useDocumentTitle('Oops! An Error Occurred');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '90vh',
      }}
    >
      <Container component="main" sx={{ mt: 8, mb: 2, ml: 2 }} maxWidth="lg">
        <Typography variant="h1" component="h1" gutterBottom>
          {title || 'Oops!'}
        </Typography>
        <Typography variant="h5" component="h2" sx={{ mb: 3 }} gutterBottom>
          {subtitle || "There's an error and we are working on it"}
        </Typography>
        <Typography variant="body1" paddingBottom="10px">
          {body || 'Contact support if the issue persists'}
        </Typography>
        <Button href="/" buttonType="dark">
          Go to Dashboard
        </Button>
      </Container>
    </Box>
  );
};
