import { useState } from 'react';
import { useAuthUserContext } from '@stationwise/component-module';
import { OvertimeListView } from '@stationwise/share-types';
import { buildOvertimeInfoObject, OvertimeInfo } from '../overtimeListHelper';
import { OvertimeList } from './OvertimeList';
import { OvertimeListHeader } from './OvertimeListHeader';

export const MandatoryOvertimeListContent = ({ overtimeList }: { overtimeList: OvertimeListView[] }) => {
  const [scroll, setScroll] = useState<boolean>(false);

  const { state } = useAuthUserContext();
  const { employee } = state;

  const overtimeInfo: OvertimeInfo = buildOvertimeInfoObject(overtimeList, employee);

  return (
    <>
      <OvertimeListHeader overtimeInfo={overtimeInfo} scrollToEmployee={() => setScroll(true)} />
      <OvertimeList overtimeInfo={overtimeInfo} setScroll={setScroll} scroll={scroll} />
    </>
  );
};
