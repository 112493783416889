import { Box } from '@mui/material';
import { BigArrowLeftIcon16 } from '@stationwise/component-module';

export const SwapArrow = () => {
  return (
    <Box sx={(theme) => ({ display: 'flex', justifyContent: 'center', height: theme.spacing(1.5) })}>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: '50%',
          border: `1px solid ${theme.palette.stationGray[100]}`,
          width: theme.spacing(3),
          height: theme.spacing(3),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          top: '50%',
          transform: 'translateY(-50%)',
          '& svg': { transform: 'rotate(-90deg)' },
        })}
      >
        <BigArrowLeftIcon16 />
      </Box>
    </Box>
  );
};
