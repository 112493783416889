import { Box } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { checkIsCollapsedApparatus, getBoardEmployee, makeOvertimeEmployeeActiveId } from '@stationwise/shift-summary-helper';
import { FloaterCard } from '../FloatersBar';
import { HireBackOperatorCard } from '../HireBackList';
import { useRosterContext } from '../RosterContext';
import { RosterAssignedEmployeeCard } from '../RosterPosition';

export const DragOverlayCard = () => {
  const { draggingId, preferences, shiftSummaryHelper, staffingListsState } = useRosterContext();

  useEffect(() => {
    draggingId && document.body.classList.add('SWDragOverlay-active');
    return () => {
      !draggingId && document.body.classList.remove('SWDragOverlay-active');
    };
  }, [draggingId]);

  if (!draggingId) {
    return null;
  }

  const renderCard = (card: ReactNode) => (
    <Box
      className="SWDragOverlayCard-root"
      sx={(theme) => ({
        position: 'relative',
        height: '100%',
        width: '100%',
        cursor: 'grabbing',
        '.SWDragOverlay-active &': {
          opacity: 0.8,
          transform: 'scale(1.2)',
        },
      })}
    >
      {card}
    </Box>
  );

  const unassignedEmployee = shiftSummaryHelper.unassignedCards.find((employee) => draggingId === employee.activeId);
  if (unassignedEmployee) {
    return renderCard(<FloaterCard card={unassignedEmployee} />);
  }

  const staffingListItem = staffingListsState.selectedStaffingList?.items?.find((item) => {
    return draggingId === makeOvertimeEmployeeActiveId(item.employee.id);
  });
  if (staffingListItem) {
    return renderCard(<HireBackOperatorCard operator={staffingListItem} isDragOverlayCard={true} />);
  }

  const { apparatus, position, employee } = getBoardEmployee(shiftSummaryHelper, draggingId);
  if (apparatus && position && employee) {
    return renderCard(
      <RosterAssignedEmployeeCard
        apparatus={apparatus}
        position={position}
        employee={employee}
        isCollapsed={checkIsCollapsedApparatus(apparatus, preferences.isCollapsed)}
      />,
    );
  }

  return null;
};
