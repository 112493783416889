import { Dispatch, SetStateAction, useState } from 'react';
import {
  ShiftSummary,
  ListFieldsStaffingList,
  RosterStation,
  RosterAdministrationStation,
  EmployeeOffView,
  RosterTemporaryNonShiftStation,
} from '@stationwise/share-types';
import { makeDateInterval } from '@stationwise/share-utils';
import { getShiftSummaryHelper } from '@stationwise/shift-summary-helper';
import { useLoadedAuthUserContext } from '../../Auth';
import { getDepartmentFeatureFlagValue, useLoadedDepartmentInfoContext } from '../../Department';
import { ILastChanges } from '../types';
import { useCancelShiftTrade } from './useCancelShiftTrade';
import { useCreateStrikeTeamModal } from './useCreateStrikeTeamModal';
import { useEmployeesOff } from './useEmployeesOff';
import { useForceShiftTrade } from './useForceShiftTrade';
import { useRosterPreferences } from './useRosterPreferences';
import { useRosterStaffingLists } from './useRosterStaffingLists';
import { useSaveStrikeTeams } from './useSaveStrikeTeams';
import { useSaveTemporaryNonShiftAssignments } from './useSaveTemporaryNonShiftAssignments';
import { useSelectedEmptyPosition } from './useSelectedEmptyPosition';
import { useSelectedFilledPosition } from './useSelectedFilledPosition';
import { useSelectedStrikeTeamApparatus } from './useSelectedStrikeTeamApparatus';
import { useSplitShiftOrTimeOff } from './useSplitShiftOrTimeOff';

interface UseRosterInput {
  selectedBattalionState?: [number | undefined, Dispatch<SetStateAction<number | undefined>>];
  shiftSummary: ShiftSummary;
  administrationStations: RosterAdministrationStation[];
  temporaryNonShiftStation: RosterTemporaryNonShiftStation;
  eventStations: RosterStation[];
  employeesOff: EmployeeOffView[];
  currentDate: Date;
  staffingListsResponse?: { data: ListFieldsStaffingList[] | null; isError: boolean };
  forceRefetch?: (battalionId?: number) => void;
}

export const useRoster = ({
  selectedBattalionState = [undefined, () => undefined],
  shiftSummary,
  administrationStations,
  temporaryNonShiftStation,
  eventStations,
  employeesOff,
  currentDate,
  staffingListsResponse,
  forceRefetch = () => undefined,
}: UseRosterInput) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;
  const { state: authUserState } = useLoadedAuthUserContext();
  const isStaffingListsEnabled = getDepartmentFeatureFlagValue(departmentInfoState, 'STAFFING_LISTS', false);
  const hasAdminPermission = authUserState.employee?.hasAdminPermission;

  const shiftDuration = makeDateInterval(currentDate, departmentInfo.shiftStart, 24);
  const isPast = shiftDuration.endTime < new Date();
  const isReadonly = isPast || !hasAdminPermission;

  const initShiftSummaryHelper = () => {
    const newShiftSummaryHelper = {
      ...getShiftSummaryHelper({ shiftSummary, shiftDuration }),
      payCodes: departmentInfo.payCodes,
      detailCodes: departmentInfo.detailCodes,
      certificationActAsRanks: departmentInfo.certificationActAsRanks,
      maxActorsRule: departmentInfo.settings.maxActorsRule,
      maxConsecutiveHoursRule: departmentInfo.settings.maxConsecutiveHoursRule,
      createdAt: new Date().toISOString(),
      actingPayCodeMappings: departmentInfo.actingPayCodeMappings,
    };
    eventStations.forEach((eventStation) => {
      if (eventStation.apparatuses.length) {
        newShiftSummaryHelper.allStationCards.set(eventStation.stationId, eventStation);
      }
    });
    if (temporaryNonShiftStation.apparatuses.length) {
      newShiftSummaryHelper.allStationCards.set(temporaryNonShiftStation.stationId, temporaryNonShiftStation);
    }
    administrationStations.forEach((administrationStation) => {
      if (administrationStation.apparatuses.length) {
        newShiftSummaryHelper.allStationCards.set(administrationStation.stationId, administrationStation);
      }
    });
    return newShiftSummaryHelper;
  };

  const [initialShiftSummaryHelper, setInitialShiftSummaryHelper] = useState(initShiftSummaryHelper);
  const [shiftSummaryHelper, setShiftSummaryHelper] = useState(initialShiftSummaryHelper);
  const [draggingId, setDraggingId] = useState<string | null>(null);
  const [lastChanges, setLastChanges] = useState<ILastChanges | null>(null);
  const [userHasMadeChanges, setUserHasMadeChanges] = useState(false);
  const createStrikeTeamModalState = useCreateStrikeTeamModal();
  const selectedStrikeTeamApparatusState = useSelectedStrikeTeamApparatus();
  const saveStrikeTeamsState = useSaveStrikeTeams(shiftSummary, initialShiftSummaryHelper, shiftSummaryHelper);
  const temporaryNonShiftAssignmentsState = useSaveTemporaryNonShiftAssignments(shiftSummary, shiftSummaryHelper);
  const cancelShiftTradeState = useCancelShiftTrade({ shiftSummaryHelper, setShiftSummaryHelper, setUserHasMadeChanges });
  const forceShiftTradeState = useForceShiftTrade();
  const splitShiftOrTimeOffState = useSplitShiftOrTimeOff();
  const employeesOffState = useEmployeesOff(employeesOff);
  const employeeOffPayloads = {
    cancelTemporaryNonShiftAssignmentPayloads: employeesOffState.temporaryNonShiftIdsToCancel,
    createTemporaryNonShiftAssignmentPayloads: temporaryNonShiftAssignmentsState.assignments,
    cancelShiftTradePayloads: cancelShiftTradeState.payloads,
    createShiftTradePayloads: forceShiftTradeState.payloads,
    cancelTimeOffPayloads: employeesOffState.timeOffIdsToCancel,
    createTimeOffPayloads: splitShiftOrTimeOffState.payloads,
  };
  const staffingListsState = useRosterStaffingLists({ staffingListsResponse, shiftSummaryHelper, employeeOffPayloads });
  const selectedEmptyPositionState = useSelectedEmptyPosition(shiftSummaryHelper, staffingListsState.staffingLists, isReadonly);
  const selectedFilledPositionState = useSelectedFilledPosition(shiftSummaryHelper);
  const preferences = useRosterPreferences({
    selectedBattalionState,
    administrationStations,
    temporaryNonShiftStation,
    allActiveStations: shiftSummary.allActiveStations || [],
  });

  const resetValues = () => {
    const newInitialShiftSummaryHelper = initShiftSummaryHelper();
    setInitialShiftSummaryHelper(newInitialShiftSummaryHelper);
    setShiftSummaryHelper(newInitialShiftSummaryHelper);
    setDraggingId(null);
    setLastChanges(null);
    setUserHasMadeChanges(false);
    createStrikeTeamModalState.resetState();
    selectedStrikeTeamApparatusState.resetState();
    temporaryNonShiftAssignmentsState.resetState();
    cancelShiftTradeState.resetState();
    forceShiftTradeState.resetState();
    splitShiftOrTimeOffState.resetState();
    employeesOffState.resetState();
    selectedEmptyPositionState.resetState();
    selectedFilledPositionState.resetState();
  };

  return {
    isPast,
    isReadonly,
    isStaffingListsEnabled,
    initialShiftSummaryHelper,
    shiftSummaryHelper,
    setShiftSummaryHelper,
    draggingId,
    setDraggingId,
    lastChanges,
    setLastChanges,
    userHasMadeChanges,
    setUserHasMadeChanges,
    createStrikeTeamModalState,
    selectedStrikeTeamApparatusState,
    saveStrikeTeamsState,
    temporaryNonShiftAssignmentsState,
    cancelShiftTradeState,
    forceShiftTradeState,
    splitShiftOrTimeOffState,
    employeesOffState,
    staffingListsState,
    selectedEmptyPositionState,
    selectedFilledPositionState,
    preferences,
    employeeOffPayloads,
    forceRefetch,
    resetValues,
  };
};

export type UseRosterReturnType = ReturnType<typeof useRoster>;
