import { Box, CircularProgress, InputAdornment } from '@mui/material';
import { captureException } from '@sentry/react';
import { useMemo, useState, FormEvent } from 'react';
import { client, isAxiosError } from '@stationwise/share-api';
import { Input, Button, CircleWarningIcon20, SnackbarService } from '../../../component-module';
import { ValidationRulesCheck } from './ValidationRulesCheck';

interface CreateNewPasswordFormProps {
  uid: string;
  token: string;
  setShowNewPasswordForm: (value: boolean) => void;
  handleRefresh?: () => void;
  showCancelButton?: boolean;
}

export const CreateNewPasswordForm = ({
  uid,
  token,
  setShowNewPasswordForm,
  handleRefresh,
  showCancelButton = false,
}: CreateNewPasswordFormProps) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkHaveAtLeastEightCharacters = useMemo((): boolean => {
    return newPassword.length >= 8;
  }, [newPassword]);

  const checkHaveASpecialCharacter = useMemo((): boolean => {
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>[\]/\\~`'_+=;|-]/;
    return specialCharacterRegex.test(newPassword);
  }, [newPassword]);

  const checkIncludeAlphaNumeric = useMemo((): boolean => {
    return !!(newPassword.match(/[a-z]/) && newPassword.match(/[A-Z]/));
  }, [newPassword]);

  const checkBothPasswordsMatch = useMemo(() => {
    const doesBothPasswordsMatch = newPassword === confirmNewPassword;
    if (!doesBothPasswordsMatch && confirmNewPassword) {
      setErrorMessage("These passwords don't match");
    } else if (confirmNewPassword && newPassword) {
      setErrorMessage('');
    }
    return doesBothPasswordsMatch;
  }, [newPassword, confirmNewPassword]);

  const isPasswordValid = useMemo((): boolean => {
    return checkBothPasswordsMatch && checkIncludeAlphaNumeric && checkHaveASpecialCharacter && checkHaveAtLeastEightCharacters;
  }, [checkBothPasswordsMatch, checkHaveASpecialCharacter, checkIncludeAlphaNumeric, checkHaveAtLeastEightCharacters]);

  const handleSubmit = async () => {
    if (isSubmitting || !isPasswordValid) {
      return;
    }

    setIsSubmitting(true);
    try {
      await client.post('/auth/login/reset-password/confirm/', {
        uid: uid,
        token: token,
        password: newPassword,
      });
      setShowNewPasswordForm(false);
      setNewPassword('');
      setConfirmNewPassword('');
      setErrorMessage('');
      SnackbarService.notify({
        content: ' Password has been successfully changed.',
        severity: 'success',
        showCloseButton: true,
        duration: 5000,
      });
      if (handleRefresh) {
        handleRefresh();
      }
    } catch (err) {
      const message = isAxiosError(err) ? err.response?.data.error : '';
      if (!message) {
        captureException(err);
      }
      setErrorMessage(message || 'Something went wrong please try again later');
      setNewPassword('');
      setConfirmNewPassword('');
    }
    setIsSubmitting(false);
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await handleSubmit();
  };

  return (
    <Box sx={(theme) => ({ gap: theme.spacing(3) })}>
      <Box
        sx={(theme) => ({
          borderRadius: theme.spacing(1),
          backgroundColor: theme.palette.stationGray[100],
          padding: theme.spacing(1.5),
        })}
      >
        <Box sx={{ typography: 'bodyMSemibold' }}>Create new password with this criteria</Box>
        <ValidationRulesCheck check={checkHaveAtLeastEightCharacters} text="It must have at least 8 characters" />
        <ValidationRulesCheck check={checkHaveASpecialCharacter} text="It must contain at least 1 special character" />
        <ValidationRulesCheck check={checkIncludeAlphaNumeric} text="It must contain uppercase and lowercase" />
      </Box>

      <Box
        component="form"
        onSubmit={handleFormSubmit}
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          py: theme.spacing(0.25),
        })}
      >
        <Box
          sx={{
            mx: 'auto',
            width: '100%',
          }}
        >
          <Box sx={(theme) => ({ mb: theme.spacing(3), mt: theme.spacing(1) })}>
            <Input
              data-cy="password"
              label="Type your new password*"
              value={newPassword}
              error={!!errorMessage}
              placeholder="New Password"
              type={'password'}
              onChange={(event) => {
                setNewPassword(event.currentTarget.value);
              }}
              sx={(theme) => ({
                color: errorMessage ? theme.palette.stationRose[900] : theme.palette.stationGray[900],
                typography: 'bodyMRegular',
              })}
              endAdornment={<InputAdornment position="end">{errorMessage && <CircleWarningIcon20 />}</InputAdornment>}
            />
          </Box>

          <Input
            label="Retype your new password*"
            error={!!errorMessage}
            type={'password'}
            value={confirmNewPassword}
            placeholder="Retype new password"
            onChange={(event) => {
              setConfirmNewPassword(event.currentTarget.value);
            }}
            sx={(theme) => ({
              color: errorMessage ? theme.palette.stationRose[900] : theme.palette.stationGray[900],
              typography: 'bodyMRegular',
            })}
            endAdornment={<InputAdornment position="end">{errorMessage && <CircleWarningIcon20 />}</InputAdornment>}
          />

          <Box
            sx={(theme) => ({
              typography: 'bodySRegular',
              minHeight: theme.spacing(3),
              color: theme.palette.stationRose[600],
              mt: theme.spacing(1),
            })}
          >
            {errorMessage}
          </Box>

          {!showCancelButton && (
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                position: 'fixed',
                bottom: 0,
                left: 'calc(50% - 100px)',
                padding: theme.spacing(2),
                maxWidth: '768px',
              })}
            >
              <Button
                data-cy="submit-login-form"
                buttonType="dark"
                type="submit"
                sx={(theme) => ({
                  p: '9px 17px',
                  width: '100%',
                  borderRadius: theme.spacing(1),
                  typography: theme.typography.buttonM,
                  height: theme.spacing(5),
                })}
                disabled={!isPasswordValid || isSubmitting}
              >
                {isSubmitting && <CircularProgress color="inherit" size="1.5rem" />}
                {!isSubmitting && (
                  <Box
                    component="span"
                    sx={(theme) => ({
                      marginRight: theme.spacing(1),
                    })}
                  >
                    Save password
                  </Box>
                )}
              </Button>
            </Box>
          )}
          {showCancelButton && (
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                pt: theme.spacing(4),
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: theme.spacing(2),
              })}
            >
              <Button
                data-cy="cancel-login-form"
                buttonType="dark"
                type="submit"
                onClick={() => {
                  setShowNewPasswordForm(false);
                }}
                sx={(theme) => ({
                  p: '9px 17px',
                  width: '100%',
                  borderRadius: theme.spacing(1),
                  typography: theme.typography.buttonM,
                  height: theme.spacing(5),
                })}
                disabled={isSubmitting}
              >
                <Box
                  component="span"
                  sx={(theme) => ({
                    marginRight: theme.spacing(1),
                  })}
                >
                  Cancel
                </Box>
              </Button>
              <Button
                data-cy="submit-login-form"
                buttonType="dark"
                type="submit"
                sx={(theme) => ({
                  p: '9px 17px',
                  width: '100%',
                  borderRadius: theme.spacing(1),
                  typography: theme.typography.buttonM,
                  height: theme.spacing(5),
                })}
                disabled={!isPasswordValid || isSubmitting}
              >
                {isSubmitting && <CircularProgress color="inherit" size="1.5rem" />}
                {!isSubmitting && (
                  <Box
                    component="span"
                    sx={(theme) => ({
                      marginRight: theme.spacing(1),
                    })}
                  >
                    Save password
                  </Box>
                )}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
