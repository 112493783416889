import { captureException } from '@sentry/react';
import React, { FormEvent, useState } from 'react';
import { To, useLocation } from 'react-router-dom';
import { client, isAxiosError } from '@stationwise/share-api';
import { useNextURLQueryParam } from '../../../hooks';
import { SnackbarService } from '../../Snackbar';
import { ResetPasswordPageBody } from './ResetPasswordPageBody';
import { ResetPasswordResultPage } from './ResetPasswordResultPage';

interface ResetPasswordPageProps {
  redirectTo: To;
  isSpecialCaseReset?: boolean;
}

export const ResetPasswordPage = ({ redirectTo, isSpecialCaseReset }: ResetPasswordPageProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const nextUrl = useNextURLQueryParam();

  const location = useLocation();

  const setAnError = (message: string) => {
    setErrorMessage(message);
  };

  const tryAgainButton = () => {
    setErrorMessage('');
  };

  const handleSubmit = async (password: string) => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    try {
      const urlParams = new URLSearchParams(window.location.search);
      await client.post('/auth/login/reset-password/confirm/', {
        uid: urlParams.get('uid'),
        token: urlParams.get('token'),
        password: password,
      });
      setIsSubmitted(true);
    } catch (err) {
      const message = isAxiosError(err) ? err.response?.data.error : '';
      SnackbarService.notify({
        content: message,
        severity: 'error',
      });
      if (!message) {
        captureException(err);
      }
      setAnError(message || 'Something went wrong please try again later');
    }
    setIsSubmitting(false);
  };

  const handleSubmitSpecialCases = async (password: string) => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    try {
      const urlParams = new URLSearchParams(window.location.search);

      await client.post('/auth/login/reset-password/confirm-casual/', {
        email: decodeURIComponent(urlParams.get('email') ?? ''),
        old_password: atob(location.state?.oldPassword),
        password: password,
      });
      setIsSubmitted(true);
    } catch (err) {
      const message = isAxiosError(err) ? err.response?.data.error : '';
      SnackbarService.notify({
        content: message,
        severity: 'error',
        duration: 5000,
      });

      if (!message) {
        captureException(err);
      }
      setAnError('Something went wrong please try again later');
    }
    setIsSubmitting(false);
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>, password: string) => {
    event.preventDefault();
    if (isSpecialCaseReset) {
      await handleSubmitSpecialCases(password);
    } else {
      await handleSubmit(password);
    }
  };
  if (isSubmitted) {
    return (
      <ResetPasswordResultPage
        isSuccess={true}
        navigate={nextUrl ? redirectTo + '/?next=' + nextUrl : redirectTo}
      ></ResetPasswordResultPage>
    );
  } else if (errorMessage !== '') {
    return (
      <ResetPasswordResultPage
        isSuccess={false}
        navigate={redirectTo}
        tryAgainFunction={tryAgainButton}
        errorMessage={errorMessage}
      ></ResetPasswordResultPage>
    );
  } else {
    return (
      <ResetPasswordPageBody
        handleFormSubmit={handleFormSubmit}
        isSubmitting={isSubmitting}
        firstPasswordChange={!!location.state?.firstPasswordChange}
        redirectTo={redirectTo}
        setErrorMessageFunc={setAnError}
        errorMessage={errorMessage}
      ></ResetPasswordPageBody>
    );
  }
};
