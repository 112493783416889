import { DetailCode, PayCode, RosterApparatus, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import {
  checkIsPlannedEmployee,
  checkIsShift,
  checkIsStrikeTeam,
  checkIsUnsavedStrikeTeamAssignment,
  modifyBoardEmployeeDetailCodes,
  overrideBoardEmployeePayCodes,
} from '@stationwise/shift-summary-helper';
import { useLoadedAuthUserContext } from '../../../Auth';
import { PayAndDetailCodes } from '../../../EmployeePreview/PayAndDetailCodes';
import { useRosterContext } from '../RosterContext';
import { notifyEmployeeNoteChanged } from './Note';

interface Props {
  apparatus: RosterApparatus | null;
  position: RosterPosition | null;
  employee: RosterEmployee;
}

export const RosterPayAndDetailCodes = ({ apparatus, position, employee }: Props) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const { isReadonly, shiftSummaryHelper, setShiftSummaryHelper, setUserHasMadeChanges } = useRosterContext();

  let defaultPayCodes = [employee.defaults.regularAssignmentPayCode];
  if (!checkIsShift(employee)) {
    defaultPayCodes = employee.payCodes;
  } else if (!checkIsPlannedEmployee(shiftSummaryHelper, employee)) {
    defaultPayCodes = [employee.defaults.overtimeAssignmentPayCode];
  }

  const handlePayCodeChange = (payCodes: PayCode[], detailCodes: DetailCode[], note: string, employee: RosterEmployee) => {
    setShiftSummaryHelper((prev) => {
      return overrideBoardEmployeePayCodes(prev, payCodes, detailCodes, note, employee.activeId, authUserState.employee);
    });
    setUserHasMadeChanges(true);
    notifyEmployeeNoteChanged(employee, note);
  };

  const handleDetailCodeChange = (detailCodes: DetailCode[], employee: RosterEmployee) => {
    setShiftSummaryHelper((prev) => modifyBoardEmployeeDetailCodes(prev, detailCodes, employee.activeId));
    setUserHasMadeChanges(true);
  };

  return (
    <PayAndDetailCodes
      employee={employee}
      defaultPayCodes={defaultPayCodes}
      handleDetailCodeChange={handleDetailCodeChange}
      handlePayCodeChange={handlePayCodeChange}
      disableOverrideNotes={checkIsStrikeTeam(employee)}
      isChangeDisabled={
        isReadonly ||
        checkIsUnsavedStrikeTeamAssignment(shiftSummaryHelper, apparatus, position, employee) ||
        (!checkIsShift(employee) && !checkIsStrikeTeam(employee))
      }
    />
  );
};
