import { RosterPosition, RosterEmployee } from '@stationwise/share-types';
import { makePlaceholderEmployee } from '../board';
import { setEmployeeActiveId } from '../id';

export const mergeVacancies = (employees: RosterEmployee[]) => {
  if (employees.length <= 1) {
    if (employees.length === 1 && !employees[0].id) {
      return [];
    }
    return employees;
  }

  const merged = [employees[0]];
  employees.slice(1, employees.length).forEach((curr) => {
    const prev = merged[merged.length - 1];
    if (!prev.id && !curr.id && curr.startDateTime <= prev.endDateTime) {
      if (curr.endDateTime > prev.endDateTime) {
        merged[merged.length - 1] = setEmployeeActiveId({ ...prev, endDateTime: curr.endDateTime });
      }
    } else {
      merged.push(curr);
    }
  });
  if (merged.length === 1 && !merged[0].id) {
    return [];
  }
  return merged;
};

export const resolveOneEmployeeOverlap = (employees: RosterEmployee[], employee: RosterEmployee, position?: RosterPosition) => {
  if (!position || position.isTemporary) {
    // Delete floater or excess capacity overlaps.
    return;
  }

  // Make vacancies for regular position overlaps.
  employees.push(makePlaceholderEmployee(position, employee));
};

/**
 * Update or remove the RosterEmployee instances for a particular employee,
 * such that none of their durations overlap with the `(startTime, endTime)` duration.
 * - The first overlap before `startTime` is updated to end at `startTime`.
 * - The last overlap after `endTime` is updated to start at `endTime`.
 * - The rest of the overlaps are handled by the `resolveOverlap` function. See `resolveOneEmployeeOverlap` for example.
 */
export const resolveAllEmployeeOverlaps = (
  employees: RosterEmployee[],
  employeeId: string,
  startTime: Date,
  endTime: Date,
  resolveOverlap: (employees: RosterEmployee[], employee: RosterEmployee) => void,
) => {
  let firstOverlap: RosterEmployee | null = null;
  let lastOverlap: RosterEmployee | null = null;
  const overlaps = new Set<RosterEmployee>();
  employees.forEach((employee) => {
    if (employee.id === employeeId && employee.startDateTime < endTime && employee.endDateTime > startTime) {
      overlaps.add(employee);
      if (!firstOverlap || employee.startDateTime < firstOverlap.startDateTime) {
        firstOverlap = employee;
      }
      if (!lastOverlap || employee.endDateTime > lastOverlap.endDateTime) {
        lastOverlap = employee;
      }
    }
  });

  const newEmployees: RosterEmployee[] = [];
  employees.forEach((employee) => {
    if (employee.id !== employeeId || !overlaps.has(employee)) {
      newEmployees.push(employee);
      return;
    }
    if (firstOverlap && firstOverlap === employee && firstOverlap.startDateTime < startTime) {
      newEmployees.push(setEmployeeActiveId({ ...employee, endDateTime: startTime }));
    }
    resolveOverlap(
      newEmployees,
      setEmployeeActiveId({
        ...employee,
        startDateTime: employee.startDateTime > startTime ? employee.startDateTime : startTime,
        endDateTime: employee.endDateTime < endTime ? employee.endDateTime : endTime,
      }),
    );
    if (lastOverlap && lastOverlap === employee && lastOverlap.endDateTime > endTime) {
      newEmployees.push(setEmployeeActiveId({ ...employee, startDateTime: endTime }));
    }
  });

  return newEmployees;
};
