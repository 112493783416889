import { Box, Modal, Typography, Divider, Button } from '@mui/material';
import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { XIcon24, PlusIcon24, Edit02Icon24, Trash04Icon24, theme } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { Employee, ShiftTrade } from '@stationwise/share-types';
import { AddEditModal } from './AddEditModal';
import { DeleteModal } from './DeleteModal';
interface ModalProps {
  detailsOpen: boolean;
  setDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEmployee: Employee | undefined;
  tradeEmployee: string | undefined;
  tradeEmployeeId: number | undefined;
  onRefetch: () => void;
}

export interface TradeHistory {
  id: number;
  tradeRequest: ShiftTrade | null;
  requester: Employee | null;
  responder: Employee | null;
  tradeDuration: number;
  note: string;
}

export const DetailsModal = ({
  detailsOpen,
  setDetailsOpen,
  selectedEmployee,
  tradeEmployee,
  tradeEmployeeId,
  onRefetch,
}: ModalProps) => {
  const [tradeHistory, setTradeHistory] = useState<TradeHistory[]>([]);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedTradeHistory, setSelectedTradeHistory] = useState<TradeHistory | null>(null);

  const fetchTradeHistory = async () => {
    if (selectedEmployee && tradeEmployee) {
      try {
        const response = await client.get(
          `/request/shift-trade-history/?employee_one=${selectedEmployee?.id}&employee_two=${tradeEmployeeId}`,
        );
        onRefetch();
        setTradeHistory(response.data);
      } catch (error) {
        captureException(error);
      }
    }
  };

  useEffect(() => {
    fetchTradeHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployee, tradeEmployee]);

  return (
    <Modal open={detailsOpen} onClose={() => setDetailsOpen(false)}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '90vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            width: '56rem',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '24px',
            }}
          >
            <Typography variant="bodyXLSemibold">Balance details</Typography>
            <Box
              onClick={() => setDetailsOpen(false)}
              sx={(theme) => ({
                color: theme.palette.stationGray[400],
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.stationGray[600],
                },
              })}
            >
              <XIcon24 />
            </Box>
          </Box>

          <Box sx={(theme) => ({ mb: theme.spacing(2), px: '24px' })}>
            <Typography variant="bodyLRegular" sx={(theme) => ({ color: theme.palette.stationGray[700] })}>
              Trade History between{' '}
              <Typography component="span" variant="bodyLSemibold">
                {selectedEmployee?.name}
              </Typography>{' '}
              and{' '}
              <Typography component="span" variant="bodyLSemibold">
                {tradeEmployee}
              </Typography>
            </Typography>
          </Box>

          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: '24px',
              color: theme.palette.stationGray[400],
            })}
          >
            <Typography variant="tagline1" sx={(theme) => ({ width: '100px', padding: theme.spacing(2) })}>
              DIRECTION
            </Typography>
            <Typography variant="tagline1" sx={(theme) => ({ width: '160px', padding: theme.spacing(2) })}>
              {'HOURS (SUM)'}
            </Typography>
            <Typography variant="tagline1" sx={(theme) => ({ flexGrow: 1, padding: theme.spacing(2) })}>
              TRADED
            </Typography>
            <Typography variant="tagline1" sx={(theme) => ({ width: '152px', padding: theme.spacing(2), textAlign: 'right' })}>
              TIMESTAMP
            </Typography>
          </Box>

          <Divider sx={{ mx: '24px' }} />

          {tradeHistory
            .filter((item) => item.tradeRequest !== null)
            .map((item, index) => (
              <React.Fragment key={index}>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: theme.spacing(3),
                    color: theme.palette.stationGray[900],
                  })}
                >
                  <Typography
                    variant="bodySRegular"
                    sx={(theme) => ({
                      width: '100px',
                      padding: theme.spacing(2),
                      color: selectedEmployee?.id === item.responder?.id ? theme.palette.success.main : theme.palette.error.main,
                    })}
                  >
                    {selectedEmployee?.id === item.responder?.id ? 'is owed' : 'owes'}
                  </Typography>
                  <Typography variant="bodySSemibold" sx={(theme) => ({ width: '160px', padding: theme.spacing(2) })}>
                    {(item.tradeDuration / 60).toFixed(2)} {item.tradeDuration / 60 === 1 ? 'hour' : 'hours'}
                  </Typography>
                  <Typography variant="bodySRegular" sx={(theme) => ({ flexGrow: 1, padding: theme.spacing(2) })}>
                    {(item.tradeDuration / 60).toFixed(2)} hours (
                    {item.tradeRequest?.startDate ? format(new Date(item.tradeRequest?.startDate), 'dd/MM/yyyy') : ''}{' '}
                    {item.tradeRequest?.startTime} {'-'}{' '}
                    {item.tradeRequest?.shiftEndDate ? format(new Date(item.tradeRequest?.shiftEndDate), 'dd/MM/yyyy') : ''}{' '}
                    {item.tradeRequest?.endTime})
                  </Typography>
                  <Typography
                    variant="bodySRegular"
                    sx={(theme) => ({ width: '152px', padding: theme.spacing(2), textAlign: 'right' })}
                  >
                    {item.tradeRequest?.createdAt ? format(new Date(item.tradeRequest.createdAt), 'MMM dd, hh:mm a') : ''}
                  </Typography>
                </Box>

                <Divider sx={{ mx: '24px' }} />
              </React.Fragment>
            ))}
          <Box sx={(theme) => ({ mb: theme.spacing(2), mt: theme.spacing(6) })}>
            <Typography variant="bodyLRegular" sx={(theme) => ({ px: theme.spacing(3), color: theme.palette.stationGray[700] })}>
              Manual Adjustments
            </Typography>
          </Box>

          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: theme.spacing(3),
              color: theme.palette.stationGray[400],
            })}
          >
            <Typography variant="tagline1" sx={(theme) => ({ width: '100px', padding: theme.spacing(2) })}>
              DIRECTION
            </Typography>
            <Typography variant="tagline1" sx={(theme) => ({ width: '160px', padding: theme.spacing(2) })}>
              HOURS
            </Typography>
            <Typography variant="tagline1" sx={(theme) => ({ flexGrow: 1, padding: theme.spacing(2) })}>
              NOTE
            </Typography>
            <Typography variant="tagline1" sx={(theme) => ({ width: '128px', padding: theme.spacing(2), textAlign: 'right' })}>
              ACTIONS
            </Typography>
          </Box>

          <Divider sx={(theme) => ({ mx: theme.spacing(3) })} />

          {tradeHistory
            .filter((item) => item.tradeRequest === null)
            .map((item, index) => (
              <React.Fragment key={index}>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: theme.spacing(3),
                    color: theme.palette.stationGray[900],
                  })}
                >
                  <Typography
                    variant="bodySRegular"
                    sx={(theme) => ({
                      width: '100px',
                      padding: theme.spacing(2),
                      color: selectedEmployee?.id === item.responder?.id ? theme.palette.success.main : theme.palette.error.main,
                    })}
                  >
                    {selectedEmployee?.id === item.responder?.id ? 'is owed' : 'owes'}
                  </Typography>
                  <Typography
                    variant="bodySSemibold"
                    sx={{
                      width: '160px',
                      padding: '16px',
                      color: theme.palette.stationGray[900],
                    }}
                  >
                    {(item.tradeDuration / 60).toFixed(2)} {item.tradeDuration / 60 === 1 ? 'hour' : 'hours'}
                  </Typography>
                  <Typography variant="bodySRegular" sx={(theme) => ({ flexGrow: 1, padding: theme.spacing(2) })}>
                    {item.note}
                  </Typography>
                  <Box
                    sx={(theme) => ({ display: 'flex', alignItems: 'center', justifyContent: 'center', px: theme.spacing(2) })}
                  >
                    <Edit02Icon24
                      color={theme.palette.stationGray[500]}
                      style={{ marginRight: '10px', cursor: 'pointer' }}
                      onClick={() => {
                        setOpenAddEditModal(true);
                        setSelectedTradeHistory(item);
                      }}
                    />
                    <Trash04Icon24
                      color={theme.palette.stationRose[500]}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpenDeleteModal(true);
                        setSelectedTradeHistory(item);
                      }}
                    />
                  </Box>
                </Box>

                <Divider sx={(theme) => ({ mx: theme.spacing(3) })} />
              </React.Fragment>
            ))}

          <Button
            variant="outlined"
            size="large"
            startIcon={<PlusIcon24 color="primary" />}
            sx={(theme) => ({ margin: theme.spacing(3), textTransform: 'none' })}
            onClick={() => {
              setOpenAddEditModal(true);
              setSelectedTradeHistory(null);
            }}
          >
            Add Entry
          </Button>
        </Box>
        {openAddEditModal && (
          <AddEditModal
            open={openAddEditModal}
            onClose={() => setOpenAddEditModal(false)}
            tradeHistoryItem={selectedTradeHistory}
            selectedEmployeeId={selectedEmployee?.id}
            tradeEmployeeId={tradeEmployeeId}
            onRefetch={fetchTradeHistory}
            selectedEmployeeName={selectedEmployee?.name}
            tradeEmployeeName={tradeEmployee}
          />
        )}
        <DeleteModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          tradeHistoryItem={selectedTradeHistory}
          onRefetch={fetchTradeHistory}
        />
      </Box>
    </Modal>
  );
};
