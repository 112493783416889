import { Box, Drawer, SxProps } from '@mui/material';
import { DrawerFooter, FooterButton } from './DrawerFooter';
import { DrawerHeader } from './DrawerHeader';

interface GenericDrawerProps {
  anchor: 'bottom' | 'top' | 'left' | 'right';
  drawerOpen: boolean;
  handleOnClose: () => void;
  loading: boolean;
  footerButtons?: FooterButton[];
  headerTitle?: string;
  children: JSX.Element;
  showHeader?: boolean;
  disableFooter?: boolean;
  sxFooterProps?: SxProps;
  noBorderOnHeader?: boolean;
  paddingBottom?: string;
  sxProps?: SxProps;
  headerIcon?: React.ReactNode;
}
export const GenericDrawer = ({
  anchor,
  drawerOpen,
  handleOnClose,
  loading,
  headerTitle,
  footerButtons,
  children,
  showHeader,
  disableFooter,
  sxFooterProps,
  noBorderOnHeader,
  paddingBottom,
  sxProps,
  headerIcon,
}: GenericDrawerProps) => {
  return (
    <Drawer anchor={anchor} open={drawerOpen} onClose={handleOnClose} sx={sxProps}>
      {showHeader && (
        <DrawerHeader
          headerTitle={headerTitle || ''}
          handleOnClose={handleOnClose}
          noBorder={noBorderOnHeader}
          headerIcon={headerIcon}
        />
      )}
      {children}
      {!disableFooter && <DrawerFooter loading={loading} sxProps={sxFooterProps} buttons={footerButtons} />}
      {paddingBottom !== '0px' && (
        <Box
          sx={{
            paddingBottom: paddingBottom,
          }}
        />
      )}
    </Drawer>
  );
};
