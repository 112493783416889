import { Box, Typography, useTheme } from '@mui/material';
import {
  Flag02Icon16,
  StationIcon20,
  User01Icon20,
  Chip,
  SHIFT_TITLES,
  getEventDisplayedTitle,
  getShiftColor,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { AllowedColors } from '@stationwise/share-types';

export const ShiftHeader = ({
  employeeName,
  stationName,
  apparatusName,
  teamColor,
  shiftName,
  shiftTitle,
}: {
  employeeName: string;
  stationName: string;
  apparatusName: string;
  teamColor?: AllowedColors;
  shiftName: string;
  shiftTitle: string;
}) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const theme = useTheme();

  const getShiftTitleColor = (shiftTitle: string) => {
    switch (shiftTitle) {
      case SHIFT_TITLES.MANDATORY_OT:
        return theme.palette.stationPink[500];
      case SHIFT_TITLES.VOLUNTARY_OT:
        return theme.palette.stationPurple[500];
      default:
        return theme.palette.stationBlue[500];
    }
  };

  const chipBackgroundColor = getShiftTitleColor(shiftTitle);
  const shiftColor = getShiftColor(teamColor?.toLowerCase() || '', theme);

  return (
    <>
      <Box
        data-cy="shiftheader"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          mb: theme.spacing(1),
        }}
      >
        <Box>
          <Typography
            variant="bodyLSemibold"
            sx={{
              color: theme.palette.stationGray[900],
            }}
          >
            Next shift
          </Typography>
        </Box>
        {shiftName && (
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: theme.spacing(1),
                '& svg': {
                  height: '12px',
                  width: '12px',
                },
                '& svg path': {
                  stroke: shiftColor,
                  fill: shiftColor,
                },
                borderRadius: '50%',
                height: '20px',
                width: '20px',
                border: `1px solid ${theme.palette.stationGray[200]}`,
              }}
            >
              <Flag02Icon16 />
            </Box>
            <Typography data-cy="shift-name" variant="bodySMedium" sx={{ color: theme.palette.stationGray[500] }}>
              {shiftName}
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={(theme) => ({ color: theme.palette.stationGray[400], display: 'flex' })}>
            <User01Icon20 />
          </Box>

          <Box data-cy="employee-name" sx={(theme) => ({ ml: theme.spacing(1) })}>
            <Typography variant="bodyMMedium">{employeeName}</Typography>
          </Box>
        </Box>
        <Box>
          <Chip
            hideArrowShapedBorder
            label={getEventDisplayedTitle(departmentInfoState, shiftTitle)}
            chipSxProps={() => ({
              background: chipBackgroundColor,
              height: '24px',
              minWidth: '130px',
              p: '4px 6px',
              borderRadius: '7px',
            })}
            variant="eyebrow"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        sx={(theme) => ({
          mt: theme.spacing(1),
          borderBottom: `solid 1px ${theme.palette.stationGray[100]}`,
        })}
        justifyContent="space-between"
        alignItems="center"
      >
        {stationName && apparatusName && (
          <Box data-cy="station-name" display="flex" alignItems="center" sx={{ mb: theme.spacing(2) }}>
            <StationIcon20 />
            <Box sx={(theme) => ({ ml: theme.spacing(1) })}>
              <Typography variant="bodyMMedium">{`${stationName}, ${apparatusName}`}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
