import { Box, Typography, useTheme } from '@mui/material';
import { OverviewCardProps } from '.';

export const Overview = ({ additionalPaidTime }: OverviewCardProps) => {
  const theme = useTheme();
  const titleStyle = { color: theme.palette.stationGray[400], mt: '5px' };
  const boxPadding = { pt: theme.spacing(2) };

  return (
    <Box
      sx={{
        mt: theme.spacing(2),
        p: theme.spacing(2),
        pb: additionalPaidTime.detailCode ? theme.spacing(2) : theme.spacing(1),
        borderTop: `1px solid ${theme.palette.stationGray[200]}`,
      }}
    >
      {additionalPaidTime.detailCode && (
        <Box display="flex" justifyContent="space-between">
          <Typography variant="buttonM" sx={titleStyle}>
            EVENT TYPE
          </Typography>
          <Typography variant="bodyMRegular" sx={{ fontWeight: 500 }}>
            {additionalPaidTime.detailCode.name}
          </Typography>
        </Box>
      )}
      {additionalPaidTime.detailCode && (
        <Box display="flex" justifyContent="space-between" sx={boxPadding}>
          <Typography variant="buttonM" sx={titleStyle}>
            EVENT SUB-TYPE
          </Typography>
          <Typography variant="bodyMRegular" sx={{ fontWeight: 500 }}>
            {additionalPaidTime.detailCode.name}
          </Typography>
        </Box>
      )}
      {additionalPaidTime.payCode && (
        <Box display="flex" justifyContent="space-between" sx={additionalPaidTime.detailCode && boxPadding}>
          <Typography variant="buttonM" sx={titleStyle}>
            PAY CODE
          </Typography>
          <Typography variant="bodyMRegular" sx={{ fontWeight: 500 }}>
            {additionalPaidTime.payCode.name}
            {additionalPaidTime.payCode.code !== additionalPaidTime.payCode.name && ` (${additionalPaidTime.payCode.code})`}
          </Typography>
        </Box>
      )}
      {additionalPaidTime.apparatus && (
        <Box display="flex" justifyContent="space-between" sx={boxPadding}>
          <Typography variant="buttonM" sx={titleStyle}>
            APPARATUS
          </Typography>
          <Typography variant="bodyMRegular" sx={{ fontWeight: 500 }}>
            {additionalPaidTime.apparatus}
          </Typography>
        </Box>
      )}

      {additionalPaidTime.endDate && additionalPaidTime.startDate && (
        <Box display="flex" justifyContent="space-between" sx={boxPadding}>
          <Typography variant="buttonM" sx={titleStyle}>
            DURATION
          </Typography>
          <Typography variant="bodyMRegular" sx={{ fontWeight: 500 }}>
            {(() => {
              const startDateTime = new Date(`${additionalPaidTime.startDate}T${additionalPaidTime.startTime}`);
              const endDateTime = new Date(`${additionalPaidTime.endDate}T${additionalPaidTime.endTime}`);
              const diffInMinutes = (endDateTime.getTime() - startDateTime.getTime()) / (1000 * 60);
              const finalHours = Math.floor(diffInMinutes / 60);
              const finalMinutes = Math.abs(Math.floor(diffInMinutes % 60));

              return `${finalHours} hrs ${finalMinutes} mins`;
            })()}
          </Typography>
        </Box>
      )}
      {additionalPaidTime.detailCode && (
        <Box display="flex" justifyContent="space-between" sx={boxPadding}>
          <Typography variant="buttonM" sx={titleStyle}>
            DETAIL CODE 1
          </Typography>
          <Typography variant="bodyMRegular" sx={{ fontWeight: 500 }}>
            {`${additionalPaidTime.detailCode.name} (${additionalPaidTime.detailCode.code})`}
          </Typography>
        </Box>
      )}
      {additionalPaidTime.detailCode && (
        <Box display="flex" justifyContent="space-between" sx={{ ...boxPadding, pb: theme.spacing(0.5) }}>
          <Typography variant="buttonM" sx={titleStyle}>
            DETAIL CODE 2
          </Typography>
          <Typography variant="bodyMRegular" sx={{ fontWeight: 500 }}>
            {`${additionalPaidTime.detailCode.name} (${additionalPaidTime.detailCode.code})`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
