import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { Button, GenericDrawer, LetterAvatar } from '@stationwise/component-module';
import { PayrollCode, PayrollHours } from '@stationwise/share-types';
import { PayrollStatusBadge } from './PayrollStatusBadge';

interface PayrollCardDrawerProps {
  dayHours: PayrollHours;
  drawerIsOpen: boolean;
  payCode: PayrollCode;
  setDrawerIsOpen: (arg0: boolean) => void;
}

export const PayrollCardDrawer = ({ dayHours, drawerIsOpen, payCode, setDrawerIsOpen }: PayrollCardDrawerProps) => {
  const reviewedAt = dayHours.reviewedAt ?? new Date().toISOString();
  const reviewedByName = dayHours.reviewedBy?.name || '';
  const reasonResponse = dayHours.reasonResponse || '';

  return (
    <GenericDrawer
      anchor="bottom"
      handleOnClose={() => setDrawerIsOpen(false)}
      headerTitle={`${payCode.name} request`}
      drawerOpen={drawerIsOpen}
      loading={false}
      showHeader={true}
      disableFooter
    >
      <Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            p: theme.spacing(2),
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              border: `1px solid ${theme.palette.stationGray[100]}`,
              borderRadius: theme.spacing(1.5),
              p: theme.spacing(1.5),
              background: `linear-gradient(90deg, ${theme.palette.stationGray[400]} 4px, ${theme.palette.common.white} 0)`,
            })}
          >
            <Box>
              <Box sx={(theme) => ({ mb: theme.spacing(1.5), typography: 'bodyXXLRegular' })}>{payCode.code}</Box>
              <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'bodyMRegular' })}>{payCode.name}</Box>
            </Box>
            <Box
              sx={(theme) => ({
                pr: theme.spacing(2),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                justifyContent: 'space-between',
              })}
            >
              <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(1), typography: 'bodySRegular' })}>
                <Box sx={(theme) => ({ color: theme.palette.stationGray[900] })}>
                  {format(parseISO(dayHours.date), 'MM/dd/yy')}
                </Box>
                <Box sx={(theme) => ({ color: theme.palette.stationGray[400], whiteSpace: 'nowrap' })}>0700 - 0700</Box>
              </Box>
              <Box sx={{ typography: 'bodyXXLRegular' }}>{payCode.total.toFixed(1)} h</Box>
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
              border: `1px solid ${theme.palette.stationGray[200]}`,
              borderRadius: theme.spacing(1.5),
              p: theme.spacing(1.5),
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                gap: theme.spacing(1),
                borderBottom: reasonResponse ? `1px solid ${theme.palette.stationGray[200]}` : 'none',
                pb: theme.spacing(1.5),
              })}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  gap: theme.spacing(1),
                })}
              >
                <Box
                  sx={{
                    borderRadius: '50%',
                    overflow: 'hidden',
                    width: '40px',
                    height: '40px',
                  }}
                >
                  <LetterAvatar firstName={reviewedByName} />
                </Box>

                <Box>
                  <Box
                    sx={(theme) => ({
                      color: theme.palette.stationGray[600],
                      mb: theme.spacing(1),
                      typography: 'bodyMRegular',
                    })}
                  >
                    <Box component="strong" sx={{ fontWeight: 500 }}>
                      {dayHours.status === 'ADMIN_APPROVED' ? 'Approved' : dayHours.status === 'ADMIN_DENIED' ? 'Denied' : ''}
                    </Box>{' '}
                    by {reviewedByName}
                  </Box>
                  <Box sx={{ typography: 'bodyMRegular' }}>
                    {format(parseISO(reviewedAt), 'MMM d, yyyy')} at {format(parseISO(reviewedAt), 'HH:mm')}
                  </Box>
                </Box>
              </Box>

              <Box>
                <PayrollStatusBadge status={dayHours.status} />
              </Box>
            </Box>
            {reasonResponse && (
              <Box
                sx={(theme) => ({
                  pt: theme.spacing(1),
                })}
              >
                <Box
                  sx={(theme) => ({
                    color: theme.palette.stationGray[400],
                    mb: theme.spacing(1),
                    typography: 'bodySMedium',
                  })}
                >
                  Note from {reviewedByName}
                </Box>
                <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodyMRegular' })}>
                  {reasonResponse}
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ width: '100%' }}>
            <Button
              variant="contained"
              onClick={() => setDrawerIsOpen(false)}
              sx={(theme) => ({
                background: theme.palette.common.white,
                border: `1px solid ${theme.palette.stationGray[200]}`,
                borderRadius: theme.spacing(0.5),
                color: theme.palette.stationGray[900],
                width: '100%',
                textTransform: 'none',
              })}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </GenericDrawer>
  );
};
