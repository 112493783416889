import axios, { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

export const SW_API_PREFIX = 'api';
export const SERVER_URL = process.env['NX_PUBLIC_SERVER_URL'] || '/';
export const SERVER_BASE_URL = SERVER_URL + SW_API_PREFIX;
export const APP_NAME = process.env['NX_PUBLIC_APP_NAME'] || '';

const axiosOptions = {
  baseURL: SERVER_BASE_URL,
};

export const client = axios.create(axiosOptions);

client.interceptors.request.use(
  (config) => {
    const csrftoken = Cookies.get(`csrftoken${APP_NAME}`) || Cookies.get('csrftoken');

    if (csrftoken && config && config.headers) {
      config.headers['X-CSRFToken'] = csrftoken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  async (error: AxiosError): Promise<AxiosError> => {
    if (error.response?.status === 401) {
      const currentPath = window.location.pathname;
      const loginUrl = `/login?next=${encodeURIComponent(currentPath)}`;
      window.location.replace(loginUrl);
      return new Promise(() => null);
    }
    return Promise.reject(error);
  },
);
