import { Box, Typography } from '@mui/material';
import { addHours } from 'date-fns';
import { theme, RequestDurationOptions } from '@stationwise/component-module';
import { AvailableCompTime, compTimeDurationOptions } from './utils';

interface CompTimeBankedProps {
  availableCompTime: AvailableCompTime[];
  duration: number;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
  selectedIndex: number;
}

export const CompTimeBanked = ({ availableCompTime, duration, setDuration, selectedIndex }: CompTimeBankedProps) => {
  return (
    <Box
      sx={{
        mt: theme.spacing(3),
        ml: theme.spacing(2),
        mr: theme.spacing(2),
      }}
    >
      <Box
        typography={'bodyLMedium'}
        sx={{
          mb: theme.spacing(1.5),
        }}
      >
        Comp time banked
      </Box>
      <RequestDurationOptions
        durationOptions={compTimeDurationOptions}
        startDateTime={new Date().toISOString()}
        duration={duration}
        setDuration={setDuration}
        originalEndDateTime={addHours(
          new Date(),
          availableCompTime[selectedIndex].overtimeHours * availableCompTime[selectedIndex].convertRatio,
        ).toISOString()}
      />
      <Box sx={{ mt: (theme) => theme.spacing(1.5) }}>
        <Typography variant="bodyMSemibold" component="span">
          {duration.toFixed(0)} hrs
        </Typography>
        <Typography component="span" variant="bodyMRegular">
          {' '}
          comp time banked,
        </Typography>
        <Typography variant="bodyMSemibold" component="span">
          {' '}
          {(availableCompTime[selectedIndex].overtimeHours - duration / availableCompTime[selectedIndex].convertRatio).toFixed(
            1,
          )}{' '}
          hrs
        </Typography>
        <Typography component="span" variant="bodyMRegular">
          {' '}
          overtime pay
        </Typography>
      </Box>
    </Box>
  );
};
