import { Button, ButtonProps, Typography } from '@mui/material';
import { PlusIcon20 } from '@stationwise/component-module';

export const CreateButton = ({ title, onClick }: ButtonProps<'button'> & { title: string }) => {
  return (
    <Button
      variant="contained"
      size="small"
      onClick={onClick}
      sx={(theme) => ({
        position: 'absolute',
        bottom: '20px',
        right: '80px',
        minWidth: 0,
        width: '150px',
        backgroundColor: theme.palette.stationBlue[700],
        borderRadius: '48px',
        boxShadow: 'none',
        height: '48px',
        zIndex: theme.zIndex.appBar,
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'none',
        gap: '8px',
        '&:hover': {
          background: theme.palette.stationBlue[700],
          boxShadow: 'none',
        },
        [theme.breakpoints.down('lg')]: { width: '48px', borderRadius: '64px' },
      })}
    >
      <PlusIcon20 />
      <Typography variant="buttonL" sx={(theme) => ({ [theme.breakpoints.down('lg')]: { display: 'none' } })}>
        {title}
      </Typography>
    </Button>
  );
};
