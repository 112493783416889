import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { FlagIconComponent, useAuthUserContext } from '@stationwise/component-module';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { OvertimeInfo } from '../overtimeListHelper';

export const OvertimeList = ({
  overtimeInfo,
  scroll,
  setScroll,
}: {
  overtimeInfo: OvertimeInfo;
  scroll: boolean;
  setScroll: (scroll: boolean) => void;
}) => {
  const [showClicked, setShowClicked] = useState<boolean>(false);
  const ref = useRef<null | HTMLDivElement>(null);
  const { state } = useAuthUserContext();
  const { employee } = state;

  useEffect(() => {
    if (scroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      setShowClicked(true);
      setScroll(false);
    }
  }, [scroll, setScroll]);

  return (
    <Box sx={(theme) => ({ mt: theme.spacing(3) })}>
      {overtimeInfo.hireBackList.map((item) => (
        <Box
          data-cy={`overtime-list-item-${makeTestIdentifier(item.employee.firstName + ' ' + item.employee.lastName)}`}
          ref={item.employee.id.toString() === employee?.id ? ref : null}
          key={item.order}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={(theme) => ({
            mt: theme.spacing(1),
            height: '48px',
            px: theme.spacing(2),
            backgroundColor:
              showClicked && item.employee.id.toString() === employee?.id ? theme.palette.stationGray[100] : 'inherit',
          })}
        >
          <Box display="flex">
            <Box
              sx={(theme) => ({
                width: '30px',
                height: '30px',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.stationGray[600],
                borderRadius: '50%',
                textAlign: 'center',
                pt: '2px',
              })}
            >
              <Typography variant="bodySMedium">{item.order}</Typography>
            </Box>
            <Typography
              variant="bodyLMedium"
              sx={(theme) => ({ ml: theme.spacing(1.5) })}
            >{`${item.employee.firstName} ${item.employee.lastName}`}</Typography>
          </Box>
          {item.employee.employeeTeam && (
            <Box display="flex">
              <Typography variant="bodySMedium" sx={(theme) => ({ color: theme.palette.stationGray[500], mr: theme.spacing(1) })}>
                {`${item.employee.employeeTeam.name?.charAt(0).toUpperCase()}${item.employee.employeeTeam.name?.slice(1)}`}
              </Typography>
              <FlagIconComponent showBorder color={item.employee.employeeTeam.color} />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};
