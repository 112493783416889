import { Box } from '@mui/material';
import { ComponentPropsWithoutRef, ReactNode, useRef, useEffect } from 'react';

interface InfiniteScrollProps extends ComponentPropsWithoutRef<typeof Box> {
  onLoadMore: () => void;
  loader?: ReactNode;
  observerOptions?: Partial<Pick<IntersectionObserver, 'root' | 'rootMargin' | 'thresholds'>>;
}

export const InfiniteScroll = ({ children, loader, onLoadMore, observerOptions, ...props }: InfiniteScrollProps) => {
  const callbackRef = useRef(onLoadMore);
  callbackRef.current = onLoadMore;

  const observerOptionsRef = useRef(observerOptions);
  observerOptionsRef.current = observerOptions;

  const rootRef = useRef<Element>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!rootRef.current || !targetRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          callbackRef.current();
        }
      },
      { root: rootRef.current, ...observerOptionsRef.current },
    );

    observer.observe(targetRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <Box {...props} ref={rootRef}>
      {children}
      <div ref={targetRef}>{loader}</div>
    </Box>
  );
};
