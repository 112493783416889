import { Box, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { useId } from 'react';
import { RosterSplitShiftOrTimeOffChange } from '@stationwise/share-types';
import { diffCycleMinutes } from '@stationwise/shift-summary-helper';
import { Trash04Icon20 } from '../../../../assets';
import { InputLabel } from '../../../Input';
import { useRosterContext } from '../RosterContext';
import { SplitTime } from './SplitTime';
import { INITIAL_SPLITS } from './constants';

interface Props {
  splits: RosterSplitShiftOrTimeOffChange[];
  index: number;
  error: string;
  isTimeOffDisabled: boolean;
  onChangeTimeOff: (splitIndex: number, isSelected: boolean) => void;
  onChangeEndTime: (splitIndex: number, endTime: Date | null) => void;
  onDelete: (splitIndex: number) => void;
}

export const Split = ({ splits, index, error, isTimeOffDisabled, onChangeTimeOff, onChangeEndTime, onDelete }: Props) => {
  const split = splits[index];
  const id = useId();
  const { shiftSummaryHelper } = useRosterContext();
  const { shiftDuration } = shiftSummaryHelper;

  const renderDuration = () => {
    let durationMinutes = diffCycleMinutes(split.endTime, split.startTime) || shiftDuration.hours * 60;
    const durationHours = Math.floor(durationMinutes / 60);
    durationMinutes -= durationHours * 60;
    return `${`${durationHours}`.padStart(2, '0')} h ${`${durationMinutes}`.padStart(2, '0')} m`;
  };

  return (
    <Box
      sx={(theme) => ({
        border: error ? `1px solid ${theme.palette.stationRose[200]}` : `1px solid ${theme.palette.stationGray[200]}`,
        borderRadius: '12px',
        p: 1.5,
        my: 2,
        position: 'relative',
      })}
    >
      <Box sx={(theme) => ({ color: theme.palette.stationGray[700], typography: 'bodyLMedium' })}>{`Split ${index + 1}`}</Box>
      <Box sx={(theme) => ({ position: 'absolute', top: theme.spacing(0.5), right: theme.spacing(1.5) })}>
        <FormControlLabel
          label="Employee off"
          labelPlacement="start"
          control={
            <Checkbox
              data-cy="employee-off-box"
              checked={split.isEmpty}
              disabled={isTimeOffDisabled}
              onChange={(event) => onChangeTimeOff(index, event.target.checked)}
            />
          }
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 3 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <InputLabel htmlFor={`${id}start`}>Start</InputLabel>
            <SplitTime disabled={true} value={split.startTime} slotProps={{ textField: { id: `${id}start` } }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <InputLabel htmlFor={`${id}end`}>End</InputLabel>
            <SplitTime
              disabled={index === splits.length - 1}
              value={split.endTime}
              onChange={(value) => onChangeEndTime(index, value)}
              slotProps={{ textField: { id: `${id}end` } }}
            />
          </Box>
        </Box>
        <Box sx={{ flex: 1, mt: 3, whiteSpace: 'nowrap' }}>
          <Box component="span" sx={(theme) => ({ color: theme.palette.stationGray[500], mr: 1 })}>
            Duration
          </Box>
          <Box component="span">{renderDuration()}</Box>
        </Box>
        {index >= INITIAL_SPLITS && splits.length > INITIAL_SPLITS && (
          <IconButton aria-label="Delete split" onClick={() => onDelete(index)} sx={{ mt: 3, mr: -1 }}>
            <Trash04Icon20 />
          </IconButton>
        )}
      </Box>
      {error && <Box sx={(theme) => ({ color: theme.palette.stationRose[700], mt: 1, typography: 'bodySRegular' })}>{error}</Box>}
    </Box>
  );
};
