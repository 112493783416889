import React from 'react';

const getTimeCounterLabel = (compareDate: Date) => {
  const now = new Date();
  const diff = now.getTime() - compareDate.getTime();
  const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
  const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor(diff / (1000 * 60));
  const seconds = Math.floor(diff / 1000);

  if (years > 0) {
    return `${years} ${years === 1 ? 'year' : 'years'} ago`;
  } else if (months > 0) {
    return `${months} ${months === 1 ? 'month' : 'months'} ago`;
  } else if (weeks > 0) {
    return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
  } else if (days > 0) {
    return `${days} ${days === 1 ? 'day' : 'days'} ago`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
  } else if (seconds > 0) {
    return `${seconds} seconds ago`;
  } else {
    return 'Just now';
  }
};

export const useTimeCounterLabel = (compareDate: Date, intervalDuration = 1000) => {
  const [timeCounterLabel, setTimeCounterLabel] = React.useState(getTimeCounterLabel(compareDate));

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeCounterLabel(getTimeCounterLabel(compareDate));
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [compareDate, intervalDuration]);

  return timeCounterLabel;
};
