import { Box } from '@mui/material';
import { PreviewButton, ViewProfileButton } from '@stationwise/component-module';

interface Props {
  employeeId: string;
  activeEmployeeId: string;
  onUnassignClick: () => void;
}

export const ShiftTemplateActions = ({ activeEmployeeId, onUnassignClick, employeeId }: Props) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
      <ViewProfileButton sx={{ flex: 1 }} employeeId={employeeId} />
      <PreviewButton sx={{ flex: 1 }} buttonType="primary" onClick={onUnassignClick}>
        Unassign
      </PreviewButton>
    </Box>
  );
};
