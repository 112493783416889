import { Alert, Box, Dialog, Link } from '@mui/material';
import { isValid, setMinutes } from 'date-fns';
import { useMemo, useState } from 'react';
import { Button, CodeAutocomplete, Input, PreviewButton, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { DetailCode, EmployeeDefaults, NonShiftAssignmentDetail, PayCode } from '@stationwise/share-types';
import { makeDateInterval } from '@stationwise/share-utils';
import { offsetMinutes } from '@stationwise/shift-summary-helper';
import { TimePicker, WeekdayPicker } from '../../../../../../components/Common';
import { usePatchRequest } from './PatchRequestProvider';

const checkIsDurationValid = (startMinutes: number, endMinutes: number) => {
  return startMinutes >= -6 * 60 && startMinutes <= 24 * 60 && endMinutes > startMinutes && endMinutes <= 24 * 60;
};
interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  hasAnyAssignment: boolean;
  nonShiftDetail?: NonShiftAssignmentDetail;
  employeeDefaults: EmployeeDefaults;
}

export const EditNonShiftAssignment = ({
  showModal,
  setShowModal,
  hasAnyAssignment,
  nonShiftDetail,
  employeeDefaults,
}: ModalProps) => {
  const hasNonShiftDetail = !!nonShiftDetail;
  const disableAll = !hasNonShiftDetail && hasAnyAssignment;
  const labelGray = disableAll ? 400 : 700;
  const defaultNonShiftDetail = {
    station: '',
    apparatus: '',
    startTime: 0,
    endTime: 480,
    activeWeekdays: [0, 1, 2, 3, 4],
    breakDuration: 60,
    payCodes: [employeeDefaults.regularAssignmentPayCode],
  };
  if (!nonShiftDetail) {
    nonShiftDetail = defaultNonShiftDetail;
  }

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;
  const shiftDuration = makeDateInterval(new Date(), departmentInfo.shiftStart, 24);

  const [startTime, setStartTime] = useState<Date | null>(setMinutes(shiftDuration.startTime, nonShiftDetail.startTime));
  const [endTime, setEndTime] = useState<Date | null>(setMinutes(shiftDuration.startTime, nonShiftDetail.endTime));

  const startMinutes = !isValid(startTime) ? 0 : offsetMinutes(startTime, shiftDuration.startTime);
  const endMinutes = !isValid(startTime) ? 0 : offsetMinutes(endTime, shiftDuration.startTime);
  const isDurationValid = isValid(startTime) && isValid(endTime) && checkIsDurationValid(startMinutes, endMinutes);
  const durationMinutes = !isDurationValid ? 0 : endMinutes - startMinutes;

  const [breakDuration, setBreakDuration] = useState<number | null>(nonShiftDetail.breakDuration);
  const [selectedStation, setSelectedStation] = useState<string>(nonShiftDetail.station);
  const [selectedApparatus, setSelectedApparatus] = useState<string>(nonShiftDetail.apparatus);

  const [activeWeekdays, setActiveWeekdays] = useState<number[]>(nonShiftDetail.activeWeekdays);
  const handleActiveWeekdaysClick = (weekdayNumber: number) => {
    if (activeWeekdays) {
      const newActiveWeekdays = new Set(activeWeekdays);
      newActiveWeekdays.has(weekdayNumber) ? newActiveWeekdays.delete(weekdayNumber) : newActiveWeekdays.add(weekdayNumber);
      setActiveWeekdays([...newActiveWeekdays]);
    }
  };
  const defaultPayCodes = [employeeDefaults.regularAssignmentPayCode];
  const [currentPayCodes, setCurrentPayCodes] = useState<PayCode[]>(nonShiftDetail.payCodes);

  const getSelectablePayCodes = (currentPayCode?: PayCode) => {
    const currentPayCodeIds = new Set(currentPayCodes.map((pc) => pc.id));
    return departmentInfo.payCodes.filter((pc) => {
      const isNew = !currentPayCodeIds.has(pc.id);
      const isCurrent = !!(currentPayCode && currentPayCode.id === pc.id);
      return !['TIME_OFF', 'HOLIDAY', 'INCENTIVE_PAY'].includes(pc.payCodeType) && (isNew || isCurrent);
    });
  };

  const onPayCodeChange = (event: React.SyntheticEvent, value: PayCode | DetailCode, index: number) => {
    const newPayCodes = [...currentPayCodes];
    if (value.code === '') {
      newPayCodes.splice(index, 1);
    } else {
      const newPayCode = departmentInfo.payCodes.find((pc) => pc.code === value.code);
      if (!newPayCode) {
        throw new Error(`Cannot find PayCode with code ${value.code}`);
      }
      newPayCodes[index] = newPayCode;
    }
    setCurrentPayCodes(newPayCodes);
  };

  const onAddPayCode = () => {
    const firstPayCode = getSelectablePayCodes()[0];
    if (firstPayCode) {
      const newPayCodes = [...currentPayCodes, firstPayCode];
      setCurrentPayCodes(newPayCodes);
    }
  };

  const { handlePatchRequest } = usePatchRequest();

  const handleUnassign = () => {
    const data = {
      deactivateNonShift: true,
    };
    handlePatchRequest(data);
    handleClose(defaultNonShiftDetail);
  };

  const handleSave = () => {
    const data = {
      editNonShift: true,
      station: selectedStation,
      apparatus: selectedApparatus,
      startTime: startMinutes,
      endTime: endMinutes,
      activeWeekdays: activeWeekdays,
      breakDuration: breakDuration,
      payCodes: currentPayCodes.map((pc) => pc.id),
    };
    handlePatchRequest(data);
    setShowModal(false);
  };

  const handleClose = (data: NonShiftAssignmentDetail) => {
    setShowModal(false);
    setSelectedStation(data.station);
    setSelectedApparatus(data.apparatus);
    setStartTime(setMinutes(shiftDuration.startTime, data.startTime));
    setEndTime(setMinutes(shiftDuration.startTime, data.endTime));
    setActiveWeekdays(data.activeWeekdays);
    setBreakDuration(data.breakDuration);
    setCurrentPayCodes(data.payCodes);
  };

  function eqSet(as: Set<number>, bs: Set<number>) {
    if (as.size !== bs.size) return false;
    for (const a of as) if (!bs.has(a)) return false;
    return true;
  }

  const isDisabled = useMemo(() => {
    const isEmptyStation = selectedStation === '';
    const isEmptyApparatus = selectedApparatus === '';
    const isEmptyWeekdays = activeWeekdays.length === 0;
    const hasNoChange =
      selectedStation === nonShiftDetail.station &&
      selectedApparatus === nonShiftDetail.apparatus &&
      startMinutes === nonShiftDetail.startTime &&
      endMinutes === nonShiftDetail.endTime &&
      breakDuration === nonShiftDetail.breakDuration &&
      eqSet(new Set(currentPayCodes.map((pc) => pc.id)), new Set(nonShiftDetail.payCodes.map((pc) => pc.id))) &&
      eqSet(new Set(activeWeekdays), new Set(nonShiftDetail.activeWeekdays));
    return isEmptyStation || isEmptyApparatus || !isDurationValid || isEmptyWeekdays || hasNoChange;
  }, [
    selectedApparatus,
    selectedStation,
    nonShiftDetail,
    startMinutes,
    endMinutes,
    activeWeekdays,
    breakDuration,
    isDurationValid,
    currentPayCodes,
  ]);

  return (
    <Dialog
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none',
      }}
      open={showModal}
      onClose={() => {
        handleClose(defaultNonShiftDetail);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {hasNonShiftDetail ? 'Edit Non-Shift Assignment' : 'Assign Non-Shift Position'}
            {hasNonShiftDetail && (
              <Button
                sx={(theme) => ({
                  width: '106px',
                  height: '44px',
                  padding: '9px 17px 9px 17px',
                  backgroundColor: theme.palette.common.black,
                  color: theme.palette.common.white,
                  borderRadius: theme.spacing(0.75),
                  '&:hover': { backgroundColor: 'black', boxShadow: 'none' },
                })}
                buttonType="tertiary"
                variant="contained"
                disableFocusRipple={true}
                disableTouchRipple={true}
                onClick={() => handleUnassign()}
                disabled={disableAll}
              >
                <Box component="span" sx={{ typography: 'buttonM' }}>
                  Unassign
                </Box>
              </Button>
            )}
          </Box>
          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[labelGray],
            })}
          >
            Organization
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
              display: 'block',
            })}
          >
            <Input
              value={selectedStation}
              onChange={(event) => {
                setSelectedStation(event.currentTarget.value);
              }}
              placeholder="Enter an organization"
              disabled={disableAll}
            />
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[labelGray],
            })}
          >
            Team
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
              display: 'block',
            })}
          >
            <Input
              value={selectedApparatus}
              onChange={(event) => {
                setSelectedApparatus(event.currentTarget.value);
              }}
              placeholder="Enter a team"
              disabled={disableAll}
            />
          </Box>
          <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(2), mt: theme.spacing(1.5) })}>
            <Box sx={(theme) => ({ flex: 1, display: 'flex', gap: theme.spacing(2) })}>
              <Box>
                <Box
                  sx={(theme) => ({
                    mb: theme.spacing(1),
                    color: theme.palette.stationGray[labelGray],
                    typography: 'bodySMedium',
                  })}
                >
                  Start time
                </Box>
                <TimePicker
                  value={startTime}
                  setValue={setStartTime}
                  sxProps={{ width: '106px', '& .MuiInputBase-input': { pl: 0 } }}
                  disabled={disableAll}
                />
              </Box>
              <Box>
                <Box
                  sx={(theme) => ({
                    mb: theme.spacing(1),
                    color: theme.palette.stationGray[labelGray],
                    typography: 'bodySMedium',
                  })}
                >
                  End time
                </Box>
                <TimePicker
                  value={endTime}
                  setValue={setEndTime}
                  sxProps={{ width: '106px', '& .MuiInputBase-input': { pl: 0 } }}
                  disabled={disableAll}
                />
              </Box>
            </Box>
            <Box sx={(theme) => ({ flex: 1, typography: 'bodySRegular', whiteSpace: 'nowrap', pt: theme.spacing(5) })}>
              <Box
                component="span"
                sx={(theme) => ({ color: theme.palette.stationGray[disableAll ? labelGray : 500], mr: theme.spacing(1) })}
              >
                Duration:
              </Box>
              {(() => {
                if (!isDurationValid) {
                  return (
                    <Box component="span" sx={(theme) => ({ color: theme.palette.stationRose[700] })}>
                      Invalid
                    </Box>
                  );
                }

                const durationHH = Math.floor(durationMinutes / 60);
                const durationMM = durationMinutes - durationHH * 60;
                return (
                  <Box
                    component="span"
                    sx={(theme) => ({ color: theme.palette.stationGray[labelGray] })}
                  >{`${`${durationHH}`.padStart(2, '0')} h ${`${durationMM}`.padStart(2, '0')} m`}</Box>
                );
              })()}
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[labelGray],
            })}
          >
            Break Duration (minutes)
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
              display: 'block',
            })}
          >
            <Input
              value={breakDuration}
              type="number"
              disabled={disableAll}
              onChange={(event) => {
                setBreakDuration(event.currentTarget.value ? parseInt(event.currentTarget.value) : null);
              }}
              placeholder="Enter break duration"
            />
          </Box>
          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[labelGray],
            })}
          >
            Active Weekdays
          </Box>
          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
              display: 'block',
            })}
          >
            <WeekdayPicker
              activeWeekdays={activeWeekdays}
              onSelect={handleActiveWeekdaysClick}
              sx={(theme) => ({ mb: theme.spacing(2.5) })}
              isDisabled={disableAll}
            />
          </Box>
          {currentPayCodes.map((payCode, index) => (
            <CodeAutocomplete
              key={payCode.id}
              label="Non-Shift Pay Code"
              handleChange={(event, payCode) => onPayCodeChange(event, payCode, index)}
              options={
                index === 0
                  ? getSelectablePayCodes(payCode)
                  : [{ id: 0, code: '', name: 'None', payCodeType: '' }, ...getSelectablePayCodes(payCode)]
              }
              value={payCode}
              isDarkBackground={false}
              isDisabled={disableAll}
              showIconAdornment={!defaultPayCodes.map((pc) => pc.code).includes(payCode.code)}
            />
          ))}
          {getSelectablePayCodes().length > 0 && !disableAll && (
            <Box display="flex" justifyContent="end">
              <PreviewButton buttonType="link" onClick={onAddPayCode}>
                + Add pay code
              </PreviewButton>
            </Box>
          )}
          {disableAll && (
            <Box
              sx={(theme) => ({
                mt: theme.spacing(2),
              })}
            >
              <Alert severity="warning">
                You cannot create non-shift assignment for an employee who has active shift assignments. Please unassign the
                employee from any shifts in the <Link href="/app/shift-template">Shift Template Editor</Link> before returning.
              </Alert>
            </Box>
          )}
          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button
              buttonType="tertiary"
              variant="contained"
              style={{
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              }}
              onClick={() => handleClose(nonShiftDetail)}
            >
              <Box component="span" sx={{ typography: 'buttonM' }}>
                Cancel
              </Box>
            </Button>
            <Button
              style={{
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              }}
              sx={(theme) => ({
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                borderRadius: theme.spacing(0.75),
                '&:hover': { backgroundColor: 'black', boxShadow: 'none' },
              })}
              buttonType="tertiary"
              variant="contained"
              disableFocusRipple={true}
              disableTouchRipple={true}
              onClick={() => handleSave()}
              disabled={isDisabled || disableAll}
            >
              <Box component="span" sx={{ typography: 'buttonM' }}>
                {hasNonShiftDetail ? 'Save' : 'Assign'}
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
