import { Box, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import {
  Button,
  User01Icon24,
  MultipleSelect,
  AdminSelect,
  getCertColors,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { RosterApparatus, RosterPosition } from '@stationwise/share-types';
import { useShiftTemplateContext } from '../../contexts/ShiftTemplateContext';
import { buildPosition } from '../../positions';

export const PositionDialog = ({
  isOpen,
  setIsOpen,
  selectedPosition,
  selectedApparatus,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  selectedPosition: RosterPosition | null;
  selectedApparatus: RosterApparatus;
}) => {
  const theme = useTheme();
  const { shiftTemplateHelper, selectedStation, addPosition, editPosition } = useShiftTemplateContext();
  const [position, setPosition] = useState<RosterPosition>(selectedPosition ? selectedPosition : buildPosition());
  const [selectedCertifications, setSelectedCertifications] = useState<string[]>(
    selectedPosition ? selectedPosition.certifications.map((cert) => cert.code) : [],
  );
  const [selectedRank, setSelectedRank] = useState<string>(selectedPosition ? selectedPosition.rank.code : '');

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const certificationOptions = departmentInfoState.departmentInfo?.certifications.map((cert) => ({
    label: cert.name,
    value: cert.code,
    color: getCertColors({ theme, color: cert.color }).color,
  }));

  const rankOptions = departmentInfoState.departmentInfo?.ranks.map((rank) => ({
    label: rank.name,
    value: rank.code,
  }));

  useEffect(() => {
    if (selectedPosition) {
      setPosition(selectedPosition);
    } else {
      setPosition(buildPosition());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPosition, isOpen]);

  const onSave = () => {
    const payload = {
      ...position,
      rank: departmentInfoState.departmentInfo?.ranks.find((rank) => rank.code === selectedRank) || position.rank,
      certifications: departmentInfoState.departmentInfo?.certifications.filter((cert) =>
        selectedCertifications.includes(cert.code),
      ),
      startDateTime: shiftTemplateHelper.shiftDuration.startTime,
      endDateTime: shiftTemplateHelper.shiftDuration.endTime,
      apparatusId: selectedApparatus.id,
    };

    if (selectedPosition && selectedApparatus && selectedStation) {
      editPosition(payload, selectedApparatus, selectedStation);
    } else if (selectedApparatus && selectedStation) {
      addPosition(payload, selectedApparatus, selectedStation);
    }
    closeDialog();
  };

  const closeDialog = () => {
    setIsOpen(false);
  };
  return (
    <Dialog open={isOpen} onClose={closeDialog} scroll="paper">
      <DialogTitle>
        <Box sx={(theme) => ({ display: 'flex', justifyContent: 'center', mt: theme.spacing(1) })}>
          {!selectedPosition && (
            <Box
              sx={(theme) => ({
                borderRadius: '80px',
                backgroundColor: theme.palette.common.white,
                p: theme.spacing(2),
                border: `1px solid ${theme.palette.stationGray[50]}`,
                boxShadow: '0px 4px 6px -1px rgba(10, 14, 22, 0.10), 0px 2px 4px -1px rgba(10, 14, 22, 0.06)',
                '& svg path': { stroke: theme.palette.stationIndigo[600] },
                width: '56px',
                height: '56px',
                mb: theme.spacing(2),
              })}
            >
              <User01Icon24 />
            </Box>
          )}
        </Box>
        <Box sx={{ textAlign: 'center', typography: 'bodyXLSemibold' }}>
          {selectedPosition ? 'Edit Position' : 'Add Position'}
        </Box>
        {!selectedPosition && (
          <Box
            sx={(theme) => ({ textAlign: 'center', typography: 'bodyMRegular', mt: theme.spacing(0.5) })}
          >{`to ${selectedStation?.stationName}, ${selectedApparatus.name}`}</Box>
        )}
      </DialogTitle>
      <DialogContent>
        <Box width="428px">
          <Box
            sx={(theme) => ({
              pt: theme.spacing(1.5),
              justifyContent: 'center',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              '.MuiFormControl-root': {
                mb: 2,
                width: '100%',
                '.MuiInputLabel-root': {
                  top: '-5px',
                },
              },
            })}
          >
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                textAlign: 'left',
                color: theme.palette.stationGray[700],
              })}
            >
              Rank
            </Box>

            <Box
              sx={(theme) => ({
                py: theme.spacing(1),
              })}
            >
              {rankOptions && (
                <AdminSelect
                  items={rankOptions}
                  selectedItem={selectedRank}
                  setSelectedItem={setSelectedRank}
                  placeholder="Choose rank"
                />
              )}
            </Box>

            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                textAlign: 'left',
                color: theme.palette.stationGray[700],
              })}
            >
              Requirements
            </Box>

            <Box
              sx={(theme) => ({
                py: theme.spacing(1),
                display: 'block',
              })}
            >
              {certificationOptions && (
                <MultipleSelect
                  items={certificationOptions}
                  selectedItems={selectedCertifications}
                  setSelectedItems={setSelectedCertifications}
                  placeholder="Add certification"
                />
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={(theme) => ({
            justifyContent: 'center',
            display: 'flex',
            gap: '16px',
            mt: theme.spacing(2),
            mb: theme.spacing(2),
            width: '100%',
          })}
        >
          <Button
            buttonType="tertiary"
            variant="contained"
            sx={() => ({
              width: '45%',
              height: '44px',
              padding: '9px 17px 9px 17px',
            })}
            onClick={closeDialog}
          >
            <Box component="span" sx={{ typography: 'buttonM', letterSpacing: '0.03em' }}>
              Cancel
            </Box>
          </Button>
          <Button
            sx={(theme) => ({
              width: '45%',
              height: '44px',
              padding: '9px 17px 9px 17px',
              backgroundColor: theme.palette.stationBlue[700],
              color: theme.palette.common.white,
              borderRadius: theme.spacing(0.75),
              '&:hover': { backgroundColor: theme.palette.stationBlue[700], boxShadow: 'none' },
            })}
            buttonType="tertiary"
            variant="contained"
            disableFocusRipple={true}
            disableTouchRipple={true}
            disabled={!selectedRank}
            onClick={onSave}
          >
            <Box component="span" sx={{ typography: 'buttonM', letterSpacing: '0.03em' }}>
              Save
            </Box>
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
