import { LoggedInEmployeeDataView, DetailFieldsStaffingList, OvertimeListView, Team } from '@stationwise/share-types';

export interface OvertimeInfo {
  hireBackList: OvertimeListView[];
  overallPosition: number;
  shiftPosition: number;
  shiftName: string;
  shiftColor: string;
}

export const buildOvertimeInfoObject = (
  list: OvertimeListView[],
  loggedInEmployee: LoggedInEmployeeDataView | null,
): OvertimeInfo => {
  const loggedEmployeeInList = list?.find(
    (item: OvertimeListView) => item.employee?.id.toString() === loggedInEmployee?.id?.toString(),
  );
  return {
    hireBackList: list,
    overallPosition:
      list?.findIndex((item: OvertimeListView) => item.employee?.id.toString() === loggedInEmployee?.id?.toString()) + 1 || 0,
    shiftPosition:
      list?.filter(
        (item: OvertimeListView) =>
          item.employee?.employeeTeam?.id === loggedEmployeeInList?.employee?.employeeTeam?.id &&
          item.order < loggedEmployeeInList?.order,
      ).length + 1,
    shiftName: loggedEmployeeInList?.employee?.employeeTeam?.name || '',
    shiftColor: loggedEmployeeInList?.employee?.employeeTeam?.color || '',
  };
};

export const buildStaffingListObject = (list: DetailFieldsStaffingList, me: LoggedInEmployeeDataView): OvertimeInfo => {
  const meItem = list.items.find((item) => item.employee.id === me.id);
  const meTeam = meItem?.employee.team;

  let meTeamPosition = 0;
  if (meTeam) {
    meTeamPosition = list.items.filter((item) => item.employee.team?.id === meTeam.id && item.order < meItem.order).length + 1;
  }

  return {
    overallPosition: meItem?.order ?? 0,
    shiftPosition: meTeamPosition,
    shiftName: meTeam?.name || '',
    shiftColor: meTeam?.color || '',
    hireBackList: list.items.map((item) => {
      const [firstName, ...lastNames] = item.employee.name.split(' ');
      const employeeTeam = item.employee.team as Team;
      return {
        order: item.order,
        employee: { ...item.employee, firstName, lastName: lastNames.join(' '), employeeTeam },
        overtime: {
          lastShift: item.attributes.lastThreeOvertimes?.[0]?.date ?? null,
          lastOtStaffTime: item.attributes.lastThreeOvertimes?.[0]?.staffedAt ?? undefined,
          partialHours: item.attributes.overtimeAccHours ?? null,
          secondOt: item.attributes.lastThreeOvertimes?.[1]?.date,
          thirdOt: item.attributes.lastThreeOvertimes?.[2]?.date,
        },
      };
    }),
  };
};
