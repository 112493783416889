import { PayCode } from '@stationwise/share-types';

export enum REQUEST_CARD_STATUS {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  DENIED = 'Denied',
}

export enum REQUEST_CARD_ACTION {
  APPROVE = 'Approve',
  DENY = 'Deny',
  POSTPONE = 'Postpone',
}

export interface RequestCardEmployee {
  name: string;
}

interface RequestCardAbstractRequest {
  createdAt: string;
  expiresAt: string;
  id: number;
  postponedUntil: string | null;
  reviewedBy: RequestCardEmployee | null;
  reviewedAt: string | null;
  reasonResponse: string | null;
  shiftDate: {
    date: string;
  };
}

interface RequestCardShiftTradeRequest extends RequestCardAbstractRequest {
  requestType: 'ShiftTradeRequest';
  sender: RequestCardEmployee;
  receiver: RequestCardEmployee;
  duration: number;
  startTimeStr: string;
  startDate: string;
}

interface RequestCardTimeOffRequest extends RequestCardAbstractRequest {
  requestType: 'TimeOffRequest';
  timeOffType: string;
  payCode: string;
  employee: RequestCardEmployee;
  note: string;
  isPartial: boolean;
  duration: number;
  startTimeStr: string;
  startDate: string;
  limit: number;
  waitlistOrder: number;
}

interface RequestCardAdditionalPaidTimeRequest extends RequestCardAbstractRequest {
  requestType: 'AdditionalPaidTimeRequest';
  payCode: PayCode;
  employee: RequestCardEmployee;
  note: string;
  duration: number;
  startTimeStr: string;
  startDate: string;
}

export interface RequestCardCompTimeRequest extends RequestCardAbstractRequest {
  requestType: 'CompTimeRequest';
  employee: RequestCardEmployee;
  duration: number;
  startTimeStr: string;
  startDate: string;
  requestedHours: number;
  bankedByFarHours: number;
}

export type RequestCardRequest =
  | RequestCardShiftTradeRequest
  | RequestCardTimeOffRequest
  | RequestCardAdditionalPaidTimeRequest
  | RequestCardCompTimeRequest;
