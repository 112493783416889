import { REQUEST_CARD_STATUS } from '../RequestCard/types';
import { useGetRequests } from './useGetRequests';

const { PENDING, APPROVED, DENIED } = REQUEST_CARD_STATUS;

export const useRequests = (refetchCounter: number) => {
  return {
    [PENDING]: useGetRequests({ status: PENDING, refetchCounter }),
    [APPROVED]: useGetRequests({ status: APPROVED, refetchCounter }),
    [DENIED]: useGetRequests({ status: DENIED, refetchCounter }),
  };
};
