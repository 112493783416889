import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { useTheme } from '@mui/material';
import React, { createContext, useContext } from 'react';
import { moveEmployee } from '@stationwise/shift-summary-helper';
import { UseRosterReturnType } from '../../hooks/useRoster';
import { DragOverlayCard } from '../DragOverlayCard';

const RosterContext = createContext<UseRosterReturnType | null>(null);

interface Props {
  roster: UseRosterReturnType;
  children: React.ReactNode;
}

export const RosterContextProvider = ({ roster, children }: Props) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      // The pointer needs to be moved 4px before a drag start event is emitted. This is needed to distinguish click vs drag.
      activationConstraint: { distance: 4 },
    }),
    useSensor(KeyboardSensor),
  );
  const theme = useTheme();

  return (
    <RosterContext.Provider value={roster}>
      <DndContext
        onDragStart={(event) => {
          roster.setDraggingId(String(event.active.id || ''));
        }}
        onDragEnd={(event) => {
          const collisionParams =
            event.over?.id &&
            event.active.id &&
            moveEmployee({
              overId: String(event.over.id),
              activeId: String(event.active.id),
              shiftSummaryHelper: roster.shiftSummaryHelper,
              selectedStaffingList: roster.staffingListsState.selectedStaffingList,
              employeeOffPayloads: roster.employeeOffPayloads,
            });
          if (collisionParams) {
            const { over, active, newActive, error, ...newShiftSummaryHelper } = collisionParams;
            roster.setShiftSummaryHelper(newShiftSummaryHelper);
            roster.setUserHasMadeChanges(true);
            const newEmployee = newActive ? newActive.employee : active.employee;
            error && roster.setLastChanges({ shiftSummaryHelper: roster.shiftSummaryHelper, employee: newEmployee, error });
          }
          roster.setDraggingId(null);
        }}
        onDragCancel={() => roster.setDraggingId(null)}
        sensors={sensors}
      >
        {children}
        <DragOverlay dropAnimation={null} zIndex={theme.zIndex.modal}>
          <DragOverlayCard />
        </DragOverlay>
      </DndContext>
    </RosterContext.Provider>
  );
};

export const useRosterContext = () => {
  const context = useContext(RosterContext);
  if (!context) {
    throw new Error('useRosterContext must be within a RosterContextProvider');
  }
  return context;
};
