import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  AccrualsCard,
  Loader,
  SnackbarService,
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { EmployeeNextShiftView, ShiftTradesView, TimeOffLimit } from '@stationwise/share-types';
import { useAccrualWarning } from '../../hooks/useAccrualWarning';
import { AccrualWarningModal } from './AccrualModals/AccrualWarningModal';
import { NextShiftCard } from './NextShiftCard';
import { NoUpcomingShiftCard } from './NextShiftCard/NoUpcomingShiftCard';
import { NextShiftTrades } from './NextShiftTrades';
import { NoShiftTrades } from './NextShiftTrades/NoShiftTrades';
import { PendingMessages } from './PendingMessages';

interface Props {
  isLoadingNextShift: boolean;
  isErrorNextShift: boolean;
  errorNextShift: string;
  dataNextShift: EmployeeNextShiftView | null;
  isLoadingAccruals: boolean;
  isErrorAccruals: boolean;
  errorAccruals: string;
  dataAccruals: TimeOffLimit[] | null;
  isLoadingShiftTrades: boolean;
  isErrorShiftTrades: boolean;
  errorShiftTrades: string;
  dataShiftTrades: ShiftTradesView | null;
  forceRefetchShiftTrades: () => void;
}

export const DashboardContent = ({
  isLoadingNextShift,
  isErrorNextShift,
  errorNextShift,
  dataNextShift,
  isLoadingAccruals,
  isErrorAccruals,
  errorAccruals,
  dataAccruals,
  isLoadingShiftTrades,
  isErrorShiftTrades,
  errorShiftTrades,
  dataShiftTrades,
  forceRefetchShiftTrades,
}: Props) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isAccrualsEnabled = departmentInfoState.departmentInfo.settings.accrualLogicEnabled;
  const unreadMessages = authUserState.employee.unreadMessages.length ?? 0;
  const { accrualWarningModalOpen, closeAccrualWarningModal } = useAccrualWarning(authUserState, departmentInfoState);
  const [searchParams] = useSearchParams();
  const bulkCallError = searchParams.get('bulk_call_error');
  useEffect(() => {
    if (bulkCallError) {
      SnackbarService.notify({
        content: 'You are not eligible for this Overtime Sign Up Request or it does not exist.',
        severity: 'error',
        duration: 5000,
      });
    }
  }, [bulkCallError]);

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.stationGray[100],
          height: '100%',
          maxWidth: '768px',
          margin: '0 auto',
        })}
      >
        <Box
          sx={(theme) => ({
            mt: theme.spacing(2.5),
            ml: { xs: theme.spacing(2) },
          })}
        >
          <Typography variant="bodyXXLSemibold">My Dashboard</Typography>
        </Box>
        <Box
          sx={(theme) => ({
            ml: { xs: theme.spacing(2) },
            mt: theme.spacing(2.5),
            mr: theme.spacing(2),
          })}
        >
          <Box>
            {isLoadingNextShift && <Loader />}
            {isErrorNextShift && <div>{`Something went wrong please try again later ${errorNextShift}`}</div>}
            {!isLoadingNextShift && dataNextShift && (
              <NextShiftCard employeeNextShift={dataNextShift} loggedInEmployeeId={authUserState.employee.id} />
            )}
            {!isLoadingNextShift && !dataNextShift && !isErrorNextShift && <NoUpcomingShiftCard />}
          </Box>

          {!authUserState.employee.isNonShift && (
            <Box sx={(theme) => ({ mt: theme.spacing(3), mb: theme.spacing(3) })}>
              {(() => {
                if (isLoadingShiftTrades) {
                  return <Loader />;
                } else if (isErrorShiftTrades) {
                  return <div>{`Something went wrong please try again later ${errorShiftTrades}`}</div>;
                } else if (
                  dataShiftTrades &&
                  ((dataShiftTrades.nextShiftTrade && Object.keys(dataShiftTrades.nextShiftTrade).length > 0) ||
                    dataShiftTrades.balance?.length > 0 ||
                    dataShiftTrades.receivedShiftTrades?.length > 0 ||
                    dataShiftTrades.sentShiftTrades?.length > 0 ||
                    dataShiftTrades.waitingApproval?.length > 0)
                ) {
                  return (
                    <Box sx={(theme) => ({ mt: theme.spacing(3) })}>
                      <NextShiftTrades shiftTrades={dataShiftTrades} forceRefetchShiftTrades={forceRefetchShiftTrades} />
                    </Box>
                  );
                } else return <NoShiftTrades />;
              })()}
            </Box>
          )}

          <Box sx={(theme) => ({ mt: theme.spacing(3), mb: theme.spacing(3) })}>
            {isLoadingAccruals && <Loader />}
            {isErrorAccruals && <div>{`Something went wrong please try again later ${errorAccruals}`}</div>}
            {!isLoadingAccruals && dataAccruals && isAccrualsEnabled && <AccrualsCard accruals={dataAccruals} />}
          </Box>
        </Box>
      </Box>
      {unreadMessages > 0 && <PendingMessages />}
      <AccrualWarningModal
        accrualWarningModalOpen={accrualWarningModalOpen}
        closeAccrualWarningModal={closeAccrualWarningModal}
      />
    </>
  );
};
