import { RosterStation, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import { getBoardEmployees } from '../board/employee';
import { checkIsShift } from '../id';
import { IShiftSummaryHelper, RequirementCount } from '../types';
import { getApparatusCertifications, getStationCertifications, getMissingCertifications } from './certification';
import { mergeConsecutiveWorkDurations } from './overtime';
import { checkIsActingAsRank, getActingAsRankCounts, getApparatusRanks } from './rank';

export const getTotalStaffed = (station: RosterStation) => {
  const count = { total: 0, amount: 0 };

  station.apparatuses.forEach((apparatus) => {
    apparatus.positions.forEach((position) => {
      count.amount += position.employees.filter((employee) => !!employee.id).length;
      count.total += Math.max(1, position.employees.length);
    });
  });
  return count;
};

export const stationHasMinRequirements = (station: RosterStation) => {
  const checkCount = ({ total, amount }: RequirementCount) => amount >= total;

  return (
    checkCount(getTotalStaffed(station)) &&
    Array.from(getStationCertifications(station).values()).every(checkCount) &&
    station.apparatuses.every((apparatus) => Array.from(getApparatusRanks(apparatus).values()).every(checkCount)) &&
    station.apparatuses.every((apparatus) => Array.from(getApparatusCertifications(apparatus).values()).every(checkCount))
  );
};

export const getPositionRequirementErrorMessages = (
  shiftSummaryHelper: IShiftSummaryHelper,
  position: RosterPosition,
  employee: RosterEmployee,
) => {
  const messages: string[] = [];

  const missingCertifications = getMissingCertifications(position.certifications, employee.certifications);
  if (missingCertifications.length > 0) {
    const codes = missingCertifications.map((cert) => cert.code).join(', ');
    const message = `This person is missing ${codes} certification and is unable to perform this role. Please, staff a qualified person`;
    messages.push(message);
  }

  const { maxActorsRule } = shiftSummaryHelper;
  const hasMaxActorsRule = !!(maxActorsRule.byRank || maxActorsRule.byTotal);
  if (hasMaxActorsRule && checkIsShift(employee) && checkIsActingAsRank(shiftSummaryHelper, position, employee)) {
    const { totalCount, rankCounts } = getActingAsRankCounts(shiftSummaryHelper);
    const rankCount = rankCounts.get(position.rank.code) ?? 0;
    const maxRankCount = maxActorsRule.byRank?.[position.rank.code];
    if (typeof maxRankCount === 'number' && rankCount > maxRankCount) {
      let message = `At most ${maxRankCount} people are allowed to act as ${position.rank.code} rank`;
      if (maxRankCount === 1) {
        message = `At most 1 person is allowed to act as ${position.rank.code} rank`;
      }
      messages.push(message);
    }

    const maxTotalCount = maxActorsRule.byTotal;
    if (typeof maxTotalCount === 'number' && totalCount > maxTotalCount) {
      let message = `At most ${maxTotalCount} people in total are allowed to act as another rank`;
      if (maxTotalCount === 1) {
        message = 'At most 1 person in total is allowed to act as another rank';
      }
      messages.push(message);
    }
  }

  return messages;
};

export const getOvertimeRequirementErrorMessages = (shiftSummaryHelper: IShiftSummaryHelper, employee: RosterEmployee) => {
  const messages: string[] = [];

  const { maxConsecutiveHoursRule, shiftDuration } = shiftSummaryHelper;
  if (typeof maxConsecutiveHoursRule.consecutiveHours === 'number') {
    const consecutiveWorkDurations = mergeConsecutiveWorkDurations(
      employee.maybeConsecutiveWorkDurations || [],
      getBoardEmployees(shiftSummaryHelper).filter((e) => e.id === employee.id),
      [],
      shiftDuration,
      maxConsecutiveHoursRule.breakHours ?? 0,
    );
    const consecutiveWorkHours = Math.max(...consecutiveWorkDurations.map((d) => d.hours));
    if (consecutiveWorkHours > maxConsecutiveHoursRule.consecutiveHours) {
      const message = `This person is working ${consecutiveWorkHours} consecutive hours, which exceeds the maximum ${maxConsecutiveHoursRule.consecutiveHours} hours`;
      messages.push(message);
    }
  }

  return messages;
};
