import { RosterEmployee } from '@stationwise/share-types';
import { SnackbarService } from '../../../../Snackbar';

export const notifyEmployeeNoteChanged = (employee: RosterEmployee, newNote: string) => {
  const oldNote = employee.noteOverride?.note || '';

  let title = '';
  if (!oldNote && newNote) {
    title = 'Note created';
  } else if (oldNote && !newNote) {
    title = 'Note deleted';
  } else if (oldNote !== newNote) {
    title = 'Note updated';
  }

  if (title) {
    SnackbarService.notify({
      severity: 'success',
      title,
      content: 'This change will be added to the Change Log.',
      duration: 5000,
    });
  }
};
