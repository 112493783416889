import { Box, Theme, useTheme } from '@mui/material';
import { EyeIcon16 } from '@stationwise/component-module';

const getChip = (type: string, theme: Theme) => {
  switch (type) {
    case 'delivered':
      return {
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.stationGray[200],
        color: theme.palette.stationGray[700],
        text: 'Delivered',
        icon: null,
      };
    case 'read':
      return {
        backgroundColor: theme.palette.stationGreen[100],
        borderColor: theme.palette.stationGreen[100],
        color: theme.palette.stationGreen[600],
        text: 'Read',
        icon: <EyeIcon16 color={theme.palette.stationGreen[500]} />,
      };
    case 'completed':
      return {
        backgroundColor: theme.palette.stationGreen[500],
        borderColor: theme.palette.stationGreen[500],
        color: theme.palette.common.white,
        text: 'Completed',
        icon: null,
      };
    default:
      return null;
  }
};

export interface ChipProps {
  type: 'delivered' | 'read' | 'completed';
}

export const Chip = ({ type }: ChipProps) => {
  const theme = useTheme();
  const chipInfo = getChip(type, theme);

  if (!chipInfo) {
    return null;
  }

  return (
    <Box
      sx={{
        padding: '4px 12px',
        border: `1px solid ${chipInfo.borderColor}`,
        maxWidth: '96px',
        width: '96px',
        backgroundColor: chipInfo.backgroundColor,
        borderRadius: '32px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        {chipInfo.icon && (
          <Box
            sx={{
              height: '16px',
              width: '16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '& svg': {
                stroke: chipInfo.color,
              },
            }}
          >
            {chipInfo.icon}
          </Box>
        )}
        <Box sx={{ color: chipInfo.color, typography: 'bodySMedium' }}>{chipInfo.text}</Box>
      </Box>
    </Box>
  );
};
