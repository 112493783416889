import { Box, Checkbox, Link as MUILink } from '@mui/material';
import React, { memo } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CardCertBadges, CustomTable, getShiftColor, LetterAvatar, TableProps } from '@stationwise/component-module';
import { Employee, Rank } from '@stationwise/share-types';
import { capitalize } from '@stationwise/share-utils';
import { getShiftColorData } from './MyTeamMain';

interface TeamSettingsTableProps {
  employees: Employee[];
  selectedEmployeeIds: string[];
  setSelectedEmployeeIds: React.Dispatch<React.SetStateAction<string[]>>;
  hideCheckbox?: boolean;
  setSelectedRanks: React.Dispatch<React.SetStateAction<Rank[]>>;
}

export const TeamSettingsTable = memo(
  ({ employees, selectedEmployeeIds, setSelectedEmployeeIds, hideCheckbox, setSelectedRanks }: TeamSettingsTableProps) => {
    const navigate = useNavigate();
    const getRowProps = (row: TableProps['data'][number]) => {
      return {
        onClick: () => navigate(`/app/employees/${row['employee'].id}`),
        sx: { cursor: 'pointer' },
      };
    };

    const handleClick = (id: string, event?: React.MouseEvent<HTMLElement>) => {
      if (event) {
        event.stopPropagation();
      }

      if (id === '-1') {
        if (selectedEmployeeIds.length === employees.length) {
          setSelectedEmployeeIds([]);
          setSelectedRanks([]);
        } else {
          setSelectedEmployeeIds(employees.map((emp) => emp.id));
          setSelectedRanks(employees.map((emp) => emp.rank) as Rank[]);
        }
      } else {
        setSelectedEmployeeIds((prev) => {
          const isSelected = prev.includes(id);
          const updatedIds = isSelected ? prev.filter((item) => item !== id) : [...prev, id];

          if (isSelected) {
            setSelectedRanks((prevRanks) => {
              const employee = employees.find((emp) => emp.id === id);
              return prevRanks.filter((rank) => rank.id !== employee?.rank?.id);
            });
          } else {
            setSelectedRanks((prevRanks) => {
              const employee = employees.find((emp) => emp.id === id);
              const rank = employee?.rank;
              if (!rank) return prevRanks;
              const isRankInArray = prevRanks.some((item) => item.id === rank.id);
              if (!isRankInArray) {
                return [...prevRanks, rank];
              }
              return prevRanks;
            });
          }

          return updatedIds;
        });
      }
    };

    const columns: TableProps['columns'] = [
      {
        id: 'checkbox',
        label: hideCheckbox ? (
          ''
        ) : (
          <Checkbox
            checked={selectedEmployeeIds.length === employees.length}
            sx={(theme) => ({
              width: '20px',
              height: '20px',
              borderRadius: '6px',
              border: `1px solid ${theme.palette.stationGray[200]}`,
              boxShadow: '0px 1px 2px 0px #0A0E160D',
              color: theme.palette.common.white,
              padding: '2px',
              '&.Mui-checked': {
                color: theme.palette.stationPurple[500],
                border: `1px solid ${theme.palette.stationPurple[400]}`,
              },
            })}
            onClick={(event: React.MouseEvent<HTMLElement>) => handleClick('-1', event)}
          />
        ),
        minWidth: 20,
        align: 'center',
      },
      { id: 'name', label: 'NAME', minWidth: 100, align: 'left' },
      { id: 'rank', label: 'RANK', minWidth: 100, align: 'center' },
      { id: 'station', label: 'STATION', minWidth: 100, align: 'center' },
      { id: 'apparatus', label: 'APPARATUS', minWidth: 100, align: 'center' },
      { id: 'shift', label: 'SHIFT', minWidth: 100, align: 'center' },
      { id: 'employee_id', label: 'EMPL. ID', minWidth: 50, align: 'center' },
      {
        id: 'certifications',
        label: 'CERTIFICATIONS',
        minWidth: 100,
        align: 'left',
      },
      { id: 'is_active', label: 'ACCOUNT STATUS', minWidth: 100, align: 'center' },
    ];

    const data: TableProps['data'] = employees.map((employee) => ({
      employee,

      checkbox: hideCheckbox ? (
        ''
      ) : (
        <Checkbox
          checked={selectedEmployeeIds.includes(employee.id)}
          sx={(theme) => ({
            width: '20px',
            height: '20px',
            borderRadius: '6px',
            border: `1px solid ${theme.palette.stationGray[200]}`,
            boxShadow: '0px 1px 2px 0px #0A0E160D',
            color: theme.palette.common.white,
            padding: '2px',
            '&.Mui-checked': {
              color: theme.palette.stationPurple[500],
              border: `1px solid ${theme.palette.stationPurple[400]}`,
            },
          })}
          onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(employee.id, event)}
        />
      ),

      name: (
        <MUILink
          component={RouterLink}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.stopPropagation();
          }}
          to={`/app/employees/${employee.id}`}
          underline="none"
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing(1),
            alignItems: 'center',
          })}
        >
          <LetterAvatar firstName={employee.name} color={employee.hasAdminPermission ? 'green' : 'lightblue'} />
          <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>
            <Box>{employee.name}</Box>
            <Box sx={(theme) => ({ color: theme.palette.stationGray[600] })}>{employee.email}</Box>
          </Box>
        </MUILink>
      ),

      rank: (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>
          {employee.rank?.name || 'N/A'}
        </Box>
      ),
      station: (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>
          {employee.plannedShiftDetails && employee.plannedShiftDetails.length > 0
            ? employee.plannedShiftDetails[0].station
            : 'Unassigned'}
        </Box>
      ),

      apparatus: (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>
          {employee.plannedShiftDetails && employee.plannedShiftDetails.length > 0
            ? employee.plannedShiftDetails[0].apparatus
            : 'Unassigned'}
        </Box>
      ),
      shift:
        employee.plannedShiftDetails && employee.plannedShiftDetails.length > 0 ? (
          <Box
            sx={(theme) => ({
              backgroundColor: getShiftColor(getShiftColorData(employee.plannedShiftDetails), theme),
              color: theme.palette.common.white,
              borderRadius: theme.spacing(2.5),
              padding: theme.spacing(0.25, 1),
              typography: 'bodySRegular',
            })}
          >
            {capitalize(employee.plannedShiftDetails[0].teamName)}
          </Box>
        ) : (
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.stationGray[500],
              borderRadius: theme.spacing(2.5),
              color: theme.palette.common.white,
              padding: theme.spacing(0.25, 1),
              typography: 'bodySRegular',
            })}
          >
            Unassigned
          </Box>
        ),
      employee_id: (
        <Box
          sx={(theme) => ({
            color: theme.palette.stationGray[600],
            typography: 'bodySRegular',
          })}
        >
          {employee.payrollId}
        </Box>
      ),
      certifications: employee.certifications ? (
        <CardCertBadges certs={employee.certifications} />
      ) : (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>N/A</Box>
      ),
      is_active: (
        <Box
          sx={(theme) => ({
            color: theme.palette.stationGray[600],
            typography: 'bodySRegular',
          })}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: employee.isActive ? theme.palette.stationGreen[500] : theme.palette.stationGray[500],
              color: theme.palette.common.white,
              borderRadius: theme.spacing(2.5),
              padding: theme.spacing(0.25, 1),
              typography: 'bodySRegular',
            })}
          >
            {employee.isActive ? 'Active' : 'Inactive'}
          </Box>
        </Box>
      ),
    }));

    return <CustomTable columns={columns} data={data} getRowProps={getRowProps} />;
  },
);
