import { useCallback, useState } from 'react';
import { RosterCreateTimeOffPayload } from '@stationwise/share-types';

export const useSplitShiftOrTimeOff = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [payloads, setPayloads] = useState<RosterCreateTimeOffPayload[]>([]);

  const resetState = useCallback(() => {
    setIsDialogOpen(false);
    setPayloads([]);
  }, []);

  return { isDialogOpen, setIsDialogOpen, payloads, setPayloads, resetState };
};
