import { Address, CertificationRequirement, RosterDataSource, RosterStation } from '@stationwise/share-types';
import { IShiftSummaryHelper } from '@stationwise/shift-summary-helper';
import { ManageApparatusesPayload } from './apparatuses';
import { sendApparatusEmployeesToFloaterStation } from './assignments';

export interface StationPayload {
  name: string;
  address?: Address;
  certificationRequirements: CertificationRequirement[];
  apparatusesPayload?: ManageApparatusesPayload;
}

export interface ManageStationsPayload {
  create: StationPayload[];
  update: { id: string; station: StationPayload }[];
  delete: string[];
}

export const buildAddress = () => {
  return {
    street1: '',
    city: '',
    postalCode: '',
    state: '',
  };
};

export const buildStation = (stationPayload: StationPayload) => {
  const newStation: RosterStation = {
    stationId: stationPayload.name,
    dataSource: RosterDataSource.STATION,
    apparatuses: [],
    stationName: stationPayload.name,
    address: stationPayload.address || buildAddress(),
    certificationRequirements: stationPayload.certificationRequirements,
    isNewlyCreated: true,
  };

  return newStation;
};

export const createStation = (shiftTemplateHelper: IShiftSummaryHelper, newStation: RosterStation) => {
  const newAllStationCards = new Map();

  for (const [stationId, station] of shiftTemplateHelper.allStationCards.entries()) {
    const copyStation = { ...station };

    // add new station in the last place before floater station
    if (stationId === 'floater-station') {
      newAllStationCards.set(newStation.stationId, newStation);
    }
    newAllStationCards.set(stationId, copyStation);
  }
  return { ...shiftTemplateHelper, allStationCards: newAllStationCards };
};

export const updateStation = (shiftTemplateHelper: IShiftSummaryHelper, stationToUpdate: RosterStation) => {
  const newAllStationCards = new Map();

  for (const [stationId, station] of shiftTemplateHelper.allStationCards.entries()) {
    const copyStation =
      stationId === stationToUpdate.stationId
        ? {
            ...station,
            stationName: stationToUpdate.stationName,
            address: stationToUpdate.address,
            certificationRequirements: stationToUpdate.certificationRequirements,
          }
        : { ...station };
    newAllStationCards.set(stationId, copyStation);
  }
  return { ...shiftTemplateHelper, allStationCards: newAllStationCards };
};

export const deleteStation = (shiftTemplateHelper: IShiftSummaryHelper, stationToRemoveId: string) => {
  const newAllStationCards = new Map();
  let newShiftTemplateHelper = { ...shiftTemplateHelper };

  for (const [stationId, station] of shiftTemplateHelper.allStationCards.entries()) {
    if (stationId !== stationToRemoveId) {
      const copyStation = { ...station };
      newAllStationCards.set(stationId, copyStation);
    } else {
      for (let index = 0; index < station.apparatuses.length; index++) {
        newShiftTemplateHelper = sendApparatusEmployeesToFloaterStation(
          newShiftTemplateHelper,
          station.apparatuses[index],
        ).updatedShiftTemplateHelper;
      }
    }
  }
  return { ...newShiftTemplateHelper, allStationCards: newAllStationCards };
};

export const addCreateToStationsPayload = (manageStationsPayload: ManageStationsPayload, stationToCreate: RosterStation) => {
  const stationPayload: StationPayload = {
    name: stationToCreate.stationName,
    address: stationToCreate.address,
    certificationRequirements: stationToCreate.certificationRequirements,
    apparatusesPayload: {
      create: [],
      update: [],
      delete: [],
    },
  };

  return { ...manageStationsPayload, create: [...manageStationsPayload.create, stationPayload] };
};

export const addRemoveToStationsPayload = (manageStationsPayload: ManageStationsPayload, stationToRemove: RosterStation) => {
  let newCreates = [...manageStationsPayload.create];
  let newDeletes = [...manageStationsPayload.delete];
  let newUpdates = [...manageStationsPayload.update];

  // avoid sending a create for a station that is being removed
  const isNewlyCreated = manageStationsPayload.create.some((payload) => payload.name === stationToRemove.stationName);

  if (isNewlyCreated) {
    newCreates = newCreates.filter((payload) => payload.name !== stationToRemove.stationName);
  } else {
    newDeletes = [...manageStationsPayload.delete, stationToRemove.stationId];
  }

  // avoid sending updates for a station that is being removed
  newUpdates = newUpdates.filter((payload) => payload.id !== stationToRemove.stationId);

  return {
    ...manageStationsPayload,
    create: newCreates,
    delete: newDeletes,
    update: newUpdates,
  };
};

export const addUpdateToStationsPayload = (manageStationsPayload: ManageStationsPayload, stationToUpdate: RosterStation) => {
  const stationPayload: StationPayload = {
    name: stationToUpdate.stationName,
    address: stationToUpdate.address || buildAddress(),
    certificationRequirements: stationToUpdate.certificationRequirements,
  };

  const newManageStationsPayload = { ...manageStationsPayload };

  const existentNewStationIndex = newManageStationsPayload.create.findIndex(
    (payload) => payload.name === stationToUpdate.stationName || payload.name === stationToUpdate.stationId,
  );

  // if the user is editing a station that has not been saved in the db yet, we add the updated payload in the create array
  if (existentNewStationIndex >= 0) {
    const newCreates = [...newManageStationsPayload.create];
    newCreates[existentNewStationIndex] = { ...newCreates[existentNewStationIndex], ...stationPayload };
    newManageStationsPayload.create = newCreates;
  } else {
    const newUpdates = [...newManageStationsPayload.update];
    const stationToUpdateIndex = newUpdates.findIndex((update) => update.id === stationToUpdate.stationId);

    if (stationToUpdateIndex >= 0) {
      newUpdates[stationToUpdateIndex].station = { ...newUpdates[stationToUpdateIndex].station, ...stationPayload };
    } else {
      newUpdates.push({ id: stationToUpdate.stationId, station: stationPayload });
    }

    newManageStationsPayload.update = newUpdates;
  }
  return newManageStationsPayload;
};
