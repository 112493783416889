import { Box, Button, Pagination } from '@mui/material';
import { captureException } from '@sentry/react';
import React, { useDeferredValue, useEffect, useRef, useState } from 'react';
import {
  exportData,
  LottieLogo,
  SearchAndFilter,
  SearchInput,
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { Certification, DetailCode, PayCodeComplete, RankComplete } from '@stationwise/share-types';
import { camelCaseToTitle, capitalize } from '@stationwise/share-utils';
import { CertificationModal } from './Certifications/CertificationsModal';
import { CertificationsTable } from './Certifications/CertificationsTable';
import { DepartmentSettingsTabProps, ElementTypes, FilterOptions } from './DepartmentSettingsUtil';
import { DetailCodeModal } from './DetailCode/DetailCodeModal';
import { DetailCodeTable } from './DetailCode/DetailCodeTable';
import { PayCodeModal } from './PayCode/PayCodeModal';
import { PayCodeTable } from './PayCode/PayCodeTable';
import { RankModal } from './Rank/RankModal';
import { RanksTable } from './Rank/RanksTable';

export const DepartmentSettingsTab = ({
  elements,
  selectedElement,
  setElements,
  setSelectedElement,
  createUpdateUrl,
  elementType,
  isFilterLowerCase,
  exportUrl,
}: DepartmentSettingsTabProps) => {
  const {
    state: {
      departmentInfo: { certifications: certificationOptions, payPeriodTypes, payCodeOptions, payCodes: existingPayCodes },
    },
  } = useLoadedDepartmentInfoContext();
  const {
    state: {
      employee: { role: userRole },
    },
  } = useLoadedAuthUserContext();
  const isSuperAdmin = userRole === 'SUPER_ADMIN';
  const [showEditModal, setShowEditModal] = useState(false);

  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const deferredSearchInput = useDeferredValue(searchInput);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [refetchCounter, setRefetchCounter] = useState(0);
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await client.get(createUpdateUrl + 'filter_options/');
        if (response.data) {
          setFilterOptions(response.data);
        }
      } catch (error) {
        captureException(error);
      }
    };
    if (elementType !== ElementTypes.CERTIFICATION) fetchFilterOptions();
  }, [createUpdateUrl, elementType]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      try {
        const filterParams = Object.fromEntries(Object.entries(selectedFilters).filter(([_, value]) => value));
        const params = {
          page: page,
          ...(deferredSearchInput && { search: deferredSearchInput }),
          ...filterParams,
        };
        const response = await client.get(createUpdateUrl, {
          params,
          signal: abortControllerRef.current.signal,
        });
        if (response.data.results && Array.isArray(response.data.results)) {
          setElements(response.data.results);
          setTotalPages(Math.ceil(response.data.count / 100));
        }
        setIsLoading(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && captureException(error);
        !isCanceled && setIsLoading(false);
      }
    };
    fetchData();
  }, [page, deferredSearchInput, refetchCounter, selectedFilters, createUpdateUrl, setElements]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleFilterChange = (filterName: string, value: string) => {
    setPage(1);
    setSelectedFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const handleExport = async () => {
    if (!exportUrl) return;
    await exportData(exportUrl, {}, elementType.toLowerCase().replace(/ /g, '_') + 's.csv');
  };

  const filters = Object.entries(filterOptions).map(([filterName, options]) => ({
    name: camelCaseToTitle(capitalize(filterName)),
    options: options.map((option) => ({
      label: (() => {
        return elementType === ElementTypes.PAY_CODE || elementType === ElementTypes.DETAIL_CODE ? option : option.toUpperCase();
      })(),
      value: (() => {
        return isFilterLowerCase ? option.toLowerCase() : capitalize(option);
      })(),
    })),
    selected: selectedFilters[filterName],
    onChange: (value: string) => handleFilterChange(filterName, value),
  }));

  const clearAllFilters = () => {
    setSelectedFilters({});
  };

  return (
    <Box
      sx={{
        height: '100%',
        overflowY: 'scroll',
      }}
    >
      <Box
        sx={(theme) => ({
          m: theme.spacing(1),
        })}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          {filters ? (
            <SearchAndFilter
              searchText={searchInput}
              setSearchText={setSearchInput}
              filters={filters}
              clearAllFilters={clearAllFilters}
              searchPlaceholder={`Search for ${elementType}`}
            />
          ) : (
            <SearchInput value={searchInput} setValue={setSearchInput} color="white" placeHolder={`Search for ${elementType}`} />
          )}
          <Box>
            {isSuperAdmin && (
              <Button
                variant="contained"
                sx={(theme) => ({
                  backgroundColor: theme.palette.common.black,
                  borderRadius: 1.5,
                  mr: theme.spacing(1),
                  p: theme.spacing(1),
                  textTransform: 'none',
                })}
                onClick={() => {
                  setShowEditModal(true);
                  setSelectedElement(undefined);
                }}
              >
                {`+ Create new ${elementType}`}
              </Button>
            )}
            {isSuperAdmin && exportUrl && (
              <Button
                variant="contained"
                sx={(theme) => ({
                  backgroundColor: theme.palette.common.black,
                  borderRadius: 1.5,
                  p: theme.spacing(1),
                  textTransform: 'none',
                })}
                onClick={handleExport}
              >
                {`Export ${elementType} CSV`}
              </Button>
            )}
          </Box>
        </Box>
        {isLoading ? (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
            <LottieLogo height="200px" width="200px" />
          </Box>
        ) : (
          (() => {
            switch (elementType) {
              case ElementTypes.RANK:
                return (
                  <RanksTable
                    ranks={elements}
                    handleEditAction={(rank: RankComplete) => {
                      setShowEditModal(true);
                      setSelectedElement(rank);
                    }}
                  />
                );
              case ElementTypes.CERTIFICATION:
                return (
                  <CertificationsTable
                    certifications={elements}
                    handleEditAction={(certification: Certification) => {
                      setShowEditModal(true);
                      setSelectedElement(certification);
                    }}
                  />
                );
              case ElementTypes.DETAIL_CODE:
                return (
                  <DetailCodeTable
                    detailCodes={elements}
                    handleEditAction={(detailCode: DetailCode) => {
                      setShowEditModal(true);
                      setSelectedElement(detailCode);
                    }}
                  />
                );
              case ElementTypes.PAY_CODE:
                return (
                  <PayCodeTable
                    existingPayCodes={existingPayCodes}
                    payCodes={elements}
                    handleEditAction={(payCode: PayCodeComplete) => {
                      setShowEditModal(true);
                      setSelectedElement(payCode);
                    }}
                  />
                );

              default:
                return null;
            }
          })()
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {totalPages > 1 && !isLoading && <Pagination count={totalPages} page={page} onChange={handlePageChange} sx={{ my: 2 }} />}
      </Box>
      {showEditModal &&
        (() => {
          switch (elementType) {
            case ElementTypes.RANK:
              return (
                <RankModal
                  certifications={certificationOptions}
                  onSaveSuccess={() => {
                    setRefetchCounter((prev) => prev + 1);
                  }}
                  payPeriodTypes={payPeriodTypes}
                  selectedRank={selectedElement ? elements.find((rank) => rank.id === selectedElement.id) : undefined}
                  setShowModal={setShowEditModal}
                  showModal={showEditModal}
                />
              );
            case ElementTypes.CERTIFICATION:
              return (
                <CertificationModal
                  onSaveSuccess={() => {
                    setRefetchCounter((prev) => prev + 1);
                  }}
                  selectedCertification={
                    selectedElement ? elements.find((certification) => certification.id === selectedElement.id) : undefined
                  }
                  setShowModal={setShowEditModal}
                  showModal={showEditModal}
                />
              );
            case ElementTypes.DETAIL_CODE:
              return (
                <DetailCodeModal
                  onSaveSuccess={() => {
                    setRefetchCounter((prev) => prev + 1);
                  }}
                  payCodes={payCodeOptions}
                  selectedDetailCode={
                    selectedElement ? elements.find((detailCode) => detailCode.id === selectedElement.id) : undefined
                  }
                  setShowModal={setShowEditModal}
                  showModal={showEditModal}
                />
              );
            case ElementTypes.PAY_CODE:
              return (
                <PayCodeModal
                  extendedPayCodes={existingPayCodes}
                  onSaveSuccess={() => {
                    setRefetchCounter((prev) => prev + 1);
                  }}
                  payCodes={payCodeOptions}
                  existingPayCodes={(selectedElement
                    ? existingPayCodes.filter((element) => element.id !== selectedElement.id)
                    : existingPayCodes
                  ).map((element) => element.code)}
                  selectedPayCode={selectedElement ? elements.find((payCode) => payCode.id === selectedElement.id) : undefined}
                  setShowModal={setShowEditModal}
                  showModal={showEditModal}
                />
              );

            default:
              return null;
          }
        })()}
    </Box>
  );
};
