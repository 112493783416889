import { Box, ListItem, List, ListItemButton, ListItemText, ListItemIcon, Drawer, useTheme } from '@mui/material';
import { useState } from 'react';
import {
  CheckIcon20,
  LetterAvatar,
  SvgIcon,
  XCloseIcon16,
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
  useSwitchDepartmentMenuItems,
} from '@stationwise/component-module';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { INavMenu } from '../../../app/AppPageLayout';
import { getIconData, MenuIconTypes } from '../SideBar/MenuIcon';

const drawerWidth = '100%';

interface Props {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  navItems: INavMenu[];
  handleSelectItem: (item: INavMenu) => void;
  selectedMenu: MenuIconTypes | null;
}

export const DrawerMenu = ({ handleDrawerToggle, mobileOpen, navItems, selectedMenu, handleSelectItem }: Props) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const switchDepartmentMenuItems = useSwitchDepartmentMenuItems();
  const theme = useTheme();
  const [isSwitchDepartmentOpen, setIsSwitchDepartmentOpen] = useState(false);

  const handleCloseDrawer = () => {
    if (isSwitchDepartmentOpen) {
      setIsSwitchDepartmentOpen(false);
    } else {
      handleDrawerToggle();
    }
  };

  const selectItem = (item: INavMenu) => {
    if (item.type === 'change-department') {
      setIsSwitchDepartmentOpen(true);
    } else {
      handleDrawerToggle();
      handleSelectItem(item);
    }
  };

  const splitDepartmentName = (name: string) => {
    const [firstName, lastName] = name.split(' ');
    return { firstName, lastName };
  };

  const currentDepartmentNames = splitDepartmentName(departmentInfoState.departmentInfo.name);

  const drawer = (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: '64px', px: 2, borderBottom: `1px dashed ${theme.palette.stationGray[100]}` }}
      >
        <Box sx={{ color: theme.palette.stationGray[900], typography: 'bodyLSemibold' }}>
          {isSwitchDepartmentOpen ? 'Change department' : 'Menu'}
        </Box>
        <Box
          onClick={handleCloseDrawer}
          sx={{
            width: '28px',
            height: '28px',
            borderRadius: '20px',
            background: theme.palette.stationGray[100],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.stationGray[500],
          }}
        >
          <XCloseIcon16 />
        </Box>
      </Box>
      {!isSwitchDepartmentOpen && (
        <List sx={{ pt: theme.spacing(1.5) }}>
          {navItems.map((item) => {
            const iconData = getIconData(item.type);
            const isActive = selectedMenu === item.type;
            const isLogout = item.type === 'logout';
            return (
              <ListItem
                data-testid={`menu-item-${makeTestIdentifier(item.type)}`}
                data-cy={`menu-item-${makeTestIdentifier(item.type)}`}
                onClick={() => selectItem(item)}
                key={item.type}
                disablePadding
                sx={{
                  mt: isLogout ? theme.spacing(4) : theme.spacing(1.5),
                  height: '64px',
                  background: isActive ? theme.palette.stationGray[100] : theme.palette.common.white,
                  borderLeft: isActive ? `solid 4px ${theme.palette.stationGray[900]}` : 'none',
                  '& svg': {
                    color: isActive ? theme.palette.stationGray[900] : 'inherit',
                  },
                }}
              >
                <ListItemButton>
                  {isLogout ? (
                    <Box sx={(theme) => ({ ml: theme.spacing(1) })}>
                      <LetterAvatar firstName={authUserState.employee.firstName} size="xsmall" color="purple" />
                    </Box>
                  ) : (
                    <ListItemIcon>
                      <SvgIcon component={isActive ? iconData.ActiveComponent : iconData.Component} />
                    </ListItemIcon>
                  )}

                  <ListItemText
                    primaryTypographyProps={{ variant: 'bodyXLMedium' }}
                    primary={
                      isLogout ? (
                        <Box
                          component="span"
                          sx={(theme) => ({
                            mx: theme.spacing(1),
                            typography: 'eyebrow',
                            color: theme.palette.stationGray[900],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          })}
                        >
                          {`${authUserState.employee.firstName.toUpperCase()} ${authUserState.employee.lastName.toUpperCase()}`}
                          <Box
                            component="span"
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              gap: 1,
                              '& svg': {
                                color: theme.palette.stationGray[400],
                              },
                            }}
                          >
                            <SvgIcon component={isActive ? iconData.ActiveComponent : iconData.Component} />
                            <Box
                              component="span"
                              sx={(theme) => ({ typography: 'eyebrow', color: theme.palette.stationGray[900] })}
                            >
                              {iconData.title.toUpperCase()}
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box component="span">{iconData.title}</Box>
                      )
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
      {isSwitchDepartmentOpen && (
        <List sx={{ pt: theme.spacing(1.5) }}>
          <ListItem sx={{ mt: theme.spacing(1.5), height: '64px' }}>
            <ListItemIcon>
              <LetterAvatar {...currentDepartmentNames} color="cyan" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ variant: 'bodyXLMedium' }}
              primary={departmentInfoState.departmentInfo.name}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '28px', height: '28px' }}>
              <CheckIcon20 />
            </Box>
          </ListItem>
          {switchDepartmentMenuItems.map((item) => (
            <ListItem key={item.key} disablePadding sx={(theme) => ({ mt: theme.spacing(1.5), height: '64px' })}>
              <ListItemButton onClick={item.onClick}>
                <ListItemIcon>
                  <LetterAvatar {...splitDepartmentName(item.name)} color="cyan" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'bodyXLMedium' }} primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );

  return (
    <Box component="nav">
      <Drawer
        container={window.document.body}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: 'block',
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            position: 'absolute',
            top: '0px',
            borderRadius: '0px',
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
