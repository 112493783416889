import { Box, List, ListItem } from '@mui/material';
import { parse, format } from 'date-fns';
import { useState, Dispatch, SetStateAction } from 'react';
import {
  Button,
  CalendarIcon16,
  ChevronDownIcon20,
  SnackbarService,
  getDepartmentFeatureFlagValue,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { PostOvertimeRequestDTO } from '@stationwise/share-types';
// import { useIOSPaddingBottom } from '../../../../../../../../apps/ui/src/context/DeviceInfoContext';

interface RequestOvertimeProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setRefetchEvents: Dispatch<SetStateAction<boolean>>;
  selectedOvertimeDates: string[];
  setSelectedOvertimeDates: Dispatch<SetStateAction<string[]>>;
}

export const RequestOvertime = ({
  open,
  setOpen,
  setRefetchEvents,
  selectedOvertimeDates,
  setSelectedOvertimeDates,
}: RequestOvertimeProps) => {
  const [isListView, setIsListView] = useState(false);
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isOptOut = departmentInfoState.departmentInfo.settings.voluntaryOvertimeOptOut;
  const isStaffingListsEnabled = getDepartmentFeatureFlagValue(departmentInfoState, 'STAFFING_LISTS', false);

  // const iosWebViewPaddingBottom = useIOSPaddingBottom();
  const iosWebViewPaddingBottom = 0;

  const handleOnClose = () => {
    setOpen(false);
    setSelectedOvertimeDates([]);
    setIsListView(false);
  };
  const handleShowMoreClick = () => {
    setIsListView(!isListView);
  };

  const requestOvertime = async (requestDto: PostOvertimeRequestDTO) => {
    let isRequestError = false;
    try {
      if (isStaffingListsEnabled) {
        const response = await client.put('/staffing-list/staffing-lists/vot/my-marks/', {
          status: 'REQUESTED',
          dates: requestDto.selected_dates.map((date) => format(parse(date, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd')),
        });
        isRequestError = response.status !== 200;
      } else {
        const response = await client.post(`/shift/overtimes/`, requestDto);
        isRequestError = response.status !== 201;
      }

      if (!isRequestError) {
        setRefetchEvents(true);
      }
      handleOnClose();
    } catch {
      isRequestError = true;
    }
    SnackbarService.notify({
      content: !isRequestError
        ? isOptOut
          ? 'You successfully opted out of overtime for the selected days.'
          : 'You successfully requested overtime for the selected days.'
        : 'There was an issue processing your request.',
      severity: !isRequestError ? 'success' : 'error',
      showCloseButton: true,
      duration: 5000,
    });
  };

  const handleRequestOvertime = () => {
    // Format dates to MM/dd/yyyy
    const formattedDates = selectedOvertimeDates.map((dateString) => {
      const dateObject = parse(dateString, 'MM/dd/yy', new Date());
      return format(dateObject, 'MM/dd/yyyy');
    });

    const requestDto = {
      selected_dates: formattedDates,
    };
    requestOvertime(requestDto);

    handleOnClose();
  };
  if (open) {
    // The `spacer` ensures the `content` doesn't overlap with whatever is rendered above this component.
    // The height of the `spacer` is roughly the height of the `content` when `See all` is in the collapsed state.
    const spacer = <Box sx={{ height: '148px' }} />;
    const content = (
      <Box
        sx={(theme) => ({
          position: 'fixed',
          bottom: iosWebViewPaddingBottom,
          width: 'calc(100% - 16px)',
          margin: theme.spacing(1),
          border: '1px solid #DEE3ED',
          borderRadius: '16px',
          boxShadow: '0px -10px 15px -3px rgba(10, 14, 22, 0.1)',
          background: theme.palette.common.white,
          zIndex: theme.zIndex.modal,
          maxWidth: '768px',
        })}
      >
        <Box
          sx={(theme) => ({
            p: theme.spacing(2),
            typography: 'bodyMMedium',
          })}
        >
          {isOptOut ? 'Select days you want to opt out of overtime' : 'Select days you want to request overtime'}
        </Box>

        {selectedOvertimeDates.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              width: '100%',
            }}
          >
            {isListView ? (
              <List sx={{ p: 0 }}>
                {selectedOvertimeDates.map((date, index) => (
                  <ListItem key={index} sx={{ pb: 0 }}>
                    <Box
                      sx={(theme) => ({
                        mr: theme.spacing(1),
                        display: 'flex',
                        '& svg': {
                          '& path': {
                            stroke: theme.palette.stationGray[400],
                          },
                        },
                      })}
                    >
                      <CalendarIcon16 />
                    </Box>
                    <Box sx={{ typography: 'bodyMRegular' }}>{date}</Box>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box
                sx={(theme) => ({
                  px: theme.spacing(2),
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  width: '65%',
                  typography: 'bodyMRegular',
                })}
              >
                {selectedOvertimeDates.join(', ')}
              </Box>
            )}

            <Box
              onClick={handleShowMoreClick}
              sx={(theme) => ({
                color: theme.palette.stationPurple[600],
                alignItems: 'center',
                justifyContent: 'center',
                px: theme.spacing(2),
              })}
            >
              {isListView ? (
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    alignItems: 'center',
                    '& svg': {
                      '& path': {
                        stroke: theme.palette.stationPurple[600],
                      },
                    },
                  })}
                >
                  <ChevronDownIcon20 />
                  <Box
                    sx={(theme) => ({
                      ml: theme.spacing(0.5),
                      typography: 'bodyMMedium',
                    })}
                  >
                    Collapse
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={(theme) => ({
                    ml: theme.spacing(0.5),
                    typography: 'bodyMMedium',
                  })}
                >
                  See all
                </Box>
              )}
            </Box>
          </Box>
        )}

        <Box
          sx={(theme) => ({
            width: '100%',
            p: theme.spacing(1),
            justifyContent: 'center',
            display: 'flex',
          })}
        >
          <Box
            sx={(theme) => ({
              width: '50%',
              m: theme.spacing(1),
            })}
          >
            <Button
              data-cy="request-overtime-cancel-button"
              disableFocusRipple={true}
              disableRipple={true}
              variant="contained"
              buttonType="tertiary"
              onClick={() => handleOnClose()}
              sx={(theme) => ({
                background: theme.palette.common.white,
                color: theme.palette.common.black,
                width: '100%',
                borderWidth: theme.spacing(0.1),
                textTransform: 'none',
                borderRadius: theme.spacing(0.75),
              })}
            >
              Cancel
            </Button>
          </Box>
          <Box sx={(theme) => ({ width: '50%', m: theme.spacing(1) })}>
            <Button
              data-cy="request-overtime-submit-button"
              variant="contained"
              disabled={selectedOvertimeDates?.length > 0 ? false : true}
              disableFocusRipple={true}
              disableRipple={true}
              onClick={() => handleRequestOvertime()}
              sx={(theme) => ({
                background: theme.palette.common.black,
                width: '100%',
                textTransform: 'none',
                borderRadius: theme.spacing(0.75),
              })}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    );
    return (
      <>
        {spacer}
        {content}
      </>
    );
  }

  return null;
};
