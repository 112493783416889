import { Box } from '@mui/material';
import { differenceInHours, format } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Option, RequestDurationOptions } from '@stationwise/component-module';
import { StartTime } from '@stationwise/share-types';
import { generateStartTimes } from '@stationwise/share-utils';
import { RequestStartTimes } from '../RequestSharedComponents/RequestStartTimes';

interface TimeOffTimeSelectProps {
  originalStartDateTime: string;
  startDateTime: string;
  setStartDateTime: Dispatch<SetStateAction<string>>;
  duration: number;
  setDuration: Dispatch<SetStateAction<number>>;
  originalEndDateTime: string;
  durationError: boolean;
  timeOffDurationOptions: Option[];
}

export const TimeOffTimeSelect = ({
  originalStartDateTime,
  startDateTime,
  setStartDateTime,
  originalEndDateTime,
  duration,
  setDuration,
  durationError,
  timeOffDurationOptions,
}: TimeOffTimeSelectProps) => {
  const [timeOffStartOptions, setTimeOffStartOptions] = useState<StartTime[]>([]);

  useEffect(() => {
    const maxDuration = Math.abs(
      differenceInHours(new Date(startDateTime), new Date(originalEndDateTime), { roundingMethod: 'ceil' }),
    );
    const result = generateStartTimes(format(startDateTime, 'HH:mm'), maxDuration);

    if (duration !== maxDuration) {
      setDuration(maxDuration);
    }

    setTimeOffStartOptions(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          typography: 'bodyLMedium',
        })}
      >
        Time off start
      </Box>
      <RequestStartTimes
        startDateTime={startDateTime}
        setStartDateTime={setStartDateTime}
        originalStartDateTime={originalStartDateTime}
        startOptions={timeOffStartOptions}
        setDuration={setDuration}
        title="Time off start"
      />
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          mt: theme.spacing(4),
          typography: 'bodyLMedium',
        })}
      >
        Time off duration
      </Box>
      <RequestDurationOptions
        durationOptions={timeOffDurationOptions}
        startDateTime={startDateTime}
        duration={duration}
        setDuration={setDuration}
        originalEndDateTime={originalEndDateTime}
        durationError={durationError}
        originalStartDateTime={originalStartDateTime}
      />
    </Box>
  );
};
