import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import {
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
  MedicalCrossIcon16 as EMSIcon,
  Mail01Icon16 as MailIcon,
  CheckCircleBrokenIcon16 as CheckIcon,
  useGetConversations,
  StandIcon16,
  Loader,
  GenericDrawer,
  ErrorPage,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { ConversationListView, ConversationCategory } from '@stationwise/share-types';
import { makeTestIdentifier, PUSHER_EVENT_TYPES, PUSHER_UPDATE_MESSAGE, RefreshEventCallback } from '@stationwise/share-utils';
import { Message } from './Message/';
import { MessageHeader } from './Message/MessageHeader';
import { MessageTopBar } from './Message/MessageTopBar';
import { Recipients } from './Message/Recipients';
import { SelectedConversation } from './Message/SelectedConversation';
import { CONVERSATION_CATEGORIES } from './Message/constants';
import { MessageComposer } from './MessageComposer';
import { NoMessages } from './NoMessages';
const conversationCategories: ConversationCategory[] = [
  { name: 'All', id: '0' },
  { name: 'General', id: '1' },
  { name: 'To-Do', id: '2' },
  { name: 'EMS', id: '3' },
];

const getCategoryIcon = (category: string) => {
  switch (category) {
    case CONVERSATION_CATEGORIES.GENERAL:
      return <MailIcon />;
    case CONVERSATION_CATEGORIES.EMS:
      return <EMSIcon />;
    case CONVERSATION_CATEGORIES.TO_DO:
      return <CheckIcon />;
    case CONVERSATION_CATEGORIES.TRAINING:
      return <StandIcon16 />;
    default:
      return;
  }
};

interface MessagesPanelProps {
  isCreatingMessage: boolean;
  setIsCreatingMessage: (value: boolean) => void;
  canSendMessages: boolean;
}

export const MessagesPanel = ({ isCreatingMessage, setIsCreatingMessage, canSendMessages }: MessagesPanelProps) => {
  const theme = useTheme();
  const [selected, setSelected] = useState(0);
  const [selectedConversation, setSelectedConversation] = useState<ConversationListView | null>(null);
  const [showRecipients, setShowRecipients] = useState(false);
  const { state: authUserState, dispatch: dispatchAuthUserState } = useLoadedAuthUserContext();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const refreshTriggerChannel = departmentInfoState.refreshTriggerChannel;

  const { data: conversations, isLoading, isError, forceRefetch } = useGetConversations();

  useEffect(() => {
    if (!refreshTriggerChannel) return;

    const handlePusherUpdate: RefreshEventCallback = (data) => {
      if (data.triggerAll) {
        forceRefetch();
      } else if (data.message === PUSHER_UPDATE_MESSAGE) {
        if (data.employeeIdList.some((id) => `${id}` === authUserState.employee.id)) {
          forceRefetch();
        }
      }
    };

    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.MESSAGE];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
    };
  }, [authUserState.employee.id, forceRefetch, refreshTriggerChannel]);

  const handleClose = () => {
    setShowRecipients(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
  };
  // toggleRead is true when we are reading/unreading a message
  // markAsCompleted is true when we are marking as completed a TO-DO message
  const updateConversation = async (conversationId: number, toggleRead: boolean, markAsCompleted: boolean) => {
    const conversationToUpdate = conversations.find((conversation) => conversation.id === conversationId);
    const recipientToUpdate = conversationToUpdate?.recipients.find((r) => `${r.employee.id}` === authUserState.employee.id);
    if (recipientToUpdate) {
      await updateConversationRecipient(recipientToUpdate.id, toggleRead, markAsCompleted);

      // updates unread message ids in context
      if (toggleRead) {
        const newUnreadMessageIds = new Set(authUserState.employee.unreadMessages);
        if (newUnreadMessageIds.has(conversationId)) {
          newUnreadMessageIds.delete(conversationId);
        } else {
          newUnreadMessageIds.add(conversationId);
        }
        dispatchAuthUserState({ type: 'SET_USER_UNREAD_MESSAGES.SUCCESS', payload: [...newUnreadMessageIds] });
      }
    }
  };

  const updateConversationRecipient = async (conversationRecipientId: number, toggleRead: boolean, markAsCompleted: boolean) => {
    try {
      await client.put(`conversation/conversation-recipient/${conversationRecipientId}/`, {
        toggleRead: toggleRead,
        markAsCompleted: markAsCompleted,
      });
    } catch (error) {
      captureException(error);
    }
  };

  const handleChevronRightClick = () => {
    setShowRecipients(true);
  };

  const selectConversation = (conversation: ConversationListView) => {
    setSelectedConversation(conversation);
  };

  const tabSxProps = {
    textTransform: 'none',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    padding: '4px 12px',
    margin: '0px 4px',
    borderRadius: '28px',
    color: theme.palette.stationGray[900],
    backgroundColor: theme.palette.stationGray[100],
    mt: theme.spacing(2),
    mb: theme.spacing(2),
    minHeight: '24px',
    minWidth: '24px',
    '&.Mui-selected': {
      color: theme.palette.stationGray[100],
      backgroundColor: theme.palette.stationGray[900],
    },
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        minHeight: {
          xs: 'calc(100vh - 110px)', // TopBar and Inbox subnavigation
          lg: 'calc(100vh - 64px)', // Inbox subnavigation only
        },
        position: 'relative',
      })}
    >
      {!isCreatingMessage && !selectedConversation && (
        <Box>
          <Tabs
            value={selected}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              borderBottom: 'none',
              pl: theme.spacing(1.5),
              pr: theme.spacing(1.5),
              '& .MuiTabs-indicator': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {conversationCategories.map((cat) => (
              <Tab
                key={cat.id}
                data-cy={`tab-${makeTestIdentifier(cat.name)}`}
                sx={tabSxProps}
                icon={getCategoryIcon(cat.name)}
                iconPosition="start"
                label={cat.name}
              />
            ))}
          </Tabs>
        </Box>
      )}
      {isError && <ErrorPage />}
      {!isCreatingMessage && isLoading && (
        <Box sx={{ mt: '200px' }}>
          <Loader />
        </Box>
      )}
      {/* //Inbox regular view */}
      {!isCreatingMessage && !selectedConversation && !isLoading && !isError && (
        <Box>
          {conversations.length === 0 ||
          (selected !== 0 &&
            conversations.filter((conversation) => conversation.category === conversationCategories[selected].name).length ===
              0) ? (
            <NoMessages selectedCategory={selected !== 0 ? conversationCategories[selected].name : undefined} />
          ) : (
            conversations.map(
              (conversation, index) =>
                (selected === 0 || conversation.category === conversationCategories[selected].name) && (
                  <Box
                    key={`box_${conversation.category}_${conversation.id}`}
                    data-cy={`conversation-${index}`}
                    sx={(theme) => ({
                      borderBottom: `solid 1px ${theme.palette.stationGray[200]}`,
                      pb: '10px',
                      ml: theme.spacing(2),
                      mr: theme.spacing(2),
                    })}
                  >
                    <Message
                      key={`${conversation.category}_${conversation.id}`}
                      conversation={conversation}
                      updateConversation={updateConversation}
                      selectConversation={selectConversation}
                      refetchConversations={forceRefetch}
                    />
                  </Box>
                ),
            )
          )}
        </Box>
      )}
      {/* Selected message */}
      {!isCreatingMessage && selectedConversation && (
        <>
          <MessageTopBar
            selectedConversation={selectedConversation}
            setSelectedConversation={setSelectedConversation}
            updateConversation={updateConversation}
            refetchConversations={forceRefetch}
          />
          <MessageHeader selectedConversation={selectedConversation} handleChevronRightClick={handleChevronRightClick} />
          <SelectedConversation selectedConversation={selectedConversation} updateConversation={updateConversation} />

          <GenericDrawer
            anchor="bottom"
            drawerOpen={showRecipients}
            handleOnClose={handleClose}
            loading={false}
            showHeader={true}
            disableFooter={true}
            headerTitle={'Recipients'}
            sxProps={() => ({ '& .MuiDrawer-paper': { height: '100%' } })}
          >
            <Box sx={{ mb: theme.spacing(2) }}>
              <Recipients recipients={selectedConversation.recipients} />
            </Box>
          </GenericDrawer>
        </>
      )}

      {isCreatingMessage && canSendMessages && (
        <MessageComposer refetchConversations={forceRefetch} setIsCreatingMessage={setIsCreatingMessage} />
      )}
    </Box>
  );
};
