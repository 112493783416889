import { Box, useTheme } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import {
  Badge,
  CustomTable,
  Loader,
  SearchAndFilter,
  TableProps,
  ToggleWithLabel,
  getColor,
  renameMandatoryOvertimeTitle,
  useFetchOvertimeList,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { OvertimeListView } from '@stationwise/share-types';
import { capitalize } from '@stationwise/share-utils';

export const OvertimeListMain = () => {
  const [searchInput, setSearchInput] = useState('');
  const [selectedShift, setSelectedShift] = useState('');
  const [selectedWorkDate, setSelectedWorkDate] = useState('');
  const theme = useTheme();
  const { state: departmentContext } = useLoadedDepartmentInfoContext();

  const date = new Date();
  const formatted_date = format(date, 'MM/dd/yyyy');
  const { isLoading, data: overtimes } = useFetchOvertimeList({
    shiftDate: formatted_date,
    skip: false,
    skipStaffed: false,
    skipFloaters: false,
    skipVoluntary: false,
  });

  const [isMandatory, setIsMandatory] = useState(true);
  const toggleOtType = () => {
    setIsMandatory(!isMandatory);
  };

  const columns: TableProps['columns'] = [
    { id: 'no', label: 'NO.', minWidth: 10, align: 'left' },
    { id: 'firstName', label: 'FIRST NAME', minWidth: 10, align: 'left' },
    { id: 'lastName', label: 'LAST NAME', minWidth: 10, align: 'left' },
    { id: 'shift', label: 'SHIFT', minWidth: 10, align: 'left' },
    {
      id: 'lastOTStaffTime',
      label: 'LAST OVERTIME STAFF TIME',
      minWidth: 10,
      align: 'left',
    },
    {
      id: 'lastOTWorkDate',
      label: 'LAST OT WORK DATE',
      minWidth: 10,
      align: 'left',
    },
    {
      id: 'secondOTWorkDate',
      label: '2ND OT WORK DATE',
      minWidth: 10,
      align: 'left',
    },
    {
      id: 'thirdOTWorkDate',
      label: '3RD OT WORK DATE',
      minWidth: 10,
      align: 'left',
    },
    {
      id: 'partialHours',
      label: 'PARTIAL HOURS',
      minWidth: 10,
      align: 'left',
    },
  ];

  const filterByShift = (data: OvertimeListView[]) => {
    return data.filter(
      (ot) => ot.employee.employeeTeam && ot.employee.employeeTeam.name.toLowerCase() === selectedShift.toLowerCase(),
    );
  };
  const filterBySearch = (data: OvertimeListView[]) => {
    return data.filter(
      (ot) =>
        ot.employee.firstName.toLowerCase().includes(searchInput.toLowerCase()) ||
        ot.employee.lastName.toLowerCase().includes(searchInput.toLowerCase()),
    );
  };
  const filterByWorkDate = (data: OvertimeListView[]) => {
    return data.filter((ot) => ot.overtime.lastShift && ot.overtime.lastShift === selectedWorkDate);
  };

  let data: TableProps['data'] = [];
  if (overtimes) {
    let filteredData = isMandatory ? overtimes.mandatoryOvertimeList : overtimes.voluntaryOvertimeList;

    if (selectedShift) {
      filteredData = filterByShift(filteredData);
    }

    if (searchInput) {
      filteredData = filterBySearch(filteredData);
    }

    if (selectedWorkDate) {
      filteredData = filterByWorkDate(filteredData);
    }

    data = filteredData.map((ot) => ({
      no: ot.order,
      firstName: ot.employee.firstName,
      lastName: ot.employee.lastName,
      shift: (
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing(1),
            alignItems: 'center',
          })}
        >
          {ot.employee.employeeTeam && ot.employee.employeeTeam.color && (
            <Badge
              label={capitalize(ot.employee.employeeTeam.name)}
              isRound
              type="sm"
              isCustom={true}
              customBackgroundColor={getColor(theme, ot.employee.employeeTeam.color).color}
              customColor="white"
            />
          )}
        </Box>
      ),
      lastOTStaffTime: ot.overtime.lastOtStaffTime ? format(ot.overtime.lastOtStaffTime, 'MMM dd, yyyy | HH:mm.ss') : '',
      lastOTWorkDate: ot.overtime.lastShift ? format(parseISO(ot.overtime.lastShift), 'MMM dd, yyyy') : '',
      secondOTWorkDate: ot.overtime.secondOt ? format(parseISO(ot.overtime.secondOt), 'MMM dd, yyyy') : '',
      thirdOTWorkDate: ot.overtime.thirdOt ? format(parseISO(ot.overtime.thirdOt), 'MMM dd, yyyy') : '',
      partialHours: ot.overtime.partialHours,
    }));
  }

  const prepareFilterItems = () => {
    const uniqueDates = new Set<string>();
    const uniqueShiftNames = new Set<string>();

    const overtimesList = isMandatory ? overtimes?.mandatoryOvertimeList : overtimes?.voluntaryOvertimeList;
    overtimesList?.forEach((item) => {
      item.overtime.lastShift && uniqueDates.add(item.overtime.lastShift);
      item.employee.employeeTeam && uniqueShiftNames.add(item.employee.employeeTeam.name);
    });

    const distinctWorkDates: { value: string; label: string }[] = Array.from(uniqueDates).map((workDate) => ({
      value: workDate,
      label: workDate ? format(parseISO(workDate), 'MMM dd, yyyy') : '',
    }));

    const distinctShiftNames: { value: string; label: string }[] = Array.from(uniqueShiftNames).map((shiftName) => ({
      value: shiftName,
      label: shiftName,
    }));
    return {
      distinctWorkDates,
      distinctShiftNames,
    };
  };

  const workDateFilterItems = prepareFilterItems().distinctWorkDates;
  const shiftFilterItems = prepareFilterItems().distinctShiftNames;

  const clearAllFilters = () => {
    setSelectedShift('');
    setSelectedWorkDate('');
  };

  const filters = [
    {
      name: 'Shift',
      options: shiftFilterItems,
      selected: selectedShift,
      onChange: setSelectedShift,
    },
    {
      name: 'Work date',
      options: workDateFilterItems,
      selected: selectedWorkDate,
      onChange: setSelectedWorkDate,
    },
  ];

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'scroll',
      })}
    >
      <Box
        component="h1"
        sx={(theme) => ({
          m: theme.spacing(4, 5, 8, 5),
          typography: 'heading4',
        })}
      >
        Overtime List
      </Box>
      <Box
        sx={{
          width: '75%',
          mx: 'auto',
          boxShadow: 'none',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '202px' }}>
          <SearchAndFilter
            searchText={searchInput}
            setSearchText={setSearchInput}
            filters={filters}
            clearAllFilters={clearAllFilters}
            searchPlaceholder="Search for people"
          />
        </Box>
        {departmentContext.departmentInfo.settings.separatedOtListEnabled && (
          <ToggleWithLabel
            label={renameMandatoryOvertimeTitle(departmentContext, 'Mandatory OT')}
            isActive={isMandatory}
            onClick={toggleOtType}
          />
        )}
        <CustomTable columns={columns} data={data} />
        {isLoading && (
          <Box
            sx={(theme) => ({
              p: theme.spacing(3),
              mt: theme.spacing(3),
            })}
          >
            <Loader />
          </Box>
        )}
      </Box>
    </Box>
  );
};
