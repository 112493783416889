import { Box } from '@mui/material';
import { theme } from '@stationwise/component-module';
import { EmployeePayroll, PayPeriod } from '@stationwise/share-types';
import { EmployeePayrollSignedContent } from './EmployeePayrollSignedContent';

interface EmployeePayrollSignedProps {
  employeePayroll: EmployeePayroll;
  setSelectedPayPeriod: React.Dispatch<React.SetStateAction<PayPeriod | null>>;
}

export const EmployeePayrollSigned: React.FC<EmployeePayrollSignedProps> = ({ employeePayroll, setSelectedPayPeriod }) => {
  const approvers = [
    {
      name: 'Employee',
      isApprover: false,
      isSigned: employeePayroll.employeeSigned,
    },
    { name: 'Approver 1', isApprover: true, isApproved: employeePayroll.approvalState.managerOne.approved },
    { name: 'Approver 2', isApprover: true, isApproved: employeePayroll.approvalState.managerTwo.approved },
  ];
  if (employeePayroll.approvalState.managerThree) {
    approvers.push({ name: 'Approver 3', isApprover: true, isApproved: employeePayroll.approvalState.managerThree.approved });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: theme.spacing(1.5),
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'left',
        width: '100%',
        paddingX: theme.spacing(3),
        height: theme.spacing(9),
      }}
    >
      {approvers.map((approver, index) => (
        <EmployeePayrollSignedContent
          key={index}
          index={index}
          approverName={approver.name}
          isApprover={approver.isApprover}
          isSigned={approver.isSigned}
          isApproved={approver.isApproved}
          employeePayroll={employeePayroll}
          setSelectedPayPeriod={setSelectedPayPeriod}
        />
      ))}
    </Box>
  );
};
