import { RosterEmployee } from '../../components';
import { PayCode } from '../payroll';

export enum TemporaryNonShiftType {
  LIGHT_DUTY = 'LIGHT_DUTY',
  EXTENDED_LEAVE = 'EXTENDED_LEAVE',
}

export const TEMPORARY_NON_SHIFT_TITLES = new Map([
  [TemporaryNonShiftType.LIGHT_DUTY, 'Light duty'],
  [TemporaryNonShiftType.EXTENDED_LEAVE, 'Extended leave'],
]);

export const TEMPORARY_NON_SHIFT_PAY_CODE_TYPES = new Map([
  [TemporaryNonShiftType.LIGHT_DUTY, new Set(['REGULAR', 'LIGHT_OR_LIMITED_DUTY'])],
  [TemporaryNonShiftType.EXTENDED_LEAVE, new Set(['TIME_OFF'])],
]);

export interface TemporaryNonShiftAssignment {
  id: number;
  employee: RosterEmployee;
  temporaryNonShiftType: TemporaryNonShiftType;
  startTime: number;
  endTime: number;
  breakDuration: number;
  payCodes: PayCode[];
  activationDate: string;
  deactivationDate: string;
}

export const ON_ROSTER_TEMPORARY_NON_SHIFT_TYPES = new Set([TemporaryNonShiftType.LIGHT_DUTY]);
