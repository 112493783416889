import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ChevronRightIcon24 } from '@stationwise/component-module';

interface PayrollValidationProps {
  leftIcon: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  message: string;
  onClick?: () => void;
}

export const PayrollValidation: React.FC<PayrollValidationProps> = ({ leftIcon: LeftIcon, message, onClick }) => {
  return (
    <Box
      className="payroll-validation"
      sx={(theme) => ({
        paddingBottom: theme.spacing(2),
      })}
      onClick={onClick}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '12px',
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05) , 0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
          border: '1px solid #DEE3ED',
          paddingY: theme.spacing(1.5),
          paddingX: theme.spacing(2),
          marginTop: theme.spacing(1),
        })}
      >
        <LeftIcon />

        <Typography variant="bodyMMedium" sx={(theme) => ({ color: theme.palette.stationGray[700] })}>
          {message}
        </Typography>
        <Box
          sx={(theme) => ({
            width: '24px',
            height: '24px',
            color: theme.palette.stationGray[400],
          })}
        >
          <ChevronRightIcon24 />
        </Box>
      </Box>
    </Box>
  );
};
