import { parseISO } from 'date-fns';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface TableDataItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  deleted: boolean;
}

export const transformDetailedPayroll = (detailedPayroll: any) => {
  const rows: TableDataItem[] = [];

  for (const date in detailedPayroll) {
    for (const payCode in detailedPayroll[date]) {
      const entry = detailedPayroll[date][payCode];
      rows.push({
        ...entry,
        deleteRow: 'deleteRow',
      });
    }
  }

  return rows;
};

export const transformToDetailedPayroll = (data: TableDataItem[]) => {
  const detailedPayroll: any = {};

  data.forEach((item) => {
    const { date, 'pay code': payCode, deleteRow, ...rest } = item;
    if (!detailedPayroll[date]) {
      detailedPayroll[date] = {};
    }
    detailedPayroll[date][payCode] = {
      date,
      'pay code': payCode,
      ...rest,
    };
  });

  return detailedPayroll;
};

export interface Change {
  date: string;
  payCode: string;
  field: string;
  previousValue: any;
  newValue: any;
}

export const findChangedField = (originalData: any, changedData: any): Change | null => {
  for (const date in originalData) {
    if (Object.prototype.hasOwnProperty.call(originalData, date) && Object.prototype.hasOwnProperty.call(changedData, date)) {
      for (const payCodeValue in originalData[date]) {
        const payCode = originalData[date][payCodeValue]['pay code'];
        if (
          Object.prototype.hasOwnProperty.call(originalData[date], payCodeValue) &&
          Object.prototype.hasOwnProperty.call(changedData[date], payCode)
        ) {
          const originalEntry = originalData[date][payCodeValue];
          const changedEntry = changedData[date][payCode];
          for (const key in originalEntry) {
            if (
              Object.prototype.hasOwnProperty.call(originalEntry, key) &&
              Object.prototype.hasOwnProperty.call(changedEntry, key)
            ) {
              if (originalEntry[key] !== changedEntry[key]) {
                return {
                  date: date,
                  payCode: payCode,
                  field: key,
                  previousValue: originalEntry[key],
                  newValue: changedEntry[key],
                };
              }
            }
          }
        } else {
          return {
            date: date,
            payCode: Object.keys(changedData[date])[0],
            field: 'pay code',
            previousValue: originalData[date][payCodeValue]['pay code'],
            newValue: Object.keys(changedData[date])[0],
          };
        }
      }
    }
  }
  return null; // No changes found
};
export const isChangedNameContainsInvalidSymbols = (newValue: string) => {
  const invalidChars = /[_\d]/;
  return invalidChars.test(newValue);
};

export const isChangedNameInvalid = (columns: string[], firstColumnsNames: string[], newValue: string) => {
  const lowerCaseColumns = columns.map((column) => column.toLowerCase());
  const lowerCaseFirstColumnsNames = firstColumnsNames.map((name) => name.toLowerCase());

  return lowerCaseColumns.includes(newValue.toLowerCase()) || lowerCaseFirstColumnsNames.includes(newValue.toLowerCase());
};

export function getTotalMinutes(dateString: string | Date): number | null {
  if (!dateString) return null;

  const date = typeof dateString === 'string' ? parseISO(dateString) : dateString;
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return hours * 60 + minutes;
}
