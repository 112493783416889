import { Autocomplete, Box, DialogTitle, TextField, createFilterOptions } from '@mui/material';
import { useMemo, useState } from 'react';
import { Button, InputLabel, useRosterContext } from '@stationwise/component-module';
import {
  checkIsEvent,
  DEFAULT_EVENT_STATION,
  updateStrikeTeamName,
  updateStrikeTeamStation,
} from '@stationwise/shift-summary-helper';

export const EditStrikeTeamForm = () => {
  const { selectedStrikeTeamApparatusState, shiftSummaryHelper, setShiftSummaryHelper, setUserHasMadeChanges } =
    useRosterContext();
  const { selectedApparatus } = selectedStrikeTeamApparatusState;
  const initialStation = selectedApparatus?.strikeTeam.station || '';
  const initialName = selectedApparatus?.strikeTeam.name || '';

  const [station, setStation] = useState(initialStation);
  const [name, setName] = useState(initialName);

  const dirty = station !== initialStation || name !== initialName;

  const disabled = !station || !name || !dirty;

  interface StationOption {
    inputValue?: string;
    station: string;
  }

  const stationOptions = useMemo((): StationOption[] => {
    const result = new Set<string>();
    result.add(DEFAULT_EVENT_STATION);
    shiftSummaryHelper.allStationCards.forEach((station) => {
      if (checkIsEvent(station)) {
        result.add(station.stationName);
      }
    });

    return Array.from(result).map((station) => ({ station }));
  }, [shiftSummaryHelper.allStationCards]);

  const filter = createFilterOptions<StationOption>();

  const onSubmit = () => {
    const { selectedApparatus } = selectedStrikeTeamApparatusState;
    if (disabled || !selectedApparatus) {
      return;
    }

    let newShiftSummaryHelper = { ...shiftSummaryHelper };
    if (name !== initialName) {
      newShiftSummaryHelper = updateStrikeTeamName(newShiftSummaryHelper, selectedApparatus, name);
    }
    if (station !== initialStation) {
      newShiftSummaryHelper = updateStrikeTeamStation(newShiftSummaryHelper, selectedApparatus, station);
    }

    setShiftSummaryHelper(newShiftSummaryHelper);
    setUserHasMadeChanges(true);
    selectedStrikeTeamApparatusState.resetState();
  };

  return (
    <Box>
      <DialogTitle component="h2" sx={{ p: 0, m: 0, typography: 'bodyXLSemibold' }}>
        Edit event
      </DialogTitle>
      <Box sx={{ mt: 2 }}>
        <InputLabel>Event group</InputLabel>
        <Autocomplete
          options={stationOptions}
          value={station}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setStation(newValue);
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setStation(newValue.inputValue);
            } else {
              setStation(newValue.station);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.station);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                station: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.station;
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.station}>
              {option.station}
            </li>
          )}
          disableClearable
          forcePopupIcon={true}
          renderInput={(params) => (
            <TextField {...params} sx={{ '& .MuiOutlinedInput-root': { padding: '2.5px 3px 2.5px 12px' } }} />
          )}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <InputLabel>Name</InputLabel>
        <TextField
          placeholder="Event name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          sx={(theme) => ({
            width: '100%',
            mt: theme.spacing(1),
            '& ::placeholder': { typography: 'bodyMRegular', color: theme.palette.stationGray[900] },
          })}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
        <Button
          buttonSize="lg"
          buttonType="tertiary"
          sx={() => ({ flex: 1 })}
          onClick={selectedStrikeTeamApparatusState.resetState}
        >
          Cancel
        </Button>
        <Button disabled={disabled} buttonSize="lg" buttonType="primary" sx={() => ({ flex: 1 })} onClick={onSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};
