import { Box, Container, Typography } from '@mui/material';
import { useDocumentTitle } from '../../hooks';
import { Button } from '../Button';

export const PageNotFound = () => {
  useDocumentTitle('Page Not Found');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
        <Typography variant="h1" component="h1" gutterBottom>
          Oops!
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          The page you are looking for does not exist.
        </Typography>
        <Button href="/" buttonType="dark">
          Go to Dashboard
        </Button>
      </Container>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) => theme.palette.stationGray[100],
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body1" paddingBottom="10px">
            Please contact us if you think this is an issue on our end.
          </Typography>
          <Button buttonType="tertiary" href="mailto:support@stationwise.com">
            support@stationwise.com
          </Button>
        </Container>
      </Box>
    </Box>
  );
};
