import { Box } from '@mui/material';
import { useDocumentTitle } from '@stationwise/component-module';
import { SubNavigation } from '../../../components/Navigation/SubNavigation/SubNavigation';
import { AppPageLayout } from '../../AppPageLayout';
import { CalendarContent } from './components/CalendarContent';

export const Calendar = () => {
  useDocumentTitle('Calendar');
  return (
    <AppPageLayout>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          p: 0,
          maxWidth: '768px',
          margin: '0 auto',
        }}
      >
        <SubNavigation />
        <CalendarContent />
      </Box>
    </AppPageLayout>
  );
};
