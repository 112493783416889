import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LottieLogoPageLoader } from '../../LottieLogo';
import { useLoadedAuthUserContext } from '../context/AuthUser';

interface Props {
  children: JSX.Element;
  restrictedComponent: React.ReactElement;
}

export const RequireAdminProtector = ({ children, restrictedComponent }: Props) => {
  const { state } = useLoadedAuthUserContext();
  const navigate = useNavigate();
  const [hasChecked, setHasChecked] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (!isAdmin && state.employee) {
      if (['SUPER_ADMIN', 'ADMIN'].includes(state.employee.role)) {
        setIsAdmin(true);
      }
    }
    setHasChecked(true);
  }, [isAdmin, navigate, state.employee]);

  if (!hasChecked) return <LottieLogoPageLoader />;
  if (!isAdmin) return restrictedComponent;
  return children;
};
