import { format, setHours } from 'date-fns';

/**
 * Returns an interval that starts at interval.startTime and ends at interval.endTime.
 *
 * @param startYearMonthDay: the start year, month, and day.
 * @param startHourMinute: the start hour and minute, in HH:mm format.
 * @param durationHours: the duration of the interval, in hours.
 *
 * The interval intentionally ignores DST. Use interval.hours to get its duration.
 * Do not use differenceInHours(interval.endTime, interval.startTime). It is incorrect if DST changes during the interval.
 */
export const makeDateInterval = (startYearMonthDay: Date, startHourMinute: string, durationHours: number) => {
  const startTime = new Date(`${format(startYearMonthDay, 'yyyy-MM-dd')}T${startHourMinute}`);
  const endTime = setHours(startTime, startTime.getHours() + durationHours);
  return { startTime, endTime, hours: durationHours };
};

/**
 * Returns (leftDate - rightDate) in minutes, ignoring DST.
 */
export const differenceInUTCMinutes = (leftDate: Date, rightDate: Date) => {
  const getUTCTimestamp = (d: Date) => {
    return Date.UTC(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds(),
      d.getMilliseconds(),
    );
  };
  return (getUTCTimestamp(leftDate) - getUTCTimestamp(rightDate)) / (60 * 1000);
};
