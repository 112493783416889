import { ReactComponent as ArrowDropDownSymbol } from '@material-symbols/svg-400/outlined/arrow_drop_down.svg';
import { ReactComponent as CancelSymbol } from '@material-symbols/svg-400/outlined/cancel-fill.svg';
import { Box, Button, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { DatePickerComp, SvgIcon } from '@stationwise/component-module';
import { ListFieldsStaffingList } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';

interface PersonalStaffingListControlsProps {
  staffingLists: ListFieldsStaffingList[];
  selectedStaffingList: ListFieldsStaffingList | undefined;
  setSelectedStaffingList: Dispatch<SetStateAction<ListFieldsStaffingList | undefined>>;
  selectedDate: Date | null;
  setSelectedDate: Dispatch<SetStateAction<Date | null>>;
}

export const PersonalStaffingListControls = (props: PersonalStaffingListControlsProps) => {
  const theme = useTheme();
  const [isStaffingListDrawerOpen, setIsStaffingListDrawerOpen] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: 1,
        mb: 2,
        '@media (max-width: 500px)': { flexDirection: 'column' },
      }}
    >
      <Button
        data-cy="staffing-list-select"
        color="inherit"
        size="large"
        disabled={!props.staffingLists.length}
        onClick={() => setIsStaffingListDrawerOpen(true)}
        endIcon={<SvgIcon component={ArrowDropDownSymbol} sx={{ color: theme.palette.action.active }} />}
      >
        {props.selectedStaffingList?.name || 'Loading...'}
      </Button>
      <DatePickerComp
        disabled={!props.staffingLists.length}
        value={props.selectedDate}
        setValue={(value) => props.setSelectedDate(value)}
        sxProps={{ border: 0, width: 180 }}
      />
      <Drawer anchor="bottom" open={isStaffingListDrawerOpen} onClose={() => setIsStaffingListDrawerOpen(false)}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, p: theme.spacing(1, 1, 0, 2) }}>
          <Box component="h2" sx={{ flex: 1, typography: 'bodyLMedium', m: 0 }}>
            Staffing Lists
          </Box>
          <IconButton onClick={() => setIsStaffingListDrawerOpen(false)}>
            <SvgIcon component={CancelSymbol} />
          </IconButton>
        </Box>
        <List>
          {props.staffingLists.map((sl) => (
            <ListItem key={sl.id} disablePadding={true}>
              <ListItemButton
                data-cy={`${makeTestIdentifier(sl.name)}-option`}
                onClick={() => {
                  props.setSelectedStaffingList(sl);
                  setIsStaffingListDrawerOpen(false);
                }}
              >
                <ListItemText primary={sl.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};
