import { Box, TextField } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

interface TimeOffNoteProps {
  timeOffNote: string;
  setTimeOffNote: Dispatch<SetStateAction<string>>;
}

export const TimeOffNote = ({ timeOffNote, setTimeOffNote }: TimeOffNoteProps) => {
  return (
    <Box>
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          mt: theme.spacing(4),
          typography: 'bodyLMedium',
        })}
      >
        Note
      </Box>
      <Box
        sx={(theme) => ({
          mt: theme.spacing(2),
        })}
      >
        <TextField
          data-cy="time-off-note-input"
          id="outlined-multiline-static"
          multiline
          rows={3}
          fullWidth
          onChange={(e) => {
            setTimeOffNote(e.target.value);
          }}
          value={timeOffNote}
        />
      </Box>
    </Box>
  );
};
