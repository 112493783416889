import { EventClickArg, EventInput, EventSourceFuncArg } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, isSameDay, isValid, parseISO } from 'date-fns';
import { useRef, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';
import { client } from '@stationwise/share-api';
import { GetMyScheduledCalendarDataView, GetShiftTeamsView, Team } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { checkIsSupportedDate } from '../../utils';
import { CalendarShiftCard } from '../CalendarShiftCard';
import { useLoadedDepartmentInfoContext } from '../Department/context/DepartmentInfo';
import { CustomSelectDayCellContent } from './CustomSelectDayCellContent';
import { DayHeaderContent } from './DayHeaderContent';
import { Header as CalendarHeader } from './Header';
import {
  getEventDisplayedTitle,
  splitEvents,
  isPastDate,
  isEventSelectDisabled,
  parseDateParam,
  formatDate,
  isFutureDate,
} from './calendarHelper';
import { SHIFT_TITLES } from './constants';
import './calendar.css';

export type DisplayOption = 'listMonth' | 'dayGridMonth';

export interface DisplayOptionObj {
  displayName: string;
  value: DisplayOption;
}
const displayOptions: DisplayOptionObj[] = [
  { displayName: 'month', value: 'dayGridMonth' },
  { displayName: 'list', value: 'listMonth' },
];

export const getDisplayOptionByName = (name: string) => {
  const option = displayOptions.find((option) => option.displayName === name);
  return option || displayOptions[0];
};

export const getDisplayOptionByValue = (value: DisplayOption) => {
  const option = displayOptions.find((option) => option.value === value);
  return option || displayOptions[0];
};

export const Calendar = ({
  displayDayEvents,
  getDateIntervalEvents,
  handleRequestOvertimeClick,
  handleCreateIncidentClick,
  setSelectedOvertimeDates,
  setSelectedIncidentDate,
  selectedDate,
  selectedOvertimeDates,
  selectedIncidentDate,
  selectedView,
  requestOvertimeOpen,
  createIncidentOpen,
  setCreateIncidentOpen,
  setRefetchEvents,
  refetchEvents = false,
  setSelectedDate,
  setSelectedEvent,
  setSelectedView,
  heightOfCalendar,
  isLoading,
  setIsLoading,
  createIncident,
  setCreateIncident,
  viewingPersonalCalendar = false,
}: {
  displayDayEvents: (dayEvents: EventInput[]) => void;
  handleRequestOvertimeClick?: () => void;
  handleCreateIncidentClick?: () => void;
  getDateIntervalEvents: (fetchInfo: EventSourceFuncArg) => Promise<GetMyScheduledCalendarDataView[]>;
  setSelectedOvertimeDates?: Dispatch<SetStateAction<string[]>>;
  setSelectedIncidentDate?: Dispatch<SetStateAction<string>>;
  selectedDate: Date;
  selectedOvertimeDates?: string[];
  selectedIncidentDate?: string;
  selectedView: DisplayOption;
  requestOvertimeOpen?: boolean;
  createIncidentOpen?: boolean;
  setCreateIncidentOpen?: (value: boolean) => void;
  setRefetchEvents: Dispatch<SetStateAction<boolean>>;
  refetchEvents: boolean;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  setSelectedEvent: Dispatch<SetStateAction<EventInput | EventImpl>>;
  setSelectedView: Dispatch<SetStateAction<DisplayOption>>;
  heightOfCalendar: number;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  createIncident?: boolean;
  setCreateIncident?: (value: boolean) => void;
  viewingPersonalCalendar?: boolean;
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const calendarRef = useRef<InstanceType<typeof FullCalendar>>(null);
  const [title, setTitle] = useState('');
  const [requestOvertimeDisabled, setRequestOvertimeDisabled] = useState(false);
  const [shiftTeams, setShiftTeams] = useState<Map<string, Team>>(new Map());
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  // add 1 second to the department.shiftStart to avoid displaying the event on the next day
  const nextDayThreshold = `${departmentInfoState.departmentInfo.shiftStart}:01`;
  const theme = useTheme();
  const splittedEvents = useRef<EventInput[]>([]);

  const selectedDateRef = useRef(selectedDate);
  selectedDateRef.current = selectedDate;

  const createShiftTeamMap = (shiftTeams: GetShiftTeamsView[]) => {
    const shiftTeamMap = new Map<string, Team>();
    shiftTeams.forEach((shiftTeam) => {
      shiftTeamMap.set(shiftTeam.date, shiftTeam.shiftTeam);
    });
    setShiftTeams(shiftTeamMap);
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const api = calendarRef?.current?.getApi();
    api && setTitle(api.view.title);

    const parsedDate = parseDateParam(searchParams.get('date') || '');
    if (format(parsedDate, 'yyyy-MM-dd') !== format(selectedDate, 'yyyy-MM-dd')) {
      setSelectedDate(parsedDate);

      //if the date change is coming from the url editing and not the calendar interface
      if (api) {
        queueMicrotask(() => {
          api.gotoDate(parsedDate);
          setTitle(api.view.title);
        });
      }
    }

    const currentDisplayOption = getDisplayOptionByValue(selectedView);
    const newDisplayOption = getDisplayOptionByName(searchParams.get('display') || '');
    if (newDisplayOption !== currentDisplayOption) {
      setSelectedView(newDisplayOption.value);
      if (api) {
        queueMicrotask(() => {
          api.changeView(newDisplayOption.value, parsedDate);
          setTitle(api.view.title);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (isMounted) {
      const isDateValid = isValid(parseISO(searchParams.get('date') || ''));
      const isDisplayValid = displayOptions.some((option) => option.displayName === searchParams.get('display'));
      if (!isDateValid || !isDisplayValid) {
        setSearchParams((prevSearchParams) => {
          const newSearchParams = new URLSearchParams(prevSearchParams);
          !isDateValid && newSearchParams.set('date', format(selectedDate, 'yyyy-MM-dd'));
          !isDisplayValid && newSearchParams.set('display', getDisplayOptionByValue(selectedView).displayName);
          return newSearchParams;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  useEffect(() => {
    if (refetchEvents) {
      const api = calendarRef?.current?.getApi();
      if (api) {
        queueMicrotask(() => {
          api.refetchEvents();
          setRefetchEvents(false);
        });
      }
    }
  }, [setRefetchEvents, refetchEvents]);

  const setDateInUrl = (urlDate: Date, day = true) => {
    const dateStr = format(urlDate, day ? 'yyyy-MM-dd' : 'yyyy-MM');
    if (!viewingPersonalCalendar) {
      setSearchParams((prevSearchParams) => {
        const newSearchParams = new URLSearchParams(prevSearchParams);
        newSearchParams.set('date', dateStr);
        return newSearchParams;
      });
    }
    setSelectedDate(parseDateParam(dateStr));
  };

  const nextHandle = () => {
    const api = calendarRef?.current?.getApi();
    if (api) {
      api.next();
      setDateInUrl(api.view.currentStart, false);
      setTitle(api.view.title);
    }
  };

  const prevHandle = () => {
    const api = calendarRef?.current?.getApi();
    if (api) {
      api.prev();
      setDateInUrl(api.view.currentStart, false);
      setTitle(api.view.title);
    }
  };
  const isOvertimeRequestedToday = (dayEvents: EventInput[]) => {
    setRequestOvertimeDisabled(
      dayEvents.some(
        (ev) => ev.title && (ev.title === SHIFT_TITLES.OVERTIME_REQUEST || ev.title === SHIFT_TITLES.OVERTIME_OPT_OUT),
      ),
    );
  };

  const filterDayEvents = (dateStr: string): EventInput[] => {
    const date = new Date(dateStr);
    return splittedEvents.current.filter((e) => {
      return e.start && isSameDay(new Date(e.start.toString()), date);
    });
  };

  const handleSelectDate = (date: Date) => {
    if (!createIncident && handleSelectAllow(date) && setSelectedOvertimeDates) {
      const formattedDate = format(date, 'MM/dd/yy');
      if (selectedOvertimeDates?.includes(formattedDate)) {
        setSelectedOvertimeDates((prevDates) => prevDates.filter((date) => date !== formattedDate));
      } else {
        setSelectedOvertimeDates((prevDates) => [...prevDates, formattedDate]);
      }
    }

    if (createIncidentOpen && handleSelectAllow(date) && setSelectedIncidentDate) {
      setSelectedIncidentDate(format(date, 'MM/dd/yy'));
    }
  };

  const fetchShiftTeams = async (startDate: Date, endDate: Date, battalionId: number | null): Promise<GetShiftTeamsView[]> => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    let result: GetShiftTeamsView[] = [];
    await client
      .get('/shift/shift-teams/', {
        params: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          battalionId: battalionId,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
    return result;
  };

  // NOTE: This handler is called when an individual event in a day cell is clicked. It's different from clicking the
  // day cell containing the event. That handler is found in the <FullCalendar> `views.dayGridMonth.dateClick` field.
  const handleEventClick = (eventClickArg: EventClickArg) => {
    if (eventClickArg.event) {
      setSelectedEvent(eventClickArg.event);
    }
    if (!requestOvertimeOpen && !createIncidentOpen) {
      const dayEvents = filterDayEvents(eventClickArg.event.startStr);
      isOvertimeRequestedToday(dayEvents);
      displayDayEvents(dayEvents);
      eventClickArg.event.start && setDateInUrl(eventClickArg.event.start);
    }
  };

  const hasEvent = (date: Date) => {
    const formattedDate = format(date, 'MM/dd/yy');
    const api = calendarRef?.current?.getApi();
    if (api) {
      const existingEvent = api.getEvents().find((event) => format(event.start as Date, 'MM/dd/yy') === formattedDate);
      if (existingEvent === undefined) {
        return false;
      }
      return isEventSelectDisabled(existingEvent.title);
    }
    return false;
  };

  const handleSelectAllow = (date: Date) => {
    const inPast = createIncident ? isFutureDate(date) : isPastDate(date);
    const existingEvent = !createIncident && hasEvent(date);
    return !existingEvent && !inPast;
  };

  return (
    <Box
      className={`calendar-${selectedView} user`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'auto',
      }}
    >
      <CalendarHeader
        selectedView={selectedView}
        nextHandle={nextHandle}
        prevHandle={prevHandle}
        title={title}
        handleRequestOvertimeClick={handleRequestOvertimeClick}
        handleCreateIncidentClick={handleCreateIncidentClick}
        requestOvertimeDisabled={requestOvertimeDisabled}
        isLoading={isLoading}
        createIncident={createIncident ?? false}
        setCreateIncident={(value: boolean) => setCreateIncident && setCreateIncident(value)}
        setCreateIncidentOpen={(value: boolean) => setCreateIncidentOpen && setCreateIncidentOpen(value)}
        viewingPersonalCalendar={viewingPersonalCalendar}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          overflowY: 'scroll',
        }}
      >
        <FullCalendar
          ref={calendarRef}
          displayEventTime={false}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          selectable={createIncident ? createIncidentOpen : requestOvertimeOpen}
          loading={(loading: boolean) => {
            if (loading !== isLoading) setIsLoading(loading);
          }}
          height={`${heightOfCalendar}px`}
          timeZone="local"
          dayMaxEventRows={true}
          progressiveEventRendering={true}
          initialDate={selectedDate}
          initialEvents={(fetchInfo, successCallback, failureCallback) => {
            fetchShiftTeams(fetchInfo.start, fetchInfo.end, null).then((shiftTeams) => {
              createShiftTeamMap(shiftTeams);
            });
            displayDayEvents([]);
            getDateIntervalEvents(fetchInfo)
              .then((events) => {
                //splitted multiday events to be displayed in monthly view as different slots
                const splitted = splitEvents(events, theme);
                splittedEvents.current = splitted;

                const dayEvents = filterDayEvents(selectedDateRef.current.toISOString());
                isOvertimeRequestedToday(dayEvents);

                const api = calendarRef?.current?.getApi();

                // if we are standing in the current month then display today events cards below the calendar
                if (
                  api?.view.currentStart.getMonth() === selectedDateRef.current.getMonth() &&
                  api?.view.currentStart.getFullYear() === selectedDateRef.current.getFullYear()
                ) {
                  displayDayEvents(dayEvents);
                }
                successCallback(splitted);
              })
              .catch((err) => {
                captureException(err);
                failureCallback(err);
              });
          }}
          firstDay={0}
          initialView={selectedView}
          locale="en-US"
          headerToolbar={false}
          views={{
            dayGridMonth: {
              titleFormat: { month: 'short', year: 'numeric' },
              dateClick: (dateClickArg) => {
                if (!isLoading && checkIsSupportedDate(dateClickArg.date)) {
                  if (!requestOvertimeOpen && !createIncidentOpen) {
                    const dayEvents = filterDayEvents(`${dateClickArg.dateStr}T00:00:00`);
                    isOvertimeRequestedToday(dayEvents);
                    displayDayEvents(dayEvents);
                    setDateInUrl(dateClickArg.date);
                  }
                  if (requestOvertimeOpen || createIncidentOpen) {
                    handleSelectDate(dateClickArg.date);
                  }
                }
              },
              eventClick: handleEventClick,
              fixedWeekCount: false,
              nextDayThreshold: nextDayThreshold,
              dayCellContent: (dayCellContentArg) => (
                <CustomSelectDayCellContent
                  cellContent={dayCellContentArg}
                  selectedIncidentDate={selectedIncidentDate ?? ''}
                  selectedOvertimeDates={selectedOvertimeDates ?? []}
                  shiftTeamMap={shiftTeams}
                />
              ),
              dayCellClassNames: (dayCellContentArg) => {
                if (requestOvertimeOpen || createIncidentOpen) {
                  const cellDate = dayCellContentArg.date;
                  const formatCellDate = format(cellDate, 'MM/dd/yy');
                  if (selectedOvertimeDates?.includes(formatCellDate) || selectedIncidentDate === formatCellDate) {
                    return 'selected-cell';
                  } else if (
                    (requestOvertimeOpen && (isPastDate(cellDate) || hasEvent(cellDate))) ||
                    (createIncidentOpen && isFutureDate(cellDate))
                  ) {
                    return 'disabled-cell';
                  }
                } else if (isSameDay(selectedDate, dayCellContentArg.date)) {
                  return 'selected-cell-border';
                }
                return '';
              },
              eventContent: (eventContentArg) => {
                // NOTE: Overriding the default event content to add a `data-cy` test identifier
                return (
                  <div className="fc-event-main-frame">
                    <div className="fc-event-title-container">
                      <div
                        className="fc-event-title fc-sticky"
                        data-cy={`event-${makeTestIdentifier(eventContentArg.event.title)}`}
                      >
                        {getEventDisplayedTitle(departmentInfoState, eventContentArg.event.title)}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            listMonth: {
              titleFormat: { month: 'short', year: 'numeric' },
              eventClick: handleEventClick,
              nextDayThreshold: nextDayThreshold,
              listDayFormat: {
                day: 'numeric',
                weekday: 'short',
              },
              listDaySideFormat: false,
              dayHeaderContent: (dayHeaderContentArg) => {
                return <DayHeaderContent dayHeaderContentArg={dayHeaderContentArg} />;
              },
              eventContent: (eventContentArg) => {
                return <CalendarShiftCard shift={eventContentArg.event} />;
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};
