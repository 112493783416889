import { Box, Pagination } from '@mui/material';
import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import React, { useDeferredValue, useEffect, useRef, useState } from 'react';
import {
  CustomTable,
  LetterAvatar,
  TableProps,
  SearchAndFilter,
  LottieLogo,
  theme,
  NoDataToDisplay,
  StateCard,
} from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { capitalize, camelCaseToTitle, snakeCaseToTitle } from '@stationwise/share-utils';
import { Activity, getTypeColor } from '../../../ActivityHistory/utils';

interface FilterOptions {
  [key: string]: string[];
}
interface Props {
  selectedDate: Date;
}
export const RosterChangeLogDrawer = ({ selectedDate }: Props) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  const abortControllerRef = useRef<AbortController | null>(null);
  const deferredSearchInput = useDeferredValue(searchInput);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const params = {
          type: 'Staffing',
          selectedDate: format(selectedDate, 'yyyy-MM-dd'),
        };
        const response = await client.get('organization/activity-feed/filter_options/', {
          params: params,
        });
        if (response.data) {
          setFilterOptions(response.data);
        }
      } catch (error) {
        captureException(error);
      }
    };
    fetchFilterOptions();
  }, [selectedDate]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      try {
        const filterParams = Object.fromEntries(Object.entries(selectedFilters).filter(([_, value]) => value));
        const params = {
          selectedDate: format(selectedDate, 'yyyy-MM-dd'),
          type: 'Staffing',
          page: page,
          latest: true,
          ...(deferredSearchInput && { search: deferredSearchInput }),
          ...filterParams,
        };

        const response = await client.get('organization/activity-feed/', {
          params,
          signal: abortControllerRef.current.signal,
        });
        if (response.data.results && Array.isArray(response.data.results)) {
          setActivities(response.data.results.filter((activity: Activity) => activity.target != null));
          setTotalPages(Math.ceil(response.data.count / 100));
        }
        setIsLoading(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && captureException(error);
        !isCanceled && setIsLoading(false);
      }
    };
    fetchData();
  }, [page, deferredSearchInput, selectedFilters, selectedDate]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleFilterChange = (filterName: string, value: string) => {
    setPage(1);
    setSelectedFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const handleSearchChange = (value: string) => {
    setPage(1);
    setSearchInput(value);
  };

  const filters = Object.entries(filterOptions).map(([filterName, options]) => ({
    name: camelCaseToTitle(capitalize(filterName)),
    options: options.map((option) => ({
      label: snakeCaseToTitle(option),
      value: capitalize(option),
    })),
    selected: selectedFilters[filterName],
    onChange: (value: string) => handleFilterChange(filterName, value),
  }));

  const columns: TableProps['columns'] = [
    { id: 'name', label: 'USER', minWidth: 200, align: 'left' },
    { id: 'action', label: 'ACTION', minWidth: 300 },
    { id: 'timestamp', label: 'TIMESTAMP', minWidth: 150, align: 'center' },
    { id: 'actorRole', label: 'USER ROLE', minWidth: 100, align: 'center' },
    { id: 'comments', label: 'COMMENTS', minWidth: 10, align: 'center' },
  ];

  const data: TableProps['data'] = activities.map((activity) => ({
    name: (
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          alignItems: 'center',
        })}
      >
        <LetterAvatar
          firstName={activity.name}
          color={['ADMIN', 'SUPER_ADMIN'].includes(activity.actorRole) ? 'green' : 'lightblue'}
        />
        {activity.name}
      </Box>
    ),

    action:
      activity.target?.subject && activity.target?.action.includes(activity.target?.subject) ? (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>
          {activity.target?.action.substring(0, activity.target?.action.indexOf(activity.target?.subject))}{' '}
          <Box component="span" sx={{ fontWeight: 600 }}>
            {activity.target?.subject}
          </Box>{' '}
          {activity.target?.action.substring(
            activity.target?.action.indexOf(activity.target?.subject) + activity.target?.subject.length,
          )}
        </Box>
      ) : (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>
          {activity.target?.action}
        </Box>
      ),
    timestamp: format(activity.timestamp, 'MMM dd, HH:mm aaa').replace(/am|pm/i, (match) => match.toUpperCase()),
    type: (
      <Box
        sx={(theme) => ({
          backgroundColor: getTypeColor(activity.activityType),
          color: theme.palette.common.white,
          borderRadius: '20px',
          padding: theme.spacing(0.25, 1),
          typography: 'bodySRegular',
        })}
      >
        {activity.activityType}
      </Box>
    ),
    actorRole: capitalize(activity.actorRole.toLowerCase()),
    comments: (
      <Box
        sx={{
          opacity: activity.comments ? undefined : '20%',
          textAlign: 'left',
          typography: 'bodySRegular',
          whiteSpace: 'pre',
        }}
      >
        {activity.comments || 'N/A'}
      </Box>
    ),
  }));

  const clearAllFilters = () => {
    setSelectedFilters({});
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'scroll',
      }}
    >
      <Box
        sx={{
          marginTop: theme.spacing(2),
          width: '95%',
          mx: 'auto',
          boxShadow: 'none',
        }}
      >
        {!isLoading ? (
          activities.length === 0 ? (
            <StateCard primaryText="No data to display yet" secondaryText="When you start making changes, they'll appear here..">
              <NoDataToDisplay />
            </StateCard>
          ) : (
            <>
              <SearchAndFilter
                searchText={searchInput}
                setSearchText={handleSearchChange}
                filters={filters}
                clearAllFilters={clearAllFilters}
                searchPlaceholder="Search for people or comments"
              />
              <CustomTable columns={columns} data={data} />
            </>
          )
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
            <LottieLogo height="200px" width="200px" />
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {totalPages > 1 && !isLoading && <Pagination count={totalPages} page={page} onChange={handlePageChange} sx={{ my: 2 }} />}
      </Box>
    </Box>
  );
};
