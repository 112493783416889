import { Box } from '@mui/material';
import { format } from 'date-fns';
import { LetterAvatar, sanitizeMessage } from '@stationwise/component-module';

export const BubbleMessage = ({
  message,
  author,
  messageDateTime,
}: {
  message: string;
  author: string;
  messageDateTime: string;
}) => {
  return (
    <Box
      display="flex"
      sx={{
        mt: '25px',
        ml: '16px',
        mb: '25px',
        p: '4px',
        overflowWrap: 'break-word',
      }}
    >
      <Box>
        <LetterAvatar firstName={author.charAt(0)} color="red" />
      </Box>
      <Box
        sx={(theme) => ({
          background: theme.palette.stationGray[50],
          borderRadius: '16px',
          width: '89%',
          ml: '16px',
          p: '16px',
          pt: '0px',
        })}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', typography: 'bodySRegular' }}>
          <Box sx={(theme) => ({ color: theme.palette.stationGray[400], pt: 2 })}>
            <Box component="span" sx={(theme) => ({ color: theme.palette.stationGray[900], fontWeight: 600 })}>
              {author}
            </Box>
            {' wrote'}
          </Box>
          <Box sx={(theme) => ({ color: theme.palette.stationGray[400], pt: 2 })}>
            {`${format(messageDateTime, 'MMM dd')} at ${format(messageDateTime, 'HHmm')}`}
          </Box>
        </Box>
        <Box
          sx={{ pt: '16px' }}
          dangerouslySetInnerHTML={{
            __html: sanitizeMessage(message),
          }}
        />
      </Box>
    </Box>
  );
};
