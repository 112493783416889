import { Box } from '@mui/material';

export const PlaceholderFloaterCard = () => {
  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.stationGray[100],
        borderRadius: '12px',
        boxShadow: '0px 1px 2px 0px #0A0E160D',
        minWidth: '143px',
        height: theme.spacing(8),
        border: `3px solid ${theme.palette.stationGray[100]}`,
        '&:hover': {
          borderColor: theme.palette.stationGray[400],
        },
      })}
    />
  );
};
