import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { useDeferredValue, useEffect, useRef, useState } from 'react';
import { Badge, CustomTable, getShiftColor, LottieLogo, SearchAndFilter, TableProps } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { camelCaseToTitle, capitalize } from '@stationwise/share-utils';
import { getShiftColorData } from '../../TeamSettings/components/MyTeamMain';
import { EligibleEmployee } from './tpyes';

interface FilterOptions {
  [key: string]: string[];
}
interface EligibleEmployeesContentProps {
  shiftDate: string | null | undefined;
}

export const EligibleEmployeesContent = ({ shiftDate }: EligibleEmployeesContentProps) => {
  const [eligibleEmployees, setEligibleEmployees] = useState<EligibleEmployee[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [searchInput, setSearchInput] = useState('');
  const deferredSearchInput = useDeferredValue(searchInput);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await client.get('employee/request-volunteers/filter_options/', {
          params: { shiftDate: shiftDate },
        });
        if (response.data) {
          setFilterOptions(response.data);
        }
      } catch (error) {
        captureException(error);
      }
    };
    fetchFilterOptions();
  }, [shiftDate]);

  useEffect(() => {
    const getEligibleEmployees = async () => {
      if (!shiftDate) return;
      setIsLoading(true);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();

      try {
        const filterParams = Object.fromEntries(Object.entries(selectedFilters).filter(([_, value]) => value));
        const response = await client.get('employee/request-volunteers/', {
          params: {
            shiftDate: shiftDate,
            ...(deferredSearchInput && { search: deferredSearchInput }),
            ...filterParams,
            signal: abortControllerRef.current.signal,
          },
        });
        const allEmployees: EligibleEmployee[] = [];
        response.data.forEach((employee: EligibleEmployee) => {
          allEmployees.push({
            id: employee.id,
            rank: employee.rank,
            name: employee.name,
            certifications: employee.certifications,
            plannedShiftDetails: employee.plannedShiftDetails,
          });
        });

        setIsLoading(false);
        setEligibleEmployees(allEmployees);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && captureException(error);
        !isCanceled && setIsLoading(false);
      }
    };
    getEligibleEmployees();
  }, [shiftDate, deferredSearchInput, selectedFilters]);

  const handleFilterChange = (filterName: string, value: string) => {
    setSelectedFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const handleSearchChange = (value: string) => {
    setSearchInput(value);
  };

  const filters = Object.entries(filterOptions).map(([filterName, options]) => ({
    name: camelCaseToTitle(capitalize(filterName)),
    options: options.map((option) => ({
      label: capitalize(option),
      value: capitalize(option),
    })),
    selected: selectedFilters[filterName],
    onChange: (value: string) => handleFilterChange(filterName, value),
  }));

  const clearAllFilters = () => {
    setSelectedFilters({});
  };
  const columns: TableProps['columns'] = [
    { id: 'name', label: 'Name', minWidth: 200, align: 'left' },
    { id: 'rank', label: 'Rank', minWidth: 64, align: 'center' },
    { id: 'certificates', label: 'Certificates', minWidth: 150, align: 'left' },
    { id: 'shift', label: 'Shift', minWidth: 150, align: 'left' },
  ];

  const data: TableProps['data'] = eligibleEmployees.map((employee) => ({
    name: (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          typography: 'bodySMedium',
        }}
      >
        {employee.name}
      </Box>
    ),
    rank: (
      <Box display="inline-flex">
        <Badge label={employee.rank.code} isRound type="sm" color={employee.rank.color} />
      </Box>
    ),
    certificates: (
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          typography: 'bodySMedium',
        })}
      >
        {employee.certifications.map((cert) => (
          <Badge key={cert.id} label={cert.code} isRound type="sm" color={cert.color} />
        ))}
      </Box>
    ),
    shift:
      employee.plannedShiftDetails && employee.plannedShiftDetails.length > 0 ? (
        <Box
          sx={(theme) => ({
            backgroundColor: getShiftColor(getShiftColorData(employee.plannedShiftDetails), theme),
            color: theme.palette.common.white,
            borderRadius: theme.spacing(2.5),
            padding: theme.spacing(0.25, 1),
            typography: 'bodySRegular',
            maxWidth: '80px',
          })}
        >
          {capitalize(employee.plannedShiftDetails[0].teamName)}
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.stationGray[500],
            borderRadius: theme.spacing(2.5),
            color: theme.palette.common.white,
            padding: theme.spacing(0.25, 1),
            typography: 'bodySRegular',
          })}
        >
          Unassigned
        </Box>
      ),
  }));
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '828px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          typography: 'bodySMedium',
          alignItems: 'center',
        }}
      >
        <SearchAndFilter
          searchText={searchInput}
          setSearchText={handleSearchChange}
          filters={filters}
          clearAllFilters={clearAllFilters}
          searchPlaceholder="Search for people in your team"
        />
      </Box>
      <Box>
        {isLoading && (
          <Box display="flex" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
            <LottieLogo width={'150px'} height={'150px'} />
          </Box>
        )}
        {!isLoading && <CustomTable columns={columns} data={data} />}
      </Box>
    </Box>
  );
};
