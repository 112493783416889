import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { Dispatch } from 'react';
import { FeedbackMessage, GenericDrawer, SnackbarService } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { CancelRequest } from '../CancelRequest';

interface Props {
  handleOnClose: () => void;
  drawerOpen: boolean;
  setRefetchEvents: Dispatch<React.SetStateAction<boolean>>;
  url: string;
  title: string;
}

export const CancelCommonRequest = ({ url, handleOnClose, drawerOpen, setRefetchEvents, title }: Props) => {
  const cancelRequest = async () => {
    let success = false;
    try {
      await client.patch(url, {
        status: 'USER_CANCELLED',
      });
      success = true;
      setRefetchEvents(true);
    } catch (error) {
      success = false;
      captureException(error);
    } finally {
      SnackbarService.notify({
        content: success
          ? `Your ${title.toLowerCase()} request has been successfully canceled.`
          : 'There was an issue processing your request. ',
        severity: success ? 'success' : 'error',
        ...(success
          ? { showCloseButton: true, onClose: () => handleOnClose() }
          : {
              onCallToAction: () => handleRequest(),
              actionButtonText: 'Try again',
            }),
        duration: 5000,
      });
    }
  };

  const handleRequest = () => {
    cancelRequest();
  };

  return (
    <GenericDrawer
      anchor="bottom"
      drawerOpen={drawerOpen}
      handleOnClose={() => handleOnClose()}
      loading={false}
      showHeader={false}
      disableFooter={true}
      headerTitle={title}
    >
      <div>
        <Box>
          <FeedbackMessage status="question" firstMessage="Do you really wish to cancel this request?" secondMessage="" />

          <CancelRequest handleOnClose={handleOnClose} handleRequest={handleRequest} />
        </Box>
      </div>
    </GenericDrawer>
  );
};
