import { RosterApparatus } from '@stationwise/share-types';
import { checkIsAdministration, checkIsTemporaryNonShift } from '../id';
import { IShiftSummaryHelper } from '../types';

export const checkIsCollapsedApparatus = (apparatus: RosterApparatus, isCollapsedPreference: boolean) => {
  if (checkIsAdministration(apparatus) || checkIsTemporaryNonShift(apparatus)) {
    return true;
  }
  return isCollapsedPreference;
};

/**
 * Return a copy of `allStationCards` such that `apparatus`, `apparatus.positions`, and `apparatus.positions[i].employees`
 * can be shallow-mutated without affecting the original `allStationCards`.
 */
export const copyBoardWithNewApparatus = ({ allStationCards }: IShiftSummaryHelper, apparatusId: string) => {
  const newAllStationCards = new Map(allStationCards);
  for (const [stationId, station] of newAllStationCards.entries()) {
    const apparatusIndex = station.apparatuses.findIndex((apparatus) => apparatus.id === apparatusId);
    const apparatus = station.apparatuses[apparatusIndex];
    if (apparatus) {
      const newPositions = apparatus.positions.map((position) => ({
        ...position,
        employees: [...position.employees],
      }));
      const newApparatus = { ...apparatus, positions: newPositions };
      const newStation = { ...station, apparatuses: [...station.apparatuses] };
      newStation.apparatuses[apparatusIndex] = newApparatus;
      newAllStationCards.set(stationId, newStation);
      return { newAllStationCards, newStation, newApparatus, apparatusIndex };
    }
  }
  throw new Error('Cannot copy board with new apparatus');
};
