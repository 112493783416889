import { EventInput, EventSourceFuncArg } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  CalendarShiftCard,
  Button,
  useLoadedAuthUserContext,
  Loader,
  SHIFT_TITLES,
  STATUS_TITLES,
  Calendar,
  getDisplayOptionByName,
  formatDate,
  isAdditionalPaidTimeEvent,
  isFutureDate,
  parseDateParam,
  getDepartmentFeatureFlagValue,
  useLoadedDepartmentInfoContext,
  SnackbarService,
  isTimeOffEvent,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { GetMyScheduledCalendarDataView, GetIncidentOverview } from '@stationwise/share-types';
import { PUSHER_EVENT_TYPES, PUSHER_UPDATE_MESSAGE, RefreshEventCallback } from '@stationwise/share-utils';
import { buildOvertimeInfoObject, buildStaffingListObject, OvertimeInfo } from '../../MandatoryOvertimeList/overtimeListHelper';
import { AdditionalPaidTime } from './AdditionalPaidTime';
import { AdditionalPaidTimeOverview } from './AdditionalPaidTime/AdditionalPaidTimeOverview';
import { AdditionalPaidTimeResponse } from './AdditionalPaidTime/AdditionalPaidTimeResponse';
import { BottomSheet } from './BottomSheet';
import { CreateIncident } from './CreateIncident';
import { IncidentOverview } from './Incident/IncidentOverview';
import { Overtime } from './Overtime';
import { FetchedDataOvertimeCard } from './Overtime/FetchedDataOvertimeCard';
import { RequestOvertime } from './RequestOvertime';
import { CancelOvertimeRequest } from './RequestOvertime/CancelOvertimeRequest';
import { OvertimeRequestCard } from './RequestOvertime/OvertimeRequestCard';
import { RequestOverview } from './RequestSharedComponents/RequestOverview';
import { getStatus } from './RequestSharedComponents/StatusCard';
import { StatusBadge } from './RequestSharedComponents/StatusCard/StatusBadge';
import { ShiftOverview } from './ShiftOverview';
import { TimeOffRequest } from './TimeOffRequest';
import { RequestTimeOffCard } from './TimeOffRequest/RequestTimeOffCard';
import { TradeShift } from './TradeShift';
import { RequestTradeShiftCard } from './TradeShift/RequestTradeShiftCard';

export const fetchEvents = async (dateInterval: {
  startDate: string;
  endDate: string;
}): Promise<GetMyScheduledCalendarDataView[]> => {
  try {
    const params = dateInterval;
    const response = await client.get('/employee/scheduled-calendar-data/', {
      params,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const ShiftOverViewOptions = {
  TRADE_SHIFT: 'trade-shift',
  REQUEST_TIME_OFF: 'request-time-off',
  ADDITIONAL_PAID_TIME: 'additional-paid-time',
  CANCEL_OVERTIME_REQUEST: 'cancel-overtime-request',
  EDIT_INCIDENT: 'edit-incident',
  CLOSE_INCIDENT: 'close-incident',
};

export const CalendarContent = () => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const refreshTriggerChannel = departmentInfoState.refreshTriggerChannel;
  const overtimeRequestEnabled = departmentInfoState.departmentInfo.settings.overtimeRequestEnabled;
  const incidentFormEnabled = departmentInfoState.departmentInfo.settings.incidentFormEnabled;
  const isStaffingListsEnabled = getDepartmentFeatureFlagValue(departmentInfoState, 'STAFFING_LISTS', false);

  const [searchParams] = useSearchParams();
  const [selectedDate, setSelectedDate] = useState(() => parseDateParam(searchParams.get('date') || ''));
  const [selectedView, setSelectedView] = useState(() => getDisplayOptionByName(searchParams.get('display') || '').value);
  const [todayEvents, setTodayEvents] = useState<EventInput[]>([]);
  const [showFutureOvertimeCard, setShowFutureOvertimeCard] = useState(true);
  const [requestOvertimeOpen, setRequestOvertimeOpen] = useState<boolean>(false);
  const [createIncidentOpen, setCreateIncidentOpen] = useState<boolean>(false);
  const [isOvertimeLoading, setIsOvertimeLoading] = useState<boolean>(false);
  const [fetchedOverTimeInfo, setFetchedOverTimeInfo] = useState<OvertimeInfo>();
  const [bottomSheet, setBottomSheet] = useState<boolean>(false);
  const [shiftOverviewOpen, setShiftOverviewOpen] = useState<boolean>(false);
  const [tradeShiftOpen, setTradeShiftOpen] = useState<boolean>(false);
  const [overviewOpen, setOverviewOpen] = useState<boolean>(false);
  const [additionalPaidTimeOpen, setAdditionalPaidTimeOpen] = useState<boolean>(false);
  const [additionalPaidTimeOverviewOpen, setAdditionalPaidTimeOverviewOpen] = useState<boolean>(false);
  const [incidentOverviewOpen, setIncidentOverviewOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<EventInput | EventImpl>({});
  const [selectedOvertimeDates, setSelectedOvertimeDates] = useState<string[]>([]);
  const [selectedIncidentDate, setSelectedIncidentDate] = useState<string>('');
  const [timeOffRequestOpen, setTimeOffRequestOpen] = useState<boolean>(false);
  const [showAdditionalPaidTimeResult, setShowAdditionalPaidTimeResult] = useState<boolean>(false);
  const [errorAdditionalPaidTime, setErrorAdditionalPaidTime] = useState<boolean>(false);
  const [messageAdditionalPaidTime, setMessageAdditionalPaidTime] = useState('');
  const [cancelOvertimeRequestOpen, setCancelOvertimeRequestOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refetchEvents, setRefetchEvents] = useState<boolean>(false);
  const [createIncident, setCreateIncident] = useState<boolean>(incidentFormEnabled && !overtimeRequestEnabled);
  const [incidentFormOpen, setIncidentFormOpen] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState<GetIncidentOverview>();
  const [isCloseIncident, setIsCloseIncident] = useState(false);

  useEffect(() => {
    if (
      selectedView === 'dayGridMonth' &&
      (todayEvents.length > 1 ||
        (todayEvents[0] && todayEvents[0].title && todayEvents[0].title === SHIFT_TITLES.OVERTIME_REQUEST) ||
        (todayEvents[0] && isTimeOffEvent(todayEvents[0])) ||
        (todayEvents[0] && isAdditionalPaidTimeEvent(todayEvents[0])))
    ) {
      setBottomSheet(true);
      document.body.style.overflow = 'hidden';
    } else if (selectedView === 'listMonth') {
      if (selectedEvent.title === SHIFT_TITLES.OVERTIME_REQUEST) {
        setBottomSheet(true);
      } else {
        setBottomSheet(false);
        document.body.style.overflow = 'auto';
      }
    } else {
      setBottomSheet(false);
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [todayEvents, selectedView, bottomSheet, selectedEvent]);

  useEffect(() => {
    if (
      selectedView === 'listMonth' &&
      selectedEvent.title &&
      selectedEvent.title !== SHIFT_TITLES.OVERTIME_REQUEST &&
      selectedEvent.title !== SHIFT_TITLES.OVERTIME_OPT_OUT
    ) {
      setShiftOverviewOpen(true);
    }
  }, [selectedView, selectedEvent]);

  useEffect(() => {
    handleShiftOverviewClose();
  }, [selectedView]);

  useEffect(() => {
    if (!refreshTriggerChannel) return;

    const handlePusherUpdate: RefreshEventCallback = (data) => {
      if (
        data.triggerAll ||
        (data.message === PUSHER_UPDATE_MESSAGE &&
          data.employeeIdList.some((id) => id.toString() === authUserState.employee.id.toString()))
      ) {
        setRefetchEvents(true);
      }
    };

    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.REVIEW, PUSHER_EVENT_TYPES.STAFFING, PUSHER_EVENT_TYPES.CANCELLATION];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
    };
  }, [refreshTriggerChannel, authUserState.employee.id]);

  const handleShiftOverviewClose = () => {
    setShiftOverviewOpen(false);
  };
  const handleFutureOvertime = (hasOvertime: boolean) => {
    setShowFutureOvertimeCard(hasOvertime);
    setIsOvertimeLoading(false);
  };

  const handleShiftOverviewOpen = (shift: EventInput | EventImpl) => {
    if (shift.title) {
      setSelectedEvent(shift);
      if (isAdditionalPaidTimeEvent(shift)) {
        setAdditionalPaidTimeOverviewOpen(true);
      } else if (shift.title.includes(SHIFT_TITLES.INCIDENT)) {
        setIncidentOverviewOpen(true);
      } else if (isTimeOffEvent(shift)) {
        setOverviewOpen(true);
      } else if (shift.title === SHIFT_TITLES.TRADE_REQUESTED || shift.title === SHIFT_TITLES.OFF_TRADE) {
        setOverviewOpen(true);
      } else {
        setShiftOverviewOpen(true);
      }
    }
  };

  const handleShiftOverviewOpenForCancelOT = (shift: EventInput | EventImpl) => {
    setSelectedEvent(shift);
    setShiftOverviewOpen(true);
  };
  const handleAdditionalPaidTimeClose = () => {
    setAdditionalPaidTimeOpen(false);
    setIncidentOverviewOpen(false);
    setSelectedIncident(undefined);
  };
  const handleSelect = (option: string) => {
    setBottomSheet(false);
    setShiftOverviewOpen(false);
    switch (option) {
      case ShiftOverViewOptions.TRADE_SHIFT:
        setTradeShiftOpen(true);
        break;
      case ShiftOverViewOptions.REQUEST_TIME_OFF:
        setTimeOffRequestOpen(true);
        break;
      case ShiftOverViewOptions.ADDITIONAL_PAID_TIME:
        setAdditionalPaidTimeOpen(true);
        break;
      case ShiftOverViewOptions.CANCEL_OVERTIME_REQUEST:
        setCancelOvertimeRequestOpen(true);
        break;
      case ShiftOverViewOptions.EDIT_INCIDENT:
        setIncidentFormOpen(true);
        break;
      case ShiftOverViewOptions.CLOSE_INCIDENT:
        setIncidentFormOpen(true);
        break;
      default:
        break;
    }
  };

  const handleAdditionalPaidTimeRequest = (responseMessage: string, error: boolean) => {
    setAdditionalPaidTimeOpen(false);
    setIncidentOverviewOpen(false);
    setShowAdditionalPaidTimeResult(true);
    setRefetchEvents(true);
    setErrorAdditionalPaidTime(error);
    setMessageAdditionalPaidTime(responseMessage);
  };

  const handleIncidentRequest = (responseMessage: string, error: boolean) => {
    setCreateIncidentOpen(false);
    setIncidentOverviewOpen(false);
    setSelectedIncidentDate('');
    setRefetchEvents(true);
    SnackbarService.notify({
      content: responseMessage,
      showCloseButton: true,
      duration: 5000,
      severity: error ? 'error' : 'success',
    });
    setSelectedIncident(undefined);
  };

  const fetchOvertime = async (shiftDate: string) => {
    try {
      if (isStaffingListsEnabled) {
        const response = await client.get('/staffing-list/staffing-lists/vot/', {
          params: { date: shiftDate },
        });
        if (response.data.items.length === 0) {
          handleFutureOvertime(false);
          //todo don't use the line above instead set line above to a new type for: https://app.clickup.com/t/86a32x08y
          //setFetchedOverTimeInfo(newType)
        } else {
          setIsOvertimeLoading(false);
          setFetchedOverTimeInfo(buildStaffingListObject(response.data, authUserState.employee));
        }
        return response.data;
      } else {
        const response = await client.get('/shift/overtimes/', {
          params: {
            shiftDate: shiftDate,
            ignoreMandatory: true,
          },
        });
        if (response.data.voluntaryOvertimeList.length === 0) {
          handleFutureOvertime(false);
          //todo don't use the line above instead set line above to a new type for: https://app.clickup.com/t/86a32x08y
          //setFetchedOverTimeInfo(newType)
        } else {
          setIsOvertimeLoading(false);
          setFetchedOverTimeInfo(buildOvertimeInfoObject(response.data.voluntaryOvertimeList, authUserState.employee));
        }
        return response.data;
      }
    } catch (err) {
      handleFutureOvertime(false);
      captureException(err);
      return [];
    }
  };

  useEffect(() => {
    if (isFutureDate(selectedDate) && todayEvents.length === 0) {
      setShowFutureOvertimeCard(true);
      setIsOvertimeLoading(true);
      fetchOvertime(formatDate(selectedDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, todayEvents]);
  useEffect(() => {
    setFetchedOverTimeInfo(undefined);
  }, [selectedDate]);

  const displayDayEvents = (dayEvents: EventInput[]) => {
    setTodayEvents(dayEvents);
  };
  const handleRequestOvertimeClick = () => {
    setRequestOvertimeOpen(true);
  };

  const handleCreateIncidentClick = () => {
    setCreateIncidentOpen(true);
    setSelectedIncident(undefined);
  };

  const getDateIntervalEvents = (fetchInfo: EventSourceFuncArg) => {
    const formattedStartDate = formatDate(fetchInfo.start);
    const formattedEndDate = formatDate(fetchInfo.end);
    return fetchEvents({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  const handleEmptyDayAdditionalPaidTimeClick = (event: EventInput | EventImpl) => {
    setSelectedEvent(event);
    setAdditionalPaidTimeOpen(true);
  };

  const getStatusBadge = (event: EventInput) => {
    const status = getStatus(event['status']);
    return <StatusBadge status={status} />;
  };

  const [calendarHeight, setCalendarHeight] = useState(0);

  useEffect(() => {
    const calculateCalendarHeight = () => {
      const windowHeight = window.innerHeight;
      const fixedBoxHeight = 140;
      const heightLeft = windowHeight - fixedBoxHeight - 179;
      setCalendarHeight(heightLeft);
    };

    calculateCalendarHeight();

    window.addEventListener('resize', calculateCalendarHeight);

    return () => window.removeEventListener('resize', calculateCalendarHeight);
  }, []);

  const renderRequestAdditionalPaidTimeButton = () => (
    <Button
      data-cy="apt-request-button"
      variant="outlined"
      color="primary"
      onClick={() =>
        handleEmptyDayAdditionalPaidTimeClick({
          start: selectedDate,
          end: selectedDate,
        })
      }
      sx={(theme) => ({
        overflow: 'auto',
        width: '100%',
        height: theme.spacing(5),
        backgroundColor: theme.palette.common.white,
        border: '1px solid solid',
        padding: '9px 17px 9px 17px',
        borderRadius: '6px',
        borderColor: theme.palette.stationGray[300],
        '&:hover': {
          backgroundColor: theme.palette.stationGray[500],
        },
      })}
    >
      <Typography variant="buttonM" sx={(theme) => ({ color: theme.palette.stationGray[900], fontWeight: 600 })}>
        Request additional paid time
      </Typography>
    </Button>
  );

  const boxRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Box
        sx={(theme) => ({
          background: theme.palette.common.white,
          display: 'flex',
        })}
      >
        <Box
          sx={{
            width: '100%',
            touchAction: bottomSheet ? 'none' : 'unset',
            height: '100%',
          }}
        >
          <Calendar
            displayDayEvents={displayDayEvents}
            getDateIntervalEvents={getDateIntervalEvents}
            handleRequestOvertimeClick={handleRequestOvertimeClick}
            handleCreateIncidentClick={handleCreateIncidentClick}
            setSelectedOvertimeDates={setSelectedOvertimeDates}
            setSelectedIncidentDate={setSelectedIncidentDate}
            requestOvertimeOpen={requestOvertimeOpen}
            createIncidentOpen={createIncidentOpen}
            setCreateIncidentOpen={setCreateIncidentOpen}
            selectedDate={selectedDate}
            selectedOvertimeDates={selectedOvertimeDates}
            selectedIncidentDate={selectedIncidentDate}
            selectedView={selectedView}
            refetchEvents={refetchEvents}
            setRefetchEvents={setRefetchEvents}
            setSelectedDate={setSelectedDate}
            setSelectedEvent={setSelectedEvent}
            setSelectedView={setSelectedView}
            heightOfCalendar={calendarHeight}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            createIncident={createIncident}
            setCreateIncident={setCreateIncident}
          />
          <Box
            sx={(theme) => {
              const defaultStyles = {
                backgroundColor: theme.palette.stationGray[100],
                pt: todayEvents.length === 0 ? 0 : todayEvents.length === 1 ? '22px' : theme.spacing(4),
              };
              if (selectedView === 'listMonth') {
                return {
                  ...defaultStyles,
                  boxShadow: '0px 1px 8px rgb(0 0 0 / 30%)',
                  position: 'sticky',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  zIndex: theme.zIndex.appBar,
                };
              }
              return defaultStyles;
            }}
          >
            {(() => {
              if (bottomSheet || (isFutureDate(selectedDate) && showFutureOvertimeCard && todayEvents.length === 0)) {
                const mockShiftForUnregisteredVot: EventInput = {};
                if (isFutureDate(selectedDate) && showFutureOvertimeCard) {
                  const dummyDate = new Date(selectedDate);
                  dummyDate.setHours(parseInt(departmentInfoState.departmentInfo.shiftStart.split(':')[0], 10));
                  mockShiftForUnregisteredVot.start = dummyDate;
                  const dummyEndDate = new Date(dummyDate);
                  dummyEndDate.setHours(dummyEndDate.getHours() + 24);
                  mockShiftForUnregisteredVot.end = dummyEndDate;
                }

                return (
                  <Box
                    sx={(theme) => ({
                      maxWidth: '500px',
                      padding: '16px',
                      position: 'absolute',
                      zIndex: theme.zIndex.appBar,
                    })}
                  >
                    <BottomSheet
                      boxHeight={todayEvents.length * 100}
                      initialDrawerDistanceTop={calendarHeight + 179}
                      isOpen={bottomSheet}
                      selectedView={selectedView}
                    >
                      <Box ref={boxRef}>
                        {todayEvents.length === 0 && isFutureDate(selectedDate) && (
                          <>
                            {isOvertimeLoading && <Loader sx={{ mt: 2, mb: 1 }} />}
                            {fetchedOverTimeInfo && (
                              //todo return new component if fetchedOverTimeInfo type is the one set in other todo  https://app.clickup.com/t/86a32x08y
                              <CalendarShiftCard
                                key={'mockShiftForUnregisteredVot.id'}
                                shift={mockShiftForUnregisteredVot}
                                forVoluntaryOvertime={true}
                                statusBadge={null}
                              >
                                <FetchedDataOvertimeCard
                                  key={'mockShiftForUnregisteredVot.id'}
                                  displayOvertimeInfo={fetchedOverTimeInfo}
                                ></FetchedDataOvertimeCard>
                              </CalendarShiftCard>
                            )}
                          </>
                        )}
                        {todayEvents.every(isAdditionalPaidTimeEvent) && (
                          <Box sx={{ px: 2, pb: 2 }}>{renderRequestAdditionalPaidTimeButton()}</Box>
                        )}
                        {todayEvents.map((shift) => {
                          if (shift.title === SHIFT_TITLES.TRADE_REQUESTED) {
                            return <RequestTradeShiftCard handleClick={handleShiftOverviewOpen} key={shift.id} shift={shift} />;
                          }

                          if (isTimeOffEvent(shift)) {
                            return (
                              <RequestTimeOffCard
                                handleClick={handleShiftOverviewOpen}
                                shift={shift}
                                key={shift.id}
                                status={shift['status']}
                              />
                            );
                          }

                          if (shift.title === SHIFT_TITLES.OVERTIME_REQUEST && shift['status'] === STATUS_TITLES.CANCELLED) {
                            return <OvertimeRequestCard key={shift.id} shift={shift} cancelled={true} />;
                          }
                          let statusBadge = null;
                          if (
                            shift.title === SHIFT_TITLES.ADDITIONAL_PAID_TIME ||
                            shift.title === SHIFT_TITLES.ADDITIONAL_PAID_TIME_REQUEST
                          ) {
                            statusBadge = getStatusBadge(shift);
                          }

                          return (
                            <CalendarShiftCard
                              key={shift.id}
                              shift={shift}
                              handleClick={
                                shift.title && shift.title !== SHIFT_TITLES.OVERTIME_REQUEST
                                  ? handleShiftOverviewOpen
                                  : handleShiftOverviewOpenForCancelOT
                              }
                              statusBadge={statusBadge}
                            >
                              {shift.title &&
                                (shift.title === SHIFT_TITLES.OVERTIME_REQUEST ||
                                  shift.title === SHIFT_TITLES.OVERTIME_OPT_OUT) && <Overtime key={shift.id} shift={shift} />}
                            </CalendarShiftCard>
                          );
                        })}
                      </Box>
                    </BottomSheet>
                  </Box>
                );
              }
              if (selectedView === 'dayGridMonth') {
                if (todayEvents.length === 1 && todayEvents[0].title === SHIFT_TITLES.OVERTIME_OPT_OUT) {
                  const ot = todayEvents[0];
                  if (ot['status'] === 'CANCELLED') {
                    return <OvertimeRequestCard key={ot.id} shift={ot} cancelled={true} />;
                  }
                  return (
                    <CalendarShiftCard
                      handleClick={handleShiftOverviewOpenForCancelOT}
                      key={todayEvents[0].id}
                      shift={todayEvents[0]}
                    />
                  );
                }
                if (todayEvents.length === 1 && todayEvents[0].title === SHIFT_TITLES.OFF_TRADE) {
                  return (
                    <CalendarShiftCard handleClick={handleShiftOverviewOpen} key={todayEvents[0].id} shift={todayEvents[0]} />
                  );
                }

                if (requestOvertimeOpen) {
                  return null;
                }

                if (todayEvents.every(isAdditionalPaidTimeEvent) && !isLoading) {
                  return (
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        padding: '16px 12px',
                        flexDirection: 'column',
                        height: '140px',
                        backgroundColor: theme.palette.common.white,
                        width: '100%',
                        gap: theme.spacing(3),
                        alignItems: 'center',
                        justifyContent: 'center',
                      })}
                    >
                      <Typography variant="bodyLSemibold" sx={(theme) => ({ color: theme.palette.stationGray[900] })}>
                        No shift on this day
                      </Typography>

                      {!(selectedDate > new Date()) && (
                        <Box sx={{ px: 2, width: '100%' }}>{renderRequestAdditionalPaidTimeButton()}</Box>
                      )}
                    </Box>
                  );
                }

                return todayEvents.map((shift) => {
                  let statusBadge = null;
                  if (
                    shift.title === SHIFT_TITLES.ADDITIONAL_PAID_TIME ||
                    shift.title === SHIFT_TITLES.ADDITIONAL_PAID_TIME_REQUEST
                  ) {
                    statusBadge = getStatusBadge(shift);
                  }
                  return (
                    <Box key={shift.id} sx={{ height: '140px', width: '100%' }}>
                      <CalendarShiftCard shift={shift} handleClick={handleShiftOverviewOpen} statusBadge={statusBadge} />
                    </Box>
                  );
                });
              }
              // no need to open drawer for list view
              return null;
            })()}
          </Box>
        </Box>
      </Box>
      {overtimeRequestEnabled && (
        <RequestOvertime
          open={requestOvertimeOpen}
          setOpen={setRequestOvertimeOpen}
          setRefetchEvents={setRefetchEvents}
          selectedOvertimeDates={selectedOvertimeDates}
          setSelectedOvertimeDates={setSelectedOvertimeDates}
        />
      )}
      <CreateIncident
        handleIncidentRequest={handleIncidentRequest}
        incidentFormOpen={incidentFormOpen}
        open={createIncidentOpen}
        onClose={() => {
          setCreateIncidentOpen(false);
          setSelectedIncidentDate('');
        }}
        selectedDate={selectedIncidentDate}
        selectedIncident={selectedIncident}
        setSelectedIncident={setSelectedIncident}
        setIncidentFormOpen={setIncidentFormOpen}
        isCloseIncident={isCloseIncident}
        setIsCloseIncident={setIsCloseIncident}
      />
      <ShiftOverview
        handleOnClose={handleShiftOverviewClose}
        drawerOpen={shiftOverviewOpen}
        shift={selectedEvent}
        handleSelect={handleSelect}
        todayEvents={todayEvents}
      />
      <TradeShift open={tradeShiftOpen} setOpen={setTradeShiftOpen} shift={selectedEvent} setRefetchEvents={setRefetchEvents} />
      {overviewOpen && selectedEvent.id && (
        <RequestOverview
          shift={selectedEvent}
          handleOnClose={() => setOverviewOpen(false)}
          drawerOpen={overviewOpen}
          setRefetchEvents={setRefetchEvents}
        />
      )}
      <TimeOffRequest
        shift={selectedEvent}
        setOpen={setTimeOffRequestOpen}
        open={timeOffRequestOpen}
        setRefetchEvents={setRefetchEvents}
      />
      <AdditionalPaidTime
        handleOnClose={handleAdditionalPaidTimeClose}
        drawerOpen={additionalPaidTimeOpen}
        shift={selectedEvent}
        handleAdditionalPaidTimeRequest={handleAdditionalPaidTimeRequest}
        forceManual={todayEvents.every(isAdditionalPaidTimeEvent) || authUserState.employee.isNonShift}
      />
      {additionalPaidTimeOverviewOpen && selectedEvent.id && (
        <AdditionalPaidTimeOverview
          handleOnClose={() => setAdditionalPaidTimeOverviewOpen(false)}
          drawerOpen={additionalPaidTimeOverviewOpen}
          id={selectedEvent.id}
          eventColor={selectedEvent.backgroundColor}
          setRefetchEvents={setRefetchEvents}
        />
      )}
      {incidentOverviewOpen && (
        <IncidentOverview
          handleOnClose={() => setIncidentOverviewOpen(false)}
          drawerOpen={incidentOverviewOpen}
          shift={selectedEvent}
          handleSelect={handleSelect}
          setSelectedIncident={setSelectedIncident}
          setIsCloseIncident={setIsCloseIncident}
        />
      )}
      <CancelOvertimeRequest
        handleOnClose={setCancelOvertimeRequestOpen}
        drawerOpen={cancelOvertimeRequestOpen}
        shift={selectedEvent}
        setRefetchEvents={setRefetchEvents}
      />
      {showAdditionalPaidTimeResult && (
        <AdditionalPaidTimeResponse
          error={errorAdditionalPaidTime}
          message={messageAdditionalPaidTime}
          onClose={setShowAdditionalPaidTimeResult}
        />
      )}
    </>
  );
};
