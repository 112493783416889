import { RosterShiftDuration, RosterSplitShiftOrTimeOffChange } from '@stationwise/share-types';
import { diffCycleMinutes } from '@stationwise/shift-summary-helper';

export const makeSplits = (shiftDuration: RosterShiftDuration, count: number) => {
  const splits: RosterSplitShiftOrTimeOffChange[] = [];
  const splitDurationMillis = Math.floor((shiftDuration.hours * 3600 * 1000) / count);
  for (let i = 0; i < count; i++) {
    const isLastSplit = i === count - 1;
    const startTime = new Date(shiftDuration.startTime.getTime() + i * splitDurationMillis);
    const endTime = isLastSplit ? new Date(shiftDuration.endTime) : new Date(startTime.getTime() + splitDurationMillis);
    splits.push({ startTime, endTime, isEmpty: false });
  }
  return splits;
};

export const mergeSplits = (splits: RosterSplitShiftOrTimeOffChange[]) => {
  const merged: RosterSplitShiftOrTimeOffChange[] = [];
  splits.forEach((split) => {
    const prevSplit = merged[merged.length - 1];
    if (prevSplit && prevSplit.isEmpty && split.isEmpty && split.startTime.getTime() <= prevSplit.endTime.getTime()) {
      prevSplit.endTime = split.endTime;
    } else {
      merged.push({ ...split });
    }
  });
  return merged;
};

export const rebalanceSplitTimes = (shiftDuration: RosterShiftDuration, splits: RosterSplitShiftOrTimeOffChange[]) => {
  const splitDurationMillis = Math.floor((shiftDuration.hours * 60) / splits.length) * 60 * 1000;
  splits.forEach((split, i) => {
    const isLastSplit = i === splits.length - 1;
    split.startTime = new Date(shiftDuration.startTime.getTime() + i * splitDurationMillis);
    split.endTime = isLastSplit ? new Date(shiftDuration.endTime) : new Date(split.startTime.getTime() + splitDurationMillis);
  });
  return splits;
};

export const getSplitErrors = (shiftDuration: RosterShiftDuration, splits: RosterSplitShiftOrTimeOffChange[]) => {
  let sumSplitDurationMinutes = 0;
  const errors = splits.map((split, i) => {
    const splitDurationMinutes = diffCycleMinutes(split.endTime, split.startTime) || shiftDuration.hours * 60;
    if (splitDurationMinutes <= 0) {
      return 'Duration must be greater than zero';
    }

    sumSplitDurationMinutes += splitDurationMinutes;
    if (sumSplitDurationMinutes > shiftDuration.hours * 60) {
      return 'Total shift duration exceeded';
    }

    if (i === 0 && split.startTime.getTime() !== shiftDuration.startTime.getTime()) {
      return 'Start time does not match shift start time';
    }

    if (i === splits.length - 1 && split.endTime.getTime() !== shiftDuration.endTime.getTime()) {
      return 'End time does not match shift end time';
    }

    const prevSplit = splits[i - 1];
    if (prevSplit && split.startTime.getTime() !== prevSplit.endTime.getTime()) {
      return 'Start time does not match end time of previous split';
    }

    return '';
  });
  if (sumSplitDurationMinutes > 0 && sumSplitDurationMinutes < shiftDuration.hours * 60) {
    return errors.map(() => 'Split durations do not add up to shift duration');
  }
  return errors;
};
