import { Box, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useEffect } from 'react';
import { XCloseIcon24 } from '../../assets';
import { Button } from '../Button';

interface SwDialogProps {
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  title: string;
  label?: string;
  children?: React.ReactNode;
  loading: boolean;
  disabled?: boolean;
  width?: string;
  displayButton?: boolean;
}
export const SwDialog = ({
  isOpen,
  onSubmit,
  onCancel,
  title,
  label,
  children,
  loading,
  disabled,
  width,
  displayButton = true,
}: SwDialogProps) => {
  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Box sx={{ typography: 'bodyXLSemibold', textAlign: 'center' }}>{title}</Box>
          <Box
            onClick={onCancel}
            sx={(theme) => ({
              color: theme.palette.stationGray[400],
              cursor: 'pointer',
              display: 'inline',
              '&:hover': {
                color: theme.palette.stationGray[700],
              },
            })}
          >
            <XCloseIcon24 />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.common.white,
              borderRadius: theme.spacing(1.5),
              maxWidth: width ? width : '350px',
              width: width ? width : 'auto',
            })}
            onClick={(e) => e.stopPropagation()}
          >
            <Box>{children}</Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow: '0px -2px 4px -1px rgba(10, 14, 22, 0.06), 0px -4px 6px -1px rgba(10, 14, 22, 0.10)',
        }}
      >
        {displayButton && (
          <Button
            onClick={onSubmit}
            buttonSize="lg"
            sx={() => ({
              width: '100%',
              height: '42px',
            })}
            disabled={disabled ? disabled : loading}
          >
            {loading ? <CircularProgress color="inherit" size="1.5rem" /> : label}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
