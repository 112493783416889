import { Box, Button } from '@mui/material';
import { ReactNode } from 'react';
import { CircleWarningIcon20 } from '../../../../assets';
import { CardName, CardNameProps } from './CardName';

interface LastChangesErrorProps {
  current: CardNameProps;
  onClickOverride?: () => void;
  onClickUndo?: () => void;
  errorMessages: ReactNode[];
}

export const LastChangesError = ({ current, onClickOverride, onClickUndo, errorMessages }: LastChangesErrorProps) => {
  return (
    <Box sx={{ width: 264, p: 1 }}>
      <Box
        sx={(theme) => ({
          background: '#472038',
          borderTopLeftRadius: theme.spacing(1),
          borderTopRightRadius: theme.spacing(1),
          p: '12px',
          pb: '19px',
        })}
      >
        <CardName {...current} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={(theme) => ({
          background: '#472038',
          borderBottomLeftRadius: theme.spacing(1),
          borderBottomRightRadius: theme.spacing(1),
          p: '12px',
          mt: '2px',
        })}
      >
        {errorMessages.map((errorMessage, i) => (
          <Box
            key={i}
            sx={(theme) => ({
              display: 'flex',
              gap: 1,
              color: theme.palette.stationRed[500],
              typography: 'bodySRegular',
            })}
          >
            <Box sx={{ display: 'inline-flex', mt: '3px' }}>
              <Box
                component={CircleWarningIcon20}
                sx={(theme) => ({ height: '14px', width: '14px', color: theme.palette.stationRed[500] })}
              />
            </Box>
            {errorMessage}
          </Box>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1.5 }}>
          {!onClickOverride && <Box sx={{ flex: 1 }} />}
          {onClickOverride && (
            <Button
              sx={(theme) => ({
                background: theme.palette.common.white,
                color: theme.palette.stationBlue[700],
                flex: 1,
                height: '30px',
                typography: 'bodyXSMedium',
                '&:hover': {
                  background: theme.palette.stationGray[300],
                },
              })}
              onClick={onClickOverride}
            >
              Override
            </Button>
          )}
          <Button
            sx={(theme) => ({
              background: theme.palette.common.white,
              color: theme.palette.stationBlue[700],
              flex: 1,
              height: '30px',
              typography: 'bodyXSMedium',
              '&:hover': {
                background: theme.palette.stationGray[300],
              },
            })}
            onClick={onClickUndo}
          >
            Undo
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
