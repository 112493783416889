import { Box, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { selectStyle, ChevronDownIcon16 } from '@stationwise/component-module';
import { Battalion } from '@stationwise/share-types';
import { useShiftTemplateContext } from '../contexts/ShiftTemplateContext';

interface Props {
  battalions: Battalion[];
  onChange: (selectedOption: string) => void;
  selectedBattalion: string;
}

export const BattalionSelect = ({ battalions, onChange, selectedBattalion }: Props) => {
  const { userHasMadeChanges } = useShiftTemplateContext();

  return (
    <Tooltip
      title="Save or cancel your changes before selecting another battalion"
      disableFocusListener={!userHasMadeChanges}
      disableHoverListener={!userHasMadeChanges}
    >
      <Select
        value={selectedBattalion}
        onChange={(event: SelectChangeEvent) => onChange(event.target.value as string)}
        variant="standard"
        IconComponent={ChevronDownIcon16}
        disableUnderline
        sx={[(theme) => selectStyle(theme, '', undefined)]}
        disabled={userHasMadeChanges}
      >
        {battalions.map((battalion) => {
          return (
            <MenuItem value={battalion.id.toString()} key={battalion.id}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={(theme) => ({
                    opacity: userHasMadeChanges ? 0.5 : 'unset',
                    display: 'flex',
                    color: theme.palette.stationGray[900],
                    typography: 'bodyMRegular',
                  })}
                >
                  {battalion.name}
                </Box>
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </Tooltip>
  );
};
