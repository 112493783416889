import { Box } from '@mui/material';
import { format } from 'date-fns';
import { memo } from 'react';
import { Candidate } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { Badge, CertBadge } from '../Badge';

interface Props {
  candidate: Candidate;
  isSelected: boolean;
  onClick?: (id: number, startDateTime?: Date) => void;
  subdued?: boolean;
}

export const CandidateCard = memo(({ candidate, isSelected, onClick, subdued }: Props) => {
  return (
    <Box
      component="button"
      type="button"
      onClick={() => (onClick ? onClick(candidate.id, candidate.startDateTime) : undefined)}
      sx={(theme) => ({
        backgroundColor: 'transparent',
        border: `1px solid ${isSelected ? theme.palette.stationGray[900] : theme.palette.stationGray[100]}`,
        boxShadow: '0px 1px 2px 0px #0A0E160D',
        borderRadius: 3,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: 1.5,
        mt: 1,
        p: theme.spacing(1, 1.5),
        opacity: subdued ? 0.5 : undefined,
        textAlign: 'left',
        width: '100%',
        '&:first-of-type': { mt: 0 },
        '&:hover': {
          borderColor: isSelected ? theme.palette.stationGray[900] : theme.palette.stationGray[400],
        },
      })}
    >
      <Badge label={candidate.rank.code} isRound type="sm" color={candidate.rank.color} isBold noPadding />
      <Box sx={{ flex: 1, typography: 'bodyMMedium' }}>{candidate.name}</Box>
      {candidate.startDateTime &&
        candidate.endDateTime &&
        differenceInUTCMinutes(candidate.endDateTime, candidate.startDateTime) !== 24 * 60 && (
          <Box
            typography="bodyMRegular"
            sx={(theme) => ({ color: theme.palette.stationGray[600] })}
          >{`${format(candidate.startDateTime, 'HH:mm')}-${format(candidate.endDateTime, 'HH:mm')}`}</Box>
        )}
      <Box sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap', gap: 0.5 }}>
        {candidate.certifications.map((cert) => (
          <CertBadge key={cert.id} cert={cert} />
        ))}
      </Box>
    </Box>
  );
});
