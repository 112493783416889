import { Box } from '@mui/material';
import React from 'react';
import { CertBadge, CardCertBadges, CustomTable, Edit02Icon16, TableProps } from '@stationwise/component-module';
import { Certification } from '@stationwise/share-types';

interface CertificationsTableProps {
  certifications: Certification[];
  handleEditAction: (certification: Certification) => void;
}

export const CertificationsTable = ({ certifications, handleEditAction }: CertificationsTableProps) => {
  const columns: TableProps['columns'] = [
    {
      id: 'name',
      label: 'NAME',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'code',
      label: 'CODE',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'childCertifications',
      label: 'CHILD CERTIFICATIONS',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'parentCertifications',
      label: 'PARENT CERTIFICATIONS',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'actions',
      label: 'ACTIONS',
      minWidth: 100,
      align: 'left',
    },
  ];

  const data: TableProps['data'] = certifications.map((certification) => ({
    name: <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>{certification.name}</Box>,
    code: <CertBadge cert={certification} />,
    childCertifications: certification.childCertifications ? (
      <CardCertBadges certs={certification.childCertifications} />
    ) : (
      <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>N/A</Box>
    ),

    parentCertifications: certification.parentCertifications ? (
      <CardCertBadges certs={certification.parentCertifications} />
    ) : (
      <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>N/A</Box>
    ),
    actions: (
      <Box>
        <Box
          onClick={() => handleEditAction(certification)}
          sx={(theme) => ({
            color: theme.palette.stationGray[500],
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              color: theme.palette.stationGray[800],
            },
          })}
          title="Edit Certification"
        >
          <Edit02Icon16 />
        </Box>
      </Box>
    ),
  }));
  return <CustomTable columns={columns} data={data} />;
};
