import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import {
  ErrorPage,
  Loader,
  ToggleWithLabel,
  renameMandatoryOvertimeTitle,
  useDocumentTitle,
  useFetchOvertimeList,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { MandatoryOvertimeListContent } from './MandatoryOvertimeListContent';

export const MandatoryOvertimeListMain = () => {
  useDocumentTitle('Overtime List');
  const date = new Date();
  const formatted_date = format(date, 'MM/dd/yyyy');
  const [isMandatory, setIsMandatory] = useState(true);
  const { state: departmentContext } = useLoadedDepartmentInfoContext();

  const toggleOtType = () => {
    setIsMandatory(!isMandatory);
  };

  const {
    isLoading,
    data: overtimes,
    isError,
  } = useFetchOvertimeList({
    shiftDate: formatted_date,
    skip: false,
    skipStaffed: false,
    skipFloaters: false,
    skipVoluntary: false,
    ignoreVoluntaryList: true,
  });

  return (
    <AppPageLayout>
      <Box
        sx={(theme) => ({
          height: '100vh',
          width: '100%',
          pt: theme.spacing(2.5),
          backgroundColor: theme.palette.common.white,
          overflow: 'auto',
          maxWidth: '768px',
          margin: '0 auto',
        })}
      >
        <Box
          sx={(theme) => ({
            alignContent: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            p: theme.spacing(1),
          })}
        >
          <Typography variant="bodyXXLSemibold" sx={(theme) => ({ color: theme.palette.stationGray[900], pl: theme.spacing(2) })}>
            My Overtime list
          </Typography>
          {departmentContext.departmentInfo.settings.separatedOtListEnabled && (
            <ToggleWithLabel
              label={renameMandatoryOvertimeTitle(departmentContext, 'Mandatory OT')}
              isActive={isMandatory}
              onClick={toggleOtType}
            />
          )}
        </Box>
        {isError ? (
          <ErrorPage />
        ) : isLoading ? (
          <Box sx={(theme) => ({ mt: theme.spacing(4) })}>
            <Loader />
          </Box>
        ) : (
          overtimes && (
            <MandatoryOvertimeListContent
              overtimeList={isMandatory ? overtimes.mandatoryOvertimeList : overtimes.voluntaryOvertimeList}
            />
          )
        )}
      </Box>
    </AppPageLayout>
  );
};
