import { Box } from '@mui/material';
import React, { Children, isValidElement } from 'react';
import { PaneContentWrapper } from './PaneContentWrapper';

interface Props {
  activePaneId: string;
  children: React.ReactNode;
}

export const PaneSelector = ({ children, activePaneId }: Props) => {
  const activePaneElement = Children.toArray(children).find((child) => {
    return isValidElement(child) && child.type === PaneContentWrapper && child.props.paneId === activePaneId;
  });

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      })}
    >
      {activePaneElement}
    </Box>
  );
};
