import { Box, Typography } from '@mui/material';
import { WhiteStationIcon20, Badge, BlankCard } from '@stationwise/component-module';
import { AllowedColors } from '@stationwise/share-types';

export interface VacancyInfoCardProps {
  badgeTitle: string;
  badgeColor?: string;
  color?: string;
  stationName: string;
  apparatusName: string;
  type?: 'default' | 'disable' | 'border';
}

export const VacancyInfoCard = ({
  badgeTitle,
  badgeColor,
  stationName,
  apparatusName,
  type = 'default',
}: VacancyInfoCardProps) => {
  return (
    <BlankCard
      type={type}
      sxProps={() => ({
        width: '100%',
        height: '92px',
        border: 'none',
        '&:hover': {
          border: 'none',
        },
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        mb: '8px',
      })}
    >
      <Box display="flex" height="100%" width="100%" flexDirection={'column'} justifyContent="center">
        <Box display="flex" height="90%" width="100%" flexDirection="column">
          <Box width="fit-content">
            <Badge
              label={badgeTitle}
              type="sm"
              isRound={true}
              color={badgeColor as AllowedColors}
              variant="bodyMRegular"
              overrideMaxLength={20}
            />
          </Box>
          <Box display="flex" alignItems="center" sx={{ mt: '10px' }} gap="5px">
            <WhiteStationIcon20 />
            <Typography
              variant="bodyMMedium"
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
                whiteSpace: 'nowrap',
              })}
            >
              {stationName}, {apparatusName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </BlankCard>
  );
};
