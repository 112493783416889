import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { client, isAxiosError } from '@stationwise/share-api';
import { EmployeeNextShiftView } from '@stationwise/share-types';
import { useLoadedAuthUserContext } from '../components/Auth';

interface Props {
  autoInitialize?: boolean;
  excludeToday?: boolean;
}

export const useFetchNextShift = ({ autoInitialize = false, excludeToday = false }: Props = {}) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<EmployeeNextShiftView | null>(null);
  const [error, setError] = useState<string | null>(null);

  const initialize = useCallback(() => setIsInitialized(true), []);

  useEffect(() => {
    autoInitialize && initialize();
  }, [autoInitialize, initialize]);

  useEffect(() => {
    const load = async () => {
      if (!isInitialized) {
        return;
      }

      try {
        setIsLoading(true);
        setError(null);
        const url = authUserState.employee.isNonShift ? '/non-shift/next/' : '/shift/next/';
        const params = {
          excludeToday: excludeToday ? '1' : undefined,
        };
        const response = await client.get<EmployeeNextShiftView>(url, { params });
        setData(response.data || null);
        setIsLoading(false);
      } catch (error) {
        captureException(error);
        setError(isAxiosError(error) ? error.response?.data.message || '' : '');
        setIsLoading(false);
      }
    };

    load();
  }, [isInitialized, authUserState.employee.isNonShift, excludeToday]);

  return {
    isLoading,
    isError: error !== null,
    data,
    error: error || '',
    initialize,
  };
};
