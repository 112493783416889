import { Dispatch, SetStateAction } from 'react';
import { SwDialog } from '@stationwise/component-module';
import { Vacancies } from '../Vacancies';
import { VacantStation } from '../Vacancies/vacanciesHelper';

interface RequestVolunteersMainProps {
  isOpen: boolean;
  vacantStations: VacantStation[];
  bulkCallIsLoading: boolean;
  setBulkCallIsOpen: Dispatch<SetStateAction<boolean>>;
  setShowEligibleEmployees: Dispatch<SetStateAction<boolean>>;
}

export const RequestVolunteersMain = ({
  isOpen,
  vacantStations,
  bulkCallIsLoading,
  setBulkCallIsOpen,
  setShowEligibleEmployees,
}: RequestVolunteersMainProps) => {
  const openEligibleEmployeesModal = () => {
    setShowEligibleEmployees(true);
  };
  const handleCancelBulkCall = () => {
    setBulkCallIsOpen(false);
  };
  return (
    <SwDialog
      isOpen={isOpen}
      onSubmit={openEligibleEmployeesModal}
      onCancel={handleCancelBulkCall}
      title="Request volunteers"
      label="Request volunteers to fill vacancies"
      loading={bulkCallIsLoading}
    >
      <Vacancies vacantStations={vacantStations} />
    </SwDialog>
  );
};
