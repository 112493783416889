import { Box } from '@mui/material';
import { format, parse } from 'date-fns';
import { RosterStrikeTeamApparatus } from '@stationwise/share-types';
import { Button } from '../../../Button';
import { InputLabel } from '../../../Input';

export enum PerformanceEndDateOption {
  ROSTER_DATE = 'ROSTER_DATE',
  DEACTIVATION_DATE = 'DEACTIVATION_DATE',
}

interface Props {
  apparatus: RosterStrikeTeamApparatus;
  value: PerformanceEndDateOption | null;
  onChange: (value: PerformanceEndDateOption) => void;
}

export const PerformanceEndDate = ({ apparatus, value, onChange }: Props) => {
  const deactivationDate = format(parse(apparatus.deactivationDate, 'yyyy-MM-dd', new Date()), 'MMM dd, yyyy');

  const options = [
    { label: 'Today only', value: PerformanceEndDateOption.ROSTER_DATE },
    { label: `Today - ${deactivationDate}`, value: PerformanceEndDateOption.DEACTIVATION_DATE },
  ];

  return (
    <Box sx={{ mt: 2 }}>
      <InputLabel>Performance dates</InputLabel>
      <Box sx={{ display: 'flex', gap: 1 }}>
        {options.map((option) => (
          <Button
            key={option.value}
            onClick={() => onChange(option.value)}
            buttonSize="lg"
            buttonType={value === option.value ? 'dark' : 'tertiary'}
            sx={() => ({ flex: 1 })}
          >
            <Box component="span" sx={{ display: 'block', typography: 'bodyMRegular', p: '9px 8px' }}>
              {option.label}
            </Box>
          </Button>
        ))}
      </Box>
      <Box sx={(theme) => ({ color: theme.palette.stationGray[600], mt: 0.5, typography: 'bodyXSRegular' })}>
        {deactivationDate} is the last day of this event’s performance
      </Box>
    </Box>
  );
};
