import { useTheme } from '@mui/material';
import { useEffect } from 'react';

export const IntercomHandler = () => {
  const theme = useTheme();
  useEffect(() => {
    const updateIntercomVisibility = () => {
      if (window.Intercom) {
        const shouldHideIntercom = window.innerWidth <= theme.breakpoints.values['lg'];
        window.Intercom('update', { hide_default_launcher: shouldHideIntercom });
      }
    };
    updateIntercomVisibility();
    window.addEventListener('resize', updateIntercomVisibility);

    return () => {
      window.removeEventListener('resize', updateIntercomVisibility);
    };
  }, [theme.breakpoints.values]);
  return null;
};
