import { Box } from '@mui/material';
import { useState } from 'react';
import { theme, Upload03Icon20, ClockRewindIcon20, useAuthUserCapabilities } from '@stationwise/component-module';
import { EmployeePayroll } from '@stationwise/share-types';
import { EmployeePayrollExportModal } from './EmployeePayrollExportModal';

interface EmployeePayrollTableHeaderContentProps {
  employeePayroll: EmployeePayroll;
  setOpenChangeLog: (value: boolean) => void;
}

export const EmployeePayrollTableHeaderContent = ({
  employeePayroll,
  setOpenChangeLog,
}: EmployeePayrollTableHeaderContentProps) => {
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const capabilities = useAuthUserCapabilities();
  const canViewChangeLog = capabilities.VIEW_CHANGE_LOGS;

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      })}
    >
      <Box
        sx={{
          typography: 'bodyXXLMedium',
          color: theme.palette.stationGray[900],
        }}
      >
        Payroll
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
        }}
      >
        {canViewChangeLog && (
          <Box
            sx={(theme) => ({
              borderRadius: theme.spacing(1),
              backgroundColor: theme.palette.common.white,
              color: theme.palette.stationGray[900],
              height: theme.spacing(5),
              paddingX: theme.spacing(1.5),
              typography: 'buttonM',
              alignItems: 'center',
              cursor: 'pointer',
              display: 'flex',
              gap: theme.spacing(1),
              ':hover': {
                backgroundColor: theme.palette.stationGray[200],
              },
              border: '1px solid',
              borderColor: theme.palette.stationGray[300],
            })}
            onClick={() => setOpenChangeLog(true)}
          >
            <ClockRewindIcon20
              style={{
                color: theme.palette.stationGray[500],
              }}
            />
            Change log
          </Box>
        )}
        <EmployeePayrollExportModal
          exportModelOpen={exportModalOpen}
          setExportModelOpen={setExportModalOpen}
          employeePayroll={employeePayroll}
        />
        <Box
          sx={(theme) => ({
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.stationGray[900],
            color: theme.palette.common.white,
            height: theme.spacing(5),
            paddingX: theme.spacing(2),
            typography: 'buttonM',
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            gap: theme.spacing(1),
          })}
          onClick={() => setExportModalOpen(true)}
        >
          <Upload03Icon20 />
          Export time card
        </Box>
      </Box>
    </Box>
  );
};
