import { Box } from '@mui/material';
import React from 'react';
import { makeTestIdentifier } from '@stationwise/share-utils';

interface HeaderOptionProps {
  label: string;
  isSelected?: boolean;
  onSelect?: () => void;
  onClick?: () => void;
}

export const HeaderOption = ({ label, isSelected, onClick }: HeaderOptionProps) => {
  return (
    <Box
      data-cy={`header-option-${makeTestIdentifier(label)}`}
      onClick={onClick}
      sx={(theme) => ({
        color: isSelected ? theme.palette.stationGray[900] : theme.palette.stationGray[400],
        cursor: 'pointer',
        typography: 'bodyMSemibold',
        '&:hover': { color: !isSelected && theme.palette.stationGray[500] },
      })}
    >
      {label}
    </Box>
  );
};
