import { Dialog } from '@mui/material';
import { RosterApparatus, RosterEmployee, RosterPosition } from '@stationwise/share-types';
import { ShiftTemplateAssignmentForm } from './ShiftTemplateAssignmentForm';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  apparatus: RosterApparatus;
  position: RosterPosition | null;
  activeEmployee?: RosterEmployee | null;
  resetSelected: () => void;
}
export const ShiftTemplateAssignmentModal = ({ open, setOpen, apparatus, position, activeEmployee, resetSelected }: Props) => {
  const onClose = () => {
    setOpen(false);
    resetSelected();
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { borderRadius: '16px', position: 'relative' } }}>
      <ShiftTemplateAssignmentForm
        apparatus={apparatus}
        position={position}
        activeEmployee={activeEmployee}
        closeForm={onClose}
      />
    </Dialog>
  );
};
