import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { client } from '@stationwise/share-api';
import { useLoadedDepartmentInfoContext } from '../../Department';
import { useLoadedAuthUserContext } from '../context/AuthUser';

export const useLogout = () => {
  const { dispatch: authUserDispatch } = useLoadedAuthUserContext();
  const { dispatch: departmentInfoDispatch } = useLoadedDepartmentInfoContext();
  const navigate = useNavigate();

  const logout = async () => {
    await client.post('/auth/logout/');

    authUserDispatch({ type: 'LOGOUT_USER.SUCCESS' });
    departmentInfoDispatch({ type: 'LOGOUT_USER.SUCCESS' });

    Cookies.remove('departmentdomain');
    window.Intercom?.('shutdown');
    navigate('/login');
  };

  return logout;
};
