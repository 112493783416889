import { Box } from '@mui/material';
import { PayrollCode, PayrollData } from '@stationwise/share-types';
import { PayrollCard } from './PayrollCard';

interface PayrollCardsProps {
  days: Date[];
  payroll: PayrollData;
  setOpenCompTimeModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleRequestCompTime: (day: Date, overtimeCode: string) => void;
  setPayrollCode: React.Dispatch<React.SetStateAction<PayrollCode | null>>;
}

export const PayrollCards = ({
  days,
  payroll,
  setOpenCompTimeModal,
  handleRequestCompTime,
  setPayrollCode,
}: PayrollCardsProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
      })}
    >
      <PayrollCard
        days={days}
        payroll={payroll.typicalWorkingPayroll}
        totalHours={payroll.activeWorkingHours}
        setOpenCompTimeModal={setOpenCompTimeModal}
        handleRequestCompTime={handleRequestCompTime}
        setPayrollCode={setPayrollCode}
        type={'REGULAR'}
        name={'Standard 24h shift'}
      />

      {payroll.pendingCompTimeDuration !== 0 && (
        <PayrollCard
          days={days}
          payroll={payroll.pendingCompTimeRequests}
          totalHours={payroll.pendingCompTimeDuration}
          setOpenCompTimeModal={setOpenCompTimeModal}
          handleRequestCompTime={handleRequestCompTime}
          setPayrollCode={setPayrollCode}
          type={'COMP TIME'}
          name={'Pending comp time'}
          isPending={true}
        />
      )}

      {payroll.totalOvertimeDuration !== 0 && (
        <PayrollCard
          days={days}
          payroll={payroll.nonTypicalPayroll}
          totalHours={payroll.totalOvertimeDuration}
          setOpenCompTimeModal={setOpenCompTimeModal}
          handleRequestCompTime={handleRequestCompTime}
          setPayrollCode={setPayrollCode}
          type={'OVERTIME'}
          name={'Overtime Pay'}
        />
      )}
    </Box>
  );
};
