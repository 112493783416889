import {
  EmployeeMaybeConsecutiveWorkDuration,
  StaffingListItem,
  RosterEmployee,
  RosterShiftDuration,
} from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { IShiftSummaryHelper } from '../types';

/**
 * Merge consecutive work durations together for an employee.
 * - `maybeConsecutiveDurations` is the employee's durations returned by the backend. These may or may not be consecutive.
 * - `currentDurations` is the employee's durations on the current roster date.
 * - `futureDurations` is the employee's durations on days after the current roster date.
 *   For example, it's possible to assign someone to a strike team from the roster date to the deactivation date.
 * - `shiftDuration` is the shift start and end datetimes on the current roster date.
 * - The durations are consecutive according to `breakHours` configured on `DepartmentMaxConsecutiveHoursRule`.
 */
export const mergeConsecutiveWorkDurations = (
  maybeConsecutiveDurations: EmployeeMaybeConsecutiveWorkDuration[],
  currentDurations: Pick<RosterEmployee, 'startDateTime' | 'endDateTime'>[],
  futureDurations: RosterShiftDuration[],
  shiftDuration: RosterShiftDuration,
  breakHours: number,
) => {
  const durations: Omit<RosterShiftDuration, 'hours'>[] = [];

  maybeConsecutiveDurations.forEach((duration) => {
    // Exclude the durations within `shiftDuration` because `currentDurations` is more accurate.
    const startTime = new Date(duration.start);
    const endTime = new Date(duration.end);
    if (startTime < shiftDuration.endTime && endTime > shiftDuration.startTime) {
      if (startTime < shiftDuration.startTime) {
        durations.push({ startTime, endTime: shiftDuration.startTime });
      }
      if (endTime > shiftDuration.endTime) {
        durations.push({ startTime: shiftDuration.endTime, endTime });
      }
    } else {
      durations.push({ startTime, endTime });
    }
  });

  currentDurations.forEach((duration) => durations.push({ startTime: duration.startDateTime, endTime: duration.endDateTime }));

  futureDurations.forEach((duration) => durations.push({ startTime: duration.startTime, endTime: duration.endTime }));

  durations.sort((a, b) => differenceInUTCMinutes(a.startTime, b.startTime));

  const mergedDurations: Omit<RosterShiftDuration, 'hours'>[] = [];
  durations.forEach((currDuration) => {
    const prevDuration = mergedDurations[mergedDurations.length - 1];
    if (prevDuration && prevDuration.endTime >= currDuration.startTime) {
      if (currDuration.endTime > prevDuration.endTime) {
        mergedDurations[mergedDurations.length - 1] = { ...prevDuration, endTime: currDuration.endTime };
      }
    } else {
      mergedDurations.push(currDuration);
    }
  });

  const consecutiveDurations: RosterShiftDuration[] = [];
  mergedDurations.forEach((currDuration) => {
    const prevDuration = consecutiveDurations[consecutiveDurations.length - 1];
    const currDurationHours = differenceInUTCMinutes(currDuration.endTime, currDuration.startTime) / 60;

    // If `breakHours > 0`, the durations are consecutive if the gap between them is less than `breakHours`.
    // If `breakHours === 0`, the durations are consecutive if there is no gap between them.
    const gapHours = !prevDuration ? 0 : differenceInUTCMinutes(currDuration.startTime, prevDuration.endTime) / 60;
    const isConsecutive = breakHours ? gapHours < breakHours : !gapHours;
    if (prevDuration && isConsecutive) {
      consecutiveDurations[consecutiveDurations.length - 1] = {
        ...prevDuration,
        endTime: currDuration.endTime,
        hours: prevDuration.hours + currDurationHours,
      };
    } else {
      consecutiveDurations.push({ ...currDuration, hours: currDurationHours });
    }
  });

  return consecutiveDurations.filter((duration) => {
    if (futureDurations.length > 0) {
      return duration.endTime > shiftDuration.startTime;
    }
    return duration.startTime < shiftDuration.endTime && duration.endTime > shiftDuration.startTime;
  });
};

export const getOvertimeCardErrorMessage = (shiftSummaryHelper: IShiftSummaryHelper, operator: StaffingListItem) => {
  const { maxConsecutiveHoursRule, shiftDuration } = shiftSummaryHelper;
  if (typeof maxConsecutiveHoursRule.consecutiveHours === 'number') {
    const consecutiveWorkDurations = mergeConsecutiveWorkDurations(
      operator.employee.maybeConsecutiveWorkDurations || [],
      [{ startDateTime: shiftDuration.startTime, endDateTime: shiftDuration.endTime }],
      [],
      shiftDuration,
      maxConsecutiveHoursRule.breakHours ?? 0,
    );
    const consecutiveWorkHours = Math.max(...consecutiveWorkDurations.map((d) => d.hours));
    if (consecutiveWorkHours > maxConsecutiveHoursRule.consecutiveHours) {
      return `This person will work ${consecutiveWorkHours} consecutive hours, which exceeds the maximum ${maxConsecutiveHoursRule.consecutiveHours} hours, if they are fully staffed today`;
    }
  }

  return undefined;
};
