import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { GenericDrawer } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';

interface DenyMessageProp {
  name: string;
  requestId: number;
  setNote: (note: string) => void;
}
export const DenyMessage = ({ name, requestId, setNote }: DenyMessageProp) => {
  const [message, setMessage] = useState('');
  const theme = useTheme();
  const handleClose = () => {
    return;
  };

  const rejectTradeShift = async () => {
    try {
      await client.put(`request/shift-trade-request/${requestId}/reject/`, {
        reasonResponse: message,
      });
      setNote(message);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <GenericDrawer
      anchor="bottom"
      loading={false}
      drawerOpen={true}
      handleOnClose={() => handleClose()}
      showHeader={true}
      headerTitle={`Let ${name} know why you can't accept his trade request`}
      footerButtons={[
        {
          label: 'Send',
          onClick: rejectTradeShift,
          backgroundColor: theme.palette.common.black,
          disabled: !message,
        },
      ]}
    >
      <TextField
        value={message}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setMessage(event.target.value);
        }}
        sx={(theme) => ({
          width: '100%',
          mt: theme.spacing(1),
          p: theme.spacing(2),
        })}
        multiline
        rows={6}
      />
    </GenericDrawer>
  );
};
