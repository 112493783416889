import { Box, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { LetterAvatar, SHIFT_TITLES, STATUS_TITLES, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { LoggedInEmployeeDataView, RequestOverview, Waitlist } from '@stationwise/share-types';
import { StatusBadge } from './StatusBadge';

export const getStatus = (status: string, isWaitlist = false) => {
  switch (status) {
    case STATUS_TITLES.ADMIN_APPROVED:
      return 'Approved';
    case STATUS_TITLES.ADMIN_DENIED:
      return 'Denied';
    case STATUS_TITLES.USER_CANCELLED:
      return 'Cancelled';
    case isWaitlist && (STATUS_TITLES.USER_MESSAGE_SENT || STATUS_TITLES.ADMIN_POSTPONED):
      return 'On waitlist';
    default:
      return 'Pending';
  }
};

interface Prop {
  waitlist?: Waitlist | null;
  overview: RequestOverview;
  eventColor?: string;
  employee?: LoggedInEmployeeDataView;
}

export const StatusCard = ({ overview, waitlist, employee }: Prop) => {
  const theme = useTheme();
  const isWaitlist = !!waitlist;
  const status = getStatus(overview.status, isWaitlist);
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const isAdminApprovalRequired = departmentContext.departmentInfo.settings.shiftTradeRequiresAdminApproval;

  if (!isAdminApprovalRequired) return null;
  if (overview.title === SHIFT_TITLES.INCIDENT_PAY) return null;
  return (
    <Box
      sx={{
        borderRadius: '12px',
        border: `1px solid ${theme.palette.stationGray[200]}`,
        color: theme.palette.stationGray[600],
      }}
    >
      <Box display="flex" justifyContent="space-between" sx={{ whiteSpace: 'nowrap', p: theme.spacing(1.5) }}>
        <Box display="flex">
          {status !== 'Pending' && status !== 'Cancelled' && status !== 'On waitlist' && (
            <Box sx={{ pr: theme.spacing(1) }}>
              <LetterAvatar firstName={overview.reviewedBy.charAt(0)} color="purple" />
            </Box>
          )}
          <Box>
            <Box display="flex" sx={{ pt: '2px' }}>
              {status !== 'Pending' && status !== 'On waitlist' ? (
                <>
                  <Typography variant="bodyMMedium">{status}</Typography>
                  <Typography variant="bodyMRegular" sx={{ ml: '5px' }}>{`by ${overview.reviewedBy}`}</Typography>
                </>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="bodyMSemibold">Approval pending</Typography>
                  {employee?.managerOne ? <Typography variant="bodyMRegular"> 1.{employee?.managerOne?.name}</Typography> : null}
                  {employee?.managerTwo ? <Typography variant="bodyMRegular"> 2.{employee?.managerTwo?.name}</Typography> : null}
                  {employee?.managerThree ? (
                    <Typography variant="bodyMRegular"> 3.{employee?.managerThree?.name}</Typography>
                  ) : null}
                </Box>
              )}
            </Box>
            {status !== 'Pending' && status !== 'On waitlist' && overview.reviewedAt && (
              <Box>
                <Typography>
                  {`${format(overview.reviewedAt, 'MMM dd, yyyy')} at ${format(overview.reviewedAt, 'HHmm')}`}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ ml: theme.spacing(1) }}>
          <StatusBadge status={status} />
        </Box>
      </Box>
      {overview.reasonResponse && (
        <Box sx={{ borderTop: `1px solid ${theme.palette.stationGray[200]}` }}>
          <Box sx={{ ml: theme.spacing(1), pt: theme.spacing(1) }}>
            <Typography variant="bodySMedium" sx={{ color: theme.palette.stationGray[400] }}>
              Note from {overview.reviewedBy}
            </Typography>
          </Box>
          <Box sx={{ ml: theme.spacing(1), p: '4px 0 8px 0' }}>
            <Typography variant="bodyMRegular" sx={{ color: theme.palette.stationGray[600] }}>
              {overview.reasonResponse}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
