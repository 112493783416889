import { Box, Drawer } from '@mui/material';
import { XCloseIcon16 as CloseIcon, Loader, useFetchEmployeesOff } from '@stationwise/component-module';
import { AbsenceCard } from './AbsenceCard';
import { NoEmployeesOffCard } from './NoEmployeesOffCard';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const EmployeesOffDrawer = ({ open, onClose }: Props) => {
  const { employeesOff, isLoading, error } = useFetchEmployeesOff({});

  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={onClose}
      ModalProps={{ keepMounted: true }}
      sx={{ '& .MuiDrawer-paper': { display: 'flex', flexDirection: 'column', width: '100%' } }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '64px',
          px: 2,
          borderBottom: `1px dashed ${theme.palette.stationGray[100]}`,
        })}
      >
        <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodyLSemibold' })}>Off roster</Box>
        <Box
          onClick={onClose}
          sx={(theme) => ({
            width: '28px',
            height: '28px',
            borderRadius: '20px',
            background: theme.palette.stationGray[100],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <CloseIcon />
        </Box>
      </Box>
      <Box sx={(theme) => ({ flex: 1, p: theme.spacing(4, 2) })}>
        {isLoading && (
          <Box sx={{ position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
            <Loader />
          </Box>
        )}
        {!isLoading && !employeesOff && (
          <Box sx={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', textAlign: 'center' }}>
            {`Something went wrong please try again later ${error}`}
          </Box>
        )}
        {!isLoading && employeesOff && employeesOff.length === 0 && <NoEmployeesOffCard />}
        {!isLoading && employeesOff && employeesOff.length > 0 && (
          <Box sx={{ '& > * + *': { mt: 3 } }}>
            {employeesOff.map((employeeOffData) => (
              <AbsenceCard key={`${employeeOffData.requestType}:${employeeOffData.id}`} employeeOffData={employeeOffData} />
            ))}
          </Box>
        )}
      </Box>
    </Drawer>
  );
};
