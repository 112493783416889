import { useCallback, useState } from 'react';
import { RosterStrikeTeamApparatus } from '@stationwise/share-types';

export const useCreateStrikeTeamModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [duplicatedFromApparatus, setDuplicatedFromApparatus] = useState<RosterStrikeTeamApparatus | null>(null);

  const resetState = useCallback(() => {
    setIsOpen(false);
    setDuplicatedFromApparatus(null);
  }, []);

  return {
    isOpen,
    duplicatedFromApparatus,
    setIsOpen,
    setDuplicatedFromApparatus,
    resetState,
  };
};
