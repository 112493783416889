import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { PayrollData } from '@stationwise/share-types';

interface Props {
  startDate?: string;
  endDate?: string;
  skip?: boolean;
  refetchCounter?: number;
}

export const useFetchPersonalPayroll = ({ startDate, endDate, skip = false, refetchCounter }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<PayrollData | null>(null);

  useEffect(() => {
    if (skip) {
      return;
    }
    if (refetchCounter === 0) {
      setIsLoading(true);
      setData(null);
    }
    setIsError(false);
    setError(null);

    client
      .get('/payroll/personal/', {
        params: {
          startDate: startDate ?? '',
          endDate: endDate ?? '',
        },
      })
      .then((data) => {
        if (data.status === 200) {
          setData(data.data);
        } else {
          setIsError(true);
          setError(data.statusText);
        }
      })
      .catch((error) => {
        setIsError(true);
        setError(error);
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [endDate, startDate, skip, refetchCounter]);

  return {
    isLoading,
    isError,
    error,
    data,
  };
};
