import { Box, Popover } from '@mui/material';
import { useId, useState } from 'react';
import { RosterEmployee } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { formatShiftTime } from '@stationwise/shift-summary-helper';
import { Badge } from '../../../Badge';
import { CardCertBadges } from '../../../Card';
import { getEmployeeCardStyles } from '../EmployeeCard';
import { useRosterContext } from '../RosterContext';

export interface FloaterCardProps {
  card: RosterEmployee;
}

export const FloaterCard = ({ card }: FloaterCardProps) => {
  const { selectedEmptyPositionState, shiftSummaryHelper } = useRosterContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isSplitted = differenceInUTCMinutes(card.endDateTime, card.startDateTime) !== shiftSummaryHelper.shiftDuration.hours * 60;
  const id = useId();
  return (
    <Box
      sx={[
        (theme) => ({
          background: theme.palette.common.white,
          border: `3px solid ${theme.palette.common.white}`,
          borderRadius: theme.spacing(1.5),
          boxShadow: '0px 1px 2px 0px #0A0E160D',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          height: theme.spacing(8),
          p: theme.spacing(1, 1.5),
        }),
        (theme) => getEmployeeCardStyles({ theme, employeeActiveId: card.activeId, selectedEmptyPositionState }),
      ]}
    >
      <Box display="flex" alignItems="center" gap="6px">
        <Badge label={card.rank.code} isRound type="sm" color={card.rank.color} isBold noPadding isFloater={true} />
        <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySMedium' })}>
          {(() => {
            const [firstName, ...lastNames] = card.name.split(' ');
            return `${firstName[0]}. ${lastNames.join(' ')}`;
          })()}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={0.5}>
          <CardCertBadges
            certs={card.certifications}
            size="md"
            sx={{
              maxWidth: '140px',
              '&:after': {
                display: card.certifications.length > 3 ? 'block' : 'none',
                position: 'absolute',
                background: 'linear-gradient(90deg, transparent 2%, white)',
                top: 0,
                right: 0,
                width: '55px',
                height: '100%',
                content: '""',
              },
            }}
          />
        </Box>
        {isSplitted && (
          <>
            <Box
              aria-describedby={`${id}popover`}
              justifyContent="center"
              alignItems="center"
              lineHeight="12px"
              marginTop="-3px"
              onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
              onMouseLeave={() => setAnchorEl(null)}
              sx={[
                (theme) => ({
                  color: theme.palette.stationGray[400],
                  display: 'inline-flex',
                  fontSize: '10px',
                }),
              ]}
            >
              {formatShiftTime(card.startDateTime)}
              <br />
              {formatShiftTime(card.endDateTime)}
            </Box>
            <Popover
              open={!!anchorEl}
              id={`${id}popover`}
              sx={{ pointerEvents: 'none' }}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={() => setAnchorEl(null)}
              disableRestoreFocus
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  p: theme.spacing(1, 1.5),
                  background: theme.palette.common.white,
                  color: theme.palette.stationGray[500],
                  typography: 'bodyXSRegular',
                })}
              >
                {differenceInUTCMinutes(card.endDateTime, card.startDateTime) / 60}h shift
              </Box>
            </Popover>
          </>
        )}
      </Box>
    </Box>
  );
};
