import {
  RosterDataSource,
  RosterStation,
  RosterShiftDuration,
  ShiftSummary,
  ShiftSummaryStation,
  ShiftSummaryPlannedAssignment,
  ShiftSummaryEmployee,
} from '@stationwise/share-types';
import { setEmployeeActiveId } from './id';
import { IShiftSummaryHelper } from './types';

const transformShiftSummaryEmployee = (employee: ShiftSummaryEmployee, dataSource: RosterDataSource) => {
  return setEmployeeActiveId({
    ...employee,
    id: String(employee.id ?? ''),
    dataSource,
    startDateTime: new Date(employee.startDateTime),
    endDateTime: new Date(employee.endDateTime),
  });
};

/**
 *  This function converts backend ShiftSummaryStation data to frontend RosterStation data.
 * @param stations  - backend ShiftSummaryStation data
 * @param shiftDuration  - shift duration
 * @returns - frontend RosterStation data
 */
export const getAllStationCardsMap = (stations: ShiftSummaryStation[], shiftDuration: RosterShiftDuration) => {
  const newMap = new Map<string, RosterStation>();
  stations.forEach((station) => {
    const newStation = {
      ...station,
      stationId: String(station.stationId),
      dataSource: RosterDataSource.STATION,
      apparatuses: station.apparatuses.map((apparatus) => {
        return {
          ...apparatus,
          id: String(apparatus.id),
          dataSource: RosterDataSource.STATION,
          positions: apparatus.positions.map((position) => {
            const temporaryEmployee = position.isTemporary && position.employees[0];
            return {
              ...position,
              id: String(position.id),
              dataSource: RosterDataSource.STATION,
              startDateTime: temporaryEmployee ? new Date(temporaryEmployee.startDateTime) : shiftDuration.startTime,
              endDateTime: temporaryEmployee ? new Date(temporaryEmployee.endDateTime) : shiftDuration.endTime,
              employees: position.employees
                .map((employee) => transformShiftSummaryEmployee(employee, RosterDataSource.STATION))
                .sort((employeeA, employeeB) => employeeA.startDateTime.getTime() - employeeB.startDateTime.getTime()),
            };
          }),
        };
      }),
    };
    newMap.set(String(station.stationId), newStation);
  });
  return newMap;
};

export const getPlannedAssignmentsMap = (plannedAssignments: ShiftSummaryPlannedAssignment[]) => {
  const map = new Map<string, ShiftSummaryPlannedAssignment[]>();
  plannedAssignments.forEach((assignment) => {
    const employeeId = `${assignment.employeeId}`;
    const assignments: ShiftSummaryPlannedAssignment[] = map.get(employeeId) || [];
    assignments.push(assignment);
    map.set(employeeId, assignments);
  });
  return map;
};

interface GetShiftSummaryHelperInput {
  shiftSummary: ShiftSummary;
  shiftDuration: RosterShiftDuration;
}

/**
 *  Get shift summary helper object from shift summary
 * @param shiftSummary - shift summary
 * @param shiftDuration - shift duration
 * @returns - shift summary helper
 */
export const getShiftSummaryHelper = ({ shiftSummary, shiftDuration }: GetShiftSummaryHelperInput): IShiftSummaryHelper => {
  return {
    unassignedCards: shiftSummary.floaters.map((floater) => transformShiftSummaryEmployee(floater, RosterDataSource.FLOATER)),
    allStationCards: getAllStationCardsMap(shiftSummary.stations, shiftDuration),
    plannedAssignments: getPlannedAssignmentsMap(shiftSummary.plannedAssignments),
    unplannedAssignees: [],
    shiftDuration,
    kellyDayEmployeeIds: shiftSummary.kellyDayEmployeeIds,
    currentTeams: shiftSummary.shiftTeams,
    shiftColor: shiftSummary.shiftColor,
    currentBattalion: {
      id: shiftSummary.battalion.id,
      name: shiftSummary.battalion.name,
      stations: [],
    },
    payCodes: [],
    detailCodes: [],
    certificationActAsRanks: [],
    maxActorsRule: {},
    maxConsecutiveHoursRule: {},
    createdAt: new Date().toISOString(),
    actingPayCodeMappings: [],
  };
};
