import { Box } from '@mui/material';
import { Button } from '@stationwise/component-module';
import { ADDITIONAL_PAID_TIME_OPTIONS } from './AdditionalPaidTimeContent';

interface TypeButtonProps {
  handleButtonClick: (selectedButton: string) => void;
  selectedButton: string;
}

export const TypeOfAdditionalPaidTime = ({ selectedButton, handleButtonClick }: TypeButtonProps) => {
  const isArrivedEarly = selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.ARRIVED_EARLY;
  const isHeldOver = selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.HELD_OVER;
  const isManualEntry = selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY;
  return (
    <>
      <Box
        sx={(theme) => ({
          px: theme.spacing(2),
          my: theme.spacing(2),
          typography: 'bodyLMedium',
        })}
      >
        Type of additional paid time
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          px: theme.spacing(2),
        })}
      >
        <Button
          data-cy="arrived-early-button"
          buttonType="tertiary"
          buttonSize="sm"
          sx={() => ({
            backgroundColor: isArrivedEarly ? 'black' : 'white',
            color: isArrivedEarly ? 'white' : 'black',
            borderRadius: '8px',
          })}
          disableFocusRipple={true}
          disableTouchRipple={true}
          variant={isArrivedEarly ? 'contained' : 'outlined'}
          onClick={() => handleButtonClick(ADDITIONAL_PAID_TIME_OPTIONS.ARRIVED_EARLY)}
        >
          Arrived early
        </Button>
        <Button
          data-cy="held-over-button"
          buttonType="tertiary"
          disableFocusRipple={true}
          disableTouchRipple={true}
          variant={isHeldOver ? 'contained' : 'outlined'}
          onClick={() => handleButtonClick(ADDITIONAL_PAID_TIME_OPTIONS.HELD_OVER)}
          sx={() => ({
            backgroundColor: isHeldOver ? 'black' : 'white',
            color: isHeldOver ? 'white' : 'black',
            borderRadius: '8px',
          })}
        >
          Held Over
        </Button>
        <Button
          data-cy="manual-entry-button"
          buttonType="tertiary"
          disableFocusRipple={true}
          disableTouchRipple={true}
          variant={isManualEntry ? 'contained' : 'outlined'}
          onClick={() => handleButtonClick(ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY)}
          sx={() => ({
            backgroundColor: isManualEntry ? 'black' : 'white',
            color: isManualEntry ? 'white' : 'black',
            borderRadius: '8px',
          })}
        >
          Manual entry
        </Button>
      </Box>
    </>
  );
};
