import { Theme, useMediaQuery } from '@mui/material';
import { useCallback, useMemo, useRef, useState } from 'react';
import { TemporaryNonShiftType, RosterEmployee } from '@stationwise/share-types';
import { IShiftSummaryHelper, getBoardEmployee } from '@stationwise/shift-summary-helper';
import { useLoadedAuthUserContext } from '../../Auth';

type SelectedBoardEmployeeResult = Pick<ReturnType<typeof getBoardEmployee>, 'station' | 'apparatus' | 'position' | 'employee'>;

enum DIALOG_TYPE {
  EMPLOYEE_PREVIEW = 'EMPLOYEE_PREVIEW',
  EMPLOYEE_ACTIONS = 'EMPLOYEE_ACTIONS',
}

const getEmptyBoardEmployeeResult = () => ({ station: null, apparatus: null, position: null, employee: null });

export const useSelectedFilledPosition = (shiftSummaryHelper: IShiftSummaryHelper) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const isMobileApp = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isAdmin = ['ADMIN', 'SUPER_ADMIN'].includes(authUserState.employee.role);
  const [selectedEmployeeActiveId, setSelectedEmployeeActiveId] = useState<string | null>(null);
  const [selectedAnchorEl, setSelectedAnchorEl] = useState<HTMLElement | null>(null);
  const [dialogType, setDialogType] = useState<DIALOG_TYPE | null>(null);
  const [selectedMoveToTemporaryNonShiftType, setSelectedMoveToTemporaryNonShiftType] = useState<TemporaryNonShiftType | null>(
    null,
  );

  const selectedBoardEmployeeResultRef = useRef<SelectedBoardEmployeeResult>(getEmptyBoardEmployeeResult());

  const selectedBoardEmployeeResult = useMemo(() => {
    if (!selectedEmployeeActiveId) {
      return getEmptyBoardEmployeeResult();
    }

    // Some Employee Preview actions, such as Unstaff From Event, remove the employee.
    // For such cases, the board employee result is empty, but we don't want it to be empty because
    // the Employee Preview content disappears immediately, before the popover has a chance to fade away.
    // Therefore, we fall back to the previous board employee result.
    const result = getBoardEmployee(shiftSummaryHelper, selectedEmployeeActiveId);
    return result.employee ? result : selectedBoardEmployeeResultRef.current;
  }, [shiftSummaryHelper, selectedEmployeeActiveId]);

  selectedBoardEmployeeResultRef.current = selectedBoardEmployeeResult;

  const resetState = useCallback(() => {
    setSelectedEmployeeActiveId(null);
    setSelectedAnchorEl(null);
    setDialogType(null);
    selectedBoardEmployeeResultRef.current = getEmptyBoardEmployeeResult();
    setSelectedMoveToTemporaryNonShiftType(null);
  }, []);

  const setEmployee = (employee: RosterEmployee, anchorEl: HTMLElement | null) => {
    if (!employee.activeId || !anchorEl) {
      return;
    }

    setSelectedEmployeeActiveId(employee.activeId);
    setSelectedAnchorEl(anchorEl);
    setDialogType(isMobileApp || !isAdmin ? DIALOG_TYPE.EMPLOYEE_ACTIONS : DIALOG_TYPE.EMPLOYEE_PREVIEW);
  };

  const isEmployeePreviewOpen = !!(selectedAnchorEl && dialogType === DIALOG_TYPE.EMPLOYEE_PREVIEW);
  const isEmployeeActionsOpen = !!(selectedAnchorEl && dialogType === DIALOG_TYPE.EMPLOYEE_ACTIONS);

  const closeDialog = () => setSelectedAnchorEl(null);

  return {
    station: selectedBoardEmployeeResult.station,
    apparatus: selectedBoardEmployeeResult.apparatus,
    position: selectedBoardEmployeeResult.position,
    employee: selectedBoardEmployeeResult.employee,
    anchorEl: selectedAnchorEl,
    isEmployeePreviewOpen,
    isEmployeeActionsOpen,
    selectedMoveToTemporaryNonShiftType,
    setEmployee,
    setSelectedMoveToTemporaryNonShiftType,
    closeDialog,
    resetState,
  };
};
