import { Dialog } from '@mui/material';
import { useRosterContext } from '../RosterContext';
import { SplitShiftOrTimeOffForm } from './SplitShiftOrTimeOffForm';

export const SplitShiftOrTimeOffModal = () => {
  const { splitShiftOrTimeOffState } = useRosterContext();
  return (
    <Dialog
      open={splitShiftOrTimeOffState.isDialogOpen}
      onClose={() => splitShiftOrTimeOffState.setIsDialogOpen(false)}
      sx={(theme) => ({
        '& .MuiDialog-paper': {
          borderRadius: '16px',
          color: theme.palette.stationGray[900],
          p: 3,
          width: '496px',
          maxWidth: '100%',
          typography: 'bodyMRegular',
        },
      })}
    >
      <SplitShiftOrTimeOffForm />
    </Dialog>
  );
};
