import { Box } from '@mui/material';
import { CheckIcon40, ExclamationMarkIcon24, QuestionMarkIcon24, XIcon24 } from '../../assets/index';

export type Status = 'success' | 'error' | 'warning' | 'question';

const StatusIcon = ({ status }: { status: Status }) => {
  switch (status) {
    case 'success':
      return <CheckIcon40 />;
    case 'error':
      return (
        <Box
          sx={(theme) => ({
            '& svg': {
              width: '40px',
              height: '40px',
            },
            color: theme.palette.stationRose[500],
          })}
        >
          <XIcon24 />
        </Box>
      );
    case 'warning':
      return (
        <Box
          sx={(theme) => ({
            '& svg': {
              width: '40px',
              height: '40px',
            },
            color: theme.palette.stationOrange[500],
          })}
        >
          <ExclamationMarkIcon24 />
        </Box>
      );
    case 'question':
      return (
        <Box
          sx={(theme) => ({
            '& svg': {
              width: '40px',
              height: '40px',
            },
            color: theme.palette.stationPurple[500],
          })}
        >
          <QuestionMarkIcon24 />
        </Box>
      );
    default:
      return null;
  }
};

export const FeedbackMessage = ({
  status,
  firstMessage,
  secondMessage = 'You will be notified about any processing of this request.',
}: {
  status: Status;
  firstMessage: string;
  secondMessage?: string;
}) => {
  return (
    <Box>
      <Box
        sx={(theme) => ({
          width: '80px',
          height: '80px',
          borderRadius: '80px',
          margin: 'auto',
          textAlign: 'center',
          mt: '44px',
          mb: '40px',
          border: `1px solid ${theme.palette.stationGray[50]}`,
          boxShadow: '0px 2px 4px -1px rgba(10, 14, 22, 0.06), 0px 4px 6px -1px rgba(10, 14, 22, 0.1)',
        })}
      >
        <Box sx={{ pt: '25%' }}>
          <StatusIcon status={status} />
        </Box>
      </Box>
      <Box sx={{ width: '310px', textAlign: 'center', margin: 'auto' }}>
        <Box sx={{ typography: 'bodyXXLSemibold' }}>{firstMessage}</Box>
        <Box
          sx={(theme) => ({
            color: theme.palette.stationGray[500],
            mt: theme.spacing(2),
            mb: theme.spacing(4),
          })}
        >
          {(status === 'success' || status === 'question' || status === 'warning') && (
            <Box sx={{ typography: 'bodyLRegular' }}>{secondMessage}</Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
