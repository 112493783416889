import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import React from 'react';
import { makeTestIdentifier } from '@stationwise/share-utils';

export interface ChipFilterProps {
  label: string;
  icon: React.ElementType | null;
  action: (labe: string) => void;
  isSelected?: boolean;
  amount?: number;
  highlight?: boolean;
  onClose?: () => void;
}

export const ChipFilter = ({ label, icon, action, isSelected, amount, highlight, onClose }: ChipFilterProps) => {
  const Icon = icon;
  return (
    <Box data-cy={`chip-filter-${makeTestIdentifier(label)}`} onClick={() => action(label)} sx={{ position: 'relative' }}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          cursor: 'pointer',
          padding: amount || onClose ? '4px 4px 4px 12px' : '6px 12px 6px 8px',
          borderRadius: theme.spacing(4),
          backgroundColor: isSelected ? theme.palette.stationGray[900] : theme.palette.stationGray[100],
          '&:hover': {
            backgroundColor: !isSelected && theme.palette.stationGray[200],
          },
        })}
      >
        {Icon !== null && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              height: theme.spacing(2),
              width: theme.spacing(2),
              '& path': {
                stroke: isSelected ? theme.palette.common.white : theme.palette.stationGray[900],
              },
            })}
          >
            <Icon />
          </Box>
        )}
        <Box
          sx={(theme) => ({
            color: isSelected ? theme.palette.common.white : theme.palette.stationGray[900],
            typography: theme.typography.bodySRegular,
          })}
        >
          {label}
        </Box>
        {(amount || onClose) && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: isSelected ? theme.palette.stationGray[700] : theme.palette.common.white,
              borderRadius: theme.spacing(4),
              padding: '4px',
              minWidth: '24px',
              height: '24px',
              width: '24px',
            })}
            onClick={onClose}
          >
            {amount ? (
              <Box
                sx={(theme) => ({
                  color: isSelected ? theme.palette.common.white : theme.palette.stationGray[900],
                  typography: 'bodySRegular',
                })}
              >
                {amount}
              </Box>
            ) : (
              <CloseIcon
                sx={(theme) => ({
                  height: '16px',
                  width: '16px',
                  padding: '0px',
                  color: isSelected ? theme.palette.common.white : theme.palette.stationGray[900],
                })}
              />
            )}
          </Box>
        )}
      </Box>
      {highlight && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: '-2px',
          }}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.stationRose[400],
              borderRadius: '20px',
              display: 'flex',
              height: '10px',
              width: '10px',
              border: `2px solid ${theme.palette.common.white}`,
            })}
          />
        </Box>
      )}
    </Box>
  );
};
