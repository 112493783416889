import { Box } from '@mui/material';
import { format } from 'date-fns';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { RosterContextProvider, useLoadedAuthUserContext, useRoster } from '@stationwise/component-module';
import {
  ShiftSummary,
  RosterStation,
  RosterAdministrationStation,
  RosterTemporaryNonShiftStation,
} from '@stationwise/share-types';
import { DaySchedule } from './DaySchedule';
import { ScrollTopButton } from './ScrollTopButton';
import { TopBar } from './TopBar';

interface Props {
  shiftSummary: ShiftSummary;
  administrationStations: RosterAdministrationStation[];
  eventStations: RosterStation[];
  temporaryNonShiftStation: RosterTemporaryNonShiftStation;
  currentDate: Date;
  selectedBattalionState: [number | undefined, Dispatch<SetStateAction<number | undefined>>];
}

export const ScheduleContent = ({
  shiftSummary,
  administrationStations,
  eventStations,
  temporaryNonShiftStation,
  currentDate,
  selectedBattalionState,
}: Props) => {
  const { state: authUserState } = useLoadedAuthUserContext();

  const [isSavingLoading] = useState(false);

  const roster = useRoster({
    shiftSummary,
    administrationStations,
    eventStations,
    temporaryNonShiftStation,
    employeesOff: [],
    currentDate,
    selectedBattalionState,
  });
  const { shiftDuration } = roster.shiftSummaryHelper;

  const shiftLeaderDetails = useMemo(() => {
    for (const [, station] of roster.shiftSummaryHelper.allStationCards) {
      for (const apparatus of station.apparatuses) {
        if (apparatus.isForShiftLeader && apparatus.positions[0]?.employees[0]?.id) {
          return { employee: apparatus.positions[0].employees[0], station, apparatus };
        }
      }
    }

    return null;
  }, [roster.shiftSummaryHelper.allStationCards]);

  const currentEmployeeDetails = useMemo(() => {
    for (const [, station] of roster.shiftSummaryHelper.allStationCards) {
      for (const apparatus of station.apparatuses) {
        for (const position of apparatus.positions) {
          const rosterEmployee = position.employees.find((employee) => employee.id === authUserState.employee.id);
          if (rosterEmployee) {
            return { employee: rosterEmployee, station, apparatus };
          }
        }
      }
    }

    return null;
  }, [roster.shiftSummaryHelper.allStationCards, authUserState.employee.id]);

  return (
    <RosterContextProvider roster={roster}>
      <Box>
        {!authUserState.employee.isApparatusLogin && (
          <TopBar
            shiftDateRange={`${format(shiftDuration.startTime, 'H:mm')}-${format(shiftDuration.endTime, 'H:mm')}`}
            shiftLeaderDetails={shiftLeaderDetails}
            currentEmployeeDetails={currentEmployeeDetails}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          height: '100%',
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <DaySchedule isLoading={isSavingLoading} />
        <ScrollTopButton />
      </Box>
    </RosterContextProvider>
  );
};
