import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';

export const ShiftSchedule = ({
  startDate,
  startTime,
  endDate,
  endTime,
}: {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}) => {
  return (
    <Box
      sx={(theme) => ({
        borderBottom: `solid 1px ${theme.palette.stationGray[100]}`,
      })}
    >
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          mb: '20px',
          justifyContent: { sm: 'space-between' },
        })}
        display="flex"
        alignItems="center"
      >
        <Box sx={{ width: '40%' }}>
          <Typography variant="eyebrow" sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
            START
          </Typography>
        </Box>
        <Box display="flex" gap="14px">
          <Box sx={(theme) => ({ mt: theme.spacing(0.5) })}>
            <Typography variant="bodyMMedium">{format(startDate, 'd MMM yyyy')}</Typography>
          </Box>
          <Box
            sx={(theme) => ({
              border: `solid 1px ${theme.palette.stationGray[100]}`,
              borderRadius: '8px',
              height: '32px',
              width: '60px',
              textAlign: 'center',
              m: 'auto',
              p: '3px',
            })}
          >
            <Typography variant="bodyMMedium">{startTime}</Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          justifyContent: { sm: 'space-between' },
          mb: theme.spacing(2),
        })}
        display="flex"
        alignItems="center"
      >
        <Box sx={{ width: '40%' }}>
          <Typography variant="eyebrow" sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
            END
          </Typography>
        </Box>
        <Box display="flex" gap="14px">
          <Box sx={(theme) => ({ mt: theme.spacing(0.5) })}>
            <Typography variant="bodyMMedium">{format(endDate, 'd MMM yyyy')}</Typography>
          </Box>
          <Box
            sx={(theme) => ({
              border: `solid 1px ${theme.palette.stationGray[100]}`,
              borderRadius: '8px',
              height: '32px',
              width: '60px',
              textAlign: 'center',
              m: 'auto',
              p: '3px',
            })}
          >
            <Typography variant="bodyMMedium">{endTime}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
