import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, ClickAwayListener, Popper } from '@mui/material';
import { useState } from 'react';
import { useLoadedAuthUserContext } from '@stationwise/component-module';
import { WorkGroupPreview } from '@stationwise/share-types';
import { MembersPreview } from './MembersPreview';
import { SubMenu } from './SubMenu';

interface Props {
  workGroups: WorkGroupPreview[];
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRemoveModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  selectedWorkGroupId: number | null;
  setSelectedWorkGroupId: React.Dispatch<React.SetStateAction<number | null>>;
}

export const ListWorkGroups = ({
  workGroups,
  setShowRemoveModal,
  setShowEditModal,
  setTitle,
  selectedWorkGroupId,
  setSelectedWorkGroupId,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { state: authUserState } = useLoadedAuthUserContext();
  const isSuperAdmin = authUserState.employee.role === 'SUPER_ADMIN';

  const handleGroupClick = (group: number) => {
    setSelectedWorkGroupId(group);
  };

  const handleClickAway = () => {
    setIsOpen(true);
    if (anchorEl && isOpen) {
      setAnchorEl(null);
      setIsOpen(false);
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleEdit = () => {
    setShowEditModal(true);
    setTitle('Edit work group');
    handleClickAway();
  };
  const handleRemove = () => {
    setShowRemoveModal(true);
    handleClickAway();
  };

  return (
    <>
      {selectedWorkGroupId !== null && isSuperAdmin && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper id={'edit-popper'} open={Boolean(anchorEl)} anchorEl={anchorEl}>
            <SubMenu handleEdit={handleEdit} handleRemove={handleRemove} />
          </Popper>
        </ClickAwayListener>
      )}
      <Box
        sx={(theme) => ({
          marginLeft: theme.spacing(2),
          mt: theme.spacing(3.5),
          mb: theme.spacing(3),
          color: theme.palette.stationGray[400],
          typography: 'bodyXSRegular',
        })}
      >
        WORK GROUP
      </Box>
      <Box sx={{ height: '100vh', overflowY: 'scroll' }}>
        {workGroups.map((group) => (
          <Box
            key={group.id}
            onClick={() => handleGroupClick(group.id)}
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              padding: '24px 16px 24px 16px',
              borderTop: `1px solid ${theme.palette.stationGray[200]}`,
              gap: theme.spacing(1.5),
              backgroundColor: selectedWorkGroupId === group.id ? theme.palette.stationGray[100] : 'none',
            })}
          >
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={(theme) => ({ typography: 'bodyLMedium', mt: theme.spacing(0.5) })}>{group.name}</Box>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '20px',
                  height: '20px',
                  borderRadius: 2,
                  padding: theme.spacing(0.25),
                  backgroundColor: theme.palette.stationGray[200],
                  visibility: selectedWorkGroupId === group.id && isSuperAdmin ? 'visible' : 'hidden',
                })}
                onClick={handleClick}
              >
                <MoreHorizIcon sx={{ height: '16px', width: '16px' }} />
              </Box>
            </Box>
            <Box sx={(theme) => ({ typography: 'bodySRegular', color: theme.palette.stationGray[500], mt: theme.spacing(0.5) })}>
              {group?.description}
            </Box>
            <Box display="flex" mt={1} justifyContent="space-between" gap={3}>
              <MembersPreview membersCount={group.membersCount} members={group.firstFourMembers} />
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};
