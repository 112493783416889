import { Dialog } from '@mui/material';
import { useMemo } from 'react';
import { checkIsStrikeTeam, checkIsStrikeTeamApparatus, getBoardPosition } from '@stationwise/shift-summary-helper';
import { useRosterContext } from '../RosterContext';
import { StrikeTeamAssignmentForm } from './StrikeTeamAssignmentForm';

export const StrikeTeamAssignmentModal = () => {
  const { selectedEmptyPositionState, shiftSummaryHelper } = useRosterContext();
  const onClose = selectedEmptyPositionState.resetState;

  const { apparatus, position } = useMemo(() => {
    if (!selectedEmptyPositionState.position || !checkIsStrikeTeam(selectedEmptyPositionState.position)) {
      return { apparatus: null, position: null };
    }

    const { apparatus } = getBoardPosition(shiftSummaryHelper, selectedEmptyPositionState.position.id);
    if (!apparatus || !checkIsStrikeTeamApparatus(apparatus)) {
      throw new Error('StrikeTeamAssignmentModal rendered with invalid strike team position');
    }

    // For split vacancies, the `shiftSummaryHelper` position is different from the `selectedEmptyPositionState` position.
    // The latter merges the former with `employee.startDateTime` and `employee.endDateTime`.
    return { apparatus, position: selectedEmptyPositionState.position };
  }, [shiftSummaryHelper, selectedEmptyPositionState.position]);

  return (
    <Dialog
      open={!!(apparatus && position)}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { borderRadius: '16px', position: 'relative' } }}
    >
      {apparatus && position && <StrikeTeamAssignmentForm apparatus={apparatus} position={position} onClose={onClose} />}
    </Dialog>
  );
};
