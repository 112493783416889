import { Box, LinearProgress, linearProgressClasses } from '@mui/material';

export interface Props {
  label: string;
  remaining: number;
  pending: number;
  maxAccrualLimit: number;
  barColor: string;
  pendingBarColor: string;
}

export const TimeOff = ({ label, remaining, pending, maxAccrualLimit, barColor, pendingBarColor }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        border: `solid 1px ${theme.palette.stationGray[100]}`,
        boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
        borderRadius: '8px',
        p: '16px',
      })}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          sx={(theme) => ({
            color: theme.palette.stationGray[700],
            typography: 'bodyMSemibold',
          })}
        >
          {label}
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box sx={(theme) => ({ color: theme.palette.stationGray[400], mr: 1, typography: 'bodySMedium' })}>remaining</Box>
          <Box sx={(theme) => ({ color: theme.palette.stationGray[700], typography: 'bodySMedium' })}>
            {remaining - pending} of {maxAccrualLimit} hrs
          </Box>
        </Box>
      </Box>
      <Box sx={(theme) => ({ mt: theme.spacing(3) })}>
        <LinearProgress
          variant="buffer"
          value={((remaining - pending) * 100) / maxAccrualLimit}
          valueBuffer={(pending * 100) / maxAccrualLimit}
          color="primary"
          sx={(theme) => ({
            height: '6px',
            borderRadius: '23px',
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: theme.palette.stationGray[100],
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
                backgroundColor: barColor,
              },
              [`& .${linearProgressClasses.bar2Buffer}`]: {
                borderRadius: 5,
                backgroundColor: pendingBarColor,
              },
            },
            '& .MuiLinearProgress-dashed': {
              backgroundColor: theme.palette.stationGray[100],
              backgroundImage: 'none',
              animation: 'none',
            },
          })}
        />
      </Box>
    </Box>
  );
};
