import { Box, useTheme } from '@mui/material';
import { getCertColors } from '@stationwise/component-module';
import { CertificationRequirement } from '@stationwise/share-types';

export const CertificationRequirementChip = ({
  certificationRequirement,
  backgroundColor,
}: {
  certificationRequirement: CertificationRequirement;
  backgroundColor?: string;
}) => {
  const theme = useTheme();
  const color = getCertColors({ theme, color: certificationRequirement.color }).color;
  return (
    <Box
      sx={(theme) => ({
        borderRadius: '24px',
        background: backgroundColor || theme.palette.common.white,
        textAlign: 'center',
        color: color,
        boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
        mr: theme.spacing(0.5),
        typography: 'bodyXSRegular',
        px: '4px',
        display: 'flex',
        justifyContent: 'space-between',
      })}
    >
      {certificationRequirement.code}
      <Box
        sx={(theme) => ({
          background: color,
          width: '16px',
          height: '16px',
          borderRadius: '12px',
          p: '0px 3px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: theme.palette.common.white,
          ml: '3px',
          mt: '2px',
        })}
      >
        {certificationRequirement.amount}
      </Box>
    </Box>
  );
};
