import { Card, CardContent, Box } from '@mui/material';
import React, { useState } from 'react';
import { PlusIcon20 } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { WorkGroupPreview } from '@stationwise/share-types';
import { EditWorkGroupModal } from './EditWorkGroupModal';
import { WorkGroupCard } from './WorkGroupCard';

interface NewWorkGroupCardProps {
  clicked: number;
  setClicked: React.Dispatch<React.SetStateAction<number>>;
  workGroupNames: string[];
}

export const NewWorkGroupCard = ({ clicked, setClicked, workGroupNames }: NewWorkGroupCardProps) => {
  const [createdWorkgroup, setCreatedWorkgroup] = useState<WorkGroupPreview>();
  const [openModal, setOpenModal] = useState(false);
  const onCreateClick = () => {
    setOpenModal(true);
  };
  const onCardCreate = (name: string, description: string) => {
    const saveData = async () => {
      const response = await saveWorkGroupView(name, description);
      setClicked(response.id);
      setCreatedWorkgroup(response);
    };
    saveData();
  };
  const saveWorkGroupView = async (name: string, description: string): Promise<WorkGroupPreview> => {
    try {
      const response = await client.post('/organization/workgroups/', {
        name: name,
        description: description,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return {} as WorkGroupPreview;
    }
  };

  return (
    <>
      <EditWorkGroupModal
        title={'New work group'}
        showModal={openModal}
        setShowModal={setOpenModal}
        onCardCreate={onCardCreate}
        workGroupNames={workGroupNames}
      />
      {createdWorkgroup && <WorkGroupCard workgroup={createdWorkgroup} clicked={clicked} setClicked={setClicked} />}

      {!createdWorkgroup && (
        <Card
          sx={(theme) => ({
            display: 'flex',
            width: '354px',
            height: '150px',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: theme.spacing(1.5),
            flexShrink: 0,
            cursor: 'pointer',
            borderRadius: '8px',

            border: `1px dashed ${theme.palette.stationGray[300]}`,

            '&:hover': {
              cursor: 'pointer',
              backgroundColor: theme.palette.stationPurple[100],
            },
          })}
          onClick={() => onCreateClick()}
        >
          <CardContent sx={{ textAlign: 'center' }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  padding: '9px',
                  borderRadius: '19px',
                  backgroundColor: theme.palette.stationGray[900],
                  '& svg': {
                    color: theme.palette.common.white,
                  },
                })}
              >
                <PlusIcon20 />
              </Box>
              <Box
                sx={(theme) => ({
                  typography: 'bodyLMedium',
                  textAlign: 'center',
                  color: theme.palette.stationGray[900],
                  alignSelf: 'stretch',
                })}
              >
                Create new work group
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
};
