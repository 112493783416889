import { Theme } from '@mui/material';

export function getShiftColor(shiftColor: string, theme: Theme) {
  switch (shiftColor) {
    case 'red':
      return theme.palette.stationRed[500];
    case 'gold':
      return theme.palette.stationYellow[500];
    case 'black':
      return theme.palette.stationGray[900];
    case 'green':
      return theme.palette.stationGreen[500];
    case 'yellow':
      return theme.palette.stationYellow[500];
    case 'orange':
      return theme.palette.stationOrange[500];
    case 'blue':
      return theme.palette.stationBlue[500];
    case 'indigo':
      return theme.palette.stationIndigo[500];
    case 'purple':
      return theme.palette.stationPurple[500];
    case 'pink':
      return theme.palette.stationPink[500];
    case 'gray':
      return theme.palette.stationGray[500];
    case 'white':
      return theme.palette.common.white;
    case 'violet':
      return theme.palette.stationViolet[500];
    default:
      return theme.palette.stationGray[500];
  }
}
