import { Box } from '@mui/material';
import { ReactElement } from 'react';

export const ItemLayout = ({ children }: { children: ReactElement }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        width: '100%',
        gap: theme.spacing(2),
        justifyContent: 'space-between',
        pt: theme.spacing(1),
      })}
    >
      {children}
    </Box>
  );
};
