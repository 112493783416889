import { Box } from '@mui/material';
import { CircleHigherIcon20, CircleTickBIcon20, CircleTickCIcon20, CircleWarningIcon20 } from '../../assets';

export interface CounterProps {
  total: number;
  amount: number;
  mode?: 'dark' | 'light';
  overrideIcon?: boolean;
  overrideIconToShow?: 'inGoal' | 'aboveGoal' | 'belowGoal';
}

export const Counter = ({
  amount,
  total,
  mode = 'light',
  overrideIcon = false,
  overrideIconToShow = 'belowGoal',
}: CounterProps) => {
  return (
    <Box
      display="flex"
      sx={(theme) => ({
        color: mode === 'light' ? theme.palette.stationGray[900] : theme.palette.common.white,
      })}
    >
      <Box sx={{ typography: 'bodySRegular', fontWeight: 600, textWrap: 'nowrap' }}>{`${amount} of ${total}`}</Box>
      <Box
        sx={{
          height: '20px',
          width: '20px',
          ml: 1,
        }}
      >
        {(() => {
          if (amount > total || (overrideIcon && overrideIconToShow === 'aboveGoal')) {
            return <CircleHigherIcon20 />;
          }
          if (amount < total || (overrideIcon && overrideIconToShow === 'belowGoal')) {
            return <CircleWarningIcon20 />;
          }
          return (
            <Box>
              {mode === 'dark' ? (
                <CircleTickBIcon20 />
              ) : (
                <Box
                  sx={(theme) => ({
                    '& path': {
                      fill: theme.palette.stationTeal[500],
                    },
                  })}
                >
                  <CircleTickCIcon20 />
                </Box>
              )}
            </Box>
          );
        })()}
      </Box>
    </Box>
  );
};
