import { Box, useTheme } from '@mui/material';
import { format, isValid, startOfDay } from 'date-fns';
import { useDeferredValue, useState } from 'react';
import { useDocumentTitle, useFetchStaffingLists, useSelectedStaffingList } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { SelectedStaffingListControls } from './SelectedStaffingListControls';
import { StaffingListControls } from './StaffingListControls';
import { StaffingListItems } from './StaffingListItems';

export const StaffingListsMain = () => {
  useDocumentTitle('Staffing Lists');
  const theme = useTheme();
  const [searchInput, setSearchInput] = useState('');
  const deferredSearchInput = useDeferredValue(searchInput);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedLastOvertimeDate, setSelectedLastOvertimeDate] = useState('');
  const today = startOfDay(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(today);
  const staffingListsResponse = useFetchStaffingLists({
    selectedDate: format(today, 'MM/dd/yyyy'),
  });
  const { isLoading, staffingLists, selectedStaffingList, setSelectedStaffingList } = useSelectedStaffingList({
    selectedDate: selectedDate && isValid(selectedDate) ? format(selectedDate, 'MM/dd/yyyy') : '',
    staffingListsResponse,
  });

  return (
    <AppPageLayout sx={{ background: theme.palette.common.white, width: '100%' }}>
      <Box component="h1" sx={{ p: theme.spacing(4, 5, 8, 5), typography: 'heading4' }}>
        Staffing Lists
      </Box>
      <Box sx={{ pb: 8, width: '75%', mx: 'auto' }}>
        <StaffingListControls
          staffingLists={staffingLists}
          selectedStaffingList={selectedStaffingList}
          setSelectedStaffingList={setSelectedStaffingList}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <SelectedStaffingListControls
          selectedStaffingList={selectedStaffingList}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          selectedLastOvertimeDate={selectedLastOvertimeDate}
          setSelectedLastOvertimeDate={setSelectedLastOvertimeDate}
        />
        <StaffingListItems
          isLoading={isLoading}
          staffingList={selectedStaffingList}
          searchInput={deferredSearchInput}
          selectedTeam={selectedTeam}
          selectedLastOvertimeDate={selectedLastOvertimeDate}
        />
      </Box>
    </AppPageLayout>
  );
};
