import { captureException } from '@sentry/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { client } from '@stationwise/share-api';
import { DETAIL_CODE_MANDATORY_OT, DETAIL_CODE_VOLUNTARY_OT, ListFieldsStaffingList } from '@stationwise/share-types';
import { getDepartmentFeatureFlagValue, useLoadedDepartmentInfoContext } from '../components/Department';
import { useFetchOvertimeList } from './useFetchOvertime';

interface UseFetchStaffingListsProps {
  selectedDate: string;
  skip?: boolean;
}

export const useFetchStaffingLists = ({ selectedDate, skip = false }: UseFetchStaffingListsProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isStaffingListsEnabled = getDepartmentFeatureFlagValue(departmentInfoState, 'STAFFING_LISTS', false);

  const [refetchCounter, setRefetchCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<ListFieldsStaffingList[] | null>(null);

  useEffect(() => {
    if (skip || !isStaffingListsEnabled) {
      return;
    }
    setIsLoading(true);
    setIsError(false);
    setData(null);
    client
      .get('/staffing-list/staffing-lists/', {
        params: {
          date: selectedDate,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setIsError(true);
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedDate, skip, isStaffingListsEnabled, refetchCounter]);

  const forceRefetch = useCallback(() => setRefetchCounter((c) => c + 1), []);

  const {
    isLoading: isLoadingOvertimeList,
    isError: isErrorOvertimeList,
    data: dataOvertimeList,
    forceRefetch: forceRefetchOvertimeList,
  } = useFetchOvertimeList({
    shiftDate: selectedDate,
    skip: skip || isStaffingListsEnabled,
    isForRoster: true,
  });

  const dataOvertimeAsStaffingLists = useMemo(() => {
    if (!dataOvertimeList) {
      return null;
    }

    const votDetailCode = departmentInfoState.departmentInfo.detailCodes.find((dc) => dc.code === DETAIL_CODE_VOLUNTARY_OT);
    const motDetailCode = departmentInfoState.departmentInfo.detailCodes.find((dc) => dc.code === DETAIL_CODE_MANDATORY_OT);
    if (!votDetailCode || !motDetailCode) {
      throw new Error('Overtime detail codes not found');
    }

    const { voluntaryOvertimeList, mandatoryOvertimeList } = dataOvertimeList;
    return [voluntaryOvertimeList, mandatoryOvertimeList].map((otItems, i) => {
      return {
        id: i + 1,
        name: otItems === voluntaryOvertimeList ? 'Voluntary Overtime' : 'Mandatory Overtime',
        items: otItems.map((otItem) => {
          const { firstName, lastName, employeeTeam, ...otEmployee } = otItem.employee;
          const slEmployee = {
            ...otEmployee,
            id: `${otEmployee.id}`,
            name: `${firstName} ${lastName}`,
            team: employeeTeam,
            detailCodes: otItems === voluntaryOvertimeList ? [votDetailCode] : [motDetailCode],
          };
          const slAttributes = {
            lastThreeOvertimes: [
              { date: otItem.overtime.lastShift, staffedAt: otItem.overtime.lastOtStaffTime },
              { date: otItem.overtime.secondOt },
              { date: otItem.overtime.thirdOt },
            ],
            overtimeAccHours: otItem.overtime.partialHours,
          };
          return { order: otItem.order, employee: slEmployee, attributes: slAttributes };
        }),
      };
    });
  }, [departmentInfoState.departmentInfo, dataOvertimeList]);

  return {
    isLoading: isStaffingListsEnabled ? isLoading : isLoadingOvertimeList,
    isError: isStaffingListsEnabled ? isError : isErrorOvertimeList,
    data: isStaffingListsEnabled ? data : dataOvertimeAsStaffingLists,
    forceRefetch: isStaffingListsEnabled ? forceRefetch : forceRefetchOvertimeList,
  };
};
