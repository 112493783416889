import React from 'react';

export type TBottomSheetEvents = {
  collapsed: string;
  dismissed: string;
  expanded: string;
  snapToTop: string;
};

export type TBottomSheetEventsKey = keyof TBottomSheetEvents;

export const bottomSheetEvents: TBottomSheetEvents = {
  collapsed: 'in collapse state',
  dismissed: 'was dismissed',
  expanded: 'is expanded',
  snapToTop: 'snapped to top',
};

export const useReduceMotion = () => {
  const [matches, setMatch] = React.useState(window.matchMedia('(prefers-reduced-motion: reduce)').matches);
  React.useEffect(() => {
    const mq = window.matchMedia('(prefers-reduced-motion: reduce)');
    const handleChange = () => {
      setMatch(mq.matches);
    };
    handleChange();
    mq.addEventListener('change', handleChange);
    return () => {
      mq.removeEventListener('change', handleChange);
    };
  }, []);
  return matches;
};
