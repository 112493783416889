import { Box, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { Badge, FlagIconComponent as FlagIcon, getColor, CertBadge } from '@stationwise/component-module';
import { AllowedColors, RosterEmployee } from '@stationwise/share-types';

interface Prop {
  employee: RosterEmployee;
  start: Date;
  end: Date;
}
export const ShiftTradeHeader = ({ employee, start, end }: Prop) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderRadius: 2,
      }}
    >
      <Box
        sx={(theme) => ({
          borderBottom: `solid 1px ${theme.palette.stationGray[100]}`,
        })}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex">
            <Badge
              label={employee.rank.code}
              isRound
              type="sm"
              isCustom
              customColor={getColor(theme, (employee.rank.color as AllowedColors) || '').color}
              customBackgroundColor={getColor(theme, (employee.rank.color as AllowedColors) || '').background}
              variant="bodySMedium"
            />
            <Typography
              variant="bodyMMedium"
              sx={{
                color: theme.palette.stationGray[900],
                ml: theme.spacing(1),
                pt: '3px',
              }}
            >
              {employee.name}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={0.5}
            sx={(theme) => ({
              minHeight: theme.spacing(2.5),
              flexFlow: 'row wrap',
            })}
          >
            {employee.certifications.map((cert, index) => (
              <CertBadge key={`${index}-${cert.code}`} cert={cert} />
            ))}
          </Box>
        </Box>
        <Box display="flex" sx={{ py: theme.spacing(1) }}>
          <FlagIcon height={theme.spacing(3)} width={theme.spacing(3)} color={employee.team?.color} showBorder />
          <Typography
            variant="bodySMedium"
            sx={{
              color: theme.palette.stationGray[500],
              ml: theme.spacing(1),
              pt: '3px',
            }}
          >
            {employee.team?.name}
          </Typography>
        </Box>
      </Box>

      <Box sx={(theme) => ({ mt: theme.spacing(1.5), typography: 'bodyMRegular', display: 'flex' })}>
        <Box
          sx={(theme) => ({
            marginRight: theme.spacing(2),
          })}
        >
          {format(start, 'M/d/yy')}
        </Box>
        <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
          {format(start, 'HHmm')}
          {end > start && ' - '}
          {end > start && format(end, 'HHmm')}
        </Box>
      </Box>
    </Box>
  );
};
