import { Box, Typography, useTheme } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { Chip, STATUS_TITLES } from '@stationwise/component-module';
import { OverviewCardProps } from '.';

export const Header = ({ overview, eventColor }: OverviewCardProps) => {
  const theme = useTheme();
  return (
    <>
      <Box sx={{ pl: theme.spacing(2) }}>
        <Chip
          label={overview.title}
          chipSxProps={() => ({
            background: eventColor || '',
            height: '24px',
            width: '105%',
            borderRadius: '7px',
          })}
          variant="buttonM"
          typographyStyles={{
            color:
              overview.status !== STATUS_TITLES.ADMIN_DENIED && overview.status !== STATUS_TITLES.ADMIN_APPROVED
                ? theme.palette.stationTeal[700]
                : theme.palette.common.white,
          }}
          hideArrowShapedBorder
        />
      </Box>
      <Box display="flex" sx={{ pt: theme.spacing(2), pl: theme.spacing(2) }}>
        <Box>
          <Typography variant="bodyXLRegular">{format(parseISO(overview.startDate), 'M/d/yy')}</Typography>
        </Box>
        <Box sx={{ pl: theme.spacing(2) }}>
          <Typography variant="bodyXLRegular" sx={{ color: theme.palette.stationGray[400] }}>
            {format(`${overview.startDate}T${overview.startTime}`, 'HHmm')}
            {' - '}
            {format(`${overview.endDate}T${overview.endTime}`, 'HHmm')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
