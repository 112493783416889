import { Box } from '@mui/material';
import { Button, theme } from '@stationwise/component-module';

interface SaveButtonContainerProps {
  handlePressCancel: () => void;
  handlePressSave: () => void;
}

export const SaveButtonContainer = ({ handlePressCancel, handlePressSave }: SaveButtonContainerProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        justifyContent: 'center',
        gap: theme.spacing(2),
        paddingY: theme.spacing(1.5),
        backgroundColor: theme.palette.common.white,
      }}
    >
      <Button buttonType="tertiary" onClick={handlePressCancel}>
        Cancel
      </Button>
      <Button
        buttonType="dark"
        sx={() => ({ color: theme.palette.common.white, ':hover': { backgroundColor: theme.palette.stationGray[700] } })}
        onClick={handlePressSave}
      >
        Save Changes
      </Button>
    </Box>
  );
};
