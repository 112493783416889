import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import { useState } from 'react';
import { ChevronDownIcon20, TimePicker } from '@stationwise/component-module';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';
import { ADDITIONAL_PAID_TIME_OPTIONS } from './AdditionalPaidTimeContent';

interface AdditionalPaidTimeSelectProps {
  handleTime: (time: Date) => void;
  label?: string;
  manualTime: Date;
  minTime?: Date;
  maxTime?: Date;
  shift: EventInput | EventImpl;
  usePreciseMinutes?: boolean;
  selectedButton?: string;
}

export const AdditionalPaidTimeSelect = ({
  handleTime,
  label = 'Additional paid time start',
  manualTime,
  minTime,
  maxTime,
  shift,
  usePreciseMinutes = false,
  selectedButton,
}: AdditionalPaidTimeSelectProps) => {
  const [openTimeSelect, setOpenTimeSelect] = useState(false);
  const iosWebViewPaddingBottom = useIOSPaddingBottom();

  const handleDateSelect = (value: Date) => {
    handleTime(value);
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          p: theme.spacing(2),
          mt: theme.spacing(2),
          typography: 'bodyLMedium',
        })}
      >
        {label}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          px: theme.spacing(2),
          width: '100%',
        })}
      >
        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              sx={{
                '& .MuiInputBase-input': {
                  typography: 'bodySRegular',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  boxShadow: 'none',
                },
              }}
              value={manualTime}
              shouldDisableDate={(day) => {
                if (!shift || !shift.start || !shift.end) return false;
                if (minTime && isSameDay(day, minTime)) return false;
                if (minTime && isBefore(day, minTime)) return true;
                if (maxTime && isAfter(day, maxTime)) return true;
                if (isSameDay(day, shift.start as string)) return false;
                return isBefore(day, shift.start as string) || isAfter(day, shift.end as string);
              }}
              onChange={(value) => value && handleDateSelect(value)}
            />
          </LocalizationProvider>
        </Box>
        <Box
          data-cy={`${makeTestIdentifier(label)}-select`}
          sx={(theme) => ({
            border: `1px solid ${theme.palette.stationGray[200]}`,
            borderRadius: theme.spacing(1),
            p: '10px 13px 10px',
            width: '50%',
            cursor: 'pointer',
            marginLeft: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            typography: 'bodySRegular',
            '& svg': {
              color: theme.palette.stationGray[400],
            },
          })}
          onClick={() => setOpenTimeSelect(true)}
        >
          <Box sx={(theme) => ({ mr: theme.spacing(2) })}>{format(manualTime.toString(), 'HHmm')}</Box>
          <ChevronDownIcon20 />
        </Box>
      </Box>

      <TimePicker
        openTimeSelect={openTimeSelect}
        setOpenTimeSelect={setOpenTimeSelect}
        handleTime={(value: Date) => {
          setOpenTimeSelect(false);
          handleTime(value);
        }}
        paddingBottom={iosWebViewPaddingBottom}
        timeSteps={usePreciseMinutes ? { hours: 1, minutes: 1 } : undefined}
        defaultValue={manualTime}
        minTime={minTime && isSameDay(manualTime, minTime) ? minTime : undefined}
        maxTime={
          selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY
            ? undefined
            : maxTime && isSameDay(manualTime, maxTime)
              ? maxTime
              : undefined
        }
      />
    </>
  );
};
