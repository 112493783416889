import { Box } from '@mui/material';
import { useState } from 'react';
import { Mail01Icon24, PhoneIcon24, useLoadedAuthUserContext, User01Icon24 } from '@stationwise/component-module';
import { prettifyPhoneNumber } from '@stationwise/share-utils';
import { DetailsItem } from './DetailsItem';
import { EditPhoneNumberDrawer } from './EditPhoneNumberDrawer';

export const Details = () => {
  const {
    state: { employee },
  } = useLoadedAuthUserContext();
  const [phoneNumber, setPhoneNumber] = useState(employee.phone);
  const [showEditPhone, setShowEditPhone] = useState(false);

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing(3),
        width: '100%',
      })}
    >
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          typography: 'bodyLSemibold',
          height: theme.spacing(1.5),
        })}
      >
        Details
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          px: theme.spacing(2),
          width: 'inherit',
          flexDirection: 'column',
          alignItems: 'flex-start',
          borderRadius: theme.spacing(1.5),
          background: theme.palette.common.white,
          boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
        })}
      >
        <DetailsItem icon={<User01Icon24 />} title={`${employee.firstName} ${employee.lastName}`} />
        <DetailsItem icon={<Mail01Icon24 />} title={employee.email} />
        <DetailsItem
          icon={<PhoneIcon24 />}
          title={phoneNumber ? prettifyPhoneNumber(phoneNumber) : <i>No phone number</i>}
          onClick={() => {
            setShowEditPhone(true);
          }}
        />
      </Box>

      {showEditPhone && (
        <EditPhoneNumberDrawer
          drawerOpen={showEditPhone}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          onClose={() => setShowEditPhone(false)}
        />
      )}
    </Box>
  );
};
