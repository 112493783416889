import { Box } from '@mui/material';
import { format } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { GenericDrawer, theme, WaitlistIcon } from '@stationwise/component-module';
import { Waitlist } from '@stationwise/share-types';

interface Props {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  waitlist: Waitlist | null;
}

export const TimeOffWaitlist = ({ isOpen, setOpen, waitlist }: Props) => {
  const toggleDrawer = (open: boolean) => () => {
    setOpen(open);
  };

  if (!isOpen) return;
  if (!waitlist) return;

  return (
    <GenericDrawer
      headerTitle="Time Off Waitlist"
      drawerOpen={isOpen}
      anchor="bottom"
      loading={false}
      showHeader
      handleOnClose={toggleDrawer(false)}
      sxProps={{
        width: '100%',
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          p: theme.spacing(2),
          pt: theme.spacing(0),
        })}
      >
        {waitlist && waitlist.data.length === 0 ? (
          <Box sx={(theme) => ({ p: theme.spacing(3), display: 'flex', flexDirection: 'column', alignItems: 'center' })}>
            <WaitlistIcon />
            <Box sx={(theme) => ({ my: theme.spacing(2), typography: 'bodyXXLSemibold' })}> The waitlist is empty </Box>
            <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodyMRegular' })}>
              When you complete your time off request, you will be added as a first person to the waitlist.
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={(theme) => ({ mb: theme.spacing(2), display: 'flex', justifyContent: 'space-between' })}>
              <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'bodySMedium' })}>EMPLOYEE</Box>
              <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'bodySMedium' })}>REQUESTED</Box>
            </Box>

            <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
              {waitlist.data.map((item, index) => (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex' }}>
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.stationGray[600],
                        height: '24px',
                        width: '24px',
                        borderRadius: 3,
                        color: theme.palette.common.white,
                      })}
                    >
                      {index + 1}
                    </Box>
                    <Box
                      sx={(theme) => ({
                        ml: theme.spacing(2),
                        typography: 'bodyLMedium',
                      })}
                    >
                      {item.employee.name}
                    </Box>
                  </Box>
                  <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodyMMedium' })}>
                    {format(item.createdAt, 'MM-dd HH:mm:ss')}
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
    </GenericDrawer>
  );
};
