import { Box } from '@mui/material';
import { EmployeeOffView } from '@stationwise/share-types';
import { SwappedEmployees } from './SwappedEmployees';

interface Props {
  employeeOffData: EmployeeOffView;
}

export const AbsenceCard = ({ employeeOffData }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.stationGray[50],
        borderRadius: '20px',
        border: `1px solid ${theme.palette.stationGray[200]}`,
        p: 1.5,
      })}
    >
      <SwappedEmployees employeeOffData={employeeOffData} />
    </Box>
  );
};
