import { useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { IFullOvertimeListView } from '@stationwise/share-types';

interface Props {
  shiftDate?: string;
  skip?: boolean;
  skipStaffed?: boolean;
  skipFloaters?: boolean;
  skipVoluntary?: boolean;
  ignoreVoluntaryList?: boolean;
  isForRoster?: boolean;
}

export const useFetchOvertimeList = ({
  shiftDate,
  skipStaffed,
  skipFloaters,
  skipVoluntary,
  ignoreVoluntaryList,
  skip = false,
  isForRoster = false,
}: Props) => {
  // this value is only to force refetch
  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<IFullOvertimeListView | null>(null);

  useEffect(() => {
    if (skip) {
      return;
    }
    if (shiftDate) {
      setIsLoading(true);
      setIsError(false);
      setError(null);
      setData(null);
      client
        .get('/shift/overtimes/', {
          params: {
            shiftDate: shiftDate,
            skipStaffed: skipStaffed !== undefined ? skipStaffed : true,
            skipFloaters: skipFloaters !== undefined ? skipFloaters : true,
            skipVoluntary: skipVoluntary !== undefined ? skipVoluntary : true,
            ignoreVoluntary: ignoreVoluntaryList || undefined,
            isForRoster: isForRoster || undefined,
          },
        })
        .then((data) => {
          if (data.status === 200) {
            setData(data.data);
          } else {
            setIsError(true);
            setError(data.statusText);
          }
        })
        .catch((error) => {
          setIsError(true);
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftDate, fetchNoRequest, skip]);

  const forceRefetch = () => {
    setFetchNoRequest((prevValue) => prevValue + 1);
  };
  return {
    isLoading,
    isError,
    error,
    data,
    forceRefetch,
  };
};
