import { Dispatch } from 'react';
import { LoggedInEmployeeDataView } from '@stationwise/share-types';
import { client } from '../axios';
import { loadIntercomForLoggedInUser } from './intercom';

export const getMe = async () => {
  let me: LoggedInEmployeeDataView | null = null;

  try {
    const response = await client.get('/auth/me/');
    me = response.data || null;
  } catch {
    me = null;
  }

  if (me && !me.isApparatusLogin && me.mobileIntercomSupportEnabled) {
    loadIntercomForLoggedInUser(me.firstName, me.id, me.email, me.phone);
  }

  return me;
};

export const refreshUserData = async (
  dispatch: Dispatch<{
    type: 'SET_USERNAME.SUCCESS';
    payload: LoggedInEmployeeDataView;
  }>,
) => {
  const me = await getMe();
  if (me) {
    dispatch({ type: 'SET_USERNAME.SUCCESS', payload: me });
  }
  return me;
};
