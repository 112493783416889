import { useSearchParams } from 'react-router-dom';

/**
 * A custom hook to get a safe 'next' URL query parameter.
 * Ensures the 'next' parameter is a relative path starting with '/'.
 * If not valid, defaults to the root '/' path.
 *
 * @returns {string} A safe 'next' URL parameter.
 */

export function useNextURLQueryParam() {
  const [searchParams] = useSearchParams();
  const next = searchParams.get('next');

  if (next && next.startsWith('/') && !next.startsWith('//')) {
    return next;
  }

  return null;
}
