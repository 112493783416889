import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { DateChanger, UsersXIcon20, useDateQueryParam, StationsSelect } from '@stationwise/component-module';
import { RosterEmployee, RosterStation, RosterApparatus } from '@stationwise/share-types';
import { EmployeesOffDrawer } from '../EmployeesOffDrawer';
import { ShiftDetails } from './ShiftDetails';

interface ShiftEmployeeDetails {
  employee: RosterEmployee;
  station?: RosterStation;
  apparatus?: RosterApparatus;
}

interface Props {
  shiftDateRange: string;
  shiftLeaderDetails: ShiftEmployeeDetails | null;
  currentEmployeeDetails: ShiftEmployeeDetails | null;
}
const CustomBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      display="flex"
      width="100%"
      sx={(theme) => ({
        flexDirection: 'row',
        justifyContent: { xs: 'space-between', lg: 'start' },
        alignItems: 'center',
        background: theme.palette.common.white,
        borderBottom: {
          xs: `1px dashed ${theme.palette.stationGray[100]}`,
          md: 'none',
        },
        padding: '8px 0px 8px 0px',
      })}
    >
      {children}
    </Box>
  );
};

export const TopBar = ({ shiftDateRange, shiftLeaderDetails, currentEmployeeDetails }: Props) => {
  const [isEmployeesOffDrawerOpen, setIsEmployeesOffDrawerOpen] = useState(false);

  const { addDays, selectedDateAsDate } = useDateQueryParam();
  return (
    <>
      <CustomBox>
        <Box
          sx={{
            alignSelf: 'center',
            display: 'flex',
          }}
        >
          <StationsSelect />
        </Box>
        <IconButton
          color="inherit"
          aria-label="open off roster drawer"
          onClick={() => setIsEmployeesOffDrawerOpen((prev) => !prev)}
          sx={{ width: '40px' }}
        >
          <UsersXIcon20 />
        </IconButton>
      </CustomBox>
      <CustomBox>
        <Box
          sx={(theme) => ({
            alignItems: 'center',
            display: 'flex',
            gap: '6px',
            paddingLeft: theme.spacing(2),
          })}
        >
          <Box display="flex" alignItems="center">
            <DateChanger currentDate={selectedDateAsDate || new Date()} addDays={addDays} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', pr: 1 }}>
          {/*
          // TODO: hide search icon until search is implemented
          <Box sx={{ mt: '11px', mr: '15px' }}>
            <SearchIcon />
          </Box> */}
        </Box>
      </CustomBox>
      <EmployeesOffDrawer open={isEmployeesOffDrawerOpen} onClose={() => setIsEmployeesOffDrawerOpen(false)} />
      <Box>
        {shiftLeaderDetails && shiftLeaderDetails.employee.id !== currentEmployeeDetails?.employee.id && (
          <ShiftDetails shiftDateRange={shiftDateRange} {...shiftLeaderDetails} />
        )}
        {currentEmployeeDetails && <ShiftDetails shiftDateRange={shiftDateRange} {...currentEmployeeDetails} />}
      </Box>
    </>
  );
};
