import { object, string, TypeOf, number } from 'zod';

// Create Apparatus DTO
export const createAdditionalPaidTimeSchema = object({
  note: string({ required_error: 'Note is required' }),
  start_time: number({ required_error: 'Start date is required' }),
  duration: number({ required_error: 'Duration is required' }),
  additional_paid_time_date: string({
    required_error: 'Shift date is required',
  }),
  additional_paid_time_type: string({ required_error: 'Additional Paid Time Type Is Required' }),
  pay_code: string().nullable(),
});

export type CreateAdditionalPaidTimeDTO = TypeOf<typeof createAdditionalPaidTimeSchema>;
