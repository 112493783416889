import { useSelectConversationRecipients } from '../../../hooks';
import { SelectContactOptionProps } from './Options';
import { RecipientsCard } from './RecipientsCard';
import { ISelectedRecipients } from './types';

interface RecipientsProps {
  data: {
    [key in string]: SelectContactOptionProps[];
  };
  onAddRecipients?: (selectedRecipients: ISelectedRecipients, close?: boolean) => void;
  onClose?: () => void;
}

export const Recipients = ({ data, onAddRecipients, onClose }: RecipientsProps) => {
  const {
    amountSelected,
    handleEditRecipients,
    handleAllSelect,
    handleClearOptions,
    handleChangeSearch,
    recipients,
    filters,
    searchValue,
    isAllSelected,
    selectedFilter,
  } = useSelectConversationRecipients({ data, onAddRecipients });

  return (
    <RecipientsCard
      onAllSelect={handleAllSelect}
      onClear={handleClearOptions}
      filters={filters}
      setSearchValue={handleChangeSearch}
      searchValue={searchValue}
      options={recipients}
      isAllSelected={isAllSelected}
      onAddRecipients={handleEditRecipients}
      amountSelected={amountSelected}
      selectedFilter={selectedFilter}
      onClose={onClose}
    />
  );
};
