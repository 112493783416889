import { Box, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { Badge, FlagIconComponent as FlagIcon, getColor } from '@stationwise/component-module';
import { AllowedColors, ShiftTrade } from '@stationwise/share-types';

export const ShiftTradeCard = ({ shiftTrade, isNextShiftTrade }: { shiftTrade: ShiftTrade; isNextShiftTrade?: boolean }) => {
  const theme = useTheme();

  return (
    <Box
      sx={(theme) => ({
        border: `solid 1px ${theme.palette.stationGray[100]}`,
        boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
        borderRadius: '8px',
        p: theme.spacing(1),
      })}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex">
          {shiftTrade.numberOfPeople && shiftTrade.numberOfPeople > 1 ? (
            <Typography variant="bodyMMedium" sx={{ color: theme.palette.stationGray[900], ml: theme.spacing(1), pt: '3px' }}>
              Sent to {shiftTrade.numberOfPeople} People
            </Typography>
          ) : (
            <>
              <Badge
                label={shiftTrade.employeeRank.code}
                isRound
                type="sm"
                isCustom
                customColor={getColor(theme, (shiftTrade.employeeRank.color as AllowedColors) || '').color}
                customBackgroundColor={getColor(theme, (shiftTrade.employeeRank.color as AllowedColors) || '').background}
                variant="bodySMedium"
              />
              <Typography variant="bodyMMedium" sx={{ color: theme.palette.stationGray[900], ml: theme.spacing(1), pt: '3px' }}>
                {shiftTrade.employeeName}
              </Typography>
            </>
          )}
        </Box>
        {isNextShiftTrade && shiftTrade.startDate && shiftTrade.endDate && (
          <Typography sx={{ color: theme.palette.stationGray[500], pt: '3px' }} variant="bodyMMedium">
            {format(`${shiftTrade.startDate}T${shiftTrade.startTime}`, 'MMM d, HHmm')}
            {'-'}
            {format(`${shiftTrade.endDate}T${shiftTrade.endTime}`, 'HHmm')}
          </Typography>
        )}
        {!isNextShiftTrade && (
          <Typography
            sx={{
              color: shiftTrade.owesMe ? theme.palette.stationGreen[500] : theme.palette.stationYellow[500],
              pt: '3px',
            }}
            variant="bodyMMedium"
          >
            {`${shiftTrade.owesMe ? 'Owes me' : 'I owe'} ${shiftTrade.owedHours?.toFixed(2)} h`}
          </Typography>
        )}
      </Box>
      <Box display="flex" sx={{ pt: theme.spacing(1) }}>
        <FlagIcon height={theme.spacing(3)} width={theme.spacing(3)} color={shiftTrade.shiftColor} showBorder />
        <Typography variant="bodySMedium" sx={{ color: theme.palette.stationGray[500], ml: theme.spacing(1), pt: '3px' }}>
          {shiftTrade.shiftName}
        </Typography>
      </Box>
    </Box>
  );
};
