import { ChevronLeft } from '@mui/icons-material';
import { Box } from '@mui/material';

interface ChangePasswordTopBarProps {
  setShowChangePasswordPage: (value: boolean) => void;
}

export const ChangePasswordTopBar = ({ setShowChangePasswordPage }: ChangePasswordTopBarProps) => {
  return (
    <Box
      display="flex"
      sx={(theme) => ({
        gap: theme.spacing(2),
        height: theme.spacing(8),
        display: 'flex',
        alignItems: 'center',
      })}
    >
      <Box
        sx={(theme) => ({ width: theme.spacing(3), height: theme.spacing(3) })}
        onClick={() => {
          setShowChangePasswordPage(false);
        }}
      >
        <ChevronLeft />
      </Box>
      <Box typography="bodyLSemibold">Change/Reset password</Box>
    </Box>
  );
};
