import { Box, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { Button } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';

interface PayrollValidationModalFooterProps {
  departmentName: string;
  userName: string;
  isButtonActive: boolean;
  setOpenPayrollValidationWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  payPeriodId: number | undefined;
  setOpenPayrollValidationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRefetchCounter: React.Dispatch<React.SetStateAction<number>>;
  refetchCounter: number;
}

export const PayrollValidationModalFooter = ({
  departmentName,
  userName,
  isButtonActive,
  setOpenPayrollValidationWarningModal,
  payPeriodId,
  setOpenPayrollValidationModal,
  setRefetchCounter,
  refetchCounter,
}: PayrollValidationModalFooterProps) => {
  const handlePressSignAndSubmit = async () => {
    try {
      await client.post('/payroll/personal/validate-payroll/', {
        payPeriodId: payPeriodId,
      });
      setOpenPayrollValidationModal(false);
      setRefetchCounter(refetchCounter + 1);
    } catch (error) {
      captureException(error);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        bottom: '0',
        width: '100%',

        border: '1px solid #FFFFFF',
        padding: '16px',
        gap: '16px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px -4px 6px -2px rgba(10, 14, 22, 0.05), 0px -10px 15px -3px rgba(10, 14, 22, 0.1)',
        borderRadius: '12px 12px 0 0',
      }}
    >
      <Button
        data-cy="sign-time-card"
        buttonType="tertiary"
        buttonSize="lg"
        variant="contained"
        sx={(theme) => ({
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          width: '100%',
          textTransform: 'none',
          borderRadius: '6px',
        })}
        onClick={handlePressSignAndSubmit}
        disabled={!isButtonActive}
      >
        Sign and submit
      </Button>

      <Button
        data-cy="sign-time-card"
        buttonType="tertiary"
        buttonSize="lg"
        variant="contained"
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          width: '100%',
          textTransform: 'none',
          borderRadius: '6px',
        })}
        onClick={() => setOpenPayrollValidationWarningModal(true)}
      >
        Sign later
      </Button>

      <Typography variant="bodyXSRegular" sx={(theme) => ({ color: theme.palette.stationGray[600] })}>
        By pressing 'Sign and submit', I agree that this electronic signature represents me, {userName}. I agree that the contents
        of this time card are in agreement with all {departmentName} rules and policies.
      </Typography>
    </Box>
  );
};
