import { CssBaseline, StyledEngineProvider, ThemeProvider as MUIThemeProvider } from '@mui/material';
import React from 'react';
import { theme } from './themeConfig';

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};
