import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fab } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { useTheme } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';

export const ScrollTopButton: React.FC = () => {
  const theme = useTheme();
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 30) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);
  return (
    <Zoom in={visible}>
      <Fab
        color="inherit"
        size="medium"
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: theme.spacing(10),
          right: theme.spacing(2),
          bgcolor: 'background.paper',
          '&:hover': {
            bgcolor: 'background.paper',
          },
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        }}
        aria-label="scroll back to top"
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </Zoom>
  );
};
