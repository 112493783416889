import { useState } from 'react';
import { RosterStrikeTeamApparatus } from '@stationwise/share-types';

export const useSelectedStrikeTeamApparatus = () => {
  const [selectedApparatus, setSelectedApparatus] = useState<RosterStrikeTeamApparatus | null>(null);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);

  const resetState = () => {
    setSelectedApparatus(null);
    setSelectedAction(null);
  };

  return {
    selectedApparatus,
    setSelectedApparatus,
    setSelectedAction,
    selectedAction,
    resetState,
  };
};
