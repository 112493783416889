import { CertificationRequirement, RosterApparatus, RosterDataSource, RosterStation } from '@stationwise/share-types';
import { copyBoardWithNewApparatus, IShiftSummaryHelper } from '@stationwise/shift-summary-helper';
import { sendApparatusEmployeesToFloaterStation } from './assignments';
import { ManagePositionsPayload } from './positions';
import { buildAddress, ManageStationsPayload } from './stations';

export interface ApparatusMode {
  apparatusId: number;
  isReserved: boolean;
}

export interface ApparatusPayload {
  name: string;
  certificationRequirements: CertificationRequirement[];
  isForShiftLeader: boolean;
  movedApparatusId?: string;
  positionsPayload?: ManagePositionsPayload;
}

export interface ManageApparatusesPayload {
  create: ApparatusPayload[];
  update: { id: string; apparatus: ApparatusPayload }[];
  delete: string[];
}

export const toggleApparatusMode = (shiftTemplateHelper: IShiftSummaryHelper, apparatus: RosterApparatus) => {
  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(shiftTemplateHelper, apparatus.id);
  newApparatus.isReserved = !newApparatus.isReserved;

  return { ...shiftTemplateHelper, allStationCards: newAllStationCards };
};

export const moveApparatusToStation = (
  shiftTemplateHelper: IShiftSummaryHelper,
  apparatusToMove: RosterApparatus,
  destinationStationId: string,
) => {
  const newAllStationCards = new Map(shiftTemplateHelper.allStationCards);

  for (const [stationId, station] of newAllStationCards.entries()) {
    let newStation = { ...station };

    if (station.apparatuses.find((app) => app.id === apparatusToMove.id || app.id === apparatusToMove.name)) {
      newStation = {
        ...station,
        apparatuses: station.apparatuses.filter((app) => app.id !== apparatusToMove.id && app.id !== apparatusToMove.name),
      };
    }
    if (station.stationId === destinationStationId || destinationStationId === station.stationName) {
      newStation = { ...station, apparatuses: [...station.apparatuses, apparatusToMove] };
    }
    newAllStationCards.set(stationId, newStation);
  }
  const updatedShiftTemplateHelper = { ...shiftTemplateHelper, allStationCards: newAllStationCards };

  return { updatedShiftTemplateHelper };
};

export const buildApparatus = (apparatusPayload: ApparatusPayload) => {
  const newApparatus: RosterApparatus = {
    id: apparatusPayload.name,
    dataSource: RosterDataSource.STATION,
    positions: [],
    name: apparatusPayload.name,
    certificationRequirements: apparatusPayload.certificationRequirements,
    isForShiftLeader: apparatusPayload.isForShiftLeader,
    isNewlyCreated: true,
  };

  return newApparatus;
};

export const createApparatus = (
  shiftTemplateHelper: IShiftSummaryHelper,
  apparatus: RosterApparatus,
  apparatusStation: RosterStation,
) => {
  const newAllStationCards = new Map();

  for (const [stationId, station] of shiftTemplateHelper.allStationCards.entries()) {
    const copyStation = { ...station };

    if (stationId === apparatusStation.stationId) {
      copyStation.apparatuses = [...station.apparatuses, { ...apparatus, id: apparatus.name }];
    }

    newAllStationCards.set(stationId, copyStation);
  }
  return { ...shiftTemplateHelper, allStationCards: newAllStationCards };
};

export const updateApparatus = (
  shiftTemplateHelper: IShiftSummaryHelper,
  apparatus: RosterApparatus,
  apparatusStation: RosterStation,
) => {
  const newAllStationCards = new Map();

  for (const [stationId, station] of shiftTemplateHelper.allStationCards.entries()) {
    const copyStation = { ...station };
    if (stationId === apparatusStation.stationId) {
      const apparatusIndex = station.apparatuses.findIndex((app) => app.id === apparatus.id || apparatus.id === app.name);
      if (apparatusIndex >= 0) {
        copyStation.apparatuses[apparatusIndex] = {
          ...copyStation.apparatuses[apparatusIndex],
          name: apparatus.name,
          isForShiftLeader: apparatus.isForShiftLeader,
          certificationRequirements: apparatus.certificationRequirements,
        };
      }
    }
    newAllStationCards.set(stationId, copyStation);
  }
  return { ...shiftTemplateHelper, allStationCards: newAllStationCards };
};

export const deleteApparatus = (
  shiftTemplateHelper: IShiftSummaryHelper,
  apparatus: RosterApparatus,
  apparatusStation: RosterStation,
) => {
  const newAllStationCards = new Map();
  let newShiftTemplateHelper = { ...shiftTemplateHelper };

  for (const [stationId, station] of shiftTemplateHelper.allStationCards.entries()) {
    const copyStation = { ...station };
    if (stationId === apparatusStation.stationId) {
      const apparatusIndex = station.apparatuses.findIndex((app) => app.id === apparatus.id || apparatus.id === app.name);
      if (apparatusIndex >= 0) {
        const apparatusToRemove = { ...copyStation.apparatuses[apparatusIndex] };
        copyStation.apparatuses = copyStation.apparatuses.filter(
          (app) => app.id !== apparatusToRemove.id && app.name !== apparatusToRemove.name,
        );
        newShiftTemplateHelper = sendApparatusEmployeesToFloaterStation(
          newShiftTemplateHelper,
          apparatusToRemove,
        ).updatedShiftTemplateHelper;
      }
    }
    newAllStationCards.set(stationId, copyStation);
  }

  return { ...newShiftTemplateHelper, allStationCards: newAllStationCards };
};

export const addRemoveApparatusToStationsPayload = (
  manageStationsPayload: ManageStationsPayload,
  apparatusStation: RosterStation,
  apparatusToRemove: RosterApparatus,
) => {
  const newManageStationsPayload = { ...manageStationsPayload };
  // check if the apparatus we want to remove belongs to a new station (not saved in the db yet)
  const newStationIndex = newManageStationsPayload.create.findIndex(
    (stationToCreate) => stationToCreate.name === apparatusStation.stationName,
  );
  // removing an apparatus from a new station
  if (newStationIndex >= 0) {
    let apparatusesPayload = newManageStationsPayload.create[newStationIndex].apparatusesPayload || {
      create: [],
      update: [],
      delete: [],
    };
    apparatusesPayload = addRemoveToApparatusesPayload(apparatusesPayload, apparatusToRemove);

    newManageStationsPayload.create[newStationIndex] = {
      ...newManageStationsPayload.create[newStationIndex],
      apparatusesPayload: apparatusesPayload,
    };
  }
  // removing an apparatus from an existing station that is in the payload already
  else {
    const stationToUpdateIndex = newManageStationsPayload.update.findIndex(
      (stationToUpdate) => stationToUpdate.id === apparatusStation.stationId,
    );
    if (stationToUpdateIndex >= 0) {
      let apparatusesPayload = newManageStationsPayload.update[stationToUpdateIndex].station.apparatusesPayload || {
        create: [],
        update: [],
        delete: [],
      };
      apparatusesPayload = addRemoveToApparatusesPayload(apparatusesPayload, apparatusToRemove);
      const stationPayload = newManageStationsPayload.update[stationToUpdateIndex].station;

      newManageStationsPayload.update[stationToUpdateIndex] = {
        ...newManageStationsPayload.update[stationToUpdateIndex],
        station: {
          ...stationPayload,
          apparatusesPayload: apparatusesPayload,
        },
      };
    }
    // removing an apparatus from an existing station that is not in the payload yet
    else {
      newManageStationsPayload.update = [
        ...newManageStationsPayload.update,
        {
          id: apparatusStation.stationId,
          station: {
            name: apparatusStation.stationName,
            address: apparatusStation.address || buildAddress(),
            certificationRequirements: apparatusStation.certificationRequirements,
            apparatusesPayload: { create: [], delete: [apparatusToRemove.id], update: [] },
          },
        },
      ];
    }
  }
  return newManageStationsPayload;
};

const addRemoveToApparatusesPayload = (apparatusesPayload: ManageApparatusesPayload, apparatusToRemove: RosterApparatus) => {
  let newCreates = [...apparatusesPayload.create];
  let newDeletes = [...apparatusesPayload.delete];
  let newUpdates = [...apparatusesPayload.update];

  // avoid sending a create for an apparatus that is being removed
  const isNewlyCreated = newCreates.some((payload) => payload.name === apparatusToRemove.name);

  // removing a new apparatus: we need to remove payload from creates
  if (isNewlyCreated) {
    newCreates = newCreates.filter((payload) => payload.name !== apparatusToRemove.name);
  } else {
    newDeletes = [...newDeletes, apparatusToRemove.id];
  }
  // avoid sending updates for an apparatus that is being removed
  newUpdates = newUpdates.filter((payload) => payload.id !== apparatusToRemove.id);

  return { ...apparatusesPayload, delete: newDeletes, create: newCreates, update: newUpdates };
};

export const addUpdateApparatusToStationsPayload = (
  manageStationsPayload: ManageStationsPayload,
  apparatusStation: RosterStation,
  apparatusToUpdate: RosterApparatus,
) => {
  const apparatusPayload: ApparatusPayload = {
    name: apparatusToUpdate.name,
    certificationRequirements: apparatusToUpdate.certificationRequirements,
    isForShiftLeader: apparatusToUpdate.isForShiftLeader,
  };

  const newManageStationsPayload = { ...manageStationsPayload };
  // check if the apparatus we want to update belongs to a new station (not saved in the db yet)
  const newStationIndex = newManageStationsPayload.create.findIndex(
    (stationToCreate) => stationToCreate.name === apparatusStation.stationName,
  );

  // updating an apparatus from a new station
  if (newStationIndex >= 0) {
    let apparatusesPayload = newManageStationsPayload.create[newStationIndex].apparatusesPayload;
    if (!apparatusesPayload) {
      apparatusesPayload = { create: [], update: [{ id: apparatusToUpdate.id, apparatus: apparatusPayload }], delete: [] };
    } else {
      apparatusesPayload = addUpdateToApparatusesPayload(apparatusesPayload, apparatusToUpdate.id, apparatusPayload);
    }
    newManageStationsPayload.create[newStationIndex] = {
      ...newManageStationsPayload.create[newStationIndex],
      apparatusesPayload: apparatusesPayload,
    };
  }
  // updating an apparatus from an existing station that is already in payload
  else {
    const stationToUpdateIndex = newManageStationsPayload.update.findIndex(
      (stationToUpdate) => stationToUpdate.id === apparatusStation.stationId,
    );
    if (stationToUpdateIndex >= 0) {
      const stationPayload = newManageStationsPayload.update[stationToUpdateIndex].station;
      let apparatusesPayload = stationPayload.apparatusesPayload || { create: [], update: [], delete: [] };
      apparatusesPayload = addUpdateToApparatusesPayload(apparatusesPayload, apparatusToUpdate.id, apparatusPayload);
      newManageStationsPayload.update[stationToUpdateIndex] = {
        ...newManageStationsPayload.update[stationToUpdateIndex],
        station: { ...stationPayload, apparatusesPayload: apparatusesPayload },
      };
    }
    // updating an apparatus from an existing station that is not in the payload yet
    else {
      newManageStationsPayload.update = [
        ...manageStationsPayload.update,
        {
          id: apparatusStation.stationId,
          station: {
            name: apparatusStation.stationName,
            address: apparatusStation.address || buildAddress(),
            certificationRequirements: apparatusStation.certificationRequirements,
            apparatusesPayload: { update: [{ id: apparatusToUpdate.id, apparatus: apparatusPayload }], create: [], delete: [] },
          },
        },
      ];
    }
  }
  return newManageStationsPayload;
};

export const addUpdateToApparatusesPayload = (
  apparatusesPayload: ManageApparatusesPayload,
  apparatusId: string,
  apparatusPayload: ApparatusPayload,
) => {
  // for new apparatuses that are not saved yet in the db we store the original name into the id field to track apparatus in case we edit the name before saving it
  const existingNewApparatusIndex = apparatusesPayload.create.findIndex(
    (payload) => payload.name === apparatusPayload.name || payload.name === apparatusId,
  );
  const existingUpdateApparatusIndex = apparatusesPayload.update.findIndex((payload) => payload.id === apparatusId);

  // if the user is editing an apparatus that has not been saved in the db yet we send the updated payload with the create array
  if (existingNewApparatusIndex >= 0) {
    const newCreates = [...apparatusesPayload.create];
    newCreates[existingNewApparatusIndex] = apparatusPayload;
    apparatusesPayload.create = newCreates;
  }
  // the apparatus is already in the payload to be updated, we add the new updates
  else if (existingUpdateApparatusIndex >= 0) {
    const newUpdates = [...apparatusesPayload.update];
    newUpdates[existingUpdateApparatusIndex].apparatus = {
      ...newUpdates[existingUpdateApparatusIndex].apparatus,
      ...apparatusPayload,
    };
    apparatusesPayload.update = newUpdates;
  }
  // add the update to the payload
  else {
    apparatusesPayload.update = [...apparatusesPayload.update, { id: apparatusId, apparatus: apparatusPayload }];
  }
  return apparatusesPayload;
};

export const addCreateApparatusToStationsPayload = (
  manageStationsPayload: ManageStationsPayload,
  apparatusStation: RosterStation,
  apparatusToCreate: RosterApparatus,
  positionsPayloadToMovedApparatus?: ManagePositionsPayload,
  apparatusWasMoved?: boolean,
) => {
  const apparatusPayload: ApparatusPayload = {
    name: apparatusToCreate.name,
    certificationRequirements: apparatusToCreate.certificationRequirements,
    isForShiftLeader: apparatusToCreate.isForShiftLeader,
    movedApparatusId: apparatusWasMoved ? apparatusToCreate.id : undefined,
    positionsPayload: positionsPayloadToMovedApparatus || { create: [], update: [], delete: [] },
  };

  const newManageStationsPayload = { ...manageStationsPayload };

  const newStationIndex = newManageStationsPayload.create.findIndex(
    (stationToCreate) => stationToCreate.name === apparatusStation.stationName,
  );
  const stationToUpdateIndex = newManageStationsPayload.update.findIndex(
    (stationToUpdate) => stationToUpdate.id === apparatusStation.stationId,
  );
  // the apparatus is being added to a new station
  if (newStationIndex >= 0) {
    const apparatusesPayload = newManageStationsPayload.create[newStationIndex].apparatusesPayload || {
      create: [],
      update: [],
      delete: [],
    };
    newManageStationsPayload.create[newStationIndex] = {
      ...newManageStationsPayload.create[newStationIndex],
      apparatusesPayload: { ...apparatusesPayload, create: [...apparatusesPayload.create, apparatusPayload] },
    };
  }
  // the new apparatus is from an existing station that is already in the payload to be updated
  // we add the new apparatus to the changes
  else if (stationToUpdateIndex >= 0) {
    const stationPayload = newManageStationsPayload.update[stationToUpdateIndex].station;
    const apparatusesPayload = stationPayload.apparatusesPayload || { create: [], update: [], delete: [] };
    newManageStationsPayload.update[stationToUpdateIndex] = {
      ...newManageStationsPayload.update[stationToUpdateIndex],
      station: {
        ...stationPayload,
        apparatusesPayload: { ...apparatusesPayload, create: [...apparatusesPayload.create, apparatusPayload] },
      },
    };
  }
  // the new apparatus is from an existing station that is not in the payload yet
  // we need to add a station update object with the new apparatus
  else {
    newManageStationsPayload.update = [
      ...newManageStationsPayload.update,
      {
        id: apparatusStation.stationId,
        station: {
          name: apparatusStation.stationName,
          address: apparatusStation.address || buildAddress(),
          certificationRequirements: apparatusStation.certificationRequirements,
          apparatusesPayload: { create: [apparatusPayload], update: [], delete: [] },
        },
      },
    ];
  }
  return newManageStationsPayload;
};
