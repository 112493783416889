import { Box } from '@mui/material';
import { format } from 'date-fns';
import { RosterEmployee } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { getShiftColor } from '../ShiftHelper';

interface Props {
  employee: RosterEmployee;
  payCodeAndDetailCodes?: React.ReactNode;
  hideDates?: boolean;
  teamName: string;
}
export const AssignmentDetails = ({ employee, payCodeAndDetailCodes, hideDates, teamName }: Props) => {
  const differenceMinutes = employee.breakDuration
    ? differenceInUTCMinutes(employee.endDateTime, employee.startDateTime) - employee.breakDuration
    : differenceInUTCMinutes(employee.endDateTime, employee.startDateTime);
  const difference = Math.floor(differenceMinutes / 60);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.stationGray[800],
        borderRadius: 2,
        p: theme.spacing(1.5, 1),
        mb: 2,
      })}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box display="flex" alignItems="center">
          <Box
            sx={(theme) => ({
              background: getShiftColor(employee.team?.color || 'red', theme),
              width: '4px',
              height: '14px',
              mr: theme.spacing(1),
              borderRadius: '200px',
            })}
          ></Box>

          <Box sx={(theme) => ({ color: theme.palette.common.white, typography: 'bodyMMedium' })}>{teamName}</Box>
        </Box>
        <Box sx={(theme) => ({ typography: 'bodyXS', lineHeight: '16px', color: theme.palette.stationGray[400] })}>
          {`${difference <= 0 ? difference + 24 : difference}h shift`}
        </Box>
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: theme.palette.common.white,
          typography: 'bodySRegular',
          mb: 2,
        })}
      >
        <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>Start</Box>
        <Box display="flex" gap={3}>
          {!hideDates && <Box>{format(employee.startDateTime, 'MMM d, yyyy')}</Box>}
          <Box>{format(employee.startDateTime, 'HHmm')}</Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: theme.palette.common.white,
          typography: 'bodySRegular',
          mb: 2,
        })}
      >
        <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>End</Box>
        <Box display="flex" gap={3}>
          {!hideDates && <Box>{format(employee.endDateTime, 'MMM d, yyyy')}</Box>}
          <Box>{format(employee.endDateTime, 'HHmm')}</Box>
        </Box>
      </Box>
      {payCodeAndDetailCodes}
    </Box>
  );
};
