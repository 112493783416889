import { DEFAULT_RANK, RosterPosition } from '@stationwise/share-types';
import { formatShiftDuration } from '@stationwise/shift-summary-helper';
import { getEmptyCardColor, EmptyCard, EmptyCardProps } from '../EmptyCard';

interface Props extends Partial<EmptyCardProps> {
  position: RosterPosition;
  isCollapsed?: boolean;
  isOver?: boolean;
  onClick?: () => void;
  isNotSelected?: boolean;
  isSelectable?: boolean;
  actions?: React.ReactNode;
}

export const EmptyPosition = ({
  position,
  isCollapsed,
  isOver,
  onClick,
  isSelectable,
  isNotSelected,
  cardSxProps,
  actions,
  ...props
}: Props) => {
  return (
    <EmptyCard
      testId={`empty-card-${position.id}`}
      withPlusSign={isSelectable}
      onClick={isSelectable && onClick ? onClick : undefined}
      badgeTitle={position.rank.id !== DEFAULT_RANK.id ? position.rank.code : ''}
      durationLabel={formatShiftDuration({ startTime: position.startDateTime, endTime: position.endDateTime })}
      color={position.rank.color}
      certs={isCollapsed ? [] : position.certifications}
      {...props}
      actions={actions}
      cardSxProps={[
        (theme) => ({
          height: isCollapsed ? '48px' : '114px',
          ...(isNotSelected && { opacity: 0.5 }),
          transition: 'opacity 0.5s ease',
          [theme.breakpoints.up('sm')]: {
            height: isCollapsed ? '40px' : '104px',
          },
          overflow: 'hidden',
        }),
        (theme) => {
          if (!isOver) {
            return {};
          }

          const colors = getEmptyCardColor(theme, { color: position.rank.color });
          return { background: colors.isOverBackground };
        },
        ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : []),
      ]}
    />
  );
};
