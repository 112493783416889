import { EventInput } from '@fullcalendar/core';
import {
  useLoadedAuthUserContext,
  getDepartmentFeatureFlagValue,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import {
  buildOvertimeInfoObject,
  buildStaffingListObject,
  OvertimeInfo,
} from '../../../MandatoryOvertimeList/overtimeListHelper';
import { OvertimeCard } from './OvertimeCard';

export const Overtime = ({ shift }: { shift: EventInput }) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isStaffingListsEnabled = getDepartmentFeatureFlagValue(departmentInfoState, 'STAFFING_LISTS', false);
  const { state: authUserState } = useLoadedAuthUserContext();

  const fetchOvertime = async (shiftDate: string) => {
    try {
      const response = await client.get('/shift/overtimes/', {
        params: {
          shiftDate: shiftDate,
          ignoreMandatory: true,
        },
      });
      return response.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const displayOvertimeInfo = async (dateStr: string): Promise<OvertimeInfo> => {
    if (isStaffingListsEnabled) {
      const response = await client.get('/staffing-list/staffing-lists/vot/', {
        params: { date: dateStr },
      });
      return buildStaffingListObject(response.data, authUserState.employee);
    } else {
      const response = await fetchOvertime(dateStr);
      return buildOvertimeInfoObject(response.voluntaryOvertimeList, authUserState.employee);
    }
  };

  return <OvertimeCard key={shift.id} shift={shift} displayOvertimeInfo={displayOvertimeInfo} />;
};
