import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import { format, addDays as fnsAddDays, subDays } from 'date-fns';

const isToday = (date: Date | null) => {
  if (!date) return false;
  const today = new Date();
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
};

interface DateChangerProps {
  currentDate: Date;
  addDays: (newValue: number) => void;
  toggleMiniCalendar?: () => void;
}

export const DateChanger = ({ currentDate, addDays, toggleMiniCalendar }: DateChangerProps) => {
  const disableForward = fnsAddDays(currentDate, 1).getFullYear() > new Date().getFullYear();
  const disableBackward = subDays(currentDate, 1).getFullYear() < new Date().getFullYear();

  const handleAddDay = () => {
    if (!disableForward) {
      addDays(1);
    }
  };

  const handleSubtractDay = () => {
    if (!disableBackward) {
      addDays(-1);
    }
  };
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Box
      data-cy="date-changer"
      display="flex"
      justifyContent="center"
      sx={(theme) => ({
        border: `1px solid ${theme.palette.stationGray[200]}`,
        borderRadius: theme.spacing(1),
        p: '10px 13px 6px',
        '&:hover': {
          border: `1px solid ${theme.palette.stationGray[300]}`,
        },
      })}
    >
      <Stack direction="row" gap="10px">
        <Box onClick={handleSubtractDay} sx={{ cursor: 'pointer' }}>
          <ArrowBackIosIcon
            sx={(theme) => ({
              color: disableBackward ? theme.palette.stationGray[200] : theme.palette.stationGray[400],
              cursor: disableBackward ? 'not-allowed' : 'pointer',
              height: '15px',
              width: '15px',
            })}
          />
        </Box>
        <Box onClick={toggleMiniCalendar} sx={{ cursor: 'pointer' }} display="flex" alignItems="center">
          <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySMedium' })}>
            {isMobile ? format(currentDate, 'MMM/d/yy E') : format(currentDate, 'dd LLL yyyy EE')}
          </Box>
          {isMobile && isToday(currentDate) && (
            <Box
              sx={(theme) => ({
                height: theme.spacing(1),
                width: theme.spacing(1),
                borderRadius: '50%',
                backgroundColor: '#F43F5E',
                ml: '7px',
                transition: 'opacity 0.2s',
              })}
              style={{
                opacity: isToday(currentDate) ? 1 : 0,
              }}
            ></Box>
          )}
        </Box>
        <Box onClick={handleAddDay} sx={{ cursor: 'pointer' }}>
          <ArrowForwardIosIcon
            sx={(theme) => ({
              color: disableForward ? theme.palette.stationGray[200] : theme.palette.stationGray[400],
              cursor: disableForward ? 'not-allowed' : 'pointer',
              height: '15px',
              width: '15px',
            })}
          />
        </Box>
      </Stack>
    </Box>
  );
};
