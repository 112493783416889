import Box from '@mui/material/Box';
import { useSpring, animated } from '@react-spring/web';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { XCloseIcon16 } from '@stationwise/component-module';
import { Header } from './Header';
import { ThumbBarWrapper } from './ThumbBarWrapper';
import { useReduceMotion } from './utils';

const DRAWER_HEIGHT = window.innerHeight;
const COLLAPSED_HEIGHT = 140;
const THUMB_HEIGHT = 35;

type TBottomSheetProps = {
  children: JSX.Element;
  initialDrawerDistanceTop?: number;
  isOpen: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  onStatusChange?: (status: string) => void;
  selectedView?: string;
  header?: string;
  showCloseIcon?: boolean;
  bgWhite?: boolean;
  boxHeight?: number;
};

export const BottomSheet: React.FC<TBottomSheetProps> = ({ children, setOpen, header, showCloseIcon, bgWhite, boxHeight }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [bottom, setBottom] = React.useState(-DRAWER_HEIGHT);

  const [isBottomOpen, setIsBottomOpen] = useState(false);

  useEffect(() => {
    setIsBottomOpen(false);
    setBottom(-DRAWER_HEIGHT + COLLAPSED_HEIGHT);
  }, [boxHeight]);

  // ANIMATION
  const prefersReducedMotion = useReduceMotion();
  const styles = useSpring({
    bottom,
    immediate: prefersReducedMotion,
    config: { friction: 20 },
  });

  return (
    <animated.div
      style={{
        left: '50%',
        transform: 'translateX(-50%)',
        position: 'fixed',
        width: '100%',
        height: DRAWER_HEIGHT,
        transition: 'height 200ms',
        zIndex: 20000,
        ...styles,
      }}
    >
      <Box
        aria-modal="true"
        role="dialog"
        aria-labelledby="BottomSheet-title"
        sx={(theme) => ({
          backgroundColor: bgWhite ? theme.palette.common.white : theme.palette.stationGray[100],
          boxShadow: '0px 1px 8px rgb(0 0 0 / 30%)',
          borderRadius: '8px',
          margin: 'auto',
          maxWidth: '480px',
          width: '100%',
          height: '100%',
        })}
      >
        <Box>
          <ThumbBarWrapper
            isOpen={isBottomOpen}
            onClick={(open) => {
              setIsBottomOpen(!open);
              isBottomOpen
                ? setBottom(-DRAWER_HEIGHT + COLLAPSED_HEIGHT)
                : boxHeight && (boxHeight + 56 < DRAWER_HEIGHT ? setBottom(-DRAWER_HEIGHT + boxHeight + 44) : setBottom(-56));
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '11px' }}>
            {header && <Header text={header} />}
            {showCloseIcon && (
              <Box
                onClick={() => setOpen && setOpen(false)}
                sx={(theme) => ({
                  mt: theme.spacing(-1),
                  backgroundColor: theme.palette.stationGray[200],
                  borderRadius: 3.5,
                  height: '28px',
                  width: '28px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  color: theme.palette.stationGray[400],
                })}
              >
                <XCloseIcon16 />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            width: '100%',
            height: `${DRAWER_HEIGHT + bottom - THUMB_HEIGHT}px`,
            transition: 'height 200ms',
          }}
          ref={scrollRef}
          tabIndex={0}
        >
          {children}
        </Box>
      </Box>
    </animated.div>
  );
};
