import { Box, BoxProps } from '@mui/material';
import { Star05Icon16 } from '../../assets';

export const SuperAdminBadge = ({ sx, ...props }: Omit<BoxProps<'div'>, 'children'>) => {
  return (
    <Box
      {...props}
      sx={[
        (theme) => ({
          border: `1px solid ${theme.palette.stationGray[200]}`,
          borderRadius: '9999px',
          color: theme.palette.stationPurple[600],
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          p: theme.spacing(0.75, 1.5),
          typography: 'bodySMedium',
          '& svg path': { stroke: 'currentColor' },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      <Star05Icon16 />
      Super admin
    </Box>
  );
};
