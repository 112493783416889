import { randomNumberFromRange } from '../number';

/**
 * This function will return n number of random values from an array
 * @param array - The array from which the random values will be taken
 * @param amountOfNumbers  - How many elements are needed from the array
 * @param allowRepetition - If true the same value can be taken more than once
 * @returns - An array with random values from the array
 */
export const randomValuesFromArray = <Type>(array: Type[], amountOfNumbers: number, allowRepetition = false): Type[] => {
  function getNewRandomNumberArray(arrayNumber: number[], amountOfNumbers: number) {
    if (amountOfNumbers - arrayNumber.length < 1) {
      return arrayNumber;
    }
    const newRandomNumber = randomNumberFromRange(array.length - 1, 0);
    if (allowRepetition || !arrayNumber.some((value) => value === newRandomNumber)) {
      arrayNumber.push(newRandomNumber);
    }
    return getNewRandomNumberArray(arrayNumber, amountOfNumbers);
  }

  const newRandomArray: Type[] = [];
  const size = array.length;

  if (size < 1) {
    return newRandomArray;
  }

  if (size === amountOfNumbers || amountOfNumbers > size) {
    return array;
  }

  const undefinedArrayNumber = getNewRandomNumberArray([], amountOfNumbers);
  return undefinedArrayNumber.map((value) => array[value]);
};

export const arraysAreEqualIgnoreOrder = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};
