import { Box } from '@mui/material';
import { useEffect } from 'react';
import { LottieLogoPageLoader, useDocumentTitle, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { GetAutoHireSignUpDTO } from '@stationwise/share-types';
import { PUSHER_EVENT_TYPES, PUSHER_UPDATE_MESSAGE, RefreshEventCallback } from '@stationwise/share-utils';
import { AppPageLayout } from '../../../../AppPageLayout';
import { useFetchAutoHireSignUpData } from '../hooks/useFetchAutoHireSignUpData';
import { AutoHireErrorPage } from './AutoHireErrorPage';
import { AutoHireSignUpContent } from './AutoHireSignUpContent';

interface Props {
  getAutoHireSignUpDTO: GetAutoHireSignUpDTO;
}

export const AutoHireSignUpMain = ({ getAutoHireSignUpDTO }: Props) => {
  useDocumentTitle('Hiring Engine Sign-up');
  const autoHireId = getAutoHireSignUpDTO.autoHireId;
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const refreshTriggerChannel = departmentContext.refreshTriggerChannel;
  const { isLoading, isError, data, forceRefetch } = useFetchAutoHireSignUpData({
    autoHireId: autoHireId,
  });

  useEffect(() => {
    if (!refreshTriggerChannel) return;

    const handlePusherUpdate: RefreshEventCallback = (data) => {
      if (data.triggerAll || data.message === PUSHER_UPDATE_MESSAGE) {
        forceRefetch();
      }
    };

    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.STAFFING];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
    };
  }, [refreshTriggerChannel, forceRefetch]);

  const renderContent = () => {
    if (isLoading) {
      return <LottieLogoPageLoader />;
    }

    if (isError) {
      return <AutoHireErrorPage />;
    }
    if (data !== null && data.autoHireVacancies.length > 0) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            alignContent: 'center',
          }}
        >
          <AutoHireSignUpContent autoHireId={autoHireId} data={data} forceRefetch={forceRefetch} />
        </Box>
      );
    }
    return <AutoHireErrorPage />;
  };

  return (
    <AppPageLayout fixedPosition>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          height: '100%',
          width: '100%',
          p: 0,
          maxWidth: '768px',
          margin: '0 auto',
        }}
      >
        {renderContent()}
      </Box>
    </AppPageLayout>
  );
};
