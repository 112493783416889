import { Box } from '@mui/material';
import { differenceInDays, isValid, startOfDay } from 'date-fns';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { AdminAutocomplete, DatePickerComp } from '@stationwise/component-module';
import { PayCode } from '@stationwise/share-types';
import { TimePicker } from '../../../../../components/Common';

export const STEP_NUMBER = 0;

interface Props {
  currentStepNumber: number;
  setNextDisabled: Dispatch<SetStateAction<boolean>>;
  setCompletedSteps: Dispatch<SetStateAction<number[]>>;
  activationDateTime: Date;
  deactivationDate: Date | null;
  setDeactivationDate: Dispatch<SetStateAction<Date | null>>;
  payCodes: PayCode[];
  selectedPayCodeCode: string;
  setSelectedPayCodeCode: Dispatch<SetStateAction<string>>;
  skipStartTime: boolean;
}

export const MoveToTemporaryNonShiftStepOne = ({
  currentStepNumber,
  setNextDisabled,
  setCompletedSteps,
  activationDateTime,
  deactivationDate,
  setDeactivationDate,
  payCodes,
  selectedPayCodeCode,
  setSelectedPayCodeCode,
  skipStartTime,
}: Props) => {
  const activationDate = startOfDay(activationDateTime);

  const hasDeactivationDate = !!deactivationDate && isValid(deactivationDate);

  const durationDays = !hasDeactivationDate ? 0 : 1 + differenceInDays(deactivationDate, activationDate);

  const isDurationValid = durationDays > 0;

  const isPayCodeValid = !!selectedPayCodeCode;
  const payCodeOptions = payCodes.map((pc) => ({ label: pc.name, value: pc.code, secondLabel: pc.code }));
  const selectedPayCodeOption = payCodeOptions.find((option) => option.value === selectedPayCodeCode) || null;

  const nextDisabled = !isDurationValid || !isPayCodeValid;

  useEffect(() => {
    if (currentStepNumber !== STEP_NUMBER) {
      return;
    }

    setNextDisabled(nextDisabled);
    setCompletedSteps((prevCompletedSteps) => {
      const newCompletedSteps = new Set(prevCompletedSteps);
      nextDisabled ? newCompletedSteps.delete(STEP_NUMBER) : newCompletedSteps.add(STEP_NUMBER);
      return [...newCompletedSteps];
    });
  }, [currentStepNumber, nextDisabled, setNextDisabled, setCompletedSteps]);

  return (
    <Box sx={{ width: '428px' }}>
      <Box sx={{ typography: 'bodyLMedium', mb: 1 }}>Effective start time</Box>
      <Box sx={{ display: 'flex', gap: 1, '& .MuiFormControl-root': { border: 0 }, '& .MuiInputBase-input': { pl: 0 } }}>
        <Box sx={{ flex: 1 }}>
          <DatePickerComp
            disabled
            value={activationDate}
            setValue={() => null}
            openIconPosition="start"
            sxProps={{ width: '100%' }}
          />
        </Box>
        {!skipStartTime && (
          <Box sx={{ width: '135px' }}>
            <TimePicker disabled value={activationDateTime} setValue={() => null} sxProps={{ width: '100%' }} />
          </Box>
        )}
      </Box>
      <Box sx={{ typography: 'bodyLMedium', mt: 3, mb: 1 }}>End date</Box>
      <Box sx={{ '& .MuiFormControl-root': { border: 0 }, '& .MuiInputBase-input': { pl: 0 } }}>
        <DatePickerComp
          minDate={activationDate}
          value={deactivationDate}
          setValue={setDeactivationDate}
          openIconPosition="start"
          sxProps={{ width: '100%' }}
        />
      </Box>
      <Box sx={{ mt: 1, whiteSpace: 'nowrap' }}>
        <Box component="span" sx={(theme) => ({ color: theme.palette.stationGray[500], mr: 1 })}>
          Duration
        </Box>
        {durationDays === 1 && `${durationDays} day`}
        {durationDays > 1 && `${durationDays} days`}
      </Box>
      <Box sx={{ typography: 'bodyLMedium', mt: 3, mb: 1 }}>Pay code</Box>
      <AdminAutocomplete
        placeholder="Choose pay code"
        options={payCodeOptions}
        onChange={(event, option) => setSelectedPayCodeCode(option?.value || '')}
        value={selectedPayCodeOption}
        multiple={false}
        endAdornment={(() => {
          if (!selectedPayCodeOption || selectedPayCodeOption.value === selectedPayCodeOption.label) {
            return undefined;
          }

          return (
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                lineHeight: '16px',
                color: theme.palette.stationGray[500],
                mr: '3px',
              })}
            >
              {selectedPayCodeOption.value}
            </Box>
          );
        })()}
      />
    </Box>
  );
};
