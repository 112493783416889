import { RosterApparatus, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { checkIsStrikeTeamApparatus } from '../id';
import { IShiftSummaryHelper } from '../types';

export const checkIsUnsavedStrikeTeamAssignment = (
  { shiftDuration }: IShiftSummaryHelper,
  apparatus: RosterApparatus | null,
  position: RosterPosition | null,
  employee: RosterEmployee,
) => {
  if (!apparatus || !checkIsStrikeTeamApparatus(apparatus) || !position) {
    return false;
  }

  return (apparatus.strikeTeamAssignmentPayloadMap.get(position.id) || []).some((payload) => {
    return (
      `${payload.candidateId}` === employee.id &&
      payload.startTime === differenceInUTCMinutes(employee.startDateTime, shiftDuration.startTime) &&
      payload.endTime === differenceInUTCMinutes(employee.endDateTime, shiftDuration.startTime)
    );
  });
};
