import { Sort } from '@mui/icons-material';
import { Box, Select, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';
import React, { useId, useState } from 'react';

interface Props {
  options: string[];
  selectedOptions?: string;
  setSelectedOptions: React.Dispatch<React.SetStateAction<string>>;
}

export const SortFilter = ({ options, selectedOptions, setSelectedOptions }: Props) => {
  const id = useId();
  const [isOpen, setIsOpen] = useState(false);
  const disabled = !options.length;

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedOptions(event.target.value);
    setIsOpen(false);
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <Box id={`${id}label`} sx={(theme) => ({ typography: 'bodySMedium', color: theme.palette.stationGray[300] })}>
        Sort by
      </Box>
      <Select
        labelId={`${id}label`}
        displayEmpty
        disabled={disabled}
        value={disabled ? '' : selectedOptions}
        onChange={handleChange}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        sx={(theme) => ({
          width: '100%',
          '& .MuiSelect-select': { p: '0 !important' },
          '& .MuiSelect-icon': { display: 'none' },
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.stationGray[200]} !important`,
            borderRadius: 2,
            boxShadow: 'none !important',
          },
        })}
        renderValue={(selected) => (
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              height: '40px',
              p: theme.spacing(0, 1, 0, '14px'),
            })}
          >
            <Sort sx={(theme) => ({ height: '20px', width: '20px', color: theme.palette.stationGray[200] })} />
            {selected && (
              <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodyXSSemibold' })}>{selected}</Box>
            )}
          </Box>
        )}
      >
        {options.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
