import { Box } from '@mui/material';
import { RosterStation } from '@stationwise/share-types';
import { StationHeader } from './StationHeader';

interface Props {
  station: RosterStation;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  certificationRequirements?: React.ReactNode;
}

export const Station = ({ station, children, actions, certificationRequirements }: Props) => {
  return (
    <Box>
      <StationHeader station={station} actions={actions} certificationRequirements={certificationRequirements} />
      {children}
    </Box>
  );
};
