import { RosterDataSource, RosterStation } from '@stationwise/share-types';

const NON_SHIFT_STATION_ORDER = new Map([
  [RosterDataSource.EVENT, 0],
  [RosterDataSource.TEMPORARY_NON_SHIFT, 1],
  [RosterDataSource.ADMINISTRATION, 2],
]);

export const sortStations = (allStationCards: Map<string, RosterStation>) => {
  const shiftStations: RosterStation[] = [];
  const nonShiftStations: RosterStation[] = [];

  for (const station of allStationCards.values()) {
    if (NON_SHIFT_STATION_ORDER.has(station.dataSource)) {
      nonShiftStations.push(station);
    } else {
      shiftStations.push(station);
    }
  }

  nonShiftStations.sort((a, b) => {
    const aOrder = NON_SHIFT_STATION_ORDER.get(a.dataSource) ?? -1;
    const bOrder = NON_SHIFT_STATION_ORDER.get(b.dataSource) ?? -1;
    return aOrder - bOrder || a.stationName.localeCompare(b.stationName);
  });

  return new Map([...shiftStations, ...nonShiftStations].map((s) => [s.stationId, s]));
};
