import { Box, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { Badge } from '@stationwise/component-module';

interface TradeBalanceProps {
  startDateTime: string;
  endDateTime: string;
  duration: number;
}
export const ShiftTradeBalance = ({ startDateTime, endDateTime, duration }: TradeBalanceProps) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <Badge
          label="TRADE REQUEST"
          color="blue"
          variant="bodySMedium"
          overrideFontSize="14px"
          overrideMaxLength={20}
          isCustom
          customColor={theme.palette.stationLightBlue[700]}
          customBackgroundColor={theme.palette.stationLightBlue[200]}
        />
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          mt: theme.spacing(2),
        })}
      >
        <Box sx={{ typography: 'bodyMRegular' }}>{format(startDateTime, 'M/d/yy')}</Box>
        <Box
          display="flex"
          sx={(theme) => ({
            ml: '10px',
            color: theme.palette.stationGray[400],
          })}
        >
          {startDateTime && <Box sx={{ typography: 'bodyMRegular' }}>{format(startDateTime, 'HH:00')}</Box>}
        </Box>
        <Box
          sx={(theme) => ({
            ml: '3px',
            mr: '3px',
            color: theme.palette.stationGray[400],
            typography: 'bodyMRegular',
          })}
        >
          -
        </Box>
        <Box
          display="flex"
          sx={(theme) => ({
            color: theme.palette.stationGray[400],
            typography: 'bodyMRegular',
          })}
        >
          {duration ? format(endDateTime, 'HH:00') : '....'}
        </Box>
      </Box>
    </>
  );
};
