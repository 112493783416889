import { useDroppable } from '@dnd-kit/core';
import { RosterApparatus, RosterPosition } from '@stationwise/share-types';
import { checkIsShift, checkIsStrikeTeam } from '@stationwise/shift-summary-helper';
import { EmptyCardProps } from '../../../EmptyCard';
import { EmptyPosition } from '../../../Position/EmptyPosition';
import { DroppableItem } from '../DroppableItem';
import { useRosterContext } from '../RosterContext';

interface Props extends Partial<EmptyCardProps> {
  apparatus: RosterApparatus;
  position: RosterPosition;
  isCollapsed: boolean;
  overId: string;
}

export const RosterEmptyPositionCard = ({ apparatus, position, isCollapsed, overId, cardSxProps, ...props }: Props) => {
  const { isReadonly, selectedEmptyPositionState } = useRosterContext();
  const isDroppableDisabled = !checkIsShift(position);
  const droppable = useDroppable({ id: overId, disabled: isDroppableDisabled });
  const hasCandidateAssignee = selectedEmptyPositionState.candidateAssigneeIds.size > 0;
  const isSelectable = !isReadonly && (checkIsShift(position) || checkIsStrikeTeam(position));
  const isNotSelected = hasCandidateAssignee && selectedEmptyPositionState.position?.id !== position.id;

  return (
    <DroppableItem droppable={droppable}>
      <EmptyPosition
        position={position}
        isCollapsed={isCollapsed}
        isOver={droppable.isOver}
        cardSxProps={cardSxProps}
        onClick={() => selectedEmptyPositionState.setPosition(position)}
        isNotSelected={isNotSelected}
        isSelectable={isSelectable}
      />
    </DroppableItem>
  );
};
