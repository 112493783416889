import { Box, FormControlLabel } from '@mui/material';
import { isValid } from 'date-fns';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { StationIcon20, useRosterContext } from '@stationwise/component-module';
import { offsetMinutes } from '@stationwise/shift-summary-helper';
import { TimePicker, Switch, WeekdayPicker } from '../../../../../components/Common';

export const STEP_NUMBER = 1;

interface Props {
  currentStepNumber: number;
  setNextDisabled: Dispatch<SetStateAction<boolean>>;
  setCompletedSteps: Dispatch<SetStateAction<number[]>>;
  activeWeekdays: number[] | null;
  setActiveWeekdays: Dispatch<SetStateAction<number[] | null>>;
  startTime: Date | null;
  setStartTime: Dispatch<SetStateAction<Date | null>>;
  endTime: Date | null;
  setEndTime: Dispatch<SetStateAction<Date | null>>;
}

export const MoveToTemporaryNonShiftStepTwo = ({
  currentStepNumber,
  setNextDisabled,
  setCompletedSteps,
  activeWeekdays,
  setActiveWeekdays,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
}: Props) => {
  const { selectedFilledPositionState, shiftSummaryHelper } = useRosterContext();
  const { station, apparatus } = selectedFilledPositionState;
  const { shiftDuration } = shiftSummaryHelper;

  const hasStartTime = !!startTime && isValid(startTime);
  const hasEndTime = !!endTime && isValid(endTime);

  const startMinutes = !hasStartTime ? -1 : offsetMinutes(startTime, shiftDuration.startTime);
  const endMinutes = !hasStartTime || !hasEndTime ? -1 : offsetMinutes(endTime, shiftDuration.startTime);
  const durationMinutes = !hasEndTime ? -1 : endMinutes - startMinutes;

  const isActiveWeekdaysValid = !activeWeekdays || activeWeekdays.length > 0;

  const isDurationValid =
    startMinutes >= -6 * 60 &&
    startMinutes <= shiftDuration.hours * 60 &&
    endMinutes > startMinutes &&
    endMinutes <= shiftDuration.hours * 60;

  const nextDisabled = !isDurationValid || !isActiveWeekdaysValid;

  const onSelectWeekday = (weekdayNumber: number) => {
    if (activeWeekdays) {
      const newActiveWeekdays = new Set(activeWeekdays);
      newActiveWeekdays.has(weekdayNumber) ? newActiveWeekdays.delete(weekdayNumber) : newActiveWeekdays.add(weekdayNumber);
      setActiveWeekdays([...newActiveWeekdays]);
    }
  };

  useEffect(() => {
    if (currentStepNumber !== STEP_NUMBER) {
      return;
    }

    setNextDisabled(nextDisabled);
    setCompletedSteps((prevCompletedSteps) => {
      const newCompletedSteps = new Set(prevCompletedSteps);
      nextDisabled ? newCompletedSteps.delete(STEP_NUMBER) : newCompletedSteps.add(STEP_NUMBER);
      return [...newCompletedSteps];
    });
  }, [currentStepNumber, nextDisabled, setNextDisabled, setCompletedSteps]);

  return (
    <Box sx={{ width: '380px' }}>
      <Box sx={{ typography: 'bodyLMedium', mb: 1.5 }}>Default staffing assignment</Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        {station && <StationIcon20 />}
        <Box sx={(theme) => ({ color: theme.palette.stationGray[500], fontWeight: 500, lineHeight: 1 })}>
          {station && apparatus ? `${station.stationName}, ${apparatus.name}` : 'Floater'}
        </Box>
      </Box>
      <Box sx={{ typography: 'bodyLMedium', mt: 3, mb: 1 }}>Work days</Box>
      <Box>
        <FormControlLabel
          label="Same shift schedule"
          control={<Switch checked={!activeWeekdays} onChange={(event) => setActiveWeekdays(event.target.checked ? null : [])} />}
          slotProps={{ typography: { variant: 'bodySRegular' } }}
          sx={(theme) => ({
            backgroundColor: theme.palette.stationGray[50],
            border: `1px solid ${theme.palette.stationGray[200]}`,
            borderRadius: 2,
            display: 'inline-flex',
            alignItems: 'center',
            gap: 1,
            p: theme.spacing(1.25, 1.5),
            m: 0,
          })}
        />
      </Box>
      {activeWeekdays && <WeekdayPicker activeWeekdays={activeWeekdays} onSelect={onSelectWeekday} sx={{ mt: 1.5 }} />}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          mt: 3,
          '& .MuiInputBase-input': { pl: 0 },
        }}
      >
        <Box>
          <Box sx={{ typography: 'bodySMedium', mb: 0.5 }}>Start time</Box>
          <TimePicker value={startTime} setValue={setStartTime} sxProps={{ width: '92px' }} />
        </Box>
        <Box>
          <Box sx={{ typography: 'bodySMedium', mb: 0.5 }}>End time</Box>
          <TimePicker value={endTime} setValue={setEndTime} sxProps={{ width: '92px' }} />
        </Box>
        <Box sx={{ flex: 1, mt: 3, textAlign: 'left', whiteSpace: 'nowrap' }}>
          <Box component="span" sx={(theme) => ({ color: theme.palette.stationGray[500], mr: 1 })}>
            Duration:
          </Box>
          {(() => {
            if (!startTime || !endTime || durationMinutes < 0 || !isDurationValid) {
              return (
                <Box component="span" sx={(theme) => ({ color: theme.palette.stationRose[700] })}>
                  Invalid
                </Box>
              );
            }

            const hours = Math.floor(durationMinutes / 60);
            const minutes = durationMinutes - hours * 60;
            return `${`${hours}`.padStart(2, '0')} h ${`${minutes}`.padStart(2, '0')} m`;
          })()}
        </Box>
      </Box>
    </Box>
  );
};
