import { RosterApparatus, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import { copyBoardWithNewApparatus, makeTemporaryPosition, sortTemporaryPositions } from './board';
import { setEmployeeActiveId } from './id';
import { IShiftSummaryHelper } from './types';

const getPayCodeIdString = ({ payCodes }: RosterEmployee) => {
  const ids = payCodes.map((pc) => pc.id).sort();
  return ids.join(',');
};

const getDetailCodeIdString = ({ detailCodes }: RosterEmployee) => {
  const ids = detailCodes.map((dc) => dc.id).sort();
  return ids.join(',');
};

const checkAreEmployeesConsecutive = (prev: RosterEmployee, curr: RosterEmployee) => {
  return prev.id === curr.id && curr.startDateTime <= prev.endDateTime;
};

const checkAreEmployeesMergeable = (prev: RosterEmployee, curr: RosterEmployee) => {
  return (
    checkAreEmployeesConsecutive(prev, curr) &&
    getPayCodeIdString(prev) === getPayCodeIdString(curr) &&
    getDetailCodeIdString(prev) === getDetailCodeIdString(curr) &&
    prev.trade?.id === curr.trade?.id &&
    prev.trade?.requester.id === curr.trade?.requester.id
  );
};

export const checkAreAllEmployeesMergeable = (employees: RosterEmployee[]) => {
  if (employees.length >= 2) {
    let index = 1;
    while (employees[index]) {
      if (!checkAreEmployeesMergeable(employees[index - 1], employees[index])) {
        return false;
      }

      index += 1;
    }
  }

  return true;
};

export const mergeEmployees = (employees: RosterEmployee[]) => {
  if (employees.length <= 1) {
    return employees;
  }

  const merged = [employees[0]];
  employees.slice(1, employees.length).forEach((curr) => {
    const prev = merged[merged.length - 1];

    if (checkAreEmployeesMergeable(prev, curr)) {
      if (curr.endDateTime > prev.endDateTime) {
        const newPrev = setEmployeeActiveId({ ...prev, endDateTime: curr.endDateTime });

        if (prev.staffedAt && curr.staffedAt && curr.staffedAt < prev.staffedAt) {
          newPrev.staffedAt = curr.staffedAt;
        } else if (!prev.staffedAt && curr.staffedAt) {
          newPrev.staffedAt = curr.staffedAt;
        }

        merged[merged.length - 1] = newPrev;
      }
    } else {
      merged.push(curr);
    }
  });

  return merged;
};

export const getConsecutiveEmployees = (
  shiftSummaryHelper: IShiftSummaryHelper,
  apparatus: RosterApparatus | null,
  position: RosterPosition | null,
  employee: RosterEmployee,
) => {
  let employees: RosterEmployee[] = [];
  if (!apparatus || !position) {
    employees = shiftSummaryHelper.unassignedCards;
  } else if (position.isTemporary) {
    employees = apparatus.positions.filter((p) => p.isTemporary).map((p) => p.employees[0]);
  } else {
    employees = position.employees;
  }

  const consecutiveEmployees = [employee];
  const currIndex = employees.findIndex((e) => e === employee);
  if (currIndex < 0) {
    return { employees, consecutiveEmployees };
  }

  let prevIndex = currIndex - 1;
  while (employees[prevIndex] && checkAreEmployeesConsecutive(employees[prevIndex], employees[prevIndex + 1])) {
    consecutiveEmployees.unshift(employees[prevIndex]);
    prevIndex -= 1;
  }

  let nextIndex = currIndex + 1;
  while (employees[nextIndex] && checkAreEmployeesConsecutive(employees[nextIndex - 1], employees[nextIndex])) {
    consecutiveEmployees.push(employees[nextIndex]);
    nextIndex += 1;
  }

  return { employees, consecutiveEmployees };
};

export const mergeConsecutiveEmployees = (
  shiftSummaryHelper: IShiftSummaryHelper,
  apparatus: RosterApparatus | null,
  position: RosterPosition | null,
  employee: RosterEmployee,
) => {
  const { employees, consecutiveEmployees } = getConsecutiveEmployees(shiftSummaryHelper, apparatus, position, employee);

  const newEmployees = [...employees];
  const spliceIndex = newEmployees.findIndex((e) => e === consecutiveEmployees[0]);
  if (spliceIndex >= 0) newEmployees.splice(spliceIndex, consecutiveEmployees.length, ...mergeEmployees(consecutiveEmployees));

  if (!apparatus || !position) {
    return { ...shiftSummaryHelper, unassignedCards: newEmployees };
  }

  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(shiftSummaryHelper, apparatus.id);
  if (position.isTemporary) {
    const newTemporaryPositions = newEmployees.map((e) => ({ ...makeTemporaryPosition(newApparatus, e), employees: [e] }));
    newApparatus.positions = newApparatus.positions.filter((p) => !p.isTemporary);
    newApparatus.positions.push(...newTemporaryPositions);
    newApparatus.positions = sortTemporaryPositions(newApparatus.positions);
    return { ...shiftSummaryHelper, allStationCards: newAllStationCards };
  }

  const positionIndex = newApparatus.positions.findIndex((p) => p.id === position.id);
  newApparatus.positions[positionIndex].employees = newEmployees;
  return { ...shiftSummaryHelper, allStationCards: newAllStationCards };
};
