import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Divider } from '@mui/material';
import {
  theme,
  UmbrellaIcon24,
  SwitchHorizontal02Icon24,
  BankNoteIcon24,
  ChevronRightIcon24,
  XCircleIcon24,
  useLoadedAuthUserContext,
  hasTemporaryNonShiftEvent,
  hasKellyDay,
  SHIFT_TITLES,
} from '@stationwise/component-module';
import { ShiftOverViewOptions as Options } from '../CalendarContent';

interface ShiftOverviewOptionsProps {
  handleSelect: (option: string) => void;
  shift: EventInput | EventImpl;
  todayEvents: EventInput[];
}

type DateRange = 'past' | 'future' | 'today';

export const ShiftOverviewOptions = ({ handleSelect, shift, todayEvents }: ShiftOverviewOptionsProps) => {
  const { state: authUserState } = useLoadedAuthUserContext();

  const determineDateRange = (shift: EventInput | EventImpl): DateRange => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    if (shift.start && new Date(shift.start as string) >= new Date(tomorrow)) {
      return 'future';
    } else if (shift.start && new Date(shift.start as string) < new Date(today)) {
      return 'past';
    } else {
      return 'today';
    }
  };

  const hasShiftTrade = todayEvents.some((event) => event.title === SHIFT_TITLES.TRADE_REQUESTED);

  const dateRange = determineDateRange(shift);

  if (hasTemporaryNonShiftEvent(todayEvents) || hasKellyDay(todayEvents)) {
    return null;
  }

  if (shift.title === SHIFT_TITLES.OVERTIME_REQUEST || shift.title === SHIFT_TITLES.OVERTIME_OPT_OUT) {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => handleSelect(Options.CANCEL_OVERTIME_REQUEST)}
          sx={(theme) => ({
            py: theme.spacing(2),
          })}
        >
          <Box display="flex">
            <Box
              sx={(theme) => ({
                color: theme.palette.stationRose[400],
              })}
            >
              <XCircleIcon24 />
            </Box>
            <Box
              sx={{
                ml: theme.spacing(2),
                color: theme.palette.stationRose[600],
                typography: 'bodyLMedium',
              }}
            >
              Cancel request
            </Box>
          </Box>

          <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
            <ChevronRightIcon24 />
          </Box>
        </Box>

        <Divider light />
      </>
    );
  }

  return (
    <>
      {!authUserState.employee.isNonShift && dateRange !== 'past' && !hasShiftTrade ? (
        <>
          <Box
            data-cy="trade-shift-button"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => handleSelect(Options.TRADE_SHIFT)}
            sx={(theme) => ({
              py: theme.spacing(2),
            })}
          >
            <Box display="flex">
              <Box
                sx={(theme) => ({
                  '& svg': {
                    '& path': {
                      stroke: theme.palette.stationGray[400],
                    },
                  },
                })}
              >
                <SwitchHorizontal02Icon24 />
              </Box>
              <Box
                sx={{
                  ml: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                  typography: 'bodyLMedium',
                }}
              >
                Trade shift
              </Box>
            </Box>

            <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
              <ChevronRightIcon24 />
            </Box>
          </Box>
          <Divider light />
        </>
      ) : null}

      {dateRange !== 'past' ? (
        <>
          <Box
            data-cy="request-time-off-button"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => handleSelect(Options.REQUEST_TIME_OFF)}
            sx={(theme) => ({
              py: theme.spacing(2),
            })}
          >
            <Box display="flex">
              <Box
                sx={(theme) => ({
                  '& svg': {
                    '& path': {
                      stroke: theme.palette.stationGray[400],
                    },
                  },
                })}
              >
                <UmbrellaIcon24 />
              </Box>
              <Box
                sx={{
                  ml: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                  typography: 'bodyLMedium',
                }}
              >
                Request time off
              </Box>
            </Box>
            <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
              <ChevronRightIcon24 />
            </Box>
          </Box>
          <Divider light />
        </>
      ) : null}

      {dateRange !== 'future' ? (
        <>
          <Box
            data-cy="request-additional-paid-time-button"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => handleSelect(Options.ADDITIONAL_PAID_TIME)}
            sx={(theme) => ({
              py: theme.spacing(2),
            })}
          >
            <Box display="flex">
              <Box
                sx={(theme) => ({
                  '& svg': {
                    '& path': {
                      stroke: theme.palette.stationGray[400],
                    },
                  },
                })}
              >
                <BankNoteIcon24 />
              </Box>
              <Box
                sx={{
                  ml: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                  typography: 'bodyLMedium',
                }}
              >
                Request additional paid time
              </Box>
            </Box>
            <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
              <ChevronRightIcon24 />
            </Box>
          </Box>
          <Divider light />
        </>
      ) : null}
    </>
  );
};
