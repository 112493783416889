import { Box, Theme, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { EmployeeOffView } from '@stationwise/share-types';
import { Flag02Icon16 } from '../../../../assets';
import { Badge } from '../../../Badge';
import { OvalChip } from '../../../Card';
import { getShiftColor } from '../../../ShiftHelper';

export interface ShiftTradePopoverContentProps {
  handleCancelClick: () => void;
  colorMode?: 'light' | 'dark';
  employeeOffData: EmployeeOffView;
}

const getColor = (theme: Theme, colorMode: string) => {
  switch (colorMode) {
    case 'dark':
      return {
        background: theme.palette.stationGray[900],
        backgroundInner: theme.palette.stationGray[800],
        border: `1px solid ${theme.palette.stationGray[500]}`,
        borderInner: 'none',
        buttonBackground: theme.palette.stationGray[900],
        buttonBorder: `1px solid ${theme.palette.stationGray[500]}`,
        text: theme.palette.common.white,
        title: theme.palette.stationGray[400],
        subtitle: theme.palette.stationGray[300],
      };
    default:
      return {
        background: theme.palette.common.white,
        backgroundInner: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[100]}`,
        borderInner: `1px solid ${theme.palette.grey[200]}`,
        buttonBackground: theme.palette.stationGray[100],
        buttonBorder: 'none',
        text: theme.palette.stationGray[900],
        title: theme.palette.stationGray[400],
        subtitle: theme.palette.stationGray[300],
      };
  }
};

export const ShiftTradePopoverContent = ({
  employeeOffData,
  colorMode = 'light',
  handleCancelClick,
}: ShiftTradePopoverContentProps) => {
  const theme = useTheme();
  const colors = getColor(theme, colorMode);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      sx={(theme) => ({
        p: theme.spacing(2),
        background: colors.background,
        borderRadius: theme.spacing(1),
        border: colors.border,
        width: '264px',
      })}
      /* Prevent drags and clicks from propagating up to the anchor element. */
      onKeyDown={(e) => (e.code === 'Space' || e.code === 'Enter') && e.stopPropagation()}
      onPointerDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <OvalChip label="TRADE" backgroundColor={theme.palette.stationLightBlue[500]} />
        <Box
          sx={(theme) => ({
            typography: 'bodySMedium',
            color: theme.palette.common.white,
            ml: theme.spacing(2),
          })}
        >
          Shift trade
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          background: colors.backgroundInner,
          border: colors.borderInner,
          borderRadius: theme.spacing(1),
          padding: theme.spacing(1.5),
          mt: theme.spacing(1),
        })}
      >
        <Box sx={{ color: colors.title, typography: 'bodySMedium', mb: 1.5 }}>Traded with</Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
          })}
        >
          <Badge label={employeeOffData.employeeOff.rank.code} isRound type="sm" color={employeeOffData.employeeOff.rank.color} />
          <Box
            sx={(theme) => ({
              color: colors.text,
              typography: 'bodyMMedium',
              ml: theme.spacing(1),
            })}
          >
            {`${employeeOffData.employeeOff.firstName} ${employeeOffData.employeeOff.lastName}`}
          </Box>
        </Box>
        <Box
          display="flex"
          sx={(theme) => ({
            mt: theme.spacing(1),
            alignItems: 'center',
          })}
        >
          <Box
            sx={(theme) => ({
              borderRadius: '50%',
              backgroundColor: theme.palette.common.white,
              pl: theme.spacing(0.75),
              pr: theme.spacing(0.75),
              '& svg': {
                color: getShiftColor(employeeOffData.employeeOff.team?.color.toLowerCase() || 'gray', theme),
                height: '12px',
                width: '12px',
              },
            })}
          >
            <Flag02Icon16 />
          </Box>
          <Box
            sx={(theme) => ({
              color: colors.text,
              ml: theme.spacing(1),
              typography: 'bodySMedium',
            })}
          >
            {employeeOffData.employeeOff.team?.name ?? ''}
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          background: colors.backgroundInner,
          border: colors.borderInner,
          borderRadius: theme.spacing(1),
          padding: theme.spacing(1.5),
          mb: theme.spacing(1.5),
        })}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1.5}>
          <Box sx={{ color: theme.palette.stationYellow[500], typography: 'bodySMedium' }}>Forced</Box>
          <Box sx={{ color: colors.title, typography: 'bodyXSMedium' }}>
            {format(employeeOffData.approvedAt, 'MMMM d, HH:mm')}
          </Box>
        </Box>
        <Box display="inline-block">
          <Badge label={employeeOffData.approvedBy.rank.name} isRound type="sm" color={employeeOffData.approvedBy.rank.color} />
        </Box>
        <Box sx={(theme) => ({ mt: theme.spacing(1), color: colors.text, typography: 'bodyMMedium' })}>
          {employeeOffData.approvedBy.name}
        </Box>
      </Box>
      <Box
        sx={{
          color: colors.text,
          backgroundColor: colors.buttonBackground,
          border: colors.buttonBorder,
          display: 'block',
          width: '100%',
          padding: '9px 13px',
          borderRadius: '6px',
          textAlign: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={handleCancelClick}
      >
        Cancel shift trade
      </Box>
    </Box>
  );
};
