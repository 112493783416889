import { Box, MenuItem, Select as MuiSelect, SelectChangeEvent, Theme } from '@mui/material';
import { ChevronDownIcon16 as ChevronDown, CheckIcon16 } from '../../assets';

export const selectStyle = (theme: Theme, width: string | undefined, height: string | undefined) => ({
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.stationGray[200]}`,
  height: height ? height : '40px',
  minWidth: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '.MuiSelect-select:focus': {
    backgroundColor: 'transparent',
  },
  '.MuiSelect-select': {
    p: '10px 12px',
    borderRadius: theme.spacing(1),
  },
  '.MuiSelect-select .dropdown-selected-icon': {
    display: 'none',
  },
  '.MuiSelect-select.MuiInputBase-input.MuiInput-input': {
    paddingRight: '32px',
  },
  '&::after': {
    border: 'none',
  },
  '&::before': {
    border: 'none',
  },
  '&:hover:not(.Mui-Disabled):before': {
    border: 'none',
  },
  '.MuiSelect-icon': {
    right: '8px',
  },
  ...(width && { width: '100%' }),
});

export interface SelectItem {
  label: string;
  value: string;
}
interface SelectProps {
  items: string[] | SelectItem[];
  selected: string;
  onChange: (selectedOption: string) => void;
  width?: string;
  height?: string;
  placeholder?: string;
}

export const Select = ({ items, selected, onChange, width, height, placeholder }: SelectProps) => {
  return (
    <Box sx={{ ml: width ? '0px' : '16px', ...(width && { width: '100%' }), minWidth: 0 }}>
      <MuiSelect
        displayEmpty
        value={selected}
        onChange={(event: SelectChangeEvent) => onChange(event.target.value as string)}
        variant="standard"
        IconComponent={ChevronDown}
        sx={(theme) => selectStyle(theme, width, height)}
        renderValue={(selected) => {
          if (selected === '') {
            return <span>{placeholder}</span>;
          }
          const selectedItem = items.find((item) => (typeof item === 'object' ? item.value === selected : item === selected));
          return typeof selectedItem === 'object' ? selectedItem.label : selectedItem;
        }}
      >
        {items.map((option) => {
          const optionValue = typeof option === 'string' ? option : option.value;
          return (
            <MenuItem value={optionValue} key={optionValue}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodyMRegular' })}>
                  {typeof option === 'string' ? option : option.label}
                </Box>
                <Box
                  className="dropdown-selected-icon"
                  sx={{
                    display: 'flex',
                    pl: '20px',
                  }}
                >
                  {optionValue === selected && <CheckIcon16 />}
                </Box>
              </Box>
            </MenuItem>
          );
        })}
      </MuiSelect>
    </Box>
  );
};
