import { Box } from '@mui/material';
import { ChangeEvent } from 'react';
import { Badge } from '@stationwise/component-module';
import { CustomSwitch } from './CustomSwitch';

interface CustomToggleItemProps {
  name: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  icon: React.ReactNode;
  departmentSetting?: boolean;
}

export const CustomToggleItem = ({ name, checked, onChange, icon, departmentSetting }: CustomToggleItemProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'flex-start',
          gap: theme.spacing(2),
        })}
      >
        {icon}
        <Box
          sx={(theme) => ({
            color: theme.palette.stationGray[900],
            typography: 'bodyMMedium',
          })}
        >
          {name}
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          width: theme.spacing(5),
          height: theme.spacing(3),
          padding: '3px 3px 3px 19px',
          justifyContent: 'flex-end',
          alignItems: 'center',
        })}
      >
        {departmentSetting && (
          <Box display="inline-flex">
            <Badge label={'Enabled'} isRound type="sm" color="green" />
          </Box>
        )}
        {!departmentSetting && <CustomSwitch checked={checked} onChange={onChange} disabled={false} />}
      </Box>
    </Box>
  );
};
