import { Box } from '@mui/material';
import { FlagIcon as FlagIconSvg } from '../../assets';

interface FlagIconProps {
  showBorder?: boolean;
  color?: string;
  height?: string;
  width?: string;
}

export const FlagIcon = ({ showBorder, height, width, color = 'red' }: FlagIconProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: height ? height : '20px',
        width: width ? width : '20px',
        borderRadius: '50%',
        border: `1px solid ${showBorder ? theme.palette.stationGray[200] : 'transparent'}`,
        '& svg': {
          stroke: color,
          fill: color,
        },
        '& svg path': {
          stroke: color,
          fill: color,
        },
      })}
    >
      <FlagIconSvg />
    </Box>
  );
};
