import { DialogTitle, Box } from '@mui/material';
import { SearchIcon20 } from '../../assets';
import { Button } from '../Button';
import { InfiniteScroll } from '../InfiniteScroll';
import { Input } from '../Input';
import { Loader } from '../Loader';

interface Props {
  title: string;
  subtitle: string;
  aboveSearchContent?: React.ReactNode;
  belowSearchContent?: React.ReactNode;
  onCancel: () => void;
  onSubmit: () => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  isLoading: boolean;
  loadMore: () => void;
  hasNextPage: boolean;
  searchResults: React.ReactNode;
  disableSubmit?: boolean;
  noResults: boolean;
}

export const AssignmentForm = ({
  title,
  subtitle,
  aboveSearchContent,
  belowSearchContent,
  onCancel,
  searchValue,
  setSearchValue,
  isLoading,
  hasNextPage,
  loadMore,
  disableSubmit,
  onSubmit,
  searchResults,
  noResults,
}: Props) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          height: '636px',
          position: 'relative',
          overflowY: 'auto',
          p: theme.spacing(3, 3, 2, 3),
          '& > :not(.SWAssignmentForm-infinite-scroll)': { width: '396px' },
          '& > .SWAssignmentForm-infinite-scroll > *': { maxWidth: '396px' },
        })}
      >
        <DialogTitle component="h2" sx={{ p: 0, m: 0, typography: 'bodyXLSemibold' }}>
          {title}
        </DialogTitle>
        <Box sx={{ mt: 1 }}>{subtitle}</Box>
        {aboveSearchContent}
        <Box sx={{ mt: 2 }}>
          <Input
            label="Select person"
            placeholder="Search"
            startAdornment={<Box component={SearchIcon20} sx={(theme) => ({ color: theme.palette.stationGray[400] })} />}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </Box>
        {belowSearchContent}
        <InfiniteScroll
          className="SWAssignmentForm-infinite-scroll"
          onLoadMore={loadMore}
          loader={hasNextPage && <Loader sx={{ my: 2 }} />}
          sx={(theme) => ({
            flex: 1,
            overflowY: 'auto',
            minHeight: '142px',
            m: theme.spacing(2, -2.5, 0, 0),
            pr: 0.5,
          })}
        >
          {!isLoading && noResults && <Box sx={{ textAlign: 'center', fontWeight: 500 }}>No results</Box>}
          {searchResults}
        </InfiniteScroll>
      </Box>
      <Box sx={{ pb: 10.5 }} />
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px -2px 4px -1px #0A0E160F, 0px -4px 6px -1px #0A0E161A',
          display: 'flex',
          gap: 2,
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          p: theme.spacing(2, 3, 3, 3),
          borderBottomLeftRadius: 'inherit',
          borderBottomRightRadius: 'inherit',
        })}
      >
        <Button buttonSize="lg" buttonType="tertiary" sx={() => ({ flex: 1 })} onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={disableSubmit} buttonSize="lg" buttonType="primary" sx={() => ({ flex: 1 })} onClick={onSubmit}>
          Submit
        </Button>
      </Box>
    </>
  );
};
