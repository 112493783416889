import { Box, Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import { ResponseBadgeApproved20, ResponseBadgeRejected20 } from '@stationwise/component-module';
import { BulkCallDetailsView } from '@stationwise/share-types';

interface RequestVolunteersHistoryCardProps {
  bulkCall: BulkCallDetailsView | null;
  index: number;
  setSelectedBulkCall?: (bulkCall: BulkCallDetailsView | null) => void;
  setIsBulkCallDetailsModalOpen?: (isOpen: boolean) => void;
  setSelectedBulkCallIndex?: (index: number) => void;
}
export const RequestVolunteersHistoryCard = ({
  bulkCall,
  index,
  setSelectedBulkCall,
  setIsBulkCallDetailsModalOpen,
  setSelectedBulkCallIndex,
}: RequestVolunteersHistoryCardProps) => {
  const volunteeredEmployeeCount = bulkCall?.bulkCallHistory.filter((history) => history.signUpTime !== null).length ?? 0;
  const notifiedEmployeeCount = bulkCall?.bulkCallHistory.length ?? 0;
  const notificationOpenedEmployeeCount = bulkCall?.bulkCallHistory.filter(
    (history) => history.timeSeen !== null && history.signUpTime === null,
  ).length;
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        p: theme.spacing(2, 0),
      })}
    >
      <Typography
        variant="bodySRegular"
        sx={(theme) => ({
          color: theme.palette.stationGray[500],
        })}
      >
        Request {index}
      </Typography>
      <Typography
        variant="bodyMSemibold"
        sx={(theme) => ({
          color: theme.palette.stationGray[800],
        })}
      >
        {notifiedEmployeeCount} People{' '}
        <Typography component="span" variant="bodyMRegular" sx={{ display: 'inline' }}>
          on
        </Typography>{' '}
        {bulkCall?.createdAt ? format(bulkCall.createdAt, 'MMM dd, HH:mm aa') : 'N/A'}
      </Typography>

      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(1),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          })}
        >
          <ResponseBadgeApproved20 />
          <Typography variant="bodySRegular">{volunteeredEmployeeCount} Volunteered</Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          })}
        >
          <ResponseBadgeRejected20 />
          <Typography variant="bodySRegular">
            {notificationOpenedEmployeeCount} Notification Opened, {notifiedEmployeeCount} Notified
          </Typography>
        </Box>
      </Box>
      {setSelectedBulkCall && setIsBulkCallDetailsModalOpen && setSelectedBulkCallIndex && (
        <Button
          variant="contained"
          sx={(theme) => ({
            borderRadius: '6px',
            width: '100%',
            height: '36px',
            textTransform: 'none',
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.stationGray[300]}`,
            boxShadow: 'none',
            color: theme.palette.stationGray[700],
            '&:hover': {
              backgroundColor: theme.palette.common.white,
              boxShadow: 'none',
            },
          })}
          onClick={() => {
            setSelectedBulkCall(bulkCall);
            setIsBulkCallDetailsModalOpen(true);
            setSelectedBulkCallIndex(index);
          }}
        >
          Show details
        </Button>
      )}
    </Box>
  );
};
