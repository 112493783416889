/**
 * This function will return a random number from a range
 * @param max - The maximum number
 * @param min - The minimum number
 * @returns A random number from the range
 * @example
 * randomNumberFromRange(10, 1) // 5
 * randomNumberFromRange(10, 1) // 10
 * randomNumberFromRange(3, 1) // 1
 * randomNumberFromRange(3, 1) // 3
 * randomNumberFromRange(3, 1) // 2
 */
export const randomNumberFromRange = (max: number, min: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
