import { Box, ButtonBase, styled } from '@mui/material';
import { Badge, WhiteStationIcon20, useLoadedAuthUserContext } from '@stationwise/component-module';
import { RosterEmployee, RosterStation, RosterApparatus } from '@stationwise/share-types';
import { getOffsetTop } from '@stationwise/share-utils';

const ShowButton = styled(ButtonBase)(({ theme }) => ({
  borderRadius: theme.spacing(2.5),
  padding: theme.spacing(1, 2),
  fontSize: theme.typography.button.fontSize,
  lineHeight: theme.typography.button.lineHeight,
  fontWeight: theme.typography.button.fontWeight,
  fontFamily: theme.typography.button.fontFamily,
  textTransform: 'none',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.primary,
  border: `1px solid #CED5DF`,
  '&:hover': {
    backgroundColor: theme.palette.grey[400],
  },
}));

interface Props {
  employee: RosterEmployee;
  shiftDateRange: string;
  station?: RosterStation;
  apparatus?: RosterApparatus;
}

export const ShiftDetails = ({ employee, shiftDateRange, station, apparatus }: Props) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const isMe = employee.id === authUserState.employee.id;

  const scrollToTarget = () => {
    const element = document.querySelector(`[data-roster-employee-id="${employee.id}"]`) as HTMLElement | null;
    if (element) {
      const elementOffsetTop = getOffsetTop(element);
      window.scrollTo({
        top: elementOffsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box
      sx={(theme) => ({
        border: `1px dashed ${theme.palette.stationGray[100]}`,
        boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
        p: theme.spacing(1, 2),
        backgroundColor: theme.palette.common.white,
        '&:last-child': { borderRadius: `0 0 ${2 * theme.shape.borderRadius}px ${2 * theme.shape.borderRadius}px` },
      })}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Badge label={employee.rank.code} isRound type="sm" color={employee.rank.color} isBold noPadding />
          <Box sx={(theme) => ({ color: theme.palette.stationGray[900], ml: theme.spacing(1), typography: 'bodyLMedium' })}>
            {employee.name}{' '}
            {isMe && (
              <Box component="span" sx={{ fontWeight: 500, color: '#A3ADB5' }}>
                {'(You)'}
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'bodyMRegular' })}>{shiftDateRange}</Box>
      </Box>

      {isMe && station && apparatus && (
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
          <Box display="flex" alignItems="center">
            <Box sx={{ marginRight: 1, display: 'flex', alignItems: 'center' }}>
              <WhiteStationIcon20 />
            </Box>
            <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodyMRegular' })}>
              {station.stationName + ' | ' + apparatus.name}
            </Box>
          </Box>

          <ShowButton
            onClick={() => {
              scrollToTarget();
            }}
          >
            Show
          </ShowButton>
        </Box>
      )}
    </Box>
  );
};
