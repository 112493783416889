import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { EmployeeTypes } from '@stationwise/share-types';
import { useLoadedAuthUserContext } from '../../Auth';
import { useLoadedDepartmentInfoContext } from '../../Department';
import { DisplayOption } from '../Calendar';
import { ActionButton } from './ActionButton';
import { DateSelector } from './DateSelector';

interface CalendarHeaderProps {
  title: string;
  selectedView: DisplayOption;
  prevHandle: () => void;
  nextHandle: () => void;
  requestOvertimeDisabled?: boolean;
  handleRequestOvertimeClick?: () => void;
  handleCreateIncidentClick?: () => void;
  children?: ReactNode;
  isLoading?: boolean;
  createIncident: boolean;
  setCreateIncident: (value: boolean) => void;
  setCreateIncidentOpen?: (value: boolean) => void;
  viewingPersonalCalendar: boolean;
}

export const Header = ({
  title,
  selectedView,
  prevHandle,
  nextHandle,
  requestOvertimeDisabled,
  handleRequestOvertimeClick,
  handleCreateIncidentClick,
  isLoading,
  createIncident,
  setCreateIncident,
  setCreateIncidentOpen,
  viewingPersonalCalendar,
}: CalendarHeaderProps) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const overtimeRequestEnabled = departmentContext.departmentInfo.settings.overtimeRequestEnabled;
  const incidentFormEnabled = departmentContext.departmentInfo.settings.incidentFormEnabled;

  const showActionButton =
    !viewingPersonalCalendar &&
    !authUserState.employee.isNonShift &&
    !(authUserState.employee.employeeType === EmployeeTypes.VOLUNTEER) &&
    !isLoading &&
    (overtimeRequestEnabled || incidentFormEnabled) &&
    selectedView === 'dayGridMonth';

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={(theme) => ({
        pb: '12px',
        pt: '12px',
        position: selectedView === 'listMonth' ? 'sticky' : 'unset',
        top: '0px',
        zIndex: selectedView === 'listMonth' ? theme.zIndex.appBar : theme.zIndex.fab,
        background: theme.palette.common.white,
        borderTop: `1px dashed ${theme.palette.stationGray[100]}`,
      })}
    >
      <Box sx={{ ml: '16px' }}>
        <Box sx={{ mt: { md: '10px' } }}>
          <DateSelector title={title} prevHandle={prevHandle} nextHandle={nextHandle} />
        </Box>
      </Box>
      {showActionButton && (
        <Box sx={{ mr: '16px' }}>
          <ActionButton
            dropdownDisabled={
              (!overtimeRequestEnabled && incidentFormEnabled) || (overtimeRequestEnabled && !incidentFormEnabled)
            }
            requestOvertimeDisabled={requestOvertimeDisabled}
            handleRequestOvertimeClick={handleRequestOvertimeClick}
            handleCreateIncidentClick={handleCreateIncidentClick}
            createIncident={createIncident}
            setCreateIncident={setCreateIncident}
            setCreateIncidentOpen={setCreateIncidentOpen}
          />
        </Box>
      )}
    </Box>
  );
};
