import { PopoverOrigin } from '@mui/material';

export interface PopoverPositionProps {
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
}

const TOP: PopoverPositionProps = {
  anchorOrigin: { horizontal: 'center', vertical: 'top' },
  transformOrigin: { horizontal: 'center', vertical: 'bottom' },
};

const BOTTOM: PopoverPositionProps = {
  anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
  transformOrigin: { horizontal: 'center', vertical: 'top' },
};

const LEFT: PopoverPositionProps = {
  anchorOrigin: { horizontal: 'left', vertical: 'center' },
  transformOrigin: { horizontal: 'right', vertical: 'center' },
};

const RIGHT: PopoverPositionProps = {
  anchorOrigin: { horizontal: 'right', vertical: 'center' },
  transformOrigin: { horizontal: 'left', vertical: 'center' },
};

export const POPOVER_POSITION = { TOP, BOTTOM, LEFT, RIGHT };

export const checkIsTop = ({ anchorOrigin }: PopoverPositionProps) => anchorOrigin.vertical === 'top';
export const checkIsBottom = ({ anchorOrigin }: PopoverPositionProps) => anchorOrigin.vertical === 'bottom';
export const checkIsLeft = ({ anchorOrigin }: PopoverPositionProps) => anchorOrigin.horizontal === 'left';
export const checkIsRight = ({ anchorOrigin }: PopoverPositionProps) => anchorOrigin.horizontal === 'right';

interface GetBestPopoverPositionInput {
  anchorRect: DOMRect;
  current: PopoverPositionProps;
  preferred: PopoverPositionProps;
  size: Pick<DOMRect, 'width' | 'height'>;
}

export const getBestPopoverPosition = ({ anchorRect, current, preferred, size }: GetBestPopoverPositionInput) => {
  // If the content overflows the current side, and there's more space on the opposite side, return the opposite side.
  // If the content fits in the preferred side, return the preferred side.
  const topSpace = anchorRect.top;
  const bottomSpace = window.innerHeight - anchorRect.bottom;
  const leftSpace = anchorRect.left;
  const rightSpace = window.innerWidth - anchorRect.right;
  if (checkIsTop(current) && size.height > topSpace && bottomSpace > topSpace) {
    return POPOVER_POSITION.BOTTOM;
  } else if (checkIsBottom(current) && size.height > bottomSpace && topSpace > bottomSpace) {
    return POPOVER_POSITION.TOP;
  } else if (checkIsLeft(current) && size.width > leftSpace && rightSpace > leftSpace) {
    return POPOVER_POSITION.RIGHT;
  } else if (checkIsRight(current) && size.width > rightSpace && leftSpace > rightSpace) {
    return POPOVER_POSITION.LEFT;
  } else if (checkIsTop(preferred) && size.height <= topSpace) {
    return preferred;
  } else if (checkIsBottom(preferred) && size.height <= bottomSpace) {
    return preferred;
  } else if (checkIsLeft(preferred) && size.width <= leftSpace) {
    return preferred;
  } else if (checkIsRight(preferred) && size.width <= rightSpace) {
    return preferred;
  }
  return current;
};

interface GetArrowPositionInput {
  anchorRect: DOMRect;
  contentRect: DOMRect;
  position: PopoverPositionProps;
}

export const getArrowPosition = ({ anchorRect, contentRect, position }: GetArrowPositionInput) => {
  // Return the (top, left) coordinates of the arrow. The origin is the (top, left) corner of the content.
  const arrowPosition = { top: '0', left: '0' };
  if (checkIsTop(position) || checkIsBottom(position)) {
    arrowPosition.top = checkIsTop(position) ? '100%' : '0';
    arrowPosition.left = `${(anchorRect.left + anchorRect.right) / 2 - contentRect.left}px`;
  } else if (checkIsLeft(position) || checkIsRight(position)) {
    arrowPosition.top = `${(anchorRect.top + anchorRect.bottom) / 2 - contentRect.top}px`;
    arrowPosition.left = checkIsLeft(position) ? '100%' : '0';
  }
  return arrowPosition;
};
