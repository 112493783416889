import { DayCellContentArg } from '@fullcalendar/core';
import { Box, Skeleton } from '@mui/material';

export const CustomEventContentLoading = ({ cellContent }: { cellContent: DayCellContentArg }) => {
  return (
    <>
      <Box display={'flex'}>
        <Box
          display="flex"
          sx={(theme) => ({
            backgroundColor: cellContent.isToday ? theme.palette.common.black : 'unset',
            color: cellContent.isToday ? theme.palette.common.white : 'unset',
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            textAlign: cellContent.isToday ? 'center' : 'unset',
            pt: cellContent.isToday ? '3px' : 'unset',
            justifyContent: cellContent.isToday ? 'center' : 'unset',
            typography: 'bodyMRegular',
          })}
        >
          {cellContent.dayNumberText}
        </Box>

        <Box
          sx={{
            mt: '7px',
            borderRadius: '20px',
            textTransform: 'none',
            textAlign: 'center',
            ml: cellContent.isToday ? '5px' : 'unset',
          }}
        >
          <Skeleton
            variant="rounded"
            width={50}
            height={20}
            sx={{ borderRadius: '42px', background: 'linear-gradient(90deg, #CED5DF 0%, #EAEFF6 100%)' }}
          />
        </Box>
      </Box>
      <Box sx={{ mt: '20px' }}>
        <Skeleton
          variant="rounded"
          width={100}
          height={16}
          sx={(theme) => ({
            mb: theme.spacing(1),
            borderRadius: '6px',
            background: 'linear-gradient(90deg, #CED5DF 0%, #EAEFF6 100%)',
          })}
        />
        <Skeleton
          variant="rounded"
          width={80}
          height={16}
          sx={{ borderRadius: '6px', background: 'linear-gradient(90deg, #CED5DF 0%, #EAEFF6 100%)' }}
        />
      </Box>
    </>
  );
};
