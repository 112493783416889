import { Theme } from '@mui/material';

interface GetEmployeeCardStylesInput {
  theme: Theme;
  employeeActiveId: string;
  selectedEmptyPositionState: {
    candidateAssigneeIds: Set<string>;
  };
}

export const getEmployeeCardStyles = ({ theme, employeeActiveId, selectedEmptyPositionState }: GetEmployeeCardStylesInput) => {
  const hasCandidateAssignee = selectedEmptyPositionState.candidateAssigneeIds.size > 0;
  const isCandidateAssignee = hasCandidateAssignee && selectedEmptyPositionState.candidateAssigneeIds.has(employeeActiveId);
  const isNotCandidateAssignee = hasCandidateAssignee && !selectedEmptyPositionState.candidateAssigneeIds.has(employeeActiveId);
  return {
    ...(isCandidateAssignee && { border: `3px solid ${theme.palette.stationTeal[400]}` }),
    ...(isNotCandidateAssignee && { opacity: 0.5 }),
    transition: 'border 0.2s linear, opacity 0.5s ease',
    '&:hover': {
      border: isCandidateAssignee ? `3px solid ${theme.palette.stationTeal[600]}` : `3px solid ${theme.palette.stationGray[400]}`,
    },
    '.SWDragOverlayCard-root &': {
      border: `3px solid ${theme.palette.stationGray[400]}`,
      borderRadius: '12px',
    },
  };
};
