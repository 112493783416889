import { Theme } from '@mui/material';

export const selectStyles = (theme: Theme) => ({
  borderRadius: theme.spacing(1),
  height: '24px',
  width: '110px',
  '.MuiTypography-root': {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
    pt: '2px',
  },
  '.MuiSelect-select:focus': {
    backgroundColor: 'transparent',
  },
  '.MuiSelect-select': {
    display: 'flex',
    borderRadius: theme.spacing(1),
  },
  '.MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: 'inherit',
  },
});
