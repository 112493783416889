import { Box, Menu, MenuItem } from '@mui/material';
import { useId, useState } from 'react';
import { RosterEmployee } from '@stationwise/share-types';
import { ChevronDownIcon16 } from '../../../../assets';
import { PreviewButton, ViewProfileButton } from '../../../EmployeePreview';

interface Props {
  employee: RosterEmployee;
}

export const AdministrationActions = ({ employee }: Props) => {
  const id = useId();

  const [cancelAnchorEl, setCancelAnchorEl] = useState<HTMLElement | null>(null);

  const closeMenu = () => setCancelAnchorEl(null);

  const openlMenu = (event: React.MouseEvent<HTMLButtonElement>) => setCancelAnchorEl(event.currentTarget);

  return (
    <Box sx={{ display: 'flex', gap: 2, mt: 2, '& .MuiButton-root': { width: '100%' } }}>
      <Box sx={{ flex: 1 }}>
        <ViewProfileButton employeeId={employee.id} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <PreviewButton
          buttonType="primary"
          id={`${id}cancel-button`}
          aria-controls={cancelAnchorEl ? `${id}cancel-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={cancelAnchorEl ? 'true' : undefined}
          onClick={openlMenu}
          sx={{
            gap: 0.5,
            pl: 1,
          }}
        >
          Manage
          <ChevronDownIcon16 />
        </PreviewButton>
        <Menu
          id={`${id}managa-menu`}
          anchorEl={cancelAnchorEl}
          open={Boolean(cancelAnchorEl)}
          onClose={closeMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: -8, horizontal: 'right' }}
          sx={(theme) => ({
            '& .MuiMenu-paper': {
              backgroundColor: theme.palette.stationGray[700],
              border: `1px solid ${theme.palette.stationGray[600]}`,
              borderRadius: '12px',
            },
            '& .MuiMenu-list': {
              p: theme.spacing(1.5),
            },
            '& .MuiMenuItem-root': {
              backgroundColor: theme.palette.stationGray[700],
              borderRadius: '8px',
              color: theme.palette.common.white,
              p: theme.spacing(1, 1.5),
              typography: 'bodySRegular',
            },
            '& .MuiMenuItem-root:hover': {
              backgroundColor: theme.palette.stationGray[800],
            },
          })}
        >
          <MenuItem component="a" href={`/app/employees/${employee.id}?edit-staffing=true`} target="_blank" onClick={closeMenu}>
            Manage staffing
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};
