import {
  PayCode,
  TemporaryNonShiftType,
  TEMPORARY_NON_SHIFT_PAY_CODE_TYPES,
  RosterPosition,
  RosterEmployee,
  ActingPayCodeMapping,
} from '@stationwise/share-types';
import { IShiftSummaryHelper } from './types';

export const filterTemporaryNonShiftPayCodes = (
  payCodes: PayCode[],
  temporaryNonShiftType: TemporaryNonShiftType,
  visibleTags: string[],
) => {
  const types = TEMPORARY_NON_SHIFT_PAY_CODE_TYPES.get(temporaryNonShiftType);

  return payCodes.filter((pc) => {
    const matchesType = types ? types.has(pc.payCodeType) : true;
    const matchesVisibilityTag = visibleTags.length > 0 ? pc.tags.some((tag) => visibleTags.includes(tag)) : true;
    return matchesType && matchesVisibilityTag;
  });
};

export const getMandatoryPayCodes = (
  shiftSummaryHelper: IShiftSummaryHelper,
  position: RosterPosition,
  employee: RosterEmployee,
): PayCode[] => {
  if (employee.rank.code === position.rank.code) {
    const currentPayCodes = [...employee.payCodes];
    const actingPayCodeMappings = shiftSummaryHelper.actingPayCodeMappings;

    // Find and remove acting codes, add back their base codes
    for (const payCode of employee.payCodes) {
      const actingMapping = actingPayCodeMappings.find((mapping) => mapping.actingPayCodeId === payCode.id);
      if (actingMapping) {
        // Remove the acting code
        const actingCodeIndex = currentPayCodes.findIndex((pc) => pc.id === payCode.id);
        if (actingCodeIndex !== -1) {
          currentPayCodes.splice(actingCodeIndex, 1);
        }

        // Add back the base pay code
        const basePayCode = shiftSummaryHelper.payCodes.find((pc) => pc.id === actingMapping.basePayCodeId);
        if (basePayCode && !currentPayCodes.some((pc) => pc.id === basePayCode.id)) {
          currentPayCodes.push(basePayCode);
        }
      }
    }

    return currentPayCodes;
  }

  const payCodesIdsToRemove = new Set<number>();
  const newPayCodes = [...employee.payCodes];

  for (const basePayCode of employee.payCodes) {
    const mapping = shiftSummaryHelper.actingPayCodeMappings.find((m) => findActingPayCodeMapping(m, basePayCode, position));

    if (!mapping) continue;

    const actingPayCode = shiftSummaryHelper.payCodes.find((pc) => pc.id === mapping.actingPayCodeId);
    if (!actingPayCode) continue;

    if (!mapping.isStacking) {
      payCodesIdsToRemove.add(basePayCode.id);
    }

    // Check if acting pay code already exists in newPayCodes
    const actingPayCodeExists = newPayCodes.some((pc) => pc.id === actingPayCode.id);
    if (!actingPayCodeExists) {
      newPayCodes.push(actingPayCode);
    }
  }

  return payCodesIdsToRemove.size > 0 ? newPayCodes.filter((pc) => !payCodesIdsToRemove.has(pc.id)) : newPayCodes;
};

const findActingPayCodeMapping = (mapping: ActingPayCodeMapping, basePayCode: PayCode, position: RosterPosition): boolean => {
  if (mapping.basePayCodeId === basePayCode.id && mapping.actingRankId === position.rank.id) {
    return true;
  }
  if (mapping.basePayCodeId === basePayCode.id && mapping.actingRankId === null) {
    return true;
  }
  return false;
};
