import { Box } from '@mui/material';
import React from 'react';
import { CounterProps } from '../../../Counter';
import { StaffingCounter } from './StaffingCounter';

interface MinReqRecordProps extends CounterProps {
  children: React.ReactNode;
  size?: 'sm' | 'lg';
}

export const MinReqRecord = ({ total, amount, children, size = 'sm' }: MinReqRecordProps) => {
  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.stationGray[700],
        p: size === 'lg' ? '22px 12px' : '8px 12px',
        borderRadius: '12px',
        display: 'flex',
        gap: 1,
      })}
    >
      <Box sx={{ display: 'flex', flex: 1 }}>{children}</Box>
      <Box>
        <StaffingCounter total={total} amount={amount} />
      </Box>
    </Box>
  );
};
