import { Box, useTheme } from '@mui/material';
import { format, isValid, startOfDay } from 'date-fns';
import { useState } from 'react';
import {
  ErrorPage,
  Loader,
  useDocumentTitle,
  useFetchStaffingLists,
  useSelectedStaffingList,
} from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { PersonalStaffingListControls } from './PersonalStaffingListControls';
import { PersonalStaffingListItems } from './PersonalStaffingListItems';
import { PersonalStaffingListPosition } from './PersonalStaffingListPosition';

export const PersonalStaffingListsMain = () => {
  useDocumentTitle('Staffing Lists');
  const theme = useTheme();
  const [shouldScrollToMeItem, setShouldScrollToMeItem] = useState(false);
  const today = startOfDay(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(today);
  const staffingListsResponse = useFetchStaffingLists({
    selectedDate: format(today, 'MM/dd/yyyy'),
  });
  const { isLoading, isError, staffingLists, selectedStaffingList, setSelectedStaffingList } = useSelectedStaffingList({
    selectedDate: selectedDate && isValid(selectedDate) ? format(selectedDate, 'MM/dd/yyyy') : '',
    staffingListsResponse,
  });

  return (
    <AppPageLayout sx={{ width: '100%' }}>
      <Box
        sx={{
          background: theme.palette.common.white,
          height: { xs: 'calc(100vh - 56px)', lg: '100vh' },
          width: '100%',
          maxWidth: '768px',
          margin: '0 auto',
          p: theme.spacing(3.5, 2, 4.5, 2),
          overflowY: 'auto',
        }}
      >
        <Box component="h1" sx={{ typography: 'bodyXXLSemibold', m: theme.spacing(0, 0, 1, 0) }}>
          My Staffing Lists
        </Box>
        <PersonalStaffingListControls
          staffingLists={staffingLists}
          selectedStaffingList={selectedStaffingList}
          setSelectedStaffingList={setSelectedStaffingList}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        {isLoading && <Loader sx={{ mt: 6 }} />}
        {!isLoading && isError && <ErrorPage />}
        {!isLoading && !isError && selectedStaffingList && (
          <PersonalStaffingListPosition staffingList={selectedStaffingList} onClick={() => setShouldScrollToMeItem(true)} />
        )}
        {!isError && selectedStaffingList && (
          <PersonalStaffingListItems
            isLoading={isLoading}
            staffingList={selectedStaffingList}
            shouldScrollToMeItem={shouldScrollToMeItem}
            setShouldScrollToMeItem={setShouldScrollToMeItem}
          />
        )}
      </Box>
    </AppPageLayout>
  );
};
