import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';

export function useGet<T>(url: string, params?: object) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<T | null>(null);
  const [fetchNoRequest, setFetchNoRequest] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setError(null);
    setData(null);

    client
      .get(url, {
        params,
      })
      .then((data) => {
        if (data.status === 200) {
          setData(data.data);
        } else {
          setIsError(true);
          setError(data.statusText);
        }
      })
      .catch((error) => {
        captureException(error);
        setIsError(true);
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [url, params, fetchNoRequest]);

  const forceRefetch = () => {
    setFetchNoRequest((prevValue) => prevValue + 1);
  };

  return {
    isLoading,
    isError,
    error,
    data,
    forceRefetch,
  };
}
