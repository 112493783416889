import { Navigate } from 'react-router-dom';
import {
  getDepartmentFeatureFlagValue,
  useLoadedDepartmentInfoContext,
  useDocumentTitle,
  CAPABILITIES,
} from '@stationwise/component-module';
import { AppPageLayout } from '../../AppPageLayout';
import { OvertimeListMain } from './components/OvertimeListMain';

const OvertimeListMainWrapper = () => {
  useDocumentTitle('Overtime List');
  return (
    <AppPageLayout requiredCapability={CAPABILITIES.VIEW_STAFFING_LIST}>
      <OvertimeListMain />
    </AppPageLayout>
  );
};

export const OvertimeList = () => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isStaffingListsEnabled = getDepartmentFeatureFlagValue(departmentInfoState, 'STAFFING_LISTS', false);
  if (isStaffingListsEnabled) {
    return <Navigate to="/app/staffing-lists" replace={true} />;
  }

  return <OvertimeListMainWrapper />;
};
