import { useEffect, useRef, useState } from 'react';
import { client, isAxiosError } from '@stationwise/share-api';
import { Candidate } from '@stationwise/share-types';
import { useShiftTemplateContext } from '../contexts/ShiftTemplateContext';

interface Props {
  searchValue: string;
  certifications: Set<string>;
}

export const useFetchCandidates = ({ searchValue, certifications }: Props) => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const { filterAvailableCandidates, mapPartialPositions, unsavedUnassignedEmployees, shiftTemplateHelper } =
    useShiftTemplateContext();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [data, setData] = useState<Candidate[]>([]);

  // returns a list with the employees that were unassigned from the frontend but still not saved in the backend
  // apply filters to them if needed
  // TODO: make it work for partial peps
  const getUnsavedUnassignedCandidates = () => {
    const unsavedUnassignedCandidates: Candidate[] = [];

    unsavedUnassignedEmployees.forEach((employee) => {
      if (
        (certifications.size === 0 || employee.certifications.some((cert) => certifications.has(cert.code))) &&
        (!searchValue || employee.name.toLowerCase().includes(searchValue.toLowerCase()))
      ) {
        const newCandidate = {
          id: Number(employee.id),
          name: employee.name,
          certifications: employee.certifications,
          rank: employee.rank,
          team: employee.team,
          defaults: employee.defaults,
          partialPlannedPositions: [],
        };
        if (!unsavedUnassignedCandidates.some((employee) => employee.id === newCandidate.id)) {
          unsavedUnassignedCandidates.push(newCandidate);
        }
      }
    });
    return unsavedUnassignedCandidates;
  };

  useEffect(() => {
    setPage(1);
    setHasNextPage(true);
    setData([]);
  }, [searchValue, certifications]);

  useEffect(() => {
    const unsavedUnassignedCandidates = getUnsavedUnassignedCandidates();

    const fetchData = async () => {
      setIsLoading(true);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      try {
        const url = 'shift/shift-template-candidates/';

        const params = {
          page,
          search: searchValue || undefined,
          certifications: [...certifications],
          currentTeamId: shiftTemplateHelper.currentTeams[0].id,
        };

        const response = await client.get(url, {
          params,
          signal: abortControllerRef.current.signal,
        });
        setData((oldData) => {
          const keys = new Set<number>();
          const newData: Candidate[] = [];
          const pushData = (result: Candidate) => {
            if (!keys.has(result.id)) {
              newData.push(result);
              keys.add(result.id);
            }
          };
          oldData.forEach(pushData);

          (response.data.results || []).forEach(pushData);

          const availableCandidates = filterAvailableCandidates(newData);
          const filteredEmployees = newData.filter(
            (candidate) =>
              availableCandidates.some((c) => c === candidate.id) &&
              !unsavedUnassignedCandidates.some((c) => c.id === candidate.id),
          );

          return mapPartialPositions(filteredEmployees.concat(unsavedUnassignedCandidates));
        });
        setHasNextPage(!!response.data.next);
        setIsLoading(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchValue, certifications]);

  const loadMore = () => {
    if (hasNextPage && !isLoading) {
      setPage(page + 1);
    }
  };

  return {
    isLoading,
    data,
    hasNextPage,
    loadMore,
  };
};
