import { useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { ALLOWED_EMPLOYEE_OFF_TYPES, EmployeeOffView } from '@stationwise/share-types';
import { useDateQueryParam } from '../../../hooks/useDateQueryParam';

interface Props {
  skip?: boolean;
  battalionId?: number;
}

export const useFetchEmployeesOff = ({ battalionId, skip = false }: Props) => {
  const [selectedDate, setSelectedDate] = useState<string | null | undefined>(null);
  const { selectedDate: paramsSelectedDate } = useDateQueryParam();

  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [employeesOff, setEmployeesOff] = useState<EmployeeOffView[] | null>(null);

  useEffect(() => {
    if (skip) {
      setIsLoading(false);
      setIsError(false);
      setError(null);
      setEmployeesOff([]);
      return;
    }
    if (paramsSelectedDate && paramsSelectedDate !== selectedDate) {
      setSelectedDate(() => paramsSelectedDate);

      setIsLoading(true);
      setIsError(false);
      setError(null);

      client
        .get('/shift/employees-off/', {
          params: {
            shiftDate: paramsSelectedDate,
            battalionId,
          },
        })
        .then((data) => {
          const filteredData = data.data.filter((employeeOff: EmployeeOffView) => {
            return ALLOWED_EMPLOYEE_OFF_TYPES.includes(employeeOff.requestType);
          });
          setEmployeesOff(filteredData);
        })
        .catch((error) => {
          setIsError(true);
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [paramsSelectedDate, selectedDate, battalionId, skip, fetchNoRequest]);

  const forceRefetch = () => {
    setFetchNoRequest((prev) => prev + 1);
    setSelectedDate(null);
  };

  return {
    isLoading,
    isError,
    error,
    employeesOff,
    forceRefetch,
  };
};
