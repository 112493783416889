import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { ShiftSummary } from '@stationwise/share-types';

interface Props {
  selectedDate: string;
  battalionId?: number;
  skip?: boolean;
}

export const useFetchShiftSummary = ({ selectedDate, battalionId, skip = false }: Props) => {
  // this value is only to force refetch
  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<ShiftSummary | null>(null);

  useEffect(() => {
    if (skip) {
      return;
    }
    setIsLoading(true);
    setIsError(false);
    setData(null);
    client
      .get('/shift/summary/', {
        params: {
          shiftDate: selectedDate,
          battalionId,
        },
      })
      .then((response) => {
        setData(response.data[selectedDate]);
      })
      .catch((error) => {
        setIsError(true);
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedDate, fetchNoRequest, skip, battalionId]);

  const forceRefetch = useCallback(() => {
    setFetchNoRequest((prevValue) => prevValue + 1);
  }, []);

  return {
    isLoading,
    isError,
    data,
    forceRefetch,
  };
};
