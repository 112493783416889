import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { BlankCard, Chip, getEventDisplayedTitle, useLoadedDepartmentInfoContext } from '@stationwise/component-module';

export const RequestTimeOffCard = ({
  shift,
  children,
  handleClick,
  status,
}: {
  shift: EventInput | EventImpl;
  children?: React.ReactNode;
  handleClick?: (arg0: EventInput | EventImpl) => void;
  status: string;
}) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const backgroundColor = shift.backgroundColor?.split('#')[1];
  return (
    <Box
      className="shift-card-box"
      sx={(theme) => ({
        mx: theme.spacing(2),
        pb: theme.spacing(2),
      })}
      onClick={() => handleClick && handleClick(shift)}
    >
      <BlankCard>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Chip
            label={
              getEventDisplayedTitle(departmentInfoState, shift.title || '').toUpperCase() +
              (status !== 'ADMIN_APPROVED' ? ' REQUEST' : '')
            }
            chipSxProps={() => ({
              background: shift.backgroundColor,
              height: '24px',
              width: '110%',
              p: '4px 2px',
              borderRadius: '4px',
              '&:after': {
                content: `url('data:image/svg+xml,<svg width="10" height="24" viewBox="0 0 10 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.30463 2.93237C4.15848 1.10758 2.15488 0 0 0V24C2.15488 24 4.15848 22.8924 5.30463 21.0676L8.66146 15.7232C10.0912 13.4469 10.0912 10.5531 8.66147 8.27681L5.30463 2.93237Z" fill="%23${backgroundColor}"/></svg>')`,
                position: 'absolute',
                height: '100%',
                width: '10px',
                right: '-4px',
              },
              '&:before': {
                content: `url('data:image/svg+xml,<svg width="10" height="24" viewBox="0 0 10 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.69537 2.93237C5.84152 1.10758 7.84512 0 10 0V0V24V24C7.84512 24 5.84152 22.8924 4.69537 21.0676L1.33854 15.7232C-0.0911757 13.4469 -0.091176 10.5531 1.33853 8.27681L4.69537 2.93237Z" fill="%23${backgroundColor}"/></svg>')`,
                position: 'absolute',
                height: '100%',
                width: '10px',
                left: '-4px',
              },
            })}
            variant="buttonM"
            typographyStyles={{
              letterSpacing: '0.03em',
              color: shift.textColor,
            }}
          />
          {(status === 'ADMIN_APPROVED' || status === 'ADMIN_DENIED') && (
            <Box
              sx={(theme) => {
                let backgroundColor = theme.palette.common.white;
                let color = theme.palette.stationGray[700];
                if (status === 'ADMIN_APPROVED') {
                  backgroundColor = theme.palette.stationGreen[100];
                  color = theme.palette.stationGreen[600];
                } else if (status === 'ADMIN_DENIED') {
                  backgroundColor = theme.palette.stationRed[100];
                  color = theme.palette.stationRed[600];
                }
                return {
                  backgroundColor,
                  color,
                  borderRadius: '16px',
                  padding: '2px 12px 2px 12px',
                  typography: 'bodySMedium',
                };
              }}
            >
              {status === 'ADMIN_APPROVED' && 'Approved'}
              {status === 'ADMIN_DENIED' && 'Denied'}
            </Box>
          )}
        </Box>
        <Box display="flex">
          {shift.start?.toString() && <Typography variant="bodyMRegular">{format(shift.start?.toString(), 'M/d/yy')}</Typography>}
          <Box
            display="flex"
            sx={(theme) => ({
              ml: theme.spacing(1.5),
              color: theme.palette.stationGray[400],
            })}
          >
            {shift.start?.toString() && (
              <Typography variant="bodyMRegular">{format(shift.start?.toString(), 'HH:mm')}</Typography>
            )}
            <Box sx={{ ml: '3px', mr: '3px' }}>-</Box>
            {shift.end?.toString() && <Typography variant="bodyMRegular">{format(shift.end?.toString(), 'HH:mm')}</Typography>}
          </Box>
        </Box>
        {children}
      </BlankCard>
    </Box>
  );
};
