import { useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { AutoHireInfoView } from '@stationwise/share-types';
import { useDateQueryParam } from '../../../hooks/useDateQueryParam';
import { getDepartmentFeatureFlagValue, useLoadedDepartmentInfoContext } from '../../Department/context/DepartmentInfo';

interface Props {
  skip?: boolean;
  battalionId?: number;
}

export const useFetchAutoHire = ({ skip = false, battalionId }: Props) => {
  const [selectedDate, setSelectedDate] = useState<string | null | undefined>(null);
  const { selectedDate: paramsSelectedDate } = useDateQueryParam();
  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [autoHireInfo, setAutoHireInfo] = useState<AutoHireInfoView | null>(null);
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const autoHireEnabled = getDepartmentFeatureFlagValue(departmentContext, 'AUTO_HIRE', false);
  useEffect(() => {
    if (skip || !autoHireEnabled) {
      setIsLoading(false);
      setIsError(false);
      setError(null);
      setAutoHireInfo(null);
      return;
    }
    if (paramsSelectedDate && paramsSelectedDate !== selectedDate) {
      setSelectedDate(() => paramsSelectedDate);

      setIsLoading(true);
      setIsError(false);
      setError(null);

      client
        .get('auto-hire/', {
          params: {
            shiftDate: paramsSelectedDate,
            battalionId,
          },
        })
        .then((data) => {
          setAutoHireInfo(data.data);
        })
        .catch((error) => {
          setIsError(true);
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [paramsSelectedDate, selectedDate, skip, fetchNoRequest, autoHireEnabled, battalionId]);

  const forceRefetch = () => {
    setFetchNoRequest((prev) => prev + 1);
    setSelectedDate(null);
  };

  return {
    isLoading,
    isError,
    error,
    autoHireInfo,
    forceRefetch,
  };
};
