import { RosterEmployeeOffPayloads, ListFieldsStaffingList } from '@stationwise/share-types';
import { getBoardEmployee, getBoardEmployeeNoteOverride } from '../board';
import { COLLISION_REGION } from '../constants';
import { makeApparatusOverId, makePositionOverId, makeOvertimeEmployeeActiveId } from '../id';
import { getOvertimeEmployee, getStaffingListItemBlockerErrorMessages } from '../overtime';
import { getPositionRequirementErrorMessages, getOvertimeRequirementErrorMessages } from '../requirement';
import {
  IShiftSummaryHelper,
  IShiftSummaryHelperActiveParams,
  IShiftSummaryHelperOverParams,
  IShiftSummaryHelperCollisionParams,
} from '../types';
import { addActiveToApparatus, addActiveToPosition, removeActiveFromPosition } from './board';
import { addActiveToUnassigned, removeActiveFromUnassigned } from './unassigned';

interface MoveEmployeeInput {
  overId: string;
  activeId: string;
  shiftSummaryHelper: IShiftSummaryHelper;
  selectedStaffingList: ListFieldsStaffingList | undefined;
  employeeOffPayloads: RosterEmployeeOffPayloads;
}

const getOverParams = (input: MoveEmployeeInput): IShiftSummaryHelperOverParams => {
  if (input.overId === COLLISION_REGION.FLOATERS_BAR) {
    return { region: COLLISION_REGION.FLOATERS_BAR };
  }

  for (const station of input.shiftSummaryHelper.allStationCards.values()) {
    for (const apparatus of station.apparatuses) {
      if (input.overId === makeApparatusOverId(apparatus.id)) {
        return { region: COLLISION_REGION.BOARD_APPARATUS, apparatus };
      }

      let positionIndex = 0;
      for (const position of apparatus.positions) {
        let employeeIndex = position.employees.findIndex((employee, employeeIndex) => {
          return input.overId === makePositionOverId(position.id, employeeIndex);
        });
        employeeIndex = Math.max(employeeIndex, input.overId === makePositionOverId(position.id) ? 0 : -1);
        if (employeeIndex >= 0) {
          const employee = position.employees[employeeIndex];
          if (employee?.id && employee?.activeId !== input.activeId) {
            // If an employee is dropped over a different occupied position,
            // assume the admin meant to drop the employee to excess capacity.
            return { region: COLLISION_REGION.BOARD_APPARATUS, apparatus };
          }

          return {
            region: COLLISION_REGION.BOARD_POSITION,
            employee,
            employeeIndex,
            position,
            positionIndex,
            apparatus,
          };
        }
        positionIndex += 1;
      }
    }
  }

  throw new Error('Cannot get over params');
};

const getActiveParams = (input: MoveEmployeeInput): IShiftSummaryHelperActiveParams => {
  const unassignedEmployee = input.shiftSummaryHelper.unassignedCards.find((employee) => input.activeId === employee.activeId);
  if (unassignedEmployee) {
    return { region: COLLISION_REGION.FLOATERS_BAR, employee: unassignedEmployee };
  }

  const overtimeEmployee = getOvertimeEmployee(input.shiftSummaryHelper, input.selectedStaffingList, input.activeId);
  if (overtimeEmployee) {
    return { region: COLLISION_REGION.OVERTIME_LIST, employee: overtimeEmployee };
  }

  const boardEmployeeResult = getBoardEmployee(input.shiftSummaryHelper, input.activeId);
  if (boardEmployeeResult.apparatus && boardEmployeeResult.position && boardEmployeeResult.employee) {
    return { region: COLLISION_REGION.BOARD_POSITION, ...boardEmployeeResult };
  }

  throw new Error('Cannot get active params');
};

/**
 * Check whether the collision is possible. If it is not possible, it is ignored completely.
 * Note "possible" is different from "valid". See `checkIsMoveValid` for the latter.
 */
const checkIsMovePossible = ({ over, active, shiftDuration }: IShiftSummaryHelperCollisionParams) => {
  if (over.region === COLLISION_REGION.FLOATERS_BAR) {
    // Ignore unassigned -> unassigned.
    return active.region !== COLLISION_REGION.FLOATERS_BAR;
  } else if (over.region === COLLISION_REGION.BOARD_APPARATUS) {
    // Allow anywhere -> excess capacity position.
    return true;
  } else if (over.region === COLLISION_REGION.BOARD_POSITION) {
    const overStartDateTime = over.employee?.startDateTime ?? shiftDuration.startTime;
    const overEndDateTime = over.employee?.endDateTime ?? shiftDuration.endTime;
    const hasOverlap = overStartDateTime < active.employee.endDateTime && overEndDateTime > active.employee.startDateTime;
    const isOverVacancy = over.position.employees.length === 0 || !over.employee.id;
    if (over.position.employees.some((employee) => employee.activeId === active.employee.activeId)) {
      // Ignore position -> same position.
      // Ignore re-ordering within the same split shift position.
      return false;
    } else {
      // Ignore anywhere -> non-vacant position.
      return isOverVacancy && hasOverlap;
    }
  }

  return false;
};

/**
 * Check whether the collision is valid. If it is not valid, return an error message.
 * Note "valid" is different from "possible". See `checkIsMovePossible` for the latter.
 *
 * Even if a collision is not valid, we still have to implement it because
 * we want to display the error message next to the active employee after it has moved.
 *
 * Return `canOverride: true` if the user is allowed to override the error.
 * By default this is false; the user must clear the error by undoing the move.
 */
const checkIsMoveValid = (input: MoveEmployeeInput, params: IShiftSummaryHelperCollisionParams) => {
  const { over, active } = params;
  const newActiveEmployee = params.newActive?.employee || active.employee;

  if (active.region === COLLISION_REGION.OVERTIME_LIST) {
    const activeStaffingListItem = input.selectedStaffingList?.items?.find((item) => {
      return active.employee.activeId === makeOvertimeEmployeeActiveId(item.employee.id);
    });
    const blockers = getStaffingListItemBlockerErrorMessages(
      params,
      input.employeeOffPayloads,
      activeStaffingListItem,
      newActiveEmployee,
    );
    if (blockers.length) {
      return { messages: blockers };
    }
  }

  const messages: string[] = [];
  if (over.region === COLLISION_REGION.BOARD_POSITION) {
    messages.push(...getPositionRequirementErrorMessages(params, over.position, active.employee));
  }
  if (active.region === COLLISION_REGION.OVERTIME_LIST) {
    messages.push(...getOvertimeRequirementErrorMessages(params, active.employee));
  }

  return !messages.length ? undefined : { messages, canOverride: true };
};

/**
 * Move (i.e. drag-and-drop) an employee from one place to another.
 * @param overId - Identifies where the employee is moved to.
 *   In drag-and-drop terms, this is the drop zone.
 * @param activeId - Identifies the employee that is moving.
 *   In drag-and-drop terms, this is the employee that the user is dragging.
 */
export const moveEmployee = (input: MoveEmployeeInput) => {
  const over = getOverParams(input);
  const active = getActiveParams(input);
  let params: IShiftSummaryHelperCollisionParams = { ...input.shiftSummaryHelper, over, active };
  if (!checkIsMovePossible(params)) {
    return null;
  }

  // Remove the employee from their original location.
  if (active.region === COLLISION_REGION.FLOATERS_BAR) {
    params = removeActiveFromUnassigned(params);
  } else if (active.region === COLLISION_REGION.BOARD_POSITION) {
    params = removeActiveFromPosition(params);
  }

  // Add the employee to their new location.
  if (over.region === COLLISION_REGION.FLOATERS_BAR) {
    params = addActiveToUnassigned(params);
  } else if (over.region === COLLISION_REGION.BOARD_APPARATUS) {
    params = addActiveToApparatus(params);
  } else if (over.region === COLLISION_REGION.BOARD_POSITION) {
    params = addActiveToPosition(params);
  }

  params.error = checkIsMoveValid(input, params);
  if (params.newActive?.employee) {
    if (params.error && !params.error.canOverride) {
      params.newActive.employee.activeId += '|error';
    }
    if (active.region === COLLISION_REGION.OVERTIME_LIST) {
      params.newActive.employee.noteOverride = getBoardEmployeeNoteOverride(input.shiftSummaryHelper, active.employee.id);
    }
    params.newActive.employee.staffedAt = params.newActive.employee.staffedAt ?? new Date();
  }

  return params;
};
