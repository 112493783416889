import { Typography, Box } from '@mui/material';
import { ActivationCodeComponentProps } from '../types';
import { LoginOptionCard } from './LoginOptionCard';
import { StepIndicator } from './StepIndicator';

export const LoginWithActivationCode = ({ activationCode, activationUrl, loading, onclick }: ActivationCodeComponentProps) => {
  const children = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 3,
          width: 400,
        }}
      >
        <Box width={400}>
          <StepIndicator number="1" text="On your mobile device or computer go to:" />
        </Box>

        <Typography
          sx={(theme) => ({
            backgroundColor: theme.palette.stationGray[100],
            mt: 2,
            display: 'flex',
            typography: 'bodyXXLMedium',
            textAlign: 'center',
            paddingX: '18px',
            paddingY: 2,
          })}
        >
          {activationUrl}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: '40px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '400px' }}>
          <Box width={400}>
            <StepIndicator number="2" text="Then enter this code" />
          </Box>

          <Box
            onClick={onclick}
            sx={(theme) => ({
              color: theme.palette.stationPurple[500],
              mt: 1,
              typography: 'buttonL',
              textAlign: 'right',
              '&:hover': {
                cursor: 'pointer',
              },
              width: 200,
            })}
          >
            Generate new
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
          backgroundColor: theme.palette.stationGray[100],
        })}
      >
        {loading && <Typography>Loading...</Typography>}
        {activationCode &&
          activationCode.split('').map((char, index) => (
            <Typography
              key={index}
              sx={{
                typography: 'bodyXXLMedium',
                textAlign: 'center',
                mx: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '52px',
                height: '56px',
              }}
            >
              {char}
            </Typography>
          ))}
      </Box>
    </>
  );
  return (
    <LoginOptionCard
      height={389}
      width={626}
      borderRadius={'16px'}
      stepIndicatorTitle={{ number: '2', text: 'ACTIVATION CODE' }}
      title="Log in with activation code"
      children={children}
      mb={6}
    />
  );
};
