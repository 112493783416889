import { Box, ButtonBase, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  BarChartSquare02Icon20,
  DateChanger,
  UsersXIcon20,
  getLastChangesErrorPopoverFallbackAnchorProps,
  useDateQueryParam,
  useRosterContext,
  PrinterIcon20,
  Edit05Icon20,
  theme,
  PlusIcon20,
  StationsSelect,
  OvertimeListIcon20,
  getTooltipProps,
  ClockRewindIcon20,
  useAuthUserCapabilities,
  HiringEngineIcon24,
} from '@stationwise/component-module';
import { AllowedColors, AutoHireInfoView, BulkCallDetailsView, Team } from '@stationwise/share-types';
import { TeamColoredBar } from '../../../../../components/Common';
import { ROUTES } from '../../../../../core/Routes';
import { HiringEngineModal } from '../../../HiringEngine/HiringEngineModal';
import { AutoHireVacancy, VacantStation } from '../../../HiringEngine/Vacancies/vacanciesHelper';
import { DRAWER_TYPE } from '../../constants';
import { PrintDayScheduleModal } from '../PrintDayScheduleModal';
import { CollapseSwitch } from './CollapseSwitch';

interface Props {
  openedDrawer: DRAWER_TYPE | null;
  setOpenedDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | null>>;
  saveChanges?: () => void;
  isSaving?: boolean;
  shiftTeams?: Team[];
  shiftColor?: AllowedColors;
  toggleMiniCalendar: () => void;
  saveDisabled?: boolean;
  groupedVacancies: Record<number, AutoHireVacancy[]>;
  forceRefetch: () => void;
  autoHireInfo: AutoHireInfoView | null;
  vacantStations: VacantStation[];
  bulkCalls: BulkCallDetailsView[];
}

export const TopBar = ({
  openedDrawer,
  setOpenedDrawer,
  saveChanges,
  isSaving = false,
  shiftTeams,
  shiftColor,
  toggleMiniCalendar,
  saveDisabled = false,
  groupedVacancies,
  forceRefetch,
  autoHireInfo,
  vacantStations,
  bulkCalls,
}: Props) => {
  const capabilities = useAuthUserCapabilities();
  const isShiftTemplateEnabled = capabilities.SHIFT_TEMPLATE_EDITOR;
  const canSendMessages = capabilities.SEND_MESSAGES;
  const { isReadonly, isStaffingListsEnabled, lastChanges, resetValues, userHasMadeChanges, preferences } = useRosterContext();

  const canViewChangelog = capabilities.VIEW_CHANGE_LOGS;

  const { addDays, selectedDateAsDate, selectedDate } = useDateQueryParam();
  const navigate = useNavigate();

  const toggleOpenedDrawer = (next: DRAWER_TYPE) => {
    setOpenedDrawer((prev) => (prev === next ? null : next));
  };

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const isDateInPast = selectedDateAsDate ? selectedDateAsDate.setHours(0, 0, 0) <= new Date().setHours(0, 0, 0) : false;
  const [hiringEngineModalOpen, setHiringEngineModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleHiringEngineClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setHiringEngineModalOpen(true);
  };

  const handlePrintModalOpen = () => {
    setIsPrintModalOpen(true);
  };

  const handlePrintModalClose = () => {
    setIsPrintModalOpen(false);
  };

  return (
    <Box>
      <Box
        display="flex"
        width="100%"
        sx={(theme) => ({
          background: theme.palette.common.white,
          p: '8px 24px',
        })}
        justifyContent="space-between"
      >
        <Box display="flex">
          <Box sx={(theme) => ({ mr: theme.spacing(1.5) })}>
            <DateChanger
              currentDate={selectedDateAsDate || new Date()}
              addDays={addDays}
              toggleMiniCalendar={toggleMiniCalendar}
            />
          </Box>
        </Box>
        <Box display="flex" alignSelf="center">
          <StationsSelect />
        </Box>
        <Box display="flex">
          <Box display="flex" justifyContent="center" alignItems="center" sx={(theme) => ({ gap: theme.spacing(2) })} mr={5}>
            {/* TODO: uncomment when Search is implemented.
            <ButtonBase
              sx={(theme) => ({
                borderRadius: theme.spacing(1),
                p: theme.spacing(1),
                '&:hover': {
                  backgroundColor: theme.palette.stationGray[200],
                },
              })}
              onClick={() => {
                throw new Error('Testing Sentry Error Boundary');
              }}
            >
              <SearchIcon />
            </ButtonBase>
            */}
            {isShiftTemplateEnabled && (
              <Tooltip arrow title="Shift template" placement="bottom" slotProps={getTooltipProps(theme)}>
                <ButtonBase
                  data-cy="shift-template-button"
                  onClick={() =>
                    navigate(ROUTES.SHIFT_TEMPLATE.fullRoute, {
                      state: {
                        teamId: shiftTeams && shiftTeams.length ? shiftTeams[0].id : null,
                        battalionId: preferences.selectedBattalionId,
                      },
                    })
                  }
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <Edit05Icon20 />
                </ButtonBase>
              </Tooltip>
            )}
            {canSendMessages && (
              <Tooltip arrow title="Send message " placement="bottom" slotProps={getTooltipProps(theme)}>
                <ButtonBase
                  onClick={() => toggleOpenedDrawer(DRAWER_TYPE.MESSAGE)}
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <PlusIcon20 />
                </ButtonBase>
              </Tooltip>
            )}
            <Tooltip arrow title="Print roster" placement="bottom" slotProps={getTooltipProps(theme)}>
              <ButtonBase
                data-cy="print-button"
                onClick={() => handlePrintModalOpen()}
                sx={(theme) => ({
                  borderRadius: theme.spacing(1),
                  p: theme.spacing(1),
                  '&:hover': {
                    backgroundColor: theme.palette.stationGray[200],
                  },
                })}
              >
                <PrinterIcon20 />
              </ButtonBase>
            </Tooltip>
            <PrintDayScheduleModal open={isPrintModalOpen} handleClose={handlePrintModalClose} />

            <Tooltip
              arrow
              title={isStaffingListsEnabled ? 'Staffing lists' : 'Overtime lists'}
              placement="bottom"
              slotProps={getTooltipProps(theme)}
            >
              <ButtonBase
                data-cy="overtime-button"
                onClick={() => toggleOpenedDrawer(DRAWER_TYPE.HIREBACK)}
                sx={(theme) => ({
                  borderRadius: theme.spacing(1),
                  p: theme.spacing(1),
                  backgroundColor: openedDrawer === DRAWER_TYPE.HIREBACK ? theme.palette.stationGray[200] : 'transparent',
                  '&:hover': {
                    backgroundColor: theme.palette.stationGray[200],
                  },
                })}
              >
                <OvertimeListIcon20 />
              </ButtonBase>
            </Tooltip>
            <Tooltip arrow title="Off roster" placement="bottom" slotProps={getTooltipProps(theme)}>
              <ButtonBase
                data-cy="employees-off-button"
                onClick={() => toggleOpenedDrawer(DRAWER_TYPE.EMPLOYEES_OFF)}
                sx={(theme) => ({
                  borderRadius: theme.spacing(1),
                  p: theme.spacing(1),
                  backgroundColor: openedDrawer === DRAWER_TYPE.EMPLOYEES_OFF ? theme.palette.stationGray[200] : 'transparent',
                  '&:hover': {
                    backgroundColor: theme.palette.stationGray[200],
                  },
                })}
              >
                <UsersXIcon20 />
              </ButtonBase>
            </Tooltip>
            <Tooltip arrow title="Staffing Stats" placement="bottom" componentsProps={getTooltipProps(theme)}>
              <ButtonBase
                data-cy="staffing-stats-button"
                onClick={() => toggleOpenedDrawer(DRAWER_TYPE.STAFFING_STATS)}
                sx={(theme) => ({
                  borderRadius: theme.spacing(1),
                  p: theme.spacing(1),
                  backgroundColor: openedDrawer === DRAWER_TYPE.STAFFING_STATS ? theme.palette.stationGray[200] : 'transparent',
                  '&:hover': {
                    backgroundColor: theme.palette.stationGray[200],
                  },
                })}
              >
                <BarChartSquare02Icon20 />
              </ButtonBase>
            </Tooltip>
            {canViewChangelog && (
              <Tooltip arrow title="Change Log" placement="bottom" slotProps={getTooltipProps(theme)}>
                <ButtonBase
                  onClick={() => toggleOpenedDrawer(DRAWER_TYPE.CHANGELOG)}
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <ClockRewindIcon20 />
                </ButtonBase>
              </Tooltip>
            )}
            {autoHireInfo &&
              (autoHireInfo.autoHires.length > 0 || !isDateInPast) &&
              (autoHireInfo.autoHires.length > 0 || Object.keys(groupedVacancies).length !== 0) && (
                <Tooltip arrow title="Hiring engine" placement="bottom" slotProps={getTooltipProps(theme)}>
                  <ButtonBase
                    data-cy="hiring-engine-button"
                    onClick={handleHiringEngineClick}
                    sx={(theme) => ({
                      borderRadius: theme.spacing(1),
                      p: theme.spacing(1),
                      '&:hover': {
                        backgroundColor: theme.palette.stationGray[200],
                      },
                    })}
                  >
                    <HiringEngineIcon24 />
                  </ButtonBase>
                </Tooltip>
              )}
            <HiringEngineModal
              open={hiringEngineModalOpen}
              setOpen={setHiringEngineModalOpen}
              anchorEl={anchorEl}
              forceRefetch={forceRefetch}
              vacantStations={vacantStations}
              bulkCalls={bulkCalls}
              selectedDate={selectedDate}
              selectedBattalionId={preferences.selectedBattalionId}
              autoHireInfo={autoHireInfo}
              isDateInPast={isDateInPast}
              groupedVacancies={groupedVacancies}
            />

            <CollapseSwitch />
            <Box display="flex" gap={1} alignItems="center">
              <Button buttonType="tertiary" disabled={!userHasMadeChanges || isSaving} onClick={resetValues} data-cy="cancel">
                Cancel
              </Button>
              <Tooltip title="You cannot save changes on past dates" disableHoverListener={!isReadonly}>
                <span>
                  <Button
                    {...getLastChangesErrorPopoverFallbackAnchorProps()}
                    disabled={!userHasMadeChanges || !!lastChanges || isSaving || isReadonly || saveDisabled}
                    onClick={saveChanges}
                    data-cy="save"
                  >
                    Save
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
      {shiftTeams && <TeamColoredBar shiftTeams={shiftTeams} shiftColor={shiftColor} />}
    </Box>
  );
};
