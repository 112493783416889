import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getMe } from '@stationwise/share-api';
import { setSentryUser } from '../../../hooks/useSentry';
import { LottieLogoPageLoader } from '../../LottieLogo';
import { useAuthUserContext } from '../context/AuthUser';

interface Props {
  children: JSX.Element;
  redirectTo: string;
}

export const RequireAuthProtector = ({ children, redirectTo }: Props) => {
  const { state, dispatch } = useAuthUserContext();
  const isAuthenticated = !!state.employee?.id;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(!isAuthenticated);
  const next = `${location.pathname}${location.search}`;

  useEffect(() => {
    const checkAuthentication = async () => {
      if (!isAuthenticated) {
        setIsLoading(true);
        const me = await getMe();
        if (me) {
          dispatch({ type: 'SET_USERNAME.SUCCESS', payload: me });
          dispatch({ type: 'SET_IS_APPARATUS_LOGIN', payload: me.isApparatusLogin });
          setSentryUser(me); // set Sentry user context
          setIsLoading(false);
          if (location.pathname !== (next || '/')) {
            navigate(next || '/', { replace: true });
          }
        } else {
          const url = next !== '/' ? `${redirectTo}?next=${encodeURIComponent(next)}` : redirectTo;
          navigate(url);
        }
      }
    };

    checkAuthentication();
  }, [isAuthenticated, dispatch, navigate, redirectTo, next, location.pathname]);

  return isLoading ? <LottieLogoPageLoader /> : children;
};
