import Box from '@mui/material/Box';
import { useState } from 'react';
import { WorkGroup } from '@stationwise/share-types';
import { EditGroupInfo } from './Edit/EditGroupInfo';
import { InfoCard, InfoCardField } from './InfoCard';

type GroupsSectionProps = {
  workGroups: WorkGroup[];
};

export const GroupsSection = (props: GroupsSectionProps) => {
  const [showModal, setShowModal] = useState(false);
  const fieldDefs: InfoCardField[][] = [];

  const handleEditModal = () => {
    setShowModal(true);
  };

  const workGroupsSection: InfoCardField[] = [
    {
      label: 'Work groups',
      field: {
        type: 'custom',
        extra: {
          custom: (
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
                flexWrap: 'wrap',
              })}
            >
              {props.workGroups.length === 0 && <Box> None </Box>}
              {props.workGroups.map((wg) => (
                <Box
                  display="flex"
                  key={`${wg.id}-license-class`}
                  sx={(theme) => ({
                    gap: theme.spacing(1),
                    alignItems: 'center',
                  })}
                >
                  <Box sx={{ typography: 'bodyMMedium' }}>{wg.name}</Box>
                </Box>
              ))}
            </Box>
          ),
        },
      },
    },
  ];

  fieldDefs.push(workGroupsSection);
  return (
    <>
      <EditGroupInfo showModal={showModal} setShowModal={setShowModal} employeeWorkGroups={props.workGroups} />
      <Box
        width="100%"
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
          border: `1px solid ${theme.palette.stationGray[200]}`,
          p: theme.spacing(4, 3, 3, 3),
          gap: theme.spacing(3),
        })}
      >
        <InfoCard fieldDefs={fieldDefs} title="Groups" handleEditModal={handleEditModal} />
      </Box>
    </>
  );
};
