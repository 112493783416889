import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DesktopTimePicker, DesktopTimePickerProps } from '@mui/x-date-pickers/DesktopTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

type Props = DesktopTimePickerProps<Date>;

export const SplitTime = ({ sx, ...props }: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopTimePicker
        ampm={false}
        timeSteps={{ hours: 1, minutes: 15 }}
        {...props}
        slotProps={{
          popper: {
            popperOptions: {
              modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
            },
          },
          ...props.slotProps,
        }}
        sx={[
          (theme) => ({
            width: 95,
            '& .MuiInputBase-input': {
              pr: 0,
            },
            '& .MuiInputAdornment-root': {
              m: 0,
            },
            '& .MuiSvgIcon-root': {
              fontSize: '20px',
            },
            '& .Mui-disabled .MuiInputAdornment-root': {
              display: 'none',
            },
            '& .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.stationGray[200],
            },
          }),
          ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
        ]}
      />
    </LocalizationProvider>
  );
};
