import { useDocumentTitle, CAPABILITIES } from '@stationwise/component-module';
import { AppPageLayout } from '../../AppPageLayout';
import { PayrollMain } from './components/PayrollMain';

export const Payroll = () => {
  useDocumentTitle('Payroll');
  return (
    <AppPageLayout requiredCapability={CAPABILITIES.VIEW_PAYROLL}>
      {' '}
      <PayrollMain />{' '}
    </AppPageLayout>
  );
};
