import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Certification } from '@stationwise/share-types';
import { getCertColors } from '../../utils';

interface Props {
  cert: Pick<Certification, 'code' | 'color'>;
  dark?: boolean;
  size?: 'md' | 'lg';
  sx?: SxProps<Theme>;
}

export const CertBadge = (props: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const defaultSize = isMobile ? 'lg' : 'md';
  const { cert, dark = false, size = defaultSize, sx } = props;
  const colors = getCertColors({ theme, color: cert.color, dark });

  return (
    <Box
      sx={[
        {
          ...colors,
          borderRadius: '9999px',
          boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
          display: 'inline-flex',
          padding: '0 6px',
          typography: size === 'lg' ? 'bodyMRegular' : 'bodyXSRegular',
          textWrap: 'nowrap',
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {cert.code}
    </Box>
  );
};
