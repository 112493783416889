import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, TextField, Theme, Typography, useMediaQuery } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { addDays, endOfDay, format, isAfter, min } from 'date-fns';
import { useEffect, useState } from 'react';
import { client, isAxiosError } from '@stationwise/share-api';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { Button } from '../../Button';
import { ErrorBanner } from '../../ErrorBanner';
import { GenericDrawer } from '../../GenericDrawer';
import { RequestCardRequest, REQUEST_CARD_ACTION } from './types';

export interface ActiveAction {
  status: REQUEST_CARD_ACTION | null;
  postponedUntil?: Date | null;
}

interface ActionModalProps {
  activeAction: ActiveAction;
  onClose: () => void;
  onConfirm: (action: REQUEST_CARD_ACTION, request: RequestCardRequest) => void;
  onChangePostponedUntil: (value: Date | null) => void;
  request: RequestCardRequest;
  isNoteRequired?: boolean;
}

type CommonModalProps = {
  isMobile: boolean;
  title: string;
  subtitle: string;
  props: ActionModalProps;
  status: REQUEST_CARD_ACTION | null;
  reason: string;
  apiAction: string;
  setReason: (value: string) => void;
  canSubmit: boolean;
  errors: Record<string, string>;
  setErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
};

const CommonModal: React.FC<CommonModalProps> = ({
  isMobile,
  title,
  props,
  status,
  reason,
  apiAction,
  setReason,
  canSubmit,
  subtitle,
  errors,
  setErrors,
}) => {
  const isApprove = status === REQUEST_CARD_ACTION.APPROVE;
  const isDeny = status === REQUEST_CARD_ACTION.DENY;
  const isPostpone = status === REQUEST_CARD_ACTION.POSTPONE;

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (status) {
      setReason('');
      setErrors({});
    }
  }, [status, setReason, setErrors]);

  const assignErrors = (newErrors: Record<string, string>) => {
    setErrors((oldErrors) => ({ ...oldErrors, ...newErrors }));
  };

  const dayBeforeDeadline = addDays(new Date(props.request.expiresAt), -1);
  const withinTwoWeeks = endOfDay(addDays(new Date(), 14));

  const onChangePostponedUntil = (value: Date | null) => {
    if (value && value < new Date()) {
      assignErrors({ postponedUntil: 'This time cannot be in the past.' });
    } else if (value && isAfter(value, dayBeforeDeadline)) {
      assignErrors({
        postponedUntil: 'This time is too close to the deadline.',
      });
    } else if (value && isAfter(value, withinTwoWeeks)) {
      assignErrors({
        postponedUntil: 'This time must be within 2 weeks of today.',
      });
    } else {
      assignErrors({ postponedUntil: '' });
    }

    props.onChangePostponedUntil(value);
  };

  const onConfirm = async () => {
    if (isSubmitting) {
      return;
    }

    const { requestType, id } = props.request;
    let url = '';
    if (requestType === 'ShiftTradeRequest') {
      url = `/request/shift-trade-request/${id}/${apiAction}/`;
    } else if (requestType === 'TimeOffRequest') {
      url = `/request/time-off-request/${id}/${apiAction}/`;
    } else if (requestType === 'AdditionalPaidTimeRequest') {
      url = `/request/additional-paid-time-request/${id}/${apiAction}/`;
    } else if (requestType === 'CompTimeRequest') {
      url = `/request/comp-time-request/${id}/${apiAction}/`;
    }
    if (!status || !apiAction || !url) {
      throw new Error('Cannot confirm review without action');
    }

    try {
      setIsSubmitting(true);
      setErrors({});

      const response = await client.put<RequestCardRequest>(url, {
        reasonResponse: reason.trim() || undefined,
        postponedUntil: props.activeAction.postponedUntil && format(props.activeAction.postponedUntil, "yyyy-MM-dd'T'HH:mm"),
      });
      setIsSubmitting(false);
      props.onClose();
      props.onConfirm(status, response.data);
    } catch (err) {
      setIsSubmitting(false);

      const data = isAxiosError(err) && err.response?.data;
      if (data && typeof data === 'object') {
        const getErrMessage = (key: string) => {
          return Array.isArray(data[key]) ? data[key].map(String).join(' ') : String(data[key] || '');
        };
        setErrors({
          status: getErrMessage('status'),
          reason: getErrMessage('reasonResponse'),
          postponedUntil: getErrMessage('postponedUntil'),
        });
      }
    }
  };

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.common.white,
        borderRadius: theme.spacing(1.5),
        padding: isMobile ? theme.spacing(0, 2, 3, 2) : theme.spacing(3),
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: isMobile ? 'auto' : '490px',
          maxWidth: '100%',
        }}
      >
        {!isMobile && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
                typography: 'bodyXLSemibold',
              })}
            >
              {title}
            </Box>
            <Box
              sx={{
                mt: '-12px',
                mr: '-12px',
                cursor: 'pointer',
                borderRadius: '50%',
              }}
              onClick={props.onClose}
            >
              <CloseIcon
                sx={(theme) => ({
                  height: '24px',
                  width: '24px',
                  color: theme.palette.stationGray[400],
                  borderRadius: '50%',
                  '&:hover': {
                    background: theme.palette.stationGray[100],
                  },
                })}
              />
            </Box>
          </Box>
        )}
        <Box
          sx={(theme) => ({
            color: theme.palette.stationGray[700],
            m: isMobile ? theme.spacing(0, 0, 4, 0) : theme.spacing(1, 0, 5, 0),
            typography: 'bodyMRegular',
          })}
        >
          <Typography sx={{ typography: 'bodyMRegular' }}>{subtitle}</Typography>
        </Box>
        {isPostpone && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDateTimePicker
              ampm={false}
              closeOnSelect={false}
              disablePast={true}
              maxDateTime={min([withinTwoWeeks, dayBeforeDeadline])}
              slotProps={{
                textField: {
                  helperText: errors['postponedUntil'],
                  error: !!errors['postponedUntil'],
                },
              }}
              value={props.activeAction.postponedUntil}
              onChange={onChangePostponedUntil}
              sx={(theme) => ({ mb: theme.spacing(3) })}
            />
          </LocalizationProvider>
        )}
        <Box sx={(theme) => ({ mb: theme.spacing(0.5), typography: 'bodySMedium' })}>
          {isApprove && !props.isNoteRequired ? 'Note (optional)' : 'Note (required)'}
        </Box>
        <Box>
          <TextField
            data-cy="deny-request-input"
            fullWidth
            minRows={3}
            multiline
            maxRows={3}
            error={!!errors['reason']}
            helperText={errors['reason']}
            value={reason}
            onChange={(e) => {
              assignErrors({ reason: '' });
              setReason(e.target.value);
            }}
            sx={{
              '.MuiInputBase-root': {
                padding: 0,
              },
            }}
          />
        </Box>
        {errors['status'] && <ErrorBanner sx={{ mt: 5 }}>{errors['status']}</ErrorBanner>}

        {!isMobile && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'flex-end',
              gap: theme.spacing(1.5),
              mt: 5,
            })}
          >
            <Button data-cy="cancel-button" onClick={props.onClose} buttonType="tertiary">
              Cancel
            </Button>
            <Button
              data-cy={`${makeTestIdentifier(title)}-button`}
              onClick={onConfirm}
              buttonType={isDeny ? 'danger' : 'primary'}
              disabled={!canSubmit || isSubmitting || (props.isNoteRequired && reason.length === 0)}
            >
              {title}
            </Button>
          </Box>
        )}

        {isMobile && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: theme.spacing(2),
              mt: theme.spacing(4),
            })}
          >
            <Box
              onClick={props.onClose}
              sx={(theme) => ({
                display: 'flex',
                width: '100%',
                height: '44px',
                padding: '9px 17px',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: '6px',
                border: '1px solid',
                borderColor: theme.palette.stationGray[300],
              })}
            >
              <Typography sx={{ typography: 'bodyMMedium' }}>Cancel</Typography>
            </Box>
            <Box
              onClick={() => {
                if (!(isSubmitting || !canSubmit || (props.isNoteRequired && reason.length === 0))) {
                  onConfirm();
                }
              }}
              sx={(theme) => ({
                display: 'flex',
                width: '100%',
                height: '44px',
                padding: '9px 17px',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
                borderRadius: '6px',
                border: '1px solid',
                borderColor: theme.palette.stationGray[300],
                color: theme.palette.common.white,
                backgroundColor:
                  !canSubmit || isSubmitting || (props.isNoteRequired && reason.length === 0)
                    ? theme.palette.stationGray[300]
                    : isDeny
                      ? theme.palette.stationRose[600]
                      : theme.palette.common.black,
              })}
            >
              <Typography sx={{ typography: 'bodyMMedium' }}>{title}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const ActionModal = (props: ActionModalProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isPostpone = props.activeAction.status === REQUEST_CARD_ACTION.POSTPONE;
  const isApprove = props.activeAction.status === REQUEST_CARD_ACTION.APPROVE;
  const isDeny = props.activeAction.status === REQUEST_CARD_ACTION.DENY;
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [reason, setReason] = useState('');

  let title = '';
  let subtitle = '';
  let canSubmit = true;
  let apiAction = '';

  if (isApprove) {
    title = 'Approve request';
    subtitle = 'You might send additional information to your employee';
    apiAction = 'approve';
  } else if (isDeny) {
    title = 'Deny request';
    subtitle = 'State the reason for denying this request';
    canSubmit = !!reason.trim();
    apiAction = 'deny';
  } else if (isPostpone) {
    title = 'Postpone request';
    subtitle = 'Set up time to be notified about this request';
    canSubmit = !!(reason.trim() && props.activeAction.postponedUntil && !errors['postponedUntil']);
    apiAction = 'postpone';
  }

  return isMobile ? (
    <GenericDrawer
      anchor="bottom"
      drawerOpen={!!props.activeAction.status}
      handleOnClose={props.onClose}
      loading={false}
      showHeader={true}
      disableFooter={true}
      headerTitle={title}
      noBorderOnHeader={true}
      sxProps={{
        '.MuiDrawer-paper': {
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
        },
      }}
    >
      <CommonModal
        isMobile={true}
        title={title}
        props={props}
        status={props.activeAction.status}
        setReason={setReason}
        reason={reason}
        apiAction={apiAction}
        canSubmit={canSubmit}
        subtitle={subtitle}
        errors={errors}
        setErrors={setErrors}
      />
    </GenericDrawer>
  ) : (
    <Modal
      open={!!props.activeAction.status}
      onClose={props.onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box>
        <CommonModal
          isMobile={false}
          title={title}
          props={props}
          status={props.activeAction.status}
          setReason={setReason}
          reason={reason}
          apiAction={apiAction}
          canSubmit={canSubmit}
          subtitle={subtitle}
          errors={errors}
          setErrors={setErrors}
        />
      </Box>
    </Modal>
  );
};
