import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { addHours, isSameDay, subHours } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { TimePicker, CalendarShiftCard, useLoadedDepartmentInfoContext, SHIFT_TITLES } from '@stationwise/component-module';
import { PayCode } from '@stationwise/share-types';
import { snakeCaseToTitle } from '@stationwise/share-utils';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';
import { AdditionalPaidTimeDropDown } from './AdditionalPaidTimeDropDown';
import { AdditionalPaidTimeDuration } from './AdditionalPaidTimeDuration';
import { AdditionalPaidTimeFooter } from './AdditionalPaidTimeFooter';
import { AdditionalPaidTimeSelect } from './AdditionalPaidTimeSelect';
import { TypeOfAdditionalPaidTime } from './TypeOfAdditionalPaidTime';

export const ADDITIONAL_PAID_TIME_OPTIONS = {
  ARRIVED_EARLY: 'ARRIVED_EARLY',
  HELD_OVER: 'HELD_OVER',
  MANUAL_ENTRY: 'MANUAL_ENTRY',
  INCIDENT: 'INCIDENT',
};

interface TimeRange {
  start: Date;
  end: Date;
}

interface ContentProps {
  handleAdditionalPaidTimeRequest: (responseMessage: string, error: boolean) => void;
  shift: EventInput | EventImpl;
  forceManual?: boolean;
  handleEventTypeOpen: () => void;
  eventType: string | null;
  handleSelectPayCodeOpen: () => void;
  payCode: PayCode | null;
}

export const AdditionalPaidTimeContent = ({
  shift,
  forceManual,
  handleAdditionalPaidTimeRequest,
  handleEventTypeOpen,
  eventType,
  handleSelectPayCodeOpen,
  payCode,
}: ContentProps) => {
  const [selectedButton, setSelectedButton] = useState<string>(() => {
    if (forceManual) {
      return ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY;
    } else if (shift.title?.includes(SHIFT_TITLES.INCIDENT)) {
      return ADDITIONAL_PAID_TIME_OPTIONS.INCIDENT;
    }
    return '';
  });
  const [note, setNote] = useState<string>('');
  const [openTimeSelect, setOpenTimeSelect] = useState<boolean>(false);
  const [time, setTime] = useState<Date>(new Date('2022-04-17T00:15'));
  const [manualTime, setManualTime] = useState<Date>(new Date(shift.start as string));
  const [endTime, setEndTime] = useState<Date>(new Date(shift.end as string));

  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();
  const isIncidentAPT = selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.INCIDENT;
  const showTypeSelect = !forceManual && !isIncidentAPT;

  const [additionalPaidTime, setAdditionalPaidTime] = useState<TimeRange>({
    start: new Date(shift.start as string),
    end: new Date(shift.start as string),
  });

  useEffect(() => {
    prepareShiftTime(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedButton, manualTime, endTime]);

  const handleRequest = (responseMessage: string, error: boolean) => {
    handleAdditionalPaidTimeRequest(responseMessage, error);
  };

  const handleButtonClick = (buttonTag: string) => {
    setSelectedButton(buttonTag);
  };

  const handleManualTime = (value: Date) => {
    setManualTime(value);
  };

  const handleEndTime = (value: Date) => {
    setEndTime(value);
  };

  const handleTime = (value: Date) => {
    setOpenTimeSelect(false);
    setTime(value);
    prepareShiftTime(value);
  };

  const openTimeSelectDrawer = () => {
    setOpenTimeSelect(!openTimeSelect);
  };

  const prepareShiftTime = (selectedTime: Date) => {
    const hours = selectedTime?.getHours();
    const minutes = selectedTime?.getMinutes();

    if (selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.ARRIVED_EARLY) {
      const additionalTime = new Date(shift.start as string);
      const shiftStart = new Date(shift.start as string);
      additionalTime.setHours(shiftStart.getHours() - hours);
      additionalTime.setMinutes(shiftStart.getMinutes() - minutes);
      setAdditionalPaidTime({
        start: additionalTime,
        end: shiftStart,
      });
    } else if (selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.HELD_OVER) {
      const additionalTime = new Date(shift.end as string);
      const shiftEnd = new Date(shift.end as string);
      additionalTime.setHours(shiftEnd.getHours() + hours);
      additionalTime.setMinutes(shiftEnd.getMinutes() + minutes);
      setAdditionalPaidTime({
        start: shiftEnd,
        end: additionalTime,
      });
    } else if (
      selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY ||
      selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.INCIDENT
    ) {
      setAdditionalPaidTime({
        start: manualTime,
        end: endTime,
      });
    }
  };

  const iosWebViewPaddingBottom = useIOSPaddingBottom();

  return (
    <>
      {selectedButton !== ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY && <CalendarShiftCard shift={shift} />}

      {showTypeSelect && <TypeOfAdditionalPaidTime selectedButton={selectedButton} handleButtonClick={handleButtonClick} />}

      {selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY ||
      selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.INCIDENT ? (
        <>
          {/* START TIME */}
          <AdditionalPaidTimeSelect
            handleTime={handleManualTime}
            label={(selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.INCIDENT && 'Time Out') || undefined}
            manualTime={manualTime}
            shift={shift}
            usePreciseMinutes={departmentInfo.settings.additionalPaidTimeUsePreciseMinutes}
            minTime={(() => {
              if (selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY) {
                return new Date(new Date(shift.start as string).setHours(0, 0, 0, 0));
              }
              if (isSameDay(shift.start as string, manualTime)) {
                return new Date(shift.start as string);
              }
              return;
            })()}
            maxTime={(() => {
              if (selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY) {
                return addHours(new Date(shift.start as string), 24);
              }
              if (isSameDay(shift.end as string, manualTime)) {
                return new Date(shift.end as string);
              }
              if (isSameDay(endTime, manualTime)) {
                return endTime;
              }
              return;
            })()}
          />

          {/* END TIME */}
          <AdditionalPaidTimeSelect
            handleTime={handleEndTime}
            label={selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.INCIDENT ? 'Time Available' : 'Additional paid time end'}
            manualTime={endTime}
            shift={shift}
            usePreciseMinutes={departmentInfo.settings.additionalPaidTimeUsePreciseMinutes}
            minTime={(() => {
              if (selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY) {
                return subHours(new Date(shift.end as string), 24);
              }
              return manualTime;
            })()}
            maxTime={(() => {
              if (selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY) {
                if (shift.end && shift.start && shift.end > shift.start) {
                  return new Date(new Date(shift.end as string).setHours(23, 59, 59, 0));
                }

                return;
              }
              if (isSameDay(shift.end as string, endTime)) {
                return new Date(shift.end as string);
              }
              return;
            })()}
          />

          <AdditionalPaidTimeDuration
            additionalPaidTime={additionalPaidTime}
            header={(selectedButton === ADDITIONAL_PAID_TIME_OPTIONS.INCIDENT && 'Hours on the Call') || 'Duration'}
            openTimeSelectDrawer={openTimeSelectDrawer}
            time={time}
            disabled
          />

          <AdditionalPaidTimeDropDown
            header="Event type"
            openEventTypeDrawer={handleEventTypeOpen}
            option={snakeCaseToTitle(eventType || '')}
            placeholder="Select event type"
          />
          <AdditionalPaidTimeDropDown
            header="Pay code"
            openEventTypeDrawer={handleSelectPayCodeOpen}
            option={payCode}
            placeholder="Select pay code"
            disabled={!eventType}
            code={payCode?.code}
          />
        </>
      ) : (
        <AdditionalPaidTimeDuration header="Additional paid time" openTimeSelectDrawer={openTimeSelectDrawer} time={time} />
      )}

      <TimePicker
        openTimeSelect={openTimeSelect}
        setOpenTimeSelect={openTimeSelectDrawer}
        handleTime={handleTime}
        paddingBottom={iosWebViewPaddingBottom}
        maxTime={selectedButton !== ADDITIONAL_PAID_TIME_OPTIONS.MANUAL_ENTRY ? new Date(new Date().setHours(6, 0)) : null}
        timeSteps={departmentInfo.settings.additionalPaidTimeUsePreciseMinutes ? { hours: 1, minutes: 1 } : undefined}
      />
      {selectedButton && !openTimeSelect && (
        <>
          <Box
            sx={(theme) => ({
              display: 'block',
              width: '100%',
              marginTop: theme.spacing(3),
              pb: theme.spacing(20),
            })}
          >
            <Box
              sx={(theme) => ({
                marginBottom: theme.spacing(2),
                px: theme.spacing(2),
                typography: 'bodyLMedium',
              })}
            >
              {isIncidentAPT ? 'Note' : 'Note (required)'}
            </Box>
            <TextField
              data-cy="apt-note"
              value={note}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNote(event.target.value);
              }}
              sx={(theme) => ({
                width: '100%',
                px: theme.spacing(2),
              })}
              multiline
              rows={3}
            />
          </Box>
          <AdditionalPaidTimeFooter
            note={note}
            additionalPaidTime={additionalPaidTime}
            handleAdditionalPaidTimeRequest={handleRequest}
            eventType={eventType}
            payCode={payCode}
            selectedButton={selectedButton}
          />
        </>
      )}
    </>
  );
};
