import { Theme } from '@mui/material';
import { DepartmentInfo } from '../components/Department/context/DepartmentInfo';

export const checkIsSupportedDate = (date: Date) => {
  return date.getFullYear() === new Date().getFullYear();
};
export const BANK_HOURS_THRESHOLD = 12;

export const getTooltipProps = (theme: Theme) => ({
  tooltip: {
    sx: {
      backgroundColor: theme.palette.stationGray[800],
      borderRadius: 2,
      p: '10px 12px',
      typography: 'bodySMedium',
      '& .MuiTooltip-arrow': { color: theme.palette.stationGray[800] },
    },
  },
});
export const getVisibleTags = (departmentInfo: DepartmentInfo | null, place: string, contentType: string) => {
  if (!departmentInfo) {
    return [];
  }
  return (
    departmentInfo.visibilityCustomizations.find(
      (customization) => customization.place === place && customization.contentType === contentType,
    )?.tags || []
  );
};
