import { Box, Skeleton } from '@mui/material';
import React from 'react';

const MAX_MESSAGE_LENGTH = 50;
export interface MessageProps {
  subject: string;
  message: string;
  isLoading?: boolean;
}

export const Conversation = ({ subject, message, isLoading }: MessageProps) => {
  if (isLoading) {
    return (
      <Box display="flex" gap={0.5} flexDirection="column">
        <Skeleton variant="text" width={240} height={20} />
        <Skeleton variant="text" width={280} height={20} />
      </Box>
    );
  }

  const isTextBiggerThanAllowed = message.length > MAX_MESSAGE_LENGTH;

  return (
    <Box sx={{ display: 'flex', gap: 0.5, flexDirection: 'column', typography: 'bodySRegular' }}>
      <Box sx={(theme) => ({ color: theme.palette.stationGray[900], fontWeight: 600 })}>{subject}</Box>
      <Box sx={(theme) => ({ color: theme.palette.stationGray[600] })}>
        {`${message.slice(0, MAX_MESSAGE_LENGTH)} ${isTextBiggerThanAllowed ? '...' : ''}`}
      </Box>
    </Box>
  );
};
