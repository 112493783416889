import { Box } from '@mui/material';
import { format } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { RequestDurationOptions } from '@stationwise/component-module';
import { differenceInUTCMinutes, generateStartTimes } from '@stationwise/share-utils';
import { StartTimes } from './StartTimes';

export const getShiftTradeDuration = (startDateTime: Date, endDateTime: Date) => {
  const durationMinutes = differenceInUTCMinutes(endDateTime, startDateTime);
  return 4 * Math.floor(durationMinutes / 60 / 4);
};

interface ShiftTradeOptionsProps {
  originalStartDateTime: Date;
  startDateTime: Date;
  setStartDateTime: Dispatch<SetStateAction<Date>>;
  duration: number;
  setDuration: Dispatch<SetStateAction<number>>;
  originalEndDateTime: Date;
}

export const ShiftTradeOptions = ({
  originalStartDateTime,
  startDateTime,
  setStartDateTime,
  originalEndDateTime,
  duration,
  setDuration,
}: ShiftTradeOptionsProps) => {
  const originalStartTime = format(originalStartDateTime, 'HH:mm');
  const originalDuration = getShiftTradeDuration(originalStartDateTime, originalEndDateTime);
  let startOptions = generateStartTimes(originalStartTime, originalDuration);
  if (originalDuration === 0) {
    startOptions = [{ label: originalStartTime, value: originalStartTime }];
  }

  const durationOptions = [
    { label: '4 hrs', value: 4 },
    { label: '8 hrs', value: 8 },
    { label: '12 hrs', value: 12 },
    { label: '16 hrs', value: 16 },
    { label: '20 hrs', value: 20 },
    { label: 'Entire Shift', value: 24 },
  ];

  return (
    <Box>
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          typography: 'bodyLMedium',
        })}
      >
        Shift trade start
      </Box>
      <StartTimes
        startDateTime={startDateTime}
        setStartDateTime={setStartDateTime}
        originalStartDateTime={originalStartDateTime}
        startOptions={startOptions}
        setDuration={setDuration}
      />
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          mt: theme.spacing(3),
          typography: 'bodyLMedium',
        })}
      >
        Shift trade duration
      </Box>
      <RequestDurationOptions
        durationOptions={durationOptions}
        startDateTime={startDateTime.toISOString()}
        duration={duration}
        setDuration={setDuration}
        originalEndDateTime={originalEndDateTime.toISOString()}
        originalStartDateTime={originalStartDateTime.toISOString()}
      />
    </Box>
  );
};
