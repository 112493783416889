import { Box, ButtonGroup, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { GenericDrawer, SearchInput } from '@stationwise/component-module';
import { PayCode } from '@stationwise/share-types';
import { makeTestIdentifier, snakeCaseToTitle } from '@stationwise/share-utils';

interface EventTypeSelectProps {
  handleOnClose: () => void;
  drawerOpen: boolean;
  options: string[];
  setEventType: React.Dispatch<React.SetStateAction<string | null>>;
  setPayCode: React.Dispatch<React.SetStateAction<PayCode | null>>;
}

export const EventTypeSelect = ({ handleOnClose, drawerOpen, options, setEventType, setPayCode }: EventTypeSelectProps) => {
  const [searchText, setSearchText] = useState('');
  const onClose = () => {
    setSearchText('');
    handleOnClose();
  };
  const handleChange = (value: string) => {
    setEventType(value);
    setPayCode(null);
    onClose();
  };
  const filteredOptions = options.filter((option) => option.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <GenericDrawer
      anchor="bottom"
      drawerOpen={drawerOpen}
      handleOnClose={onClose}
      loading={false}
      showHeader={true}
      disableFooter={true}
      headerTitle={'Event Type'}
      sxProps={{
        '.MuiDrawer-paper': {
          borderRadius: '0px',
        },
      }}
    >
      <>
        <Box
          sx={(theme) => ({
            p: theme.spacing(2),
            pb: 0,
          })}
        >
          <SearchInput value={searchText} setValue={setSearchText} color={'white'} />
        </Box>
        <Box sx={{ mt: '8px', height: `calc(10vh * ${options.length})`, display: 'flex', flexDirection: 'column' }}>
          <ButtonGroup orientation="vertical" fullWidth sx={(theme) => ({ gap: theme.spacing(1.5) })}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <Button
                  data-cy={`event-type-${makeTestIdentifier(option)}`}
                  sx={(theme) => ({
                    height: theme.spacing(5),
                    backgroundColor: theme.palette.common.white,
                    border: 'none',
                    boxShadow: 'none',
                    marginBottom: theme.spacing(1),
                    '&.MuiButton-outlined': {
                      color: '#EEF2F8',
                      border: 'none',
                    },
                    '&:hover': {
                      backgroundColor: '#EEF2F8',
                      border: 'none',
                    },
                  })}
                  key={option as string}
                  variant={'outlined'}
                  onClick={() => handleChange(option)}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={(theme) => ({
                      borderRadius: theme.spacing(1),
                      textTransform: 'none',
                      p: '10px 13px 10px',
                      width: '100%',
                      justifyContent: 'space-between',
                    })}
                  >
                    <Typography variant="bodyLRegular" sx={(theme) => ({ color: theme.palette.stationGray[900] })}>
                      {snakeCaseToTitle(option)}
                    </Typography>
                  </Box>
                </Button>
              ))
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                sx={(theme) => ({
                  height: theme.spacing(5),
                  p: '10px 13px 10px',
                  width: '100%',
                  justifyContent: 'space-between',
                  typography: 'bodyLRegular',
                  color: theme.palette.stationGray[500],
                })}
              >
                No results found
              </Box>
            )}
          </ButtonGroup>
        </Box>
      </>
    </GenericDrawer>
  );
};
