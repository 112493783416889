import { EventInput } from '@fullcalendar/core';
import { ScheduledShiftsDataView } from '@stationwise/share-types';

export const scheduledShiftsToEventInput = (events: ScheduledShiftsDataView[]): EventInput[] => {
  const mapped: EventInput[] = [];

  events.forEach((ev) => {
    mapped.push({
      backgroundColor: ev.teamColor,
      title: ev.shiftName.split(' ')[0],
      id: ev.id,
      display: 'list-item',
      start: new Date(ev.startDate + 'T' + ev.startTime),
      end: new Date(ev.endDate + 'T' + ev.endTime),
      extendedProps: {
        vacancies: ev.vacancies,
        floaters: ev.floaters,
        employeesOff: ev.employeesOff,
      },
    });
  });

  return mapped;
};
