import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { AllowedColors, Certification } from '@stationwise/share-types';
import { Badge } from '../Badge';
import { BlankCard } from '../BlankCard';
import { getEmptyCardColor } from '../EmptyCard';
import { ActingAsIndicator } from './ActingAsIndicator';
import { CardCertBadges } from './CardCertBadges';
import { DriverIndicator } from './DriverIndicator';
import { ShiftDurationIndicator } from './ShiftDurationIndicator';

export interface CardProps {
  badgeTitle: string;
  certs: Pick<Certification, 'name' | 'code' | 'color'>[];
  color?: AllowedColors;
  durationLabel: string;
  nameLabel: string;
  borderColor?: AllowedColors;
  type?: 'default' | 'disable' | 'border';
  isDriver?: boolean;
  actingAs?: string;
  duration: number;
  indicators?: ReactNode;
  cardSxProps?: SxProps<Theme>;
  actions?: React.ReactNode;
}

export const Card = ({
  badgeTitle,
  color = 'orange',
  durationLabel,
  nameLabel,
  certs,
  borderColor = 'gold',
  type = 'default',
  isDriver = false,
  actingAs,
  duration,
  indicators,
  cardSxProps,
  actions,
}: CardProps) => {
  const theme = useTheme();
  const colors = getEmptyCardColor(theme, { color });

  return (
    <BlankCard
      type={type}
      borderColor={borderColor}
      sxProps={[
        { justifyContent: 'space-between', position: 'relative' },
        ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : []),
      ]}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="space-between">
          {isDriver && <DriverIndicator sx={{ mr: 0.5 }} />}
          {actingAs && <ActingAsIndicator actingAs={actingAs} color={color} sx={{ mr: 0.75 }} />}
          {badgeTitle && (
            <Badge
              label={badgeTitle}
              isRound
              type="sm"
              color="orange"
              isBold
              noPadding
              isCustom
              customBackgroundColor={colors.badgeBackgroundColor}
              customColor={colors.badgeColor}
            />
          )}
        </Box>
        <ShiftDurationIndicator duration={duration} durationLabel={durationLabel} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          color: theme.palette.stationGray[900],
          typography: 'bodyLMedium',
          [theme.breakpoints.up('sm')]: { typography: 'bodySMedium' },
        }}
      >
        {nameLabel}
      </Box>
      <Box sx={{ display: 'flex' }}>
        <CardCertBadges certs={certs} />
        <Box sx={{ flex: 1 }} />
        {indicators}
        {actions && (
          <Box
            sx={(theme) => ({
              color: theme.palette.stationGray[600],
              minWidth: '24px',
              position: 'relative',
              typography: 'bodySSemibold',
              lineHeight: '14px',
              [theme.breakpoints.up('sm')]: { typography: 'buttonS', fontWeight: 400 },
            })}
          >
            {actions}
          </Box>
        )}
      </Box>
    </BlankCard>
  );
};
