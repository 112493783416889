import { useParams } from 'react-router-dom';
import { AutoHireSignUpMain } from './components/AutoHireSignUpMain';

export const AutoHireSignUp = () => {
  const { autoHireId } = useParams();

  return (
    <AutoHireSignUpMain
      getAutoHireSignUpDTO={{
        autoHireId: autoHireId || '',
      }}
    />
  );
};
