import { captureException } from '@sentry/react';
import { client } from '@stationwise/share-api';
import { SnackbarService } from '../components/Snackbar/SnackbarService';

export const exportData = async (url: string, params = {}, defaultFilename = 'download') => {
  try {
    const response = await client.get(url, {
      params: params,
      responseType: 'blob',
    });

    if (response.status === 204) {
      SnackbarService.notify({
        content: 'No data to export',
        duration: 5000,
        severity: 'error',
      });
      return;
    }

    const contentDisposition = response.headers['content-disposition'];
    let filename = defaultFilename;
    if (contentDisposition && contentDisposition.includes('filename=')) {
      filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
    }

    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    captureException(error);
  }
};
