import { Box } from '@mui/material';
import { useState, Dispatch, SetStateAction } from 'react';
import {
  ToDoListIcon16,
  MedicalCrossIcon16 as EMSIcon,
  Mail02Icon16,
  XCircleIcon20,
  SuccessIcon,
  CheckCircleBrokenIcon16,
  StandIcon16,
  REQUEST_CARD_STATUS,
  Requests,
  NextShiftFetcher,
  RequestsFetchersByStatus,
  RequestsFilterChips,
} from '@stationwise/component-module';
import { ConversationListView } from '@stationwise/share-types';
import { Conversations } from './Conversations';
import { Header } from './Header';
import { PaneContentWrapper } from './PaneContentWrapper';
import { PaneSelector } from './PaneSelector';
import { HEADER_OPTIONS } from './constants';

const chipFiltersRequests: RequestsFilterChips[] = [
  {
    label: 'Unresolved',
    icon: () => (
      <Box
        sx={{
          mt: '8px',
        }}
      >
        <ToDoListIcon16 />
      </Box>
    ),
    filterName: REQUEST_CARD_STATUS.PENDING,
  },
  {
    label: 'Approved',
    icon: SuccessIcon,
    filterName: REQUEST_CARD_STATUS.APPROVED,
  },
  {
    label: 'Denied',
    icon: XCircleIcon20,
    filterName: REQUEST_CARD_STATUS.DENIED,
  },
];

const chipFiltersMessages = [
  {
    label: 'All',
    icon: null,
    filterName: null,
  },
  {
    label: 'General',
    icon: Mail02Icon16,
    filterName: 'General',
  },
  {
    label: 'To-Do',
    icon: CheckCircleBrokenIcon16,
    filterName: 'To-Do',
  },
  {
    label: 'EMS',
    icon: EMSIcon,
    filterName: 'EMS',
  },
  {
    label: 'Training',
    icon: StandIcon16,
    filterName: 'Training',
  },
];

export interface MessagesInboxProps {
  conversations: ConversationListView[];
  isLoading: boolean;
  requests: RequestsFetchersByStatus;
  nextShiftFetcher: NextShiftFetcher;
  onComposeClick: () => void;
  onConversationClick: (id: number) => void;
  onCancelConversation?: (id: number) => void;
  setRefetchEvents: (refetch: boolean) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const MessagesInbox = ({
  conversations,
  isLoading,
  requests,
  nextShiftFetcher,
  onComposeClick,
  onConversationClick,
  onCancelConversation,
  setRefetchEvents,
  setOpen,
}: MessagesInboxProps) => {
  const [selectedPane, setSelectedPane] = useState(0);

  const handleClickHeader = (index: number) => {
    setSelectedPane(index);
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        backgroundColor: theme.palette.stationGray[50],
      })}
    >
      <Box sx={{ height: '70px' }}>
        <Header
          options={HEADER_OPTIONS}
          selectedOption={selectedPane}
          onClick={handleClickHeader}
          showCompose={HEADER_OPTIONS[selectedPane].label === 'Messages'}
          onComposeClick={onComposeClick}
          setOpen={setOpen}
        />
      </Box>
      <Box sx={{ display: 'flex', height: 'calc(100% - 70px)' }}>
        <PaneSelector activePaneId={HEADER_OPTIONS[selectedPane].label}>
          <PaneContentWrapper paneId={HEADER_OPTIONS[0].label}>
            <Requests
              filterChips={chipFiltersRequests}
              requests={requests}
              nextShiftFetcher={nextShiftFetcher}
              setRefetchEvents={setRefetchEvents}
            />
          </PaneContentWrapper>
          <PaneContentWrapper paneId={HEADER_OPTIONS[1].label}>
            <Conversations
              conversations={conversations}
              filterChips={chipFiltersMessages}
              isLoading={isLoading}
              onConversationClick={onConversationClick}
              onCancelConversation={onCancelConversation}
            />
          </PaneContentWrapper>
        </PaneSelector>
      </Box>
    </Box>
  );
};
