import { useState } from 'react';
import { EmployeeOffView } from '@stationwise/share-types';

export const useEmployeesOff = (employeesOff: EmployeeOffView[]) => {
  const [timeOffIdsToCancel, setTimeOffIdsToCancel] = useState<number[]>([]);
  const [temporaryNonShiftIdsToCancel, setTemporaryNonShiftIdsToCancel] = useState<number[]>([]);

  const resetState = () => {
    setTimeOffIdsToCancel([]);
    setTemporaryNonShiftIdsToCancel([]);
  };

  const setTimeOffToCancel = (id: number) => setTimeOffIdsToCancel([...timeOffIdsToCancel, id]);

  const setTemporaryNonShiftToCancel = (id: number) => setTemporaryNonShiftIdsToCancel([...temporaryNonShiftIdsToCancel, id]);

  return {
    employeesOff,
    timeOffIdsToCancel,
    temporaryNonShiftIdsToCancel,
    setTimeOffToCancel,
    setTemporaryNonShiftToCancel,
    resetState,
  };
};
