import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { CodeAutocomplete } from '@stationwise/component-module';
import { EmployeeOffView } from '@stationwise/share-types';
import { noop } from '@stationwise/share-utils';

interface Props {
  employeeOffData: EmployeeOffView;
}

export const NonShiftDetails = ({ employeeOffData }: Props) => {
  const title = employeeOffData.title.charAt(0).toUpperCase() + employeeOffData.title.slice(1).toLowerCase();

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.stationGray[800],
        borderRadius: 2,
        p: theme.spacing(1.5, 1),
        mb: 2,
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: theme.palette.common.white,
          typography: 'bodySRegular',
          mb: 2,
        })}
      >
        <Box>{`${title} details`}</Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: theme.palette.common.white,
          typography: 'bodySRegular',
          mb: 2,
        })}
      >
        <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>Start Date</Box>
        <Box display="flex" gap={3}>
          <Box>{format(parseISO(employeeOffData.activationDate), 'MMM d, yyyy')}</Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: theme.palette.common.white,
          typography: 'bodySRegular',
          mb: 2,
        })}
      >
        <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>End Date</Box>
        <Box display="flex" gap={3}>
          <Box>{format(parseISO(employeeOffData.deactivationDate), 'MMM d, yyyy')}</Box>
        </Box>
      </Box>

      {employeeOffData.payCodes.map((payCode) => (
        <CodeAutocomplete key={payCode.id} label="Pay Code" handleChange={noop} options={[]} value={payCode} isDisabled={true} />
      ))}
    </Box>
  );
};
