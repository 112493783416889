import React from 'react';
import { FlagIcon } from '../../../FlagIcon';
import { SelectCommonOption } from './SelectCommonOption';

export interface SelectShiftOptionProps {
  id?: number[];
  chipBackgroundColor?: string;
  chipLabel?: string;
  name: string;
  email?: string;
  userImg?: string;
  onClick?: () => void;
  checked?: boolean;
  count?: number;
}

export const SelectShiftOption = ({
  chipBackgroundColor,
  chipLabel = '',
  name,
  email = '',
  userImg = '',
  onClick,
  checked,
  id,
  count,
}: SelectShiftOptionProps) => {
  const adornment = <FlagIcon showBorder color={chipBackgroundColor} />;

  return (
    <SelectCommonOption
      chipBackgroundColor={chipBackgroundColor}
      chipLabel=""
      name={name}
      email=""
      onClick={onClick}
      checked={checked}
      id={id}
      adornment={adornment}
    />
  );
};
