import { Box, Menu, MenuItem, MenuItemProps, Tooltip, useTheme } from '@mui/material';
import { MouseEvent, ReactElement, ReactNode, cloneElement, useId, useState } from 'react';
import { TemporaryNonShiftType, RosterApparatus, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import {
  checkAreAllEmployeesMergeable,
  checkHasMandatoryOvertimeDetailCode,
  checkHasVoluntaryOvertimeDetailCode,
  checkIsPlannedEmployee,
  filterTemporaryNonShiftPayCodes,
  getConsecutiveEmployees,
  mergeConsecutiveEmployees,
  removeEmployeeAvailability,
} from '@stationwise/shift-summary-helper';
import { ChevronDownIcon16 } from '../../../../assets';
import { getVisibleTags } from '../../../../utils/utils';
import { useLoadedDepartmentInfoContext } from '../../../Department';
import { PreviewButton } from '../../../EmployeePreview';
import { ViewProfileButton } from '../../../EmployeePreview/ViewProfileButton';
import { useRosterContext } from '../RosterContext';

interface Props {
  apparatus: RosterApparatus | null;
  position: RosterPosition | null;
  employee: RosterEmployee;
}

export const ManageShiftActions = ({ apparatus, position, employee }: Props) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const {
    isStaffingListsEnabled,
    forceShiftTradeState,
    shiftSummaryHelper,
    setShiftSummaryHelper,
    selectedFilledPositionState,
    splitShiftOrTimeOffState,
    setUserHasMadeChanges,
  } = useRosterContext();
  const theme = useTheme();

  const id = useId();
  const [manageAnchorEl, setManageAnchorEl] = useState<HTMLElement | null>(null);

  const { consecutiveEmployees } = getConsecutiveEmployees(shiftSummaryHelper, apparatus, position, employee);

  const openManageMenu = (event: MouseEvent<HTMLButtonElement>) => setManageAnchorEl(event.currentTarget);
  const closeManageMenu = () => setManageAnchorEl(null);

  const openSplitShiftOrTimeOffModal = () => {
    splitShiftOrTimeOffState.setIsDialogOpen(true);
    closeManageMenu();
  };

  const unsplitShift = () => {
    setShiftSummaryHelper(mergeConsecutiveEmployees(shiftSummaryHelper, apparatus, position, employee));
    setUserHasMadeChanges(true);
    closeManageMenu();
    selectedFilledPositionState.closeDialog();
  };

  const openMoveToTemporaryNonShiftModal = (temporaryNonShiftType: TemporaryNonShiftType) => {
    selectedFilledPositionState.setSelectedMoveToTemporaryNonShiftType(temporaryNonShiftType);
    closeManageMenu();
  };

  const openForceShiftTrade = () => {
    forceShiftTradeState.setIsDialogOpen(true);
    closeManageMenu();
  };

  const moveToOvertime = () => {
    const newShiftSummaryHelper = removeEmployeeAvailability({
      shiftSummaryHelper,
      employeeId: employee.id,
      startTime: employee.startDateTime,
      endTime: employee.endDateTime,
    });
    setShiftSummaryHelper(newShiftSummaryHelper);
    setUserHasMadeChanges(true);
    closeManageMenu();
    selectedFilledPositionState.closeDialog();
  };

  const checkIsTemporaryNonShiftTypeEnabled = (temporaryNonShiftType: TemporaryNonShiftType, visibleTags: string[]) => {
    return (
      filterTemporaryNonShiftPayCodes(departmentInfoState.departmentInfo.payCodes, temporaryNonShiftType, visibleTags).length > 0
    );
  };

  const checkWasStaffingListEmployee = () => {
    return (
      (checkHasMandatoryOvertimeDetailCode(employee) || checkHasVoluntaryOvertimeDetailCode(employee)) &&
      !checkIsPlannedEmployee(shiftSummaryHelper, employee)
    );
  };

  const renderDisabledTooltip = (key: string, tooltipText: string, menuItem: ReactElement<MenuItemProps>) => {
    if (!menuItem.props.disabled) {
      return cloneElement(menuItem, { key });
    }

    return (
      <Tooltip
        key={key}
        arrow
        title={tooltipText}
        slotProps={{
          tooltip: {
            sx: {
              backgroundColor: theme.palette.stationGray[800],
              borderRadius: 2,
              p: '10px 12px',
              typography: 'bodySMedium',
              '& .MuiTooltip-arrow': { color: theme.palette.stationGray[800] },
            },
          },
        }}
      >
        <div>{cloneElement(menuItem, { onClick: undefined })}</div>
      </Tooltip>
    );
  };

  const defaultMenuItem = (
    <MenuItem key="split-shift-or-time-off" onClick={openSplitShiftOrTimeOffModal}>
      Split shift or time off
    </MenuItem>
  );

  const getVisibleTagsForTemporaryNonShiftType = (temporaryNonShiftType: TemporaryNonShiftType) => {
    const visibilityTag = temporaryNonShiftType === TemporaryNonShiftType.EXTENDED_LEAVE ? 'EXT_LEAVE' : 'LIGHT_DUTY';
    return getVisibleTags(departmentInfoState.departmentInfo, visibilityTag, 'paycode');
  };

  const menuItems: ReactNode[] = [
    defaultMenuItem,
    consecutiveEmployees.length > 1 &&
      renderDisabledTooltip(
        'unsplit-shift',
        'Make sure all the pay codes, detail codes and notes are the same to undo split shift.',
        <MenuItem disabled={!checkAreAllEmployeesMergeable(consecutiveEmployees)} onClick={unsplitShift}>
          Undo split shift
        </MenuItem>,
      ),
    checkIsTemporaryNonShiftTypeEnabled(
      TemporaryNonShiftType.LIGHT_DUTY,
      getVisibleTagsForTemporaryNonShiftType(TemporaryNonShiftType.LIGHT_DUTY),
    ) && (
      <MenuItem key="light-duty" onClick={() => openMoveToTemporaryNonShiftModal(TemporaryNonShiftType.LIGHT_DUTY)}>
        Move to light duty (on roster)
      </MenuItem>
    ),
    checkIsTemporaryNonShiftTypeEnabled(
      TemporaryNonShiftType.EXTENDED_LEAVE,
      getVisibleTagsForTemporaryNonShiftType(TemporaryNonShiftType.EXTENDED_LEAVE),
    ) && (
      <MenuItem key="extended-leave" onClick={() => openMoveToTemporaryNonShiftModal(TemporaryNonShiftType.EXTENDED_LEAVE)}>
        Move to extended leave (off roster)
      </MenuItem>
    ),
    <MenuItem key="force-shift-trade" onClick={() => openForceShiftTrade()}>
      Force shift trade
    </MenuItem>,
    checkWasStaffingListEmployee() && (
      <MenuItem key="move-to-overtime" onClick={() => moveToOvertime()}>
        {isStaffingListsEnabled ? 'Restore position on staffing list' : 'Restore position on OT list'}
      </MenuItem>
    ),
  ].filter(Boolean);

  if (!menuItems.length) {
    return null;
  }

  if (menuItems.length === 1 && menuItems[0] === defaultMenuItem) {
    return (
      <Box sx={{ mt: 2, '& .MuiButton-root': { width: '100%' } }}>
        <PreviewButton buttonType="primary" onClick={openSplitShiftOrTimeOffModal}>
          Manage shift
        </PreviewButton>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', gap: 2, mt: 2, minWidth: '274px', '& .MuiButton-root': { width: '100%' } }}>
      <Box sx={{ flex: 1 }}>
        <ViewProfileButton employeeId={employee.id} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <PreviewButton
          buttonType="primary"
          id={`${id}manage-button`}
          aria-controls={manageAnchorEl ? `${id}manage-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={manageAnchorEl ? 'true' : undefined}
          onClick={openManageMenu}
          sx={{
            gap: 0.5,
            pl: 1,
            pr: 0.75,
            '& svg': { minWidth: '16px' },
            '&[aria-expanded="true"] svg': { transform: 'rotate(180deg)' },
          }}
        >
          Manage shift
          <ChevronDownIcon16 />
        </PreviewButton>
      </Box>
      <Menu
        id={`${id}manage-menu`}
        anchorEl={manageAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -8, horizontal: 'right' }}
        open={!!manageAnchorEl}
        onClose={closeManageMenu}
        MenuListProps={{ 'aria-labelledby': `${id}manage-button` }}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor: theme.palette.stationGray[700],
            border: `1px solid ${theme.palette.stationGray[600]}`,
            borderRadius: '12px',
          },
          '& .MuiMenu-list': {
            p: theme.spacing(1.5),
          },
          '& .MuiMenuItem-root': {
            backgroundColor: theme.palette.stationGray[700],
            borderRadius: '8px',
            color: theme.palette.common.white,
            p: theme.spacing(1, 1.5),
            typography: 'bodySRegular',
          },
          '& .MuiMenuItem-root.Mui-disabled': {
            color: theme.palette.stationGray[400],
            opacity: 1,
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: theme.palette.stationGray[800],
          },
        }}
      >
        {menuItems}
      </Menu>
    </Box>
  );
};
