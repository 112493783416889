import { Box, Typography, Checkbox, Chip, Button, ChipProps } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { EmptyCard, theme, useDepartmentInfoContext } from '@stationwise/component-module';
import { AutoHireInfoView, AutoHireView, Position } from '@stationwise/share-types';
import { formatShiftDuration } from '@stationwise/shift-summary-helper';
import { RankOrderModal } from '../Vacancies/RankOrderModal';
import { VacancyFillRankOrderModal } from '../Vacancies/VacancyFillRankOrder';
import { AutoHireVacancy } from '../Vacancies/vacanciesHelper';

type MergedItem = (AutoHireVacancy & { type: 'vacancy' }) | { type: 'autoHire'; data: AutoHireView; position: Position };

interface AutoHireVacanciesProps {
  groupedVacancies: Record<number, AutoHireVacancy[]>;
  rankOrders: Record<number, { rankId: number; isSelected: boolean }[]>;
  setRankOrders: React.Dispatch<React.SetStateAction<Record<number, { rankId: number; isSelected: boolean }[]>>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  autoHireInfo: AutoHireInfoView;
  handleAutoHireHistoryOpen: (rankId: number) => void;
  vacancyFillRankOrder: number[];
  setVacancyFillRankOrder: React.Dispatch<React.SetStateAction<number[]>>;
  isDateInPast: boolean;
}

function getAutoHireChipProps(items: MergedItem[]): Pick<ChipProps, 'label' | 'color'> {
  const isCompleted = items.some((item) => item.type === 'autoHire' && item.data.isCompleted);
  const isActive = items.some((item) => item.type === 'autoHire' && item.data.isActive);
  const allNotificationsSent = items.some((item) => item.type === 'autoHire' && item.data.allNotificationsSent);
  const someNotificationsSent = items.some(
    (item) => item.type === 'autoHire' && item.data.history.some((entry) => entry.notificationSentTime),
  );

  let label: string;
  let color: 'success' | 'warning' | 'info' | 'default';

  if (isCompleted) {
    label = 'Completed';
    color = 'success';
  } else if (isActive) {
    if (allNotificationsSent) {
      label = 'All Notified';
      color = 'success';
    } else if (someNotificationsSent) {
      label = 'In Progress';
      color = 'warning';
    } else {
      label = 'In Queue';
      color = 'info';
    }
  } else {
    label = 'Inactive';
    color = 'default';
  }

  return { label, color };
}
export const AutoHireVacancies: React.FC<AutoHireVacanciesProps> = ({
  groupedVacancies,
  rankOrders,
  setRankOrders,
  setDisabled,
  autoHireInfo,
  handleAutoHireHistoryOpen,
  vacancyFillRankOrder,
  setVacancyFillRankOrder,
  isDateInPast,
}) => {
  const [selectedRankId, setSelectedRankId] = useState<number | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isVacancyFillModalOpen, setVacancyFillModalOpen] = useState(false);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const initialCheckedRanks = useMemo(
    () =>
      Object.keys(groupedVacancies).reduce<Record<number, boolean>>((acc, rankIdStr) => {
        const rankId = Number(rankIdStr);
        acc[rankId] = false;
        return acc;
      }, {}),
    [groupedVacancies],
  );

  const [checkedRanks, setCheckedRanks] = useState<Record<number, boolean>>(initialCheckedRanks);

  const { state: department } = useDepartmentInfoContext();

  const uniqueRanks = department.departmentInfo?.ranks || [];

  useEffect(() => {
    const anyChecked = Object.values(checkedRanks).some((checked) => checked);
    setDisabled(!anyChecked);
  }, [checkedRanks, setDisabled]);

  const generateRankOrder = (startRankId: number): { rankId: number; isSelected: boolean }[] => {
    const startRankIndex = uniqueRanks.findIndex((rank) => rank.id === startRankId);
    if (startRankIndex === -1) return uniqueRanks.map((rank) => ({ rankId: rank.id, isSelected: false }));

    const higherRanks = uniqueRanks.slice(startRankIndex).map((rank) => ({ rankId: rank.id, isSelected: true }));
    const lowerRanks = uniqueRanks
      .slice(0, startRankIndex)
      .reverse()
      .map((rank) => ({ rankId: rank.id, isSelected: true }));
    return [...higherRanks, ...lowerRanks];
  };

  const handleCheckboxChange = (rankId: number) => {
    setCheckedRanks((prev) => {
      const newCheckedRanks = { ...prev, [rankId]: !prev[rankId] };

      if (newCheckedRanks[rankId]) {
        setRankOrders((prev) => {
          const autoHireOrder = autoHireInfo.autoHireDefaultRankOrders[rankId];
          return {
            ...prev,
            [rankId]: autoHireOrder ? autoHireOrder : generateRankOrder(rankId),
          };
        });
      } else {
        setRankOrders((prev) => {
          const updatedRankOrders = { ...prev };
          delete updatedRankOrders[rankId];
          return updatedRankOrders;
        });
      }

      return newCheckedRanks;
    });
  };

  const handleAllCheckboxChange = () => {
    const newCheckedState = !allChecked;
    setAllChecked(newCheckedState);

    const newCheckedRanks = Object.keys(groupedVacancies).reduce<Record<number, boolean>>((acc, rankIdStr) => {
      const rankId = Number(rankIdStr);
      const hasActiveAutoHire = autoHireInfo.autoHires.some((autoHire) =>
        autoHire.positions.some((position) => position.rank.id === rankId && autoHire.isActive),
      );
      acc[rankId] = newCheckedState && !hasActiveAutoHire;
      return acc;
    }, {});

    setCheckedRanks(newCheckedRanks);

    if (newCheckedState) {
      const newRankOrders = Object.keys(groupedVacancies).reduce<Record<number, { rankId: number; isSelected: boolean }[]>>(
        (acc, rankIdStr) => {
          const rankId = Number(rankIdStr);
          if (
            !autoHireInfo.autoHires.some((autoHire) =>
              autoHire.positions.some((position) => position.rank.id === rankId && autoHire.isActive),
            )
          ) {
            const autoHireOrder = autoHireInfo.autoHireDefaultRankOrders[rankId];
            acc[rankId] = autoHireOrder ? autoHireOrder : generateRankOrder(rankId);
          }
          return acc;
        },
        {},
      );
      setRankOrders(newRankOrders);
    } else {
      setRankOrders({});
    }
  };

  const handleEditButtonClick = (rankId: number) => {
    setSelectedRankId(rankId);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedRankId(null);
  };

  const handleRankOrderSubmit = (newOrders: { rankId: number; isSelected: boolean }[]) => {
    if (selectedRankId) {
      setRankOrders((prev) => ({ ...prev, [selectedRankId]: newOrders }));
    }
    setModalOpen(false);
  };

  const mergedVacancies = useMemo(() => {
    const merged: Record<number, MergedItem[]> = {};

    autoHireInfo.autoHires.forEach((autoHire) => {
      autoHire.positions.forEach((position) => {
        if (!merged[position.rank.id]) {
          merged[position.rank.id] = [];
        }
        merged[position.rank.id].push({ type: 'autoHire', data: autoHire, position });
      });
    });

    Object.entries(groupedVacancies).forEach(([rankIdStr, vacancies]) => {
      const rankId = Number(rankIdStr);
      if (!merged[rankId]) {
        merged[rankId] = [];
      }
      vacancies.forEach((vacancy) => {
        const isOverlapping = merged[rankId].some(
          (item) =>
            item.type === 'autoHire' &&
            item.position.station === vacancy.stationName &&
            item.position.apparatus === vacancy.apparatusName,
        );
        if (!isOverlapping) {
          merged[rankId].push({ ...vacancy, type: 'vacancy' as const });
        }
      });
    });

    return merged;
  }, [groupedVacancies, autoHireInfo.autoHires]);

  const handleVacancyFillModalClose = () => {
    setVacancyFillModalOpen(false);
  };

  const handleVacancyFillOrderSubmit = (newVacancyFillOrder: number[]) => {
    setVacancyFillRankOrder(newVacancyFillOrder);
    setVacancyFillModalOpen(false);
  };

  const handleEditFillOrder = () => {
    setVacancyFillModalOpen(true);
  };
  const sortedRankIds = vacancyFillRankOrder || Object.keys(mergedVacancies).map(Number);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {Object.keys(groupedVacancies).length !== Object.keys(autoHireInfo.autoHires).length && !isDateInPast && (
        <Box sx={(theme) => ({ display: 'flex', alignItems: 'center', px: theme.spacing(2), justifyContent: 'space-between' })}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox checked={allChecked} onChange={handleAllCheckboxChange} />
            <Typography sx={{ typography: 'bodySMedium' }}>Select All</Typography>
          </Box>
          <Box
            sx={(theme) => ({
              color: theme.palette.stationPurple[500],
              typography: 'buttonM',
              padding: theme.spacing(1, 2),
              '&:hover': {
                backgroundColor: theme.palette.stationGray[100],
                borderRadius: '4px',
              },
              cursor: 'pointer',
              textAlign: 'center',
            })}
            onClick={handleEditFillOrder}
          >
            Edit Vacancy Fill Order
          </Box>
        </Box>
      )}
      <Box
        sx={() => ({
          flex: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        })}
      >
        {sortedRankIds.map((rankId) => {
          const items = mergedVacancies[rankId] || [];
          if (items.length === 0) return null;

          const rankName = uniqueRanks
            .find((rank) => rank.id === rankId)
            ?.name.toLowerCase()
            .replace(/^\w/, (c) => c.toUpperCase());

          return (
            <Box
              key={rankId}
              sx={{
                p: theme.spacing(0, 2, 2, 2),
                mt: 1,
                width: '100%',
                backgroundColor: theme.palette.stationGray[50],
                marginTop: theme.spacing(2),
                borderRadius: '16px',
              }}
            >
              <Box
                sx={(theme) => ({
                  color: theme.palette.stationGray[500],
                  display: 'flex',
                  gap: theme.spacing(4),
                  typography: 'bodySMedium',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                })}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {!items.some((item) => item.type === 'autoHire') && (
                    <Checkbox checked={checkedRanks[rankId] || false} onChange={() => handleCheckboxChange(rankId)} />
                  )}
                  <Typography>{rankName}</Typography>
                </Box>
                {checkedRanks[rankId] && items.every((item) => item.type === 'vacancy') && (
                  <Box
                    sx={(theme) => ({
                      color: theme.palette.stationPurple[500],
                      typography: 'buttonM',
                      '&:hover': {
                        backgroundColor: theme.palette.stationGray[100],
                        borderRadius: '4px',
                      },
                      cursor: 'pointer',
                      textAlign: 'center',
                    })}
                    onClick={() => handleEditButtonClick(rankId)}
                  >
                    Edit Rank Order
                  </Box>
                )}
                {items.some((item) => item.type === 'autoHire') && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
                    <Chip {...getAutoHireChipProps(items)} size="small" variant="filled" />
                    <Button variant="text" color="success" size="small" onClick={() => handleAutoHireHistoryOpen(rankId)}>
                      View
                    </Button>
                  </Box>
                )}
              </Box>
              {items.map((item, index) => (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box sx={{ width: '100%', maxWidth: '255px' }}>
                    <Typography
                      variant="caption"
                      sx={(theme) => ({
                        color: theme.palette.stationGray[400],
                        display: 'block',
                        mb: theme.spacing(0.5),
                        mt: theme.spacing(1),
                        textAlign: 'center',
                      })}
                    >
                      {item.type === 'vacancy'
                        ? `${item.stationName.toUpperCase()} | ${item.apparatusName.toUpperCase()}`
                        : `${item.position.station?.toUpperCase()} | ${item.position.apparatus?.toUpperCase()}`}
                    </Typography>
                    <EmptyCard
                      badgeTitle={item.type === 'vacancy' ? item.rank.code : item.position.rank.code}
                      durationLabel={
                        item.type === 'vacancy'
                          ? formatShiftDuration({
                              startTime: item.startDateTime,
                              endTime: item.endDateTime,
                            })
                          : ''
                      }
                      color={item.type === 'vacancy' ? item.rank.color : item.data.positions[0].rank.color}
                      certs={[]}
                      cardSxProps={{
                        height: '48px',
                        [theme.breakpoints.up('sm')]: {
                          height: '40px',
                        },
                        overflow: 'hidden',
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          );
        })}
      </Box>
      {selectedRankId && (
        <RankOrderModal
          open={isModalOpen}
          setOpen={handleModalClose}
          selectedRankId={selectedRankId}
          rankOrders={rankOrders[selectedRankId] || []}
          onSubmit={handleRankOrderSubmit}
          uniqueRanks={uniqueRanks}
        />
      )}
      <VacancyFillRankOrderModal
        open={isVacancyFillModalOpen}
        setOpen={handleVacancyFillModalClose}
        rankOrder={vacancyFillRankOrder}
        uniqueRanks={uniqueRanks}
        onSubmit={handleVacancyFillOrderSubmit}
      />
    </Box>
  );
};
