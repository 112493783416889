import { Box, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, ReactNode } from 'react';
import { AllowedColors, Certification } from '@stationwise/share-types';
import { Badge, CertBadge } from '../Badge';

export const getEmptyCardColor = (theme: Theme, color: Pick<EmptyCardProps, 'color'>) => {
  switch (color.color) {
    //todo add possible colors here with caution about the ones uses default gray right now
    case 'red':
      return {
        background: theme.palette.stationRose[100],
        color: theme.palette.stationRose[500],
        hoverBackground: theme.palette.stationRose[300],
        hoverColor: theme.palette.stationRose[500],
        isOverBackground: theme.palette.stationRose[200],
        borderColor: theme.palette.stationRose[500],
        badgeColor: theme.palette.stationRose[600],
        badgeBackgroundColor: theme.palette.stationRose[200],
      };
    case 'yellow':
      return {
        background: theme.palette.stationYellow[200],
        color: theme.palette.stationYellow[500],
        hoverBackground: theme.palette.stationYellow[100],
        hoverColor: theme.palette.stationYellow[500],
        isOverBackground: theme.palette.stationYellow[300],
        borderColor: theme.palette.stationYellow[500],
        badgeColor: theme.palette.stationYellow[600],
        badgeBackgroundColor: theme.palette.stationYellow[300],
      };
    case 'orange':
      return {
        background: theme.palette.stationOrange[100],
        color: theme.palette.stationOrange[500],
        hoverBackground: theme.palette.stationOrange[300],
        hoverColor: theme.palette.stationOrange[500],
        isOverBackground: theme.palette.stationOrange[200],
        borderColor: theme.palette.stationOrange[500],
        badgeColor: theme.palette.stationOrange[800],
        badgeBackgroundColor: theme.palette.stationOrange[200],
      };
    case 'indigo':
      return {
        background: theme.palette.stationIndigo[200],
        color: theme.palette.stationIndigo[500],
        hoverBackground: theme.palette.stationIndigo[400],
        hoverColor: theme.palette.stationIndigo[500],
        isOverBackground: theme.palette.stationIndigo[300],
        borderColor: theme.palette.stationIndigo[500],
        badgeColor: theme.palette.stationIndigo[600],
        badgeBackgroundColor: theme.palette.stationIndigo[100],
      };
    case 'teal':
      return {
        background: theme.palette.stationCyan[100],
        color: theme.palette.stationCyan[600],
        hoverBackground: theme.palette.stationCyan[200],
        hoverColor: theme.palette.stationCyan[600],
        isOverBackground: theme.palette.stationCyan[200],
        borderColor: theme.palette.stationCyan[600],
        badgeColor: theme.palette.stationCyan[600],
        badgeBackgroundColor: theme.palette.stationCyan[300],
      };
    case 'gray':
    default:
      return {
        background: theme.palette.stationGray[200],
        color: theme.palette.stationGray[600],
        hoverBackground: theme.palette.stationGray[100],
        hoverColor: theme.palette.stationGray[600],
        isOverBackground: theme.palette.stationGray[300],
        borderColor: theme.palette.stationGray[600],
        badgeColor: theme.palette.stationGray[600],
        badgeBackgroundColor: theme.palette.stationGray[300],
      };
  }
};

interface EmptyCardCertification extends Pick<Certification, 'code' | 'color'> {
  parentCertifications?: Pick<Certification, 'code' | 'color'>[];
}

export interface EmptyCardProps {
  color?: AllowedColors;
  badgeTitle: string;
  certs: EmptyCardCertification[];
  durationLabel: string;
  noBadge?: boolean;
  withPlusSign?: boolean;
  onClick?: () => void;
  cardSxProps?: SxProps<Theme>;
  actions?: ReactNode;
  testId?: string;
}

export const EmptyCard = ({
  color = 'orange',
  badgeTitle,
  durationLabel,
  certs,
  noBadge = false,
  withPlusSign = true,
  onClick,
  cardSxProps,
  actions,
  testId = '',
}: EmptyCardProps) => {
  const theme = useTheme();
  const colors = getEmptyCardColor(theme, { color });
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Box
      data-cy={testId}
      display="flex"
      flexDirection="column"
      data-clickable={onClick ? 'true' : undefined}
      onClick={onClick}
      sx={[
        (theme) => ({
          position: 'relative',
          borderRadius: theme.spacing(1.5),
          p: '8px 12px',
          background: colors.background,
          border: `2px dashed ${colors.borderColor}`,
          height: '104px',
          width: '100%',
          cursor: onClick ? 'pointer' : undefined,
          '&:hover': {
            background: onClick ? colors.hoverBackground : colors.background,
          },
        }),
        ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : []),
      ]}
    >
      <Box position="relative" sx={{ height: '100%', width: '100%' }}>
        <Box position="absolute" sx={{ top: 0, left: 0, width: '100%', height: '100%' }}>
          {withPlusSign && (
            <Box display="flex" alignItems="center" justifyContent="center" alignContent="center" height="100%">
              <Box sx={{ height: '18px', width: '2px', background: colors.borderColor }} />
              <Box position="absolute">
                <Box sx={{ height: '1.2px', width: '18px', background: colors.borderColor }} />
              </Box>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ height: '100%' }}>
          <Box display="flex" alignItems="baseline" justifyContent="space-between">
            {noBadge || !badgeTitle ? (
              <Box sx={{ color: colors.badgeColor, typography: 'bodyXSRegular' }}>{badgeTitle}</Box>
            ) : (
              <Badge
                label={badgeTitle}
                isRound
                type="sm"
                color="orange"
                isBold
                noPadding
                isCustom
                customBackgroundColor={colors.badgeBackgroundColor}
                customColor={colors.badgeColor}
              />
            )}
            <Box sx={{ color: colors.color, typography: isMobile ? 'bodyMRegular' : 'bodyXSRegular' }}>{durationLabel}</Box>
          </Box>
          <Box display="flex" alignItems="baseline" justifyContent="space-between">
            <Box display="inline-flex" alignItems="center" gap={0.5}>
              {certs.map((cert, index) => (
                <CertBadge key={`${index}-${cert.code}`} cert={cert} />
              ))}
              {certs.length === 1 &&
                certs[0].parentCertifications?.map((cert, index) => (
                  <Fragment key={`${index}-${cert.code}`}>
                    <Typography variant="bodyXSSemibold" sx={(theme) => ({ color: theme.palette.stationGray[500] })}>
                      or
                    </Typography>
                    <CertBadge cert={cert} />
                  </Fragment>
                ))}
            </Box>
            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[600],
                position: 'relative',
                bottom: '15px',
                typography: 'bodySSemibold',
                lineHeight: '14px',
                [theme.breakpoints.up('sm')]: { typography: 'buttonS', fontWeight: 400 },
              })}
            >
              {actions}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
