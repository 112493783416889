import { Box } from '@mui/material';
import { MinReqRecord } from './MinReqRecord';

interface Props {
  name: string;
  total: number;
  amount: number;
  color: string;
  backgroundColor: string;
}

export const RankRecord = ({ name, total, amount, color, backgroundColor }: Props) => {
  return (
    <Box mt={1}>
      <MinReqRecord total={total} amount={amount}>
        <Box sx={{ backgroundColor, color, px: 1, borderRadius: '30px', typography: 'bodyXSMedium' }}>{name}</Box>
      </MinReqRecord>
    </Box>
  );
};
