import { format } from 'date-fns';
import { useState, useEffect } from 'react';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAccrualWarning(authUserState: any, departmentInfoState: any) {
  const [accrualWarningModalOpen, setAccrualWarningModalOpen] = useState(false);

  useEffect(() => {
    const checkAccrualWarning = () => {
      const payPeriodType = authUserState.employee.rank.payPeriodType;
      const currentPayPeriods = departmentInfoState.departmentInfo.currentPayPeriods || [];
      if (!currentPayPeriods.length) {
        return;
      }
      const currentPayPeriodEndDate =
        currentPayPeriods.find((period: { payPeriodType: string }) => period.payPeriodType === payPeriodType)?.endDate ||
        currentPayPeriods[0]?.endDate;
      const currentPayPeriodEnd = new Date(currentPayPeriodEndDate);
      const today = new Date();
      const currentPayPeriodEndDay = format(currentPayPeriodEnd, 'yyyy-MM-dd');
      const todayDay = format(today, 'yyyy-MM-dd');

      if (todayDay === currentPayPeriodEndDay) {
        setAccrualWarningModalOpen(true);
        return;
      }

      const localStorageLastOvertimeCreatedAt = localStorage.getItem('lastOvertimeCreatedAt');
      const authUserLastOvertimeCreatedAt = authUserState.employee.lastOvertimeCreatedAt;

      if (authUserLastOvertimeCreatedAt) {
        const authUserLastOvertimeCreatedAtISO = new Date(authUserLastOvertimeCreatedAt).toISOString();
        if (!localStorageLastOvertimeCreatedAt || localStorageLastOvertimeCreatedAt !== authUserLastOvertimeCreatedAtISO) {
          setAccrualWarningModalOpen(true);
        } else {
          setAccrualWarningModalOpen(false);
        }
      } else {
        setAccrualWarningModalOpen(false);
      }
    };

    checkAccrualWarning();
  }, [
    authUserState.employee.lastOvertimeCreatedAt,
    departmentInfoState.departmentInfo,
    authUserState.employee.rank.payPeriodType,
  ]);

  const closeAccrualWarningModal = () => {
    const authUserLastOvertimeCreatedAt = new Date(authUserState.employee.lastOvertimeCreatedAt);
    const authUserLastOvertimeCreatedAtISO = authUserLastOvertimeCreatedAt.toISOString();
    localStorage.setItem('lastOvertimeCreatedAt', authUserLastOvertimeCreatedAtISO);
    setAccrualWarningModalOpen(false);
  };

  return {
    accrualWarningModalOpen,
    closeAccrualWarningModal,
  };
}
