import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Typography, Checkbox } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, theme } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { Rank } from '@stationwise/share-types';

interface RankOrderModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  rankOrders: { rankId: number; isSelected: boolean }[];
  selectedRankId: number;
  onSubmit: (newOrders: { rankId: number; isSelected: boolean }[]) => void;
  uniqueRanks: Rank[];
}

export const RankOrderModal: React.FC<RankOrderModalProps> = ({
  open,
  setOpen,
  rankOrders,
  selectedRankId,
  onSubmit,
  uniqueRanks,
}) => {
  const [orders, setOrders] = useState<{ rankId: number; isSelected: boolean }[]>(rankOrders);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);

  useEffect(() => {
    if (open) {
      const allSelected = rankOrders.every((order) => order.isSelected);
      const noneSelected = rankOrders.every((order) => !order.isSelected);
      if (noneSelected) {
        setOrders(rankOrders.map((order) => ({ ...order, isSelected: true })));
        setAllChecked(true);
      } else {
        setOrders(rankOrders);
        setAllChecked(allSelected);
      }
    }
  }, [open, rankOrders]);

  useEffect(() => {
    const allSelected = orders.every((order) => order.isSelected);
    const noneSelected = orders.every((order) => !order.isSelected);

    if (allSelected !== allChecked && !noneSelected) {
      setAllChecked(allSelected);
    }
  }, [orders, allChecked]);

  const handleSort = () => {
    if (dragItem.current !== null && dragOverItem.current !== null) {
      const draggedItem = orders[dragItem.current];
      const targetItem = orders[dragOverItem.current];
      if (draggedItem.isSelected !== targetItem.isSelected) {
        return;
      }
      const updatedOrders = [...orders];
      const draggedItemContent = updatedOrders.splice(dragItem.current, 1)[0];
      updatedOrders.splice(dragOverItem.current, 0, draggedItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setOrders(updatedOrders);
    }
  };

  const handleSaveDefault = async () => {
    try {
      await client.post('auto-hire/save_auto_hire_default_rank_order/', {
        rank_id: selectedRankId,
        rank_order: orders.map((order) => ({ rank_id: order.rankId, is_selected: order.isSelected })),
      });
      handleSubmit();
    } catch (error) {
      console.error('Failed to save default rank order', error);
    }
  };

  const handleCheckboxToggle = (rankId: number) => {
    setOrders((prevOrders) =>
      prevOrders
        .map((order) => (order.rankId === rankId ? { ...order, isSelected: !order.isSelected } : order))
        .sort((a, b) => Number(b.isSelected) - Number(a.isSelected)),
    );
  };

  const handleSelectAllToggle = () => {
    const newCheckedState = !allChecked;
    setAllChecked(newCheckedState);

    setOrders((prevOrders) =>
      prevOrders
        .map((order) => ({ ...order, isSelected: newCheckedState }))
        .sort((a, b) => Number(b.isSelected) - Number(a.isSelected)),
    );
  };

  const handleSubmit = () => {
    onSubmit(orders);
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen} hideCloseIcon>
      <Box
        sx={{
          p: theme.spacing(2),
          borderRadius: '8px',
          mx: 'auto',
          position: 'relative',
          maxWidth: '320px',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '85vh',
          overflow: 'auto',
        }}
      >
        <Typography variant="h6">Edit Rank Orders</Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: theme.spacing(2) }}>
          <Checkbox checked={allChecked} onChange={handleSelectAllToggle} />
          <Typography>Select All</Typography>
        </Box>

        <Box sx={{ flex: 1, overflow: 'auto', my: 2 }}>
          {orders.map((order, index) => {
            const rank = uniqueRanks.find((rank) => rank.id === order.rankId);
            return (
              <Box
                key={index}
                draggable
                onDragStart={() => (dragItem.current = index)}
                onDragEnter={() => (dragOverItem.current = index)}
                onDragEnd={handleSort}
                onDragOver={(e) => e.preventDefault()}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1,
                  backgroundColor: theme.palette.stationGray[50],
                  p: theme.spacing(1),
                  width: '280px',
                  borderRadius: theme.spacing(1),
                  border: '1px solid',
                  borderColor: dragItem.current === index ? theme.palette.primary.light : 'transparent',
                  opacity: order.isSelected ? 1 : 0.5,
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: theme.palette.stationGray[300],
                    cursor: 'move',
                  },
                }}
              >
                <DragIndicatorIcon
                  sx={{
                    visibility: 'visible',
                    marginRight: theme.spacing(1),
                    color: theme.palette.stationGray[500],
                    '&:hover': {
                      color: theme.palette.primary.dark,
                    },
                  }}
                />
                <Typography sx={{ width: '20px', textAlign: 'center', color: theme.palette.stationGray[500] }}>
                  {index + 1}
                </Typography>
                <Typography sx={{ flex: 1, ml: theme.spacing(1.5) }}>{rank?.code}</Typography>
                <Checkbox
                  checked={order.isSelected}
                  onChange={() => handleCheckboxToggle(order.rankId)}
                  sx={{ padding: theme.spacing(1), marginLeft: theme.spacing(1) }}
                />
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: theme.spacing(2),
            gap: theme.spacing(1),
            mb: theme.spacing(2),
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveDefault}>
            Save as Default
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
