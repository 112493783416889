import { Box, MenuItem, Select, SelectProps } from '@mui/material';
import { useId, useMemo } from 'react';
import { TimeOffLimit } from '@stationwise/share-types';
import { useLoadedDepartmentInfoContext } from '../../../Department';
import { InputLabel } from '../../../Input';
import { useGet } from '../../hooks/useGet';

type Props = Pick<SelectProps<string>, 'value' | 'onChange'> & {
  employeeId: string;
};

export const Reason = ({ employeeId, ...props }: Props) => {
  const id = useId();

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { data: dataAccruals } = useGet<TimeOffLimit[]>(`/employee/accruals/?employeeId=${employeeId}`);

  const options = useMemo(() => {
    const defaultValue = {
      text: 'Employee is not off',
      payCode: 'N/A',
      limit: null as number | null,
      isUnlimited: false,
    };
    const values = new Map([['NONE', defaultValue]]);

    if (departmentInfoState.departmentInfo.timeOffTypes) {
      departmentInfoState.departmentInfo.timeOffTypes.forEach((timeOffType) => {
        values.set(timeOffType.code, {
          text: timeOffType.name,
          payCode: timeOffType.code,
          limit: null,
          isUnlimited: true,
        });
      });
    }

    const isAccrualsEnabled = departmentInfoState.departmentInfo.settings.accrualLogicEnabled;
    if (dataAccruals) {
      dataAccruals.forEach((accrual) => {
        values.set(accrual.payCode.code, {
          text: accrual.payCode.name,
          payCode: accrual.payCode.code,
          limit: isAccrualsEnabled ? accrual.accruedTimeOff - accrual.pending : null,
          isUnlimited: isAccrualsEnabled ? true : accrual.isUnlimited,
        });
      });
    }

    let longestPayCode = '';
    let shouldDisplayNotAvailableCode = false;
    for (const [key, value] of values.entries()) {
      if (value.limit === 0) {
        values.delete(key);
        continue;
      }

      if (value.payCode.length > longestPayCode.length) {
        longestPayCode = value.payCode;
      }

      if (value !== defaultValue && value.payCode !== value.text) {
        shouldDisplayNotAvailableCode = true;
      }
    }

    return { defaultValue, values, longestPayCode, shouldDisplayNotAvailableCode };
  }, [dataAccruals, departmentInfoState.departmentInfo]);

  const checkShouldDisplayPayCode = (key: string, payCode: string, text: string) => {
    const value = options.values.get(key) || options.defaultValue;
    return value === options.defaultValue ? options.shouldDisplayNotAvailableCode : payCode !== text;
  };

  return (
    <Box sx={{ my: 3 }}>
      <InputLabel id={`${id}label`}>Reason</InputLabel>
      <Select
        labelId={`${id}label`}
        id={`${id}select`}
        renderValue={(key) => {
          const value = options.values.get(key) || options.defaultValue;
          return (
            <Box
              data-cy="split-reason-select"
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {checkShouldDisplayPayCode(key, value.payCode, value.text) && (
                  <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodySMedium' })}>
                    {value.payCode}
                  </Box>
                )}
                <Box sx={{ typography: 'bodyMRegular' }}>{value.text}</Box>
              </Box>
              {value.limit !== null && (
                <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodyMRegular' })}>
                  {value.isUnlimited ? 'Unlimited' : `${value.limit.toFixed(2)} h`}
                </Box>
              )}
            </Box>
          );
        }}
        sx={{ width: '100%' }}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: -8, horizontal: 'left' },
        }}
        {...props}
      >
        {[...options.values.entries()].map(([key, value]) => (
          <MenuItem key={key} value={key} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {checkShouldDisplayPayCode(key, value.payCode, value.text) && (
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    color: theme.palette.stationGray[500],
                    typography: 'bodySMedium',
                    whiteSpace: 'nowrap',
                  })}
                >
                  <Box>{value.payCode}</Box>
                  <Box sx={{ userSelect: 'none', visibility: 'hidden', height: 0, overflow: 'hidden' }}>
                    {options.longestPayCode}
                  </Box>
                </Box>
              )}
              <Box sx={{ typography: 'bodyMRegular' }}>{value.text}</Box>
            </Box>
            {value.limit !== null && (
              <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodyMRegular' })}>
                {value.isUnlimited ? 'Unlimited' : `${value.limit.toFixed(2)} h`}
              </Box>
            )}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
