import { Box, useTheme } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { memo } from 'react';
import { Badge, CustomTable, Loader, TableProps, getColor } from '@stationwise/component-module';
import { ListFieldsStaffingList } from '@stationwise/share-types';
import { capitalize } from '@stationwise/share-utils';

interface StaffingListItemsProps {
  isLoading: boolean;
  staffingList: ListFieldsStaffingList | undefined;
  searchInput: string;
  selectedTeam: string;
  selectedLastOvertimeDate: string;
}

export const StaffingListItems = memo((props: StaffingListItemsProps) => {
  const theme = useTheme();

  const columns: TableProps['columns'] = [
    { id: 'order', label: 'NO.' },
    { id: 'firstName', label: 'FIRST NAME' },
    { id: 'lastName', label: 'LAST NAME' },
    { id: 'team', label: 'SHIFT' },
    { id: 'lastOvertimeStaffedAt', label: 'LAST OT STAFF TIME' },
    { id: 'lastOvertimeDate', label: 'LAST OT WORK DATE' },
    { id: 'secondLastOvertimeDate', label: '2ND OT WORK DATE' },
    { id: 'thirdLastOvertimeDate', label: '3RD OT WORK DATE' },
    { id: 'overtimeAccHours', label: 'PARTIAL HOURS' },
  ];

  const items = (props.staffingList?.items || []).filter((item) => {
    let match = true;
    if (props.searchInput) {
      match = match && item.employee.name.toLowerCase().includes(props.searchInput.toLowerCase());
    }
    if (props.selectedTeam) {
      match = match && item.employee.team?.name === props.selectedTeam;
    }
    if (props.selectedLastOvertimeDate) {
      match = match && item.attributes.lastThreeOvertimes?.[0]?.date === props.selectedLastOvertimeDate;
    }
    return match;
  });

  const data = items.map((item) => {
    const [firstName, ...lastNames] = item.employee.name.split(' ');

    const lastOvertimeStaffedAt = item.attributes.lastThreeOvertimes?.[0]?.staffedAt;
    const lastOvertimeDate = item.attributes.lastThreeOvertimes?.[0]?.date;
    const secondLastOvertimeDate = item.attributes.lastThreeOvertimes?.[1]?.date;
    const thirdLastOvertimeDate = item.attributes.lastThreeOvertimes?.[2]?.date;

    const teamBadge = item.employee.team && (
      <Box sx={{ display: 'inline-flex', whiteSpace: 'nowrap' }}>
        <Badge
          label={capitalize(item.employee.team.name)}
          isRound
          type="sm"
          isCustom={true}
          customBackgroundColor={getColor(theme, item.employee.team.color).color}
          customColor="white"
        />
      </Box>
    );

    return {
      order: item.order,
      firstName,
      lastName: lastNames.join(' '),
      team: teamBadge,
      lastOvertimeStaffedAt: lastOvertimeStaffedAt && format(lastOvertimeStaffedAt, 'MMM dd, yyyy | HH:mm.ss'),
      lastOvertimeDate: lastOvertimeDate && format(parseISO(lastOvertimeDate), 'MMM dd, yyyy'),
      secondLastOvertimeDate: secondLastOvertimeDate && format(parseISO(secondLastOvertimeDate), 'MMM dd, yyyy'),
      thirdLastOvertimeDate: thirdLastOvertimeDate && format(parseISO(thirdLastOvertimeDate), 'MMM dd, yyyy'),
      overtimeAccHours: item.attributes.overtimeAccHours,
    };
  });

  return (
    <Box sx={{ position: 'relative', minHeight: 140 }}>
      {props.isLoading && (
        <Box sx={{ pt: 11, position: 'absolute', top: 0, left: 0, width: '100%' }}>
          <Loader data-cy="staffing-list-items-loader" />
        </Box>
      )}
      <Box sx={{ visibility: props.isLoading ? 'hidden' : undefined }}>
        <CustomTable columns={columns} data={data} />
        {!props.staffingList?.items?.length && (
          <Box sx={{ display: 'flex', alignItems: 'center', pt: 3, justifyContent: 'center', typography: 'bodyMMedium' }}>
            This list is empty
          </Box>
        )}
      </Box>
    </Box>
  );
});
