import { FilterList as Filter } from '@mui/icons-material';
import { Box, Select, Chip, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';
import React, { useId, useState } from 'react';
import { XIcon16 } from '../../../../assets';

const MAX_DISPLAYED_OPTIONS = 1;
const MAX_BADGE_TITLE_LENGTH = 5;
const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 8;

interface Props {
  options: string[];
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
}

export const MultipleFilter = ({ options, selectedOptions, setSelectedOptions }: Props) => {
  const id = useId();
  const [isOpen, setIsOpen] = useState(false);
  const disabled = !options.length;

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedOptions(Array.isArray(event.target.value) ? event.target.value : [event.target.value]);
    setIsOpen(false);
  };

  const handleClearFilters = () => {
    setSelectedOptions([]);
  };

  const handleDelete = (deletedOption: string) => {
    setSelectedOptions((prevSelectedOptions) => prevSelectedOptions.filter((option) => option !== deletedOption));
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <Box id={`${id}label`} sx={(theme) => ({ typography: 'bodySMedium', color: theme.palette.stationGray[300] })}>
        Filter by
      </Box>
      <Select
        labelId={`${id}label`}
        displayEmpty
        multiple
        disabled={disabled}
        value={disabled ? [] : selectedOptions}
        onChange={handleChange}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        MenuProps={{
          slotProps: {
            paper: {
              sx: { maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP },
            },
          },
        }}
        sx={(theme) => ({
          width: '100%',
          '& .MuiSelect-select': { p: '0 !important' },
          '& .MuiSelect-icon': { display: 'none' },
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.stationGray[200]} !important`,
            borderRadius: 2,
            boxShadow: 'none !important',
          },
          '& .MuiChip-root': {
            background: theme.palette.stationGray[100],
            color: theme.palette.stationGray[600],
            height: '25px',
            m: 0,
          },
          '& .MuiChip-root .MuiChip-deleteIcon': { display: 'inline-flex', p: '6px', m: '0 2px 0 -12px' },
        })}
        renderValue={(selected) => (
          <Box
            data-cy="multiple-filter"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              height: '40px',
              p: theme.spacing(0, 1, 0, '14px'),
            })}
          >
            <Filter sx={(theme) => ({ height: '20px', width: '20px', color: theme.palette.stationGray[200] })} />
            <Box sx={{ flex: 1, minWidth: '1px', display: 'flex', gap: 1 }}>
              {selected.slice(0, MAX_DISPLAYED_OPTIONS).map((value) => (
                <Chip
                  key={value}
                  label={
                    selected.length > MAX_DISPLAYED_OPTIONS && value.length > MAX_BADGE_TITLE_LENGTH
                      ? `${value.slice(0, MAX_BADGE_TITLE_LENGTH)}...`
                      : value
                  }
                  onDelete={() => handleDelete(value)}
                  deleteIcon={
                    <Box
                      sx={(theme) => ({ color: theme.palette.stationGray[400] })}
                      onMouseDown={(event) => event.stopPropagation()}
                    >
                      <XIcon16 />
                    </Box>
                  }
                  sx={{ fontSize: value.length > MAX_BADGE_TITLE_LENGTH ? '0.8rem' : 'inherit' }}
                />
              ))}
              {selected.length > MAX_DISPLAYED_OPTIONS && <Chip label={`+${selected.length - 1}`} />}
            </Box>
            {selected.length > 0 && (
              <Box
                data-cy="clear-filter-button"
                onClick={handleClearFilters}
                onMouseDown={(event) => event.stopPropagation()}
                sx={(theme) => ({
                  background: theme.palette.stationGray[100],
                  display: 'inline-flex',
                  borderRadius: '50%',
                  padding: theme.spacing(1),
                })}
              >
                <XIcon16 />
              </Box>
            )}
          </Box>
        )}
      >
        {options.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
