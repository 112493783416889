import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, ClickAwayListener, Popper } from '@mui/material';
import { useState } from 'react';
import { SubMenu as MenuOptions } from '../../MessagesInbox/ConversationCard/SubMenu';

interface SubMenuProps {
  onCancelConversation?: (id: number) => void;
  conversationId: number;
}

export const SubMenu = ({ conversationId, onCancelConversation }: SubMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setIsOpen(true);
    if (anchorEl && isOpen) {
      setAnchorEl(null);
      setIsOpen(false);
    }
  };

  return (
    <>
      <Box
        className="message-header-more-icon"
        sx={(theme) => ({
          mr: '16px',
          backgroundColor: isOpen ? theme.palette.stationGray[100] : 'inherit',
          '&:hover': {
            cursor: 'pointer',
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '28px',
          height: '28px',
          borderRadius: '8px',
          padding: '2px',
        })}
        onClick={handleClick}
      >
        <MoreHorizIcon
          sx={(theme) => ({
            height: '20px',
            width: '20px',
          })}
        />
      </Box>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper id={'message-popper'} open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-end">
          <MenuOptions conversationId={conversationId} onCancelConversation={onCancelConversation} />
        </Popper>
      </ClickAwayListener>
    </>
  );
};
