import { Box } from '@mui/material';
import { isValid, setMinutes } from 'date-fns';
import { Dispatch, SetStateAction, useId } from 'react';
import { diffCycleMinutes } from '@stationwise/shift-summary-helper';
import { InputLabel } from '../../../Input';
import { useRosterContext } from '../RosterContext';
import { SplitTime } from '../SplitShiftOrTimeOffModal/SplitTime';

interface Props {
  isDurationValid: boolean;
  startDateTime: Date | null;
  setStartDateTime: Dispatch<SetStateAction<Date | null>>;
  endDateTime: Date | null;
  setEndDateTime: Dispatch<SetStateAction<Date | null>>;
}

export const StrikeTeamAssignmentTimes = ({
  isDurationValid,
  startDateTime,
  setStartDateTime,
  endDateTime,
  setEndDateTime,
}: Props) => {
  const id = useId();
  const { shiftSummaryHelper } = useRosterContext();
  const { shiftDuration } = shiftSummaryHelper;

  let durationMinutes = 0;
  if (isValid(startDateTime) && isValid(endDateTime)) {
    durationMinutes = diffCycleMinutes(endDateTime, startDateTime) || shiftDuration.hours * 60;
  }

  const normalizeDateTime = (value: Date | null, isEnd: boolean) => {
    if (!value || !isValid(value)) {
      return value;
    }

    let minutes = 0;
    if (isEnd) {
      minutes += diffCycleMinutes(startDateTime, shiftDuration.startTime);
      minutes += diffCycleMinutes(value, startDateTime) || shiftDuration.hours * 60;
    } else {
      minutes += diffCycleMinutes(value, shiftDuration.startTime);
    }

    return setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + minutes);
  };

  return (
    <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
      <Box sx={{ flex: 1, display: 'flex', gap: 0.5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <InputLabel htmlFor={`${id}start`}>Start time</InputLabel>
          <SplitTime
            value={startDateTime}
            onChange={(value) => setStartDateTime(normalizeDateTime(value, false))}
            slotProps={{ textField: { id: `${id}start` } }}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <InputLabel htmlFor={`${id}start`}>End time</InputLabel>
          <SplitTime
            value={endDateTime}
            onChange={(value) => setEndDateTime(normalizeDateTime(value, true))}
            slotProps={{ textField: { id: `${id}end` } }}
          />
        </Box>
      </Box>
      <Box sx={{ flex: 1, typography: 'bodySRegular', whiteSpace: 'nowrap', pt: 4.5 }}>
        <Box component="span" sx={(theme) => ({ color: theme.palette.stationGray[500], mr: 1 })}>
          Duration:
        </Box>
        {(() => {
          if (!isDurationValid) {
            return (
              <Box component="span" sx={(theme) => ({ color: theme.palette.stationRose[700] })}>
                Invalid
              </Box>
            );
          }

          const durationHH = Math.floor(durationMinutes / 60);
          const durationMM = durationMinutes - durationHH * 60;
          return <Box component="span">{`${`${durationHH}`.padStart(2, '0')} h ${`${durationMM}`.padStart(2, '0')} m`}</Box>;
        })()}
      </Box>
    </Box>
  );
};
