import { Box } from '@mui/material';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { Toggle, ToggleProps } from './Toggle';

interface Props extends ToggleProps {
  label: string;
}

export const ToggleWithLabel = (props: Props) => {
  return (
    <Box display="flex">
      <Box
        data-cy={`toggle-${makeTestIdentifier(props.label)}`}
        onClick={() => props.onClick()}
        sx={(theme) => ({
          backgroundColor: theme.palette.stationGray[50],
          borderRadius: theme.spacing(5),
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          padding: '3px',
          pl: '10px',
          gap: 1,
          '&:hover': {
            cursor: 'pointer',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            color: props.isActive ? theme.palette.stationGray[900] : theme.palette.stationGray[400],
            typography: 'tagline1',
          })}
        >
          {props.label}
        </Box>
        <Toggle
          {...props}
          // don't pass onClick to Toggle, because we want to handle it here
          onClick={() => null}
        />
      </Box>
    </Box>
  );
};
