import { Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Button } from '@stationwise/component-module';

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
  selectedPerson: string;
  handleSubmit: () => void;
}
export const Footer = ({ selectedPerson, handleSubmit, setStep }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        justifyContent: 'space-between',
        display: 'flex',
        mt: theme.spacing(2),
        width: '100%',
        pt: theme.spacing(2),
        pb: theme.spacing(3),
      })}
    >
      <Button
        sx={(theme) => ({
          width: '182px',
          height: '44px',
          padding: '9px 17px 9px 17px',
        })}
        buttonType="tertiary"
        variant="contained"
        onClick={() => setStep(1)}
      >
        <Box component="span" sx={{ typography: 'buttonL' }}>
          Previous
        </Box>
      </Button>
      <Button
        sx={(theme) => ({
          backgroundColor: theme.palette.common.black,
          width: '182px',
          height: '44px',
          padding: '9px 17px 9px 17px',
          color: theme.palette.common.white,
          borderRadius: theme.spacing(0.75),
          '&:hover': { backgroundColor: theme.palette.common.black, boxShadow: 'none' },
        })}
        buttonType="tertiary"
        variant="contained"
        disableFocusRipple={true}
        disableTouchRipple={true}
        onClick={() => handleSubmit()}
        disabled={selectedPerson === ''}
      >
        <Box component="span" sx={{ typography: 'buttonL' }}>
          Submit
        </Box>
      </Button>
    </Box>
  );
};
