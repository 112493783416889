import { useEffect } from 'react';

export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    let envName = 'Stationwise';
    if (window.location.host.includes('localhost:')) {
      envName = 'Local';
    } else if (window.location.host.includes('.dev.stationwise.com')) {
      envName = 'Dev';
    } else if (window.location.host.includes('.staging.stationwise.com')) {
      envName = 'Staging';
    }
    document.title = `${envName} | ${title}`;
  }, [title]);
};
