import { SxProps, Theme, useTheme } from '@mui/material';
import { AllowedColors } from '@stationwise/share-types';
import { Badge } from '../Badge';
import { getEmptyCardColor } from '../EmptyCard';
import { CardTooltip } from './CardTooltip';
import { Chip } from './Chip';

interface Props {
  actingAs: string;
  color: AllowedColors;
  sx?: SxProps<Theme>;
}

export const ActingAsIndicator = ({ actingAs, color, sx }: Props) => {
  const theme = useTheme();
  const colors = getEmptyCardColor(theme, { color });
  return (
    <CardTooltip
      anchorSx={sx}
      anchorContent={<Chip label="A" />}
      popoverSx={{ gap: 1, p: '12px 14px' }}
      popoverContent={
        <>
          <Chip label="Acting as" />
          <Badge
            label={actingAs}
            isRound
            type="sm"
            color="orange"
            isBold
            noPadding
            isCustom
            customBackgroundColor={colors.badgeBackgroundColor}
            customColor={colors.badgeColor}
          />
        </>
      }
    />
  );
};
