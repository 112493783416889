import { parse } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import {
  useFetchBulkCallForShift,
  useDateQueryParam,
  useFetchStaffingLists,
  useSelectedBattalionPreference,
} from '../../../hooks';
import { useLoadedAuthUserContext } from '../../Auth';
import { SnackbarService } from '../../Snackbar';
import { useFetchAdministrationStations } from './useFetchAdministrationStations';
import { useFetchAutoHire } from './useFetchAutoHire';
import { useFetchEmployeesOff } from './useFetchEmployeesOff';
import { useFetchEventStation } from './useFetchEventStation';
import { useFetchShiftSummary } from './useFetchShiftSummary';
import { useFetchTemporaryNonShiftAssignments } from './useFetchTemporaryNonShiftEmployees';

export const useFetchRosterData = () => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const [selectedDate, setSelectedDate] = useState<string | null | undefined>(null);
  const [selectedDateAsDate, setSelectedDateAsDate] = useState<Date>(new Date());
  const { selectedDate: paramsSelectedDate } = useDateQueryParam();
  const hasAdminPermission = authUserState.employee?.hasAdminPermission;

  const selectedBattalionState = useSelectedBattalionPreference();
  const [selectedBattalionId, setSelectedBattalionId] = selectedBattalionState;

  const { isApparatusLogin } = authUserState.employee;

  useEffect(() => {
    if (paramsSelectedDate !== selectedDate) {
      SnackbarService.clearQueue();
      setSelectedDate(() => paramsSelectedDate);
      setSelectedDateAsDate(() => {
        if (!paramsSelectedDate) {
          return new Date();
        }
        return parse(paramsSelectedDate, 'MM/dd/yyyy', new Date());
      });
    }
  }, [paramsSelectedDate, selectedDate]);

  const {
    isLoading: isLoadingShiftSummary,
    isError: isErrorShiftSummary,
    data: dataShiftSummary,
    forceRefetch: forceRefetchShiftSummary,
  } = useFetchShiftSummary({
    selectedDate: selectedDate || '',
    battalionId: selectedBattalionId,
    skip: !selectedDate,
  });

  const {
    isLoading: isLoadingAdministrationStations,
    isError: isErrorAdministrationStations,
    data: dataAdministrationStations,
    forceRefetch: forceRefetchAdministrationStations,
  } = useFetchAdministrationStations({
    selectedDate: selectedDate || '',
    battalionId: selectedBattalionId,
    skip: !selectedDate || isApparatusLogin,
  });

  const {
    isLoading: isLoadingTemporaryNonShift,
    isError: isErrorTemporaryNonShift,
    data: dataTemporaryNonShift,
    forceRefetch: forceRefetchTemporaryNonShift,
  } = useFetchTemporaryNonShiftAssignments({
    selectedDate: selectedDate || '',
    battalionId: selectedBattalionId,
    skip: !selectedDate || isApparatusLogin,
  });

  const {
    isLoading: isLoadingEventStation,
    isError: isErrorEventStation,
    data: dataEventStations,
    forceRefetch: forceRefetchEventStation,
  } = useFetchEventStation({
    selectedDate: selectedDate || '',
    skip: !selectedDate || isApparatusLogin,
  });

  const {
    data: dataStaffingLists,
    isError: isErrorStaffingLists,
    forceRefetch: forceRefetchStaffingLists,
  } = useFetchStaffingLists({
    selectedDate: selectedDate || '',
    skip: !selectedDate || isApparatusLogin || !hasAdminPermission,
  });

  const {
    isLoading: isLoadingEmployeesOff,
    isError: isErrorEmployeesOff,
    employeesOff: dataEmployeesOff,
    forceRefetch: forceRefetchEmployeesOff,
  } = useFetchEmployeesOff({
    battalionId: selectedBattalionId,
    skip: isApparatusLogin,
  });
  const {
    isLoading: isLoadingAutoHire,
    isError: isErrorAutoHire,
    autoHireInfo: dataAutoHire,
    forceRefetch: forceRefetchAutoHire,
  } = useFetchAutoHire({
    skip: !selectedDate || isApparatusLogin || !hasAdminPermission,
    battalionId: selectedBattalionId,
  });

  const {
    isLoading: isLoadingBulkCall,
    isError: isErrorBulkCall,
    data: bulkCalls,
    forceRefetch: forceRefetchBulkCall,
  } = useFetchBulkCallForShift({
    selectedDate: selectedDate || '',
    skip: !selectedDate || isApparatusLogin || !hasAdminPermission,
  });

  const forceRefetchAll = useCallback((battalionId?: number) => {
    battalionId && setSelectedBattalionId(battalionId);
    forceRefetchShiftSummary();
    forceRefetchAutoHire();
    forceRefetchAdministrationStations();
    forceRefetchEventStation();
    forceRefetchStaffingLists();
    forceRefetchEmployeesOff();
    forceRefetchTemporaryNonShift();
    forceRefetchBulkCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading:
      isLoadingShiftSummary ||
      isLoadingAdministrationStations ||
      isLoadingEventStation ||
      isLoadingEmployeesOff ||
      isLoadingTemporaryNonShift ||
      isLoadingAutoHire ||
      isLoadingBulkCall,
    isError:
      isErrorShiftSummary ||
      isErrorAdministrationStations ||
      isErrorEventStation ||
      isErrorEmployeesOff ||
      isErrorTemporaryNonShift ||
      isErrorAutoHire ||
      isErrorBulkCall,
    selectedBattalionState,
    isErrorStaffingLists,
    shiftSummary: dataShiftSummary,
    staffingLists: dataStaffingLists,
    forceRefetchAll,
    currentDate: selectedDateAsDate,
    administrationStations: dataAdministrationStations,
    temporaryNonShiftStation: dataTemporaryNonShift,
    eventStations: dataEventStations,
    dataEmployeesOff,
    dataAutoHire,
    bulkCalls,
  };
};
