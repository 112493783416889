import { SxProps, Theme, Box, Tooltip, useTheme } from '@mui/material';

interface Weekday {
  value: number;
  label: string;
}

// The `value` matches Python `date.weekday()`.
export const weekdays: Weekday[] = [
  { value: 6, label: 'Su' },
  { value: 0, label: 'Mo' },
  { value: 1, label: 'Tu' },
  { value: 2, label: 'We' },
  { value: 3, label: 'Th' },
  { value: 4, label: 'Fr' },
  { value: 5, label: 'Sa' },
];

interface Props {
  activeWeekdays: number[];
  getDisabledTooltipText?: (weekdayNumber: number) => string;
  onSelect: (weekdayNumber: number) => void;
  sx?: SxProps<Theme>;
  isDisabled?: boolean;
}

export const WeekdayPicker = ({ activeWeekdays, getDisabledTooltipText, onSelect, sx, isDisabled = false }: Props) => {
  const theme = useTheme();

  return (
    <Box sx={[{ display: 'flex', gap: 1.5 }, ...(Array.isArray(sx) ? sx : sx ? [sx] : [])]}>
      {weekdays.map((weekday) => {
        const isSelected = activeWeekdays.includes(weekday.value);
        const disabledTooltipText = getDisabledTooltipText?.(weekday.value);
        const button = (
          <Box
            key={weekday.value}
            component="button"
            type="button"
            onClick={disabledTooltipText || isDisabled ? undefined : () => onSelect(weekday.value)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '44px',
              width: '44px',
              borderRadius: '50%',
              border: `1px solid ${theme.palette.stationGray[200]}`,
              typography: 'buttonM',
              color: isSelected
                ? isDisabled
                  ? theme.palette.stationGray[100]
                  : theme.palette.common.white
                : isDisabled
                  ? theme.palette.stationGray[400]
                  : theme.palette.stationGray[900],
              backgroundColor: isSelected
                ? isDisabled
                  ? theme.palette.stationGray[400]
                  : theme.palette.common.black
                : 'inherit',
              p: 0,
              cursor: isDisabled || disabledTooltipText ? 'default' : 'pointer',
              ...(!(disabledTooltipText || isDisabled) && {
                '&:hover': {
                  backgroundColor: isSelected ? theme.palette.common.black : theme.palette.stationGray[100],
                  borderColor: isSelected ? theme.palette.common.white : theme.palette.stationGray[900],
                },
              }),
            }}
          >
            {weekday.label}
          </Box>
        );
        if (!disabledTooltipText) {
          return button;
        }

        return (
          <Tooltip
            key={weekday.value}
            arrow
            title={disabledTooltipText}
            slotProps={{
              tooltip: {
                sx: {
                  backgroundColor: theme.palette.stationGray[800],
                  borderRadius: 2,
                  p: '10px 12px',
                  typography: 'bodySMedium',
                  '& .MuiTooltip-arrow': { color: theme.palette.stationGray[800] },
                },
              },
            }}
          >
            {button}
          </Tooltip>
        );
      })}
    </Box>
  );
};
