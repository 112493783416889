import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { getAvatarColors } from '../../utils';

export interface LetterAvatarProps {
  firstName?: string;
  lastName?: string;
  color?: string;
  dark?: boolean;
  size?: 'xxsmall' | 'xsmall' | 'medium' | 'large' | 'xlarge';
  sx?: SxProps<Theme>;
}

export function LetterAvatar({ firstName, lastName, color, dark, size, sx }: LetterAvatarProps) {
  const theme = useTheme();

  return (
    <Box
      data-cy="letter-avatar"
      sx={[
        {
          ...getAvatarColors({ theme, color, dark }),
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '36px',
          height: '36px',
          typography: 'bodySSemibold',
          ...(size === 'xxsmall' && { width: '20px', height: '20px', typography: 'bodyXSMedium' }),
          ...(size === 'xsmall' && { width: '24px', height: '24px', typography: 'bodyXSMedium' }),
          ...(size === 'large' && { width: '42px', height: '42px' }),
          ...(size === 'xlarge' && { width: '80px', height: '80px', fontSize: '30px' }),
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {firstName?.charAt(0).toUpperCase()}
      {lastName?.charAt(0).toUpperCase()}
    </Box>
  );
}
